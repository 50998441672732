import React from 'react';

export const FilterAppliedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M4.66669 2C3.56212 2 2.66669 2.89543 2.66669 4V4.73952C2.66669 5.30649 2.90733 5.84682 3.32876 6.22611L6.00002 8.63024V12.9213C6.00002 13.9125 7.0431 14.5571 7.92964 14.1139L9.26297 13.4472C9.71468 13.2214 10 12.7597 10 12.2546V11C10 10.6318 9.70154 10.3333 9.33335 10.3333C8.96516 10.3333 8.66669 10.6318 8.66669 11V12.2546L7.33335 12.9213V8.63024C7.33335 8.25226 7.17292 7.89204 6.89197 7.63918L4.22071 5.23505C4.08023 5.10862 4.00002 4.92851 4.00002 4.73952V4C4.00002 3.63181 4.2985 3.33333 4.66669 3.33333H11.3334C11.7015 3.33333 12 3.63181 12 4V4.73952C12 4.92851 11.9198 5.10862 11.7793 5.23505L11.3874 5.5878C11.1137 5.83411 11.0915 6.25564 11.3378 6.52931C11.5841 6.80298 12.0057 6.82517 12.2793 6.57886L12.6713 6.2261C13.0927 5.84682 13.3334 5.30649 13.3334 4.73952V4C13.3334 2.89543 12.4379 2 11.3334 2H4.66669Z"
      fill="#070B12"
    />
    <path
      d="M10.3334 10.0003C11.2538 10.0003 12 9.25413 12 8.33366C12 7.41318 11.2538 6.66699 10.3334 6.66699C9.41288 6.66699 8.66669 7.41318 8.66669 8.33366C8.66669 9.25413 9.41288 10.0003 10.3334 10.0003Z"
      fill="#537BFF"
    />
  </svg>
);
