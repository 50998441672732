import { Duration } from 'luxon';

export const getTimeString = (hoursValue?: number): string => {
  if (!hoursValue) {
    return 'N/A';
  }

  const duration = Duration.fromObject({
    hours: hoursValue,
  });

  const isLessThanMinute = duration.shiftTo('minutes').minutes < 1;
  const isLessThanHour = duration.shiftTo('hours').hours < 1;
  const isLessThanThreeDays = duration.shiftTo('days').days < 3;
  const isLessThanThirtyDays = duration.shiftTo('days').days < 30;
  const isLessThanEightyFourDays = duration.shiftTo('days').days < 84;

  if (isLessThanMinute) {
    return '< 1 min';
  }

  if (isLessThanHour) {
    const shiftedDuration = duration.shiftTo('minutes', 'seconds');

    return `${shiftedDuration.minutes} min${
      shiftedDuration.minutes === 1 ? '' : 's'
    }`;
  }

  if (isLessThanThreeDays) {
    const shiftedDuration = duration.shiftTo('hours', 'minutes');

    return shiftedDuration.hours === 1 && !shiftedDuration.minutes
      ? `${shiftedDuration.toFormat('h')} hour`
      : `${shiftedDuration.toFormat('hh:mm')} hrs`;
  }

  if (isLessThanThirtyDays) {
    const shiftedDuration = duration.shiftTo('days', 'hours');
    const isHalfDay = shiftedDuration.hours >= 12;

    return `${shiftedDuration.toFormat('d')}${isHalfDay ? '.5' : ''} days`;
  }

  if (isLessThanEightyFourDays) {
    const shiftedDuration = duration.shiftTo('weeks', 'days');
    const isHalfWeek = shiftedDuration.days >= 3.5;

    return `${shiftedDuration.toFormat('w')}${isHalfWeek ? '.5' : ''} weeks`;
  }

  const shiftedDuration = duration.shiftTo('months', 'weeks');
  const isHalfMonth = shiftedDuration.weeks >= 2;

  return `${shiftedDuration.toFormat('m')}${isHalfMonth ? '.5' : ''} months`;
};
