import React from 'react';

export const FullScreenIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M9.99998 1.33325C9.63179 1.33325 9.33331 1.63173 9.33331 1.99992C9.33331 2.36811 9.63179 2.66659 9.99998 2.66659H12.6666C13.0348 2.66659 13.3333 2.96506 13.3333 3.33325V5.99992C13.3333 6.36811 13.6318 6.66659 14 6.66659C14.3682 6.66659 14.6666 6.36811 14.6666 5.99992V3.33325C14.6666 2.22868 13.7712 1.33325 12.6666 1.33325H9.99998Z"
      fill="#070B12"
    />
    <path
      d="M5.99998 14.6666C6.36817 14.6666 6.66665 14.3681 6.66665 13.9999C6.66665 13.6317 6.36817 13.3333 5.99998 13.3333H3.33331C2.96512 13.3333 2.66665 13.0348 2.66665 12.6666V9.99992C2.66665 9.63173 2.36817 9.33325 1.99998 9.33325C1.63179 9.33325 1.33331 9.63173 1.33331 9.99992V12.6666C1.33331 13.7712 2.22874 14.6666 3.33331 14.6666H5.99998Z"
      fill="#070B12"
    />
    <path
      d="M1.99998 6.66659C1.63179 6.66659 1.33331 6.36811 1.33331 5.99992V3.33325C1.33331 2.22868 2.22874 1.33325 3.33331 1.33325H5.99998C6.36817 1.33325 6.66665 1.63173 6.66665 1.99992C6.66665 2.36811 6.36817 2.66659 5.99998 2.66659H3.33331C2.96512 2.66659 2.66665 2.96506 2.66665 3.33325V5.99992C2.66665 6.36811 2.36817 6.66659 1.99998 6.66659Z"
      fill="#070B12"
    />
    <path
      d="M14.6666 9.99992C14.6666 9.63173 14.3682 9.33325 14 9.33325C13.6318 9.33325 13.3333 9.63173 13.3333 9.99992V12.6666C13.3333 13.0348 13.0348 13.3333 12.6666 13.3333H9.99998C9.63179 13.3333 9.33331 13.6317 9.33331 13.9999C9.33331 14.3681 9.63179 14.6666 9.99998 14.6666H12.6666C13.7712 14.6666 14.6666 13.7712 14.6666 12.6666V9.99992Z"
      fill="#070B12"
    />
  </svg>
);
