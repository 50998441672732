import React, { useEffect, useRef } from 'react';
import { RelationshipsTableRow } from './components/tableRow/RelationsTableRow';
import { RelationshipFilters } from './components/RelationshipFilters/RelationshipFilters';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { openAddAccountModal } from '../../store/relationshipFlows/relationshipFlows.slice';
import { AddBotModal } from './components/addBotModal/AddBotModal';
import { getRelationships } from '../../store/relationships/relationships.thunks';
import {
  clearFilters,
  clearRelationships,
  clearSearch,
  updateFiltersQueryParams,
} from '../../store/relationships/relationships.slice';
import { LoaderIcon } from '../../components/Icons/LoaderIcon';
import { noRSDataIcon } from './components/assets';
import { appliedFiltersSelector } from '../../store/relationships/relationships.selectors';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  InviteAssistantButton,
} from '../../components/SoundWave';
import { getRelationshipsFiltersQueryParams } from '../../store/relationships/relationships.utils';
import { useTrackPageView } from '../../hooks';

export const MyRelationships: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    relationships,
    relationshipsForTable,
    relationshipsDataStatus,
  } = useAppSelector((state) => state.relationships);

  const filtersQueryParams = useAppSelector(
    (state) => state.relationships.filtersQueryParams
  );

  const appliedFilters = useAppSelector(appliedFiltersSelector);

  const { sortBy, sortOrder } = useAppSelector(
    (state) => state.relationships.relationships
  );

  const loadMoreRequest = useRef<
    | (Promise<unknown> & {
        abort: () => void;
      })
    | null
  >(null);

  useTrackPageView();

  useEffect(() => {
    const request = dispatch(
      getRelationships({
        filters: filtersQueryParams,
        sortBy,
        sortOrder,
      })
    );

    return () => {
      request?.abort();
      loadMoreRequest.current?.abort();
      dispatch(clearSearch());
      dispatch(clearRelationships());
    };
  }, [dispatch, filtersQueryParams, sortBy, sortOrder]);

  const elScrollToRef = useRef<HTMLDivElement>(null);

  const relationsCountRef = useRef(relationshipsForTable.length);

  useEffect(() => {
    if (
      relationshipsForTable.length > 1 &&
      relationsCountRef.current > 1 &&
      relationshipsForTable.length > relationsCountRef.current
    ) {
      if (elScrollToRef?.current) {
        elScrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }
    relationsCountRef.current = relationshipsForTable.length;
  }, [relationshipsForTable]);

  const isNoMoreRelationshipsToRequest =
    relationshipsForTable.length &&
    relationships.p * relationships.ipp >= relationships.count;

  const isNoData = !relationships.results?.length;

  const attrs = {
    wrapper: {
      className: 'my-relationships',
    },

    headerSection: {
      wrapper: {
        className: 'my-relationships__header-section',
      },

      title: {
        className: 'my-relationships__header-section-title',
        onClick: () => {
          if (elScrollToRef?.current) {
            elScrollToRef?.current?.scrollIntoView({ behavior: 'smooth' });
          }
        },
      },

      controls: {
        wrapper: {
          className: 'my-relationships__header-section__controls',
        },

        button: {
          size: ButtonSize.S,
          variant: ButtonVariant.Secondary,
          onClick: () => {
            dispatch(
              openAddAccountModal({
                withRedirect: true,
              })
            );
          },
        },
      },
    },

    tableWrapper: {
      wrapper: {
        className: 'my-relationships__table-wrapper',
      },

      noData: {
        wrapper: {
          className: 'my-relationships__table-wrapper__no-data',
        },

        title: {
          className: 'my-relationships__table-wrapper__no-data-title',
        },

        image: {
          className: 'my-relationships__table-wrapper__no-data-img',
          src: noRSDataIcon,
        },

        clearButton: {
          className: `${
            appliedFilters?.length ? '' : 'hidden '
          }my-relationships__table-wrapper__no-data-clear-button`,
          onClick: () => {
            dispatch(clearFilters());
            dispatch(
              updateFiltersQueryParams(getRelationshipsFiltersQueryParams([]))
            );
          },
        },
      },

      loadMore: {
        wrapper: {
          className: `${
            isNoMoreRelationshipsToRequest || isNoData ? 'hidden ' : ''
          }my-relationships__table-wrapper__load-more`,
        },

        button: {
          className: 'my-relationships__table-wrapper__load-more-button',
          onClick: () => {
            if (!isNoMoreRelationshipsToRequest) {
              loadMoreRequest.current = dispatch(
                getRelationships({
                  filters: filtersQueryParams,
                  sortBy,
                  sortOrder,
                })
              );
            }
          },
        },

        loadingIcon: {
          className: 'my-relationships__table-wrapper__load-more-button-loader',
        },

        buttonToltip: {
          className:
            'my-relationships__table-wrapper__load-more-button-tooltip',
        },
      },

      elForScroll: {
        className: 'my-relationships__table-wrapper-scroll-element',
        ref: elScrollToRef,
      },
    },
  };

  const noDataTableBody = (
    <div {...attrs.tableWrapper.noData.wrapper}>
      <div {...attrs.tableWrapper.noData.title}>No relationships found</div>
      <img {...attrs.tableWrapper.noData.image} alt="" />
      <div {...attrs.tableWrapper.noData.clearButton}>Clear all filters</div>
    </div>
  );

  const loadMoreSkeletons =
    relationshipsForTable?.length && relationshipsDataStatus.isLoading ? (
      <>
        <RelationshipsTableRow isLoading />
        <RelationshipsTableRow isLoading />
        <RelationshipsTableRow isLoading />
        <RelationshipsTableRow isLoading />
        <RelationshipsTableRow isLoading />
      </>
    ) : null;

  const defaultSkeletonLoadingBody = (
    <>
      <RelationshipsTableRow isLoading />
      <RelationshipsTableRow isLoading />
      <RelationshipsTableRow isLoading />
      <RelationshipsTableRow isLoading />
      <RelationshipsTableRow isLoading />
      <RelationshipsTableRow isLoading />
      <RelationshipsTableRow isLoading />
      <RelationshipsTableRow isLoading />
    </>
  );

  const conditionalStatusBody =
    !relationshipsDataStatus.isLoading || relationshipsDataStatus.isError
      ? noDataTableBody
      : defaultSkeletonLoadingBody;

  const table = relationshipsForTable?.length
    ? relationshipsForTable.map((el, i) => (
        <React.Fragment key={`${el?.account?.accountId}|${i}`}>
          <RelationshipsTableRow relationship={el} />
        </React.Fragment>
      ))
    : conditionalStatusBody;

  return (
    <div {...attrs.wrapper}>
      <AddBotModal />
      <div {...attrs.headerSection.wrapper}>
        <div {...attrs.headerSection.title}>My Relationships</div>
        <div {...attrs.headerSection.controls.wrapper}>
          <RelationshipFilters />
          <Button {...attrs.headerSection.controls.button}>
            <span>Create Account</span>
          </Button>
          <InviteAssistantButton />
        </div>
      </div>
      <div {...attrs.tableWrapper.wrapper}>
        <RelationshipsTableRow isTitle />
        {table}
        {loadMoreSkeletons}
        <div {...attrs.tableWrapper.loadMore.wrapper}>
          <div {...attrs.tableWrapper.loadMore.button}>
            {relationshipsDataStatus.isLoading ? (
              <LoaderIcon {...attrs.tableWrapper.loadMore.loadingIcon} />
            ) : (
              'Load More'
            )}
            <div {...attrs.tableWrapper.loadMore.buttonToltip}>
              {relationshipsDataStatus.isLoading
                ? 'Loading'
                : 'Load more relationships'}
            </div>
          </div>
        </div>
        <div {...attrs.tableWrapper.elForScroll} />
      </div>
    </div>
  );
};
