import React from 'react';

export const ComingSoonIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M10.6668 8.66659C11.035 8.66659 11.3335 8.36811 11.3335 7.99992C11.3335 7.63173 11.035 7.33325 10.6668 7.33325H8.66683V5.33325C8.66683 4.96506 8.36835 4.66659 8.00016 4.66659C7.63197 4.66659 7.3335 4.96506 7.3335 5.33325V7.99992C7.3335 8.36811 7.63197 8.66659 8.00016 8.66659H10.6668Z"
      fill="#070B12"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325ZM2.66683 7.99992C2.66683 5.0544 5.05464 2.66659 8.00016 2.66659C10.9457 2.66659 13.3335 5.0544 13.3335 7.99992C13.3335 10.9454 10.9457 13.3333 8.00016 13.3333C5.05464 13.3333 2.66683 10.9454 2.66683 7.99992Z"
      fill="#070B12"
    />
  </svg>
);
