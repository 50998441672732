import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as Triangle } from '../../icons/triangle_icon.svg';
import { ReactComponent as CircleLines } from '../../icons/circleLines_icon.svg';
import { Avatar, Popup } from '../../../../components';
import GetColorAndMessage from '../infoBar/getColorAndMessage';
import CircleBarSkeleton from '../skeletons/CircleBarSkeleton';
import { User } from '../../../../types';
import { TooltipIcon } from '../../../../components/Icons/TooltipIcon';

interface AnimatematedCircleBarProps {
  measure: number | string;
  animationTime: number;
  isLoading: boolean;
  user: User;
  noData?: boolean;
}

const CircleProgressBar: FC<AnimatematedCircleBarProps> = (props) => {
  const { measure, isLoading, user, noData } = props;

  let { animationTime } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [color, setColor] = useState('#F26065');

  const rotateCirkle = !measure ? NaN : measure;

  useEffect(() => {
    if (
      !Number.isNaN(rotateCirkle) &&
      rotateCirkle !== undefined &&
      typeof rotateCirkle === 'number'
    ) {
      const { triangeColor } = GetColorAndMessage(
        rotateCirkle,
        'overallCompetence'
      );
      setColor(triangeColor);
    } else {
      setColor('#E7EAFF');
      animationTime = 0.1; // eslint-disable-line
    }
  }, [rotateCirkle, noData]);

  return (
    <CircleBarSkeleton duration={2500} globalLoading={isLoading}>
      <>
        <div className="personalQ__secondColumn-overallcompetence-title">
          COMPETENCE{' '}
          <TooltipIcon
            active={tooltipOpen}
            className="personalQ__secondColumn-overallcompetence-title-img"
            openTooltip={() => setTooltipOpen(true)}
            closeTooltip={() => setTooltipOpen(false)}
          />
          <Popup
            open={tooltipOpen}
            className="personalQ__secondColumn-overallcompetence-title-popup"
            text="Based on the ratio of 'upper-hand' results across all of your messages with any given prospect."
          />
        </div>
        <div className={!measure ? 'circle' : 'loaded circle'}>
          <div className="circle__border" />
          <div
            className="circle__conicGradMeasure"
            style={{
              background: `conic-gradient(transparent ${
                typeof measure === 'number' ? measure * 360 : 0
              }deg, white 0)`,
            }}
          />
          <CircleLines className="circle__lines" />
          <div
            className="circle__triangleToRotate"
            style={{
              transform: `rotate(${
                typeof measure === 'number' ? measure * 360 : 0
              }deg)`,
              transitionDuration: `${animationTime}s`,
            }}
          >
            <Triangle
              className="circle__triangleToRotate-triangle"
              fill={color}
              style={{
                transitionDuration: `${animationTime}s`,
              }}
            />
          </div>
          <div className="circle__toRotate" />
          <div className="circle__photoPlace">
            <Avatar
              user={user}
              image={user?.avatar}
              className="circle__photoPlace__photo"
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>
      </>
    </CircleBarSkeleton>
  );
};

export default CircleProgressBar;
