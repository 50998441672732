import React from 'react';

export const LinkIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.66634 3.33337C2.66634 2.96518 2.96482 2.66671 3.33301 2.66671H7.99967C8.36786 2.66671 8.66634 2.36823 8.66634 2.00004C8.66634 1.63185 8.36786 1.33337 7.99967 1.33337H3.33301C2.22844 1.33337 1.33301 2.2288 1.33301 3.33337V12.6667C1.33301 13.7713 2.22844 14.6667 3.33301 14.6667H12.6663C13.7709 14.6667 14.6663 13.7713 14.6663 12.6667V8.00004C14.6663 7.63185 14.3679 7.33337 13.9997 7.33337C13.6315 7.33337 13.333 7.63185 13.333 8.00004V12.6667C13.333 13.0349 13.0345 13.3334 12.6663 13.3334H3.33301C2.96482 13.3334 2.66634 13.0349 2.66634 12.6667V3.33337Z"
      fill="#537BFF"
    />
    <path
      d="M9.99967 2.00004C9.99967 1.63185 10.2982 1.33337 10.6663 1.33337H13.333C14.0694 1.33337 14.6663 1.93033 14.6663 2.66671V5.33337C14.6663 5.70156 14.3679 6.00004 13.9997 6.00004C13.6315 6.00004 13.333 5.70156 13.333 5.33337V3.60952L8.47108 8.47145C8.21073 8.7318 7.78862 8.7318 7.52827 8.47145C7.26792 8.2111 7.26792 7.78899 7.52827 7.52864L12.3902 2.66671H10.6663C10.2982 2.66671 9.99967 2.36823 9.99967 2.00004Z"
      fill="#537BFF"
    />
  </svg>
);
