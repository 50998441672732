import React from 'react';

export const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2C8.86819 2 9.16667 2.29848 9.16667 2.66667V7.33333H13.8333C14.2015 7.33333 14.5 7.63181 14.5 8C14.5 8.36819 14.2015 8.66667 13.8333 8.66667H9.16667V13.3333C9.16667 13.7015 8.86819 14 8.5 14C8.13181 14 7.83333 13.7015 7.83333 13.3333V8.66667H3.16667C2.79848 8.66667 2.5 8.36819 2.5 8C2.5 7.63181 2.79848 7.33333 3.16667 7.33333H7.83333V2.66667C7.83333 2.29848 8.13181 2 8.5 2Z"
      fill="#537BFF"
    />
  </svg>
);
