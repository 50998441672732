import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  children?: JSX.Element | string;
  isLoading?: boolean;
  isTeamQ?: boolean;
}

export const SellingZoneSkeleton: React.FC<Props> = (props: Props) => {
  const { children, isLoading, isTeamQ } = props;

  const attrs = {
    title: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 200,
          maxWidth: '45%',
          height: 20,
          borderRadius: 30,
          marginTop: 0,
        },
      },
    },
    subTitle: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 150,
          maxWidth: '40%',
          height: 13,
          borderRadius: 30,
          marginTop: 21,
        },
      },
    },
    bars: {
      below: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 45,
            height: 90,
            borderRadius: 10,
            margin: 0,
            marginTop: 5,
          },
        },
      },
      in: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 45,
            height: 130,
            borderRadius: 10,
            margin: 0,
            marginTop: 5,
          },
        },
      },
      above: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 45,
            height: 47,
            borderRadius: 10,
            margin: 0,
            marginTop: 5,
          },
        },
      },
    },
    xAxisTick: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 26,
          height: 7,
          borderRadius: 30,
          margin: 'auto',
          marginTop: 15,
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className={`${isTeamQ ? 'team-q-chart' : ''} chart-wrapper`}>
          <div className="chart-wrapper__title-block">
            <Skeleton {...attrs.title} />
          </div>
          <div
            className={`${isTeamQ ? 'no-border ' : ''}chart-wrapper__subtitle`}
          >
            <Skeleton {...attrs.subTitle} />
          </div>
          <div className="selling-zone-chart">
            <div className="selling-zone-chart__column">
              <Skeleton {...attrs.bars.below} />
              <Skeleton {...attrs.xAxisTick} />
            </div>
            <div className="selling-zone-chart__column">
              <Skeleton {...attrs.bars.in} />
              <Skeleton {...attrs.xAxisTick} />
            </div>
            <div className="selling-zone-chart__column">
              <Skeleton {...attrs.bars.above} />
              <Skeleton {...attrs.xAxisTick} />
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
