import React, { ChangeEvent } from 'react';

type Props = {
  onUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

export const AvatarUploadClip: React.FC<Props> = ({
  onUpload,
  className,
}: Props): JSX.Element => {
  return (
    <>
      <label className={`upload-clip ${className}`} htmlFor="clip">
        Upload a photo
      </label>
      <input type="file" id="clip" onChange={onUpload} />
    </>
  );
};
