import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import ReactPlayerClass from 'react-player';

interface VideoPlayerContextValue {
  activeTranscriptItemIndex: number | null;
  isFullScreen: boolean;
  isPlaying: boolean;
  isMuted: boolean;
  isChangingTimeline: boolean;
  showBigPlay: boolean;
  duration: number;
  volume: number;
  playbackRate: number;
  timelineState: {
    played: number;
    playedSeconds: number;
    loaded: number;
    loadedSeconds: number;
  };
}

interface ExtendedVideoPlayerContextValue extends VideoPlayerContextValue {
  playerRef: React.RefObject<HTMLDivElement>;
  reactPlayerInstanceRef: React.RefObject<ReactPlayerClass | null>;
  isLoading: boolean;
}

interface VideoPlayerContextApi {
  setActiveTranscriptItemIndex: (index: number | null) => void;
  setIsFullScreen: (isFullScreen: boolean) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  setIsMuted: (isMuted: boolean) => void;
  setIsChangingTimeline: (isChangingTimeline: boolean) => void;
  setShowBigPlay: (showBigPlay: boolean) => void;
  setDuration: (duration: number) => void;
  setVolume: (volume: number) => void;
  setPlaybackRate: (playbackRate: number) => void;
  setTimelineState: (
    timelineState: VideoPlayerContextValue['timelineState']
  ) => void;
}

const initialState: VideoPlayerContextValue = {
  activeTranscriptItemIndex: null,
  isFullScreen: false,
  isPlaying: false,
  isMuted: false,
  isChangingTimeline: false,
  showBigPlay: true,
  duration: 0,
  volume: 1,
  playbackRate: 1,
  timelineState: {
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  },
};

const VideoPlayerContext = createContext<
  [ExtendedVideoPlayerContextValue, VideoPlayerContextApi]
>([
  {
    ...initialState,
    playerRef: { current: null },
    reactPlayerInstanceRef: { current: null },
    isLoading: false,
  },
  {
    setActiveTranscriptItemIndex: () => {},
    setIsFullScreen: () => {},
    setIsPlaying: () => {},
    setIsMuted: () => {},
    setIsChangingTimeline: () => {},
    setShowBigPlay: () => {},
    setDuration: () => {},
    setVolume: () => {},
    setPlaybackRate: () => {},
    setTimelineState: () => {},
  },
]);

const videoPlayerReducer = (
  state: VideoPlayerContextValue,
  action: Partial<VideoPlayerContextValue>
): VideoPlayerContextValue => {
  return {
    ...state,
    ...action,
  };
};

const VideoPlayerContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(videoPlayerReducer, initialState);

  const playerRef = useRef<HTMLDivElement>(null);
  const reactPlayerInstanceRef = useRef<ReactPlayerClass | null>(null);

  const { loadedSeconds, playedSeconds } = state.timelineState;

  const isLoading = !loadedSeconds || loadedSeconds < playedSeconds;

  const contextValue: ExtendedVideoPlayerContextValue = {
    ...state,
    playerRef,
    reactPlayerInstanceRef,
    isLoading,
  };

  const contextApi: VideoPlayerContextApi = useMemo(
    () => ({
      setActiveTranscriptItemIndex: (index: number | null) =>
        dispatch({ activeTranscriptItemIndex: index }),
      setIsFullScreen: (isFullScreen: boolean) => dispatch({ isFullScreen }),
      setIsPlaying: (isPlaying: boolean) => dispatch({ isPlaying }),
      setIsMuted: (isMuted: boolean) => dispatch({ isMuted }),
      setIsChangingTimeline: (isChangingTimeline: boolean) =>
        dispatch({ isChangingTimeline }),
      setShowBigPlay: (showBigPlay: boolean) => dispatch({ showBigPlay }),
      setDuration: (duration: number) => dispatch({ duration }),
      setVolume: (volume: number) => dispatch({ volume }),
      setPlaybackRate: (playbackRate: number) => dispatch({ playbackRate }),
      setTimelineState: (timelineState) => dispatch({ timelineState }),
    }),
    []
  );

  const attrs = {
    provider: {
      value: [contextValue, contextApi] as [
        ExtendedVideoPlayerContextValue,
        VideoPlayerContextApi
      ],
    },
  };

  return (
    <VideoPlayerContext.Provider {...attrs.provider}>
      {children}
    </VideoPlayerContext.Provider>
  );
};

export const useVideoPlayerContext = (): [
  ExtendedVideoPlayerContextValue,
  VideoPlayerContextApi
] => useContext(VideoPlayerContext);

export default VideoPlayerContextProvider;
