import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { RootState } from '../../../../store/store';
import { updateUser } from '../../../../store/users/users.actions';

type RenameUserForm = {
  firstName: string;
  lastName: string;
};
// eslint-disable-next-line
export const useRenameUser = (onClose: () => void) => {
  const { currentUser, users } = useSelector((state: RootState) => state.users);
  const user = users.find((u) => u.userId === currentUser?.userId);
  const dispatch = useDispatch();

  const handleSubmit = (values: RenameUserForm) => {
    dispatch(updateUser(values, user?.orgId || '', currentUser?.userId || ''));
    onClose();
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required()
      .matches(/[a-zA-Z+]/),
    lastName: Yup.string()
      .required()
      .matches(/[a-zA-Z+]/),
  });

  const formik = useFormik<RenameUserForm>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return {
    ...formik,
    handleCancel,
  };
};
