import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { DateTime, Duration } from 'luxon';
import {
  MeetingParticipantWithContactInfo,
  MeetingTranscriptWithParticipantInfo,
} from '../../types/meetingDetails.types';
import { groupBy } from '../../utils/groupBy';
import { RootState } from '../store';

export const meetingDateStringSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.createdAt,
  (createdAt) => {
    if (!createdAt) {
      return '';
    }

    return DateTime.fromISO(createdAt).toFormat('MMM dd, yyyy, hh:mm a');
  }
);

export const meetingRecordingDurationStringSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.duration,
  (duration) => {
    if (!duration) {
      return '';
    }

    const { minutes, seconds } = Duration.fromObject({
      seconds: duration || 0,
    }).shiftTo('minutes', 'seconds');

    if (!minutes) {
      return `${Math.round(seconds)} sec`;
    }

    return `${minutes + (seconds >= 30 ? 1 : 0)} min${
      minutes === 1 ? '' : 's'
    }`;
  }
);

export const meetingHostWithContactInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.host,
  (state: RootState) => state.meetingDetails.data?.contacts,
  (host, contacts = []) => {
    if (!host) {
      return null;
    }

    const contact =
      contacts.find((item) => item.contactId === host.contactId) || null;

    const hostWithContactInfo: MeetingParticipantWithContactInfo = {
      ...host,
      contact,
    };

    return hostWithContactInfo;
  }
);

export const meetingParticipantsWithContactInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.participants,
  (state: RootState) => state.meetingDetails.data?.contacts,
  (participants = [], contacts = []) => {
    if (!participants.length) {
      return [];
    }

    return participants.map((participant) => {
      const contact =
        contacts.find((item) => item.contactId === participant.contactId) ||
        null;

      const participantWithContactInfo: MeetingParticipantWithContactInfo = {
        ...participant,
        contact,
      };

      return participantWithContactInfo;
    });
  }
);

export const meetingParticipantsBySideSelector = createDraftSafeSelector(
  meetingParticipantsWithContactInfoSelector,
  (participants) => groupBy(participants, (participant) => participant.side)
);

export const transcriptsWithParticipantInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingDetails.data?.transcripts,
  meetingParticipantsWithContactInfoSelector,
  (transcripts = [], participants = []) => {
    if (!transcripts.length) {
      return [];
    }

    return transcripts.map((transcript) => {
      const participant =
        participants.find(
          (item) => item.participantId === transcript.participantId
        ) || null;

      const transcriptWithParticipantInfo: MeetingTranscriptWithParticipantInfo = {
        ...transcript,
        participant,
      };

      return transcriptWithParticipantInfo;
    });
  }
);
