import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Button, Modal } from '../../../../components';
import { Member } from '../hooks/useAddTeamForm';
import { fieldIconTeam } from '../../icons/index';
import { photo } from '../../../Users/icons';
import {
  updateTeamDetails,
  updateTeamMembers,
} from '../../../../store/teams/teams.actions';
import { Team } from '../../../../store/teams/teams.reducer';
import { User } from '../../../../types';
import { EditMembersCaseType, EditMembers } from '../editMembers/EditMembers';
import {
  ButtonType,
  ButtonVariantType,
} from '../../../../components/Button/Button';

type EditTeamProps = {
  open: boolean;
  onClose: () => void;
  currentUser: User | null;
  currentTeam: Team;
  handleUploadAvatar: (e: ChangeEvent<HTMLInputElement>) => void;
  saveCroppedAvatar: (image: string) => void;
  croppedAvatar: string;
  isUploadAvatarOpen: boolean;
};

export const EditTeam: React.FC<EditTeamProps> = ({
  open,
  onClose,
  currentUser,
  currentTeam,
  croppedAvatar,
  handleUploadAvatar,
  saveCroppedAvatar,
  isUploadAvatarOpen,
}: EditTeamProps): JSX.Element => {
  const [arrayOfMembers, setArrayOfMembers] = useState<Member[]>([]);
  const [isNoAddedMembersError, setIsNoAddedMembersError] = useState(false);
  const [changedLeaderWarn, setchangedLeaderWarn] = useState(false);
  const [teamName, setTeamName] = useState('');
  const [initialTeamLeader, setinitialTeamLeader] = useState('');
  const [currentTeamLeader, setCurrentTeamLeader] = useState('');
  const [noTeamLeadError, setNoTeamLeadError] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentTeam?.members?.length && open) {
      const result = currentTeam.members.map((el) => ({
        userId: el.userId,
        teamMemberRole: el.teamMemberRole as 'teamLeader' | 'member',
        name: el.name,
        email: el.email,
      }));
      setArrayOfMembers(result);
      const teamLead = currentTeam.members.find(
        (el) => el.teamMemberRole === 'teamLeader'
      );
      setinitialTeamLeader(teamLead?.name || '');
    }
    setTeamName(currentTeam?.name);
  }, [open]); // eslint-disable-line

  useEffect(() => {
    const checkHavingTeamLeader = arrayOfMembers.some(
      (el) => el.teamMemberRole === 'teamLeader'
    );
    const isCurrentLeadInitialOne =
      arrayOfMembers?.find((el) => el.teamMemberRole === 'teamLeader')?.name ===
      initialTeamLeader;
    if (!isCurrentLeadInitialOne) {
      const newLeader = arrayOfMembers?.find(
        (el) => el.teamMemberRole === 'teamLeader'
      );
      setCurrentTeamLeader(newLeader?.name || '');
    }
    if (arrayOfMembers.length && !checkHavingTeamLeader) {
      setNoTeamLeadError(true);
    } else if (!arrayOfMembers.length || checkHavingTeamLeader) {
      setNoTeamLeadError(false);
    }
    if (!isCurrentLeadInitialOne && !changedLeaderWarn && !noTeamLeadError) {
      setchangedLeaderWarn(true);
    } else if (isCurrentLeadInitialOne && changedLeaderWarn) {
      setchangedLeaderWarn(false);
    } // eslint-disable-next-line
  }, [arrayOfMembers]);

  const saveAndClose = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!arrayOfMembers?.length) {
      setIsNoAddedMembersError(true);
    } else if (currentUser && currentTeam && teamName.length) {
      const { orgId, userId } = currentUser;
      const { teamId } = currentTeam;
      const nameToUpdate = currentTeam.name !== teamName ? teamName : null;
      const teamDetails = {
        avatar: croppedAvatar,
        name: nameToUpdate,
      };
      const membersToDelete = arrayOfMembers
        .filter((el) => el.isDeleted)
        .map((el) => ({
          userId: el.userId,
          teamMemberRole: el.teamMemberRole,
        }));
      const membersToUpdate = arrayOfMembers
        .filter((el) => !el.isDeleted)
        .map((el) => ({
          userId: el.userId,
          teamMemberRole: el.teamMemberRole,
        }));

      if (membersToDelete.length) {
        dispatch(
          updateTeamMembers(
            orgId,
            userId,
            teamId,
            membersToDelete,
            'deleteMembers',
            true
          )
        );
      }
      setTimeout(() => {
        dispatch(
          updateTeamMembers(
            orgId,
            userId,
            teamId,
            membersToUpdate,
            'addMembers'
          )
        );
        if (croppedAvatar) {
          saveCroppedAvatar('');
        }
      }, 500);
      setTimeout(() => {
        // eslint-disable-next-line
        dispatch(updateTeamDetails(orgId, userId, teamId, teamDetails as any));
        onClose();
      }, 1000);
    }
  };

  const cancelAndClose = () => {
    if (croppedAvatar) {
      saveCroppedAvatar('');
    }
    onClose();
  };

  const onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setTeamName(value);
  };

  const error = () => {
    let currentError;
    if (!teamName.length) currentError = 'This field is required';
    if (isNoAddedMembersError) currentError = 'Add members to edit team';
    return currentError;
  };

  const attrs = {
    modal: {
      title: 'Edit team',
      open,
      onClose: cancelAndClose,
      opacity: isUploadAvatarOpen ? '0' : '1',
    },
    titleError: {
      className: 'teams-add__members-body__title-error',
    },
    titleChangedLeaderWarn: {
      className: 'teams-add__members-body__title-warn',
    },
    avatar: {
      className: 'add-user__avatar',
      onUpload: handleUploadAvatar,
      image: croppedAvatar || currentTeam?.avatar || photo,
      hasUpload: true,
      style: { width: '5rem', height: '5rem' },
      inModal: true,
    },
    input: {
      error: error(),
      onChange: onNameChange,
      value: teamName,
      icon: fieldIconTeam,
      className: error()
        ? 'error create-new-team__input'
        : 'create-new-team__input',
      label: 'Team’s name',
    },
    EditMembers: {
      onClose,
      arrayOfMembers,
      setArrayOfMembers,
      currentCase: 'editing' as EditMembersCaseType,
    },
    buttons: {
      saveChanges: {
        type: 'submit' as ButtonType,
        className: 'create-new-team__btn',
        variant: 'common' as ButtonVariantType,
        disabled: noTeamLeadError,
      },
    },
    form: {
      onSubmit: saveAndClose,
    },
    modalBodyWrapper: {
      className: 'create-new-team',
    },
  };

  // const avatarImage =
  //   croppedAvatar || currentTeam?.avatar ? <Avatar {...attrs.avatar} /> : null;

  // const avatarUploadClip =
  //   croppedAvatar || currentTeam?.avatar ? null : (
  //     <AvatarUploadClip onUpload={handleUploadAvatar} />
  //   );

  const noTeamLeaderMessage = noTeamLeadError ? (
    <div {...attrs.titleError}>
      No Team Leader has been assigned, please select one to continue
    </div>
  ) : null;

  const changedLeaderWarnMessage = changedLeaderWarn ? (
    <div {...attrs.titleChangedLeaderWarn}>
      {currentTeamLeader} is the new team leader instead of {initialTeamLeader}.
    </div>
  ) : null;

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.modalBodyWrapper}>
        {noTeamLeaderMessage || changedLeaderWarnMessage}
        {/* {avatarImage} */}
        <form onSubmit={saveAndClose}>
          <Input {...attrs.input} />
          {/* {avatarUploadClip} */}
          <EditMembers {...attrs.EditMembers} />
          <Button {...attrs.buttons.saveChanges}>Save changes</Button>
        </form>
      </div>
    </Modal>
  );
};
