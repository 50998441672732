import React from 'react';

export const CheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4712 4.86239C12.7315 5.12282 12.7314 5.54493 12.4709 5.8052L7.13447 11.1385C7.00938 11.2635 6.83975 11.3337 6.66291 11.3337C6.48606 11.3336 6.3165 11.2632 6.19152 11.1381L3.52799 8.47145C3.2678 8.21095 3.26804 7.78884 3.52855 7.52864C3.78905 7.26845 4.21116 7.2687 4.47136 7.5292L6.66362 9.72404L11.5284 4.86212C11.7888 4.60184 12.2109 4.60197 12.4712 4.86239Z"
      fill="#537BFF"
    />
  </svg>
);
