import { Skeleton } from 'antd';
import React from 'react';
import { EventSummaryEmpty } from '../EventSummaryEmpty/EventSummaryEmpty';

export interface EventSummaryTopicsProps {
  emptyText?: string;
  isLoading?: boolean;
  labels?: Array<{ text: string; value?: string }>;
}

export const EventSummaryTopics: React.FC<EventSummaryTopicsProps> = ({
  emptyText,
  isLoading = false,
  labels,
}) => {
  const attrs = {
    container: {
      className: 'event-summary-topics',
    },
    label: {
      className: 'event-summary-topics__label',
    },
    labelText: {
      className: 'event-summary-topics__label-text',
    },
    labelValue: {
      className: 'event-summary-topics__label-value',
    },
    eventSummaryEmpty: {
      text: emptyText,
    },
    labelSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 120,
      },
      title: {
        style: {
          width: 120,
          height: 28,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
  };

  const renderLabels = () => {
    if (isLoading) {
      return Array.from({ length: 5 }).map((_, index) => (
        <Skeleton {...attrs.labelSkeleton} key={index} />
      ));
    }

    if (labels?.length) {
      return labels.map((label, index) => {
        const { text, value } = label;

        return (
          <div {...attrs.label} key={index}>
            <span {...attrs.labelText}>{text}</span>
            {value ? <span {...attrs.labelValue}>{value}</span> : null}
          </div>
        );
      });
    }

    return <EventSummaryEmpty {...attrs.eventSummaryEmpty} />;
  };

  return <div {...attrs.container}>{renderLabels()}</div>;
};
