import React, { useEffect, useState } from 'react';
import { EstimatedTendencies } from '../../../../../store/charts/charts.types';
import { TeamQETendencies } from '../../../../../store/teamQ/teamQ.types';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { QActivitySkeleton } from '../../skeletons/QActivitySkeleton';
import { TendencyBar } from './tendencyBar/TendencyBar';

type Props = {
  data: EstimatedTendencies | TeamQETendencies | null;
  lastDays?: number;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  isInTeamQ?: boolean;
};

export const EstimatedTendsChart: React.FC<Props> = ({
  data,
  lastDays,
  isLoading,
  noData,
  error,
  isInTeamQ,
}): JSX.Element => {
  const [localData, setLocalData] = useState<
    EstimatedTendencies | TeamQETendencies | null
  >(null);

  useEffect(() => {
    if (localData) {
      setLocalData(null);
    }
    if (data) {
      setLocalData(data);
    }
    // eslint-disable-next-line
  }, [isInTeamQ, data]);

  const attrs = {
    chartWrapper: {
      title: 'Estimated Tendencies',
      subtitle: `${isInTeamQ ? 'Team avg, last' : 'Last'} ${
        lastDays ? lastDays - 1 : ''
      } days`,
      tooltipText: isInTeamQ
        ? `Estimated tendencies are based on the team’s overall email communication. 
        Being in the recommended range will assist your team members 
        to be perceived more favorably by their prospects.`
        : `Estimated tendencies based on your overall email communication. 
      Try to be in the recommended range to be perceived more favorably by your prospects.`,
      isNoData: noData || error,
      isInTeamQ,
    },

    body: {
      className: 'charts__estimated-tendencies',
      style: noData || error ? { opacity: 0.4 } : {},
    },

    competenceBar: {
      title: 'Authority',
      min: 0,
      max: 1,
      value: localData?.competence || null,
      tooltipMessage: isInTeamQ
        ? `Based on the ratio of 'Upper-Hand' 
      results across all of the team members’ messages with prospects.`
        : `Based on the ratio of 'upper-hand' 
      results across all of your messages with any given prospect.`,
      noData,
    },

    empathyBar: {
      title: 'Empathy',
      min: -1.75,
      max: 1.5,
      value: localData?.empathy || null,
      tooltipMessage: isInTeamQ
        ? `Having empathy allows you to better 
      understand the prospects' pain points and motivations. 
      Being too empathetic may be perceived by prospects as being too pleasing, 
      while not showing enough empathy may come across as being indifferent.`
        : `Empathy allows you to walk a mile in your prospects' 
      shoes and understand their pain points and motivations.
      Being too empathetic may sacrifice your advantage,
      while not showing enough empathy may come across as being indifferent.`,
      noData,
    },

    responsivenessBar: {
      title: 'Responsiveness',
      min: -1.3,
      max: 1,
      value: localData?.responsiveness || null,
      tooltipMessage: isInTeamQ
        ? `The team’s average response time to prospects. 
      This may impact the way prospects perceive your team members. 
      Responding too quickly may signal overenthusiasm, 
      while responding too slowly may signal a lack of motivation.`
        : `Your average response time to your prospects. 
      This may impact the way they perceive you.
      Responding too quickly may signal overenthusiasm,
      while responding too slowly may signal a lack of motivation.`,
      noData,
    },
  };

  return (
    <QActivitySkeleton isLoading={isLoading} isTeamQ={isInTeamQ}>
      <ChartWrapper {...attrs.chartWrapper}>
        <div {...attrs.body}>
          <TendencyBar {...attrs.competenceBar} />
          <TendencyBar {...attrs.empathyBar} />
          <TendencyBar {...attrs.responsivenessBar} />
        </div>
      </ChartWrapper>
    </QActivitySkeleton>
  );
};
