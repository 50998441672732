import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar } from '../../../../../components';
import { Tooltip } from '../../../../../components/Tooltip/Tooltip';
import { RootState } from '../../../../../store/store';

type TableRowNameProps = {
  userId: string;
};

export const TableRowName: React.FC<TableRowNameProps> = ({
  userId,
}: TableRowNameProps): JSX.Element => {
  const user = useSelector((state: RootState) => state.users.users).find(
    (el) => el.userId === userId
  );
  const [showTooltip, setShowTooltip] = useState(false);

  const handleShowTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="options__name">
      <div
        className={`options__name-dot options__name-dot__${user?.role}`}
        onMouseEnter={handleShowTooltip}
        onMouseLeave={handleShowTooltip}
      />
      {user?.userId ? (
        <Avatar
          user={user}
          image={user?.avatar}
          className="options__name__avatar"
          inTable
        />
      ) : null}

      {user?.name}
      <Tooltip
        text={user?.role || ''}
        open={showTooltip}
        marginLeft="24px"
        capitalize
      />
    </div>
  );
};
