import React from 'react';

export const NotConnectedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.5026 4.00065C8.87079 4.00065 9.16927 4.29913 9.16927 4.66732V9.33398C9.16927 9.70217 8.87079 10.0007 8.5026 10.0007C8.13441 10.0007 7.83594 9.70217 7.83594 9.33398V4.66732C7.83594 4.29913 8.13441 4.00065 8.5026 4.00065Z"
      fill="#AD6E00"
    />
    <path
      d="M8.5026 12.334C8.96284 12.334 9.33594 11.9609 9.33594 11.5007C9.33594 11.0404 8.96284 10.6673 8.5026 10.6673C8.04237 10.6673 7.66927 11.0404 7.66927 11.5007C7.66927 11.9609 8.04237 12.334 8.5026 12.334Z"
      fill="#AD6E00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.83594 8.00065C1.83594 4.31875 4.82071 1.33398 8.5026 1.33398C12.1845 1.33398 15.1693 4.31875 15.1693 8.00065C15.1693 11.6825 12.1845 14.6673 8.5026 14.6673C4.82071 14.6673 1.83594 11.6825 1.83594 8.00065ZM8.5026 2.66732C5.55709 2.66732 3.16927 5.05513 3.16927 8.00065C3.16927 10.9462 5.55709 13.334 8.5026 13.334C11.4481 13.334 13.8359 10.9462 13.8359 8.00065C13.8359 5.05513 11.4481 2.66732 8.5026 2.66732Z"
      fill="#AD6E00"
    />
  </svg>
);
