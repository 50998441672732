import React, { useRef, useState } from 'react';
import { useClickOutside } from '../../../../../../hooks';
import { ChevronDownIcon } from './icons/ChevronDownIcon';
import { CheckedIcon } from './icons/CheckedIcon';
import {
  FilterSelectOption,
  FilterValue,
} from '../../../../../../types/meetingRecordsFilters.types';
import {
  isDateRangeFilterValue,
  isRangeFilterValue,
} from '../../../../../../store/meetingRecords/meetingRecords.utils';

export interface FilterSelectProps {
  selectedOption?: FilterSelectOption<FilterValue>;
  options: FilterSelectOption<FilterValue>[];
  onSelect: (option: FilterValue) => void;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
  selectedOption,
  options,
  onSelect,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const attrs = {
    container: {
      className: `filter-select${isExpanded ? ' filter-select--expanded' : ''}`,
      ref: containerRef,
    },
    field: {
      type: 'button' as const,
      className: 'filter-select__field',
      onClick: () => {
        setIsExpanded(!isExpanded);
      },
    },
    text: {
      className: 'filter-select__text',
    },
    fieldIcon: {
      className: 'filter-select__field-icon',
    },
    dropdown: {
      className: 'filter-select__dropdown',
    },
    checkedIcon: {
      className: 'filter-select__checked-icon',
    },
  };

  useClickOutside(containerRef, () => {
    setIsExpanded(false);
  });

  if (isRangeFilterValue(selectedOption?.value)) {
    return null;
  }

  const renderOptions = () =>
    options.map((option, index) => {
      const isSelected =
        isDateRangeFilterValue(option.value) &&
        isDateRangeFilterValue(selectedOption?.value)
          ? option.value.selectValue === selectedOption?.value.selectValue
          : option.value === selectedOption?.value;

      const props = {
        key: index,
        disabled: isSelected,
        className: 'filter-select__option',
        onClick: () => {
          onSelect(option.value);
          setIsExpanded(false);
        },
      };

      return (
        <button {...props}>
          <span {...attrs.text}>{option.label}</span>
          {isSelected ? <CheckedIcon {...attrs.checkedIcon} /> : null}
        </button>
      );
    });

  return (
    <div {...attrs.container}>
      <button {...attrs.field}>
        <span {...attrs.text}>{selectedOption?.label || 'Select'}</span>
        <ChevronDownIcon {...attrs.fieldIcon} />
      </button>
      <div {...attrs.dropdown}>{renderOptions()}</div>
    </div>
  );
};
