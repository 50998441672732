import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { QActivitySkeleton } from '../../skeletons/QActivitySkeleton';

type Data = {
  date: string;
  analyzed: number;
  simulated: number;
}[];

type HistoricalData = {
  date: string;
  analyzes: number;
  simulations: number;
}[];

type Props = {
  dataQ?: Data;
  historicalDataQ?: HistoricalData;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
};

export const QActivityChart: React.FC<Props> = ({
  dataQ,
  historicalDataQ,
  isLoading,
  noData,
  error,
}): JSX.Element => {
  const [data, setData] = useState<Data>([]);

  const [historicalData, setHistoricalData] = useState<HistoricalData>([]);

  const [lineHovered, setLineHovered] = useState<'a' | 's' | ''>('');

  useEffect(() => {
    if (dataQ?.length) {
      // const customData: Data = []; // eslint-disable-next-line
      // for (let i = 0; i < 20; i++) {
      //   customData.push({
      //     date: `Jan ${i + 1}`,
      //     analyzed: Math.floor(Math.random() * 15),
      //     simulated: Math.floor(Math.random() * 10),
      //   });
      // }
      // setData(customData);
      setData(dataQ);
      if (historicalDataQ?.length) {
        setHistoricalData(historicalDataQ);
      }
    } // eslint-disable-next-line
  }, [dataQ?.length, historicalDataQ?.length]);

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
    },
    date: {
      className: 'chart-tooltip__date-on-top',
    },
    analyzedSection: {
      wrapper: {
        className: 'chart-tooltip__multi-line',
      },
      border: {
        className: 'chart-tooltip__multi-line-left-border',
        style: {
          backgroundColor: '#4EB4FF',
        },
      },
      body: {
        className: 'chart-tooltip__multi-line__body',
      },
    },
    simulatedSection: {
      wrapper: {
        className: 'chart-tooltip__multi-line',
      },
      border: {
        className: 'chart-tooltip__multi-line-left-border',
        style: {
          backgroundColor: '#365798',
        },
      },
      body: { className: 'chart-tooltip__multi-line__body' },
    },
    label: {
      className: 'chart-tooltip__multi-line__label',
    },
    emailsQty: {
      className: 'chart-tooltip__value',
    },
    emailsText: {
      className: 'chart-tooltip__value-text',
    },
    bluePercents: {
      className: 'chart-tooltip__percents-blue',
    },
    persentageValuetext: {
      className: 'chart-tooltip__percents-text',
    },
    boldText: {
      className: 'chart-tooltip__percents-text-bold',
    },
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    const emailsQtyValue = (emails: string | number) => {
      if (emails && typeof +emails === 'number' && !Number.isNaN(emails)) {
        return (
          <span {...tooltipAttrs.emailsQty}>
            {emails}{' '}
            <span {...tooltipAttrs.emailsText}>
              {+emails === 1 ? 'message' : 'messages'}
            </span>
          </span>
        );
      }
      return (
        <span {...tooltipAttrs.emailsQty}>
          0<span {...tooltipAttrs.emailsText}> messages</span>
        </span>
      );
    };

    const conditionalPercentageMessage = (percents: string | number) => {
      let resultMessage: string | JSX.Element = '';
      if (percents === 0 || percents === 'N/A' || percents === 'NaN') {
        resultMessage = (
          <div {...tooltipAttrs.emailsText}>
            <span {...tooltipAttrs.boldText}>N/A </span>
            from previous day
          </div>
        );
      } else if (percents === 'same') {
        resultMessage = (
          <div {...tooltipAttrs.emailsText}>
            <span {...tooltipAttrs.bluePercents}>0% </span>
            from previous day
          </div>
        );
      } else {
        const valueWithPlus = +percents > 0 ? `+${percents}` : percents;

        resultMessage = (
          <div {...tooltipAttrs.bluePercents}>
            {valueWithPlus}%{' '}
            <span {...tooltipAttrs.persentageValuetext}>from previous day</span>
          </div>
        );
      }
      return resultMessage;
    };

    const getPercentageComparison = (
      currentDayIndex: number,
      today: number,
      yesterday: number
    ) => {
      let resultToShow: string | number = '';
      if (currentDayIndex === 0 && !historicalData?.length) {
        resultToShow = 'N/A';
      } else if (today !== 0 && today === yesterday) {
        resultToShow = 'same';
      } else if (yesterday === 0 || today === 0) {
        resultToShow = 0;
      } else {
        resultToShow = ((today / yesterday) * 100 - 100).toFixed(0);
      }
      return resultToShow;
    };

    if (active && data.length && !noData && !error) {
      const currentDayIndex = data.map((el) => el).indexOf(payload[0].payload);
      const previousDayData =
        data[currentDayIndex !== 0 ? currentDayIndex - 1 : 0];

      const { date } = payload[0].payload;

      const analyzedToday = payload[1].payload.analyzed;

      const analyzedYesterday =
        currentDayIndex === 0 && historicalData?.length
          ? historicalData[0].analyzes
          : +previousDayData.analyzed;

      const analyzedPercents: string | number = getPercentageComparison(
        currentDayIndex,
        analyzedToday,
        analyzedYesterday
      );

      const simulatedToday = payload[0].payload.simulated;

      const simulatedYesterday =
        currentDayIndex === 0 && historicalData?.length
          ? historicalData[0].simulations
          : +previousDayData.simulated;

      const simulatedPercents = getPercentageComparison(
        currentDayIndex,
        simulatedToday,
        simulatedYesterday
      );

      const formattedDate = moment(date).format('ddd, MMM D, YYYY');

      const analyzedValue = emailsQtyValue(analyzedToday);

      const analyzedFooterMessage = conditionalPercentageMessage(
        analyzedPercents
      );

      const simulatedValue = emailsQtyValue(simulatedToday);

      const simulatedFooterMessage = conditionalPercentageMessage(
        simulatedPercents
      );

      result = (
        <div {...tooltipAttrs.wrapper}>
          <span {...tooltipAttrs.date}>{formattedDate}</span>
          <div {...tooltipAttrs.analyzedSection.wrapper}>
            <div {...tooltipAttrs.analyzedSection.border} />
            <div {...tooltipAttrs.analyzedSection.body}>
              <span {...tooltipAttrs.label}>Analyzed Messages</span>
              {analyzedValue}
              {analyzedFooterMessage}
            </div>
          </div>

          <div {...tooltipAttrs.simulatedSection.wrapper}>
            <div {...tooltipAttrs.simulatedSection.border} />
            <div {...tooltipAttrs.simulatedSection.body}>
              <span {...tooltipAttrs.label}>Simulated Messages</span>
              {simulatedValue}
              {simulatedFooterMessage}
            </div>
          </div>
        </div>
      );
    }
    return result;
  };

  const formatter = (value: number) => {
    let formattedTicks = value >= 1 ? `${value}` : '0';
    if (formattedTicks.length === 4 && +formattedTicks > 1) {
      const formtdNumb = +formattedTicks * 0.001;
      formattedTicks =
        formtdNumb.toString().length < 3
          ? `${formtdNumb.toFixed(0)}k`
          : `${formtdNumb.toFixed(1)}k`;
    } else if (formattedTicks.length === 5) {
      const formtdNumb = +formattedTicks * 0.001;
      const splitedValues = formtdNumb?.toString()?.split('.');
      formattedTicks =
        (splitedValues[1]?.length < 5 && splitedValues[1]?.includes('0')) ||
        formtdNumb.toString().length === 2
          ? `${formtdNumb.toFixed(0)}k`
          : `${formtdNumb.toFixed(1)}k`;
    }
    return formattedTicks;
  };

  const defineLinesColors = () => {
    let analyzeLineColor = '#4EB4FF';

    let simulateLineColor = '#365798';

    if (noData || error) {
      analyzeLineColor = 'rgba(0, 0, 0, 0)';

      simulateLineColor = 'rgba(0, 0, 0, 0)';
    } else if (lineHovered === 'a') {
      analyzeLineColor = '#2F86C7';
    } else if (lineHovered === 's') {
      simulateLineColor = '#243F73';
    }

    return { analyzeLineColor, simulateLineColor };
  };

  const { analyzeLineColor, simulateLineColor } = defineLinesColors();

  const attrs = {
    chartWrapper: {
      title: 'Recent Activity',
      subtitle: `Last ${data?.length - 1} days`,
      tooltipText: `The number of messages you analyzed and/or 
        simulated per day in the selected time period`,
      isNoData: noData || error,
      isSoundWave: true,
    },
    legendWrapper: {
      className: 'charts__qactivity',
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    legendAnalyzed: {
      className: 'charts__qactivity-analyzed',
    },
    legendSimulated: {
      className: 'charts__qactivity-simulated',
    },
    responsiveContainer: {
      height: '100%',
    },
    chart: {
      lineChart: {
        width: 295,
        height: 220,
        data,
        margin: { top: 30, right: 0, left: -28, bottom: -5 },
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      cartesianGrid: {
        strokeDasharray: '0 0',
        vertical: false,
        stroke: '#F8F8FB',
        strokeWidth: 1,
      },
      xAxis: {
        dataKey: 'date',
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 8,
        tickLine: { stroke: '#F8F8FB' },
        axisLine: { stroke: '#F8F8FB' },
        tickMargin: 9,
        padding: { right: 5, left: 15 },
        interval: 0,
        tickFormatter: (str: string, i: number) => {
          const desiredIndex = data?.length / 5 || 1;
          if (i % Math.ceil(desiredIndex) === 0) {
            return moment(str).format('MMM D');
          }
          return '';
        },
      },
      yAxis: {
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 0,
        tickLine: false,
        axisLine: false,
        tickMargin: 10,
        tickFormatter: formatter,
      },
      tooltip: {
        wrapperStyle: { top: '-210px', left: '-107px' },
        content: <CustomTooltip payload={data} active />,
        allowEscapeViewBox: { x: true, y: true },
        isAnimationActive: false,
        cursor: !noData && !error,
      },
      lineAnalyzed: {
        dataKey: 'analyzed',
        stroke: analyzeLineColor,
        strokeWidth: 2,
        dot: false,
        activeDot: {
          strokeWidth: 2,
          r: 4,
          onMouseEnter: () => setLineHovered('a'),
          onMouseLeave: () => setLineHovered(''),
        },
        style: { filter: 'drop-shadow(1px 2px 2px rgba(36, 45, 81, 0.3))' },
        onMouseEnter: () => setLineHovered('a'),
        onMouseLeave: () => setLineHovered(''),
      },
      lineSimulated: {
        dataKey: 'simulated',
        stroke: simulateLineColor,
        strokeWidth: 2,
        dot: false,
        activeDot: {
          strokeWidth: 2,
          r: 4,
          onMouseEnter: () => setLineHovered('s'),
          onMouseLeave: () => setLineHovered(''),
        },
        style: { filter: 'drop-shadow(1px 2px 2px rgba(78, 180, 255, 0.3))' },
        onMouseEnter: () => setLineHovered('s'),
        onMouseLeave: () => setLineHovered(''),
      },
    },
  };

  return (
    <QActivitySkeleton isLoading={isLoading}>
      <ChartWrapper {...attrs.chartWrapper}>
        <>
          <div {...attrs.legendWrapper}>
            <span {...attrs.legendAnalyzed}>Analyzed messages</span>
            <span {...attrs.legendSimulated}>Simulated messages</span>
          </div>
          <ResponsiveContainer {...attrs.responsiveContainer}>
            <LineChart {...attrs.chart.lineChart}>
              <CartesianGrid {...attrs.chart.cartesianGrid} />
              <XAxis {...attrs.chart.xAxis} />
              <YAxis {...attrs.chart.yAxis} />
              <Tooltip {...attrs.chart.tooltip} />
              <Line {...attrs.chart.lineSimulated} type="monotone" />
              <Line {...attrs.chart.lineAnalyzed} type="monotone" />
            </LineChart>
          </ResponsiveContainer>
        </>
      </ChartWrapper>
    </QActivitySkeleton>
  );
};
