import React, { useEffect } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { VideoPlayer } from './components/VideoPlayer/VideoPlayer';
import { MeetingAnalysis } from './components/MeetingAnalysis/MeetingAnalysis';
import { MeetingSummary } from './components/MeetingSummary/MeetingSummary';
import { EventFrame } from '../../components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getMeetingDetails,
  updateMeetingTitle,
} from '../../store/meetingDetails/meetingDetails.thunks';
import VideoPlayerContextProvider from './context/VideoPlayerContext';
import { PATHS } from '../../constants/paths';
import { clearMeetingDetails } from '../../store/meetingDetails/meetingDetails.slice';
import {
  useGetRelationshipAccountDetails,
  useTrackPageView,
} from '../../hooks';
import { getMeetingSourceIcon } from '../../utils/getMeetingSourceIcon';
import { EventPagination } from '../../components/EventPagination/EventPagination';

export const MeetingDetails: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const isMeetingDetailsLoading = useAppSelector(
    (state) => state.meetingDetails.isLoading.meetingDetails
  );
  const isMeetingDetailsError = useAppSelector(
    (state) => state.meetingDetails.isError.meetingDetails
  );
  const meetingDetails = useAppSelector((state) => state.meetingDetails.data);

  const history = useHistory();

  const meetingRecordMatch = useRouteMatch(PATHS.MEETING_RECORD);

  const { meetingId } = useParams<{
    meetingId: string;
  }>();

  const { title: meetingTitle, account, platform } = meetingDetails || {};

  const MeetingSourceIcon = getMeetingSourceIcon(platform);

  const attrs = {
    container: {
      className: 'meeting-details',
    },
    eventFrame: {
      headerProps: {
        isLoading:
          !meetingDetails || isMeetingDetailsLoading || isMeetingDetailsError,
        account: account || null,
        eventId: meetingId,
        showAccount: true,
        rightIcon: MeetingSourceIcon,
        titleProps: {
          value: meetingTitle || 'No Meeting Title',
          onChange: (value: string) => {
            dispatch(updateMeetingTitle(value));
          },
        },
      },
      leftContent: <VideoPlayer />,
      rightContent: (
        <>
          <MeetingAnalysis />
          <MeetingSummary />
        </>
      ),
    },
  };

  useGetRelationshipAccountDetails();
  useTrackPageView([meetingId]);

  useEffect(() => {
    const request = dispatch(getMeetingDetails(meetingId));

    return () => {
      request.abort();
      dispatch(clearMeetingDetails());
    };
  }, [dispatch, meetingId]);

  useEffect(() => {
    if (meetingRecordMatch && account) {
      history.replace(
        `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${account.accountId}/meetings/${meetingId}`
      );
    }
  }, [meetingRecordMatch, account, user, history, meetingId]);

  return (
    <div {...attrs.container}>
      <VideoPlayerContextProvider>
        <EventFrame {...attrs.eventFrame} />
      </VideoPlayerContextProvider>
      {account ? <EventPagination /> : null}
    </div>
  );
};
