import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { linkIcon } from './assest';

type LabelProps = {
  label?: string;
  value?: React.ReactNode;
  coloredValue?: boolean;
  valueLink?: string;
  linkInNewTab?: boolean;
  rowHeight?: string;
  onAction?: () => void;
  labelColor?: string;
  labelFontSize?: string;
  labelFontWeight?: number;
  valueColor?: string;
  valueFontSize?: string;
  valueFontWeight?: number;
  hidden?: boolean;
  minWidth?: string;
  hoverAndLinkClickEverywhere?: boolean;
  withFadeInAnimation?: boolean;
};

const LabelValueRow: React.FC<LabelProps> = ({
  label = '',
  value = '',
  coloredValue = false,
  valueLink = '',
  linkInNewTab = false,
  rowHeight = '47px',
  onAction = () => {},
  labelColor,
  labelFontSize,
  labelFontWeight,
  valueColor,
  valueFontSize,
  valueFontWeight,
  hidden,
  minWidth,
  hoverAndLinkClickEverywhere = false,
  withFadeInAnimation = false,
}: LabelProps): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(0);

  const history = useHistory();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const labelRef = useRef<HTMLDivElement>(null);

  const valueWrapRef = useRef<HTMLDivElement>(null);

  const valueTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateScreenWidth = () => {
      if (typeof screenWidth === 'number' && window.innerWidth) {
        setScreenWidth(window.innerWidth);
      }
    };

    if (screenWidth === 0) {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    }; // eslint-disable-next-line
  }, []);

  const defineValueCss = () => {
    let hasTooltip = false;

    let valueWrapperWidth = '100%';

    if (
      valueWrapRef?.current?.offsetWidth &&
      valueTextRef?.current?.offsetWidth
    ) {
      if (
        valueWrapRef.current.offsetWidth ===
        valueTextRef.current.offsetWidth + 20
      ) {
        hasTooltip = true;
      }
    }

    if (wrapperRef?.current?.offsetWidth && labelRef?.current?.offsetWidth) {
      valueWrapperWidth = `${
        wrapperRef.current.offsetWidth - (labelRef.current.offsetWidth + 15)
      }px`;
    }

    const valueClassName = `${hasTooltip ? 'cuted' : ''} ${
      coloredValue && value === 'Active'
        ? 'active'
        : `${value === 'Inactive' ? 'inactive' : ''}`
    } ${valueLink?.length ? 'link' : ''} lv-row__value-text`;

    return { hasTooltip, valueWrapperWidth, valueClassName };
  };

  const { hasTooltip, valueWrapperWidth, valueClassName } = defineValueCss();

  const goToLinkUrl = () => {
    if (valueLink?.length) {
      if (!linkInNewTab) {
        history.push(valueLink);
        onAction();
      } else {
        window.open(encodeURI(valueLink), '_blank');
      }
    }
  };

  const clickLinkEverywhere = (e: React.MouseEvent) => {
    if (hoverAndLinkClickEverywhere) {
      e.stopPropagation();
      goToLinkUrl();
    }
  };

  const attrs = {
    wrapper: {
      className: `${hoverAndLinkClickEverywhere ? 'all-active ' : ''}${
        withFadeInAnimation ? 'with-fade-in ' : ''
      }lv-row`,
      ref: wrapperRef,
      style: {
        height: rowHeight,
        minWidth,
        display: hidden ? 'none' : 'flex',
      },
      onClick: clickLinkEverywhere,
    },

    label: {
      wrapper: {
        className: 'lv-row__label',
        ref: labelRef,
      },

      text: {
        className: 'lv-row__label-text',
        style: {
          color: labelColor,
          fontSize: labelFontSize,
          fontWeight: labelFontWeight,
        },
      },
    },

    valueSection: {
      wrapper: {
        className: 'lv-row__value',
        ref: valueWrapRef,
        style: {
          width: valueWrapperWidth,
        },
      },

      value: {
        className: valueClassName,
        ref: valueTextRef,
        onClick: goToLinkUrl,
        style: {
          color: valueColor,
          fontSize: valueFontSize,
          fontWeight: valueFontWeight,
        },
      },

      linkIcon: {
        src: linkIcon,
        className: 'lv-row__value-text-link-icon',
      },

      tooltip: {
        className: `${hasTooltip ? '' : 'hidden'} lv-row__value-tooltip`,
      },
    },
  };

  const valueLinkIcon = valueLink?.length ? (
    <img {...attrs.valueSection.linkIcon} alt="" />
  ) : null;

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.label.wrapper}>
        <div {...attrs.label.text}>{label}</div>
      </div>
      <div {...attrs.valueSection.wrapper}>
        <div {...attrs.valueSection.value}>
          {value}
          {valueLinkIcon}
        </div>

        <div {...attrs.valueSection.tooltip}>{value}</div>
      </div>
    </div>
  );
};

export default LabelValueRow;
