import React from 'react';

export const PlayIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M6 8.00847C6 4.98689 9.2216 3.05629 11.8862 4.48109L24.4592 11.2042C26.2286 12.1503 27.3333 13.9938 27.3333 16.0003C27.3333 18.0068 26.2286 19.8503 24.4592 20.7964L11.8862 27.5195C9.2216 28.9443 6 27.0137 6 23.9921V8.00847Z"
      fill="white"
    />
  </svg>
);
