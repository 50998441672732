import React from 'react';

export const ChevronLeftIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M10.8536 3.14645C11.0488 3.34171 11.0488 3.65829 10.8536 3.85355L6.70711 8L10.8536 12.1464C11.0488 12.3417 11.0488 12.6583 10.8536 12.8536C10.6583 13.0488 10.3417 13.0488 10.1464 12.8536L5.64645 8.35355C5.45118 8.15829 5.45118 7.84171 5.64645 7.64645L10.1464 3.14645C10.3417 2.95118 10.6583 2.95118 10.8536 3.14645Z"
      fill="#18222F"
    />
  </svg>
);
