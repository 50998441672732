import React from 'react';

export interface EventSummaryItemProps {
  title: string;
  children?: React.ReactNode;
}

export const EventSummaryItem: React.FC<EventSummaryItemProps> = ({
  title,
  children,
}) => {
  const attrs = {
    container: {
      className: 'event-summary-item',
    },
    title: {
      className: 'event-frame__content-subtitle',
    },
    content: {
      className: 'event-summary-item__content',
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.title}>{title}</div>
      <div {...attrs.content}>{children}</div>
    </div>
  );
};
