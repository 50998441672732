import moment from 'moment';
import React from 'react';
import { Avatar } from '../../../../../../components';

type Props = {
  email?: string;
  name?: string;
  avatar?: string;
  emailsQty?: number;
  lastEmailDate?: string;
  isEmpty?: boolean;
};

export const MostCompetentRow: React.FC<Props> = ({
  email,
  name,
  avatar,
  emailsQty,
  lastEmailDate,
  isEmpty,
}): JSX.Element => {
  const attrs = {
    wrapper: {
      className: 'most-comt',
      style: isEmpty ? { borderBottom: '1px solid #ebeef3' } : {},
    },

    nameSection: {
      wrapper: {
        className: 'most-comt__user',
      },
      avatar: {
        userName: name,
        image: avatar,
        isTwentyPx: true,
      },
      name: {
        className: 'most-comt__user-name',
      },
      tooltip: {
        className: 'most-comt__user__tooltip',
      },
      tooltipName: {
        className: 'most-comt__user__tooltip-text',
      },
      tooltipEmail: {
        className: 'most-comt__user__tooltip-text-blue',
      },
    },

    numberOfEmails: {
      className: 'most-comt-emails',
    },

    lastEmailAt: {
      className: 'most-comt-date',
    },
  };

  const lastEmaitSentAt = lastEmailDate
    ? moment(lastEmailDate).format('MMM DD, YYYY')
    : '';

  const tooltip = (
    <div {...attrs.nameSection.tooltip}>
      <span {...attrs.nameSection.tooltipName}>{name}</span>
      <span {...attrs.nameSection.tooltipEmail}>
        {'<'}
        {email}
        {'>'}
      </span>
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      {isEmpty ? null : (
        <>
          <div {...attrs.nameSection.wrapper}>
            <Avatar {...attrs.nameSection.avatar} />
            <div {...attrs.nameSection.name}>{name}</div>
            {tooltip}
          </div>
          <div {...attrs.numberOfEmails}>{emailsQty}</div>
          <div {...attrs.lastEmailAt}>{lastEmaitSentAt}</div>
        </>
      )}
    </div>
  );
};
