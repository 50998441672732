import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  duration: number;
  globalLoading: boolean;
  children?: JSX.Element | string;
}

const SummarySkeleton: React.FC<Props> = (props: Props) => {
  const { duration, globalLoading, children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    const summaryTimer = setTimeout(() => {
      setIsLoading(false);
    }, duration);
    return () => {
      clearTimeout(summaryTimer);
    };
    // eslint-disable-next-line
  }, [globalLoading]);

  const attrs = {
    wrapper: {
      className:
        'personalQ__secondColumn-overallcompetence-summary__skeleton-block',
    },
    skeleton: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
    },
  };

  return (
    <div {...attrs.wrapper}>
      {isLoading ? (
        <>
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: 56,
                height: 16,
                position: 'absolute',
                top: '-8%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: '100%',
                height: 16,
                position: 'absolute',
                top: '25%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: '100%',
                height: 16,
                position: 'absolute',
                top: '60%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: '100%',
                height: 16,
                position: 'absolute',
                top: '95%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: '100%',
                height: 16,
                position: 'absolute',
                top: '130%',
                borderRadius: 30,
              },
            }}
          />
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default SummarySkeleton;
