import React from 'react';
import { CompetentWith } from '../../../../../store/charts/charts.types';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { QActivitySkeleton } from '../../skeletons/QActivitySkeleton';
import { MostCompetentRow } from '../mostCompetent/mostCompRow/MostCompetentRow';

type Props = {
  data: CompetentWith[] | null;
  lastDays?: number;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
};

export const LeastCompetent: React.FC<Props> = ({
  data,
  lastDays,
  isLoading,
  noData,
  error,
}): JSX.Element => {
  const attrs = {
    chartWrapper: {
      title: 'Least Authoritative with',
      subtitle: `Last ${lastDays ? lastDays - 1 : ''} days`,
      tooltipText: `People outside of your organization whom you are least authoritative with, 
      on average, during the selected time period.`,
      isNoData: noData || error,
    },

    wrapper: {
      className: 'charts__most-competent',
      style: noData || error ? { opacity: 0.4 } : {},
    },
    header: {
      wrapper: {
        className: 'charts__most-competent__header',
      },
      name: {
        className: 'charts__most-competent__header-name',
      },
      emailsQty: {
        className: 'charts__most-competent__header-emails',
      },
      lastEmailAt: {
        className: 'charts__most-competent__header-date',
      },
    },
  };

  const emptyRows = (
    <>
      <MostCompetentRow isEmpty />
      <MostCompetentRow isEmpty />
      <MostCompetentRow isEmpty />
      <MostCompetentRow isEmpty />
    </>
  );

  const peopleToShow = data?.length
    ? data
        .sort((a, b) => b.numberOfEmails - a.numberOfEmails)
        .map((el) => (
          <MostCompetentRow
            key={el.email}
            name={el?.metadata?.name}
            avatar={el?.metadata?.avatarSrc}
            email={el?.email}
            emailsQty={el?.numberOfEmails}
            lastEmailDate={el?.lastInteractionTs}
          />
        ))
    : emptyRows;

  return (
    <QActivitySkeleton isLoading={isLoading}>
      <ChartWrapper {...attrs.chartWrapper}>
        <div {...attrs.wrapper}>
          <div {...attrs.header.wrapper}>
            <div {...attrs.header.name}>Name</div>
            <div {...attrs.header.emailsQty}># of Interactions</div>
            <div {...attrs.header.lastEmailAt}>Last Interaction</div>
          </div>
          {peopleToShow}
        </div>
      </ChartWrapper>
    </QActivitySkeleton>
  );
};
