import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { InfoIcon } from '../../../Icons/InfoIcon';
import { PADDING_OFFSET } from './constants/tooltipConstants';
import { ArrowIcon } from './icons/ArrowIcon';
import { getCurrentPosition } from './utils/getCurrentPosition';
import { getPositionOffsetStyles } from './utils/getPositionOffsetStyles';
import { getTooltipContentPosition } from './utils/getTooltipContentPosition';

export enum TooltipPosition {
  TOP = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  RIGHT = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  BOTTOM = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  LEFT = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
}

export enum TooltipSize {
  M = 'm',
}

export interface TooltipProps {
  children?: React.ReactNode;
  id?: string;
  position?: TooltipPosition;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  text?: React.ReactNode;
  title?: React.ReactNode;
  width?: number;
  simplified?: boolean;
  dark?: boolean;
  forcePositionUpdate?: boolean;
  className?: string;
  contentClassName?: string;
  size?: TooltipSize;
  delay?: number;
  useCursorPosition?: boolean;
  positionOffset?: number;
  autoPosition?: boolean;
  buttonProps?: {
    label: string;
    onClick: () => void;
  };
}
interface TooltipContentProps
  extends Omit<TooltipProps, 'icon' | 'delay' | 'useCursorPosition'> {
  isVisible: boolean;
  parentRef: React.RefObject<HTMLDivElement>;
  cursorPosition?: { x: number; y: number };
}

const isTopOrBottomPosition = (position: TooltipPosition) =>
  position === TooltipPosition.TOP ||
  position === TooltipPosition.TOP_START ||
  position === TooltipPosition.TOP_END ||
  position === TooltipPosition.BOTTOM ||
  position === TooltipPosition.BOTTOM_START ||
  position === TooltipPosition.BOTTOM_END;

const TooltipContent: React.FC<TooltipContentProps> = ({
  id,
  width,
  title,
  text,
  parentRef,
  contentClassName,
  simplified = false,
  dark = false,
  forcePositionUpdate = false,
  position: positionProp = TooltipPosition.TOP,
  isVisible: isVisibleProp,
  size,
  buttonProps,
  cursorPosition,
  positionOffset = 8,
  autoPosition = false,
}) => {
  const [isVisible, setIsVisible] = useState(isVisibleProp);
  const [position, setPosition] = useState(positionProp);

  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const attrs = {
    contentWrapper: {
      id,
      ref: contentWrapperRef,
      role: 'tooltip',
      className: `sound-wave-tooltip__content-wrapper sound-wave-tooltip__content-wrapper--${position}${
        simplified ? ' sound-wave-tooltip__content-wrapper--simplified' : ''
      }${dark ? ' sound-wave-tooltip__content-wrapper--dark' : ''}${
        size ? ` sound-wave-tooltip__content-wrapper--${size}` : ''
      }${
        !isVisibleProp ? ' sound-wave-tooltip__content-wrapper--closing' : ''
      }${contentClassName ? ` ${contentClassName}` : ''}`,
      onAnimationEnd: () => {
        if (!isVisibleProp) {
          setIsVisible(false);
          setPosition(positionProp);
        }
      },
      style: {
        ...(typeof width === 'number'
          ? {
              width: isTopOrBottomPosition(position)
                ? width
                : width + PADDING_OFFSET,
            }
          : {}),
        ...getPositionOffsetStyles(position, positionOffset),
      },
    },
    content: {
      className: 'sound-wave-tooltip__content',
    },
    text: {
      className: 'sound-wave-tooltip__text',
    },
    title: {
      className: 'sound-wave-tooltip__title',
    },
    action: {
      type: 'button' as const,
      className: 'sound-wave-tooltip__action',
      onClick: () => {
        buttonProps?.onClick();
      },
    },
    arrowIcon: {
      dark,
      position,
      className: 'sound-wave-tooltip__arrow-icon',
    },
  };

  const handleTooltipPosition = useCallback(() => {
    const contentWrapperRefCurrent = contentWrapperRef.current;
    const parentRefCurrent = parentRef.current;

    if (isVisible && contentWrapperRefCurrent && parentRefCurrent) {
      const transformStyle = getTooltipContentPosition(
        contentWrapperRefCurrent,
        parentRefCurrent,
        position,
        simplified,
        cursorPosition
      );

      contentWrapperRefCurrent.style.transform = transformStyle;
    }
  }, [parentRef, isVisible, position, simplified, cursorPosition]);

  useEffect(() => {
    if (isVisibleProp) {
      setIsVisible(true);
    }
  }, [isVisibleProp]);

  useEffect(() => {
    const contentWrapperRefCurrent = contentWrapperRef.current;

    if (isVisible && contentWrapperRefCurrent) {
      window.requestAnimationFrame(() => {
        setPosition(
          getCurrentPosition(
            contentWrapperRefCurrent,
            positionProp,
            autoPosition
          )
        );
      });
    }
  }, [autoPosition, isVisible, positionProp]);

  useEffect(() => {
    handleTooltipPosition();
  }, [handleTooltipPosition]);

  useEffect(() => {
    const handler = () => {
      window.requestAnimationFrame(handleTooltipPosition);
    };

    document.addEventListener('scroll', handler, true);

    return () => {
      document.removeEventListener('scroll', handler, true);
    };
  }, [handleTooltipPosition]);

  useLayoutEffect(() => {
    if (forcePositionUpdate) {
      handleTooltipPosition();
    }
  });

  if (!isVisible) {
    return null;
  }

  return createPortal(
    <div {...attrs.contentWrapper}>
      <div {...attrs.content}>
        {title ? <div {...attrs.title}>{title}</div> : null}
        {text ? <div {...attrs.text}>{text}</div> : null}
        {buttonProps ? (
          <button {...attrs.action}>{buttonProps.label}</button>
        ) : null}
        <ArrowIcon {...attrs.arrowIcon} />
      </div>
    </div>,
    document.body
  );
};

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  id,
  position,
  icon,
  text,
  title,
  width,
  simplified,
  dark,
  forcePositionUpdate,
  className,
  contentClassName,
  size,
  buttonProps,
  delay = 0,
  useCursorPosition,
  positionOffset,
  autoPosition,
}) => {
  const IconComponent = icon || InfoIcon;

  const [isVisible, setIsVisible] = useState(false);
  const [cursorPosition, setCursorPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const parentRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<number | null>(null);

  const attrs = {
    container: {
      ref: parentRef,
      className: `sound-wave-tooltip${
        isVisible ? ' sound-wave-tooltip--opened' : ''
      }${className ? ` ${className}` : ''}`,
      onMouseEnter: (e: React.MouseEvent) => {
        timeoutRef.current = window.setTimeout(() => {
          if (useCursorPosition) {
            setCursorPosition({ x: e.clientX, y: e.clientY });
          }

          setIsVisible(true);
        }, delay);
      },
      onMouseLeave: () => {
        if (typeof timeoutRef.current === 'number') {
          window.clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }

        setIsVisible(false);
      },
    },
    icon: {
      className: 'sound-wave-tooltip__icon',
    },
    tooltipContent: {
      id,
      width,
      title,
      text,
      position,
      simplified,
      dark,
      forcePositionUpdate,
      isVisible,
      parentRef,
      contentClassName,
      size,
      buttonProps,
      positionOffset,
      autoPosition,
      ...(cursorPosition ? { cursorPosition } : {}),
    },
  };

  return (
    <div {...attrs.container}>
      {children}
      {!children ? <IconComponent {...attrs.icon} /> : null}
      <TooltipContent {...attrs.tooltipContent} />
    </div>
  );
};
