import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { SellingZoneSkeleton } from '../../skeletons/SellingZoneSkeleton';

type Data = {
  userSide: number;
  buyerSide: number;
  // otherSide: number;
};

type Props = {
  participantsData?: Data;
  lastDays: number | null;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
};

type LocalData = {
  label: string;
  value: number;
  fill: string;
}[];

export const AvgNumOfPartics: React.FC<Props> = ({
  participantsData,
  lastDays,
  isLoading,
  noData,
  error,
}): JSX.Element => {
  const [data, setData] = useState<LocalData>([]);

  const [activeBarIndex, setActiveBarIndex] = useState<number | null>(null);

  const [totalParts, setTotalParts] = useState<number>(0);

  useEffect(() => {
    if (participantsData) {
      const {
        userSide: user,
        buyerSide: prospect,
        // otherSide: other,
      } = participantsData;

      const roundTenth = (value: number) => {
        let resultValue = value;
        if (value && value.toString().includes('.')) {
          const integer = value.toString().split('.')[0];

          const tenth = value.toString().split('.')[1].slice(0, 1);

          if (+tenth >= 1) {
            resultValue = +`${integer}.${tenth}`;
          } else resultValue = +integer;
        }
        return resultValue;
      };

      const dataToSet: LocalData = [
        {
          label: 'My side',
          value: roundTenth(user),
          fill: '#4EB4FF',
        },
        {
          label: 'Prospect side',
          value: roundTenth(prospect),
          fill: '#365798',
        },
      ];

      const total =
        user < 0.5 && prospect < 0.5
          ? 0.5
          : Math.ceil(Math.max(user, prospect));

      setTotalParts(total);

      setData(dataToSet);
    }
  }, [participantsData]);

  const onBarOver = (mess: string, index: number) => {
    setActiveBarIndex(index);
  };

  const onBarOut = () => {
    if (activeBarIndex !== null && activeBarIndex > -1) {
      setActiveBarIndex(null);
    }
  };

  const defineTooltipBorderColor = () => {
    let border = '';
    if (activeBarIndex === 0) {
      border = '#4EB4FF';
    } else if (activeBarIndex === 1) {
      border = '#365798';
    } else if (activeBarIndex === 2) {
      border = '#ACACB7';
    }
    return border;
  };

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
      style: { height: 'max-content', width: '154px' },
    },
    lastDays: {
      className: 'margin chart-tooltip__date-on-top',
    },
    body: {
      wrapper: {
        className: 'chart-tooltip__single-line',
      },
      border: {
        className: 'chart-tooltip__single-line-left-border',
        style: {
          backgroundColor: defineTooltipBorderColor(),
          height: '36px',
        },
      },
      contentSection: {
        className: 'chart-tooltip__single-line__body',
      },
    },
    value: {
      title: {
        className: 'chart-tooltip__label',
      },
      text: {
        className: 'participants chart-tooltip__value',
      },
      boldText: {
        className: 'chart-tooltip__value-bold',
      },
    },
  };

  // eslint-disable-next-line
  const CustomisedTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    if (active && data.length) {
      const place = payload[0]?.payload?.label;

      const value = payload[0]?.payload?.value;

      const valueToShow =
        value >= 100 && value.toString().includes('.')
          ? value.toString().split('.')[0]
          : value;

      const days = lastDays;

      const messageToShow =
        place && !Number.isNaN(place) ? (
          <div {...tooltipAttrs.body.contentSection}>
            <div {...tooltipAttrs.value.title}>{place}</div>
            <div {...tooltipAttrs.value.text}>
              {' '}
              <span {...tooltipAttrs.value.boldText}>
                {value < 0.1 ? 0 : valueToShow}{' '}
              </span>
              {value === 1 ? 'participant' : 'participants'} (avg.)
            </div>
          </div>
        ) : (
          <div {...tooltipAttrs.body.contentSection}>
            0<span {...tooltipAttrs.value.text}>-</span>
          </div>
        );

      result = (
        <div {...tooltipAttrs.wrapper}>
          <div {...tooltipAttrs.lastDays}>Last {days} days</div>
          <div {...tooltipAttrs.body.wrapper}>
            <div {...tooltipAttrs.body.border} />
            {messageToShow}
          </div>
        </div>
      );
    }
    return result;
  };

  const yTicks =
    totalParts === 0.5 && participantsData
      ? [0, 0.1, 0.2, 0.3, 0.4, 0.5]
      : [0, totalParts * 0.25, totalParts * 0.5, totalParts * 0.75, totalParts];

  const conditionalSubtitle = lastDays
    ? `Average, by side, last ${lastDays} days`
    : 'Average, by side';

  const attrs = {
    chartWrapper: {
      title: 'Number of Participants in Thread',
      subtitle: conditionalSubtitle,
      tooltipText: `The average number of participants in a deal-related thread, 
      by side, for the selected time period.`,
      isNoData: noData || error,
    },
    legendWrapper: {
      className: 'charts__avg-partics',
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    legendUser: { className: 'charts__avg-partics__user-legend' },
    legendProspect: { className: 'charts__avg-partics__prospect-legend' },
    legendOther: { className: 'charts__avg-partics__other-legend' },
    responsiveContainer: {
      height: '100%',
    },
    chart: {
      barChart: {
        width: 295,
        height: 220,
        data,
        margin: { top: 30, right: 0, left: -28, bottom: -7 },
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      cartesianGrid: {
        strokeDasharray: '0 0',
        vertical: false,
        stroke: '#F8F8FB',
        strokeWidth: 1,
      },
      xAxis: {
        dataKey: 'label',
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 0,
        tickLine: { stroke: '#F8F8FB' },
        axisLine: { stroke: '#F8F8FB' },
        tickMargin: 10,
        padding: { right: 5, left: 15 },
        interval: 0,
      },
      yAxis: {
        dataKey: 'value',
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        ticks: noData ? undefined : yTicks,
        tickSize: 0,
        tickLine: false,
        axisLine: false,
        tickMargin: 8,
        tickCount: totalParts === 0.5 ? 6 : 5,
        interval: 0,
        domain: noData ? ['auto'] : [0, totalParts],
      },
      tooltip: {
        wrapperStyle:
          activeBarIndex !== null && activeBarIndex > -1 && !noData && !error
            ? { top: '-114px', left: '-88px' }
            : { display: 'none' },
        content: <CustomisedTooltip payload={data} active />,
        allowEscapeViewBox: { x: true, y: true },
        isAnimationActive: false,
        cursor: false,
      },
      bar: {
        dataKey: 'value',
        maxBarSize: 25,
        isAnimationActive: true,
        onMouseEnter: onBarOver,
        onMouseLeave: onBarOut,
      },
    },
  };

  return (
    <SellingZoneSkeleton isLoading={isLoading}>
      <ChartWrapper {...attrs.chartWrapper}>
        <>
          <div {...attrs.legendWrapper}>
            <span {...attrs.legendUser}>My side</span>
            <span {...attrs.legendProspect}>Prospect side</span>
          </div>
          <ResponsiveContainer {...attrs.responsiveContainer}>
            <BarChart {...attrs.chart.barChart}>
              <CartesianGrid {...attrs.chart.cartesianGrid} />
              <XAxis {...attrs.chart.xAxis} />
              <YAxis {...attrs.chart.yAxis} />
              <Tooltip {...attrs.chart.tooltip} />
              <Bar {...attrs.chart.bar} radius={[4, 4, 0, 0]}>
                {data.map((el, index) => (
                  <Cell
                    filter={index === activeBarIndex ? 'brightness(85%)' : ''}
                    key={`cell-${index}${el}`}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      </ChartWrapper>
    </SellingZoneSkeleton>
  );
};
