import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../../store/store';
import LabelValueRow from '../../../../components/LabelValueRow/LabelValueRow';

export const OrgGeneralInfo: React.FC = (): JSX.Element => {
  const { myOrg } = useSelector((state: RootState) => state.org);

  const createdOn = moment(myOrg?.createdAt).format('MMM D, YYYY') || '';

  const owner = myOrg?.ownerName || '';

  const admins = myOrg?.adminNames?.length
    ? myOrg?.adminNames?.map((admin) => admin).join(', ')
    : 'There are no admins currently';

  const members = myOrg?.usersCount ? `${myOrg?.usersCount}` : '';

  const attrs = {
    wrapper: {
      className: 'gen-info',
    },

    header: {
      className: 'gen-info-header',
    },

    body: {
      wrapper: {
        className: 'gen-info__body',
      },

      rows: {
        CreatedOn: {
          label: 'Created on',
          value: createdOn,
          coloredValue: true,
        },

        Owner: {
          label: 'Owner',
          value: owner,
        },

        Admins: {
          label: 'Admins',
          value: admins,
        },

        Members: {
          label: 'Members',
          value: members,
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header}>General Information</div>
      <div {...attrs.body.wrapper}>
        <LabelValueRow {...attrs.body.rows.CreatedOn} />
        <LabelValueRow {...attrs.body.rows.Owner} />
        <LabelValueRow {...attrs.body.rows.Admins} />
        <LabelValueRow {...attrs.body.rows.Members} />
      </div>
    </div>
  );
};
