import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { RootState } from '../../../../../store/store';
import {
  addBot,
  createAccountAddBotFlow,
  getAccounts,
} from '../../../../../store/relationshipFlows/relationshipFlows.thunks';
import {
  closeAddBotModal,
  resetAddAccountStateABF,
  resetMeetingUrlFieldError,
  selectAccountAddBotFlow,
} from '../../../../../store/relationshipFlows/relationshipFlows.slice';
import { useMixpanel } from '../../../../../hooks';

type AddBotModalForm = {
  meetingUrl: string;
  meetingTitle: string;
  accountName: string;
  accountWebsite: string;
};

// eslint-disable-next-line
export const useAddBotModal = () => {
  const { selectedAccount, accounts } = useSelector(
    (state: RootState) => state.relatioshipFlows.addBot
  );

  const dispatch = useDispatch();

  const { trackEvent } = useMixpanel();

  const closeModal = () => {
    dispatch(closeAddBotModal());
  };

  const getAccountsForSelect = () => {
    dispatch(getAccounts());
  };

  const selectAccount = (accountId: string) => {
    const selectedAccountResult = accounts.find(
      (account) => account.accountId === accountId
    );

    if (selectedAccountResult) {
      dispatch(selectAccountAddBotFlow(selectedAccountResult));
    }
  };

  const createAccount = (name: string, websiteUrl: string) => {
    const data = {
      name,
      website: websiteUrl,
    };

    dispatch(createAccountAddBotFlow(data));

    trackEvent('createAccountForAssistant');
  };

  const resetAddBotState = () => {
    dispatch(resetAddAccountStateABF());
  };

  const resetSelectedAccount = () => {
    dispatch(selectAccountAddBotFlow(null));
  };

  const resetMeetingUrlError = () => {
    dispatch(resetMeetingUrlFieldError());
  };

  const handleSubmit = (values: AddBotModalForm) => {
    const dataForRequest = {
      title: values.meetingTitle,
      joinUrl: values.meetingUrl,
      accountId: selectedAccount?.accountId || '',
    };

    trackEvent('inviteAssistant');

    dispatch(addBot(dataForRequest));
  };

  const validationSchema = Yup.object().shape({
    meetingUrl: Yup.string().url('Wrong link').required('Please enter URL'),
    meetingTitle: Yup.string()
      .required('*Required field')
      .typeError('This is a required field')
      .matches(/[a-zA-Z+]/, 'The value in this field must start with a letter'),
    accountWebsite: Yup.string(),
    accountName: Yup.string(),
  });

  const formik = useFormik<AddBotModalForm>({
    initialValues: {
      meetingUrl: '',
      meetingTitle: '',
      accountName: '',
      accountWebsite: '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleCancel: () => void = () => {
    formik.resetForm();
    closeModal();
  };

  const { initialValues } = formik;

  return {
    ...formik,
    handleCancel,
    initialValues,
    getAccountsForSelect,
    selectAccount,
    createAccount,
    resetAddBotState,
    resetSelectedAccount,
    resetMeetingUrlError,
    closeModal,
  };
};
