import React from 'react';
import { DateTime } from 'luxon';
import { CalendarIcon } from './icons/CalendarIcon';

export interface DatePickerInputProps {
  isActive: boolean;
  label: string;
  value: string | null;
  onClick: () => void;
}

export const DatePickerInput: React.FC<DatePickerInputProps> = ({
  isActive,
  label,
  value,
  onClick,
}) => {
  const dateString = value
    ? DateTime.fromISO(value).toFormat('dd/MM/yyyy')
    : 'dd/mm/yyyy';

  const attrs = {
    container: {
      className: 'date-picker-input',
    },
    label: {
      className: 'date-picker-input__label',
    },
    button: {
      type: 'button' as const,
      disabled: isActive,
      className: 'date-picker-input__button',
      onClick,
    },
    icon: {
      className: 'date-picker-input__icon',
    },
  };

  return (
    <div {...attrs.container}>
      <span {...attrs.label}>{label}</span>
      <button {...attrs.button}>
        <CalendarIcon {...attrs.icon} />
        <span>{dateString}</span>
      </button>
    </div>
  );
};
