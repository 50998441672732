import React from 'react';
import { Skeleton } from 'antd';

export interface RangeAnalysisGroupProps {
  isLoading?: boolean;
  children?: React.ReactNode;
  title?: string;
}

export const RangeAnalysisGroup: React.FC<RangeAnalysisGroupProps> = ({
  isLoading = false,
  title,
  children,
}) => {
  const attrs = {
    container: {
      className: 'range-analysis-group',
    },
    title: {
      className: 'range-analysis-group__title',
    },
    titleSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 120,
      },
      title: {
        style: {
          width: 120,
          height: 24,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.title}>
        {isLoading ? <Skeleton {...attrs.titleSkeleton} /> : title}
      </div>
      {children}
    </div>
  );
};
