import React, { useCallback, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { ConfirmationIcon } from '../../../../../../components/Icons/ConfirmationIcon';
import { ErrorIcon } from '../../../../../../components/Icons/ErrorIcon';
import {
  ConfirmationModal,
  Modal,
} from '../../../../../../components/SoundWave';
import { useMixpanel } from '../../../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { editModeChangesSelector } from '../../../../../../store/relationshipDetails/relationshipDetails.selectors';
import { switchToViewMode } from '../../../../../../store/relationshipDetails/relationshipDetails.slice';
import { applyEditModeChanges } from '../../../../../../store/relationshipDetails/relationshipDetails.thunks';
import { EditModeChanges } from '../EditModeChanges/EditModeChanges';

export interface ApplyChangesModalProps {
  isOpened: boolean;
  onClose: () => void;
}

export const ApplyChangesModal: React.FC<ApplyChangesModalProps> = ({
  isOpened,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.relationshipDetails.account);

  const editModeChanges = useAppSelector(editModeChangesSelector);

  const isLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.editModeChanges
  );

  const { trackEvent } = useMixpanel();

  const { show } = useIntercom();

  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);

  const handleApplyChanges = useCallback(async () => {
    try {
      trackEvent('applyEditModeChanges');
      await dispatch(
        applyEditModeChanges({
          accountId: account?.accountId || '',
          editModeChanges,
        })
      ).unwrap();
      dispatch(switchToViewMode());
      setIsSuccessModalOpened(true);
      setIsErrorModalOpened(false);
      onClose();
    } catch (error) {
      setIsSuccessModalOpened(false);
      setIsErrorModalOpened(true);
    }
  }, [account, dispatch, editModeChanges, onClose, trackEvent]);

  const attrs = {
    modal: {
      isOpened,
      onClose,
      width: 656,
      closeOnClickOutside: true,
      headerProps: {
        title: 'Do you want to apply these changes?',
      },
      footerProps: {
        isLoading,
        onConfirm: handleApplyChanges,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Apply',
      },
    },
    successModal: {
      isOpened: isSuccessModalOpened,
      icon: ConfirmationIcon,
      title: 'Changes applied',
      buttonProps: {
        text: 'Close',
        isCloseButton: true,
      },
      onClose: () => {
        setIsSuccessModalOpened(false);
      },
    },
    errorModal: {
      isOpened: isErrorModalOpened,
      icon: ErrorIcon,
      title: 'Unexpected Error',
      description: 'Changes were not applied',
      onClose: () => {
        setIsErrorModalOpened(false);
      },
      footerProps: {
        isLoading,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Try again',
        isConfirmButtonSecondary: true,
        onConfirm: handleApplyChanges,
      },
      buttonProps: {
        text: 'Report',
        isCloseButton: true,
        className: 'confirmation-modal__button--blue',
        onClick: () => {
          show();
          onClose();
        },
      },
    },
    editModeChanges: {
      onDiscardChanges: () => {
        if (editModeChanges.length <= 1) {
          onClose();
        }
      },
    },
  };

  return (
    <>
      <Modal {...attrs.modal}>
        <EditModeChanges {...attrs.editModeChanges} />
      </Modal>
      <ConfirmationModal {...attrs.successModal} />
      <ConfirmationModal {...attrs.errorModal} />
    </>
  );
};
