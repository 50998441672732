import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { editModeChangesSelector } from '../../../../../../store/relationshipDetails/relationshipDetails.selectors';
import { Contact } from '../../../../../../types/contact.types';
import { TeamMember } from '../TeamMember/TeamMember';

export interface EditModeChangesProps {
  onDiscardChanges?: (contact: Contact) => void;
}

export const EditModeChanges: React.FC<EditModeChangesProps> = ({
  onDiscardChanges,
}) => {
  const editModeChanges = useAppSelector(editModeChangesSelector);

  const attrs = {
    container: {
      className: 'edit-mode-changes',
    },
  };

  const renderChanges = () =>
    editModeChanges.map(({ contact, changes }) => {
      const props = {
        contact,
        changes,
        onDiscardChanges,
        key: contact.contactId,
        isChangesView: true,
      };

      return <TeamMember {...props} />;
    });

  return <div {...attrs.container}>{renderChanges()}</div>;
};
