import { Row } from '../../../../../types';

export const sortTeamMembers = (members: Row[]): Row[] => {
  const slicedTeamLeader = members.filter(
    (el) => el.teamMemberRole === 'teamLeader'
  );

  const slicedMembers = members
    .filter((el) => el.teamMemberRole !== 'teamLeader')
    .sort((a, b) => (a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1));

  const sorterArray = slicedTeamLeader.concat(slicedMembers);

  return sorterArray;
};
