import React from 'react';

export const SuccessIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.9987 14.6668C11.6806 14.6668 14.6654 11.6821 14.6654 8.00016C14.6654 4.31826 11.6806 1.3335 7.9987 1.3335C4.3168 1.3335 1.33203 4.31826 1.33203 8.00016C1.33203 11.6821 4.3168 14.6668 7.9987 14.6668ZM11.303 6.58526C11.5636 6.32514 11.564 5.90304 11.3039 5.64246C11.0437 5.38188 10.6216 5.3815 10.361 5.64162L7.05245 8.94438L5.63677 7.52871C5.37642 7.26836 4.95431 7.26836 4.69396 7.52871C4.43361 7.78906 4.43361 8.21117 4.69396 8.47152L6.58063 10.3582C6.84081 10.6184 7.2626 10.6186 7.52302 10.3586L11.303 6.58526Z"
      fill="#10B981"
    />
  </svg>
);
