import React, { useCallback, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { AccountNote } from '../../../../../../../../types/account.types';
import { DotsIcon } from '../../../../../../../../components/Icons/DotsIcon';
import {
  Dropdown,
  EditIcon,
  RemoveIcon,
} from '../../../../../../../../components/SoundWave';
import { useAppDispatch } from '../../../../../../../../store/hooks';
import { openAddNoteModal } from '../../../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { deleteAccountNote } from '../../../../../../../../store/relationshipDetails/relationshipDetails.thunks';
import { NoteItemOverview } from '../NoteItemOverview/NoteItemOverview';
import { useMixpanel } from '../../../../../../../../hooks';

export const NoteItem: React.FC<AccountNote> = ({
  accountId,
  createdAt,
  note,
  noteId,
}) => {
  const dispatch = useAppDispatch();

  const { trackEvent } = useMixpanel();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isNoteOverviewOpened, setIsNoteOverviewOpened] = useState(false);

  const dateText = DateTime.fromISO(createdAt).toFormat('MMMM dd, yyyy');
  const timeText = DateTime.fromISO(createdAt).toFormat('h:mm a; ZZZZ');

  const handleCloseDropdown = useCallback(() => {
    setIsDropdownOpened(false);
  }, []);

  const dropdownOptions = useMemo(
    () => [
      {
        label: 'Edit Note',
        icon: <EditIcon />,
        onClick: () => {
          setIsDropdownOpened(false);
          dispatch(
            openAddNoteModal({
              accountId,
              noteId,
              intialValues: {
                note,
              },
            })
          );
        },
      },
      {
        label: 'Remove Note',
        icon: <RemoveIcon />,
        onClick: () => {
          trackEvent('removeAccountNote');
          setIsDropdownOpened(false);
          dispatch(deleteAccountNote({ accountId, noteId }));
        },
      },
    ],
    [accountId, dispatch, note, noteId, trackEvent]
  );

  const attrs = {
    container: {
      className: 'rs-details-note-item',
      onClick: () => {
        setIsNoteOverviewOpened(true);
      },
    },
    time: {
      className: 'rs-details-note-item__time',
    },
    timeText: {
      className: 'rs-details-note-item__time-text',
    },
    timeTextGray: {
      className:
        'rs-details-note-item__time-text rs-details-note-item__time-text--gray',
    },
    note: {
      className: 'rs-details-note-item__note',
    },
    noteText: {
      className: 'rs-details-note-item__note-text',
    },
    dropdownButton: {
      type: 'button' as const,
      className: `rs-details-note-item__dropdown-button${
        isDropdownOpened ? ' rs-details-note-item__dropdown-button--active' : ''
      }`,
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsDropdownOpened(!isDropdownOpened);
      },
    },
    dropdown: {
      className: 'rs-details-note-item__dropdown',
      isOpened: isDropdownOpened,
      closeOnScroll: true,
      onClose: handleCloseDropdown,
      options: dropdownOptions,
    },
    noteItemOverview: {
      accountId,
      noteId,
      note,
      dateText,
      timeText,
      isOpened: isNoteOverviewOpened,
      onClose: () => {
        setIsNoteOverviewOpened(false);
      },
    },
  };

  return (
    <>
      <div {...attrs.container}>
        <div {...attrs.time}>
          <span {...attrs.timeText}>{dateText}</span>
          <span {...attrs.timeTextGray}>{timeText}</span>
        </div>
        <div {...attrs.note}>
          <span {...attrs.noteText}>{note}</span>
          <Dropdown {...attrs.dropdown}>
            <button {...attrs.dropdownButton}>
              <DotsIcon />
            </button>
          </Dropdown>
        </div>
      </div>
      <NoteItemOverview {...attrs.noteItemOverview} />
    </>
  );
};
