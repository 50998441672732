import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '../../../../../components';
import {
  ButtonType,
  ButtonVariantType,
} from '../../../../../components/Button/Button';
import { Team } from '../../../../../store/teams/teams.reducer';
import { User } from '../../../../../types';
import {
  EditMembersCaseType,
  EditMembers,
} from '../../editMembers/EditMembers';
import { Member } from '../../hooks/useAddTeamForm';
import { updateTeamMembers } from '../../../../../store/teams/teams.actions';

type EditMembersProps = {
  open: boolean;
  onClose: () => void;
  currentUser: User | null;
  currentTeam: Team | null;
};

export const EditMembersFromTableModal: React.FC<EditMembersProps> = ({
  open,
  onClose,
  currentUser,
  currentTeam,
}: EditMembersProps): JSX.Element => {
  const [arrayOfMembers, setArrayOfMembers] = useState<Member[]>([]);
  const [noTeamLeadError, setNoTeamLeadError] = useState(false);
  const [changedLeaderWarn, setchangedLeaderWarn] = useState(false);
  const [initialTeamLeader, setinitialTeamLeader] = useState('');
  const [currentTeamLeader, setCurrentTeamLeader] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentTeam?.members?.length && open) {
      // eslint-disable-next-line
      const result = currentTeam.members.map((el) => ({
        userId: el.userId,
        teamMemberRole: el.teamMemberRole as 'teamLeader' | 'member',
        name: el.name,
        email: el.email,
      }));
      setArrayOfMembers(result);
      const teamLead = currentTeam.members.find(
        (el) => el.teamMemberRole === 'teamLeader'
      );
      setinitialTeamLeader(teamLead?.name || '');
    }
  }, [open]); // eslint-disable-line

  useEffect(() => {
    const checkHavingTeamLeader = arrayOfMembers.some(
      (el) => el.teamMemberRole === 'teamLeader'
    );
    const isCurrentLeadInitialOne =
      arrayOfMembers?.find((el) => el.teamMemberRole === 'teamLeader')?.name ===
      initialTeamLeader;
    if (!isCurrentLeadInitialOne) {
      const newLeader = arrayOfMembers?.find(
        (el) => el.teamMemberRole === 'teamLeader'
      );
      setCurrentTeamLeader(newLeader?.name || '');
    }
    if (arrayOfMembers.length && !checkHavingTeamLeader) {
      setNoTeamLeadError(true);
    } else if (!arrayOfMembers.length || checkHavingTeamLeader) {
      setNoTeamLeadError(false);
    }
    if (!isCurrentLeadInitialOne && !changedLeaderWarn && !noTeamLeadError) {
      setchangedLeaderWarn(true);
    } else if (isCurrentLeadInitialOne && changedLeaderWarn) {
      setchangedLeaderWarn(false);
    } // eslint-disable-next-line
  }, [arrayOfMembers]);

  const updateAndClose = () => {
    if (
      arrayOfMembers.length &&
      arrayOfMembers?.some((el) => el.teamMemberRole === 'teamLeader') &&
      currentTeam &&
      currentUser
    ) {
      const membersToDelete = arrayOfMembers
        .filter((el) => el.isDeleted)
        .map((el) => ({
          userId: el.userId,
          teamMemberRole: el.teamMemberRole,
        }));
      const membersToUpdate = arrayOfMembers
        .filter((el) => !el.isDeleted)
        .map((el) => ({
          userId: el.userId,
          teamMemberRole: el.teamMemberRole,
        }));
      if (membersToDelete.length) {
        dispatch(
          updateTeamMembers(
            currentUser.orgId,
            currentUser.userId,
            currentTeam.teamId,
            membersToDelete,
            'deleteMembers',
            true
          )
        );
      }
      setTimeout(() => {
        dispatch(
          updateTeamMembers(
            currentUser.orgId,
            currentUser.userId,
            currentTeam.teamId,
            membersToUpdate,
            'addMembers'
          )
        );
      }, 1000);
      onClose();
    } else if (!arrayOfMembers.length) {
      onClose();
    }
  };

  const cancelAndClose = () => {
    setTimeout(() => {
      setArrayOfMembers([]);
    }, 200);
    onClose();
  };

  const attrs = {
    modal: {
      title: 'Members',
      open,
      onClose: cancelAndClose,
    },
    titleError: {
      className: 'teams-add__members-body__title-error',
    },
    titleChangedLeaderWarn: {
      className: 'teams-add__members-body__title-warn',
    },
    EditMembers: {
      onClose,
      arrayOfMembers,
      setArrayOfMembers,
      currentCase: 'editing' as EditMembersCaseType,
    },
    buttons: {
      saveChanges: {
        type: 'submit' as ButtonType,
        className: 'create-new-team__btn',
        variant: 'common' as ButtonVariantType,
        disabled: noTeamLeadError || !arrayOfMembers.length,
        onClick: updateAndClose,
      },
    },
    modalBodyWrapper: {
      className: 'create-new-team',
    },
  };

  const noTeamLeaderMessage = noTeamLeadError ? (
    <div {...attrs.titleError}>
      No Team Leader has been assigned, please select one to continue
    </div>
  ) : null;

  const changedLeaderWarnMessage = changedLeaderWarn ? (
    <div {...attrs.titleChangedLeaderWarn}>
      {currentTeamLeader} is the new team leader instead of {initialTeamLeader}.
    </div>
  ) : null;

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.modalBodyWrapper}>
        {noTeamLeaderMessage || changedLeaderWarnMessage}
        <EditMembers {...attrs.EditMembers} />
        <Button {...attrs.buttons.saveChanges}>Save changes</Button>
      </div>
    </Modal>
  );
};
