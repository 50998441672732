import React from 'react';
import {
  Tooltip,
  TooltipPosition,
} from '../SoundWave/components/Tooltip/Tooltip';

export interface EventAnalysisCompareProps {
  title: string;
  children?: React.ReactNode;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  tooltipWidth?: number;
  contentTooltip?: string;
  contentRef?: React.RefObject<HTMLDivElement>;
  renderHeaderButton?: () => React.ReactNode;
}

export const EventAnalysisCompare: React.FC<EventAnalysisCompareProps> = ({
  title,
  children,
  tooltip,
  tooltipPosition,
  tooltipWidth,
  contentTooltip,
  contentRef,
  renderHeaderButton,
}) => {
  const attrs = {
    container: {
      className: 'event-analysis-compare',
    },
    tooltip: {
      position: tooltipPosition || TooltipPosition.TOP,
      text: tooltip,
      width: tooltipWidth || 256,
    },
    header: {
      className: 'event-analysis-compare__header',
    },
    title: {
      className: 'event-analysis-compare__title event-frame__content-subtitle',
    },
    contentTooltip: {
      text: contentTooltip,
      width: 256,
      contentClassName: 'event-analysis-compare__content-tooltip',
    },
    content: {
      className: 'event-analysis-compare__content',
      ref: contentRef,
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.header}>
        <div {...attrs.title}>
          {title}
          {tooltip ? <Tooltip {...attrs.tooltip} /> : null}
        </div>
        {renderHeaderButton ? renderHeaderButton() : null}
      </div>
      {contentTooltip ? (
        <Tooltip {...attrs.contentTooltip}>
          <div {...attrs.content}>{children}</div>
        </Tooltip>
      ) : (
        <div {...attrs.content}>{children}</div>
      )}
    </div>
  );
};
