import React from 'react';

export type OptionIconProps = {
  disabled: boolean;
};

export type Option = {
  text: string;
  onChoose: () => void;
  className?: string;
  Icon?: React.FC<OptionIconProps>;
  disabled?: boolean;
  isRed?: boolean;
  isSidebar?: boolean;
  hidden?: boolean;
  hasBorderBottom?: boolean;
};

export const PopupOption: React.FC<Option> = ({
  text,
  onChoose,
  className = '',
  Icon,
  disabled = false,
  isRed = false,
  isSidebar = false,
  hidden = false,
  hasBorderBottom = false,
}: Option): JSX.Element => {
  return (
    <div
      className={`popup-option ${className} ${isSidebar ? 'sidebar' : ''} ${
        hasBorderBottom ? 'bottom-border' : ''
      } ${
        disabled ? `${isSidebar ? 'sidebar' : ''} popup__option-disabled` : ''
      } ${isRed && !disabled ? 'red popup__option' : ''}`}
      onClick={onChoose}
      style={hidden ? { display: 'none' } : {}}
    >
      {Icon ? (
        <div className="popup__option-icon">
          <Icon disabled={disabled} />
        </div>
      ) : null}
      <span className={`${isSidebar ? 'in-sidebar' : ''} popup__option-text`}>
        {text}
      </span>
    </div>
  );
};
