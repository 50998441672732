import React from 'react';
import { Button, Modal } from '../../../../components';

type InviteSuccessModalProps = {
  onClose: () => void;
  open: boolean;
};

export const InviteSuccessModal: React.FC<InviteSuccessModalProps> = ({
  onClose,
  open,
}: InviteSuccessModalProps): JSX.Element => {
  return (
    <Modal open={open} title="Invite was successfully sent" onClose={onClose}>
      <Button onClick={onClose} variant="common">
        Got it
      </Button>
    </Modal>
  );
};
