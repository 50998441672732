import React from 'react';

export type TeamQTendBarTriangleVariantType =
  | 'green'
  | 'greenOpacity'
  | 'grey'
  | 'default';

interface Props {
  variant: TeamQTendBarTriangleVariantType;
}

export const TendBarTriangle: React.FC<Props> = ({ variant }): JSX.Element => {
  const defineCurrTriangleByVariant = () => {
    let triangle = (
      <svg
        width="42"
        height="38"
        viewBox="0 0 42 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_233_39367)">
          <path
            d="M21.822 20.8136C21.4243 21.3876 20.5757 21.3876 20.178 20.8136L11.6951 8.56949C11.2356 7.90629 11.7103 7 12.5171 7L29.4829 7C30.2898 7 30.7644 7.90629 30.3049 8.5695L21.822 20.8136Z"
            fill="#acacb7"
          />
          <path
            d="M21 20.2441L12.5171 8L29.4829 8L21 20.2441Z"
            stroke="white"
            strokeWidth="2"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_233_39367"
            x="0.0605907"
            y="0.127272"
            width="41.8788"
            height="37.1532"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4.58182" />
            <feGaussianBlur stdDeviation="5.72727" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.223611 0 0 0 0 0.271269 0 0 0 0 0.466667 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_233_39367"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_233_39367"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    );

    if (variant === 'grey') {
      triangle = (
        <svg
          width="42"
          height="38"
          viewBox="0 0 42 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_233_39386)">
            <path
              d="M21.822 20.8136C21.4243 21.3876 20.5757 21.3876 20.178 20.8136L11.6951 8.56949C11.2356 7.90629 11.7103 7 12.5171 7L29.4829 7C30.2898 7 30.7644 7.90629 30.3049 8.5695L21.822 20.8136Z"
              fill="#E5E8EE"
              style={{ transition: 'all 2s linear' }}
            />
            <path
              d="M21 20.2441L12.5171 8L29.4829 8L21 20.2441Z"
              stroke="white"
              strokeWidth="2"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_233_39386"
              x="0.0608349"
              y="0.127272"
              width="41.8783"
              height="37.1532"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.58182" />
              <feGaussianBlur stdDeviation="5.72727" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.223611 0 0 0 0 0.271269 0 0 0 0 0.466667 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_233_39386"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_233_39386"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );
    } else if (variant === 'greenOpacity') {
      triangle = (
        <svg
          width="42"
          height="38"
          viewBox="0 0 42 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_233_39424)">
            <path
              d="M21.822 20.8136C21.4243 21.3876 20.5757 21.3876 20.178 20.8136L11.6951 8.56949C11.2356 7.90629 11.7103 7 12.5171 7L29.4829 7C30.2898 7 30.7644 7.90629 30.3049 8.5695L21.822 20.8136Z"
              fill="#CBE4DB"
              style={{ transition: 'all 2s linear' }}
            />
            <path
              d="M21 20.2441L12.5171 8L29.4829 8L21 20.2441Z"
              stroke="white"
              strokeWidth="2"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_233_39424"
              x="0.0608349"
              y="0.127272"
              width="41.8783"
              height="37.1532"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.58182" />
              <feGaussianBlur stdDeviation="5.72727" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.223611 0 0 0 0 0.271269 0 0 0 0 0.466667 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_233_39424"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_233_39424"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );
    } else if (variant === 'green') {
      triangle = (
        <svg
          width="42"
          height="38"
          viewBox="0 0 42 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_233_39367)">
            <path
              d="M21.822 20.8136C21.4243 21.3876 20.5757 21.3876 20.178 20.8136L11.6951 8.56949C11.2356 7.90629 11.7103 7 12.5171 7L29.4829 7C30.2898 7 30.7644 7.90629 30.3049 8.5695L21.822 20.8136Z"
              fill="#40BA76"
              style={{ transition: 'all 2s linear' }}
            />
            <path
              d="M21 20.2441L12.5171 8L29.4829 8L21 20.2441Z"
              stroke="white"
              strokeWidth="2"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_233_39367"
              x="0.0605907"
              y="0.127272"
              width="41.8788"
              height="37.1532"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4.58182" />
              <feGaussianBlur stdDeviation="5.72727" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.223611 0 0 0 0 0.271269 0 0 0 0 0.466667 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_233_39367"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_233_39367"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );
    }

    return triangle;
  };

  return defineCurrTriangleByVariant();
};
