import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import LabelValueRow from '../../../../components/LabelValueRow/LabelValueRow';

export const SubscriptionInfo: React.FC = (): JSX.Element => {
  const { myOrg } = useSelector((state: RootState) => state.org);

  const { planId, changePlanUrl } = useSelector(
    (state: RootState) => state.appSumo
  );

  const defineValues = () => {
    let appSumoPlan = '';

    let totalSeats = myOrg?.maxUsersCount || '';

    let seatsLeft: string | number = '';

    if (planId === 'appsumo_qbysubstrata_tier1') {
      appSumoPlan = 'AppSumo Tier 1';
    } else if (planId === 'appsumo_qbysubstrata_tier2') {
      appSumoPlan = 'AppSumo Tier 2';
    } else if (planId === 'appsumo_qbysubstrata_tier3') {
      appSumoPlan = 'AppSumo Tier 3';
    } else {
      appSumoPlan = 'Premium';
    }

    if (typeof myOrg?.maxUsersCount === 'number') {
      if (myOrg.maxUsersCount === -1) {
        totalSeats = 'Unlimited';

        seatsLeft = 'Unlimited';
      }

      if (typeof myOrg?.usersCount === 'number' && myOrg.maxUsersCount !== -1)
        seatsLeft =
          myOrg?.maxUsersCount === -1 ? 'Unlimited' : myOrg?.maxUsersCount;
    }

    return {
      appSumoPlan,
      totalSeats: totalSeats.toString(),
      seatsLeft: seatsLeft.toString(),
    };
  };

  const { appSumoPlan, totalSeats, seatsLeft } = defineValues();

  const attrs = {
    wrapper: {
      className: 'subscription-info',
    },

    header: {
      className: 'subscription-info-header',
    },

    body: {
      wrapper: {
        className: 'subscription-info__body',
      },

      rows: {
        AppSumo: {
          label: 'Subscription Plan',
          value: appSumoPlan,
          valueLink: changePlanUrl || '',
          coloredValue: true,
          linkInNewTab: true,
        },

        TotalSeats: {
          label: 'Total No. of Seats',
          value: totalSeats,
        },

        SeatsLeft: {
          label: 'Seats Left',
          value: seatsLeft,
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header}>Subscription Information</div>
      <div {...attrs.body.wrapper}>
        <LabelValueRow {...attrs.body.rows.AppSumo} />
        <LabelValueRow {...attrs.body.rows.TotalSeats} />
        <LabelValueRow {...attrs.body.rows.SeatsLeft} />
      </div>
    </div>
  );
};
