import React, { useEffect, useState } from 'react';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { MeetingDurationData } from '../../../../../store/charts/charts.types';
import { dashedLine } from './assets';
import { MeetingDurationSkeleton } from '../../skeletons/MeetingDurationSkeleton';

type Props = {
  meetingDurationData: MeetingDurationData | null;
  lastDays: number | null;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  isInTeamQ?: boolean;
};

type LocalData = {
  label: string;
  value: number;
  fill: string;
}[];

export const MeetingDurationChart: React.FC<Props> = ({
  meetingDurationData,
  lastDays,
  isLoading,
  noData,
  error,
  isInTeamQ = false,
}): JSX.Element => {
  const [data, setData] = useState<LocalData>([]);

  // TODO: Remove this if not needed
  const [activeBarIndex] = useState<number | null>(null);

  const [maxValue, setMaxValue] = useState<number>(0);

  const noDataCheck = noData || error;

  useEffect(() => {
    if (meetingDurationData) {
      const {
        userAverageDurationMin: me,
        teamAverageDurationMin: team,
      } = meetingDurationData;

      const roundTenth = (value: number) => {
        let resultValue = value;
        if (value && value.toString().includes('.')) {
          const integer = value.toString().split('.')[0];

          const tenth = value.toString().split('.')[1].slice(0, 1);

          if (+tenth >= 1) {
            resultValue = +`${integer}.${tenth}`;
          } else resultValue = +integer;
        }
        return resultValue;
      };

      const dataToSet: LocalData = [
        {
          label: 'Me',
          value: roundTenth(me || 0),
          fill: '#4EB4FF',
        },
        {
          label: isInTeamQ ? 'My Team' : 'Team',
          value: roundTenth(team),
          fill: isInTeamQ ? '#4EB4FF' : '#365798',
        },
      ];

      const biggerValue =
        (me || 0) < 0.5 && team < 0.5
          ? 0.5
          : Math.ceil(Math.max(me || 0, team));

      setMaxValue(typeof me === 'number' ? biggerValue : team);

      setData(dataToSet);
    }
  }, [isInTeamQ, meetingDurationData]);

  const defineTooltipBorderColor = () => {
    let border = '';
    if (activeBarIndex === 0) {
      border = '#4EB4FF';
    } else if (activeBarIndex === 1) {
      border = '#365798';
    } else if (activeBarIndex === 2) {
      border = '#ACACB7';
    }
    return border;
  };

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
      style: { height: 'max-content', width: '154px' },
    },
    lastDays: {
      className: 'margin chart-tooltip__date-on-top',
    },
    body: {
      wrapper: {
        className: 'chart-tooltip__single-line',
      },
      border: {
        className: 'chart-tooltip__single-line-left-border',
        style: {
          backgroundColor: defineTooltipBorderColor(),
          height: '36px',
        },
      },
      contentSection: {
        className: 'chart-tooltip__single-line__body',
      },
    },
    value: {
      title: {
        className: 'chart-tooltip__label',
      },
      text: {
        className: 'participants chart-tooltip__value',
      },
      boldText: {
        className: 'chart-tooltip__value-bold',
      },
    },
  };

  // eslint-disable-next-line
  const CustomisedTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    if (active && data.length) {
      const place = payload[0]?.payload?.label;

      const value = payload[0]?.payload?.value;

      const valueToShow =
        value >= 100 && value.toString().includes('.')
          ? value.toString().split('.')[0]
          : value;

      const days = lastDays;

      const messageToShow =
        place && !Number.isNaN(place) ? (
          <div {...tooltipAttrs.body.contentSection}>
            <div {...tooltipAttrs.value.title}>{place}</div>
            <div {...tooltipAttrs.value.text}>
              {' '}
              <span {...tooltipAttrs.value.boldText}>
                {value < 0.1 ? 0 : valueToShow}{' '}
              </span>
            </div>
          </div>
        ) : (
          <div {...tooltipAttrs.body.contentSection}>
            0<span {...tooltipAttrs.value.text}>-</span>
          </div>
        );

      result = (
        <div {...tooltipAttrs.wrapper}>
          <div {...tooltipAttrs.lastDays}>Last {days} days</div>
          <div {...tooltipAttrs.body.wrapper}>
            <div {...tooltipAttrs.body.border} />
            {messageToShow}
          </div>
        </div>
      );
    }
    return result;
  };

  const defineXticks = () => {
    let xTicks = ['0', '00:02:30', '00:05:00', '00:07:30', '00:10:00'];
    let maxWidthValue = 10;

    if (maxValue > 10 && maxValue <= 18) {
      xTicks = ['00:00', '00:05', '00:10', '00:15', '00:20'];
      maxWidthValue = 20;
    } else if (maxValue > 18 && maxValue <= 28) {
      xTicks = ['00:00', '00:10', '00:20', '00:30'];
      maxWidthValue = 30;
    } else if (maxValue > 28 && maxValue <= 55) {
      xTicks = ['00:00', '00:15', '00:30', '00:45', '01:00'];
      maxWidthValue = 60;
    } else if (maxValue > 55 && maxValue <= 85) {
      xTicks = ['00:00', '00:25', '00:50', '01:15', '01:40'];
      maxWidthValue = 100;
    } else if (maxValue > 85 && maxValue <= 110) {
      xTicks = ['00:00', '00:30', '01:00', '01:30', '02:00'];
      maxWidthValue = 120;
    } else if (maxValue > 110 && maxValue <= 210) {
      xTicks = ['00:00', '01:00', '02:00', '03:00', '04:00'];
      maxWidthValue = 240;
    } else if (maxValue > 210 && maxValue <= 350) {
      xTicks = ['00:00', '01:30', '03:00', '04:30', '06:00'];
      maxWidthValue = 360;
    } else if (maxValue > 350 && maxValue <= 710) {
      xTicks = ['00:00', '03:00', '06:00', '09:00', '12:00'];
      maxWidthValue = 720;
    } else if (maxValue > 710 && maxValue <= 1440) {
      xTicks = ['00:00', '06:00', '12:00', '18:00', '24:00'];
      maxWidthValue = 1440;
    }

    return { xTicks, maxWidthValue };
  };

  const { xTicks, maxWidthValue } = defineXticks();

  const defineBarWidth = (user: number, team: number) => {
    let userBarWidth = user ? (user / maxWidthValue) * 100 : 0;

    let teamBarWidth = team ? (team / maxWidthValue) * 100 : 0;

    if (userBarWidth > 100) {
      userBarWidth = 100;
    }

    if (teamBarWidth > 100) {
      teamBarWidth = 100;
    }

    return { userBarWidth, teamBarWidth };
  };

  const { userBarWidth, teamBarWidth } = defineBarWidth(
    data[0]?.value || 0,
    data[1]?.value || 0
  );

  const attrs = {
    meetingDurationSkeleton: {
      isLoading,
      isInTeamQ,
    },
    chartWrapper: {
      isInTeamQ,
      title: 'Meeting Duration',
      subtitle: `Last ${lastDays} days`,
      tooltipText: isInTeamQ
        ? 'The average meeting duration of your team over the selected period.'
        : `Your average meeting duration compared to your team's average meeting duration over the selected period, excluding my meetings.`,
      isNoData: noDataCheck,
      isSoundWave: true,
    },

    contentWrapper: {
      className: 'meeting-duration',
    },

    description: {
      wrapper: {
        className: 'meeting-duration__description',
        style: noDataCheck ? { opacity: 0.5, zIndex: 1 } : {},
      },
      text: {
        className: 'meeting-duration__description-text',
      },
    },

    chart: {
      wrapper: {
        className: `meeting-duration__chart${
          isInTeamQ ? ' meeting-duration__chart--team-q' : ''
        }`,
        style: noDataCheck ? { opacity: 0.5, zIndex: 1 } : {},
      },

      firstColumn: {
        wrapper: {
          className: `meeting-duration__chart__first-column${
            isInTeamQ ? ' meeting-duration__chart__first-column--team-q' : ''
          }`,
        },

        yAxis: {
          wrapper: {
            className: 'meeting-duration__chart__first-column__y-axis',
          },

          label: {
            className: 'meeting-duration__chart__first-column__y-axis-label',
          },
        },
      },

      secondColumn: {
        wrapper: {
          className: 'meeting-duration__chart__second-column',
        },

        chartBody: {
          wrapper: {
            className: 'meeting-duration__chart__second-column__chart-body',
            ...(noDataCheck ? { style: { opacity: 0 } } : {}),
          },

          avgLineSection: {
            wrapper: {
              className:
                'meeting-duration__chart__second-column__chart-body__avg-section',
            },

            line: {
              className:
                'meeting-duration__chart__second-column__chart-body__avg-section-line',
            },
          },

          bar: {
            wrapper: {
              className:
                'meeting-duration__chart__second-column__chart-body__bar',
            },

            me: {
              stripe: {
                className:
                  'meeting-duration__chart__second-column__chart-body__bar-me-stripe',
                style: {
                  width: `${userBarWidth}%`,
                  opacity: userBarWidth && !noDataCheck ? 1 : 0,
                },
              },

              dashedLine: {
                src: dashedLine,
                className:
                  'meeting-duration__chart__second-column__chart-body__bar-me-dashed-line',
                style: {
                  opacity: userBarWidth ? 1 : 0,
                  zIndex: userBarWidth ? 1 : 0,
                },
              },

              label: {
                className:
                  'meeting-duration__chart__second-column__chart-body__bar-me-label',
                style: {
                  left: `${userBarWidth}%`,
                  opacity: userBarWidth ? 1 : 0,
                },
              },
            },

            team: {
              stripe: {
                className: `meeting-duration__chart__second-column__chart-body__bar-team-stripe${
                  isInTeamQ
                    ? ' meeting-duration__chart__second-column__chart-body__bar-team-stripe--team-q'
                    : ''
                }`,
                style: {
                  width: `${teamBarWidth}%`,
                  opacity: teamBarWidth ? 1 : 0,
                },
              },

              dashedLine: {
                src: dashedLine,
                className:
                  'meeting-duration__chart__second-column__chart-body__bar-team-dashed-line',
                style: {
                  opacity: teamBarWidth ? 1 : 0,
                  zIndex: teamBarWidth ? 1 : 0,
                },
              },

              label: {
                className: `meeting-duration__chart__second-column__chart-body__bar-team-label${
                  isInTeamQ
                    ? ' meeting-duration__chart__second-column__chart-body__bar-team-label--team-q'
                    : ''
                }`,
                style: {
                  left: `${teamBarWidth}%`,
                  opacity: teamBarWidth ? 1 : 0,
                },
              },
            },
          },
        },

        xAxis: {
          wrapper: {
            className: 'meeting-duration__chart__second-column__x-axis',
          },

          label: {
            className: 'meeting-duration__chart__second-column__x-axis-label',
          },
        },
      },
    },

    legend: {
      wrapper: {
        className: 'meeting-duration__legend',
        style: noDataCheck ? { opacity: 0.5, zIndex: 1 } : {},
      },
      me: { className: 'meeting-duration__legend-me' },
      team: {
        className: `meeting-duration__legend-team${
          isInTeamQ ? ' meeting-duration__legend-team--team-q' : ''
        }`,
      },
    },

    responsiveContainer: {
      height: '100%',
    },
  };

  const titleSection =
    typeof meetingDurationData?.belowTeamAverageRatio === 'number' ? (
      <div {...attrs.description.wrapper}>
        <div {...attrs.description.text}>
          {(
            meetingDurationData?.belowTeamAverageRatio &&
            meetingDurationData?.belowTeamAverageRatio * 100
          )?.toFixed(0) || '0'}
          % of your meetings have durations below the average duration of the
          rest of the Team`s Meetings.
        </div>
      </div>
    ) : null;

  const legendSection = (
    <div {...attrs.legend.wrapper}>
      {!isInTeamQ ? <span {...attrs.legend.me}>My meetings (Avg.)</span> : null}
      <span {...attrs.legend.team}>
        {isInTeamQ ? 'My Team (Avg.)' : 'Teams meetings (Avg.)'}
      </span>
    </div>
  );

  const mappedXAxis = xTicks.map((el, i) => (
    <div {...attrs.chart.secondColumn.xAxis.label} key={`${el}${i}`}>
      {el}
    </div>
  ));

  return (
    <MeetingDurationSkeleton {...attrs.meetingDurationSkeleton}>
      <ChartWrapper {...attrs.chartWrapper}>
        <div {...attrs.contentWrapper}>
          {titleSection}
          <div {...attrs.chart.wrapper}>
            <div {...attrs.chart.firstColumn.wrapper}>
              <div {...attrs.chart.firstColumn.yAxis.wrapper}>
                {!isInTeamQ ? (
                  <div {...attrs.chart.firstColumn.yAxis.label}>Me</div>
                ) : null}
                <div {...attrs.chart.firstColumn.yAxis.label}>
                  {isInTeamQ ? 'My Team' : 'Team'}
                </div>
              </div>
            </div>
            <div {...attrs.chart.secondColumn.wrapper}>
              <div {...attrs.chart.secondColumn.chartBody.wrapper}>
                {!isInTeamQ ? (
                  <div {...attrs.chart.secondColumn.chartBody.bar.wrapper}>
                    <div
                      {...attrs.chart.secondColumn.chartBody.bar.me.stripe}
                    />
                    <img
                      {...attrs.chart.secondColumn.chartBody.bar.me.dashedLine}
                      alt=""
                    />
                    <div {...attrs.chart.secondColumn.chartBody.bar.me.label}>
                      {`${data[0]?.value?.toFixed(0)} ${
                        data[0]?.value === 1 ? 'Min' : 'Mins'
                      }`}
                    </div>
                  </div>
                ) : null}
                <div {...attrs.chart.secondColumn.chartBody.bar.wrapper}>
                  <div
                    {...attrs.chart.secondColumn.chartBody.bar.team.stripe}
                  />
                  <img
                    {...attrs.chart.secondColumn.chartBody.bar.team.dashedLine}
                    alt=""
                  />
                  <div {...attrs.chart.secondColumn.chartBody.bar.team.label}>
                    {`${data[1]?.value?.toFixed(0)} ${
                      data[1]?.value === 1 ? 'Min' : 'Mins'
                    }`}
                  </div>
                </div>
              </div>
              <div {...attrs.chart.secondColumn.xAxis.wrapper}>
                {mappedXAxis}
              </div>
            </div>
          </div>
          {legendSection}
        </div>
      </ChartWrapper>
    </MeetingDurationSkeleton>
  );
};
