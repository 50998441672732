import React, { useState } from 'react';
import { Skeleton } from 'antd';
import { Avatar, AvatarProps, AvatarSize, Tooltip } from '../../../SoundWave';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import {
  SelectDropdown,
  SelectDropdownProps,
} from './components/SelectDropdown/SelectDropdown';
import {
  SideOption,
  SideOptionProps,
} from './components/SideOption/SideOption';

export interface RangeAnalysisProps {
  isLoading?: boolean;
  rangeValue?: number | null;
  title?: string;
  value?: string;
  side?: RelationshipMemberSide;
  avatar?: AvatarProps;
  dropdownOptions?: SideOptionProps[];
  selectDropdownProps?: Omit<SelectDropdownProps, 'isExpanded' | 'onExpand'>;
  tooltip?: React.ReactNode;
}

export const RangeAnalysis: React.FC<RangeAnalysisProps> = ({
  isLoading = false,
  rangeValue,
  title,
  value,
  avatar,
  side,
  dropdownOptions,
  selectDropdownProps,
  tooltip,
}) => {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false);

  const attrs = {
    container: {
      className: 'range-analysis',
    },
    avatar: {
      side,
      size: AvatarSize.S,
      ...avatar,
    },
    avatarSkeleton: {
      active: true,
      paragraph: false,
      title: false,
      avatar: {
        shape: 'circle' as const,
        size: 28,
      },
      style: {
        width: 'auto',
      },
    },
    content: {
      className: 'range-analysis__content',
    },
    titleWrapper: {
      className: 'range-analysis__title-wrapper',
    },
    left: {
      className: 'range-analysis__left',
    },
    title: {
      className: 'range-analysis__title',
    },
    titleWithTooltip: {
      className: 'range-analysis__title range-analysis__title--with-tooltip',
    },
    value: {
      className: 'range-analysis__value',
    },
    titleSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 120,
      },
      title: {
        style: {
          width: 120,
          height: 24,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
    rangeWrapper: {
      className: 'range-analysis__range-wrapper',
    },
    range: {
      className: `range-analysis__range${
        typeof rangeValue === 'number' && side
          ? ` range-analysis__range--${side}`
          : ''
      }`,
      style: {
        width: `${typeof rangeValue === 'number' ? rangeValue : 100}%`,
      },
    },
    rangeSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: '100%',
      },
      title: {
        style: {
          width: '100%',
          height: 4,
          margin: 0,
          borderRadius: 4,
        },
      },
    },
    selectDropdown: {
      ...selectDropdownProps,
      isExpanded: isDropdownExpanded,
      onExpand: (isExpanded: boolean) => {
        setIsDropdownExpanded(isExpanded);
      },
    },
    tooltip: {
      text: tooltip,
      contentClassName: 'range-analysis__tooltip',
      positionOffset: 16,
    },
  };

  const renderAvatar = () => {
    if (!avatar) {
      return null;
    }

    if (isLoading) {
      return <Skeleton {...attrs.avatarSkeleton} />;
    }

    return <Avatar {...attrs.avatar} />;
  };

  const renderDropdownOptions = () =>
    dropdownOptions?.map((option) => (
      <SideOption key={option.value} {...option} />
    ));

  return (
    <div {...attrs.container}>
      {renderAvatar()}
      <div {...attrs.content}>
        <div {...attrs.titleWrapper}>
          <div {...attrs.left}>
            {isLoading ? (
              <Skeleton {...attrs.titleSkeleton} />
            ) : (
              <>
                {tooltip ? (
                  <div {...attrs.titleWithTooltip}>
                    <Tooltip {...attrs.tooltip}>{title}</Tooltip>
                  </div>
                ) : (
                  <span {...attrs.title}>{title}</span>
                )}
                <span {...attrs.value}>{value}</span>
              </>
            )}
          </div>
          {selectDropdownProps ? (
            <SelectDropdown {...attrs.selectDropdown}>
              {renderDropdownOptions()}
            </SelectDropdown>
          ) : null}
        </div>
        <div {...attrs.rangeWrapper}>
          {isLoading ? (
            <Skeleton {...attrs.rangeSkeleton} />
          ) : (
            <div {...attrs.range} />
          )}
        </div>
      </div>
    </div>
  );
};
