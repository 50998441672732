import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { DatePickerInput } from './components/DatePickerInput/DatePickerInput';
import 'react-datepicker/dist/react-datepicker.css';
import { DatePickerHeader } from './components/DatePickerHeader/DatePickerHeader';
import { CrossIcon } from './icons/CrossIcon';
import { CheckedIcon } from './icons/CheckedIcon';

export interface DatePickerProps {
  startDate: string | null;
  endDate: string | null;
  onChange: (startDate: string | null, endDate: string | null) => void;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  startDate,
  endDate,
  onChange,
}) => {
  const [isStartDateActive, setIsStartDateActive] = useState(false);
  const [isEndDateActive, setIsEndDateActive] = useState(false);

  const attrs = {
    container: {
      className: 'date-picker',
    },
    inputsWrapper: {
      className: 'date-picker__inputs-wrapper',
    },
    datePickerWrapper: {
      className: 'date-picker__wrapper',
    },
    startDateInput: {
      isActive: isStartDateActive,
      label: 'Start date',
      value: startDate,
      onClick: () => {
        setIsEndDateActive(false);
        setIsStartDateActive(true);
      },
    },
    endDateInput: {
      isActive: isEndDateActive,
      label: 'End date',
      value: endDate,
      onClick: () => {
        setIsStartDateActive(false);
        setIsEndDateActive(true);
      },
    },
    startDatePicker: {
      inline: true,
      selectsStart: true,
      selected: startDate ? new Date(startDate) : null,
      startDate: startDate ? new Date(startDate) : null,
      endDate: endDate ? new Date(endDate) : null,
      maxDate: endDate ? new Date(endDate) : null,
      renderCustomHeader: DatePickerHeader,
      onChange: (date: Date) => {
        onChange(date.toISOString(), endDate);
        setIsStartDateActive(false);
        setIsEndDateActive(true);
      },
    },
    endDatePicker: {
      inline: true,
      selectsEnd: true,
      selected: endDate ? new Date(endDate) : null,
      startDate: startDate ? new Date(startDate) : null,
      endDate: endDate ? new Date(endDate) : null,
      minDate: startDate ? new Date(startDate) : null,
      renderCustomHeader: DatePickerHeader,
      onChange: (date: Date) => {
        onChange(startDate, date.toISOString());
        setIsEndDateActive(false);
        setIsStartDateActive(true);
      },
    },
    buttonsWrapper: {
      className: 'date-picker__buttons-wrapper',
    },
    clearButton: {
      type: 'button' as const,
      className: 'date-picker__button',
      onClick: () => {
        onChange(null, null);
        setIsEndDateActive(false);
        setIsStartDateActive(true);
      },
    },
    confirmButton: {
      type: 'button' as const,
      className: 'date-picker__button',
      onClick: () => {
        setIsEndDateActive(false);
        setIsStartDateActive(false);
      },
    },
    buttonIcon: {
      className: 'date-picker__button-icon',
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.inputsWrapper}>
        <DatePickerInput {...attrs.startDateInput} />
        <DatePickerInput {...attrs.endDateInput} />
      </div>
      {isStartDateActive || isEndDateActive ? (
        <div {...attrs.datePickerWrapper}>
          {isStartDateActive ? (
            <ReactDatePicker {...attrs.startDatePicker} />
          ) : null}
          {isEndDateActive ? (
            <ReactDatePicker {...attrs.endDatePicker} />
          ) : null}
        </div>
      ) : null}
      {isStartDateActive || isEndDateActive ? (
        <div {...attrs.buttonsWrapper}>
          <button {...attrs.clearButton}>
            <span>Clear</span>
            <CrossIcon {...attrs.buttonIcon} />
          </button>
          <button {...attrs.confirmButton}>
            <span>Confirm</span>
            <CheckedIcon {...attrs.buttonIcon} />
          </button>
        </div>
      ) : null}
    </div>
  );
};
