import { MeetingEventIcon } from '../components/Icons/MeetingEventIcon';
import { MeetingRecordSource } from '../types/meetingDetails.types';
import { GoogleMeetIcon } from '../components/Icons/GoogleMeetIcon';
import { ZoomIcon } from '../components/Icons/ZoomIcon';
import { MicrosoftTeamsIcon } from '../components/Icons/MicrosoftTeamsIcon';

export const getMeetingSourceIcon = (
  source?: MeetingRecordSource | null
): React.FC<React.SVGProps<SVGSVGElement>> => {
  switch (source) {
    case MeetingRecordSource.GOOGLE_MEET:
      return GoogleMeetIcon;
    case MeetingRecordSource.ZOOM:
      return ZoomIcon;
    case MeetingRecordSource.TEAMS:
      return MicrosoftTeamsIcon;
    default:
      return MeetingEventIcon;
  }
};
