import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Options } from './elements/Options';
import { TableRowName } from './elements/TableRowName';
import { TeamRole } from './elements/TeamRole';
import { Row, User } from '../../../../../types';
import { ActiveStatus } from '../../../../../components';
import { RootState } from '../../../../../store/store';
import { PopupOption } from '../../../../../components/Popup/PopupOption';
import {
  invokeDeleteMebersModal,
  invokeNewLeaderModal,
  setCurrentTeamMember,
} from '../../../../../store/teams/teams.actions';
import { AssignLeaderPopupIcon } from '../../../icons/AssignLeaderPopupIcon';
import { DeleteMemberPopupIcon } from '../../../icons/DeleteMemberPopupIcon';

const noop = () => {};

const statusTitle = <div className="centered table__column-title">Status</div>;

type Col = {
  title: string | JSX.Element;
  dataIndex: string;
  key: string;
  width?: string;
  render?: (...params: any[]) => any; // eslint-disable-line
  shouldCellUpdate: () => boolean;
};

const getColumns = (
  options: JSX.Element[],
  getSelectedMember: (teamMember: Row) => void
): Col[] => [
  {
    title: 'Member',
    dataIndex: 'userId',
    key: 'name',
    render: (userId: string): JSX.Element => <TableRowName userId={userId} />,
    shouldCellUpdate: () => true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    shouldCellUpdate: () => true,
  },
  {
    title: statusTitle,
    dataIndex: 'status',
    key: 'status',
    render: (status: 'active' | 'inactive'): JSX.Element => (
      <ActiveStatus status={status} centered />
    ),
    shouldCellUpdate: () => true,
  },
  {
    title: 'Last Activity',
    dataIndex: 'lastActivity',
    key: 'lastActivity',
    width: '17%',
    render: (lastActivity: string) =>
      lastActivity ? moment(lastActivity).fromNow() : '',
    shouldCellUpdate: () => true,
  },
  {
    title: 'Role',
    dataIndex: 'teamMemberRole',
    key: 'teamMemberRole',
    render: (role: string) => <TeamRole role={role} />,
    shouldCellUpdate: () => true,
  },
  {
    title: '',
    dataIndex: 'options',
    key: 'options',
    render: (teamMember: Row): JSX.Element => (
      <div onClick={() => getSelectedMember(teamMember)}>
        <Options options={options} />
      </div>
    ),
    shouldCellUpdate: () => true,
  },
];

type MembersTable = {
  cols: Col[];
  rowSelection: {
    onChange: (keys: React.Key[], rows: Row[]) => void;
  };
  selectedRows: Row[];
  selectedTeamMember: Row | null;
  toggleAssignRoleModal?: () => void;
  user: User | null;
  openDeleteModal: () => void;
  openAssignLeaderModal: () => void;
  closeDeleteModal: () => void;
  closeNewLeaderModal: () => void;
  clearSelectedRow: () => void;
};

export const useTable = (): MembersTable => {
  const [selectedRows, setSelectedRows] = useState<Row[]>([]);
  const { user } = useSelector((state: RootState) => state.auth);
  const { currentMember } = useSelector((state: RootState) => state.teams);

  const dispatch = useDispatch();

  const handleSelect = (keys: React.Key[], rows: Row[]): void => {
    setSelectedRows(rows);
    if (currentMember) {
      dispatch(setCurrentTeamMember(null));
    }
  };

  const handleChooseMember = useCallback(
    (current: Row): void => {
      dispatch(setCurrentTeamMember(current));
    },
    [dispatch]
  );

  const openDeleteModal = () => {
    dispatch(invokeDeleteMebersModal(true));
  };
  const openAssignLeaderModal = () => {
    dispatch(invokeNewLeaderModal(true));
  };

  const closeDeleteModal = () => {
    dispatch(invokeDeleteMebersModal(false));
  };
  const closeNewLeaderModal = () => {
    dispatch(invokeNewLeaderModal(false));
  };

  const clearSelectedRow = () => {
    setSelectedRows([]);
  };

  const conditionToDisableOptions =
    currentMember?.teamMemberRole === 'teamLeader' ||
    user?.role === 'dealmaker';

  const options: JSX.Element[] = useMemo(
    () => [
      <PopupOption
        key="Assign as Team Leader"
        className="teams-options__option"
        text="Assign as Team Leader"
        onChoose={!conditionToDisableOptions ? openAssignLeaderModal : noop}
        disabled={conditionToDisableOptions}
        Icon={AssignLeaderPopupIcon}
      />,
      <PopupOption
        key="Remove User from Team"
        className="teams-options__option"
        disabled={conditionToDisableOptions}
        text="Remove User from Team"
        isRed
        onChoose={!conditionToDisableOptions ? openDeleteModal : noop}
        Icon={DeleteMemberPopupIcon}
      />,
    ],
    // eslint-disable-next-line
    [currentMember?.userId]
  );

  const cols = useMemo(() => getColumns(options, handleChooseMember), [
    options,
    handleChooseMember,
  ]);

  const rowSelection = {
    onChange: handleSelect,
  };

  return {
    cols,
    rowSelection,
    selectedRows,
    selectedTeamMember: currentMember,
    user,
    openDeleteModal,
    openAssignLeaderModal,
    closeDeleteModal,
    closeNewLeaderModal,
    clearSelectedRow,
  };
};
