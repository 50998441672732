import React, { useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash.debounce';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { closeIcon, successCheckmarkIcon } from './assets';
import {
  Button,
  ButtonType,
  ButtonVariantType,
} from '../../../../components/Button/Button';
import { Input, LogoPlaceholderIcon } from '../../../../components';
import { useAddBotModal } from './utils/useAddBotModal';
import { CreateAccountErrorType } from '../../../../store/relationshipFlows/relationshipFlows.types';
import { LoaderIcon } from '../../../../components/Icons/LoaderIcon';
import { Avatar, AvatarSize, Modal } from '../../../../components/SoundWave';
import { useAddBotModalForm } from './utils/useAddBotModalForm';
import { getStringTokens } from '../../../../components/utils';
import { AddNewAccountIcon } from './assets/AddNewAccountIcon';

interface SelectItem {
  key: string;
  label: string;
  value: string;
  accountId?: string;
  avatar?: string;
}

interface RowProps {
  avatar: string;
  name: string;
  accountId: string;
}

export const AddBotModal: React.FC = (): JSX.Element => {
  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    handleCancel,
    getAccountsForSelect,
    selectAccount,
    createAccount,
    setFieldValue,
    resetAddBotState,
    resetSelectedAccount,
    resetMeetingUrlError,
  } = useAddBotModal();

  const { resetAccountInputValue } = useAddBotModalForm();

  const {
    accounts,
    selectedAccount,
    addAccount,
    isLoading,
    meetingUrlFieldError,
    isModalOpened,
    isSuccess,
  } = useSelector((state: RootState) => state.relatioshipFlows.addBot);

  const [selectValue, setSelectValue] = useState('');
  const [accountsToShow, setAccountsToShow] = useState<SelectItem[] | null>(
    null
  );
  const [selectItemAfterSuccess, setSetSelectItemAfterSuccess] = useState<
    SelectItem | undefined
  >(undefined);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const [accountName, setAccountName] = useState('');
  const [existingAccountName, setExistingAccountName] = useState('');
  const [companyWebsiteUrl, setCompanyWebsiteUrl] = useState('');
  const [companyWebsiteUrlError, setCompanyWebsiteUrlError] = useState(false);

  const accountInputwrapperRef = useRef<HTMLDivElement>(null);

  const defaultAddAccOption = {
    label: 'Create a new Account',
    value: 'new',
    key: 'create-new-account',
  };

  useEffect(() => {
    if (isModalOpened) {
      getAccountsForSelect();
    } // eslint-disable-next-line
  }, [isModalOpened]);

  useEffect(() => {
    if (accounts?.length) {
      setAccountsToShow([
        defaultAddAccOption,
        ...accounts.map((account) => ({
          key: account.accountId,
          label: account.name,
          value: account.accountId,
          avatar: account.avatarSrc || '',
        })),
      ]);
    }
  }, [accounts]);

  useEffect(() => {
    if (selectValue === 'Create a new Account' && !accountName?.length) {
      setCompanyWebsiteUrl('');
    } // eslint-disable-next-line
  }, [selectValue]);

  useEffect(() => {
    if (existingAccountName.length && accountName !== existingAccountName) {
      resetAddBotState();
      setExistingAccountName('');
    } // eslint-disable-next-line
  }, [accountName]);

  useEffect(() => {
    // if (selectedAccount && popupIsOpen) {
    //   setPopupIsOpen(false);
    // }

    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (popupIsOpen && !accountInputwrapperRef.current?.contains(target)) {
        setPopupIsOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [popupIsOpen, selectedAccount]);

  const filterAccountsResults = useCallback(
    debounce((currentValue: string) => {
      const filteredAccounts = accounts
        .filter((account) =>
          account.name.toLowerCase().includes(currentValue.toLowerCase())
        )
        .map((account) => ({
          key: account.accountId,
          label: account.name,
          value: account.accountId,
          avatar: account.avatarSrc || '',
        }));

      setAccountsToShow([defaultAddAccOption, ...filteredAccounts]);
    }, 100),
    [selectValue]
  );

  const updateValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectValue(e.currentTarget.value);
      filterAccountsResults(e.currentTarget.value);
      if (!popupIsOpen) {
        setPopupIsOpen(true);
      }
    }, // eslint-disable-next-line
    [selectValue]
  );

  const isUrlValid = () => {
    let result = false;
    const urlValidityResult = companyWebsiteUrl?.match(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    );
    if (urlValidityResult == null || !urlValidityResult) {
      result = false;
    } else result = true;

    return result;
  };

  useEffect(() => {
    const globalHeaderSelector = document.querySelector('.global-header');

    if (isModalOpened) {
      globalHeaderSelector?.setAttribute('style', 'z-index: 1');
    } else {
      globalHeaderSelector?.setAttribute('style', 'z-index: 10');
      globalHeaderSelector?.setAttribute('style', 'transition: all 0.8s');
    }
  }, [isModalOpened]);

  useEffect(() => {
    if (
      companyWebsiteUrl?.length > 1 &&
      !isUrlValid() &&
      !companyWebsiteUrlError
    ) {
      setCompanyWebsiteUrlError(true);
    } else if (
      (!companyWebsiteUrl?.length && companyWebsiteUrlError) ||
      (isUrlValid() && companyWebsiteUrlError)
    ) {
      setCompanyWebsiteUrlError(false);
    } // eslint-disable-next-line
  }, [companyWebsiteUrl]);

  const isSubmitButtonDisabled =
    !values?.meetingTitle?.length ||
    !values?.meetingUrl?.length ||
    (selectValue === 'Create a new Account' && !accountName) ||
    isLoading ||
    meetingUrlFieldError;

  const accountsForSelect = accounts.map((account) => ({
    key: account.accountId,
    label: account.name,
    value: account.accountId,
    avatar: account.avatarSrc || '',
  }));

  const selectValues = [defaultAddAccOption, ...accountsForSelect];

  const changeSelectValue = (newValue: typeof selectValues[0]) => {
    if (newValue.value === 'new') {
      setAccountName('');
      setCompanyWebsiteUrl('');
      resetSelectedAccount();
    }
    setSelectValue(newValue.label);
    selectAccount(newValue.value);
  };

  useEffect(() => {
    if (addAccount.isSuccess) {
      const selectedAccountResult = accounts.findIndex(
        (account) => account.accountId === selectedAccount?.accountId
      );
      console.log(addAccount.isSuccess);

      if (selectedAccountResult) {
        const selectedAcc = accounts[selectedAccountResult];
        setSetSelectItemAfterSuccess({
          label: selectedAcc.name,
          value: selectedAcc.accountId,
          key: selectedAcc.accountId,
          avatar: selectedAcc.avatarSrc || '',
        });
        setSelectValue(selectedAcc.name);
        resetAddBotState();
      }
    } // eslint-disable-next-line
  }, [addAccount.isSuccess, addAccount.isLoading]);

  const closeAndClear = () => {
    changeSelectValue({ value: '', label: '', key: '' });
    setCompanyWebsiteUrlError(false);
    handleCancel();
    setAccountName('');
    setCompanyWebsiteUrl('');
  };

  const clickSubmit = () => {
    handleSubmit();
  };

  const addAccountSubmit = () => {
    createAccount(accountName, companyWebsiteUrl || values.accountWebsite);
    if (accountName !== existingAccountName) {
      setExistingAccountName(accountName);
    }
  };

  const onAddAccCancel = () => {
    resetAccountInputValue();
    resetSelectedAccount();
    changeSelectValue({ value: '', label: '', key: '' });
    setFieldValue('accountName', '');
    setFieldValue('accountWebsite', '');
    setCompanyWebsiteUrl('');
    setAccountName('');
    setCompanyWebsiteUrlError(false);
  };

  const createMeetingUrlFieldError = () => {
    let errorMessage = '';

    if (touched.meetingUrl && errors.meetingUrl) {
      errorMessage = errors.meetingUrl || '';
    } else if (meetingUrlFieldError) {
      errorMessage = 'Currently we only support Zoom, Meet and Teams';
    }

    return errorMessage;
  };

  const createAccountNameFieldError = () => {
    let errorMessage = '';

    if (!accountName?.length && touched.accountName) {
      errorMessage = 'Name is required';
    } else if (addAccount.errorType === CreateAccountErrorType.NAME_EXISTS) {
      errorMessage = 'Account name already exists';
    }

    return errorMessage;
  };

  const attrs = {
    Modal: {
      isOpened: isModalOpened,
      onClose: closeAndClear,
      className: 'add-bot__modal',
      width: 429,
    },

    wrapper: {
      className: 'add-bot',
    },

    closeIcon: {
      className: 'add-bot-close-icon',
      src: closeIcon,
      onClick: closeAndClear,
    },

    title: {
      wrapper: {
        className: 'add-bot__title',
      },

      text: {
        className: 'add-bot__title-text',
      },
    },

    loadingIcon: {
      className: 'add-bot-button-loader',
    },

    successBody: {
      wrapper: {
        className: 'add-bot__success-body',
      },
      checkmnark: {
        src: successCheckmarkIcon,
        className: 'add-bot__success-body-checkmark',
      },
      title: {
        className: 'add-bot__success-body-title',
      },
      subtitle: {
        className: 'add-bot__success-body-subtitle',
      },
      closeButton: {
        className: 'add-bot__success-body-close-button',
        onClick: closeAndClear,
      },
    },

    body: {
      wrapper: {
        className: 'add-bot__body',
      },

      inputWrapper: {
        className: 'add-bot__body-input',
      },

      inputs: {
        url: {
          label: 'Meeting URL*',
          placeholder: 'https://meet.google.com/example',
          name: 'meetingUrl',
          className: `${
            createMeetingUrlFieldError() ? 'error ' : ''
          }add-bot__body-input`,
          value: values.meetingUrl,
          onChange: (e: React.ChangeEvent<any>) => {
            handleChange(e);
            if (meetingUrlFieldError) {
              resetMeetingUrlError();
            }
          },
          error: createMeetingUrlFieldError(),
          type: 'url',
        },
        title: {
          label: 'Meeting Title*',
          placeholder: 'Project Discussion',
          name: 'meetingTitle',
          className: `${
            touched.meetingTitle && errors.meetingTitle ? 'error ' : ''
          }add-bot__body-input`,
          value: values.meetingTitle,
          onChange: handleChange,
          error: touched.meetingTitle ? errors.meetingTitle : '',
        },
        account: {
          className: 'add-bot__body-input',
          label: 'Account (optional)',
          placeholder: 'Select Account',
          isInAddBotModal: true,
          selectedItem: selectItemAfterSuccess,
          medium: true,
          items: selectValues,
          onChange: changeSelectValue,
          resetValue: selectValue === '',
          style: {
            padding: '5px 17px',
            background: '#FFFFFF',
            justifyContent: 'space-between',
            borderRadius: '4px',
          },
          selectedItemJustify: 'flex-start',
          margin: '40px 0px 0px',
        },
      },

      addAccountSection: {
        wrapper: {
          className: 'add-bot__body__add-account',
        },

        inputs: {
          wrapper: {
            className: 'add-bot__body__add-account__inputs',
          },

          accountName: {
            label: 'Account Name*',
            placeholder: 'Org`s Name',
            className: `${
              createAccountNameFieldError().length ? 'error ' : ''
            }add-bot__body-input`,
            name: 'accountName',
            value: accountName,
            onChange: (e: React.ChangeEvent<any>) => {
              handleChange(e);
              setAccountName(e.target.value);
            },
            error: createAccountNameFieldError(),
          },
          companyWebsite: {
            label: 'Company Website',
            placeholder: 'www.company.com',
            className: `${
              companyWebsiteUrlError ? 'error ' : ''
            }add-bot__body-input`,
            name: 'accountWebsite',
            value: companyWebsiteUrl,
            onChange: (e: React.ChangeEvent<any>) => {
              handleChange(e);
              setCompanyWebsiteUrl(e.target.value);
            },
            error: companyWebsiteUrlError ? 'Please enter valid URL' : '',
          },
        },

        buttons: {
          wrapper: {
            className: 'add-bot__body__add-account__buttons',
          },

          AddAccountButton: {
            type: 'submit' as ButtonType,
            className: 'add-acc add-bot__footer-button',
            variant: 'common' as ButtonVariantType,
            disabled:
              !accountName?.length ||
              (addAccount?.isError && accountName === existingAccountName),
            onClick: addAccountSubmit,
          },

          CancelButton: {
            type: 'reset' as ButtonType,
            className: 'cancel add-acc add-bot__footer-button',
            variant: 'text' as ButtonVariantType,
            onClick: onAddAccCancel,
          },
        },

        selectedAccName: {
          className: 'add-bot__body__add-account-selected-account',
        },
      },
    },

    footer: {
      wrapper: {
        className: 'add-bot__footer',
      },

      SubmitBut: {
        type: 'submit' as ButtonType,
        className: 'add-bot__footer-button',
        variant: 'common' as ButtonVariantType,
        disabled: isSubmitButtonDisabled,
        onClick: clickSubmit,
      },

      CancelBut: {
        type: 'reset' as ButtonType,
        className: 'cancel add-bot__footer-button',
        variant: 'text' as ButtonVariantType,
        onClick: closeAndClear,
      },
    },
  };

  const accInputAttrs = {
    wrapper: {
      className: 'invite-assistant-account',
      ref: accountInputwrapperRef,
      onClick: () => setPopupIsOpen(true),
    },

    input: {
      className: 'invite-assistant-account-input',
      placeholder: 'Select Account',
      onChange: updateValue,
      value: selectValue,
      label: 'Account (optional)',
      isInGlobalHeader: true,
    },

    popup: {
      wrapper: {
        className: `${
          popupIsOpen ? '' : 'closed '
        }invite-assistant-account__popup`,
      },

      body: {
        wrapper: {
          className: 'invite-assistant-account__popup__body',
        },

        overflowWrapper: {
          className: 'invite-assistant-account__popup__body__overflow',
        },

        row: {
          wrapper: (accountId: string, isCreateAccOption: boolean) => ({
            className: `${
              isCreateAccOption ? 'create-acc ' : ''
            }invite-assistant-account__popup__body__row`,
            onClick: () => {
              const selectedAcc = accounts.find(
                (account) => account.accountId === accountId
              );

              if (selectedAcc) {
                selectAccount(selectedAcc.accountId);
                setSelectValue(selectedAcc.name);
                setTimeout(() => {
                  setPopupIsOpen(false);
                }, 50);
              } else if (isCreateAccOption) {
                setSelectValue(defaultAddAccOption.label);
                setTimeout(() => {
                  setPopupIsOpen(false);
                }, 50);
              }
            },
          }),

          avatar: {
            wrapper: {
              className: 'invite-assistant-account__popup__body__row__avatar',
            },

            img: (isDefalt: boolean) => ({
              className: `${
                isDefalt ? 'default-icon ' : ''
              }invite-assistant-account__popup__body__row__avatar-img`,
            }),

            plusIcon: {
              className:
                'invite-assistant-account__popup__body__row__avatar-plus-icon',
            },

            avatar: (avatar: string) => ({
              src: avatar || '',
              size: AvatarSize.XS,
              shape: 'square' as const,
              placeholder: LogoPlaceholderIcon,
              hasBorder: !avatar,
            }),
          },

          companyName: {
            text: {
              className:
                'invite-assistant-account__popup__body__row-company-name',
            },

            textHighlighted: {
              className:
                'invite-assistant-account__popup__body__row-company-name--highlighted',
            },
          },

          email: {
            wrapper: {
              className: 'invite-assistant-account__popup__body__row__email',
            },

            text: {
              wrapper: {
                className:
                  'invite-assistant-account__popup__body__row__email__text',
              },

              letter: {
                className:
                  'invite-assistant-account__popup__body__row__email__text-letter',
              },

              letterHighlighted: {
                className:
                  'invite-assistant-account__popup__body__row__email__text-letter--highlighted',
              },
            },
          },
        },
      },
    },
  };

  const createAccountOptionRow = ({ avatar, name, accountId }: RowProps) => {
    const accountNameTokens = getStringTokens(name || '', selectValue);

    const isCreateAccOption = name === 'Create a new Account';

    const defineClassName = (isHighlighted: boolean) => {
      if (isHighlighted && !isCreateAccOption) {
        return accInputAttrs.popup.body.row.companyName.textHighlighted
          .className;
      }

      return accInputAttrs.popup.body.row.companyName.text.className;
    };

    const mapTextWithMarking = (tokens: typeof accountNameTokens) =>
      tokens.map(({ value: valueToShow, isHighlighted }, index) => {
        const props = {
          key: index,
          className: defineClassName(isHighlighted),
        };

        return <span {...props}>{valueToShow}</span>;
      });
    const mappedName = mapTextWithMarking(accountNameTokens);

    return (
      <div
        {...accInputAttrs.popup.body.row.wrapper(
          accountId || '',
          isCreateAccOption
        )}
      >
        <div {...accInputAttrs.popup.body.row.avatar.wrapper}>
          {isCreateAccOption ? (
            <AddNewAccountIcon
              {...accInputAttrs.popup.body.row.avatar.plusIcon}
            />
          ) : (
            <Avatar {...accInputAttrs.popup.body.row.avatar.avatar(avatar)} />
          )}
        </div>
        <div>{mappedName || ''}</div>
      </div>
    );
  };

  const mappedAccountRows = accountsToShow?.length
    ? accountsToShow.map((el, i) => (
        <React.Fragment key={`${el.accountId}|${i}`}>
          {createAccountOptionRow({
            avatar: el?.avatar || '',
            name: el?.label || '',
            accountId: el?.value || '',
          })}
        </React.Fragment>
      ))
    : null;

  const accPopupbody = (
    <div {...accInputAttrs.popup.body.wrapper}>{mappedAccountRows}</div>
  );

  const accPopup = (
    <div {...accInputAttrs.popup.wrapper}>
      {popupIsOpen ? accPopupbody : <></>}
    </div>
  );

  const newAccountSection =
    selectValue === 'Create a new Account' ? (
      <div {...attrs.body.addAccountSection.wrapper}>
        <div {...attrs.body.addAccountSection.inputs.wrapper}>
          <Input {...attrs.body.addAccountSection.inputs.accountName} />
          <Input {...attrs.body.addAccountSection.inputs.companyWebsite} />
        </div>
        <div {...attrs.body.addAccountSection.buttons.wrapper}>
          <Button {...attrs.body.addAccountSection.buttons.CancelButton}>
            Cancel
          </Button>
          <Button {...attrs.body.addAccountSection.buttons.AddAccountButton}>
            {addAccount.isLoading ? (
              <LoaderIcon {...attrs.loadingIcon} />
            ) : (
              'Create Account'
            )}
          </Button>
        </div>
      </div>
    ) : null;

  const conditionalBody = isSuccess ? (
    <div {...attrs.successBody.wrapper}>
      <img {...attrs.successBody.checkmnark} alt="" />
      <div {...attrs.successBody.title}>
        Substrata Bot is set to join your meeting
      </div>
      <div {...attrs.successBody.subtitle}>
        Substrata will join your meeting shortly
      </div>
      <div {...attrs.successBody.closeButton}>Close</div>
    </div>
  ) : (
    <>
      <div {...attrs.title.wrapper}>
        <div {...attrs.title.text}>Invite AI Assistant to Meeting</div>
      </div>
      <div {...attrs.body.wrapper}>
        <Input {...attrs.body.inputs.url} />
        <Input {...attrs.body.inputs.title} />
        {/* {selectedAccount ? (
          <CustomSelect {...attrs.body.inputs.account} />
        ) : (
          <FormField {...fieldProps.accountId} />
        )} */}

        {/* <FormField {...fieldProps.accountId} /> */}

        <div {...accInputAttrs.wrapper}>
          <Input {...accInputAttrs.input} />
          {accPopup}
        </div>

        {newAccountSection}
      </div>
      <div {...attrs.footer.wrapper}>
        <Button {...attrs.footer.CancelBut}>Cancel</Button>
        <Button {...attrs.footer.SubmitBut}>
          {isLoading ? <LoaderIcon {...attrs.loadingIcon} /> : 'Invite'}
        </Button>
      </div>
    </>
  );

  return (
    <Modal {...attrs.Modal}>
      <div {...attrs.wrapper}>
        <img {...attrs.closeIcon} alt="" />
        {conditionalBody}
      </div>
    </Modal>
  );
};
