import React from 'react';

export const CheckboxCheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M6 3C4.34315 3 3 4.34315 3 6V14C3 15.6569 4.34315 17 6 17H14C15.6569 17 17 15.6569 17 14V6C17 4.34315 15.6569 3 14 3H6ZM13.8536 7.85355L8.85355 12.8536C8.65829 13.0488 8.34171 13.0488 8.14645 12.8536L6.14645 10.8536C5.95118 10.6583 5.95118 10.3417 6.14645 10.1464C6.34171 9.95118 6.65829 9.95118 6.85355 10.1464L8.5 11.7929L13.1464 7.14645C13.3417 6.95118 13.6583 6.95118 13.8536 7.14645C14.0488 7.34171 14.0488 7.65829 13.8536 7.85355Z"
      fill="#537BFF"
    />
  </svg>
);
