import React, { useEffect, useState } from 'react';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { SentimentSkeleton } from '../../skeletons/SentimentSkeleton';
import { CurrCase, SentimentTooltip } from './SentimentTooltip';

type Values = {
  emailsCount: number;
  prospectsCount: number;
};

type Section = {
  negativeVs: Values;
  neutralVs: Values;
  positiveVs: Values;
};

type Props = {
  data: {
    positive: Section;
    neutral: Section;
    negative: Section;
  } | null;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
};

type ValuesResults = {
  positive: number;
  neutral: number;
  negative: number;
} | null;

type Tooltip = {
  isOpen: boolean;
  case: CurrCase;
};

export const SentimentReceived: React.FC<Props> = ({
  data,
  isLoading,
  noData,
  error,
}: Props): JSX.Element => {
  const [positiveResult, setPositiveResult] = useState<ValuesResults>(null);
  const [neutralResult, setNeutralResult] = useState<ValuesResults>(null);
  const [negativeResult, setNegativeResult] = useState<ValuesResults>(null);

  const [tooltip, setTooltip] = useState<Tooltip | null>(null);

  useEffect(() => {
    if (data?.positive) {
      const posit = data.positive?.positiveVs?.emailsCount;
      const neutr = data.positive?.neutralVs?.emailsCount;
      const negat = data.positive?.negativeVs?.emailsCount;
      const totalEmails = posit + neutr + negat;
      const resultObj = {
        positive: Math.round((posit / totalEmails) * 100),
        neutral: Math.round((neutr / totalEmails) * 100),
        negative: Math.round((negat / totalEmails) * 100),
      };
      setPositiveResult(resultObj);
    }
    if (data?.neutral) {
      const posit = data.neutral?.positiveVs?.emailsCount;
      const neutr = data.neutral?.neutralVs?.emailsCount;
      const negat = data.neutral?.negativeVs.emailsCount;
      const totalEmails = posit + neutr + negat;
      const resultObj = {
        positive: Math.round((posit / totalEmails) * 100),
        neutral: Math.round((neutr / totalEmails) * 100),
        negative: Math.round((negat / totalEmails) * 100),
      };
      setNeutralResult(resultObj);
    }
    if (data?.negative) {
      const posit = data.negative?.positiveVs?.emailsCount;
      const neutr = data.negative?.neutralVs?.emailsCount;
      const negat = data.negative?.negativeVs.emailsCount;
      const totalEmails = posit + neutr + negat;
      const resultObj = {
        positive: Math.round((posit / totalEmails) * 100),
        neutral: Math.round((neutr / totalEmails) * 100),
        negative: Math.round((negat / totalEmails) * 100),
      };
      setNegativeResult(resultObj);
    }
  }, [data]);

  const setTooltipOpen = (currCase: CurrCase) => {
    setTooltip({ isOpen: true, case: currCase });
  };
  const closeTooltip = () => {
    if (tooltip?.isOpen) {
      setTooltip(null);
    }
  };

  const attrs = {
    chartWrapper: {
      title: 'Sentiment Breakdown',
      subtitle: 'Sent vs. received',
      tooltipText: `This shows the distribution of sentiment received from prospects 
      vs. the sentiment you have conveyed in the message you sent them.`,
      width: '220px',
      isNoData: noData || error,
      upIndex: tooltip?.isOpen,
    },
    graph: {
      graphWrapper: {
        className: 'sent-wrap',
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      header: {
        wrapper: { className: 'sent-wrap__header' },
        title: { className: 'sent-wrap__header-title' },
        titleBold: { className: 'sent-wrap__header-title-bold' },
        value: { className: 'sent-wrap__header-value' },
        valueBold: { className: 'sent-wrap__header-value-bold' },
      },
      positive: {
        wrapper: { className: 'sent-wrap__positive' },
        title: { className: 'sent-wrap__positive-title' },
        valueSection: {
          wrapper: { className: 'sent-wrap__positive-wrap' },
          positive: {
            className: 'sent-wrap__positive-wrap__positive',
            style: { width: `${positiveResult?.positive}%` },
            onMouseEnter: () => setTooltipOpen('pos-pos'),
            onMouseLeave: closeTooltip,
          },
          neutral: {
            className: 'sent-wrap__positive-wrap__neutral',
            style: { width: `${positiveResult?.neutral}%` },
            onMouseEnter: () => setTooltipOpen('pos-net'),
            onMouseLeave: closeTooltip,
          },
          negative: {
            className: 'sent-wrap__positive-wrap__negative',
            style: { width: `${positiveResult?.negative}%` },
            onMouseEnter: () => setTooltipOpen('pos-neg'),
            onMouseLeave: closeTooltip,
          },
        },
      },
      neutral: {
        wrapper: { className: 'sent-wrap__neutral' },
        title: { className: 'sent-wrap__neutral-title' },
        valueSection: {
          wrapper: { className: 'sent-wrap__neutral-wrap' },
          positive: {
            className: 'sent-wrap__neutral-wrap__positive',
            style: { width: `${neutralResult?.positive}%` },
            onMouseEnter: () => setTooltipOpen('net-pos'),
            onMouseLeave: closeTooltip,
          },
          neutral: {
            className: 'sent-wrap__neutral-wrap__neutral',
            style: { width: `${neutralResult?.neutral}%` },
            onMouseEnter: () => setTooltipOpen('net-net'),
            onMouseLeave: closeTooltip,
          },
          negative: {
            className: 'sent-wrap__neutral-wrap__negative',
            style: { width: `${neutralResult?.negative}%` },
            onMouseEnter: () => setTooltipOpen('net-neg'),
            onMouseLeave: closeTooltip,
          },
        },
      },
      negative: {
        wrapper: { className: 'sent-wrap__negative' },
        title: { className: 'sent-wrap__negative-title' },
        valueSection: {
          wrapper: { className: 'sent-wrap__negative-wrap' },
          positive: {
            className: 'sent-wrap__negative-wrap__positive',
            style: { width: `${negativeResult?.positive}%` },
            onMouseEnter: () => setTooltipOpen('neg-pos'),
            onMouseLeave: closeTooltip,
          },
          neutral: {
            className: 'sent-wrap__negative-wrap__neutral',
            style: { width: `${negativeResult?.neutral}%` },
            onMouseEnter: () => setTooltipOpen('neg-net'),
            onMouseLeave: closeTooltip,
          },
          negative: {
            className: 'sent-wrap__negative-wrap__negative',
            style: { width: `${negativeResult?.negative}%` },
            onMouseEnter: () => setTooltipOpen('neg-neg'),
            onMouseLeave: closeTooltip,
          },
        },
      },
      noDataForBarsClassName: {
        className: 'sent-wrap__bars-no-data',
      },
      legend: {
        wrapper: { className: 'sent-wrap__legend' },
        positive: { className: 'sent-wrap__legend-positive' },
        neutral: { className: 'sent-wrap__legend-neutral' },
        negative: { className: 'sent-wrap__legend-negative' },
      },
      Tooltip: {
        data,
        open: tooltip?.isOpen,
        currCase: tooltip?.case,
      },
    },
  };

  const positiveBar =
    positiveResult?.positive ||
    positiveResult?.neutral ||
    positiveResult?.negative ? (
      <div {...attrs.graph.positive.valueSection.wrapper}>
        {positiveResult?.negative ? (
          <div {...attrs.graph.positive.valueSection.negative}>
            {tooltip?.case === 'pos-neg' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {positiveResult?.negative > 9 ? `${positiveResult?.negative}%` : ''}
          </div>
        ) : null}
        {positiveResult?.neutral ? (
          <div {...attrs.graph.positive.valueSection.neutral}>
            {tooltip?.case === 'pos-net' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {positiveResult?.neutral > 11 ? `${positiveResult?.neutral}%` : ''}
          </div>
        ) : null}
        {positiveResult?.positive ? (
          <div {...attrs.graph.positive.valueSection.positive}>
            {tooltip?.case === 'pos-pos' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {positiveResult?.positive > 9 ? `${positiveResult?.positive}%` : ''}
          </div>
        ) : null}
      </div>
    ) : (
      <div {...attrs.graph.noDataForBarsClassName}>N/A</div>
    );

  const neutralBar =
    neutralResult?.positive ||
    neutralResult?.neutral ||
    neutralResult?.negative ? (
      <div {...attrs.graph.neutral.valueSection.wrapper}>
        {neutralResult?.negative ? (
          <div {...attrs.graph.neutral.valueSection.negative}>
            {tooltip?.case === 'net-neg' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {neutralResult?.negative > 9 ? `${neutralResult?.negative}%` : ''}
          </div>
        ) : null}
        {neutralResult?.neutral ? (
          <div {...attrs.graph.neutral.valueSection.neutral}>
            {tooltip?.case === 'net-net' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {neutralResult?.neutral > 9 ? `${neutralResult?.neutral}%` : ''}
          </div>
        ) : null}
        {neutralResult?.positive ? (
          <div {...attrs.graph.neutral.valueSection.positive}>
            {tooltip?.case === 'net-pos' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {neutralResult?.positive > 9 ? `${neutralResult?.positive}%` : ''}
          </div>
        ) : null}
      </div>
    ) : (
      <div {...attrs.graph.noDataForBarsClassName}>N/A</div>
    );

  const negativeBar =
    negativeResult?.positive ||
    negativeResult?.neutral ||
    negativeResult?.negative ? (
      <div {...attrs.graph.negative.valueSection.wrapper}>
        {negativeResult?.negative ? (
          <div {...attrs.graph.negative.valueSection.negative}>
            {tooltip?.case === 'neg-neg' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {negativeResult?.negative > 9 ? `${negativeResult?.negative}%` : ''}
          </div>
        ) : null}
        {negativeResult?.neutral ? (
          <div {...attrs.graph.negative.valueSection.neutral}>
            {tooltip?.case === 'neg-net' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {negativeResult?.neutral > 9 ? `${negativeResult?.neutral}%` : ''}
          </div>
        ) : null}
        {negativeResult?.positive ? (
          <div {...attrs.graph.negative.valueSection.positive}>
            {tooltip?.case === 'neg-pos' && tooltip.isOpen ? (
              <SentimentTooltip {...attrs.graph.Tooltip} />
            ) : null}
            {negativeResult?.positive > 9 ? `${negativeResult?.positive}%` : ''}
          </div>
        ) : null}
      </div>
    ) : (
      <div {...attrs.graph.noDataForBarsClassName}>N/A</div>
    );

  return (
    <SentimentSkeleton isLoading={isLoading}>
      <>
        <ChartWrapper {...attrs.chartWrapper}>
          <div {...attrs.graph.graphWrapper}>
            <div {...attrs.graph.legend.wrapper}>
              <div {...attrs.graph.legend.negative}>Negative</div>
              <div {...attrs.graph.legend.neutral}>Neutral</div>
              <div {...attrs.graph.legend.positive}>Positive</div>
            </div>
            <div {...attrs.graph.header.wrapper}>
              <div {...attrs.graph.header.title}>Sentiment sent</div>
              <div {...attrs.graph.header.value}>Sentiment received</div>
            </div>
            <div {...attrs.graph.positive.wrapper}>
              <div {...attrs.graph.positive.title}>Positive</div>
              {positiveBar}
            </div>
            <div {...attrs.graph.neutral.wrapper}>
              <div {...attrs.graph.neutral.title}>Neutral</div>
              {neutralBar}
            </div>
            <div {...attrs.graph.negative.wrapper}>
              <div {...attrs.graph.negative.title}>Negative</div>
              {negativeBar}
            </div>
          </div>
        </ChartWrapper>
      </>
    </SentimentSkeleton>
  );
};
