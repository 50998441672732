import React from 'react';
import { OptionIconProps } from '../../../components/Popup/PopupOption';

export const AssignLeaderPopupIcon: React.FC<OptionIconProps> = ({
  disabled,
}): JSX.Element => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={disabled ? '0.4' : '1'}
    >
      <path
        d="M5.07964 4.62719L6.00009 1.7943L6.92055 4.62718C7.01427 4.9156 7.28303 5.11087 7.58629 5.11087H10.565L8.15517 6.86169C7.90983 7.03994 7.80717 7.3559 7.90088 7.64431L8.82134 10.4772L6.41154 8.72638C6.1662 8.54813 5.83399 8.54813 5.58864 8.72638L3.17885 10.4772L4.09931 7.64431C4.19302 7.3559 4.09036 7.03994 3.84502 6.86169L1.43523 5.11087H4.4139C4.71715 5.11087 4.98592 4.9156 5.07964 4.62719Z"
        stroke="white"
      />
    </svg>
  );
};
