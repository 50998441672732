import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import { DateTime } from 'luxon';
import { RootState } from '../../../../store/store';
import { callEventIcon, crmIconInactive, mailEventIcon } from './assets';
import { Relationship } from '../../../../types/relationship.types';
import { getRelationshipAgeString } from '../../../../components/RelationshipDetailsHeader/utils/getRelationshipAgeString';
import { RelationshipEventType } from '../../../../types/relationshipEvent.types';
import { ChevronDownIcon, LogoPlaceholderIcon } from '../../../../components';
import { SortIcon } from '../../../MeetingRecords/components/MeetingRecordsList/icons/SortIcon';
import { RSSortBy } from '../../../../store/relationships/relationships.types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateSorting } from '../../../../store/relationships/relationships.slice';
import { getRSCurrentSorting } from './utils/getRSCurrentSorting';
import {
  Avatar,
  AvatarSize,
} from '../../../../components/SoundWave/components/Avatar/Avatar';
import { Contact } from '../../../../types/contact.types';
import { Tooltip, TooltipPosition } from '../../../../components/SoundWave';

interface RelationshipsSubrow {
  event: string;
  dateAndTime: string;
  participants: string;
  id: string;
}

interface Props {
  relationship?: Relationship;
  isLoading?: boolean;
  isTitle?: boolean;
  subrowData?: RelationshipsSubrow[];
}

export const RelationshipsTableRow: React.FC<Props> = ({
  isTitle = false,
  isLoading = false,
  relationship,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { sortBy, sortOrder } = useAppSelector(
    (state: RootState) => state.relationships.relationships
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const history = useHistory();

  const dispatch = useAppDispatch();

  const relationshipAgeString: string = useMemo(
    () => getRelationshipAgeString(relationship?.account?.createdAt || ''),
    [relationship?.account?.createdAt]
  );

  const accordionHeight = relationship?.touchPoints?.length
    ? 70 + relationship?.touchPoints?.length * 65
    : 0;

  const goToRelationshipEventsPage = () => {
    if (relationship?.touchPoints && relationship.touchPoints?.length >= 5) {
      history.push(
        `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${
          relationship?.account?.accountId || ''
        }#events`
      );
    }
  };

  const expandEvents = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (relationship?.touchPoints?.length) {
      setIsExpanded(!isExpanded);
    }
  };

  const goToRSDetailsPage = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (user && relationship?.account?.accountId) {
      const { orgId, userId } = user;
      history.push(
        `/orgs/${orgId}/users/${userId}/relationships/${relationship?.account?.accountId}`
      );
    }
  };

  const updateSortingParams = (newSortByValue: RSSortBy) => {
    dispatch(
      updateSorting(getRSCurrentSorting(newSortByValue, sortBy, sortOrder))
    );
  };

  const attrs = {
    wrapper: {
      className: `${isTitle ? 'title-row' : ''} ${
        isExpanded ? 'expanded' : ''
      } rs-table-row`,
    },

    titleRow: {
      wrapper: {
        className: 'title rs-table-row__main-row',
      },
    },

    row: {
      wrapper: {
        className: 'rs-table-row__main-row',
        onClick: goToRSDetailsPage,
      },

      tooltip: {
        className: 'rs-table-row__main-row-tooltip',
      },

      cell: {
        wrapper: {
          className: 'rs-table-row__main-row__cell',
        },

        avatar: {
          hasBorder: false,
          placeholder: LogoPlaceholderIcon,
          src: relationship?.account?.avatarSrc || '',
          shape: 'square' as const,
          className: 'rs-table-row__main-row-avatar',
        },

        label: {
          wrapper: {
            className: 'rs-table-row__main-row__label',
          },

          tooltip: {
            className: 'rs-table-row__main-row__label-tooltip',
          },

          sortIcons: {
            account: {
              className: `rs-table-row__main-row__label-sort-icon${
                sortOrder && sortBy === RSSortBy.ACCOUNT_NAME
                  ? ` meeting-records-list__sort-icon--${sortOrder}`
                  : ''
              }`,
            },
            lastActivity: {
              className: `rs-table-row__main-row__label-sort-icon${
                sortOrder && sortBy === RSSortBy.LAST_ACTIVITY
                  ? ` meeting-records-list__sort-icon--${sortOrder}`
                  : ''
              }`,
            },
            createdAt: {
              className: `rs-table-row__main-row__label-sort-icon${
                sortOrder && sortBy === RSSortBy.CREATED_AT
                  ? ` meeting-records-list__sort-icon--${sortOrder}`
                  : ''
              }`,
            },
            numberOfEvents: {
              className: `rs-table-row__main-row__label-sort-icon${
                sortOrder && sortBy === RSSortBy.EVENTS_NUMBER
                  ? ` meeting-records-list__sort-icon--${sortOrder}`
                  : ''
              }`,
            },
          },

          lastCellTooltip: {
            className: 'last-cell rs-table-row__main-row__label-tooltip',
            style: isExpanded ? { display: 'none' } : {},
          },
        },

        crmIcon: {
          wrapper: {
            className: 'rs-table-row__main-row__icon',
          },

          icon: {
            // src: crmIconInactive || crmIconActive,
            src: crmIconInactive,
            className: 'rs-table-row__main-row__icon-img',
          },

          tooltip: {
            className: 'rs-table-row__main-row__icon-tooltip',
          },
        },

        expandButton: {
          className: `${
            !relationship?.touchPoints?.length ? 'disabled ' : ''
          }rs-table-row__main-row__expand-button`,
          onClick: expandEvents,
        },

        expandButtonIcon: {
          className: 'rs-table-row__main-row__expand-button-icon',
        },

        Skeleton: {
          active: true,
          paragraph: false,
          style: {
            width: 120,
          },
          title: {
            style: {
              width: '100%',
              height: 24,
              margin: 0,
              borderRadius: 4,
            },
          },
        },
      },
    },

    subrow: {
      wrapper: {
        className: 'rs-table-row__subrow',
        style: { height: isExpanded ? accordionHeight : 0 },
      },

      overflow: {
        className: 'rs-table-row__subrow-overflow',
      },

      verticalBorderSection: {
        wrapper: {
          className: 'rs-table-row__subrow__vertical-border-section',
        },

        border: {
          className: 'rs-table-row__subrow__vertical-border-section-line',
        },
      },

      rowsSection: {
        wrapper: {
          className: 'rs-table-row__subrow__rows-section',
        },

        row: {
          wrapper: {
            className: 'rs-table-row__subrow__rows-section__row',
          },

          cell: {
            wrapper: {
              className: 'rs-table-row__subrow__rows-section__row__cell',
            },

            mailEventIcon: {
              className:
                'rs-table-row__subrow__rows-section__row__cell-event-icon',
              src: mailEventIcon,
            },

            callEventIcon: {
              className:
                'rs-table-row__subrow__rows-section__row__cell-event-icon',
              src: callEventIcon,
            },

            eventName: {
              className:
                'rs-table-row__subrow__rows-section__row__cell-event-name',
            },

            participants: {
              wrapper: {
                className:
                  'rs-table-row__subrow__rows-section__row__cell__participants',
              },

              avatar: {
                className:
                  'rs-table-row__subrow__rows-section__row__cell__participants-avatar',
              },

              left: {
                className:
                  'rs-table-row__subrow__rows-section__row__cell__participants-text',
              },
            },

            link: {
              className: 'rs-table-row__subrow__rows-section__row__cell-link',
            },

            linkTooltip: {
              className:
                'rs-table-row__subrow__rows-section__row__cell-link-tooltip',
            },

            tooltip: {
              className:
                'rs-table-row__subrow__rows-section__row__cell-tooltip',
            },
          },
        },
      },
    },
  };

  const arrayOfEventDates = relationship?.touchPoints?.length
    ? relationship.touchPoints.map((el) =>
        new Date(el.lastActivity).toISOString()
      )
    : [];

  const maxDate =
    arrayOfEventDates?.length &&
    arrayOfEventDates.reduce((a, b) => {
      return a > b ? a : b;
    });

  const lastAcivityDate = maxDate
    ? DateTime.fromISO(maxDate, {
        setZone: true,
      }).toFormat('MMM dd, yyyy')
    : '-';

  const skeleton = <Skeleton {...attrs.row.cell.Skeleton} />;

  const titleRow = (
    <div {...attrs.titleRow.wrapper}>
      <div {...attrs.row.cell.wrapper}>
        <Tooltip
          text="Sort by Account Name"
          simplified
          position={TooltipPosition.BOTTOM_START}
          width={162}
        >
          <div
            {...attrs.row.cell.label.wrapper}
            onClick={() => updateSortingParams(RSSortBy.ACCOUNT_NAME)}
          >
            Account
            <SortIcon {...attrs.row.cell.label.sortIcons.account} />
            {/* <div {...attrs.row.cell.label.tooltip}></div> */}
          </div>
        </Tooltip>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div
          {...attrs.row.cell.label.wrapper}
          onClick={() => updateSortingParams(RSSortBy.LAST_ACTIVITY)}
        >
          Last Event
          <SortIcon {...attrs.row.cell.label.sortIcons.lastActivity} />
          <div {...attrs.row.cell.label.tooltip}>Sort by Last Interaction</div>
        </div>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div
          {...attrs.row.cell.label.wrapper}
          onClick={() => updateSortingParams(RSSortBy.CREATED_AT)}
        >
          Relationship Age
          <SortIcon {...attrs.row.cell.label.sortIcons.createdAt} />
          <div {...attrs.row.cell.label.tooltip}>Sort by Relationship Age</div>
        </div>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div
          {...attrs.row.cell.label.wrapper}
          onClick={() => updateSortingParams(RSSortBy.EVENTS_NUMBER)}
        >
          Events
          <SortIcon {...attrs.row.cell.label.sortIcons.numberOfEvents} />
          <div {...attrs.row.cell.label.tooltip}>Sort by Event Count</div>
        </div>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div {...attrs.row.cell.label.wrapper}>Employees</div>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div {...attrs.row.cell.label.wrapper}>Market Category</div>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div {...attrs.row.cell.label.wrapper}>
          Deal Stage
          <div {...attrs.row.cell.label.tooltip}>CRM Deal Stage</div>
        </div>
        <div {...attrs.row.cell.crmIcon.wrapper}>
          <img {...attrs.row.cell.crmIcon.icon} alt="" />
          <div {...attrs.row.cell.crmIcon.tooltip}>CRM Connection: Not set</div>
        </div>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div {...attrs.row.cell.label.wrapper}>
          Est.Deal Size
          <div {...attrs.row.cell.label.tooltip}>
            Est. Deal Size based on CRM Data
          </div>
        </div>
        <div {...attrs.row.cell.crmIcon.wrapper}>
          <img {...attrs.row.cell.crmIcon.icon} alt="" />
          <div {...attrs.row.cell.crmIcon.tooltip}>CRM Connection: Not set</div>
        </div>
      </div>
      <div {...attrs.row.cell.wrapper}>
        <div {...attrs.row.cell.expandButton} />
      </div>
    </div>
  );

  const onOpenEventClick = (
    eventType: RelationshipEventType,
    touchpointId: string
  ) => {
    if (eventType === RelationshipEventType.EMAIL_BRANCH) {
      history.push(
        `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${
          relationship?.account?.accountId
        }/threads/${touchpointId || ''}`
      );
    } else if (eventType === RelationshipEventType.ONLINE_MEETING) {
      history.push(
        `/orgs/${user?.orgId}/users/${user?.userId}/meeting-records/${
          touchpointId || ''
        }`
      );
    }
  };

  const createParticipantsCell = (contacts: Contact[] | []) => {
    if (contacts.length > 3) {
      return (
        <>
          {contacts.slice(0, 3).map((contact, index) => (
            <React.Fragment key={index}>
              <Avatar
                {...attrs.subrow.rowsSection.row.cell.participants.avatar}
                size={AvatarSize.XS}
                src={contact?.metadata?.avatarSrc || ''}
                name={contact?.metadata?.firstName || ''}
              />
            </React.Fragment>
          ))}
          <div {...attrs.subrow.rowsSection.row.cell.participants.avatar}>
            +{contacts.length - 3}
          </div>
        </>
      );
    }
    if (contacts.length >= 1 && contacts.length <= 3) {
      return contacts.map((contact, index) => (
        <React.Fragment key={index}>
          <Avatar
            {...attrs.subrow.rowsSection.row.cell.participants.avatar}
            size={AvatarSize.XS}
            src={contact?.metadata?.avatarSrc || ''}
            name={contact?.metadata?.firstName || ''}
          />
        </React.Fragment>
      ));
    }

    return '-';
  };

  const subrows = relationship?.touchPoints?.length
    ? relationship.touchPoints.map((el, i) => (
        <div {...attrs.subrow.rowsSection.row.wrapper} key={`${el}|${i}`}>
          <div {...attrs.subrow.rowsSection.row.cell.wrapper}>
            {el.type === RelationshipEventType.EMAIL_BRANCH ? (
              <img
                {...attrs.subrow.rowsSection.row.cell.mailEventIcon}
                alt=""
              />
            ) : (
              <img
                {...attrs.subrow.rowsSection.row.cell.callEventIcon}
                alt=""
              />
            )}{' '}
            <div {...attrs.subrow.rowsSection.row.cell.eventName}>
              {el?.title || 'N/A'}
            </div>
          </div>
          <div {...attrs.subrow.rowsSection.row.cell.wrapper}>
            {DateTime.fromISO(el?.startDateTime, {
              setZone: true,
            }).toFormat('MMM dd, yyyy, hh:mm a')}
          </div>
          <div {...attrs.subrow.rowsSection.row.cell.wrapper}>
            <div {...attrs.subrow.rowsSection.row.cell.participants.wrapper}>
              {createParticipantsCell(el?.contacts || [])}
            </div>
          </div>
          <div {...attrs.subrow.rowsSection.row.cell.wrapper}>
            <div
              {...attrs.subrow.rowsSection.row.cell.link}
              onClick={() => onOpenEventClick(el.type, el.touchPointId)}
            >
              Open
              <div {...attrs.subrow.rowsSection.row.cell.linkTooltip}>
                Open Event Page
              </div>
            </div>
          </div>
        </div>
      ))
    : null;

  const isAllEventslinkShown =
    relationship?.touchPoints?.length && relationship.touchPoints.length >= 5;

  const subrowSection = (
    <div {...attrs.subrow.wrapper}>
      <div {...attrs.subrow.overflow}>
        <div {...attrs.subrow.verticalBorderSection.wrapper}>
          <div {...attrs.subrow.verticalBorderSection.border} />
        </div>
        <div {...attrs.subrow.rowsSection.wrapper}>
          <div {...attrs.subrow.rowsSection.row.wrapper}>
            <div {...attrs.subrow.rowsSection.row.cell.wrapper}>Event</div>
            <div {...attrs.subrow.rowsSection.row.cell.wrapper}>
              Date and Time
            </div>
            <div {...attrs.subrow.rowsSection.row.cell.wrapper}>
              Participants
            </div>
            <div
              {...attrs.subrow.rowsSection.row.cell.wrapper}
              onClick={() => goToRelationshipEventsPage()}
            >
              {isAllEventslinkShown ? 'All Events' : ''}
              {isAllEventslinkShown ? (
                <div {...attrs.subrow.rowsSection.row.cell.tooltip}>
                  View All Events
                </div>
              ) : null}
            </div>
          </div>
          {subrows}
        </div>
      </div>
    </div>
  );

  const companyAvatar = <Avatar {...attrs.row.cell.avatar} />;

  const estimatedDealSize = relationship?.dealDetails?.estimatedValue
    ? `${relationship?.dealDetails?.estimatedValue} ${
        relationship?.dealDetails?.currency || ''
      }`
    : '-';

  return (
    <div {...attrs.wrapper}>
      {isTitle ? (
        titleRow
      ) : (
        <>
          <div {...attrs.row.wrapper}>
            <div {...attrs.row.cell.wrapper}>
              {companyAvatar}
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>
                  {relationship?.account?.name || '-'}
                </div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>{lastAcivityDate}</div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>
                  {relationshipAgeString}
                </div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>
                  {relationship?.touchPointsCount || '-'}
                </div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>
                  {relationship?.companyData?.employees || '-'}
                </div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>
                  {relationship?.companyData?.category || '-'}
                  <div {...attrs.row.cell.label.tooltip}>Viev Relationship</div>
                </div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>
                  {relationship?.dealDetails?.stage || '-'}
                </div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>{estimatedDealSize}</div>
              )}
            </div>
            <div {...attrs.row.cell.wrapper}>
              {isLoading ? (
                skeleton
              ) : (
                <div {...attrs.row.cell.label.wrapper}>
                  {isAllEventslinkShown && isExpanded ? (
                    <div
                      {...attrs.subrow.rowsSection.row.cell.link}
                      onClick={() => goToRelationshipEventsPage()}
                    >
                      Open
                      {/* <div {...attrs.subrow.rowsSection.row.cell.linkTooltip}>
                        Open Event Page
                      </div> */}
                    </div>
                  ) : null}
                  <div {...attrs.row.cell.expandButton}>
                    <ChevronDownIcon {...attrs.row.cell.expandButtonIcon} />
                  </div>
                  <div {...attrs.row.cell.label.lastCellTooltip}>
                    {!relationship?.touchPoints?.length
                      ? 'N/A'
                      : 'Expand Events'}
                  </div>
                </div>
              )}
            </div>
            <div {...attrs.row.tooltip}>View Relationship</div>
          </div>
          {subrowSection}
        </>
      )}
    </div>
  );
};
