import React from 'react';

export const SendIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="send">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.89762 6.60241C3.3881 4.05479 6.13756 2.10798 8.37134 3.43471L18.5696 9.49185C19.4545 10.0174 19.9969 10.9704 19.9969 11.9996C19.9969 13.0288 19.4545 13.9818 18.5696 14.5074L8.37134 20.5645C6.13757 21.8913 3.3881 19.9445 3.89762 17.3968L4.77706 12.9996H11.9969C12.5491 12.9996 12.9969 12.5519 12.9969 11.9996C12.9969 11.4473 12.5491 10.9996 11.9969 10.9996H4.77707L3.89762 6.60241Z"
        fill="#95A1B6"
      />
    </g>
  </svg>
);
