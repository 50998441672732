import React, { useEffect, useRef, useState } from 'react';
import { EditIcon } from '../Icons/EditIcon';
import { lock } from './assets';

type LabelProps = {
  children?: string | JSX.Element;
  onClick?: () => void;
  title: string | undefined;
  value: string | undefined;
  disabled?: boolean;
  image?: string;
  hasLock?: boolean;
  isLowerHeight?: boolean;
};

const Label: React.FC<LabelProps> = ({
  children,
  onClick,
  title = '',
  value = '',
  disabled = false,
  image,
  hasLock = false,
  isLowerHeight = false,
}: LabelProps): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(0);

  const valueWrapperRef = useRef<HTMLDivElement>(null);

  const valueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateScreenWidth = () => {
      if (typeof screenWidth === 'number' && window.innerWidth && hasLock) {
        setScreenWidth(window.innerWidth);
      }
    };

    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    }; // eslint-disable-next-line
  }, []);

  const defineValueCss = () => {
    let hasTooltip = false;

    let lockPosition = '100%';

    if (valueWrapperRef.current?.offsetWidth && valueRef.current?.offsetWidth) {
      if (
        valueWrapperRef.current.offsetWidth ===
        valueRef.current.offsetWidth + 20
      ) {
        hasTooltip = true;
      }

      if (hasLock) {
        lockPosition = `${valueRef.current.offsetWidth + 53}px`;
      }
    }

    return { hasTooltip, lockPosition };
  };

  const { hasTooltip, lockPosition } = defineValueCss();

  const attrs = {
    wrapper: {
      className: `${isLowerHeight ? 'lower' : ''} label`,
    },

    imageSection: {
      wrapper: {
        className: 'label__image-section',
      },

      img: {
        className: 'label__image-section-img',
        src: image,
      },
    },

    infoSection: {
      wrapper: {
        className: 'label__body',
      },

      title: {
        className: `${isLowerHeight ? 'lower' : ''} label__body-title`,
      },

      valueSection: {
        wrapper: {
          className: 'label__body__value',
          ref: valueWrapperRef,
        },

        value: {
          className: 'label__body__value-text',
          ref: valueRef,
        },

        tooltip: {
          className: `${hasTooltip ? '' : 'hidden'} label__body__value-tooltip`,
        },

        lock: {
          wrapper: {
            className: `${
              isLowerHeight ? 'lower' : ''
            } label__body__value__lock`,
            style: {
              left: lockPosition,
              display: lockPosition !== '100%' ? 'flex' : 'none',
            },
          },

          icon: {
            className: 'label__body__value__lock-icon',
            src: lock,
          },

          tooltip: {
            className: 'label__body__value__lock-tooltip',
          },
        },
      },

      tooltip: {
        wrapper: {
          className: 'label__body__tooltip-section',
        },

        icon: {
          className: 'label__body__tooltip-section-icon',
        },
      },
    },

    edit: {
      wrapper: {
        className: `${disabled ? 'hidden' : ''} ${
          isLowerHeight ? 'lower' : ''
        } label__edit`,
        onClick,
      },

      icon: {
        className: 'label__edit-icon',
      },
    },
  };

  const conditionalImage = image?.length ? (
    <div {...attrs.imageSection.wrapper}>
      <img {...attrs.imageSection.img} alt="img" />
    </div>
  ) : null;

  const conditionalLock =
    hasLock && value.length ? (
      <div {...attrs.infoSection.valueSection.lock.wrapper}>
        <img {...attrs.infoSection.valueSection.lock.icon} alt="lock" />
        <div {...attrs.infoSection.valueSection.lock.tooltip}>
          Email cannot be changed
        </div>
      </div>
    ) : null;

  return (
    <div {...attrs.wrapper}>
      {conditionalImage}
      <div {...attrs.infoSection.wrapper}>
        <div {...attrs.infoSection.title}>{title}</div>
        <div {...attrs.infoSection.valueSection.wrapper}>
          <div {...attrs.infoSection.valueSection.value}>{value}</div>
          <div {...attrs.infoSection.valueSection.tooltip}>{value}</div>
        </div>
        {conditionalLock}
        <div {...attrs.edit.wrapper}>
          <EditIcon {...attrs.edit.icon} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Label;
