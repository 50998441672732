import React from 'react';

export const ActivateIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.66634 1.99967C8.66634 1.63148 8.36786 1.33301 7.99967 1.33301C7.63148 1.33301 7.33301 1.63148 7.33301 1.99967V5.99967C7.33301 6.36786 7.63148 6.66634 7.99967 6.66634C8.36786 6.66634 8.66634 6.36786 8.66634 5.99967V1.99967Z"
      fill="#10B981"
    />
    <path
      d="M10.963 2.02634C10.6333 1.86249 10.2332 1.99694 10.0693 2.32666C9.90548 2.65638 10.0399 3.05651 10.3697 3.22036C12.1274 4.09388 13.333 5.90659 13.333 7.99967C13.333 10.9452 10.9452 13.333 7.99967 13.333C5.05416 13.333 2.66634 10.9452 2.66634 7.99967C2.66634 5.90659 3.87197 4.09388 5.6297 3.22036C5.95942 3.05651 6.09387 2.65638 5.93002 2.32666C5.76616 1.99694 5.36604 1.86249 5.03632 2.02634C2.8428 3.11643 1.33301 5.38112 1.33301 7.99967C1.33301 11.6816 4.31778 14.6663 7.99967 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99967C14.6663 5.38112 13.1566 3.11643 10.963 2.02634Z"
      fill="#10B981"
    />
  </svg>
);
