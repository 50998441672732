import React, { useState } from 'react';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Tooltip,
} from '../../../../components/SoundWave';
import { UserConnection } from '../../../../types';
import {
  ComingSoonIcon,
  ConnectedIcon,
  DisconnectIcon,
  ErrorIcon,
  LoaderIcon,
  WarningIcon,
} from './icons';

export interface IntegrationCardProps {
  id?: UserConnection | string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title?: string;
  description?: string;
  additionalInfo?: string;
  statusText?: string;
  isError?: boolean;
  isConnected?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isComingSoon?: boolean;
  tooltip?: string;
  isTooltipSimplified?: boolean;
  buttonVariant?: ButtonVariant;
  buttonClassName?: string;
  buttonText?: string;
  buttonIcon?: React.FC<React.SVGProps<SVGSVGElement>> | null;
  isPlaceholder?: boolean;
  isAnimated?: boolean;
  onConnect?: () => Promise<void> | void;
  onDisconnect?: () => Promise<void> | void;
}

export const IntegrationCard: React.FC<IntegrationCardProps> = ({
  id,
  icon: IconComponent,
  title,
  description,
  additionalInfo,
  statusText,
  isError = false,
  isConnected = false,
  isLoading = false,
  isDisabled = false,
  isComingSoon = false,
  tooltip,
  isTooltipSimplified = false,
  buttonVariant = ButtonVariant.Secondary,
  buttonClassName,
  buttonText,
  buttonIcon: ButtonIconComponent,
  isPlaceholder = false,
  isAnimated = false,
  onConnect,
  onDisconnect,
}) => {
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  const isButtonDisabled = isDisabled || isLoading || isComingSoon;

  const attrs = {
    container: {
      id,
      className: `integration-card${
        isPlaceholder ? ' integration-card--placeholder' : ''
      }${isAnimated && !isPlaceholder ? ' integration-card--animated' : ''}`,
    },
    icon: {
      className: 'integration-card__icon',
    },
    textWrapper: {
      className: 'integration-card__text-wrapper',
    },
    title: {
      className: 'integration-card__title',
    },
    text: {
      className: 'integration-card__text',
    },
    textGray: {
      className: 'integration-card__text integration-card__text--gray',
    },
    status: {
      className: 'integration-card__status',
    },
    statusError: {
      className: 'integration-card__status integration-card__status--error',
    },
    tooltip: {
      text: tooltip,
      className: 'integration-card__tooltip-container',
      contentClassName: 'integration-card__tooltip',
      simplified: isTooltipSimplified,
    },
    button: {
      className: `integration-card__button${
        isLoading ? ' integration-card__button--loading' : ''
      }${buttonClassName ? ` ${buttonClassName}` : ''}`,
      variant: buttonVariant,
      disabled: isButtonDisabled,
      size: ButtonSize.S,
      onMouseEnter: () => {
        setIsButtonHovered(true);
      },
      onMouseLeave: () => {
        setIsButtonHovered(false);
      },
      onClick: () => {
        if (isButtonDisabled) {
          return;
        }

        if (isConnected) {
          onDisconnect?.();
          return;
        }

        onConnect?.();
      },
    },
    buttonIcon: {
      className: 'integration-card__button-icon',
    },
    buttonLoader: {
      className: `integration-card__button-loader${
        buttonVariant === ButtonVariant.Primary
          ? ' integration-card__button-loader--primary'
          : ''
      }`,
    },
    placeholderIcon: {
      className: 'integration-card__placeholder-icon',
    },
  };

  const getButtonText = () => {
    if (buttonText) {
      return buttonText;
    }

    if (isButtonHovered && isConnected) {
      return 'Disconnect';
    }

    if (isComingSoon) {
      return 'Coming Soon';
    }

    if (isConnected) {
      return 'Connected';
    }

    return 'Connect';
  };

  const getButtonIcon = () => {
    if (isLoading || ButtonIconComponent) {
      return null;
    }

    if (isButtonHovered && isConnected) {
      return <DisconnectIcon {...attrs.buttonIcon} />;
    }

    if (isComingSoon) {
      return <ComingSoonIcon {...attrs.buttonIcon} />;
    }

    if (isConnected) {
      return <ConnectedIcon {...attrs.buttonIcon} />;
    }

    return null;
  };

  const renderButton = () => {
    if (tooltip) {
      return (
        <Tooltip {...attrs.tooltip}>
          <Button {...attrs.button}>
            {ButtonIconComponent ? <ButtonIconComponent /> : null}
            <span>{getButtonText()}</span>
            {getButtonIcon()}
            {isLoading ? <LoaderIcon {...attrs.buttonLoader} /> : null}
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button {...attrs.button}>
        {ButtonIconComponent ? <ButtonIconComponent /> : null}
        <span>{getButtonText()}</span>
        {getButtonIcon()}
        {isLoading ? <LoaderIcon {...attrs.buttonLoader} /> : null}
      </Button>
    );
  };

  if (isPlaceholder) {
    return (
      <div {...attrs.container}>
        <div {...attrs.textWrapper}>
          <ComingSoonIcon {...attrs.placeholderIcon} />
          <div {...attrs.textGray}>More Coming Soon</div>
        </div>
      </div>
    );
  }

  return (
    <div {...attrs.container}>
      {IconComponent ? <IconComponent {...attrs.icon} /> : null}
      <div {...attrs.textWrapper}>
        {title ? <div {...attrs.title}>{title}</div> : null}
        {description ? <div {...attrs.textGray}>{description}</div> : null}
        {additionalInfo ? <div {...attrs.text}>{additionalInfo}</div> : null}
      </div>
      {statusText && (
        <div {...(isError ? attrs.statusError : attrs.status)}>
          {isError ? <ErrorIcon /> : <WarningIcon />}
          <span>{statusText}</span>
        </div>
      )}
      {renderButton()}
    </div>
  );
};
