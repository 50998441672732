import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from '../../../../../../components';
import { RootState } from '../../../../../../store/store';

type OptionsProps = {
  options: JSX.Element[];
};

export const Options: React.FC<OptionsProps> = ({
  options,
}: OptionsProps): JSX.Element => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as Node;
    const isOptionsTarget = optionsRef.current?.contains(target);
    if (isOptionsTarget) setOpen(!open);
    else if (!isOptionsTarget && open) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => document.removeEventListener('click', handleClick, true);
  });

  return (
    <div
      ref={optionsRef}
      className={
        currentUser?.role !== 'dealmaker'
          ? 'teams-options'
          : 'hidden teams-options'
      }
      style={open ? { opacity: '1', backgroundColor: '#e5e5e5' } : {}}
    >
      <div className="teams-options__dot" />
      <div className="teams-options__dot" />
      <div className="teams-options__dot" />
      <Popup open={open} className="teams-options__popup" options={options} />
    </div>
  );
};
