import React from 'react';

export const NextSpeakerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.66671 7.99992C2.66671 5.04891 5.03669 2.66659 7.94801 2.66659C9.7156 2.66659 11.2823 3.54309 12.2427 4.8951C12.3884 5.10022 12.6372 5.20584 12.8859 5.16819C13.1347 5.13054 13.3411 4.95603 13.4196 4.71698L13.9668 3.05021C14.0816 2.70039 13.8911 2.3237 13.5413 2.20886C13.1915 2.09401 12.8148 2.2845 12.7 2.63432L12.5201 3.18227C11.3334 2.03813 9.72296 1.33325 7.94801 1.33325C4.28938 1.33325 1.33337 4.32351 1.33337 7.99992C1.33337 11.6763 4.28938 14.6666 7.94801 14.6666C10.1686 14.6666 12.1318 13.5633 13.3297 11.8769C13.5429 11.5767 13.4724 11.1605 13.1722 10.9473C12.8721 10.7341 12.4559 10.8046 12.2427 11.1047C11.2823 12.4568 9.7156 13.3333 7.94801 13.3333C5.03669 13.3333 2.66671 10.9509 2.66671 7.99992Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00004 8.66659C9.10461 8.66659 10 7.77116 10 6.66659C10 5.56202 9.10461 4.66659 8.00004 4.66659C6.89547 4.66659 6.00004 5.56202 6.00004 6.66659C6.00004 7.77116 6.89547 8.66659 8.00004 8.66659ZM8.00004 7.33325C8.36823 7.33325 8.66671 7.03477 8.66671 6.66659C8.66671 6.2984 8.36823 5.99992 8.00004 5.99992C7.63185 5.99992 7.33337 6.2984 7.33337 6.66659C7.33337 7.03477 7.63185 7.33325 8.00004 7.33325Z"
      fill="white"
    />
    <path
      d="M5.52864 11.138C5.26829 10.8776 5.26829 10.4555 5.52864 10.1952C6.89355 8.83026 9.10653 8.83026 10.4714 10.1952C10.7318 10.4555 10.7318 10.8776 10.4714 11.138C10.2111 11.3983 9.78899 11.3983 9.52864 11.138C8.68442 10.2938 7.31567 10.2938 6.47145 11.138C6.2111 11.3983 5.78899 11.3983 5.52864 11.138Z"
      fill="white"
    />
  </svg>
);
