import React, { useRef } from 'react';
import { SellingZoneElement } from '@substratahq/selling-zone/typing/base';
import { Scrollbar } from '../../../../components';
import { EmailListItem } from './components/EmailListItem/EmailListItem';
import { useAppSelector } from '../../../../store/hooks';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import { selectedBranchWithContactsInfoSelector } from '../../../../store/threadDetails/threadDetails.selectors';
import { ReplyEmail } from './components/ReplyEmail/ReplyEmail';
import { checkFeatureAvailability } from '../../../MyMeetings/components/myMeetingsTable/utils/checkForFeature';
import { Features } from '../../../../types';

export interface EmailListProps {
  sellingZoneRef: React.MutableRefObject<SellingZoneElement | null>;
}

export const EmailList: React.FC<EmailListProps> = ({ sellingZoneRef }) => {
  const { user, userConnections } = useAppSelector((state) => state.auth);

  const { selectedEmail } = useAppSelector((state) => state.threadDetails);

  const selectedBranch = useAppSelector(selectedBranchWithContactsInfoSelector);

  const isDetailedAnalysisLoading = useAppSelector(
    (state) => state.threadDetails.isLoading.threadDetails
  );

  const scrollableNodeRef = useRef<HTMLElement | null>(null);

  const { emails } = selectedBranch || {};

  const isSendEmailsFeatureEnabled = checkFeatureAvailability(
    user,
    Features.REPLY_FROM_DASHBOARD
  );

  const sendEmailIsConnected = userConnections?.gmailWrite === true;

  const attrs = {
    container: {
      className: 'emails-list',
    },

    scrollable: {
      className: 'emails-list__scrollable',
      scrollableNodeProps: {
        ref: scrollableNodeRef,
      },
    },
  };

  const renderEmailListItems = () => {
    if (isDetailedAnalysisLoading || !emails) {
      return (
        <>
          <EmailListItem isLoading />
          <EmailListItem isLoading />
          <EmailListItem isLoading />
        </>
      );
    }

    return emails.map((email, index) => {
      const {
        sender,
        sentAt,
        text,
        platformMessageId,
        participants: { leftParticipant, rightParticipant },
      } = email;

      const senderParticipant = leftParticipant.isSender
        ? leftParticipant
        : rightParticipant;

      console.log(
        sendEmailIsConnected,
        isSendEmailsFeatureEnabled,
        index,
        emails.length,
        index + 1 === emails.length
      );

      const props = {
        emailListItem: {
          sender,
          sentAt,
          index,
          scrollableNodeRef,
          key: index,
          content: text,
          total: emails.length,
          isActive:
            selectedEmail?.platformMessageId === email.platformMessageId,
          side:
            senderParticipant.contact?.extra?.currentAccountLink?.contactSide ||
            RelationshipMemberSide.UNASSIGNED,
          messageId: platformMessageId,
          onSelect: (messageId: string) => {
            const sellingZoneRefCurrent = sellingZoneRef?.current;

            if (sellingZoneRefCurrent) {
              sellingZoneRefCurrent.selectedMessageId = messageId;
            }
          },
          isLastItemWithMargin:
            sendEmailIsConnected &&
            isSendEmailsFeatureEnabled &&
            index + 1 === emails.length,
        },
      };

      return <EmailListItem {...props.emailListItem} />;
    });
  };

  return (
    <div {...attrs.container}>
      <Scrollbar {...attrs.scrollable}>{renderEmailListItems()}</Scrollbar>
      <ReplyEmail />
    </div>
  );
};
