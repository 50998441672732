import React from 'react';

interface Props {
  active: boolean;
  className?: string;
  reversed?: boolean;
}

export const QSortArrow: React.FC<Props> = ({
  active,
  className = '',
  reversed = false,
}): JSX.Element => {
  const fillCol = active ? '#4D4558' : '#CDD5E5';

  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={reversed ? { transform: 'rotate(180deg)' } : {}}
    >
      <path
        d="M1.18928 7.16658H10.8118C11.5301 7.16658 11.9118 6.31658 11.4343 5.77908L6.62345 0.366583C6.54542 0.278503 6.44958 0.207986 6.34227 0.159692C6.23496 0.111398 6.11863 0.0864258 6.00095 0.0864258C5.88328 0.0864258 5.76694 0.111398 5.65963 0.159692C5.55232 0.207986 5.45648 0.278503 5.37845 0.366583L0.56595 5.77908C0.0884495 6.31658 0.470116 7.16658 1.18928 7.16658Z"
        fill={fillCol}
        className="q-graph__header__sort-section__icon-path"
      />
    </svg>
  );
};
