import React from 'react';
import { Tooltip, TooltipPosition } from '../../../../components/SoundWave';
import { Toggle, ToggleProps } from './components/Toggle/Toggle';
import { LinkIcon } from './icons/LinkIcon';

export interface AppProps {
  title: string;
  text: string;
  imageSrcSet: Array<{ src: string; type: string }>;
  linkProps?: {
    to: string;
    label: string;
  } | null;
  iconProps?: {
    component: React.FC<React.SVGProps<SVGSVGElement>> | null;
    tooltip?: React.ReactNode;
  } | null;
  buttonProps?: {
    label: string;
    disabled?: boolean;
    active?: boolean;
    outlined?: boolean;
    icon?: React.FC<React.SVGProps<SVGSVGElement>> | null;
    className?: string;
    tooltip?: React.ReactNode;
    onClick: () => void;
  } | null;
  toggleProps?: ToggleProps | null;
  renderAdditionalContent?: () => React.ReactNode;
}

export const App: React.FC<AppProps> = ({
  title,
  text,
  imageSrcSet,
  linkProps,
  iconProps,
  buttonProps,
  toggleProps,
  renderAdditionalContent,
}) => {
  const showButtonsWrapper = !!buttonProps || !!toggleProps;
  const IconComponent = iconProps?.component;
  const ButtonIconComponent = buttonProps?.icon;

  const attrs = {
    container: {
      className: 'app',
    },
    body: {
      className: 'app__body',
    },
    image: {
      className: 'app__image',
    },
    content: {
      className: 'app__content',
    },
    header: {
      className: 'app__header',
    },
    buttonsWrapper: {
      className: 'app__buttons-wrapper',
    },
    icon: {
      className: 'app__icon',
    },
    button: {
      type: 'button' as const,
      className: `app__button${
        buttonProps?.active ? ' app__button--active' : ''
      }${buttonProps?.outlined ? ' app__button--outlined' : ''}${
        buttonProps?.className ? ` ${buttonProps.className}` : ''
      }`,
      disabled: buttonProps?.disabled,
      onClick: buttonProps?.onClick,
    },
    tooltip: {
      text: buttonProps?.tooltip || '',
      position: TooltipPosition.TOP_END,
      simplified: true,
      contentClassName: 'app__tooltip',
    },
    iconTooltip: {
      text: iconProps?.tooltip || '',
      position: TooltipPosition.TOP_END,
      simplified: true,
      contentClassName: 'app__tooltip',
    },
    separator: {
      className: 'app__separator',
    },
    title: {
      className: 'app__title',
    },
    text: {
      className: 'app__text',
    },
    link: {
      className: 'app__link',
      href: linkProps?.to,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  };

  const renderImage = () => {
    return (
      <picture {...attrs.image}>
        {imageSrcSet.map(({ src, type }, index) => {
          const isLastItem = index === imageSrcSet.length - 1;
          const props = {
            image: isLastItem
              ? {
                  key: src,
                  src,
                  type,
                }
              : {
                  key: src,
                  srcSet: src,
                  type,
                },
          };

          return isLastItem ? (
            <img {...props.image} alt="" />
          ) : (
            <source {...props.image} />
          );
        })}
      </picture>
    );
  };

  const renderIcon = () => {
    if (!IconComponent) {
      return null;
    }

    if (iconProps?.tooltip) {
      return (
        <Tooltip {...attrs.iconTooltip}>
          <IconComponent {...attrs.icon} />
        </Tooltip>
      );
    }

    return <IconComponent {...attrs.icon} />;
  };

  const renderButton = () => {
    if (!buttonProps) {
      return null;
    }

    if (buttonProps.tooltip) {
      return (
        <Tooltip {...attrs.tooltip}>
          <button {...attrs.button}>
            <span>{buttonProps.label}</span>
            {ButtonIconComponent ? <ButtonIconComponent /> : null}
          </button>
        </Tooltip>
      );
    }

    return (
      <button {...attrs.button}>
        <span>{buttonProps.label}</span>
        {ButtonIconComponent ? <ButtonIconComponent /> : null}
      </button>
    );
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.body}>
        {renderImage()}
        <div {...attrs.content}>
          <div {...attrs.header}>
            <span {...attrs.title}>{title}</span>
            {showButtonsWrapper ? (
              <div {...attrs.buttonsWrapper}>
                {renderIcon()}
                {renderButton()}
                {toggleProps ? <Toggle {...toggleProps} /> : null}
              </div>
            ) : null}
          </div>
          <div {...attrs.separator} />
          <div {...attrs.text}>{text}</div>
          {linkProps ? (
            <a {...attrs.link}>
              <span>{linkProps.label}</span>
              <LinkIcon />
            </a>
          ) : null}
        </div>
      </div>
      {renderAdditionalContent ? renderAdditionalContent() : null}
    </div>
  );
};
