import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  duration: number;
  globalLoading: boolean;
  children?: JSX.Element | string;
}

const PersonalInfoSkeleton: React.FC<Props> = (props: Props) => {
  const { duration, globalLoading, children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [globalLoading]); // eslint-disable-line

  return (
    <div className="personalQ__firstColumn__skeleton-pers-info">
      {isLoading ? (
        <>
          {' '}
          <Skeleton
            loading={isLoading}
            active
            avatar={false}
            paragraph={false}
            title={{
              style: {
                width: 155,
                height: 21,
                position: 'absolute',
                top: '-3%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            loading={isLoading}
            active
            avatar={false}
            paragraph={false}
            title={{
              style: {
                width: 88,
                height: 16,
                position: 'absolute',
                top: '28%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            loading={isLoading}
            active
            avatar={false}
            paragraph={false}
            title={{
              style: {
                width: 195,
                height: 16,
                position: 'absolute',
                top: '52%',
                borderRadius: 30,
              },
            }}
          />
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default PersonalInfoSkeleton;
