import React, { useCallback, useMemo, useState } from 'react';
import { CheckboxCheckedIcon } from '../../../../../../components/Icons/CheckboxCheckedIcon';
import { CheckboxUncheckedIcon } from '../../../../../../components/Icons/CheckboxUncheckedIcon';
import { DotsIcon } from '../../../../../../components/Icons/DotsIcon';
import {
  Dropdown,
  EditIcon,
  InfoIcon,
} from '../../../../../../components/SoundWave';
import {
  Avatar,
  AvatarSize,
} from '../../../../../../components/SoundWave/components/Avatar/Avatar';
import { useMixpanel } from '../../../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  changeMembersActiveState,
  changeMembersAssignment,
  discardMemberChanges,
} from '../../../../../../store/relationshipDetails/relationshipDetails.slice';
import { openAddMemberModal } from '../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { Contact } from '../../../../../../types/contact.types';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberLink,
  RelationshipMemberSide,
} from '../../../../../../types/relationshipMember.types';
import { ActivateIcon } from '../../icons/ActivateIcon';
import { CheckedIcon } from '../../icons/CheckedIcon';
import { MemberContactModal } from '../MemberContactModal/MemberContactModal';
import { CrossIcon } from './icons/CrossIcon';
import { EmailIcon } from './icons/EmailIcon';
import { MeetingIcon } from './icons/MeetingIcon';
import { getChangesTextDescription } from './utils/getChangesTextDescription';

export interface TeamMemberProps {
  contact: Contact;
  changes?: RelationshipMemberLink;
  isChangesView?: boolean;
  isEditable?: boolean;
  isSelected?: boolean;
  onSelect?: (isSelected: boolean, contact: Contact) => void;
  onDiscardChanges?: (contact: Contact) => void;
}

export const TeamMember: React.FC<TeamMemberProps> = ({
  contact,
  changes,
  isChangesView = false,
  isEditable = false,
  isSelected = false,
  onSelect,
  onDiscardChanges,
}) => {
  const dispatch = useAppDispatch();

  const prospectAccount = useAppSelector(
    (state) => state.relationshipDetails.account
  );

  const userOrganization = useAppSelector((state) => state.auth.org);

  const { trackEvent } = useMixpanel();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [isInfoModalOpened, setIsInfoModalOpened] = useState(false);

  const handleCloseDropdown = useCallback(() => {
    setIsDropdownOpened(false);
  }, []);

  const dropdownOptions = useMemo(() => {
    const { contactId, extra } = contact;
    const { isActive = false, contactSide, associationType } =
      extra?.currentAccountLink || {};

    const props = {
      activateIcon: {
        ...(isActive && {
          className: 'team-member__deactivate-icon',
        }),
      },
    };

    return isEditable
      ? [
          {
            label: 'Change Assignment',
            suboptions: [
              {
                label: prospectAccount?.name
                  ? `${prospectAccount.name} Team Member`
                  : 'Prospect Team Member',
                disabled:
                  contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.TEAM_MEMBER,
                ...(contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.TEAM_MEMBER && {
                    icon: <CheckedIcon />,
                  }),
                onClick: () => {
                  trackEvent('assignMemberToProspectSide');
                  setIsDropdownOpened(false);
                  dispatch(
                    changeMembersAssignment({
                      contactIds: [contactId],
                      contactSide: RelationshipMemberSide.PROSPECT_SIDE,
                      associationType:
                        RelationshipMemberAssociationType.TEAM_MEMBER,
                    })
                  );
                },
              },
              {
                label: prospectAccount?.name
                  ? `${prospectAccount.name} 3rd Party`
                  : 'Prospect 3rd Party',
                disabled:
                  contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.THIRD_PARTY,
                ...(contactSide === RelationshipMemberSide.PROSPECT_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.THIRD_PARTY && {
                    icon: <CheckedIcon />,
                  }),
                onClick: () => {
                  trackEvent('assignMemberToProspectSide3rdParty');
                  setIsDropdownOpened(false);
                  dispatch(
                    changeMembersAssignment({
                      contactIds: [contactId],
                      contactSide: RelationshipMemberSide.PROSPECT_SIDE,
                      associationType:
                        RelationshipMemberAssociationType.THIRD_PARTY,
                    })
                  );
                },
              },
              {
                label: userOrganization?.name
                  ? `${userOrganization.name} Team Member`
                  : 'User Team Member',
                disabled:
                  contactSide === RelationshipMemberSide.USER_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.TEAM_MEMBER,
                ...(contactSide === RelationshipMemberSide.USER_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.TEAM_MEMBER && {
                    icon: <CheckedIcon />,
                  }),
                onClick: () => {
                  trackEvent('assignMemberToUserSide');
                  setIsDropdownOpened(false);
                  dispatch(
                    changeMembersAssignment({
                      contactIds: [contactId],
                      contactSide: RelationshipMemberSide.USER_SIDE,
                      associationType:
                        RelationshipMemberAssociationType.TEAM_MEMBER,
                    })
                  );
                },
              },
              {
                label: userOrganization?.name
                  ? `${userOrganization.name} 3rd Party`
                  : 'User 3rd Party',
                disabled:
                  contactSide === RelationshipMemberSide.USER_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.THIRD_PARTY,
                ...(contactSide === RelationshipMemberSide.USER_SIDE &&
                  associationType ===
                    RelationshipMemberAssociationType.THIRD_PARTY && {
                    icon: <CheckedIcon />,
                  }),
                onClick: () => {
                  trackEvent('assignMemberToUserSide3rdParty');
                  setIsDropdownOpened(false);
                  dispatch(
                    changeMembersAssignment({
                      contactIds: [contactId],
                      contactSide: RelationshipMemberSide.USER_SIDE,
                      associationType:
                        RelationshipMemberAssociationType.THIRD_PARTY,
                    })
                  );
                },
              },
              {
                label: 'Unassigned',
                disabled: contactSide === RelationshipMemberSide.UNASSIGNED,
                ...(contactSide === RelationshipMemberSide.UNASSIGNED && {
                  icon: <CheckedIcon />,
                }),
                onClick: () => {
                  trackEvent('unassignMember');
                  setIsDropdownOpened(false);
                  dispatch(
                    changeMembersAssignment({
                      contactIds: [contactId],
                      contactSide: RelationshipMemberSide.UNASSIGNED,
                      associationType:
                        RelationshipMemberAssociationType.TEAM_MEMBER,
                    })
                  );
                },
              },
            ],
          },
          {
            label: isActive ? 'Mark as Inactive' : 'Mark as Active',
            icon: <ActivateIcon {...props.activateIcon} />,
            onClick: () => {
              trackEvent(!isActive ? 'activateMember' : 'deactivateMember');
              setIsDropdownOpened(false);
              dispatch(
                changeMembersActiveState({
                  contactIds: [contactId],
                  isActive: !isActive,
                })
              );
            },
          },
        ]
      : [
          {
            label: 'Edit Member',
            icon: <EditIcon />,
            onClick: () => {
              setIsDropdownOpened(false);
              if (prospectAccount) {
                dispatch(
                  openAddMemberModal({
                    accountId: prospectAccount.accountId,
                    accountName: prospectAccount.name,
                    isEditFlow: true,
                    existingContact: contact,
                    initialValues: {
                      email: contact.email || '',
                      firstName: contact.metadata?.firstName || '',
                      lastName: contact.metadata?.lastName || '',
                      title: contact.metadata?.title || '',
                      phone: contact.metadata?.phoneNumbers?.[0]?.number || '',
                      countryCode:
                        contact.metadata?.phoneNumbers?.[0]?.countryCode || '',
                      assignedTo:
                        contact.extra?.currentAccountLink?.contactSide || '',
                      contactType:
                        contact.extra?.currentAccountLink?.associationType ||
                        '',
                      avatar: contact.metadata.avatarSrc || '',
                      note: contact.extra?.currentAccountLink?.note || '',
                    },
                  })
                );
              }
            },
          },
          {
            label: 'Contact Details',
            icon: <InfoIcon />,
            onClick: () => {
              trackEvent('openMemberContactDetails');
              setIsDropdownOpened(false);
              setIsInfoModalOpened(true);
            },
          },
        ];
  }, [
    contact,
    dispatch,
    isEditable,
    prospectAccount,
    trackEvent,
    userOrganization,
  ]);

  // TODO: Add events count feature for next version
  // const isUnassigned =
  //   !contact.extra?.currentAccountLink?.contactSide ||
  //   contact.extra.currentAccountLink.contactSide ===
  //     RelationshipMemberSide.UNASSIGNED;

  const showEmailEvents = false;
  // const showEmailEvents =
  //   isUnassigned &&
  //   typeof contact.extra?.branchesCount === 'number' &&
  //   contact.extra.branchesCount > 0;

  const showMeetingEvents = false;
  // const showMeetingEvents =
  //   isUnassigned &&
  //   typeof contact.extra?.meetingsCount === 'number' &&
  //   contact.extra.meetingsCount > 0;

  const attrs = {
    container: {
      className: `team-member${
        contact.extra?.currentAccountLink?.isActive
          ? ''
          : ' team-member--inactive'
      }${isChangesView ? ' team-member--changes-view' : ''}`,
    },
    checkbox: {
      type: 'button' as const,
      className: 'team-member__checkbox',
      onClick: () => {
        trackEvent('selectMember');
        onSelect?.(!isSelected, contact);
      },
    },
    avatar: {
      src: contact.metadata?.avatarSrc || '',
      name: contact.metadata?.name || contact.email || '',
      side: contact.extra?.currentAccountLink?.contactSide,
      size: AvatarSize.XS,
      className: 'team-member__avatar',
    },
    text: {
      className: 'team-member__text',
    },
    textGray300: {
      className: 'team-member__text team-member__text--gray-300',
    },
    textGray200: {
      className: 'team-member__text team-member__text--gray-200',
    },
    eventsCount: {
      className: 'team-member__events-count',
    },
    eventsCountText: {
      className:
        'team-member__text team-member__text--gray-300 team-member__text--bold',
    },
    separator: {
      className: 'team-member__separator',
    },
    leftSection: {
      className: 'team-member__left-section',
    },
    rigthSection: {
      className: 'team-member__right-section',
    },
    changesButton: {
      type: 'button' as const,
      className: 'team-member__changes-button',
      onClick: () => {
        trackEvent('discardMemberChanges');
        onDiscardChanges?.(contact);
        dispatch(discardMemberChanges(contact.contactId));
      },
    },
    dropdownButton: {
      type: 'button' as const,
      className: `team-member__dropdown-button${
        isDropdownOpened ? ' team-member__dropdown-button--active' : ''
      }`,
      onClick: () => {
        setIsDropdownOpened(!isDropdownOpened);
      },
    },
    dropdown: {
      isOpened: isDropdownOpened,
      closeOnScroll: true,
      onClose: handleCloseDropdown,
      options: dropdownOptions,
    },
    memberContactModal: {
      contact,
      isOpened: isInfoModalOpened,
      onClose: () => {
        setIsInfoModalOpened(false);
      },
    },
  };

  const renderChanges = () => {
    if (!changes) {
      return null;
    }

    const changesText = getChangesTextDescription(
      contact,
      changes,
      prospectAccount,
      userOrganization
    );

    return (
      <button {...attrs.changesButton}>
        <span>{changesText}</span>
        <CrossIcon />
      </button>
    );
  };

  return (
    <div {...attrs.container}>
      {isEditable ? (
        <button {...attrs.checkbox}>
          {isSelected ? <CheckboxCheckedIcon /> : <CheckboxUncheckedIcon />}
        </button>
      ) : null}
      <Avatar {...attrs.avatar} />
      <div {...attrs.leftSection}>
        <span {...attrs.text}>{contact.metadata?.name || contact.email}</span>
        {contact.metadata?.title ? (
          <>
            <span {...attrs.separator} />
            <span {...attrs.textGray300}>{contact.metadata?.title}</span>
          </>
        ) : null}
        {showEmailEvents ? (
          <>
            <span {...attrs.separator} />
            <span {...attrs.eventsCount}>
              <EmailIcon />
              <span {...attrs.eventsCountText}>
                x{contact.extra?.branchesCount}
              </span>
            </span>
          </>
        ) : null}
        {showMeetingEvents ? (
          <>
            <span {...attrs.separator} />
            <span {...attrs.eventsCount}>
              <MeetingIcon />
              <span {...attrs.eventsCountText}>
                x{contact.extra?.meetingsCount}
              </span>
            </span>
          </>
        ) : null}
      </div>
      <div {...attrs.rigthSection}>
        {isChangesView ? (
          renderChanges()
        ) : (
          <>
            {contact.email ? (
              <span {...attrs.text}>{contact.email}</span>
            ) : (
              <span {...attrs.textGray200}>no email detected</span>
            )}
            <Dropdown {...attrs.dropdown}>
              <button {...attrs.dropdownButton}>
                <DotsIcon />
              </button>
            </Dropdown>
          </>
        )}
      </div>
      {!isChangesView ? (
        <MemberContactModal {...attrs.memberContactModal} />
      ) : null}
    </div>
  );
};
