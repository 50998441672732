import React from 'react';

export const SortIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      d="M5.6464 1.85348C5.84166 1.65822 6.15824 1.65822 6.3535 1.85348L9.1464 4.64637C9.46138 4.96135 9.2383 5.49992 8.79284 5.49992H3.20706C2.7616 5.49992 2.53852 4.96135 2.8535 4.64637L5.6464 1.85348Z"
      fill="#E3E7ED"
    />
    <path
      d="M6.3536 10.1465C6.15834 10.3418 5.84176 10.3418 5.6465 10.1465L2.8536 7.35363C2.53862 7.03865 2.7617 6.50008 3.20716 6.50008H8.79294C9.2384 6.50008 9.46148 7.03865 9.1465 7.35363L6.3536 10.1465Z"
      fill="#E3E7ED"
    />
  </svg>
);
