import React from 'react';

export const UnassignAccountIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.3577 6.5857C10.618 6.32535 10.618 5.90324 10.3577 5.64289C10.0973 5.38254 9.67521 5.38254 9.41487 5.64289L8.00065 7.05711L6.58644 5.64289C6.32609 5.38254 5.90398 5.38254 5.64363 5.64289C5.38328 5.90324 5.38328 6.32535 5.64363 6.5857L7.05784 7.99992L5.64363 9.41413C5.38328 9.67448 5.38328 10.0966 5.64363 10.3569C5.90398 10.6173 6.32609 10.6173 6.58644 10.3569L8.00065 8.94272L9.41487 10.3569C9.67521 10.6173 10.0973 10.6173 10.3577 10.3569C10.618 10.0966 10.618 9.67448 10.3577 9.41413L8.94346 7.99992L10.3577 6.5857Z"
        fill="#070B12"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00065 1.33325C4.31875 1.33325 1.33398 4.31802 1.33398 7.99992C1.33398 11.6818 4.31875 14.6666 8.00065 14.6666C11.6825 14.6666 14.6673 11.6818 14.6673 7.99992C14.6673 4.31802 11.6825 1.33325 8.00065 1.33325ZM2.66732 7.99992C2.66732 5.0544 5.05513 2.66659 8.00065 2.66659C10.9462 2.66659 13.334 5.0544 13.334 7.99992C13.334 10.9454 10.9462 13.3333 8.00065 13.3333C5.05513 13.3333 2.66732 10.9454 2.66732 7.99992Z"
        fill="#070B12"
      />
    </svg>
  );
};
