import React, { CSSProperties, useEffect, useState } from 'react';
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
} from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { TalkTimeRatioSkeleton } from '../../skeletons/TalkTimeRatioSkeleton';
import { Tooltip as SoundWaveTooltip } from '../../../../../components/SoundWave';

type Data = {
  me?: number;
  prospect: number;
  myTeam: number;
  unassigned: number;
  silence: number;
  totalMeetings: number;
  previousPeriodUserTTR?: number;
};

type Props = {
  talkTimeRatioData?: Data;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  lastDays: number | null;
  isInTeamQ?: boolean;
  requestedTeamQId?: string;
};

type LocalData = {
  label: string;
  percentageValue: number;
  inPercents: number;
  fill: string;
}[];

export const TalkTimeRatio: React.FC<Props> = ({
  talkTimeRatioData,
  isLoading,
  noData,
  error,
  lastDays,
  isInTeamQ,
  requestedTeamQId,
}): JSX.Element => {
  const [activeShapeIndex, setActiveShapeIndex] = useState<number | null>(null);

  const [data, setData] = useState<LocalData>([]);

  const [lastDaysResponse, setLastDaysResponse] = useState<number | null>(null);

  useEffect(() => {
    if (!talkTimeRatioData || isLoading) {
      setData([]);
    }

    if (lastDays) {
      setLastDaysResponse(lastDays);
    }

    if (
      talkTimeRatioData?.me?.toString().length ||
      talkTimeRatioData?.prospect?.toString().length ||
      talkTimeRatioData?.myTeam?.toString().length ||
      talkTimeRatioData?.unassigned?.toString().length ||
      talkTimeRatioData?.silence?.toString().length
    ) {
      const {
        me = 0,
        prospect,
        myTeam,
        unassigned,
        silence,
      } = talkTimeRatioData;

      // const me = 0.71;
      // const prospect = 0.07;
      // const myTeam = 0.12;
      // const unassigned = 0.10;
      // const silence = 0.28;
      // const me = 0.5;
      // const prospect = 0.07;
      // const myTeam = 0.13;
      // const unassigned = 0.24;
      // const silence = 0.06;

      const customData: LocalData = [
        {
          label: 'My Team',
          percentageValue: myTeam,
          inPercents: Math.round(myTeam * 100) || 0,
          fill: isInTeamQ ? '#4EB4FF' : '#57C888',
        },
        ...(!isInTeamQ
          ? [
              {
                label: 'Me',
                percentageValue: me,
                inPercents: Math.round(me * 100) || 0,
                fill: '#4EB4FF',
              },
            ]
          : []),
        {
          label: 'Prospect',
          percentageValue: prospect,
          inPercents: Math.round(prospect * 100) || 0,
          fill: '#365798',
        },
        {
          label: 'Silence',
          percentageValue: silence,
          inPercents: Math.round(silence * 100) || 0,
          fill: '#ACACB7',
        },
        {
          label: 'Unassigned',
          percentageValue: unassigned,
          inPercents: Math.round(unassigned * 100) || 0,
          fill: '#646C83',
        },
      ];
      setData(customData);
      // console.log(totalValue, customData);
    }
  }, [talkTimeRatioData, lastDays, isLoading, isInTeamQ]);

  const onMouseShapeEnter = (mess: string, index: number) => {
    setActiveShapeIndex(index);
  };

  const onMouseShapeLeave = () => {
    if (activeShapeIndex !== null && activeShapeIndex > -1) {
      setActiveShapeIndex(null);
    }
  };

  const defineTooltipStyle = () => {
    let tooltipPosition: CSSProperties = { display: 'flex' };

    const tooltipWidth = 'max-content';

    if (
      activeShapeIndex !== null &&
      activeShapeIndex > -1 &&
      !noData &&
      !error
    ) {
      tooltipPosition = { top: '-63px', left: '-60px' };
    }

    return { tooltipPosition, tooltipWidth };
  };

  const { tooltipPosition, tooltipWidth } = defineTooltipStyle();

  const tooltipAttrs = {
    wrapper: {
      className: 'light chart-tooltip',
      style: {
        width: tooltipWidth,
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    if (active && data.length) {
      const place = payload[0]?.payload?.label;
      const percentageValue = payload[0]?.payload?.inPercents;

      const messageToShow =
        place && !Number.isNaN(place) ? (
          <>
            {percentageValue}% {place}
          </>
        ) : (
          <>-</>
        );

      result = (
        <div {...tooltipAttrs.wrapper}>
          {/* <TooltipArrowIcon {...tooltipAttrs.tooltipArrow} /> */}
          {messageToShow}
        </div>
      );
    }

    return result;
  };

  const CustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    inPercents,
    label,
    index, // eslint-disable-next-line
  }: any) => {
    const defineLabelPosition = () => {
      let calcedX = 0;

      let calcedY = 0;

      const isActiveShapeCheck =
        label === 'Me' || (isInTeamQ && label === 'My Team');

      const radian = Math.PI / 180;

      const radius =
        innerRadius +
        (outerRadius - innerRadius) * (isActiveShapeCheck ? 0.66 : 0.48);

      const centeredX = cx + radius * Math.cos(-midAngle * radian);

      const centeredY = cy + radius * Math.sin(-midAngle * radian);

      const isShapeTooSmall = inPercents < 6;

      const availableData = data?.filter((el) => el.percentageValue > 0);

      if (availableData.length === 1) {
        calcedX = cx + 1;
        calcedY = cy + 2;
      } else if (availableData.length > 1 && !isShapeTooSmall) {
        calcedX = centeredX + 3;
        calcedY = centeredY + 5;
      } else {
        calcedX = centeredX + 8;
        calcedY = centeredY;
      }

      return { calcedX, calcedY, isShapeTooSmall };
    };

    const { calcedX, calcedY, isShapeTooSmall } = defineLabelPosition();

    const conditionalBaseline = isShapeTooSmall ? 'middle' : 'center';

    const conditionalClassName = isShapeTooSmall
      ? 'decreased charts__talk-time-ratio__wrapper-title-chart-value'
      : 'charts__talk-time-ratio__wrapper-title-chart-value';

    const conditionalValue =
      inPercents === 0 ? '' : `${inPercents.toFixed(0)}%`;

    // console.log(inPercents, conditionalValue);

    return (
      <text
        x={calcedX}
        y={calcedY}
        fill="white"
        textAnchor="middle"
        dominantBaseline={conditionalBaseline}
        className={conditionalClassName}
        onMouseEnter={() => onMouseShapeEnter('', index)}
      >
        {conditionalValue}
      </text>
    );
  };

  const ActiveShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    label,
    fill, // eslint-disable-next-line
  }: any) => {
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          filter="brightness(85%)"
        />
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={
            label === 'Me' || (isInTeamQ && label === 'My Team')
              ? outerRadius + 10
              : outerRadius
          }
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          filter="brightness(85%)"
          opacity={0.6}
        />
      </g>
    );
  };

  const DefaultSectorShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    label,
    fill, // eslint-disable-next-line
  }: any) => {
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={
            label === 'Me' || (isInTeamQ && label === 'My Team')
              ? outerRadius + 10
              : outerRadius
          }
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  const getPercentageComparison = (currentTTR: number, prevTTR: number) => {
    let resultToShow: string | number = '';
    if (typeof prevTTR !== 'number') {
      resultToShow = '-';
    } else if (currentTTR !== 0 && currentTTR === prevTTR) {
      resultToShow = '-';
    } else if (prevTTR === 0 || currentTTR === 0) {
      resultToShow = '-';
    } else {
      resultToShow = +((currentTTR / prevTTR) * 100 - 100).toFixed(0);
    }
    return resultToShow;
  };

  const decreasedArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5941_509147)">
        <path
          d="M5.20044 12.9094C4.83225 12.9094 4.53378 12.6109 4.53378 12.2427C4.53378 11.8746 4.83225 11.5761 5.20044 11.5761L11.1334 11.5761L3.78623 4.22887C3.52588 3.96852 3.52588 3.54641 3.78623 3.28606C4.04658 3.02571 4.46869 3.02571 4.72904 3.28606L12.0762 10.6333L12.0762 4.70027C12.0762 4.33208 12.3747 4.0336 12.7429 4.0336C13.1111 4.0336 13.4096 4.33208 13.4096 4.70027L13.4096 12.2427C13.4096 12.6109 13.1111 12.9094 12.7429 12.9094L5.20044 12.9094Z"
          fill="#646F87"
        />
      </g>
      <defs>
        <clipPath id="clip0_5941_509147">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const increaseArrow = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_5941_517128)">
        <path
          d="M5.20044 3.09059C4.83225 3.09059 4.53378 3.38907 4.53378 3.75726C4.53378 4.12545 4.83225 4.42392 5.20044 4.42392L11.1334 4.42392L3.78623 11.7711C3.52588 12.0315 3.52588 12.4536 3.78623 12.7139C4.04658 12.9743 4.46869 12.9743 4.72904 12.7139L12.0762 5.36673L12.0762 11.2997C12.0762 11.6679 12.3747 11.9664 12.7429 11.9664C13.1111 11.9664 13.4096 11.6679 13.4096 11.2997L13.4096 3.75726C13.4096 3.38907 13.1111 3.09059 12.7429 3.09059L5.20044 3.09059Z"
          fill="#646F87"
        />
      </g>
      <defs>
        <clipPath id="clip0_5941_517128">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const chartCenterValue = getPercentageComparison(
    talkTimeRatioData?.me || 0,
    talkTimeRatioData?.previousPeriodUserTTR || 0
  );

  const defineCenterValueTooltipText = () => {
    if (typeof chartCenterValue === 'number' && chartCenterValue !== 0) {
      return `${Math.abs(chartCenterValue)}% ${
        chartCenterValue > 0 ? 'Increase' : 'Decrease'
      }`;
    }

    return 'Unchanged';
  };

  const attrs = {
    chartWrapper: {
      title: 'Talk-Time Ratio',
      subtitle: `${
        isInTeamQ ? 'Team avg, last' : 'Average talk time ratio, last'
      } ${lastDaysResponse} days`,
      tooltipText: isInTeamQ
        ? `The "Talk-Time Ratio" graph visualizes the aggregate distribution of relative speaking time across your team's recorded online calls. This graph provides insights into the balance between the time your team spends talking versus the time other participants spend talking during these interactions.`
        : 'The "Talk-Time Ratio" graph visualizes the aggregate distribution of relative speaking time across your recorded online calls. It provides insights into the balance between the time you spend talking and the time your team members and prospects` team members spend talking during these interactions.', //
      isNoData: noData || error,
      upIndex: activeShapeIndex !== null && activeShapeIndex > -1,
      isInTeamQ,
      isSoundWave: true,
    },
    totalMeetings: {
      wrapper: {
        className: 'charts__talk-time-ratio__total-meetings',
      },

      value: {
        className: 'charts__talk-time-ratio__total-meetings-value',
      },

      subtitle: {
        className: 'charts__talk-time-ratio__total-meetings-subtitle',
      },
    },
    centerValue: {
      tooltip: {
        className: 'charts__talk-time-ratio__chart-center',
        text: defineCenterValueTooltipText(),
        width: 128,
        contentClassName:
          'charts__talk-time-ratio__chart-center-tooltip-content',
      },
      content: { className: 'charts__talk-time-ratio__chart-center' },
    },
    legendWrapper: {
      className: 'charts__talk-time-ratio__legend',
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    legendBelow: {
      className: 'charts__talk-time-ratio__legend-below',
    },
    legends: {
      me: {
        className: 'charts__talk-time-ratio__legend-me',
      },
      prospect: {
        className: 'charts__talk-time-ratio__legend-prospect',
      },
      team: {
        className: `charts__talk-time-ratio__legend-team${
          isInTeamQ ? ' charts__talk-time-ratio__legend-team--team-q' : ''
        }`,
      },
      unassigned: {
        className: 'charts__talk-time-ratio__legend-unassigned',
      },
      silence: {
        className: 'charts__talk-time-ratio__legend-silence',
      },
    },
    responsiveContainer: {
      height: '100%',
    },
    chart: {
      PieChart: {
        width: 180,
        height: 180,

        data,
        padding: { top: 0, right: 0, left: 0, bottom: 0 },
        margin: { top: 0, right: 0, left: 0, bottom: 22 },
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      tooltip: {
        wrapperStyle: { ...tooltipPosition, zIndex: 1001 },
        content: <CustomTooltip payload={data} active />,
        allowEscapeViewBox: { x: true, y: true },
        isAnimationActive: false,
        cursor: false,
      },
      Pie: {
        dataKey: 'inPercents',
        maxBarSize: 40,
        innerRadius: 44,
        outerRadius: 78,
        paddingAngle: 2,
        onMouseEnter: onMouseShapeEnter,
        onMouseLeave: onMouseShapeLeave,
        isAnimationActive: data && data.length > 0,
        data,
        blendStroke: true,
        labelLine: false,
        label: data ? CustomizedLabel : undefined,
        activeShape: ActiveShape,
        inactiveShape: DefaultSectorShape,
        activeShapeIndex: 2,
        activeIndex: 2,
      },
    },
  };

  const chartCenter = noData ? null : (
    <SoundWaveTooltip {...attrs.centerValue.tooltip}>
      <div>
        {typeof chartCenterValue === 'number'
          ? `${Math.abs(chartCenterValue)}%`
          : chartCenterValue}
        {typeof chartCenterValue === 'number' && chartCenterValue > 0
          ? increaseArrow
          : null}
        {typeof chartCenterValue === 'number' && chartCenterValue < 0
          ? decreasedArrow
          : null}
      </div>
    </SoundWaveTooltip>
  );

  const totalMeetings = noData ? null : (
    <div {...attrs.totalMeetings.wrapper}>
      {' '}
      <div {...attrs.totalMeetings.value}>
        {talkTimeRatioData?.totalMeetings || '-'}
      </div>
      <div {...attrs.totalMeetings.subtitle}>Total Meetings</div>{' '}
    </div>
  );

  const legendSection = (
    <div {...attrs.legendWrapper}>
      {!isInTeamQ ? <span {...attrs.legends.me}>Me</span> : null}
      <span {...attrs.legends.team}>My Team</span>
      <span {...attrs.legends.prospect}>Prospect</span>
      <span {...attrs.legends.unassigned}>Unassigned</span>
      <span {...attrs.legends.silence}>Silence</span>
    </div>
  );

  return (
    <TalkTimeRatioSkeleton isLoading={isLoading} isTeamQ={isInTeamQ}>
      <ChartWrapper {...attrs.chartWrapper}>
        <>
          <ResponsiveContainer {...attrs.responsiveContainer}>
            <PieChart {...attrs.chart.PieChart}>
              <Pie {...attrs.chart.Pie}>
                {data.map((el, index) => (
                  <Cell
                    filter={el.label === 'Me' ? 'brightness(85%)' : ''}
                    key={`cell-${index}${el}`}
                    fontSize={12}
                  />
                ))}
              </Pie>
              <Tooltip {...attrs.chart.tooltip} />
            </PieChart>
          </ResponsiveContainer>
          {chartCenter}
          {totalMeetings}
          {legendSection}
        </>
      </ChartWrapper>
    </TalkTimeRatioSkeleton>
  );
};
