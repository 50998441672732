import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '../../../../components';
import { ButtonVariantType } from '../../../../components/Button/Button';
import { deleteUsers } from '../../../../store/users/users.actions';
import { Row, User } from '../../../../types';

type DeleteUserModalProps = {
  users: User[] | Row[];
  onClose: () => void;
  orgId: string;
  open: boolean;
};

export const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  users,
  onClose,
  orgId,
  open,
}: DeleteUserModalProps): JSX.Element => {
  const [deleted, setDeleted] = useState(false);
  const dispatch = useDispatch();

  const usersToDelete = users.map((user: Row | User) => user.name);
  const userIds = users.map((user: Row | User) => user.userId);

  const handleDelete = () => {
    dispatch(deleteUsers(userIds, orgId));
    setDeleted(true);
  };

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setDeleted(false);
      }, 500);
    }
  }, [open, deleted]);

  const modalTitle = !deleted ? 'Delete member' : '';

  const attrs = {
    modal: {
      open,
      onClose,
      title: modalTitle,
      width: '560px',
    },
    bodyWrapper: {
      className: !deleted ? 'delete-user-modal' : 'deleted delete-user-modal',
    },
    boldText: {
      className: 'delete-user-modal__bold',
    },
    buttonDelete: {
      onClick: handleDelete,
      variant: 'common' as ButtonVariantType,
      className: 'delete-user-modal__btn',
    },
    buttonCancel: {
      onClick: onClose,
      variant: 'text' as ButtonVariantType,
      className: 'delete-user-modal__btn-cancel',
    },
    buttonGotIt: {
      onClick: onClose,
      variant: 'common' as ButtonVariantType,
      className: 'delete-user-modal__btn',
    },
  };

  const conditionalBody = !deleted ? (
    <div {...attrs.bodyWrapper}>
      Are you sure you want to delete{' '}
      <span {...attrs.boldText}>{usersToDelete.join(', ')}?</span>
      <Button {...attrs.buttonDelete}>Got it</Button>
      <Button {...attrs.buttonCancel}>Cancel</Button>
    </div>
  ) : (
    <div {...attrs.bodyWrapper}>
      <span {...attrs.boldText}>{usersToDelete.join(', ')}</span>{' '}
      {usersToDelete.length === 1 ? ' was ' : ' were '}
      <span {...attrs.boldText}>deleted</span>
      <Button {...attrs.buttonGotIt}>Got It</Button>
    </div>
  );

  return <Modal {...attrs.modal}>{conditionalBody}</Modal>;
};
