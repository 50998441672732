import React from 'react';
import { Button, Input, Modal } from '../../../../components';
import { useEditUserInfo } from '../../hooks/useEditUserInfo';
import { phone } from '../../icons';

type RenameUserProps = {
  open: boolean;
  onClose: () => void;
};

export const EditUserTelNumber: React.FC<RenameUserProps> = ({
  open,
  onClose,
}: RenameUserProps): JSX.Element => {
  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    initialValues,
  } = useEditUserInfo(onClose);

  return (
    <Modal title="Edit phone number" open={open} onClose={onClose}>
      <div className={`edit-user edit-user-${open ? 'open' : 'closed'}`}>
        <form onSubmit={handleSubmit}>
          <Input
            label="phone"
            value={values.phoneNumber}
            onChange={handleChange}
            name="phoneNumber"
            icon={phone}
            className="edit-user__input"
            error={touched.phoneNumber ? errors.phoneNumber : ''}
          />
          <Button
            type="submit"
            className="edit-user__btn"
            variant="common"
            disabled={
              initialValues.phoneNumber === values.phoneNumber ||
              !values.phoneNumber?.length
            }
          >
            Save
          </Button>
        </form>
      </div>
    </Modal>
  );
};
