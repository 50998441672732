import React from 'react';

type TeamRoleProps = {
  role: string;
  className?: string;
};

export const TeamRole: React.FC<TeamRoleProps> = ({
  role,
  className = '',
}: TeamRoleProps): JSX.Element => {
  const correctRole = role === 'teamLeader' ? 'Team leader' : 'Member';
  return <div className={`teams-table__role ${className}`}>{correctRole}</div>;
};
