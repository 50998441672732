import React from 'react';
import { Skeleton } from 'antd';

export const TranscriptItemsSkeleton: React.FC = () => {
  const attrs = {
    container: {
      className: 'transcript-items-skeleton',
    },
    item: {
      className: 'transcript-item',
    },
    speakerInfo: {
      className: 'transcript-item__speaker-info',
    },
    speakerTextInfo: {
      className: 'transcript-item__speaker-text-info',
    },
    avatarSkeleton: {
      active: true,
      paragraph: false,
      title: false,
      avatar: {
        shape: 'circle' as const,
        size: 32,
      },
      style: {
        width: 'auto',
      },
    },
    speakerInfoTextSkeleton: {
      active: true,
      paragraph: false,
      title: {
        width: 70,
        style: {
          width: 70,
          height: 16,
          borderRadius: 4,
          margin: 0,
        },
      },
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      title: {
        width: 'auto',
        style: {
          width: '100%',
          height: 16,
          borderRadius: 4,
        },
      },
    },
  };

  const renderItems = () =>
    Array.from({ length: 10 }).map((_, index) => (
      <div {...attrs.item} key={index}>
        <div {...attrs.speakerInfo}>
          <Skeleton {...attrs.avatarSkeleton} />
          <div {...attrs.speakerTextInfo}>
            <Skeleton {...attrs.speakerInfoTextSkeleton} />
            <Skeleton {...attrs.speakerInfoTextSkeleton} />
          </div>
        </div>
        <Skeleton {...attrs.textSkeleton} />
      </div>
    ));

  return <div {...attrs.container}>{renderItems()}</div>;
};
