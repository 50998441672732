import React, { useEffect, useState } from 'react';
import { Skeleton } from 'antd';

interface Props {
  duration: number;
  globalLoading: boolean;
  skelTitleWidth: number;
  children?: JSX.Element | string;
}

const InfobarsSkeleton: React.FC<Props> = (props: Props) => {
  const { duration, skelTitleWidth, globalLoading, children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [globalLoading]); // eslint-disable-line

  const attrs = {
    wrapper: { className: 'barwrapper__skeleton-block' },
    skeleton: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
    },
  };

  return (
    <div {...attrs.wrapper}>
      {isLoading ? (
        <>
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: skelTitleWidth,
                height: 16,
                position: 'absolute',
                top: '8%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: '100%',
                height: 17,
                position: 'absolute',
                top: '63%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: 25,
                height: 12,
                position: 'absolute',
                top: '108%',
                borderRadius: 30,
              },
            }}
          />
          <Skeleton
            {...attrs.skeleton}
            title={{
              style: {
                width: 25,
                height: 12,
                position: 'absolute',
                top: '108%',
                right: '0%',
                borderRadius: 30,
              },
            }}
          />
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default InfobarsSkeleton;
