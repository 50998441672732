import React, { useEffect, useState } from 'react';
import { TooltipIcon } from '../../../../../../components/Icons/TooltipIcon';
import { ReactComponent as Triangle } from './assets/triangle.svg';
import { barSeparators } from './assets';

interface Props {
  title: string;
  value: number | null;
  min: number;
  max: number;
  tooltipMessage?: string;
  noData?: boolean;
}

export const TendencyBar: React.FC<Props> = ({
  title,
  value,
  min,
  max,
  tooltipMessage,
  noData,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [triangleColor, setTriangleColor] = useState('#ACACB7');

  const [measure, setMeasure] = useState<number | null>(null);

  const maxPosssibleVal = max - min;

  const isCurrentHasTypeNumber =
    !Number.isNaN(value) && value !== undefined && typeof value === 'number';

  const conditionToShowNoData =
    Number.isNaN(value) || value === undefined || typeof value !== 'number';

  useEffect(() => {
    if (measure || triangleColor !== '#ACACB7') {
      setTriangleColor('#ACACB7');
      setMeasure(null);
    }

    const barsTimer = setTimeout(() => {
      if (isCurrentHasTypeNumber && (value || value === 0)) {
        const resultValue = ((value - min) / maxPosssibleVal) * 100;

        setMeasure(resultValue);

        if (
          (resultValue >= 28.6 && resultValue <= 38) ||
          (resultValue >= 62 && resultValue <= 71.4)
        ) {
          setTriangleColor('#BCDBD0');
        } else if (resultValue > 38 && resultValue < 62) {
          setTriangleColor('#32B76C');
        }
      }
    }, 200);
    return () => {
      clearTimeout(barsTimer);
    };
  }, [value]); // eslint-disable-line

  const animationDuration = measure || measure === 0 ? 2 : 0;

  const attrs = {
    wrapper: {
      className: 'tendency',
    },
    title: {
      wrapper: {
        className: 'tendency-title',
      },
      tooltip: {
        wrapper: {
          className: 'tendency-title__icon-section',
        },
        icon: {
          active: tooltipOpen,
          openTooltip: () => setTooltipOpen(true),
          closeTooltip: () => setTooltipOpen(false),
          className: 'tendency-title__icon-section-icon',
        },
        tooltip: {
          className: tooltipOpen
            ? 'tendency-title__icon-section-tooltip'
            : 'closed tendency-title__icon-section-tooltip',
        },
      },
    },
    body: {
      barNoData: {
        wrapper: {
          className: 'tendency__bar-section-bar',
        },
        greyBar: {
          className: 'tendency__bar-section-bar-grey',
        },
        lines: {
          className: 'tendency__bar-section-bar-lines',
          src: barSeparators,
        },
        noData: {
          className: 'tendency-footer__not-enough-data',
        },
      },
      animatedBar: {
        wrapper: {
          className: 'tendency__bar-section',
        },
        animatedTriangle: {
          wrapper: {
            className: 'tendency__bar-section-triangle',
            style: {
              left: `${measure || 50}%`,
              transitionDuration: `${animationDuration}s`,
              transitionTimingFunction: 'ease',
            },
          },
          triangle: {
            fill: isCurrentHasTypeNumber ? triangleColor : '#c5c5cc',
            className: 'tendency__bar-section-triangle-color',
          },
        },
        bar: {
          wrapper: {
            className: 'tendency__bar-section-bar',
          },
          blueBackground: {
            className: tooltipOpen
              ? 'active tendency__bar-section-bar-blue-background'
              : 'tendency__bar-section-bar-blue-background',
          },
          lines: {
            className: 'tendency__bar-section-bar-lines',
            src: barSeparators,
          },
        },
      },
    },
    footer: {
      wrapper: { className: 'tendency-footer' },
      label: { className: 'tendency-footer__text' },
    },
  };

  const titleSection = (
    <div {...attrs.title.wrapper}>
      {title}
      <div {...attrs.title.tooltip.wrapper}>
        <TooltipIcon {...attrs.title.tooltip.icon} />
        <div {...attrs.title.tooltip.tooltip}>{tooltipMessage}</div>
      </div>
    </div>
  );

  const totalNoDataCheck = noData;

  const conditionalBodySection = conditionToShowNoData ? (
    <div {...attrs.body.barNoData.wrapper}>
      <div {...attrs.body.barNoData.greyBar} />
      <img {...attrs.body.barNoData.lines} alt="lines" />
      {totalNoDataCheck ? null : (
        <>
          {!value ? (
            <span {...attrs.body.barNoData.noData}>Not enough data</span>
          ) : null}
        </>
      )}
    </div>
  ) : (
    <div {...attrs.body.animatedBar.wrapper}>
      <div {...attrs.body.animatedBar.animatedTriangle.wrapper}>
        <Triangle {...attrs.body.animatedBar.animatedTriangle.triangle} />
      </div>
      <div {...attrs.body.animatedBar.bar.wrapper}>
        <div {...attrs.body.animatedBar.bar.blueBackground} />
        <img {...attrs.body.animatedBar.bar.lines} alt="lines" />
      </div>
    </div>
  );

  const footer = (
    <div {...attrs.footer.wrapper}>
      <span {...attrs.footer.label}>Low</span>
      <span {...attrs.footer.label}>High</span>
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      {titleSection}
      {conditionalBodySection}
      {footer}
    </div>
  );
};
