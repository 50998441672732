import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { RootState } from '../../../store/store';
import { updateUser } from '../../../store/users/users.actions';

type RenameUserForm = {
  firstName?: string;
  lastName?: string;
  title?: string;
  phoneNumber?: string;
  timezone?: string;
};
// eslint-disable-next-line
export const useEditUserInfo = (onClose: () => void) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const handleSubmit = (values: RenameUserForm) => {
    dispatch(updateUser(values, user?.orgId || '', user?.userId || ''));
    onClose();
  };

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('*Required field')
      .matches(/[a-zA-Z+]/, 'The value in this field must start with a letter'),
    lastName: Yup.string()
      .required('*Required field')
      .typeError('This is a required field')
      .matches(/[a-zA-Z+]/, 'The value in this field must start with a letter'),
    phoneNumber: Yup.string()
      .min(6, 'Please enter a valid phone number')
      .max(14, 'Too long phone number')
      .matches(phoneRegExp, 'Phone number is not valid'),
  });

  const formik = useFormik<RenameUserForm>({
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      title: user?.title || '',
      phoneNumber: user?.phoneNumber || '',
      timezone: user?.timezone || '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  const { initialValues } = formik;

  return {
    ...formik,
    handleCancel,
    initialValues,
  };
};
