import { RelationshipEventType } from '../../../../../types/relationshipEvent.types';
import { MailIcon } from '../assets/MailIcon';
import { MeetingEventIcon } from '../../../../../components/Icons/MeetingEventIcon';

export const getEventSourceIcon = (
  source?: RelationshipEventType | null
): React.FC<React.SVGProps<SVGSVGElement>> => {
  switch (source) {
    case RelationshipEventType.ONLINE_MEETING:
      return MeetingEventIcon;
    case RelationshipEventType.EMAIL_BRANCH:
      return MailIcon;
    default:
      return MailIcon;
  }
};
