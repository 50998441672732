import React from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { useTooltip } from '../hooks/useTooltip';

type AddIconProps = {
  onClick: () => void;
  tooltipText: string;
  width?: string;
  disabled?: boolean;
};

export const AddIcon: React.FC<AddIconProps> = ({
  onClick,
  tooltipText,
  width,
  disabled,
}: AddIconProps): JSX.Element => {
  const { handleToggleTooltip, open } = useTooltip();
  const fillColor = !disabled && open ? '#333438' : '#898EA1';
  return (
    <div className="table__icon">
      <Tooltip
        open={!disabled ? open : false}
        text={tooltipText}
        width={width}
      />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={handleToggleTooltip}
        onMouseLeave={handleToggleTooltip}
        className={
          disabled ? 'disabled table__icon__icon' : 'table__icon__icon'
        }
        onClick={onClick}
      >
        <rect
          x="11.2002"
          y="5"
          width="2"
          height="14.4003"
          rx="1"
          fill={fillColor}
          style={{ transitionDuration: '0.2s' }}
        />
        <rect
          x="19.4004"
          y="11.2002"
          width="2"
          height="14.4003"
          rx="1"
          transform="rotate(90 19.4004 11.2002)"
          fill={fillColor}
          style={{ transitionDuration: '0.2s' }}
        />
      </svg>
    </div>
  );
};
