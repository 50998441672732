import React from 'react';

export const EditIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M12.1545 2.67178C11.6338 2.15108 10.7896 2.15108 10.2689 2.67178L9.8747 3.06596C9.87603 3.06833 9.87734 3.07071 9.87864 3.0731L10.2042 3.67224C10.6345 4.46385 11.2846 5.114 12.0762 5.5442L12.6753 5.86981C12.7903 5.93228 12.8795 6.02366 12.9389 6.13002L13.333 5.73591C13.8537 5.21521 13.8537 4.37099 13.333 3.85029L12.1545 2.67178Z"
      fill="#646F87"
    />
    <path
      d="M12.0315 7.03742L11.4395 6.71571C10.4218 6.16259 9.58586 5.32668 9.03274 4.30891L8.89155 4.04911L3.51585 9.42481C3.3286 9.61206 3.20135 9.85083 3.15032 10.1107L2.67105 12.5511C2.57976 13.016 2.98884 13.425 3.45369 13.3337L5.89411 12.8545C6.15396 12.8034 6.39273 12.6762 6.57998 12.4889L12.0315 7.03742Z"
      fill="#646F87"
    />
  </svg>
);
