import React from 'react';

export const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6654 7.99984C14.6654 4.31794 11.6806 1.33317 7.9987 1.33317C4.3168 1.33317 1.33203 4.31794 1.33203 7.99984C1.33203 11.6817 4.3168 14.6665 7.9987 14.6665C11.6806 14.6665 14.6654 11.6817 14.6654 7.99984ZM7.9987 11.9998C8.36689 11.9998 8.66536 11.7014 8.66536 11.3332V6.6665C8.66536 6.29831 8.36689 5.99984 7.9987 5.99984C7.63051 5.99984 7.33203 6.29831 7.33203 6.6665V11.3332C7.33203 11.7014 7.63051 11.9998 7.9987 11.9998ZM7.9987 3.6665C8.45894 3.6665 8.83203 4.0396 8.83203 4.49984C8.83203 4.96007 8.45894 5.33317 7.9987 5.33317C7.53846 5.33317 7.16536 4.96007 7.16536 4.49984C7.16536 4.0396 7.53846 3.6665 7.9987 3.6665Z"
      fill="#646F87"
    />
  </svg>
);
