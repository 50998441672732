import React from 'react';
import { Modal } from '../../../../../../../../components/SoundWave';
import { useAppDispatch } from '../../../../../../../../store/hooks';
import { openAddNoteModal } from '../../../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { CrossIcon } from './icons/CrossIcon';
import { EditIcon } from './icons/EditIcon';

export interface NoteItemOverviewProps {
  accountId: string;
  noteId: string;
  note: string;
  dateText: string;
  timeText: string;
  isOpened: boolean;
  onClose: () => void;
}

export const NoteItemOverview: React.FC<NoteItemOverviewProps> = ({
  accountId,
  noteId,
  note,
  dateText,
  timeText,
  isOpened,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const attrs = {
    modal: {
      isOpened,
      onClose,
      width: 560,
      className: 'note-item-overview',
      closeOnClickOutside: true,
    },
    header: {
      className: 'note-item-overview__header',
    },
    headerTextWrapper: {
      className: 'note-item-overview__header-text-wrapper',
    },
    dateText: {
      className: 'note-item-overview__date-text',
    },
    timeText: {
      className: 'note-item-overview__time-text',
    },
    headerButtonsWrapper: {
      className: 'note-item-overview__header-buttons-wrapper',
    },
    editButton: {
      type: 'button' as const,
      className: 'note-item-overview__button',
      onClick: () => {
        dispatch(
          openAddNoteModal({
            accountId,
            noteId,
            intialValues: {
              note,
            },
          })
        );
      },
    },
    closeButton: {
      type: 'button' as const,
      className: 'note-item-overview__button',
      onClick: onClose,
    },
    content: {
      className: 'note-item-overview__content',
    },
    title: {
      className: 'note-item-overview__title',
    },
    text: {
      className: 'note-item-overview__text',
    },
  };

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.header}>
        <div {...attrs.headerTextWrapper}>
          <span {...attrs.dateText}>{dateText}</span>
          <span {...attrs.timeText}>{timeText}</span>
        </div>
        <div {...attrs.headerButtonsWrapper}>
          <button {...attrs.editButton}>
            <EditIcon />
          </button>
          <button {...attrs.closeButton}>
            <CrossIcon />
          </button>
        </div>
      </div>
      <div {...attrs.content}>
        <span {...attrs.title}>Note</span>
        <span {...attrs.text}>{note}</span>
      </div>
    </Modal>
  );
};
