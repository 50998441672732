import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { QActivitySkeleton } from '../../skeletons/QActivitySkeleton';

type Data = {
  date: string;
  userAvgEmailLengthWords: number;
  buyerAvgEmailLengthWords: number;
}[];

type Props = {
  emailLengthData?: Data;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
};

type LocalData = {
  date: string;
  user: number;
  buyer: number;
}[];

export const AvgEmailLength: React.FC<Props> = ({
  emailLengthData,
  isLoading,
  noData,
  error,
}): JSX.Element => {
  const [data, setData] = useState<LocalData>([]);

  const [lineHovered, setLineHovered] = useState<'my' | 'p' | ''>('');

  useEffect(() => {
    if (emailLengthData?.length) {
      // const customData: LocalData = []; // eslint-disable-next-line
      // for (let i = 0; i < 15; i++) {
      //   customData.push({
      //     date: `Jan ${i + 1}`,
      //     user: Math.random() * 150,
      //     buyer: Math.random() * 300,
      //   });
      // }
      // setData(customData);
      const formattedArray = emailLengthData.map((el) => ({
        ...el,
        user: el.userAvgEmailLengthWords,
        buyer: el.buyerAvgEmailLengthWords,
      }));
      setData(formattedArray);
    } // eslint-disable-next-line
  }, [emailLengthData?.length]);

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
      style: { width: '200px' },
    },
    yourSection: {
      wrapper: {
        className: 'chart-tooltip__multi-line',
      },
      border: {
        className: 'chart-tooltip__multi-line-left-border',
        style: {
          backgroundColor: '#4EB4FF',
          height: '34px',
        },
      },
      body: {
        className: 'chart-tooltip__multi-line__body',
      },
    },
    prospectSection: {
      wrapper: {
        className: 'chart-tooltip__multi-line',
      },
      border: {
        className: 'chart-tooltip__multi-line-left-border',
        style: {
          backgroundColor: '#365798',
          height: '34px',
        },
      },
      body: {
        className: 'chart-tooltip__multi-line__body',
      },
    },
    date: {
      className: 'chart-tooltip__date-on-top',
    },
    label: {
      className: 'chart-tooltip__label__email-length',
    },
    wordsQty: {
      className: 'chart-tooltip__label__email-length-value',
    },
    wordsText: {
      className: 'chart-tooltip__value-text',
    },
    persentageValueArrow: {
      className: 'chart-tooltip__percents-arrow',
    },
    persentageValuetext: {
      className: 'chart-tooltip__percents-text',
    },
    boldText: {
      className: 'chart-tooltip__percents-text-bold',
    },
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;
    if (active && data.length && !noData && !error) {
      const { date } = payload[0].payload;
      const { user } = payload[0].payload;
      const { buyer } = payload[1].payload;

      const formattedDate = moment(date).format('ddd, MMM D, YYYY');

      const valueToShow = (value: number) => {
        if (value && !Number.isNaN(value)) {
          return (
            <span {...tooltipAttrs.wordsQty}>
              {value?.toFixed(0)}{' '}
              <span {...tooltipAttrs.wordsText}>
                {value?.toFixed(0) === '1' ? 'word' : 'words'}
              </span>
            </span>
          );
        }
        return (
          <span {...tooltipAttrs.wordsQty}>
            0<span {...tooltipAttrs.wordsText}> words</span>
          </span>
        );
      };

      const userWords = valueToShow(user);
      const prospectsWords = valueToShow(buyer);

      result = (
        <div {...tooltipAttrs.wrapper}>
          <span {...tooltipAttrs.date}>{formattedDate}</span>
          <div {...tooltipAttrs.yourSection.wrapper}>
            <div {...tooltipAttrs.yourSection.border} />
            <div {...tooltipAttrs.yourSection.body}>
              <span {...tooltipAttrs.label}>My word count {userWords}</span>
            </div>
          </div>
          <div {...tooltipAttrs.prospectSection.wrapper}>
            <div {...tooltipAttrs.prospectSection.border} />
            <div {...tooltipAttrs.prospectSection.body}>
              <span {...tooltipAttrs.label}>
                Prospects’ word count {prospectsWords}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return result;
  };

  const defineLinesColors = () => {
    let userLineColor = '#4EB4FF';

    let buyerLineColor = '#365798';

    if (noData || error) {
      userLineColor = 'rgba(0, 0, 0, 0)';

      buyerLineColor = 'rgba(0, 0, 0, 0)';
    } else if (lineHovered === 'my') {
      userLineColor = '#2F86C7';
    } else if (lineHovered === 'p') {
      buyerLineColor = '#243F73';
    }

    return { userLineColor, buyerLineColor };
  };

  const { userLineColor, buyerLineColor } = defineLinesColors();

  const attrs = {
    chartWrapper: {
      title: 'Message Word Count',
      subtitle: 'Average, by day',
      tooltipText: `This shows the average word count 
      of your deal-related messages compared to that of your prospects.`,
      isNoData: noData || error,
    },
    legendWrapper: {
      className: 'charts__avg-partics',
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    legendUser: { className: 'charts__avg-partics__user-legend' },
    legendProspect: { className: 'charts__avg-partics__prospect-legend' },
    legendOther: { className: 'charts__avg-partics__other-legend' },
    responsiveContainer: { height: '100%' },
    chart: {
      lineChart: {
        width: 295,
        height: 220,
        data,
        margin: { top: 30, right: 0, left: -28, bottom: -5 },
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      cartesianGrid: {
        strokeDasharray: '0 0',
        vertical: false,
        stroke: '#F8F8FB',
        strokeWidth: 1,
      },
      xAxis: {
        dataKey: 'date',
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 8,
        tickLine: { stroke: '#F8F8FB' },
        axisLine: { stroke: '#F8F8FB' },
        tickMargin: 9,
        padding: { right: 5, left: 15 },
        interval: 0,
        tickFormatter: (str: string, i: number) => {
          const desiredIndex = data?.length / 5 || 1;
          if (i % Math.ceil(desiredIndex) === 0) {
            return moment(str).format('MMM D');
          }
          return '';
        },
      },
      yAxis: {
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 0,
        tickLine: false,
        axisLine: false,
        tickMargin: 7,
      },
      tooltip: {
        wrapperStyle: { top: '-167px', left: '-111px' },
        content: <CustomTooltip payload={data} active />,
        allowEscapeViewBox: { x: true, y: true },
        isAnimationActive: false,
        cursor: !noData && !error,
      },
      userLine: {
        dataKey: 'user',
        stroke: userLineColor,
        strokeWidth: 2,
        dot: false,
        activeDot: {
          strokeWidth: 2,
          r: 4,
          onMouseEnter: () => setLineHovered('my'),
          onMouseLeave: () => setLineHovered(''),
        },
        style: { filter: 'drop-shadow(1px 2px 2px rgba(36, 45, 81, 0.3))' },
        onMouseEnter: () => setLineHovered('my'),
        onMouseLeave: () => setLineHovered(''),
      },
      prospectLine: {
        dataKey: 'buyer',
        stroke: buyerLineColor,
        strokeWidth: 2,
        dot: false,
        activeDot: {
          strokeWidth: 2,
          r: 4,
          onMouseEnter: () => setLineHovered('p'),
          onMouseLeave: () => setLineHovered(''),
        },
        style: { filter: 'drop-shadow(1px 2px 2px rgba(78, 180, 255, 0.3))' },
        onMouseEnter: () => setLineHovered('p'),
        onMouseLeave: () => setLineHovered(''),
      },
    },
  };

  return (
    <QActivitySkeleton isLoading={isLoading}>
      <ChartWrapper {...attrs.chartWrapper}>
        <>
          <div {...attrs.legendWrapper}>
            <span {...attrs.legendUser}>My word count</span>
            <span {...attrs.legendProspect}>Prospects’ word count</span>
          </div>
          <ResponsiveContainer {...attrs.responsiveContainer}>
            <LineChart {...attrs.chart.lineChart}>
              <CartesianGrid {...attrs.chart.cartesianGrid} />
              <XAxis {...attrs.chart.xAxis} />
              <YAxis {...attrs.chart.yAxis} />
              <Tooltip {...attrs.chart.tooltip} />
              <Line {...attrs.chart.prospectLine} type="monotone" />
              <Line {...attrs.chart.userLine} type="monotone" />
            </LineChart>
          </ResponsiveContainer>
        </>
      </ChartWrapper>
    </QActivitySkeleton>
  );
};
