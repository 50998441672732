import React, { useEffect, useState } from 'react';
import {
  SellingZoneElement,
  SellingZoneGraphItemSelected,
  SellingZoneTimelineChanged,
} from '@substratahq/selling-zone/typing/base';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getSellingZoneData } from './SellingZone.utils';
import {
  updateCurrentBranch,
  updateCurrentEmail,
  updateGraphItems,
  updateSelectedGraphItem,
} from '../../../../store/threadDetails/threadDetails.slice';
import { useMixpanel } from '../../../../hooks';

export interface SellingZoneProps {
  sellingZoneRef: React.MutableRefObject<SellingZoneElement | null>;
}

export const SellingZone: React.FC<SellingZoneProps> = ({ sellingZoneRef }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const detailedAnalysis = useAppSelector(
    (state) => state.threadDetails.analysis
  );

  const [firstReplyIndex, setFirstReplyIndex] = useState(0);

  const { messageId, branchId } = useParams<{
    messageId: string;
    branchId: string;
  }>();

  const { trackEvent } = useMixpanel();

  // eslint-disable-next-line
  // @ts-ignore
  const sellingZoneComponent = <selling-zone-element ref={sellingZoneRef} />;

  useEffect(() => {
    const sellingZoneRefCurrent = sellingZoneRef?.current;

    const onBranchChanged = (event: CustomEvent<string>) => {
      if (detailedAnalysis?.branch && event) {
        dispatch(updateCurrentBranch(detailedAnalysis.branch));
        trackEvent('changeBranch');
      }
    };

    const onMessageSelected = (event: CustomEvent<string>) => {
      dispatch(updateCurrentEmail(event.detail));
      trackEvent('changeEmail');
    };

    const onTimelineChanged = (
      event: CustomEvent<SellingZoneTimelineChanged>
    ) => {
      const graphItems =
        event?.detail?.graphItems?.map(({ date, ...rest }) => ({
          ...rest,
        })) || [];

      dispatch(updateGraphItems(graphItems));
    };

    const onGraphItemSelected = (
      event: CustomEvent<SellingZoneGraphItemSelected>
    ) => {
      dispatch(updateSelectedGraphItem(event.detail?.id));
    };

    sellingZoneRefCurrent?.addEventListener<'branchChanged'>(
      'branchChanged',
      onBranchChanged,
      false
    );

    sellingZoneRefCurrent?.addEventListener<'messageSelected'>(
      'messageSelected',
      onMessageSelected,
      false
    );

    sellingZoneRefCurrent?.addEventListener<'timelineChanged'>(
      'timelineChanged',
      onTimelineChanged,
      false
    );

    sellingZoneRefCurrent?.addEventListener<'graphItemSelected'>(
      'graphItemSelected',
      onGraphItemSelected,
      false
    );

    return () => {
      sellingZoneRefCurrent?.removeEventListener(
        'branchChanged',
        onBranchChanged,
        true
      );
      sellingZoneRefCurrent?.removeEventListener(
        'messageSelected',
        onMessageSelected,
        true
      );
      sellingZoneRefCurrent?.addEventListener<'timelineChanged'>(
        'timelineChanged',
        onTimelineChanged,
        true
      );
      sellingZoneRefCurrent?.addEventListener<'graphItemSelected'>(
        'graphItemSelected',
        onGraphItemSelected,
        true
      );
    };
  }, [detailedAnalysis, dispatch, sellingZoneRef, trackEvent]);

  useEffect(() => {
    const sellingZoneRefCurrent = sellingZoneRef?.current;

    if (sellingZoneRefCurrent) {
      sellingZoneRefCurrent.internalThreadReadMoreUrl =
        'https://help.substrata.me/en/articles/6632615-intra-organizational-communication';

      sellingZoneRefCurrent.sellingZoneReadMoreUrl =
        'https://help.substrata.me/en/articles/6519583-selling-zone-101';
    }
  }, [sellingZoneRef]);

  useEffect(() => {
    const sellingZoneRefCurrent = sellingZoneRef?.current;

    if (user && sellingZoneRefCurrent) {
      sellingZoneRefCurrent.curUserInfo = {
        email: user.email,
        name: user.name,
      };
    }
  }, [sellingZoneRef, user]);

  useEffect(() => {
    const sellingZoneRefCurrent = sellingZoneRef?.current;

    if (!detailedAnalysis && sellingZoneRefCurrent) {
      sellingZoneRefCurrent.currentAnimationStep = 0;
    }
  }, [detailedAnalysis, sellingZoneRef]);

  useEffect(() => {
    const sellingZoneRefCurrent = sellingZoneRef?.current;

    if (sellingZoneRefCurrent && detailedAnalysis) {
      sellingZoneRefCurrent.currentAnimationStep = 3;
      sellingZoneRefCurrent.analyzedData = getSellingZoneData({
        analyzedData: detailedAnalysis,
        branchId,
        messageId,
        firstReplyIndex,
        setFirstReplyIndex,
      });
    }
  }, [branchId, detailedAnalysis, firstReplyIndex, messageId, sellingZoneRef]);

  return sellingZoneComponent;
};
