import React from 'react';

export const CheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4715 4.86203C12.7317 5.12245 12.7316 5.54456 12.4712 5.80484L7.13471 11.1382C7.00963 11.2632 6.84 11.3334 6.66315 11.3333C6.48631 11.3332 6.31674 11.2629 6.19177 11.1378L3.52824 8.47109C3.26804 8.21058 3.26829 7.78847 3.52879 7.52828C3.78929 7.26808 4.2114 7.26833 4.4716 7.52883L6.66386 9.72368L11.5287 4.86175C11.7891 4.60148 12.2112 4.6016 12.4715 4.86203Z"
      fill="#646F87"
    />
  </svg>
);
