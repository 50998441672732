import React from 'react';

export const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.50275 2.87247C5.94089 1.9336 6.88317 1.3335 7.91924 1.3335H8.07819C9.11426 1.3335 10.0565 1.9336 10.4947 2.87247L14.6707 11.8211C15.2895 13.147 14.3216 14.6668 12.8583 14.6668H3.13911C1.67587 14.6668 0.707957 13.147 1.32674 11.8211L5.50275 2.87247ZM7.99871 4.00016C8.3669 4.00016 8.66538 4.29864 8.66538 4.66683V9.3335C8.66538 9.70169 8.3669 10.0002 7.99871 10.0002C7.63052 10.0002 7.33205 9.70169 7.33205 9.3335V4.66683C7.33205 4.29864 7.63052 4.00016 7.99871 4.00016ZM7.99871 12.3335C8.45895 12.3335 8.83205 11.9604 8.83205 11.5002C8.83205 11.0399 8.45895 10.6668 7.99871 10.6668C7.53848 10.6668 7.16538 11.0399 7.16538 11.5002C7.16538 11.9604 7.53848 12.3335 7.99871 12.3335Z"
      fill="#F0384A"
    />
  </svg>
);
