import React from 'react';
import {
  ConfirmationModal,
  Modal,
  Form,
  FormField,
} from '../../../../components/SoundWave';
import { useUpgradeModal } from './hooks/useUpgradeModal';

export interface UpgradeModalProps {
  feature: string | null;
  onClose: () => void;
}

export const UpgradeModal: React.FC<UpgradeModalProps> = ({
  feature,
  onClose,
}) => {
  const {
    modalProps,
    formProps,
    fieldProps,
    confirmationModalProps,
  } = useUpgradeModal({
    feature,
    onClose,
  });

  const attrs = {
    title: {
      className: 'apps__modal-title',
    },
    fieldset: {
      className: 'sound-wave-form__fieldset',
    },
  };

  return (
    <>
      <Modal {...modalProps}>
        <div {...attrs.title}>Contact Us for Upgade</div>
        <Form {...formProps}>
          <fieldset {...attrs.fieldset}>
            <FormField {...fieldProps.note} />
          </fieldset>
        </Form>
      </Modal>
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
};
