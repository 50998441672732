import { Account } from '../../types/account.types';
import { Contact, ContactPhoneNumber } from '../../types/contact.types';
import { Pagination } from '../../types/pagination.types';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberSide,
} from '../../types/relationshipMember.types';

export interface AddMemberFormValues {
  email: string;
  firstName: string;
  lastName: string;
  title: string;
  phone: string;
  countryCode: string;
  assignedTo: string;
  contactType: string;
  avatar: string;
  note: string;
}

export interface AddAccountFormValues {
  logo: string;
  name: string;
  website: string;
}

export interface AssignAccountFormValues {
  accountId: string;
}

export interface AddNoteFormValues {
  note: string;
}

export interface CreateAccountRequestBody {
  name: string;
  companyWebsite: string;
  avatarB64: {
    contentType: string | null;
    b64content: string;
  } | null;
}

export type UpdateAccountRequestBody = Partial<CreateAccountRequestBody>;

export interface CreateContactRequestBody {
  contactDetails: {
    email: string;
    firstName: string;
    lastName: string;
    title: string | null;
    phoneNumber: ContactPhoneNumber | null;
    avatarB64: {
      contentType: string | null;
      b64content: string;
    } | null;
  };
}

export type UpdateContactRequestBody = Partial<
  CreateContactRequestBody['contactDetails']
>;

export interface LinkContactToAccountRequestBody {
  accountId: string;
  contactSide: RelationshipMemberSide;
  associationType: RelationshipMemberAssociationType;
  note: string | null;
}

export interface GetExisitingContactSuggestionsRequestBody {
  search: {
    partialMatch?: boolean;
    fields: string[];
    value: string;
  };
  pagination?: Partial<Pagination>;
  includeAssociatedAccounts?: boolean;
}

export interface GetExisitingAccountSuggestionsRequestBody {
  search: GetExisitingContactSuggestionsRequestBody['search'];
  pagination?: Partial<Pagination>;
}

export interface CreateAccountAddBotFormValues {
  name: string;
  website: string;
}

export enum CreateAccountErrorType {
  NAME_EXISTS = 'name exists',
  NOT_FOUND = 'not found',
  UNKNOWN = 'unknown',
}

export interface AddBotFormValues {
  title: string;
  joinUrl: string;
  accountId: string;
}

export interface RelationshipFlowsState {
  isLoading: {
    emailSuggestions: boolean;
    accountSuggestions: boolean;
    addAccount: boolean;
    createContact: boolean;
    linkContactToAccount: boolean;
    assignEventToAccount: boolean;
  };
  isError: {
    emailSuggestions: boolean;
    accountSuggestions: boolean;
    addAccount: boolean;
    createContact: boolean;
    linkContactToAccount: boolean;
    assignEventToAccount: boolean;
  };
  addMember: {
    isModalOpened: boolean;
    isEditFlow: boolean;
    isEditingExistingContact: boolean;
    existingContact: Contact | null;
    accountId: string | null;
    accountName: string | null;
    existingContactSuggestions: Contact[];
    initialValues: AddMemberFormValues;
  };
  addAccount: {
    isModalOpened: boolean;
    withRedirect: boolean;
    accountId: string | null;
    initialValues: AddAccountFormValues;
  };
  assignAccount: {
    isModalOpened: boolean;
    eventId: string | null;
    isReassignFlow: boolean;
    selectedAccount: Account | null;
    existingAccountSuggestions: Account[];
  };
  unassignAccount: {
    isModalOpened: boolean;
    eventId: string | null;
  };
  addNote: {
    isModalOpened: boolean;
    noteId: string | null;
    accountId: string | null;
    initialValues: AddNoteFormValues;
  };
  addBot: {
    isModalOpened: boolean;
    isLoading: boolean;
    isError: boolean;
    meetingUrlFieldError: boolean;
    isSuccess: boolean;
    selectedAccount: Account | null;
    accounts: Account[] | [];
    addAccount: {
      isLoading: boolean;
      isError: boolean;
      isSuccess: boolean;
      errorType: CreateAccountErrorType | null;
    };
  };
}
