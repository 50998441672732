import React, { useState } from 'react';
import { TooltipIcon } from '../../../../components/Icons/TooltipIcon';
import { Stats } from '../../../../store/analytics/analytics.types';
import { User } from '../../../../types';
import DealStatsSkeleton from '../skeletons/DealStatsSkeleton';

interface Props {
  user: User | null;
  stats: Stats | null;
  noData: boolean;
  error: boolean;
  isLoading: boolean;
  isGetDataCalledFirstTime: boolean;
}

export const ThirdColumn: React.FC<Props> = ({ isLoading }): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const attrs = {
    wrapper: { className: 'personalQ__thirdColumn' },
    dateSelect: { className: 'personalQ__thirdColumn-date__select' },
    skeleton: {
      dealStats: {
        duration: 3200,
        globalLoading: isLoading,
      },
    },
    tooltipIcon: {
      active: tooltipOpen,
      className: 'personalQ__thirdColumn-header-title__tooltip-section-icon',
      openTooltip: setTooltipOpen,
      closeTooltip: setTooltipOpen,
    },
    tooltip: {
      wrapper: {
        className: `personalQ__thirdColumn-header-title__tooltip-section-tooltip ${
          tooltipOpen ? 'open' : 'closed'
        }`,
      },
      text: {
        className:
          'personalQ__thirdColumn-header-title__tooltip-section-tooltip-text',
      },
      boldText: {
        className:
          'personalQ__thirdColumn-header-title__tooltip-section-tooltip-text-bold',
      },
    },
    div: {
      header: { className: 'personalQ__thirdColumn-header' },
      tooltipBlock: {
        className: 'personalQ__thirdColumn-header-title__tooltip-section',
      },
      body: { className: 'personalQ__thirdColumn-body' },
      statsWrapper: {
        className: 'personalQ__thirdColumn-body-wrapper',
      },
      stats: { className: 'personalQ__thirdColumn-body-wrapper-stats' },
      schedule: { className: 'personalQ__thirdColumn-body-schedule' },
    },
    span: {
      headerTitle: { className: 'personalQ__thirdColumn-header-title' },
      comingSoon: { className: 'personalQ__thirdColumn-header-coming__soon' },
      statsLabel: {
        className: 'personalQ__thirdColumn-body-wrapper-stats-label',
      },
      statsValue: {
        className: 'personalQ__thirdColumn-body-wrapper-stats-value',
      },
    },
    img: {
      firstSchedule: {
        className: 'personalQ__thirdColumn-body-schedule-first',
      },
      secondSchedule: {
        className: 'personalQ__thirdColumn-body-schedule-second',
      },
    },
  };

  const headerTooltip = (
    <div {...attrs.tooltip.wrapper}>
      <div {...attrs.tooltip.text}>
        This shows the effect your
        <span {...attrs.tooltip.boldText}> Perceived Competence</span> has on
        your sales results, focusing on{' '}
        <span {...attrs.tooltip.boldText}>Win-Rate</span> (%) and{' '}
        <span {...attrs.tooltip.boldText}>Sales-Cycle</span> length.
        <br />
        <br />
        Your <span {...attrs.tooltip.boldText}>
          &quot;upper-hand &quot;
        </span>{' '}
        results across all of your engagements with any given prospect are used
        to calculate your{' '}
        <span {...attrs.tooltip.boldText}>Average Perceived Competence</span>{' '}
        (APC) in a given deal.
      </div>
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      <DealStatsSkeleton {...attrs.skeleton.dealStats}>
        <>
          <div {...attrs.div.header}>
            <span {...attrs.span.headerTitle}>
              APC / Pipeline
              <div {...attrs.div.tooltipBlock}>
                <TooltipIcon {...attrs.tooltipIcon} />
                {headerTooltip}
              </div>
            </span>
            <span {...attrs.span.comingSoon}>CRM Integration Coming Soon</span>
          </div>
          <div {...attrs.div.body}>
            <div {...attrs.div.statsWrapper}>
              <div {...attrs.div.stats}>
                <span {...attrs.span.statsLabel}>Win Rate</span>
                <span {...attrs.span.statsValue}>-</span>
              </div>
              <div {...attrs.div.stats}>
                <span {...attrs.span.statsLabel}>Total deals</span>
                <span {...attrs.span.statsValue}>-</span>
              </div>
            </div>

            <div {...attrs.div.schedule}>
              <div {...attrs.img.firstSchedule} />
            </div>
            <div {...attrs.div.schedule}>
              <div {...attrs.img.secondSchedule} />
            </div>
          </div>
        </>
      </DealStatsSkeleton>
    </div>
  );
};
