import React, { useState } from 'react';
import { MeetingRecordSource } from '../../../../../../types/meetingDetails.types';
import { Toggle } from '../toggle/Toggle';
import { defaultEventIcon, meetIcon, teamsIcon, zoomIcon } from './assets';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { updateUpcomingInviteAssistant } from '../../../../../../store/meetingRecords/meetingRecords.thunks';
import { checkFeatureAvailability } from '../../../myMeetingsTable/utils/checkForFeature';
import {
  Tooltip,
  TooltipPosition,
  TooltipSize,
} from '../../../../../../components/SoundWave';
import { Features } from '../../../../../../types';

interface Props {
  meetingType: MeetingRecordSource;
  meetingName: string;
  meetingTime: string;
  status: boolean;
  meetingId: string;
}

const getMeetingIcon = (src: MeetingRecordSource | null) => {
  switch (src) {
    case MeetingRecordSource.GOOGLE_MEET:
      return meetIcon;
    case MeetingRecordSource.ZOOM:
      return zoomIcon;
    case MeetingRecordSource.TEAMS:
      return teamsIcon;
    default:
      return defaultEventIcon;
  }
};

export const UpcomingMeetingsRow: React.FC<Props> = ({
  meetingType = MeetingRecordSource.GOOGLE_MEET,
  meetingName = 'N/A',
  meetingTime = '',
  status = false,
  meetingId = '',
}) => {
  const { user } = useAppSelector((state) => state.auth);

  const [toggleActive, setToggleActive] = useState(status);

  const dispatch = useAppDispatch();

  const meetingIcon = getMeetingIcon(meetingType);

  const isSoundwaveFeatureEnabled = checkFeatureAvailability(
    user,
    Features.SOUNDWAVE
  );

  const attrs = {
    wrapper: {
      className: 'upcoming-row',
    },

    rowBody: {
      wrapper: {
        className: 'upcoming-row__body',
      },

      iconSection: {
        wrapper: {
          className: 'upcoming-row__body__icon-section',
        },

        icon: {
          className: 'upcoming-row__body__icon-section-img',
          src: meetingIcon,
        },
      },

      info: {
        wrapper: {
          className: 'upcoming-row__body__info',
        },

        name: {
          wrapper: {
            className: 'upcoming-row__body__info__name',
          },

          text: {
            className: 'upcoming-row__body__info__name-text',
          },
        },

        time: {
          className: 'upcoming-row__body__info-time',
        },
      },

      toggle: {
        wrapper: {
          className: 'upcoming-row__body__toggle',
        },

        tooltip: {
          size: TooltipSize.M,
          width: 256,
          text:
            'Inviting assistant is not available for your account. Ask your Admin to activate this function.',
          position: TooltipPosition.TOP_END,
          positionOffset: 13,
        },
      },
    },

    accordion: {
      wrapper: {
        className: 'upcoming-row__accordion',
      },
      overflowWrapper: {
        className: 'upcoming-row__accordion-overflow',
      },
      hostName: {
        className: 'upcoming-row__accordion-host',
      },
      email: {
        className: 'upcoming-row__accordion-email',
      },
    },
  };

  const onToggle = () => {
    setToggleActive(!toggleActive);
    dispatch(
      updateUpcomingInviteAssistant({
        meetingId,
        action: !toggleActive ? 'schedule' : 'cancel',
      })
    );
  };

  const toggle = isSoundwaveFeatureEnabled ? (
    <Toggle active={toggleActive} onToggle={onToggle} />
  ) : (
    <Tooltip {...attrs.rowBody.toggle.tooltip}>
      <Toggle active={toggleActive} onToggle={() => {}} disabled />
    </Tooltip>
  );

  const hostEmail = meetingId.split('|')[0] || 'N/A';

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.rowBody.wrapper}>
        <div {...attrs.rowBody.iconSection.wrapper}>
          <img {...attrs.rowBody.iconSection.icon} alt="" />
        </div>
        <div {...attrs.rowBody.info.wrapper}>
          <div {...attrs.rowBody.info.name.wrapper}>
            <div {...attrs.rowBody.info.name.text}>{meetingName}</div>
          </div>
          <div {...attrs.rowBody.info.time}>{meetingTime}</div>
        </div>
        <div {...attrs.rowBody.toggle.wrapper}>{toggle}</div>
      </div>
      <div {...attrs.accordion.wrapper}>
        <div {...attrs.accordion.overflowWrapper}>
          <div {...attrs.accordion.hostName}>Host:</div>
          <div {...attrs.accordion.email}>{hostEmail}</div>
        </div>
      </div>
    </div>
  );
};
