import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { AvgResponseSkeleton } from '../../skeletons/AvgResponseSkeleton';

type Data = {
  date: string;
  avgRtMin: number;
}[];

type HistoricalData = {
  date: string;
  avgRtMin: number;
}[];

type Props = {
  responseTimeData?: Data;
  responseTimeHistoricalData?: HistoricalData;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
};

export const AvgResponseTime: React.FC<Props> = ({
  responseTimeData,
  responseTimeHistoricalData,
  isLoading,
  noData,
  error,
}): JSX.Element => {
  const [data, setData] = useState<Data>([]);

  const [historicalData, setHistoricalData] = useState<HistoricalData>([]);

  const [lineHovered, setLineHovered] = useState(false);

  useEffect(() => {
    if (responseTimeData?.length) {
      // const customData: Data = []; // eslint-disable-next-line
      // for (let i = 0; i < 30; i++) {
      //   customData.push({
      //     date: `Jan ${i + 1}`,
      //     avgRtMin: Math.floor(Math.random() * 3333),
      //   });
      // }
      // setData(customData);
      setData(responseTimeData);
      if (responseTimeHistoricalData?.length) {
        setHistoricalData(responseTimeHistoricalData);
      }
    } // eslint-disable-next-line
  }, [responseTimeData?.length, responseTimeHistoricalData?.length]);

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
      style: { width: '215px', height: '114px' },
    },
    date: {
      className: 'margin chart-tooltip__date-on-top',
    },
    body: {
      wrapper: {
        className: 'chart-tooltip__single-line',
      },
      border: {
        className: 'chart-tooltip__single-line-left-border',
      },
      contentSection: {
        className: 'chart-tooltip__single-line__body',
      },
    },
    label: {
      className: 'chart-tooltip__label',
    },
    time: {
      className: 'chart-tooltip__value',
    },
    timeText: {
      className: 'chart-tooltip__value-text',
    },
    aloneMessage: {
      className: 'chart-tooltip__value-text-alone',
      style: { margin: '7px 0px 14px 0px' },
    },
    bluePercents: {
      className: 'chart-tooltip__percents-blue',
    },
    persentageValuetext: {
      className: 'chart-tooltip__percents-text',
    },
    boldText: {
      className: 'chart-tooltip__percents-text-bold',
    },
  };

  const formatter = (value: number) => {
    const days = value >= 1440 * 3 ? `${Math.round(value / 1440)}d` : '';

    const hours = value >= 60 ? `${Math.round(value / 60)}h` : '';

    const minutes = value < 60 ? `${Math.round(value % 60)}m` : '';

    const result = () => {
      let time = `${value}`;

      if (!hours && minutes.length >= 2 && value !== 0) {
        time = minutes;
      } else if (hours && minutes.length < 3 && !days) {
        time = hours;
      } else if (hours && minutes && !days) {
        time = `${hours}${minutes}`;
      } else if (Math.round(value / 60) >= 48) {
        time = days;
      }

      return time;
    };
    return result();
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    if (active && data.length) {
      const currentDayIndex = data.map((el) => el).indexOf(payload[0].payload);

      const previousDayData =
        data[currentDayIndex !== 0 ? currentDayIndex - 1 : 0];

      const resTimeToday = +payload[0].payload.avgRtMin;

      const resTimeYesterday = +previousDayData.avgRtMin;

      const getPercentageComparison = () => {
        let resultToShow: string | number = '';

        if (currentDayIndex === 0 && !historicalData?.length) {
          resultToShow = 'N/A';
        } else if (resTimeToday === resTimeYesterday) {
          resultToShow = 'same';
        } else if (resTimeYesterday === 0 || resTimeYesterday === 0) {
          resultToShow = 0;
        } else {
          resultToShow = (
            (resTimeToday / resTimeYesterday) * 100 -
            100
          ).toFixed(0);
        }

        return resultToShow;
      };

      const percentageСomparison = getPercentageComparison();

      const conditionalPercentageMessage = () => {
        let resultMessage: string | JSX.Element = '';

        if (
          percentageСomparison === 0 ||
          percentageСomparison === 'N/A' ||
          percentageСomparison.toString().includes('NaN')
        ) {
          resultMessage = (
            <span {...tooltipAttrs.persentageValuetext}>
              <span {...tooltipAttrs.boldText}>N/A from previous day</span>
            </span>
          );
        } else if (percentageСomparison === 'same') {
          resultMessage = (
            <span {...tooltipAttrs.persentageValuetext}>
              <span {...tooltipAttrs.bluePercents}>0% </span>from previous day
            </span>
          );
        } else {
          const bueutifyValue = () => {
            let val = percentageСomparison;

            if (
              percentageСomparison?.toString().includes('-') &&
              percentageСomparison?.toString().length > 6
            ) {
              val = `${Math.round(+percentageСomparison * 0.001)}K `;
            } else if (
              +percentageСomparison > 0 &&
              percentageСomparison?.toString().length > 5
            ) {
              val = `+${Math.round(+percentageСomparison * 0.001)}K `;
            } else if (+percentageСomparison > 0) {
              val = `+${percentageСomparison}`;
            }

            return val;
          };

          const formattedValue = bueutifyValue();

          resultMessage = (
            <>
              {formattedValue}%{' '}
              <span {...tooltipAttrs.persentageValuetext}>
                from previous day
              </span>
            </>
          );
        }

        return resultMessage;
      };

      const conditionalFooterMessage = conditionalPercentageMessage();

      const formattedMinutes = payload[0]?.payload?.avgRtMin
        ? formatter(payload[0].payload.avgRtMin)
        : '0';

      const minsHoursOrDays = () => {
        let textToShow = '';

        if (
          formattedMinutes.includes('d') &&
          +formattedMinutes?.slice(0, -1) === 1
        ) {
          textToShow = 'day';
        } else if (
          formattedMinutes.includes('d') &&
          +formattedMinutes?.slice(0, -1) > 1
        ) {
          textToShow = 'days';
        } else if (
          formattedMinutes.includes('h') &&
          +formattedMinutes?.slice(0, -1) === 1
        ) {
          textToShow = 'hour';
        } else if (
          formattedMinutes.includes('h') &&
          +formattedMinutes?.slice(0, -1) > 1
        ) {
          textToShow = 'hours';
        } else if (
          (formattedMinutes.includes('m') &&
            +formattedMinutes?.slice(0, -1) > 1) ||
          +formattedMinutes?.slice(0, -1) === 0
        ) {
          textToShow = 'minutes';
        } else {
          textToShow = 'minute';
        }

        return textToShow;
      };

      const formattedDate = payload[0]?.payload?.date
        ? moment(payload[0].payload.date).format('ddd, MMM D, YYYY')
        : '';

      const conditionalMessage =
        +formattedMinutes === 0 ? (
          <span {...tooltipAttrs.aloneMessage}>No emails have been sent.</span>
        ) : (
          <>
            <span {...tooltipAttrs.time}>
              {formattedMinutes?.slice(0, -1) || 0}{' '}
              <span {...tooltipAttrs.timeText}>
                {minsHoursOrDays()} response time{' '}
              </span>
            </span>
            <div {...tooltipAttrs.bluePercents}>{conditionalFooterMessage}</div>
          </>
        );

      result = (
        <div {...tooltipAttrs.wrapper}>
          <span {...tooltipAttrs.date}>{formattedDate}</span>
          <div {...tooltipAttrs.body.wrapper}>
            <div
              {...tooltipAttrs.body.border}
              style={
                +formattedMinutes === 0
                  ? { backgroundColor: '#4EB4FF', height: '53px' }
                  : { backgroundColor: '#4EB4FF', height: '53px' }
              }
            />
            <div {...tooltipAttrs.body.contentSection}>
              <span {...tooltipAttrs.label}>My avg. response time </span>
              {conditionalMessage}
            </div>
          </div>
        </div>
      );
    }
    return result;
  };

  const defineLineColor = () => {
    let color = '#4EB4FF';

    if (noData || error) {
      color = 'rgba(0, 0, 0, 0)';
    } else if (lineHovered) {
      color = '#2F86C7';
    }

    return color;
  };

  const attrs = {
    Wrapper: {
      title: 'My Response Time to Messages',
      subtitle: 'Average, by day',
      tooltipText: `Your daily average response time to prospects' 
        messages for the selected time period.`,
      isNoData: noData || error,
    },
    legendWrapper: {
      className: 'charts__qactivity',
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    legendAnalyzed: {
      className: 'charts__qactivity-analyzed',
    },
    ResponsiveContainer: { height: '100%' },
    LineChart: {
      width: 295,
      height: 220,
      data,
      margin: { top: 25, right: 0, left: -25, bottom: -5 },
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    CartesianGrid: {
      strokeDasharray: '0 0',
      vertical: false,
      stroke: '#F8F8FB',
      strokeWidth: 1,
    },
    XAxis: {
      dataKey: 'date',
      tick: { stroke: '#646C83', strokeWidth: 0.1 },
      tickSize: 8,
      tickLine: { stroke: '#F8F8FB' },
      axisLine: { stroke: '#F8F8FB' },
      tickMargin: 8,
      padding: { right: 17, left: 15 },
      interval: 0,
      tickFormatter: (str: string, i: number) => {
        const desiredIndex = data?.length / 5 || 1;
        if (i % Math.ceil(desiredIndex) === 0) {
          return moment(str).format('MMM D');
        }
        return '';
      },
    },
    YAxis: {
      tick: { stroke: '#646C83', strokeWidth: 0.1 },
      tickSize: 0,
      tickLine: false,
      axisLine: false,
      tickMargin: 13,
      tickFormatter: formatter,
    },
    Tooltip: {
      wrapperStyle:
        noData || error
          ? { display: 'none' }
          : { top: '-140px', left: '-118px', width: '200px' },
      content: <CustomTooltip payload={data} active />,
      allowEscapeViewBox: { x: true, y: true },
      isAnimationActive: false,
      cursor: !noData && !error,
    },
    Line: {
      dataKey: 'avgRtMin',
      stroke: defineLineColor(),
      strokeWidth: 2,
      dot: false,
      activeDot: {
        strokeWidth: 2,
        r: 4,
        onMouseEnter: () => setLineHovered(true),
        onMouseLeave: () => setLineHovered(false),
      },
      style: { filter: 'drop-shadow(2px 2px 2px rgba(36, 45, 81, 0.3))' },
      onMouseEnter: () => setLineHovered(true),
      onMouseLeave: () => setLineHovered(false),
    },
  };

  return (
    <AvgResponseSkeleton isLoading={isLoading}>
      <>
        <ChartWrapper {...attrs.Wrapper}>
          <>
            <div {...attrs.legendWrapper}>
              <span {...attrs.legendAnalyzed}>My average response time</span>
            </div>
            <ResponsiveContainer {...attrs.ResponsiveContainer}>
              <LineChart {...attrs.LineChart}>
                <CartesianGrid {...attrs.CartesianGrid} />
                <XAxis {...attrs.XAxis} />
                <YAxis {...attrs.YAxis} />
                <Tooltip {...attrs.Tooltip} />
                <Line {...attrs.Line} type="linear" />
              </LineChart>
            </ResponsiveContainer>
          </>
        </ChartWrapper>
      </>
    </AvgResponseSkeleton>
  );
};
