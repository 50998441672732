export const getEventName = ({
  isLoading,
  meetingTitle,
  threadSubject,
  isMeetingDetailsPage,
  isThreadDetailsPage,
}: {
  isLoading: boolean;
  meetingTitle: string | null | undefined;
  threadSubject: string | null | undefined;
  isMeetingDetailsPage: boolean;
  isThreadDetailsPage: boolean;
}): string => {
  if (isLoading) {
    return '';
  }

  if (isMeetingDetailsPage) {
    return meetingTitle || 'No Meeting Title';
  }

  if (isThreadDetailsPage) {
    return threadSubject || 'No Subject';
  }

  return '';
};
