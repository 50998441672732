import { InfoIcon } from '../icons/InfoIcon';
import { SuccessIcon } from '../icons/SuccessIcon';
import { WarningIcon } from '../icons/WarningIcon';

export const getIconComponent = (
  isPositive?: boolean
): React.FC<React.SVGProps<SVGSVGElement>> => {
  if (typeof isPositive !== 'boolean') {
    return InfoIcon;
  }

  return isPositive ? SuccessIcon : WarningIcon;
};
