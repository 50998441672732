import { Contact } from '../../types/contact.types';
import {
  Email,
  EmailParticipant,
  EmailRecipient,
  EmailSender,
  EmailWithContactsInfo,
} from '../../types/emailAnalysis.types';

export const getEmailWithContactsInfo = (
  email: Email,
  contacts: Contact[]
): EmailWithContactsInfo => {
  const { sender, participants, recipients } = email;

  const senderWithContactInfo: EmailSender & { contact: Contact | null } = {
    ...sender,
    contact: null,
  };

  const participantsWithContactInfo: {
    leftParticipant: EmailParticipant & { contact: Contact | null };
    rightParticipant: EmailParticipant & { contact: Contact | null };
  } = {
    leftParticipant: { ...participants.leftParticipant, contact: null },
    rightParticipant: { ...participants.rightParticipant, contact: null },
  };

  const recipientsWithContactInfo: Array<
    EmailRecipient & { contact: Contact | null }
  > = recipients.map((recipient) => ({
    ...recipient,
    contact: null,
  }));

  contacts.forEach((contact) => {
    if (contact.email === sender.email) {
      senderWithContactInfo.contact = contact;
    }

    if (contact.email === participants.leftParticipant.email) {
      participantsWithContactInfo.leftParticipant.contact = contact;
    }

    if (contact.email === participants.rightParticipant.email) {
      participantsWithContactInfo.rightParticipant.contact = contact;
    }

    const recipientIndex = recipientsWithContactInfo.findIndex(
      (recipient) => recipient.email === contact.email
    );

    if (recipientIndex !== -1) {
      recipientsWithContactInfo[recipientIndex].contact = contact;
    }
  });

  return {
    ...email,
    sender: senderWithContactInfo,
    participants: participantsWithContactInfo,
    recipients: recipientsWithContactInfo,
  };
};
