import React from 'react';

export const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00275 2.87247C6.44089 1.9336 7.38317 1.3335 8.41924 1.3335H8.57819C9.61426 1.3335 10.5565 1.9336 10.9947 2.87247L15.1707 11.8211C15.7895 13.147 14.8216 14.6668 13.3583 14.6668H3.63911C2.17587 14.6668 1.20796 13.147 1.82674 11.8211L6.00275 2.87247ZM8.49871 4.00016C8.8669 4.00016 9.16538 4.29864 9.16538 4.66683V9.3335C9.16538 9.70169 8.8669 10.0002 8.49871 10.0002C8.13052 10.0002 7.83205 9.70169 7.83205 9.3335V4.66683C7.83205 4.29864 8.13052 4.00016 8.49871 4.00016ZM8.49871 12.3335C8.95895 12.3335 9.33205 11.9604 9.33205 11.5002C9.33205 11.0399 8.95895 10.6668 8.49871 10.6668C8.03848 10.6668 7.66538 11.0399 7.66538 11.5002C7.66538 11.9604 8.03848 12.3335 8.49871 12.3335Z"
      fill="#FFB200"
    />
  </svg>
);
