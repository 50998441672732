import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { sendEmailReply } from '../../../../../../store/threadDetails/threadDetails.thunks';
import { PlusIcon } from '../EmailListItem/icons/PlusIcon';
import { SendIcon } from '../EmailListItem/icons/SendIcon';
import { NotConnectedIcon } from '../EmailListItem/icons/NotConnectedIcon';
import { selectedBranchWithContactsInfoSelector } from '../../../../../../store/threadDetails/threadDetails.selectors';
import { resetReplyState } from '../../../../../../store/threadDetails/threadDetails.slice';
import { checkFeatureAvailability } from '../../../../../MyMeetings/components/myMeetingsTable/utils/checkForFeature';
import { LoaderIcon } from '../../../../../../components/Icons/LoaderIcon';
import { Features } from '../../../../../../types';

export const ReplyEmail: React.FC = () => {
  const [textareaValue, setTextareaValue] = useState('');
  const [prevValue, setPrevValue] = useState('');
  const [textareaFocused, settextareaFocused] = useState(false);

  const user = useAppSelector((state) => state.auth.user);

  const userConnections = useAppSelector((state) => state.auth.userConnections);

  const { analysis, isLoading, isError, success } = useAppSelector(
    (state) => state.threadDetails
  );

  const isDetailedAnalysisLoading = useAppSelector(
    (state) => state.threadDetails.isLoading.threadDetails
  );

  const selectedBranch = useAppSelector(selectedBranchWithContactsInfoSelector);

  const history = useHistory();
  const dispatch = useAppDispatch();

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const { emails } = selectedBranch || {};

  const changeTextareaValue = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextareaValue(e.currentTarget.value);
  };

  useEffect(() => {
    if (success.sendEmail) {
      setTextareaValue('');
      dispatch(resetReplyState());
    }
  }, [success.sendEmail, isError.sendEmail, isLoading.sendEmail, dispatch]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (textareaFocused && !textAreaRef.current?.contains(target)) {
        settextareaFocused(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [textareaFocused]);

  const goToProfilePage = () => {
    history.push(
      `/orgs/${user?.orgId}/users/${user?.userId}/?selectedIntegrations=gmailWrite`
    );
  };

  const sendReply = () => {
    if (textareaValue.length && analysis?.thread?.platformThreadId) {
      setPrevValue(textareaValue);
      dispatch(
        sendEmailReply({
          value: textareaValue,
          platformThreadId: analysis?.thread?.platformThreadId || '',
        })
      );
    }
  };

  const sameValueAfterError =
    textareaValue?.length && prevValue.length && textareaValue === prevValue;

  const attrs = {
    wrapper: {
      className: 'reply-email',
    },
    textarea: {
      wrapper: {
        className: 'reply-email__textarea',
        htmlFor: 'replyEmail',
      },
      control: {
        ref: textAreaRef,
        name: 'replyEmail',
        id: 'replyEmail',
        value: textareaValue,
        placeholder: '    Type your message',
        className: 'reply-email__textarea-control',
        onChange: changeTextareaValue,
        onFocus: () => settextareaFocused(true),
      },
      plus: {
        className: 'reply-email__textarea-plus-icon',
      },
    },

    icons: {
      plus: {
        className: 'reply-email-plus-icon',
      },
      send: {
        className: `${textareaValue?.length ? 'active ' : ''}${
          isLoading.sendEmail || sameValueAfterError ? 'disabled ' : ''
        }reply-email-send-icon`,
        onClick: sendReply,
      },
      loader: {
        wrapper: {
          className: 'reply-email__loader',
        },
        icon: {
          className: 'reply-email__loader-icon',
        },
      },
    },

    notConnected: {
      wrapper: {
        className: 'not-connected reply-email',
      },
      info: {
        wrapper: {
          className: 'reply-email__not-connected',
        },
        icon: {
          className: 'reply-email__not-connected-icon',
        },
        text: {
          className: 'reply-email__not-connected-text',
        },
        link: {
          className: 'reply-email__not-connected-link',
          onClick: goToProfilePage,
        },
      },

      plus: {
        className: 'disabled reply-email-plus-icon',
        style: { marginBottom: 0 },
      },
      send: {
        className: 'disabled reply-email-send-icon',
        style: { marginBottom: 0 },
      },
    },
  };

  const isSendEmailsFeatureEnabled = checkFeatureAvailability(
    user,
    Features.REPLY_FROM_DASHBOARD
  );

  const sendEmailIsConnected = userConnections?.gmailWrite === true;

  const replySection = (
    <div {...attrs.wrapper}>
      <label {...attrs.textarea.wrapper}>
        {textareaFocused || textareaValue.length ? null : (
          <PlusIcon {...attrs.textarea.plus} />
        )}
        <textarea {...attrs.textarea.control} />
      </label>
      {isLoading.sendEmail ? (
        <div {...attrs.icons.loader.wrapper}>
          <LoaderIcon {...attrs.icons.loader.icon} />
        </div>
      ) : (
        <SendIcon {...attrs.icons.send} />
      )}
    </div>
  );

  const renderReplySection = () => {
    if (isDetailedAnalysisLoading || !emails || !isSendEmailsFeatureEnabled) {
      return null;
    }

    if (!sendEmailIsConnected) {
      return (
        <div {...attrs.notConnected.wrapper}>
          <PlusIcon {...attrs.notConnected.plus} />
          <div {...attrs.notConnected.info.wrapper}>
            <NotConnectedIcon />
            <div {...attrs.notConnected.info.text}>
              Your Gmail is not connected. Check your{' '}
            </div>
            <div {...attrs.notConnected.info.link}>integrations.</div>
          </div>
          <SendIcon {...attrs.notConnected.send} />
        </div>
      );
    }

    return replySection;
  };

  return renderReplySection();
};
