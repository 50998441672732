import moment from 'moment';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from '../../../../components';
import { RootState } from '../../../../store/store';
import {
  deleteTeam,
  getTeams,
  setCurrentTeam,
  updateTeamDetails,
} from '../../../../store/teams/teams.actions';
import { Team } from '../../../../store/teams/teams.reducer';
import { Row } from '../../../../types';
import { chevron } from '../../icons';
import { DeleteTeam } from '../../icons/DeleteTeam';
import { EditTeamIcon } from '../../icons/EditTeamIcon';
import { EditTeam } from '../editTeam/EditTeam';
import { EditMembersFromTableModal } from './components/EditMembersFromTableModal';
import { TeamsTable } from './teamsTable/TeamsTable';

type TeamProps = {
  team: Team;
  handleUploadAvatar: (e: ChangeEvent<HTMLInputElement>) => void;
  saveCroppedAvatar: (image: string) => void;
  croppedAvatar: string;
  isUploadAvatarOpen: boolean;
};

const TeamSection: React.FC<TeamProps> = ({
  team,
  croppedAvatar,
  handleUploadAvatar,
  saveCroppedAvatar,
  isUploadAvatarOpen,
}): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { currentTeam } = useSelector((state: RootState) => state.teams);
  const [isUsersShown, setIsUsersShown] = useState(false);
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false);
  const [isTeamDeleted, setIsTeamDeleted] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isAddMembersOpen, setIsAddMembersOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      croppedAvatar.length &&
      currentTeam &&
      team &&
      currentTeam.teamId === team.teamId &&
      user
    ) {
      const { orgId, userId } = user;

      dispatch(
        updateTeamDetails(orgId, userId, team?.teamId, {
          avatar: croppedAvatar,
        })
      );

      saveCroppedAvatar('');
    } // eslint-disable-next-line
  }, [croppedAvatar, currentTeam?.teamId]);

  const setSelectedTeamAsCurrent = () => {
    if (team?.teamId !== currentTeam?.teamId) {
      dispatch(setCurrentTeam(team));
    }
  };

  const changeUsersVisibility = () => {
    setIsUsersShown(!isUsersShown);
  };

  const deleteCurrentTeam = () => {
    if (user) {
      dispatch(deleteTeam(user.orgId, team.teamId));
      setIsTeamDeleted(true);
    }
  };

  const closeDeleteModal = () => {
    if (user) {
      dispatch(getTeams(user?.orgId));
      setIsDeleteModalShown(false);
      setIsTeamDeleted(false);
    } else {
      setIsDeleteModalShown(false);
      setIsTeamDeleted(false);
    }
  };

  const openEditMembers = () => {
    if (team?.members) {
      setIsAddMembersOpen(true);
    }
  };

  const sortMembers = () => {
    const result: Row[] = [];
    const teamLeader = team?.members?.filter(
      (el) => el.teamMemberRole === 'teamLeader'
    );
    const members = team?.members
      .filter((el) => el.teamMemberRole === 'member')
      .sort((a, b) => (a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1));
    return result.concat(teamLeader, members);
  };

  const sortedMembers = sortMembers() || [];

  const attrs = {
    wrapper: {
      className: 'teams__team-wrapper',
      onMouseEnter: setSelectedTeamAsCurrent,
    },

    teamInfo: {
      wrapper: {
        className: 'teams__team-wrapper__info',
      },

      row: {
        wrapper: 'teams__team-wrapper__info',

        label: 'teams__team-wrapper__info__overview-label',

        value: 'teams__team-wrapper__info__overview-value',
      },
    },

    controls: {
      wrapper: {
        className: 'teams__team-wrapper__team-block__third-column',
      },
    },
  };

  const conditionalModalChild = !isTeamDeleted ? (
    <div className="delete-modal-body">
      <span className="delete-modal-body__normal">
        Are you sure you want to
      </span>
      <span className="delete-modal-body__bold"> delete {team.name}</span>?
      <Button variant="common" onClick={deleteCurrentTeam} marginTop="20px">
        Delete
      </Button>
    </div>
  ) : (
    <div className="deleted delete-modal-body">
      <span className="delete-modal-body__bold">{team.name}</span>
      <span className="delete-modal-body__normal"> was</span>
      <span className="delete-modal-body__bold"> deleted</span>
      <Button variant="common" onClick={closeDeleteModal} marginTop="20px">
        Got it
      </Button>
    </div>
  );

  return (
    <>
      <div {...attrs.wrapper}>
        <div className="teams__team-wrapper__team-block">
          <div className="teams__team-wrapper__team-block__second-column">
            <span className="teams__team-wrapper__team-block__second-column__team-name">
              {team.name}
            </span>
            <div className="teams__team-wrapper__team-block__second-column__team-info">
              <div className="teams__team-wrapper__team-block__second-column__team-info__label">
                Created on:{' '}
                <span className="teams__team-wrapper__team-block__second-column__team-info__value">
                  {moment(team.createdAt).format('MMM D, YYYY')}
                </span>
              </div>
              <div className="teams__team-wrapper__team-block__second-column__team-info__label">
                Last updated:{' '}
                <span className="teams__team-wrapper__team-block__second-column__team-info__value">
                  {moment(team.updatedAt).format('MMM D, YYYY')}
                </span>
              </div>
            </div>
          </div>
          <div className="teams__team-wrapper__team-block__third-column">
            <div className="teams__team-wrapper__team-block__third-column__icons">
              {user?.role !== 'dealmaker' ? (
                <>
                  <EditTeamIcon
                    className="teams__team-wrapper__team-block__third-column__icons-edit"
                    onClick={() => {
                      setEditModalOpen(!editModalOpen);
                    }}
                  />
                  <DeleteTeam
                    className="teams__team-wrapper__team-block__third-column__icons-del"
                    onClick={() => {
                      setIsDeleteModalShown(!isDeleteModalShown);
                    }}
                  />
                </>
              ) : null}
            </div>
            <div className="teams__team-wrapper__team-block__third-column__members">
              {team?.members?.length} members{' '}
              <img
                className={
                  isUsersShown
                    ? 'open teams__team-wrapper__team-block__third-column__members__show-hide-users'
                    : 'teams__team-wrapper__team-block__third-column__members__show-hide-users'
                }
                src={chevron}
                onClick={changeUsersVisibility}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="teams__team-wrapper__users-block">
          {team.members && (
            <TeamsTable
              onAddMember={openEditMembers}
              users={sortedMembers}
              isShown={isUsersShown}
            />
          )}
        </div>
      </div>
      <Modal
        title="Delete team"
        onClose={closeDeleteModal}
        open={isDeleteModalShown}
      >
        {conditionalModalChild}
      </Modal>
      <EditTeam
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(!editModalOpen);
        }}
        currentUser={user}
        currentTeam={team}
        croppedAvatar={croppedAvatar}
        handleUploadAvatar={handleUploadAvatar}
        saveCroppedAvatar={saveCroppedAvatar}
        isUploadAvatarOpen={isUploadAvatarOpen}
      />
      <EditMembersFromTableModal
        open={isAddMembersOpen}
        onClose={() => setIsAddMembersOpen(false)}
        currentTeam={currentTeam}
        currentUser={user}
      />
    </>
  );
};

export default TeamSection;
