import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as Chevron } from './chevron-icon.svg';

interface RequiredItemProperties<T> {
  value: T;
  label: string;
}

interface RoleSelectProps<A, T extends RequiredItemProperties<A>> {
  selectedItem?: T;
  items: T[];
  onChange?: (newValue: T) => void;
  onClick?: () => void;
  placeholder?: string;
  icon?: string | null;
  disabled?: boolean;
}

export function RoleSelect<A, T extends RequiredItemProperties<A>>(
  props: RoleSelectProps<A, T>
): JSX.Element {
  const {
    selectedItem,
    items,
    onChange,
    onClick,
    placeholder,
    icon,
    disabled,
  } = props;
  const [currentValue, setCurrentValue] = useState<T | null>(
    selectedItem || null
  );
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);

  const refToCatchClickOutside = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (open && !refToCatchClickOutside.current?.contains(target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  const updateValue = (newValue: T) => () => {
    setCurrentValue(newValue);
    if (onChange) onChange(newValue);
    toggleOpen();
  };

  useEffect(() => {
    setCurrentValue(selectedItem || null);
  }, [selectedItem]);

  const selectedcontainerClassName = () => {
    let currentClassName = 'main-block__selected-item-container';
    if (open) currentClassName = 'open main-block__selected-item-container';
    if (disabled)
      currentClassName = 'disabled main-block__selected-item-container';
    return currentClassName;
  };

  return (
    <div className="main-block" onClick={onClick} ref={refToCatchClickOutside}>
      <div
        className={selectedcontainerClassName()}
        onClick={!disabled ? toggleOpen : () => {}}
      >
        <div className="main-block__selected-item-container__first-block">
          {icon ? (
            <img
              className="main-block__selected-item-container__first-block__optionalIcon"
              src={icon}
              alt="icon"
            />
          ) : null}
          <div className="main-block__selected-item-container__first-block__text">
            {currentValue?.label || placeholder || null}
          </div>
        </div>

        <div
          className={`main-block__chevron-container ${!open ? 'revert' : ''}`}
        >
          <Chevron />
        </div>
      </div>
      <div
        className={
          open
            ? 'main-block__dropped-overflow-container'
            : 'main-block__dropped-overflow-container closed'
        }
      >
        <div className={`main-block__dropped-block ${!open ? 'closed' : ''}`}>
          {items.map((el) => (
            <div
              key={JSON.stringify(el)}
              className={el.label !== 'Remove' ? 'drop-item' : 'drop-item red'}
              onClick={updateValue(el)}
            >
              {el.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
