import { Skeleton } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { getSubscriptionInfo } from '../../../../store/billing/billing.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export const SubscriptionInfo: React.FC = () => {
  const dispatch = useAppDispatch();

  const subscription = useAppSelector((state) => state.billing.subscription);

  const isLoading = useAppSelector(
    (state) => state.billing.isLoading.subscription
  );

  const nextInvoiceDate = subscription?.nextInvoiceDate
    ? `Renews on ${DateTime.fromISO(subscription.nextInvoiceDate).toFormat(
        'MMM d, y'
      )}`
    : 'N/A';
  const name = subscription?.planName || 'N/A';
  const numberOfSeats =
    typeof subscription?.numberOfSeats === 'number'
      ? subscription.numberOfSeats
      : 'N/A';
  const price =
    typeof subscription?.numberOfSeats === 'number'
      ? `$${subscription.price}`
      : 'N/A';

  const attrs = {
    container: {
      className: 'sound-wave-subscription-info',
    },
    item: {
      className: 'sound-wave-subscription-info__item',
    },
    itemTitle: {
      className: 'sound-wave-subscription-info__item-title',
    },
    itemValue: {
      className: 'sound-wave-subscription-info__item-value',
    },
    valueSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: 100,
      },
      title: {
        style: {
          width: 100,
          height: 28,
          margin: 0,
          borderRadius: 14,
        },
      },
    },
  };

  useEffect(() => {
    const request = dispatch(getSubscriptionInfo());

    return () => {
      request.abort();
    };
  }, [dispatch]);

  return (
    <div {...attrs.container}>
      <div {...attrs.item}>
        <span {...attrs.itemTitle}>Next invoice issue date</span>
        {isLoading ? (
          <Skeleton {...attrs.valueSkeleton} />
        ) : (
          <span {...attrs.itemValue}>{nextInvoiceDate}</span>
        )}
      </div>
      <div {...attrs.item}>
        <span {...attrs.itemTitle}>Plan</span>
        {isLoading ? (
          <Skeleton {...attrs.valueSkeleton} />
        ) : (
          <span {...attrs.itemValue}>{name}</span>
        )}
      </div>
      <div {...attrs.item}>
        <span {...attrs.itemTitle}>Seats</span>
        {isLoading ? (
          <Skeleton {...attrs.valueSkeleton} />
        ) : (
          <span {...attrs.itemValue}>{numberOfSeats}</span>
        )}
      </div>
      <div {...attrs.item}>
        <span {...attrs.itemTitle}>Total Amount</span>
        {isLoading ? (
          <Skeleton {...attrs.valueSkeleton} />
        ) : (
          <span {...attrs.itemValue}>{price}</span>
        )}
      </div>
    </div>
  );
};
