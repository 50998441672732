import React from 'react';
import {
  ConfirmationModal,
  Modal,
  Form,
  FormField,
} from '../../../../components/SoundWave';
import { useManageSubscriptionModal } from './hooks/useManageSubscriptionModal';

export interface ManageSubscriptionModalProps {
  isOpened: boolean;
  onClose: () => void;
}

export const ManageSubscriptionModal: React.FC<ManageSubscriptionModalProps> = ({
  isOpened,
  onClose,
}) => {
  const {
    modalProps,
    formProps,
    fieldProps,
    confirmationModalProps,
    isNoteFieldHidden,
  } = useManageSubscriptionModal({
    isOpened,
    onClose,
  });

  const attrs = {
    title: {
      className: 'billing__modal-title',
    },
    fieldset: {
      className: 'sound-wave-form__fieldset',
    },
  };

  return (
    <>
      <Modal {...modalProps}>
        <div {...attrs.title}>
          Which subscription option would you like to manage?
        </div>
        <Form {...formProps}>
          <fieldset {...attrs.fieldset}>
            <FormField {...fieldProps.reason} />
            {isNoteFieldHidden ? null : <FormField {...fieldProps.text} />}
          </fieldset>
        </Form>
      </Modal>
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
};
