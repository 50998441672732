import React from 'react';

export const PreviousSpeakerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M13.3333 7.99992C13.3333 5.04891 10.9633 2.66659 8.05197 2.66659C6.28437 2.66659 4.71768 3.54309 3.7573 4.8951C3.6116 5.10022 3.36281 5.20584 3.11404 5.16819C2.86526 5.13054 2.65888 4.95603 2.5804 4.71698L2.0332 3.05021C1.91835 2.70039 2.10884 2.3237 2.45866 2.20886C2.80848 2.09401 3.18516 2.2845 3.30001 2.63432L3.4799 3.18227C4.66657 2.03813 6.27702 1.33325 8.05197 1.33325C11.7106 1.33325 14.6666 4.32351 14.6666 7.99992C14.6666 11.6763 11.7106 14.6666 8.05197 14.6666C5.83136 14.6666 3.86823 13.5633 2.6703 11.8769C2.45708 11.5767 2.52756 11.1605 2.82773 10.9473C3.1279 10.7341 3.54408 10.8046 3.7573 11.1047C4.71768 12.4568 6.28438 13.3333 8.05197 13.3333C10.9633 13.3333 13.3333 10.9509 13.3333 7.99992Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99994 8.66659C9.10451 8.66659 9.99994 7.77116 9.99994 6.66659C9.99994 5.56202 9.10451 4.66659 7.99994 4.66659C6.89537 4.66659 5.99994 5.56202 5.99994 6.66659C5.99994 7.77116 6.89537 8.66659 7.99994 8.66659ZM7.99994 7.33325C8.36813 7.33325 8.6666 7.03477 8.6666 6.66659C8.6666 6.2984 8.36813 5.99992 7.99994 5.99992C7.63175 5.99992 7.33327 6.2984 7.33327 6.66659C7.33327 7.03477 7.63175 7.33325 7.99994 7.33325Z"
      fill="white"
    />
    <path
      d="M5.52853 11.138C5.26818 10.8776 5.26818 10.4555 5.52853 10.1952C6.89345 8.83026 9.10642 8.83026 10.4713 10.1952C10.7317 10.4555 10.7317 10.8776 10.4713 11.138C10.211 11.3983 9.78888 11.3983 9.52853 11.138C8.68431 10.2938 7.31556 10.2938 6.47134 11.138C6.21099 11.3983 5.78888 11.3983 5.52853 11.138Z"
      fill="white"
    />
  </svg>
);
