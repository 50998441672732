import { UserConnection } from '../../../../../types';

export const getModalText = (connectionId: UserConnection): string => {
  switch (connectionId) {
    case UserConnection.GmailRead:
      return 'Disconnecting the Analyze your emails will also disable the “Send Emails” and “Calendar” features, and log you out of Substrata. Are you sure you want to continue?';
    case UserConnection.GmailWrite:
      return 'Disconnecting the Send Emails will also disable the “Analyze your emails” and “Calendar” features, and log you out of Substrata. Are you sure you want to continue?';
    case UserConnection.Calendar:
      return 'Disconnecting the Calendar will also disable the “Analyze your emails” and “Send Emails” features, and log you out of Substrata. Are you sure you want to continue?';
    default:
      return '';
  }
};
