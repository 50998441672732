import React from 'react';

type Props = {
  title: string;
};

const RelationsNoData: React.FC<Props> = (props: Props) => {
  const { title } = props;

  return (
    <div className="relations">
      <span className="relations__title">{title}</span>
      <div className="relations__avatarsblock">
        <>
          <div className="relations__avatarsblock-arrows__empty" />
          <div>
            <div className="relations__avatarsblock-img1" />
            <div className="relations__avatarsblock-img2" />
            <div className="relations__avatarsblock-img3" />
          </div>
          <div className="relations__avatarsblock-arrows__empty" />
        </>
      </div>
      <div className="relations__name-empty-block">
        <div className="relations__name-empty-block__empty-el" />
      </div>
    </div>
  );
};

export default RelationsNoData;
