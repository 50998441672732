import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { User } from '../../types';
import { QActivityChart } from './statsComponents/charts/qActivity/QActivityChart';
import { getChartsData } from '../../store/charts/charts.actions';
import { AvgResponseTime } from './statsComponents/charts/avgResponseTime/AvgResponseTime';
import { SentimentReceived } from './statsComponents/charts/sentimentReceived/SentimentReceived';
import { SellingZone } from './statsComponents/charts/sellingZone/SellingZone';
import { AvgNumOfPartics } from './statsComponents/charts/avgNumberOfPartics/AvgNumOfPartics';
import { AvgEmailLength } from './statsComponents/charts/avgEmailLength/AvgEmailLength';
import { TopTenDomains } from './statsComponents/charts/topTenDomains/TopTenDomains';
import { UpperHandChart } from './statsComponents/charts/upperHand/UpperHand';
import { EstimatedTendsChart } from './statsComponents/charts/estimatedTends/EstimatedTends';
import { MostCompetent } from './statsComponents/charts/mostCompetent/MostCompetent';
import { LeastCompetent } from './statsComponents/charts/leastCompetent/LeastCompetent';
import { OutgoingActivityHeatMap } from './statsComponents/charts/heatMaps/OutgoingEmailActivity';
import { IncomingActivityHeatMap } from './statsComponents/charts/heatMaps/IncomingEmailActivity';
import { TalkTimeRatio } from './statsComponents/charts/talkTimeRatio/TalkTimeRatio';
import { MeetingCountChart } from './statsComponents/charts/meetingCount/MeetingCountChart';
import { MeetingDurationChart } from './statsComponents/charts/meetingDuration/MeetingDurationChart';
import { getLastNinetyDaysRangeOption } from '../../utils/getDateRangeDropdownOptions';
import { DateRangeDropdown } from './statsComponents/DateRangeDropdown/DateRangeDropdown';
import { useTrackPageView } from '../../hooks';

export const Stats: React.FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    uhPossessions,
    uhPossessionsStatus,
    estTendencies,
    estTendenciesStatus,
    qActivity,
    qActivityStatus,
    sentiment,
    sentimentStatus,
    responseTime,
    responseTimeStatus,
    participans,
    participansStatus,
    emailLength,
    emailLengthStatus,
    topDomains,
    topDomainsStatus,
    sellingZone,
    sellingZoneStatus,
    mostCompetent,
    mostCompetentStatus,
    leastCompetent,
    leastCompetentStatus,
    outgoingEmailActivity,
    outgoingEmailActivityStatus,
    incomingEmailActivity,
    incomingEmailActivityStatus,
    talkTimeRatio,
    talkTimeRatioStatus,
    meetingCount,
    meetingCountStatus,
    meetingDuration,
    meetingDurationStatus,
  } = useSelector((state: RootState) => state.charts);
  const { orgId, userId } = user as User;

  const dispatch = useDispatch();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [selectedDateRange, setSelectedDateRange] = useState(
    getLastNinetyDaysRangeOption
  );

  useEffect(() => {
    const { fromDate, toDate } = selectedDateRange;
    dispatch(getChartsData(orgId, userId, fromDate, toDate));
  }, [dispatch, orgId, selectedDateRange, userId]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useTrackPageView();

  const defineRequestedPeriodInDays = () => {
    let lastDays = null;

    if (participans?.fromDate && participans?.toDate) {
      const fromDate = new Date(participans.fromDate);

      const toDate = new Date(participans.toDate);

      const diffInMs = toDate.getTime() - fromDate.getTime();

      lastDays = diffInMs / 1000 / 60 / 60 / 24;
    }

    return lastDays;
  };

  const requestedPeriodInDays = defineRequestedPeriodInDays();

  const isTotalNoData =
    uhPossessionsStatus?.noData &&
    qActivityStatus?.noData &&
    sentimentStatus?.noData &&
    responseTimeStatus?.noData;

  const attrs = {
    wrapper: { className: 'stats' },
    header: { className: 'stats__header' },
    title: { className: 'stats__title' },
    content: { className: 'stats__content' },
    dateRangeDropdown: {
      selectedDateRange,
      setSelectedDateRange,
    },
    pageHeader: { user, noData: isTotalNoData },
    UhPossessions: {
      upperHandData: uhPossessions,
      isLoading: uhPossessionsStatus?.isLoading,
      noData: uhPossessionsStatus?.noData,
      error: uhPossessionsStatus?.error,
      // noData: false,
      // error: false,
      lastDays: qActivity?.data?.length,
    },
    EstimatedTends: {
      data: estTendencies,
      // data: { competence: -0.2, empathy: 0.2, responsiveness: 0.6 },
      isLoading: estTendenciesStatus.isLoading,
      noData: estTendenciesStatus?.noData,
      error: estTendenciesStatus.error,
      lastDays: qActivity?.data?.length,
    },
    QActivityChart: {
      dataQ: qActivity?.data,
      historicalDataQ: qActivity?.historicalData,
      isLoading: qActivityStatus.isLoading,
      noData: qActivityStatus?.noData,
      error: qActivityStatus.error,
    },
    TalkTimeRatioChart: {
      talkTimeRatioData: {
        me: talkTimeRatio?.userTTR || 0,
        prospect: talkTimeRatio?.prospectsTTR || 0,
        myTeam: talkTimeRatio?.teamTTR || 0,
        unassigned: talkTimeRatio?.unassignedTTR || 0,
        silence: talkTimeRatio?.silenceRatio || 0,
        totalMeetings: talkTimeRatio?.totalMeetings || 0,
        previousPeriodUserTTR: talkTimeRatio?.previousPeriodUserTTR || 0,
      },
      isLoading: talkTimeRatioStatus.isLoading,
      noData: talkTimeRatioStatus.noData,
      error: talkTimeRatioStatus.error,
      lastDays: requestedPeriodInDays,
    },
    MeetingCountChart: {
      meetingCountData: meetingCount,
      isLoading: meetingCountStatus.isLoading,
      noData: meetingCountStatus?.noData,
      error: meetingCountStatus.error,
      lastDays: requestedPeriodInDays,
    },
    MeetingDurationChart: {
      meetingDurationData: meetingDuration,
      // meetingDurationData: {
      //   belowTeamAverageRatio: 0,
      //   teamAverageDurationMin: 6,
      //   userAverageDurationMin: 1,
      // },

      lastDays: requestedPeriodInDays,
      isLoading: meetingDurationStatus.isLoading,
      noData: meetingDurationStatus?.noData,
      error: meetingDurationStatus.error,
    },
    SentimentReceived: {
      data: sentiment,
      isLoading: sentimentStatus.isLoading,
      noData: sentimentStatus?.noData,
      error: sentimentStatus.error,
    },
    AvgResponseTime: {
      responseTimeData: responseTime?.data,
      responseTimeHistoricalData: responseTime?.historicalData,
      isLoading: responseTimeStatus.isLoading,
      noData: responseTimeStatus?.noData,
      error: responseTimeStatus.error,
    },
    Participants: {
      participantsData: participans?.data,
      // participantsData: { userSide: 8, buyerSide: 5 },
      lastDays: defineRequestedPeriodInDays(),
      isLoading: participansStatus.isLoading,
      noData: participansStatus?.noData,
      error: participansStatus.error,
    },
    EmailsLenght: {
      emailLengthData: emailLength?.data,
      isLoading: emailLengthStatus.isLoading,
      noData: emailLengthStatus?.noData,
      error: emailLengthStatus.error,
    },
    TopDomains: {
      activeDomainsdata: topDomains?.data,
      isLoading: topDomainsStatus.isLoading,
      noData: topDomainsStatus?.noData,
      error: topDomainsStatus.error,
    },
    SellingZone: {
      sellZoneData: sellingZone?.data,
      isLoading: sellingZoneStatus.isLoading,
      noData: sellingZoneStatus?.noData,
      error: sellingZoneStatus.error,
      lastDays: qActivity?.data?.length,
    },
    MostCompetent: {
      data: mostCompetent,
      isLoading: mostCompetentStatus.isLoading,
      noData: mostCompetentStatus?.noData,
      error: mostCompetentStatus.error,
      lastDays: qActivity?.data?.length,
    },
    LeastCompetent: {
      data: leastCompetent,
      isLoading: leastCompetentStatus.isLoading,
      noData: leastCompetentStatus?.noData,
      error: leastCompetentStatus.error,
      lastDays: qActivity?.data?.length,
    },
    IncomingActivity: {
      data: incomingEmailActivity,
      isLoading: incomingEmailActivityStatus.isLoading,
      noData: incomingEmailActivityStatus?.noData,
      error: incomingEmailActivityStatus.error,
      lastDays: qActivity?.data?.length,
    },
    OutgoingActivity: {
      data: outgoingEmailActivity,
      isLoading: outgoingEmailActivityStatus.isLoading,
      noData: outgoingEmailActivityStatus?.noData,
      error: outgoingEmailActivityStatus.error,
      lastDays: qActivity?.data?.length,
    },
    comingSoong: {
      outgoing: { isLoading: responseTimeStatus.isLoading },
      incoming: { isLoading: responseTimeStatus.isLoading },
    },
  };

  const conditionalPageBody =
    windowWidth < 1660 ? (
      <>
        <UpperHandChart {...attrs.UhPossessions} />
        <EstimatedTendsChart {...attrs.EstimatedTends} />
        <QActivityChart {...attrs.QActivityChart} />
        <TalkTimeRatio {...attrs.TalkTimeRatioChart} />
        <MeetingCountChart {...attrs.MeetingCountChart} />
        <MeetingDurationChart {...attrs.MeetingDurationChart} />
        <AvgResponseTime {...attrs.AvgResponseTime} />
        <SellingZone {...attrs.SellingZone} />
        <SentimentReceived {...attrs.SentimentReceived} />
        <AvgEmailLength {...attrs.EmailsLenght} />
        <AvgNumOfPartics {...attrs.Participants} />
        <TopTenDomains {...attrs.TopDomains} />
        <MostCompetent {...attrs.MostCompetent} />
        <LeastCompetent {...attrs.LeastCompetent} />
        <IncomingActivityHeatMap {...attrs.IncomingActivity} />
        <OutgoingActivityHeatMap {...attrs.OutgoingActivity} />
      </>
    ) : (
      <>
        <UpperHandChart {...attrs.UhPossessions} />
        <EstimatedTendsChart {...attrs.EstimatedTends} />
        <QActivityChart {...attrs.QActivityChart} />
        <TalkTimeRatio {...attrs.TalkTimeRatioChart} />
        <MeetingCountChart {...attrs.MeetingCountChart} />
        <MeetingDurationChart {...attrs.MeetingDurationChart} />
        <SentimentReceived {...attrs.SentimentReceived} />
        <AvgNumOfPartics {...attrs.Participants} />
        <TopTenDomains {...attrs.TopDomains} />
        <SellingZone {...attrs.SellingZone} />
        <AvgResponseTime {...attrs.AvgResponseTime} />
        <MostCompetent {...attrs.MostCompetent} />
        <LeastCompetent {...attrs.LeastCompetent} />
        <AvgEmailLength {...attrs.EmailsLenght} />
        <IncomingActivityHeatMap {...attrs.IncomingActivity} />
        <OutgoingActivityHeatMap {...attrs.OutgoingActivity} />
      </>
    );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header}>
        <span {...attrs.title}>Performance Q</span>
        <DateRangeDropdown {...attrs.dateRangeDropdown} />
      </div>
      <div {...attrs.content}>{conditionalPageBody}</div>
    </div>
  );
};
