export const getRelativeRangeValues = <
  T extends Record<string, number | null | undefined>
>(
  valuesMap: T
): Record<string, number | null> => {
  const values = Object.values(valuesMap).filter(
    (value) => typeof value === 'number'
  ) as number[];

  const maxValue = Math.max(...values);

  return Object.entries(valuesMap).reduce<Record<string, number | null>>(
    (acc, [key, value]) => {
      acc[key] = typeof value === 'number' ? (value / maxValue) * 100 : null;
      return acc;
    },
    {}
  );
};
