import React from 'react';
import { PreviousSpeakerIcon } from '../Icons/PreviousSpeakerIcon';
import { RewindIcon } from '../Icons/RewindIcon';
import { PauseIcon } from '../Icons/PauseIcon';
import { PlayIcon } from '../Icons/PlayIcon';
import { ForwardIcon } from '../Icons/ForwardIcon';
import { NextSpeakerIcon } from '../Icons/NextSpeakerIcon';
import {
  ControlButton,
  ControlButtonSize,
} from '../ControlButton/ControlButton';

export interface FloatingControlsProps {
  isPlaying: boolean;
  isPreviousSpeakerButtonDisabled: boolean;
  isNextSpeakerButtonDisabled: boolean;
  onPlayPause: (isPlaying: boolean) => void;
  onRewind: () => void;
  onFastForward: () => void;
  onNextSpeaker: () => void;
  onPreviousSpeaker: () => void;
}

export const FloatingControls: React.FC<FloatingControlsProps> = ({
  isPlaying,
  isPreviousSpeakerButtonDisabled,
  isNextSpeakerButtonDisabled,
  onPlayPause,
  onRewind,
  onFastForward,
  onNextSpeaker,
  onPreviousSpeaker,
}) => {
  const attrs = {
    container: {
      className: 'player-floating-controls',
    },
    previousSpeakerButton: {
      label: 'Previous speaker',
      disabled: isPreviousSpeakerButtonDisabled,
      onClick: onPreviousSpeaker,
    },
    rewindButton: {
      label: '-15 seconds',
      onClick: onRewind,
    },
    playPauseButton: {
      label: isPlaying ? 'Pause' : 'Play',
      size: ControlButtonSize.BIG,
      onClick: () => {
        onPlayPause(!isPlaying);
      },
    },
    playIcon: {
      className: 'player-floating-controls__play-icon',
    },
    fastForwardButton: {
      label: '+15 seconds',
      onClick: onFastForward,
    },
    nextSpeakerButton: {
      label: 'Next speaker',
      disabled: isNextSpeakerButtonDisabled,
      onClick: onNextSpeaker,
    },
  };

  return (
    <div {...attrs.container}>
      <ControlButton {...attrs.previousSpeakerButton}>
        <PreviousSpeakerIcon />
      </ControlButton>
      <ControlButton {...attrs.rewindButton}>
        <RewindIcon />
      </ControlButton>
      <ControlButton {...attrs.playPauseButton}>
        {isPlaying ? <PauseIcon /> : <PlayIcon {...attrs.playIcon} />}
      </ControlButton>
      <ControlButton {...attrs.fastForwardButton}>
        <ForwardIcon />
      </ControlButton>
      <ControlButton {...attrs.nextSpeakerButton}>
        <NextSpeakerIcon />
      </ControlButton>
    </div>
  );
};
