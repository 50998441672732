import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EditUserName } from '../editName/EditUserName';
import { EditUserJobTitle } from '../editJobTitle/EditUserJobTitle';
import { EditUserTelNumber } from '../editPhoneNumber/EditUserTelNumber';
import { EditTimeZone } from '../editTimeZone/EditTimeZone';
import { email, person, phone, timezone, work } from '../../icons';
import {
  Avatar,
  ImageCropper,
  Modal,
  useFileUpload,
} from '../../../../components';
import { RootState } from '../../../../store/store';
import { updateUser } from '../../../../store/users/users.actions';
import Label from '../../../../components/Label/Label';
import { getUserLocalTimezone } from '../../../../store/teamQ/teamQ.utils';

export const MyProfile: React.FC = (): JSX.Element => {
  const {
    handleUpload,
    file,
    removeFile,
    croppedImage,
    saveCroppedImage,
  } = useFileUpload();

  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [isOpenRename, setIsOpenRename] = useState(false);
  const [isOpenEditJob, setIsOpenEditJob] = useState(false);
  const [isOpenEditPhone, setIsOpenEditPhone] = useState(false);
  const [isOpenEditTimeZone, setIsOpenEditTimeZone] = useState(false);

  useEffect(() => {
    if (croppedImage)
      dispatch(
        updateUser(
          { newAvatar: croppedImage },
          user?.orgId || '',
          user?.userId || ''
        )
      );
  }, [croppedImage, dispatch, user?.userId, user?.orgId]);

  const userCurrenttimeZone = getUserLocalTimezone();

  const conditionToShowGMT: string = user?.timezone?.includes('GMT')
    ? user.timezone
    : userCurrenttimeZone;

  const attrs = {
    wrapper: {
      className: 'my-profile',
    },

    avatarSection: {
      wrapper: {
        className: 'my-profile__avatar-section',
      },

      header: {
        wrapper: {
          className: 'my-profile__avatar-section__header',
        },

        title: {
          className: 'my-profile__avatar-section__header-title',
        },

        roleLabel: {
          className: 'my-profile__avatar-section__header-role',
        },
      },

      avatar: {
        wrapper: {
          className: 'my-profile__avatar-section__avatar',
        },

        Avatar: {
          user,
          image: user?.avatar,
          className: 'my-profile__avatar-section__avatar-image',
          onUpload: handleUpload,
          hasUpload: true,
        },
      },
    },

    editModals: {
      avatar: {
        wrapper: {
          open: !!file,
          onClose: removeFile,
          title: 'Upload a photo',
          height: '570px',
        },

        cropper: {
          onSave: saveCroppedImage,
          onCancel: removeFile,
          image: file || '',
        },
      },

      name: {
        open: isOpenRename,
        onClose: () => setIsOpenRename(false),
      },

      title: {
        open: isOpenEditJob,
        onClose: () => setIsOpenEditJob(false),
      },

      number: {
        open: isOpenEditPhone,
        onClose: () => setIsOpenEditPhone(false),
      },

      timeZone: {
        open: isOpenEditTimeZone,
        onClose: () => setIsOpenEditTimeZone(false),
      },
    },

    body: {
      wrapper: {
        className: 'my-profile__body',
      },

      rows: {
        Name: {
          title: 'Name',
          value: `${user?.firstName} ${user?.lastName}`,
          onClick: () => setIsOpenRename(true),
          image: person,
        },

        Role: {
          title: 'Role',
          value: user?.title,
          onClick: () => setIsOpenEditJob(true),
          image: work,
        },

        TimeZone: {
          title: 'Time zone',
          value: conditionToShowGMT,
          onClick: () => setIsOpenEditTimeZone(true),
          image: timezone,
        },

        Phone: {
          title: 'Phone',
          value: user?.phoneNumber,
          onClick: () => setIsOpenEditPhone(true),
          image: phone,
        },

        Email: {
          title: 'Email',
          value: user?.email,
          // value: 'user?.email addortjstrrsjotivhgrs;tojsr',
          disabled: true,
          image: email,
          hasLock: true,
        },
      },
    },
  };

  const editModals = (
    <>
      <Modal {...attrs.editModals.avatar.wrapper}>
        <ImageCropper {...attrs.editModals.avatar.cropper} />
      </Modal>
      <EditUserName {...attrs.editModals.name} />
      <EditUserJobTitle {...attrs.editModals.title} />
      <EditUserTelNumber {...attrs.editModals.number} />
      <EditTimeZone {...attrs.editModals.timeZone} />
    </>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.avatarSection.wrapper}>
        <div {...attrs.avatarSection.header.wrapper}>
          <div {...attrs.avatarSection.header.title}>My Profile</div>
          <div {...attrs.avatarSection.header.roleLabel}>{user?.role}</div>
        </div>
        <div {...attrs.avatarSection.avatar.wrapper}>
          <Avatar {...attrs.avatarSection.avatar.Avatar} />
        </div>
      </div>
      <div {...attrs.body.wrapper}>
        <Label {...attrs.body.rows.Name} />
        <Label {...attrs.body.rows.Role} />
        <Label {...attrs.body.rows.Email} />
        <Label {...attrs.body.rows.Phone} />
        <Label {...attrs.body.rows.TimeZone} />
      </div>
      {editModals}
    </div>
  );
};
