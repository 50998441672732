import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  duration: number;
  globalLoading: boolean;
  children?: JSX.Element | string;
}

const CircleBarSkeleton: React.FC<Props> = (props: Props) => {
  const { duration, globalLoading, children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [globalLoading]); // eslint-disable-line
  return (
    <>
      {isLoading ? (
        <div className="personalQ__secondColumn-overallcompetence-skeleton">
          <div className="personalQ__secondColumn-overallcompetence-skeleton-title">
            <Skeleton
              loading={isLoading}
              active
              avatar={false}
              paragraph={false}
              title={{ style: { width: 92, height: 16, borderRadius: 30 } }}
            />
          </div>
          <div className="personalQ__secondColumn-overallcompetence-skeleton-circle">
            <Skeleton
              loading={isLoading}
              active
              avatar={{ size: 143 }}
              paragraph={false}
              title={false}
            />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default CircleBarSkeleton;
