import React from 'react';

export const MicrosoftTeamsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_5398_109394)">
      <path
        d="M15.344 7.5H21.0266C21.5635 7.5 21.9987 7.92532 21.9987 8.45V13.5085C21.9987 15.4368 20.3992 17 18.426 17H18.4091C16.436 17.0003 14.8362 15.4373 14.8359 13.509C14.8359 13.5088 14.8359 13.5087 14.8359 13.5085V7.9965C14.8359 7.72229 15.0634 7.5 15.344 7.5Z"
        fill="#5059C9"
      />
      <path
        d="M19.1851 6.5C20.4567 6.5 21.4875 5.49264 21.4875 4.25C21.4875 3.00736 20.4567 2 19.1851 2C17.9136 2 16.8828 3.00736 16.8828 4.25C16.8828 5.49264 17.9136 6.5 19.1851 6.5Z"
        fill="#5059C9"
      />
      <path
        d="M12.0248 6.50001C13.8615 6.50001 15.3504 5.04493 15.3504 3.25C15.3504 1.45508 13.8615 0 12.0248 0C10.1881 0 8.69922 1.45508 8.69922 3.25C8.69922 5.04493 10.1881 6.50001 12.0248 6.50001Z"
        fill="#7B83EB"
      />
      <path
        d="M16.4563 7.5H7.07612C6.54564 7.51283 6.126 7.94306 6.13831 8.46151V14.231C6.06423 17.3421 8.58284 19.9239 11.7662 20C14.9496 19.9239 17.4682 17.3421 17.3941 14.231V8.46151C17.4064 7.94306 16.9868 7.51283 16.4563 7.5Z"
        fill="#7B83EB"
      />
      <path
        opacity="0.1"
        d="M12.2804 7.5V15.585C12.2779 15.9558 12.048 16.2888 11.6972 16.43C11.5855 16.4762 11.4654 16.5 11.3442 16.5H6.59113C6.52462 16.335 6.46322 16.17 6.41205 16C6.23296 15.4263 6.14158 14.8298 6.14089 14.23V8.46C6.12859 7.94239 6.54754 7.51283 7.07717 7.5H12.2804Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7688 7.5V16.085C11.7688 16.2035 11.7444 16.3208 11.6972 16.43C11.5527 16.7728 11.2119 16.9975 10.8325 17H6.8316C6.74462 16.835 6.66276 16.67 6.59113 16.5C6.5195 16.33 6.46322 16.17 6.41205 16C6.23296 15.4263 6.14158 14.8298 6.14089 14.23V8.46C6.12859 7.94239 6.54754 7.51283 7.07717 7.5H11.7688Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7688 7.5V15.085C11.7649 15.5888 11.348 15.9962 10.8325 16H6.41206C6.23296 15.4263 6.14158 14.8298 6.14089 14.23V8.46C6.12859 7.94239 6.54754 7.51283 7.07717 7.5H11.7688Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.2572 7.5V15.085C11.2533 15.5888 10.8364 15.9962 10.3209 16H6.41205C6.23296 15.4263 6.14158 14.8298 6.14089 14.23V8.46C6.12859 7.94239 6.54754 7.51283 7.07717 7.5H11.2572Z"
        fill="black"
      />
      <path
        opacity="0.1"
        d="M12.2784 4.91499V6.48999C12.1914 6.49499 12.1095 6.49999 12.0226 6.49999C11.9356 6.49999 11.8537 6.495 11.7667 6.48999C11.594 6.47879 11.4228 6.45201 11.2551 6.40999C10.2191 6.17022 9.36312 5.46013 8.95279 4.5C8.88218 4.33876 8.82737 4.17134 8.78906 4H11.3421C11.8584 4.00191 12.2764 4.41044 12.2784 4.91499Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7671 5.41499V6.48999C11.5944 6.47879 11.4231 6.45202 11.2555 6.41C10.2194 6.17022 9.36346 5.46014 8.95312 4.5H10.8308C11.3471 4.50191 11.7651 4.91045 11.7671 5.41499Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.7671 5.41499V6.48999C11.5944 6.47879 11.4231 6.45202 11.2555 6.41C10.2194 6.17022 9.36346 5.46014 8.95312 4.5H10.8308C11.3471 4.50191 11.7651 4.91045 11.7671 5.41499Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.2555 5.415V6.41C10.2194 6.17022 9.36346 5.46014 8.95312 4.5H10.3192C10.8355 4.50192 11.2535 4.91046 11.2555 5.415Z"
        fill="black"
      />
      <path
        d="M0.937809 4.5H10.318C10.8359 4.5 11.2558 4.91033 11.2558 5.4165V14.5835C11.2558 15.0897 10.8359 15.5 10.318 15.5H0.937809C0.419867 15.5 0 15.0897 0 14.5835V5.4165C0 4.91033 0.419877 4.5 0.937809 4.5Z"
        fill="url(#paint0_linear_5398_109394)"
      />
      <path
        d="M8.09634 7.98948H6.22122V12.9795H5.02658V7.98948H3.16016V7.02148H8.09634V7.98948Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5398_109394"
        x1="1.95536"
        y1="3.78386"
        x2="9.05009"
        y2="16.3574"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A62C3" />
        <stop offset="0.5" stopColor="#4D55BD" />
        <stop offset="1" stopColor="#3940AB" />
      </linearGradient>
      <clipPath id="clip0_5398_109394">
        <rect width="22" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
