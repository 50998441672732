import { DateTime } from 'luxon';

export interface DateRangeOption {
  label: string;
  fromDate: string;
  toDate: string;
}

export const getLastSevenDaysRangeOption = (): DateRangeOption => ({
  label: 'Last 7 days',
  fromDate: DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd'),
  toDate: DateTime.now().toFormat('yyyy-MM-dd'),
});

export const getLastThirtyDaysRangeOption = (): DateRangeOption => ({
  label: 'Last 30 days',
  fromDate: DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd'),
  toDate: DateTime.now().toFormat('yyyy-MM-dd'),
});

export const getLastNinetyDaysRangeOption = (): DateRangeOption => ({
  label: 'Last 90 days',
  fromDate: DateTime.now().minus({ days: 90 }).toFormat('yyyy-MM-dd'),
  toDate: DateTime.now().toFormat('yyyy-MM-dd'),
});

export const getDateRangeOptions = (): DateRangeOption[] => [
  getLastSevenDaysRangeOption(),
  getLastThirtyDaysRangeOption(),
  getLastNinetyDaysRangeOption(),
];
