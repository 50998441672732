import React from 'react';

export interface ToggleProps {
  isActive: boolean;
  onToggle: (isActive: boolean) => void;
}

export const Toggle: React.FC<ToggleProps> = ({ isActive, onToggle }) => {
  const attrs = {
    container: {
      className: `toggle ${isActive ? 'toggle-active' : 'toggle-inactive'}`,
    },
    input: {
      type: 'checkbox',
      checked: isActive,
      readOnly: true,
      className: 'toggle__control',
      onClick: () => onToggle(isActive),
    },
    toggle: {
      className: 'toggle__toggle',
    },
    label: {
      className: `toggle__label ${
        isActive ? 'toggle__label-active' : 'toggle__label-inactive'
      }`,
    },
  };

  return (
    <label {...attrs.container}>
      <input {...attrs.input} />
      <div {...attrs.toggle} />
      <span {...attrs.label}>{isActive ? 'ON' : 'OFF'}</span>
    </label>
  );
};
