import React from 'react';

export const ChevronRightIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M6.14645 3.14645C5.95118 3.34171 5.95118 3.65829 6.14645 3.85355L10.2929 8L6.14645 12.1464C5.95118 12.3417 5.95118 12.6583 6.14645 12.8536C6.34171 13.0488 6.65829 13.0488 6.85355 12.8536L11.3536 8.35355C11.5488 8.15829 11.5488 7.84171 11.3536 7.64645L6.85355 3.14645C6.65829 2.95118 6.34171 2.95118 6.14645 3.14645Z"
      fill="#18222F"
    />
  </svg>
);
