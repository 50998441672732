import { Tooltip } from 'antd';
import { Duration } from 'luxon';
import React from 'react';
import { formatNavigationCellValue } from '../../../../utils/teamq.utils';
import { TeamQTendencyBar } from '../graphTendencyBar/TeamQTendencyBar';

export type RowBarVariant = 'basic' | 'sellingZone' | 'tendency';

type SellingZoneValues = {
  below: number;
  in: number;
  above: number;
};

interface Props {
  variant?: RowBarVariant;
  name: string;
  singleValue?: number;
  maxValue?: number;
  sellingZoneValues?: SellingZoneValues;
  singleBarTooltip?: string;
  sellZoneTooltips?: { below: string; in: string; above: string };
  invisible?: boolean;
  wordValue?: boolean;
  percentageValue?: boolean;
  isThreadDuration?: boolean;
  isUpperHand?: boolean;
  noDataFlag?: boolean;
  isMeeting?: boolean;
  isDuration?: boolean;
}

export const TeamQGraphRow: React.FC<Props> = ({
  variant = 'basic',
  name,
  singleValue,
  maxValue = 100,
  sellingZoneValues,
  singleBarTooltip = '',
  sellZoneTooltips = {
    below: '',
    in: '',
    above: '',
  },
  invisible = false,
  wordValue = false,
  percentageValue = false,
  isThreadDuration = false,
  isUpperHand = false,
  noDataFlag = false,
  isMeeting = false,
  isDuration = false,
}) => {
  const basicBarBarWidth =
    singleValue && maxValue ? (singleValue / maxValue) * 100 : 0;

  const isNeedToCenterBarValue = wordValue && basicBarBarWidth <= 15;

  const attrs = {
    wrapper: {
      className: 'graph-row',
      style: {
        opacity: invisible ? 0 : 1,
      },
    },

    name: {
      wrapper: {
        className: `${
          variant === 'sellingZone' ? 's-z' : ''
        } graph-row__name-section`,
      },

      value: {
        className: 'graph-row__name-section-value',
      },
    },

    barSection: {
      basic: {
        wrapper: {
          className: 'graph-row__bar-section',
        },

        bar: {
          wrapper: {
            className: 'graph-row__bar-section__bar',
            style: {
              width:
                singleValue && !basicBarBarWidth
                  ? '0.5%'
                  : `${basicBarBarWidth}%`,
            },
          },

          tooltip: {
            className: 'graph-row__bar-section__bar-tooltip',
          },

          noData: {
            className: 'graph-row__bar-section__bar-n-a',
          },

          value: {
            className: `${
              isNeedToCenterBarValue ? 'centered' : ''
            } graph-row__bar-section__bar-value`,
            style: {
              display: basicBarBarWidth <= 6 ? 'none' : 'flex',
            },
          },
        },
      },

      sellZone: {
        wrapper: {
          className: 'graph-row__sz-bar-section',
        },

        noData: {
          className: 'graph-row__sz-bar-section-n-a',
        },

        bar: {
          below: {
            className: 'graph-row__sz-bar-section__bar',
            style: {
              width: `${
                sellingZoneValues?.below ? sellingZoneValues.below * 100 : 0
              }%`,
              backgroundColor: '#646C83',
            },
          },

          in: {
            className: 'graph-row__sz-bar-section__bar',
            style: {
              width: `${
                sellingZoneValues?.in ? sellingZoneValues.in * 100 : 0
              }%`,
              backgroundColor: '#57C888',
            },
          },

          above: {
            className: 'graph-row__sz-bar-section__bar',
            style: {
              width: `${
                sellingZoneValues?.above ? sellingZoneValues.above * 100 : 0
              }%`,
              backgroundColor: '#ACACB7',
            },
          },

          tooltip: {
            className: 'graph-row__sz-bar-section__bar-tooltip',
          },

          value: {
            className: 'graph-row__sz-bar-section__bar-value',
          },
        },
      },
    },
  };

  const basicBarValue =
    percentageValue && typeof singleValue === 'number'
      ? singleValue * 100
      : singleValue;

  const defineNoDataValueByCase = () => {
    let noDataValue = 'N/A';

    if (noDataFlag) {
      if (isUpperHand) {
        noDataValue = '0%';
      } else if (isThreadDuration) {
        noDataValue = 'N/A';
      }
    } else if (isThreadDuration && !noDataFlag) {
      noDataValue = 'Threads contain only outgoing messages';
    }

    return { noDataValue };
  };

  const getFormattedValue = () => {
    if (isDuration && basicBarValue) {
      return Duration.fromObject({
        minutes: basicBarValue,
      }).toFormat('hh:mm');
    }

    return formatNavigationCellValue(basicBarValue || 'N/A');
  };

  const { noDataValue } = defineNoDataValueByCase();

  const basicBar =
    variant === 'basic' ? (
      <div {...attrs.barSection.basic.wrapper}>
        <Tooltip placement="top" title={!basicBarValue ? '' : singleBarTooltip}>
          <div {...attrs.barSection.basic.bar.wrapper}>
            {!basicBarValue ? (
              <div {...attrs.barSection.basic.bar.noData}>{noDataValue}</div>
            ) : (
              <div {...attrs.barSection.basic.bar.value}>
                {getFormattedValue()}
                {percentageValue ? '%' : ''}{' '}
                {wordValue && !isNeedToCenterBarValue
                  ? `${singleValue === 1 ? 'Week' : 'Weeks'}`
                  : ''}
                {isMeeting && basicBarValue && !percentageValue && !isDuration
                  ? `meeting${basicBarValue === 1 ? '' : 's'}`
                  : ''}
                {isDuration && basicBarValue ? 'hr' : ''}
              </div>
            )}
          </div>
        </Tooltip>
      </div>
    ) : null;

  const sellZoneBarHasDataCheck =
    sellingZoneValues?.above ||
    sellingZoneValues?.in ||
    sellingZoneValues?.below;

  const sellingZonerBars =
    variant === 'sellingZone' ? (
      <div {...attrs.barSection.sellZone.wrapper}>
        {sellZoneBarHasDataCheck ? (
          <>
            <Tooltip
              placement="top"
              title={sellingZoneValues?.above ? sellZoneTooltips.above : ''}
            >
              <div {...attrs.barSection.sellZone.bar.above}>
                <div {...attrs.barSection.sellZone.bar.value}>
                  {sellingZoneValues?.above && sellingZoneValues?.above > 0.05
                    ? `${formatNavigationCellValue(
                        sellingZoneValues.above * 100
                      )}%`
                    : ''}
                </div>
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title={sellingZoneValues?.in ? sellZoneTooltips.in : ''}
            >
              <div {...attrs.barSection.sellZone.bar.in}>
                <div {...attrs.barSection.sellZone.bar.value}>
                  {sellingZoneValues?.in && sellingZoneValues?.in > 0.05
                    ? `${formatNavigationCellValue(
                        sellingZoneValues.in * 100
                      )}%`
                    : ''}
                </div>
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title={sellingZoneValues?.below ? sellZoneTooltips.below : ''}
            >
              <div {...attrs.barSection.sellZone.bar.below}>
                <div {...attrs.barSection.sellZone.bar.value}>
                  {sellingZoneValues?.below && sellingZoneValues?.below > 0.05
                    ? `${formatNavigationCellValue(
                        sellingZoneValues.below * 100
                      )}%`
                    : ''}
                </div>
              </div>
            </Tooltip>
          </>
        ) : (
          <div {...attrs.barSection.sellZone.noData}>N/A</div>
        )}
      </div>
    ) : null;

  const tendencyBar =
    variant === 'tendency' ? (
      <TeamQTendencyBar value={singleValue || null} />
    ) : null;

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.name.wrapper}>
        <div {...attrs.name.value}>{name}</div>
      </div>
      {basicBar}
      {sellingZonerBars}
      {tendencyBar}
    </div>
  );
};
