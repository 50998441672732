import React, { useEffect, useState } from 'react';

type EmailsAnalyzedProps = {
  emailsNumber: number;
};

export const EmailsAnalyzed: React.FC<EmailsAnalyzedProps> = ({
  emailsNumber,
}: EmailsAnalyzedProps): JSX.Element => {
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    if (emailsNumber) {
      setCurrentNumber(emailsNumber);
    }
  }, [emailsNumber]);

  return <div className="table__emails-analyzed">{currentNumber} emails</div>;
};
