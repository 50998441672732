import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Modal } from '../Modal/Modal';

interface Props {
  navigateTo: (location: string) => void;
  when: boolean;
  buttonConfirmText: string;
  buttonCancelText: string;
  modalBodyText: string;
}

export const NavigationBlocker: React.FC<Props> = ({
  navigateTo,
  when,
  buttonConfirmText,
  buttonCancelText,
  modalBodyText,
}) => {
  const [modalIsOpen, setModalOpen] = useState(false);

  const [lastLocation, updateLastLocation] = useState('');

  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  useEffect(() => {
    if (confirmedNavigation) {
      navigateTo(lastLocation);
      updateConfirmedNavigation(false);
    } // eslint-disable-next-line
  }, [confirmedNavigation]);

  const showModal = (location: string) => {
    setModalOpen(true);
    updateLastLocation(location);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleBlockedNavigation = (nextLocation: any) => {
    if (!confirmedNavigation) {
      showModal(nextLocation?.pathname);

      return false;
    }

    return true;
  };

  const handleConfirmNavigationClick = () => {
    if (lastLocation) {
      closeModal();
      updateConfirmedNavigation(true);
    }
  };

  const attrs = {
    blocker: {
      when,
      message: (nextLocation: any) => handleBlockedNavigation(nextLocation),
    },

    Modal: {
      open: modalIsOpen,
      title: '',
      onClose: () => setModalOpen(false),
      empty: true,
      className: 'nav-blocker__modal',
    },

    modalBody: {
      wrapper: {
        className: 'nav-blocker__modal-body',
      },

      text: {
        className: 'nav-blocker__modal-body-text',
      },

      buttons: {
        wrapper: {
          className: 'nav-blocker__modal-body__buttons',
        },

        confirm: {
          className: 'nav-blocker__modal-body__buttons-confirm',
          onClick: handleConfirmNavigationClick,
        },

        cancel: {
          className: 'nav-blocker__modal-body__buttons-cancel',
          onClick: closeModal,
        },
      },
    },
  };

  return (
    <>
      <Prompt {...attrs.blocker} />
      <Modal {...attrs.Modal}>
        <div {...attrs.modalBody.wrapper}>
          <div {...attrs.modalBody.text}>{modalBodyText}</div>
          <div {...attrs.modalBody.buttons.wrapper}>
            <div {...attrs.modalBody.buttons.confirm}>{buttonConfirmText}</div>
            <div {...attrs.modalBody.buttons.cancel}>{buttonCancelText}</div>
          </div>
        </div>
      </Modal>
    </>
  );
};
