import React, { CSSProperties, useEffect, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { SellingZoneSkeleton } from '../../skeletons/SellingZoneSkeleton';

type Data = {
  belowSzEmailsCount: number;
  inSzEmailsCount: number;
  aboveSzEmailsCount: number;
};

type Props = {
  sellZoneData?: Data;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  lastDays?: number;
  isInTeamQ?: boolean;
  requestedTeamQId?: string;
};

type LocalData = {
  label: string;
  messages: number;
  inPercents: number;
  fill: string;
}[];

export const SellingZone: React.FC<Props> = ({
  sellZoneData,
  isLoading,
  noData,
  error,
  lastDays,
  isInTeamQ,
  requestedTeamQId,
}): JSX.Element => {
  const [activeShapeIndex, setActiveShapeIndex] = useState<number | null>(null);

  const [data, setData] = useState<LocalData>([]);

  const [lastDaysResponse, setLastDaysResponse] = useState<number | null>(null);

  useEffect(() => {
    if (!sellZoneData || isLoading) {
      setData([]);
    }

    if (
      sellZoneData?.belowSzEmailsCount?.toString().length ||
      sellZoneData?.inSzEmailsCount?.toString().length ||
      sellZoneData?.aboveSzEmailsCount?.toString().length
    ) {
      const {
        belowSzEmailsCount,
        inSzEmailsCount,
        aboveSzEmailsCount,
      } = sellZoneData;

      // const belowSzEmailsCount = 55;
      // const inSzEmailsCount = 10;
      // const aboveSzEmailsCount = 1.5;

      const totalMessages =
        belowSzEmailsCount + inSzEmailsCount + aboveSzEmailsCount;

      const customData: LocalData = [
        {
          label: 'below',
          messages: belowSzEmailsCount,
          inPercents:
            Math.round((belowSzEmailsCount / totalMessages) * 100) || 0,
          fill: '#646C83',
        },
        {
          label: 'in',
          messages: inSzEmailsCount,
          inPercents: Math.round((inSzEmailsCount / totalMessages) * 100) || 0,
          fill: '#57C888',
        },
        {
          label: 'above',
          messages: aboveSzEmailsCount,
          inPercents:
            Math.round((aboveSzEmailsCount / totalMessages) * 100) || 0,
          fill: '#ACACB7',
        },
      ];
      if (lastDays) {
        setLastDaysResponse(lastDays - 1);
      }
      setData(customData);
    }
  }, [sellZoneData, lastDays, isLoading]);

  const onMouseShapeEnter = (mess: string, index: number) => {
    setActiveShapeIndex(index);
  };

  const onMouseShapeLeave = () => {
    if (activeShapeIndex !== null && activeShapeIndex > -1) {
      setActiveShapeIndex(null);
    }
  };

  const defineTooltipStyle = () => {
    let tooltipPosition: CSSProperties = { display: 'none' };

    let tooltipWidth = 'max-content';

    const availableData = data?.filter((el) => el.messages > 0);

    const onlyOneValueCheck = availableData.length === 1;

    const isSectionInCheck = availableData?.some(
      (el) => el.messages < 100 && el.label === 'in'
    );

    if (
      activeShapeIndex !== null &&
      activeShapeIndex > -1 &&
      !noData &&
      !error &&
      !onlyOneValueCheck
    ) {
      tooltipPosition = { top: '-75px', left: '-120px' };
    } else if (onlyOneValueCheck) {
      tooltipPosition = { top: '10px', left: '-85px' };

      tooltipWidth = '240px';

      if (isSectionInCheck) {
        tooltipPosition = { top: '10px', left: '-75px' };

        tooltipWidth = '220px';
      }
    }

    return { tooltipPosition, tooltipWidth };
  };

  const { tooltipPosition, tooltipWidth } = defineTooltipStyle();

  const tooltipAttrs = {
    wrapper: {
      className: 'no-triangle chart-tooltip',
      style: {
        height: '36px',
        width: tooltipWidth,
        padding: '15px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    value: {
      className: 'chart-tooltip__value',
      style: { marginBottom: '0px' },
    },
    valueText: {
      className: 'nowrap chart-tooltip__value-text',
    },
    boldText: {
      className: 'chart-tooltip__percents-text-bold',
    },
    place: {
      className: 'chart-tooltip__percents-blue',
    },
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    if (active && data.length) {
      const place = payload[0]?.payload?.label;
      const messages = payload[0]?.payload?.messages;

      const messageToShow =
        place && !Number.isNaN(place) ? (
          <span {...tooltipAttrs.value}>
            <span {...tooltipAttrs.valueText}>
              {isInTeamQ
                ? `${
                    requestedTeamQId === 'allUsers'
                      ? 'Users were'
                      : 'The team was'
                  }`
                : 'You were'}{' '}
              <span {...tooltipAttrs.place}>{place}</span> the Authority Zone
              <span {...tooltipAttrs.boldText}> {messages}</span>{' '}
              {messages === 1 ? 'time' : 'times'}
            </span>
          </span>
        ) : (
          <span {...tooltipAttrs.value}>
            0<span {...tooltipAttrs.valueText}>-</span>
          </span>
        );

      result = <div {...tooltipAttrs.wrapper}>{messageToShow}</div>;
    }
    return result;
  };

  const attrs = {
    chartWrapper: {
      title: (
        <div className="charts__sell-zone__wrapper-title">
          Authority Zone Position
        </div>
      ),
      subtitle: `${
        isInTeamQ ? 'Team avg, last' : 'Last'
      } ${lastDaysResponse} days`,
      tooltipText: isInTeamQ
        ? `The distribution of team members' positions in the Authority Zone chart 
        based on the analyzed messages during the selected time period.`
        : `The distribution of your position in the Authority Zone chart
       based on analyzed messages during the selected time period`,
      isNoData: noData || error,
      upIndex: activeShapeIndex !== null && activeShapeIndex > -1,
      isInTeamQ,
    },
    legendWrapper: {
      className: 'charts__sell-zone__legend',
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    legendBelow: {
      className: 'charts__sell-zone__legend-below',
    },
    legendIn: {
      className: 'charts__sell-zone__legend-in',
    },
    legendAbove: {
      className: 'charts__sell-zone__legend-above',
    },
    responsiveContainer: {
      height: '100%',
    },
    chart: {
      PieChart: {
        width: 180,
        height: 180,
        data,
        padding: { top: 0, right: 0, left: 0, bottom: 0 },
        margin: { top: 18, right: 0, left: 0, bottom: 0 },
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      tooltip: {
        wrapperStyle: tooltipPosition,
        content: <CustomTooltip payload={data} active />,
        allowEscapeViewBox: { x: true, y: true },
        isAnimationActive: false,
        cursor: false,
      },
      Pie: {
        dataKey: 'messages',
        maxBarSize: 44,
        onMouseEnter: onMouseShapeEnter,
        onMouseLeave: onMouseShapeLeave,
      },
    },
  };

  const CustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index, // eslint-disable-next-line
  }: any) => {
    const defineLabelPosition = () => {
      let calcedX = 0;

      let calcedY = 0;

      const radian = Math.PI / 180;

      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

      const centeredX = cx + radius * Math.cos(-midAngle * radian);

      const centeredY = cy + radius * Math.sin(-midAngle * radian);

      const isShapeTooSmall = percent * 100 < 6;

      const availableData = data?.filter((el) => el.messages > 0);

      if (availableData.length === 1) {
        calcedX = cx + 1;
        calcedY = cy + 2;
      } else if (availableData.length > 1 && !isShapeTooSmall) {
        calcedX = centeredX + 5;
        calcedY = centeredY + 5;
      } else {
        calcedX = centeredX + 8;
        calcedY = centeredY;
      }

      return { calcedX, calcedY, isShapeTooSmall };
    };

    const { calcedX, calcedY, isShapeTooSmall } = defineLabelPosition();

    const conditionalBaseline = isShapeTooSmall ? 'middle' : 'center';

    const conditionalClassName = isShapeTooSmall
      ? 'decreased charts__sell-zone__wrapper-title-chart-value'
      : 'charts__sell-zone__wrapper-title-chart-value';

    const conditionalValue =
      percent === 0 ? '' : `${(percent * 100).toFixed(0)}%`;

    return (
      <text
        x={calcedX}
        y={calcedY}
        fill="white"
        textAnchor="middle"
        dominantBaseline={conditionalBaseline}
        className={conditionalClassName}
        onMouseEnter={() => onMouseShapeEnter('', index)}
      >
        {conditionalValue}
      </text>
    );
  };

  const ActiveShape = ({
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill, // eslint-disable-next-line
  }: any) => {
    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          filter="brightness(85%)"
        />
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          filter="brightness(85%)"
          opacity={0.6}
        />
      </g>
    );
  };

  return (
    <SellingZoneSkeleton isLoading={isLoading} isTeamQ={isInTeamQ}>
      <ChartWrapper {...attrs.chartWrapper}>
        <>
          <div {...attrs.legendWrapper}>
            <span {...attrs.legendIn}>In</span>
            <span {...attrs.legendBelow}>Below</span>
            <span {...attrs.legendAbove}>Above</span>
          </div>
          <ResponsiveContainer {...attrs.responsiveContainer}>
            <PieChart {...attrs.chart.PieChart}>
              <Pie
                isAnimationActive={data && data.length > 0}
                data={data}
                dataKey="messages"
                outerRadius={90}
                blendStroke
                labelLine={false}
                label={data ? CustomizedLabel : undefined}
                onMouseEnter={onMouseShapeEnter}
                onMouseLeave={onMouseShapeLeave}
                activeShape={ActiveShape}
                activeIndex={
                  typeof activeShapeIndex === 'number'
                    ? activeShapeIndex
                    : undefined
                }
              />
              <Tooltip {...attrs.chart.tooltip} />
            </PieChart>
          </ResponsiveContainer>
        </>
      </ChartWrapper>
    </SellingZoneSkeleton>
  );
};
