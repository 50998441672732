import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '../../../../components';
import { webhookChevron } from '../../../../components/CustomSelect/assets';
import { Input } from '../../../../components/Input/Input';
import { NavigationBlocker } from '../../../../components/NavigationBlocker/NavigationBlocker';
import { getWebhookSettingsKey } from '../../../../store/org/org.actions';
import { WebhookSettingsAuthType } from '../../../../store/org/org.types';
import { RootState } from '../../../../store/store';
import { useEditWebhook, AuthParams, FieldName } from './hooks/useEditWebhook';
import { tooltipIcon } from './components/AuthParamsRow/assets';
import { AuthParamsRow } from './components/AuthParamsRow/AuthParamsRow';
import { Spinner } from './components/Spinner/Spinner';
import { WebhookStatusModal } from './components/StatusModal/StatusModal';
import { EncryptKeyInput } from './components/EncryptKeyInput/EncryptKeyInput';

type Props = {
  isOpen: boolean;
  isWebhookFeatureActive: boolean;
};

export const WebhooksForm: React.FC<Props> = ({
  isOpen,
  isWebhookFeatureActive,
}): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);

  const { settings, status, eventsInfo } = useSelector(
    (state: RootState) => state.org.webhook
  );

  const [authParams, setAuthParams] = useState<AuthParams[]>([]);

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
    defineEvents,
  } = useEditWebhook(authParams);

  useEffect(() => {
    if (status.isDataReseted) {
      setAuthParams([]);
    }
  }, [status.isDataReseted, status.isLoading]);

  useEffect(() => {
    if (values?.hasAuth && !authParams?.length) {
      if (settings?.auth?.length) {
        const authFromResponse = settings?.auth?.map((el) => el);

        setAuthParams(authFromResponse);
      } else {
        const firstAuthParamRow = [
          {
            type: '' as WebhookSettingsAuthType,
            name: '',
            value: '',
          },
        ];

        setAuthParams(firstAuthParamRow);
      }
    } else if (!values?.hasAuth && authParams?.length) {
      setFieldValue('hasAuth', true);
    } // eslint-disable-next-line
  }, [values.hasAuth]);

  const toggleEncryptChecked = (flag: boolean) => {
    setFieldValue('hasEncryption', flag);

    if (flag === false) {
      setFieldValue('encryptKey', '');
    } else {
      setFieldValue('encryptKey', settings.key);
    }

    if (!settings.key && user) {
      dispatch(getWebhookSettingsKey(user.orgId, null));
    }
  };

  const toggleChecked = (flag: boolean) => {
    setFieldValue('hasAuth', flag);

    if (!flag) {
      setAuthParams([]);
    }
  };

  const setAuthParam = (
    index: number,
    fieldName: FieldName,
    value: WebhookSettingsAuthType | string
  ) => {
    if (fieldName && typeof value === 'string') {
      const currParamsArr = [...authParams];

      const newParams: AuthParams[] = currParamsArr.map((el, i) => ({
        ...el,
        key: `${i}|${el.value}`,
        type:
          index === i && fieldName === 'type'
            ? (value as WebhookSettingsAuthType)
            : el.type,
        name: index === i && fieldName === 'name' ? value : el.name,
        value: index === i && fieldName === 'value' ? value : el.value,
      }));

      setAuthParams(newParams);
    }
  };

  const addAuthParamRow = (isFirstRow?: boolean) => {
    if (isFirstRow) {
      if (!authParams?.length) {
        const firstAuthParamRow = [
          {
            type: '' as WebhookSettingsAuthType,
            name: '',
            value: '',
          },
        ];

        setAuthParams(firstAuthParamRow);
      }
    } else if (authParams?.length < 5) {
      const updatedAuthParams = [
        ...authParams,
        {
          type: '' as WebhookSettingsAuthType,
          name: '',
          value: '',
        },
      ];

      setAuthParams(updatedAuthParams);
    }
    if (!values?.hasAuth) {
      setFieldValue('hasAuth', true);
    }
  };

  const removeAuthParamRow = (index: number) => {
    const updatedAuthParams = authParams.filter((el, i) => i !== index);

    if (!updatedAuthParams?.length) {
      setFieldValue('hasAuth', false);
    }

    setAuthParams(updatedAuthParams);
  };

  const addParamDisabled =
    !authParams[authParams.length - 1]?.type?.length ||
    !authParams[authParams.length - 1]?.value?.length ||
    !authParams[authParams.length - 1]?.name?.length;

  const attrs = {
    wrapper: {
      className: `${isOpen ? '' : 'closed'} wk-form`,
    },

    eventsSection: {
      wrapper: {
        className: 'wk-form__events',
      },

      title: {
        className: 'wk-form__events-title',
      },

      subtitle: {
        className: 'wk-form__events-subtitle',
      },

      row: {
        wrapper: {
          className: 'wk-form__events__row',
        },

        checkbox: {
          className: 'wk-form__events__row-checkbox',
        },

        text: {
          className: 'wk-form__events__row-text',
        },

        tooltip: {
          wrapper: {
            className: 'wk-form__events__row__tooltip',
          },

          icon: {
            className: 'wk-form__events__row__tooltip-icon',
            src: tooltipIcon,
          },

          text: {
            className: 'wk-form__events__row__tooltip-text',
          },
        },
      },

      error: {
        className: 'wk-form__events-error',
      },
    },

    settings: {
      wrapper: {
        className: 'wk-form__settings',
      },

      title: {
        wrapper: {
          className: 'wk-form__settings__title',
        },

        errStatus: {
          className: 'wk-form__settings__title-err-status',
        },
      },

      subtitle: {
        className: 'wk-form__settings-subtitle',
      },

      form: {
        onSubmit: handleSubmit,
      },

      urlInput: {
        label: 'Notification URL',
        value: values.url,
        name: 'url',
        error: touched.url ? errors.url : '',
        onChange: handleChange,
        className: 'wk-form__settings-url-input',
        placeholder: 'Enter URL',
        isInWebhook: true,
        required: true,
      },

      encrypt: {
        wrapper: {
          className: 'wk-form__settings__encrypt',
        },

        input: {
          label: '',
          value: values.encryptKey,
          name: 'encryptKey',
          onChange: handleChange,
          className: 'wk-form__settings__encrypt-input',
          errorOnTheRight: true,
          placeholder: '',
          isInWebhook: true,
        },

        ecnInput: {
          value: values.encryptKey,
          isLoading: status.encryptKeyLoading,
          onChange: handleChange,
          orgId: user?.orgId || '',
          disabled: !isWebhookFeatureActive,
        },
      },

      authParam: {
        title: {
          wrapper: {
            className: 'wk-form__settings__auth-param',
          },

          checkbox: {
            className: 'wk-form__settings__auth-param-checkbox',
            defaultActive: values.hasAuth,
          },

          label: {
            wrapper: {
              className: 'wk-form__settings__auth-param__label',
              onClick: () => addAuthParamRow(true),
            },

            text: {
              className: 'wk-form__settings__auth-param__label-text',
            },

            chevron: {
              src: webhookChevron,
              className: `${
                values.hasAuth ? 'revert' : ''
              } wk-form__settings__auth-param__label-chevron`,
            },
          },
        },

        tableTitle: {
          wrapper: {
            className: 'wk-form__settings__auth-param__table-title',
          },

          type: {
            className: 'wk-form__settings__auth-param__table-title-type',
          },

          name: {
            className: 'wk-form__settings__auth-param__table-title-name',
          },

          value: {
            className: 'wk-form__settings__auth-param__table-title-value',
          },
        },
      },

      footer: {
        wrapper: {
          className: 'wk-form__settings__footer',
        },

        addParam: {
          className: `${
            addParamDisabled ? 'disabled' : ''
          } wk-form__settings__footer-add-row-btn`,
          onClick: () => addAuthParamRow(),
        },

        save: {
          button: {
            className: `${
              status.isLoading ? 'loading' : ''
            } wk-form__settings__footer__save-btn`,
            onClick: () => handleSubmit(),
          },

          spinner: {
            className: 'wk-form__settings__footer__save-btn-spinner',
            isPng: true,
          },
        },
      },
    },

    NavigationBlocker: {
      navigateTo: (path: string) => {
        if (path?.length) {
          history.push(path);
        }
      },
      buttonConfirmText: 'Leave',
      buttonCancelText: 'Stay',
      modalBodyText: `Are you sure you want to leave this page?
      Changes you made will not be saved.`,
    },
  };

  const setEventSelected = (flag: boolean, index?: number) => {
    if (typeof index === 'number') {
      const eventNames = [
        'addParticipant',
        'removeParticipant',
        'newAnalysis',
        'upperHandUpdate',
      ];

      setFieldValue(eventNames[index], flag);
    }
  };

  const eventRowsData = [
    {
      text: 'When the other side adds a new participant to a thread',
      value: values.addParticipant,
      tooltip:
        'The other side added a new participant to an existing engagement thread.',
    },
    {
      text: 'When the other side removes a participant from a thread',
      value: values.removeParticipant,
      tooltip:
        'The other side removed a participant from an existing engagement thread.',
    },
    {
      text: 'When there is a new analysis result',
      value: values.newAnalysis,
      tooltip: 'New analysis results for an existing engagement are available.',
    },
    {
      text: 'When Upper-Hand status switches sides',
      value: values.upperHandUpdate,
      tooltip:
        'The Upper Hand possession has switched between your side and the other side.',
    },
  ];

  const createSelectEventRow = (
    text: string,
    value: boolean,
    index: number,
    tooltip?: string
  ) => {
    return (
      <div {...attrs.eventsSection.row.wrapper}>
        <Checkbox
          changeValue={setEventSelected}
          defaultActive={value}
          index={index}
          disabled={!isWebhookFeatureActive}
          {...attrs.eventsSection.row.checkbox}
        />
        <div {...attrs.eventsSection.row.text}>{text}</div>
        <div {...attrs.eventsSection.row.tooltip.wrapper}>
          <img {...attrs.eventsSection.row.tooltip.icon} alt="" />
          <div {...attrs.eventsSection.row.tooltip.text}>{tooltip}</div>
        </div>
      </div>
    );
  };

  const events = eventRowsData.map((el, i) => (
    <React.Fragment key={`${el.text}${i}`}>
      {createSelectEventRow(el.text, el.value, i, el.tooltip)}
    </React.Fragment>
  ));

  const eventsErrorCheck = !eventRowsData.some((el) => el.value);

  const eventsError = eventsErrorCheck ? (
    <div {...attrs.eventsSection.error}>Please select at least one event</div>
  ) : null;

  const encryptField = (
    <EncryptKeyInput {...attrs.settings.encrypt.ecnInput}>
      <Checkbox
        changeValue={toggleEncryptChecked}
        defaultActive={values.hasEncryption}
        className="wk-form__settings__encrypt-checkbox"
      />
    </EncryptKeyInput>
  );

  const authParamsTitle = (
    <div {...attrs.settings.authParam.title.wrapper}>
      <Checkbox
        changeValue={toggleChecked}
        {...attrs.settings.authParam.title.checkbox}
      />
      <div {...attrs.settings.authParam.title.label.wrapper}>
        <div {...attrs.settings.authParam.title.label.text}>
          Authorization Parameters
        </div>
        <img {...attrs.settings.authParam.title.label.chevron} alt="" />
      </div>
    </div>
  );

  const authTableTitle = authParams?.length ? (
    <div {...attrs.settings.authParam.tableTitle.wrapper}>
      <div {...attrs.settings.authParam.tableTitle.type}>Type</div>
      <div {...attrs.settings.authParam.tableTitle.name}>Name</div>
      <div {...attrs.settings.authParam.tableTitle.value}>Value</div>
    </div>
  ) : null;

  const authRowsToShow = authParams?.length
    ? Array.from(new Array(authParams.length)).map((el, i) => (
        <React.Fragment key={i}>
          <AuthParamsRow
            index={i}
            row={authParams[i]}
            setAuthParam={setAuthParam}
            deleteAuthRow={removeAuthParamRow}
          />
        </React.Fragment>
      ))
    : null;

  const addNewRowButton =
    typeof authRowsToShow?.length === 'number' &&
    authRowsToShow?.length > 0 &&
    authRowsToShow?.length < 5 ? (
      <div {...attrs.settings.footer.addParam}>Add parameter</div>
    ) : (
      <div />
    );

  const hasEncryptNotChangedCheck =
    settings.hasEncryption === values.hasEncryption;

  const keyNotChangedCheck = settings.key === settings.oldKey;

  const {
    addParticipantEv,
    removeParticipantEv,
    newAnalysisEv,
    upperHandUpdateEv,
  } = defineEvents(eventsInfo.events);

  const eventsNotChangedCheck =
    addParticipantEv === values.addParticipant &&
    removeParticipantEv === values.removeParticipant &&
    newAnalysisEv === values.newAnalysis &&
    upperHandUpdateEv === values.upperHandUpdate;

  const compareTwoArrays = (localArr: AuthParams[], reduxArr: AuthParams[]) => {
    return (
      localArr.length === reduxArr.length &&
      localArr.every((localEl) =>
        reduxArr.some(
          (reduxEl) =>
            localEl.type === reduxEl.type &&
            localEl.name === reduxEl.name &&
            localEl.value === reduxEl.value
        )
      )
    );
  };

  const authArrNotChangedCheck = compareTwoArrays(
    authParams,
    settings.auth || []
  );

  const blockNavigationCondition =
    !hasEncryptNotChangedCheck ||
    !keyNotChangedCheck ||
    !authArrNotChangedCheck ||
    !eventsNotChangedCheck ||
    authParams?.length !== settings?.auth?.length ||
    values?.url !== settings?.url ||
    values?.encryptKey !== settings?.key;

  const submitBtnDisabled =
    !isWebhookFeatureActive ||
    authParams.some(
      (el) => !el?.type?.length || !el?.name?.length || !el?.value?.length
    ) ||
    eventsErrorCheck ||
    !blockNavigationCondition;

  const submitInner = status.isLoading ? (
    <>
      <Spinner {...attrs.settings.footer.save.spinner} /> Testing
    </>
  ) : (
    'Save'
  );

  const footer = (
    <div {...attrs.settings.footer.wrapper}>
      {addNewRowButton}{' '}
      <button
        {...attrs.settings.footer.save.button}
        type="submit"
        disabled={submitBtnDisabled}
      >
        {submitInner}
      </button>
    </div>
  );

  const titleUpdateFailedStatus = status.updateFailed ? (
    <div {...attrs.settings.title.errStatus}>Connection failed</div>
  ) : null;

  const titleDisabledByError =
    (settings.isDisabledByError || status.connectionLost) &&
    !status.updateFailed ? (
      <div {...attrs.settings.title.errStatus}>Connection lost</div>
    ) : null;

  const settingsTitleErrorLabel = (
    <>
      {titleUpdateFailedStatus} {titleDisabledByError}
    </>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.eventsSection.wrapper}>
        <div {...attrs.eventsSection.title}>Select Engagement Events</div>
        <div {...attrs.eventsSection.subtitle}>
          These events will trigger the calls to your notification URL
        </div>
        {events}
        {eventsError}
      </div>
      <div {...attrs.settings.wrapper}>
        <div {...attrs.settings.title.wrapper}>
          Connection Settings {settingsTitleErrorLabel}
        </div>
        <div {...attrs.settings.subtitle}>
          {`Fill in the fields below and click 'Save' to connect the webhooks.`}
        </div>
        <form {...attrs.settings.form}>
          <Input {...attrs.settings.urlInput} />
          {encryptField}
        </form>
        {authParamsTitle}
        {authTableTitle}
        {authRowsToShow}
        {footer}
      </div>
      <NavigationBlocker
        {...attrs.NavigationBlocker}
        when={blockNavigationCondition}
      />
      <WebhookStatusModal />
    </div>
  );
};
