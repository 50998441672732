import React from 'react';

export const ErrorIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.49983 3.99992C8.86802 3.99992 9.1665 4.2984 9.1665 4.66659V9.33325C9.1665 9.70144 8.86802 9.99992 8.49983 9.99992C8.13164 9.99992 7.83316 9.70144 7.83316 9.33325V4.66659C7.83316 4.2984 8.13164 3.99992 8.49983 3.99992Z"
      fill="#F0384A"
    />
    <path
      d="M8.49983 12.3333C8.96007 12.3333 9.33316 11.9602 9.33316 11.4999C9.33316 11.0397 8.96007 10.6666 8.49983 10.6666C8.03959 10.6666 7.6665 11.0397 7.6665 11.4999C7.6665 11.9602 8.03959 12.3333 8.49983 12.3333Z"
      fill="#F0384A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.56851 3.18556C6.10628 2.05417 7.24714 1.33325 8.49983 1.33325C9.75253 1.33325 10.8934 2.05417 11.4311 3.18556L15.0766 10.8552C15.9176 12.6246 14.6273 14.6666 12.6681 14.6666H4.33154C2.37237 14.6666 1.08204 12.6246 1.92309 10.8552L5.56851 3.18556ZM8.49983 2.66659C7.76176 2.66659 7.08958 3.09134 6.77274 3.75794L3.12731 11.4275C2.70679 12.3123 3.35195 13.3333 4.33154 13.3333H12.6681C13.6477 13.3333 14.2929 12.3123 13.8723 11.4275L10.2269 3.75794C9.91008 3.09134 9.2379 2.66659 8.49983 2.66659Z"
      fill="#F0384A"
    />
  </svg>
);
