import React, { useEffect, useState } from 'react';
import {
  IncomingEmailActivity,
  OutgoingEmailActivity,
} from '../../../../../../store/charts/charts.types';

export type HeatMapType = 'incoming' | 'outgoing';

type TimeInterval =
  | 'fourAm'
  | 'eightAm'
  | 'twelveAm'
  | 'fourPm'
  | 'eightPm'
  | 'twelvePm';

type Props = {
  data?: IncomingEmailActivity | OutgoingEmailActivity | null;
  type: HeatMapType;
  noData: boolean;
  error: boolean;
};

interface Cell {
  weekDay: string;
  timeInterval: TimeInterval;
  value: number;
}

type ConvertedData = {
  monday: Cell[] | null;
  tuesday: Cell[] | null;
  wednesday: Cell[] | null;
  thursday: Cell[] | null;
  friday: Cell[] | null;
  saturday: Cell[] | null;
  sunday: Cell[] | null;
};

export const HeatMap: React.FC<Props> = ({ data, type, noData, error }) => {
  const [convertedData, setConvertedData] = useState<ConvertedData | null>(
    null
  );

  useEffect(() => {
    if (!data && convertedData) {
      setConvertedData(null);
    }

    if (data && !convertedData) {
      const objWithoutData: ConvertedData = {
        monday: null,
        tuesday: null,
        wednesday: null,
        thursday: null,
        friday: null,
        saturday: null,
        sunday: null,
      };

      const monday = Object.entries(data.Mon).map(([key, value]) => ({
        weekDay: 'Mondays',
        timeInterval: key as TimeInterval,
        value: value as number,
      }));

      const tuesday = Object.entries(data.Tue).map(([key, value]) => ({
        weekDay: 'Tuesdays',
        timeInterval: key as TimeInterval,
        value: value as number,
      }));

      const wednesday = Object.entries(data.Wed).map(([key, value]) => ({
        weekDay: 'Wednesdays',
        timeInterval: key as TimeInterval,
        value: value as number,
      }));

      const thursday = Object.entries(data.Thu).map(([key, value]) => ({
        weekDay: 'Thursdays',
        timeInterval: key as TimeInterval,
        value: value as number,
      }));

      const friday = Object.entries(data.Fri).map(([key, value]) => ({
        weekDay: 'Fridays',
        timeInterval: key as TimeInterval,
        value: value as number,
      }));

      const saturday = Object.entries(data.Sat).map(([key, value]) => ({
        weekDay: 'Saturdays',
        timeInterval: key as TimeInterval,
        value: value as number,
      }));

      const sunday = Object.entries(data.Sun).map(([key, value]) => ({
        weekDay: 'Sundays',
        timeInterval: key as TimeInterval,
        value: value as number,
      }));

      const objectToSet = {
        ...objWithoutData,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
      };

      setConvertedData(objectToSet);
    } // eslint-disable-next-line
  }, [data]);

  const attrs = {
    wrapper: {
      className: 'heat-map',
    },

    chart: {
      wrapper: {
        className: 'heat-map__chart',
        style: noData || error ? { opacity: 0.4 } : {},
      },

      yAxis: {
        wrapper: {
          className: 'heat-map__chart__y-axis',
        },
        item: {
          className: 'heat-map__chart__y-axis-item',
        },
      },

      xAxis: {
        wrapper: {
          className: 'heat-map__chart__x-axis',
        },
        item: {
          className: 'heat-map__chart__x-axis-item',
        },
      },

      column: {
        wrapper: {
          className: `${convertedData ? 'loaded ' : ''}heat-map__chart__column`,
        },
        item: {
          wrapper: {
            className: `${
              convertedData ? 'loaded ' : ''
            }heat-map__chart__column__item`,
          },
          background: {
            className: 'heat-map__chart__column__item-background',
          },
          value: {
            className: 'heat-map__chart__column__item-value',
          },
          tooltip: {
            wrapper: {
              className: 'heat-map__chart__column__item__tooltip',
            },
            border: {
              className: 'heat-map__chart__column__item__tooltip-border',
            },
            body: {
              wrapper: {
                className: 'heat-map__chart__column__item__tooltip__body',
              },
              time: {
                className: 'heat-map__chart__column__item__tooltip__body-time',
              },
              messages: {
                className:
                  'heat-map__chart__column__item__tooltip__body-messages',
              },
            },
          },
        },
      },
    },
  };

  const createHeatMapCell = (
    obj: Cell,
    currType: HeatMapType,
    columnNumber: number
  ) => {
    let cell = null;

    const { weekDay, timeInterval, value: currentValue } = obj;

    const defineCellBgColor = (value: number) => {
      let bg = '#cdd5e5';

      if (value < 25) {
        bg = currType === 'incoming' ? '#D3ECFF' : '#CDD5E5';
      } else if (value >= 25 && value < 50) {
        bg = currType === 'incoming' ? '#A6D9FF' : '#9AABCB';
      } else if (value >= 50 && value < 75) {
        bg = currType === 'incoming' ? '#7AC7FF' : '#6881B2';
      } else if (value >= 25) {
        bg = currType === 'incoming' ? '#4EB4FF' : '#365798';
      }

      return bg;
    };

    const convertTimeRange = () => {
      let result = '';

      if (timeInterval === 'fourAm') {
        result = '12pm-4am';
      } else if (timeInterval === 'eightAm') {
        result = '4am-8am';
      } else if (timeInterval === 'twelveAm') {
        result = '8am-12am';
      } else if (timeInterval === 'fourPm') {
        result = '12am-4pm';
      } else if (timeInterval === 'eightPm') {
        result = '4pm-8pm';
      } else if (timeInterval === 'twelvePm') {
        result = '8pm-12pm';
      }

      return result;
    };

    const maxPossibleValue = data ? data.highestValue - data.lowestValue : 0;
    const minValue = data?.lowestValue || 0;

    const currentValueInPercents = data
      ? ((currentValue - minValue) / maxPossibleValue) * 100
      : 0;

    const defineValueToShow = () => {
      let valueToShow: number | string = currentValue;

      if (currentValue === 0) {
        valueToShow = '-';
      } else if (currentValue >= 1000) {
        valueToShow = currentValue.toLocaleString('en-US');
      }

      return { valueToShow };
    };

    const { valueToShow } = defineValueToShow();

    const defineTooltipPosition = (column: number) => {
      let x = '0%';

      let leftPosition = 'unset';

      let rightPosition = 'unset';

      if (column === 1) {
        leftPosition = '0%';
        rightPosition = 'unset';
        x = '0%';
      } else if (column === 2) {
        leftPosition = '0%';
        rightPosition = 'unset';
        x = '-13%';
      } else if (column === 3) {
        leftPosition = '0%';
        rightPosition = 'unset';
        x = '-26%';
      } else if (column === 4) {
        leftPosition = '50%';
        rightPosition = 'unset';
        x = '-50%';
      } else if (column === 5) {
        leftPosition = 'unset';
        rightPosition = '0%';
        x = '26%';
      } else if (column === 6) {
        leftPosition = 'unset';
        rightPosition = '0%';
        x = '13%';
      } else if (column === 7) {
        leftPosition = 'unset';
        rightPosition = '0%';
        x = '0%';
      }

      return { x, leftPosition, rightPosition };
    };

    const { x, leftPosition, rightPosition } = defineTooltipPosition(
      columnNumber
    );

    const cellInner = (
      <div {...attrs.chart.column.item.wrapper}>
        <div
          {...attrs.chart.column.item.background}
          style={{ backgroundColor: defineCellBgColor(currentValueInPercents) }}
        />
        <div {...attrs.chart.column.item.value}>{valueToShow}</div>
        <div
          {...attrs.chart.column.item.tooltip.wrapper}
          style={{
            transform: `translate(${x}, 17px)`,
            left: leftPosition,
            right: rightPosition,
          }}
        >
          <div
            {...attrs.chart.column.item.tooltip.border}
            style={{
              backgroundColor: defineCellBgColor(currentValueInPercents),
            }}
          />
          <div {...attrs.chart.column.item.tooltip.body.wrapper}>
            <div {...attrs.chart.column.item.tooltip.body.time}>
              {weekDay}, {convertTimeRange()}
            </div>
            <div {...attrs.chart.column.item.tooltip.body.messages}>
              {valueToShow === '-' ? 0 : valueToShow}{' '}
              {currentValue === 1 ? 'message' : 'messages'}{' '}
              {currType === 'incoming' ? 'received' : 'sent'}
            </div>
          </div>
        </div>
      </div>
    );

    cell = cellInner;

    return cell;
  };

  const xAxis = (
    <div {...attrs.chart.xAxis.wrapper}>
      <div {...attrs.chart.xAxis.item}>Mon</div>
      <div {...attrs.chart.xAxis.item}>Tue</div>
      <div {...attrs.chart.xAxis.item}>Wed</div>
      <div {...attrs.chart.xAxis.item}>Thu</div>
      <div {...attrs.chart.xAxis.item}>Fri</div>
      <div {...attrs.chart.xAxis.item}>Sat</div>
      <div {...attrs.chart.xAxis.item}>Sun</div>
    </div>
  );

  const yAxis = (
    <div {...attrs.chart.yAxis.wrapper}>
      <div {...attrs.chart.yAxis.item}>12pm</div>
      <div {...attrs.chart.yAxis.item}>8pm</div>
      <div {...attrs.chart.yAxis.item}>4pm</div>
      <div {...attrs.chart.yAxis.item}>12am</div>
      <div {...attrs.chart.yAxis.item}>8am</div>
      <div {...attrs.chart.yAxis.item}>4am</div>
    </div>
  );

  const monday = (
    <div {...attrs.chart.column.wrapper}>
      {convertedData?.monday?.map((el, i) => (
        <React.Fragment key={`${el.weekDay}${el.value}${i}`}>
          {createHeatMapCell(el, type, 1)}
        </React.Fragment>
      ))}
    </div>
  );

  const tuesday = (
    <div {...attrs.chart.column.wrapper}>
      {convertedData?.tuesday?.map((el, i) => (
        <React.Fragment key={`${el.weekDay}${el.value}${i}`}>
          {createHeatMapCell(el, type, 2)}
        </React.Fragment>
      ))}
    </div>
  );

  const wednesday = (
    <div {...attrs.chart.column.wrapper}>
      {convertedData?.wednesday?.map((el, i) => (
        <React.Fragment key={`${el.weekDay}${el.value}${i}`}>
          {createHeatMapCell(el, type, 3)}
        </React.Fragment>
      ))}
    </div>
  );

  const thursday = (
    <div {...attrs.chart.column.wrapper}>
      {convertedData?.thursday?.map((el, i) => (
        <React.Fragment key={`${el.weekDay}${el.value}${i}`}>
          {createHeatMapCell(el, type, 4)}
        </React.Fragment>
      ))}
    </div>
  );

  const friday = (
    <div {...attrs.chart.column.wrapper}>
      {convertedData?.friday?.map((el, i) => (
        <React.Fragment key={`${el.weekDay}${el.value}${i}`}>
          {createHeatMapCell(el, type, 5)}
        </React.Fragment>
      ))}
    </div>
  );

  const saturday = (
    <div {...attrs.chart.column.wrapper}>
      {convertedData?.saturday?.map((el, i) => (
        <React.Fragment key={`${el.weekDay}${el.value}${i}`}>
          {createHeatMapCell(el, type, 6)}
        </React.Fragment>
      ))}
    </div>
  );

  const sunday = (
    <div {...attrs.chart.column.wrapper}>
      {convertedData?.sunday?.map((el, i) => (
        <React.Fragment key={`${el.weekDay}${el.value}${i}`}>
          {createHeatMapCell(el, type, 7)}
        </React.Fragment>
      ))}
    </div>
  );

  const conditionalChartBody =
    noData || error ? (
      <></>
    ) : (
      <>
        {monday}
        {tuesday}
        {wednesday}
        {thursday}
        {friday}
        {saturday}
        {sunday}
      </>
    );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.chart.wrapper}>
        {yAxis}
        {conditionalChartBody}
        {xAxis}
      </div>
    </div>
  );
};
