import React from 'react';

export const CheckedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.35366 3.64655C9.54886 3.84187 9.54877 4.15845 9.35345 4.35366L5.3511 8.35366C5.25728 8.44742 5.13006 8.50006 4.99743 8.5C4.86479 8.49994 4.73762 8.44719 4.64389 8.35335L2.64624 6.35335C2.45109 6.15797 2.45128 5.84139 2.64665 5.64624C2.84203 5.45109 3.15861 5.45128 3.35376 5.64665L4.99796 7.29279L8.64655 3.64634C8.84187 3.45114 9.15845 3.45123 9.35366 3.64655Z"
      fill="#070B12"
    />
  </svg>
);
