import { Duration } from 'luxon';
import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { EstimatedTendsChart } from '../../../Analytics/statsComponents/charts/estimatedTends/EstimatedTends';
import { MeetingCountChart } from '../../../Analytics/statsComponents/charts/meetingCount/MeetingCountChart';
import { MeetingDurationChart } from '../../../Analytics/statsComponents/charts/meetingDuration/MeetingDurationChart';
import { SellingZone } from '../../../Analytics/statsComponents/charts/sellingZone/SellingZone';
import { TalkTimeRatio } from '../../../Analytics/statsComponents/charts/talkTimeRatio/TalkTimeRatio';
import { UpperHandChart } from '../../../Analytics/statsComponents/charts/upperHand/UpperHand';
import { formatNavigationCellValue } from '../../utils/teamq.utils';
import { QCellSkeleton } from '../skeletons/QCellSkeleton';

export const TeamOverview: React.FC = () => {
  const {
    requestedTeamId,
    requestedTimePeriodDays,
    activity,
    activityStatus,
    upperHand,
    upperHandStatus,
    estTendencies,
    estTendenciesStatus,
    sellingZone,
    sellingZoneStatus,
  } = useAppSelector((state) => state.teamQ);

  const talkTimeRatioChartData = useAppSelector(
    (state) => state.soundWaveTeamQ.talkTimeRatioChartData
  );

  const isTalkTimeRatioChartDataLoading = useAppSelector(
    (state) => state.soundWaveTeamQ.isLoading.talkTimeRatioChartData
  );

  const isTalkTimeRatioChartDataError = useAppSelector(
    (state) => state.soundWaveTeamQ.isError.talkTimeRatioChartData
  );

  const meetingCountChartData = useAppSelector(
    (state) => state.soundWaveTeamQ.meetingCountChartData
  );

  const isMeetingCountChartDataLoading = useAppSelector(
    (state) => state.soundWaveTeamQ.isLoading.meetingCountChartData
  );

  const isMeetingCountChartDataError = useAppSelector(
    (state) => state.soundWaveTeamQ.isError.meetingCountChartData
  );

  const meetingDurationChartData = useAppSelector(
    (state) => state.soundWaveTeamQ.meetingDurationChartData
  );

  const isMeetingDurationChartDataLoading = useAppSelector(
    (state) => state.soundWaveTeamQ.isLoading.meetingDurationChartData
  );

  const isMeetingDurationChartDataError = useAppSelector(
    (state) => state.soundWaveTeamQ.isError.meetingDurationChartData
  );

  const attrs = {
    wrapper: {
      className: 'team-q-overview',
    },

    titleRow: {
      wrapper: {
        className: 'team-q__header',
      },

      label: {
        className: 'team-q__header-title',
      },
    },

    activitySection: {
      wrapper: {
        className: 'team-q-overview__activity-section',
      },

      divider: {
        className: 'team-q-overview__activity-section-divider',
      },

      cell: {
        wrapper: {
          className: 'team-q-overview__activity-section__cell',
        },

        title: {
          className: 'team-q-overview__activity-section__cell-title',
        },

        value: {
          normal: {
            className: 'team-q-overview__activity-section__cell__value',
          },

          bold: {
            className: 'team-q-overview__activity-section__cell__value-bold',
          },

          noData: {
            className: 'team-q-overview__activity-section__cell__value-n-a',
          },
        },
      },
    },

    chartSectionsWrapper: {
      className: 'team-q-overview__chart-sections-wrapper',
    },

    chartSection: {
      wrapper: {
        className: 'team-q-overview__chart-section',
      },

      divider: {
        className: 'team-q-overview__chart-section-divider',
      },

      UhPossessions: {
        upperHandData: upperHand,
        isLoading: upperHandStatus?.isLoading,
        noData: upperHandStatus?.noData,
        error: upperHandStatus?.error,
        isInTeamQ: true,
        lastDays: requestedTimePeriodDays ? requestedTimePeriodDays + 1 : 0,
      },

      EstimatedTends: {
        data: estTendencies,
        isLoading: estTendenciesStatus.isLoading,
        noData: estTendenciesStatus?.noData,
        error: estTendenciesStatus.error,
        isInTeamQ: true,
        lastDays: requestedTimePeriodDays ? requestedTimePeriodDays + 1 : 0,
      },

      SellingZone: {
        sellZoneData: sellingZone?.data,
        isLoading: sellingZoneStatus.isLoading,
        noData: sellingZoneStatus?.noData,
        error: sellingZoneStatus.error,
        isInTeamQ: true,
        lastDays: requestedTimePeriodDays ? requestedTimePeriodDays + 1 : 0,
        requestedTeamQId: requestedTeamId || '',
      },
    },

    talkTimeRationChart: {
      talkTimeRatioData: {
        prospect: talkTimeRatioChartData?.prospectsTTR || 0,
        myTeam: talkTimeRatioChartData?.teamTTR || 0,
        unassigned: talkTimeRatioChartData?.unassignedTTR || 0,
        silence: talkTimeRatioChartData?.silenceRatio || 0,
        totalMeetings: talkTimeRatioChartData?.totalMeetings || 0,
        previousPeriodUserTTR:
          talkTimeRatioChartData?.previousPeriodAvgTTR || 0,
      },
      isLoading: isTalkTimeRatioChartDataLoading,
      noData: !(
        talkTimeRatioChartData?.prospectsTTR ||
        talkTimeRatioChartData?.teamTTR ||
        talkTimeRatioChartData?.unassignedTTR ||
        talkTimeRatioChartData?.silenceRatio ||
        talkTimeRatioChartData?.totalMeetings
      ),
      error: isTalkTimeRatioChartDataError,
      lastDays: requestedTimePeriodDays || 0,
      isInTeamQ: true,
    },

    weeklyMeetingCountChart: {
      meetingCountData: meetingCountChartData || null,
      isLoading: isMeetingCountChartDataLoading,
      noData: !meetingCountChartData?.stats?.length,
      error: isMeetingCountChartDataError,
      lastDays: requestedTimePeriodDays || 0,
      isInTeamQ: true,
    },

    meetingDurationChart: {
      meetingDurationData: meetingDurationChartData,
      lastDays: requestedTimePeriodDays || 0,
      isLoading: isMeetingDurationChartDataLoading,
      noData: !(
        meetingDurationChartData &&
        meetingDurationChartData.teamAverageDurationMin
      ),
      error: isMeetingDurationChartDataError,
      isInTeamQ: true,
    },
  };

  const analyticsHeadline = (
    <div {...attrs.titleRow.wrapper}>
      <div {...attrs.titleRow.label}>Team Analytics</div>
    </div>
  );

  const createCell = (
    title: string,
    value: number | string,
    avgText: React.ReactNode
  ) => {
    const definedValue =
      typeof value === 'number' ? formatNavigationCellValue(value) : value;

    const valueToShow =
      definedValue === 'N/A' ? (
        <div {...attrs.activitySection.cell.value.noData}>N/A</div>
      ) : (
        <>
          <div {...attrs.activitySection.cell.value.bold}>{definedValue}</div>
          {avgText}
        </>
      );

    return (
      <div {...attrs.activitySection.cell.wrapper}>
        <div {...attrs.activitySection.cell.title}>{title}</div>
        <QCellSkeleton isLoading={activityStatus.isLoading}>
          <div {...attrs.activitySection.cell.value.normal}>{valueToShow}</div>
        </QCellSkeleton>
      </div>
    );
  };

  const activitySection = (
    <div {...attrs.activitySection.wrapper}>
      {createCell(
        'Thread Duration',
        activity?.avgThreadDurationWeeks || 'N/A',
        <>
          {activity?.avgThreadDurationWeeks === 1 ? 'Week' : 'Weeks'}
          <br /> Team Avg
        </>
      )}

      <div {...attrs.activitySection.divider} />

      {createCell(
        'Thread Length',
        activity?.avgMessagesPerThread || 'N/A',
        <>
          {activity?.avgMessagesPerThread === 1 ? 'Message' : 'Messages'}
          <br /> Team Avg
        </>
      )}

      <div {...attrs.activitySection.divider} />

      {createCell(
        'Messages Analyzed',
        activity?.emailsAnalyzedAvg || 'N/A',
        <>
          Avg
          <br /> Team Member
        </>
      )}

      <div {...attrs.activitySection.divider} />

      {createCell(
        'Messages Simulated',
        activity?.emailsSimulatedAvg || 'N/A',
        <>
          Avg
          <br /> Team Member
        </>
      )}

      <div {...attrs.activitySection.divider} />

      {createCell(
        'Meeting Count',
        activity?.avgMeetingCount || 'N/A',
        <>
          {activity?.avgMeetingCount === 1 ? 'Meeting' : 'Meetings'}
          <br /> Team Avg
        </>
      )}

      <div {...attrs.activitySection.divider} />

      {createCell(
        'Meeting Duration',
        activity?.avgMeetingDurationMin
          ? Duration.fromObject({
              minutes: activity.avgMeetingDurationMin,
            }).toFormat('hh:mm')
          : 'N/A',
        <>
          Duration Avg
          <br /> Team Meetings
        </>
      )}
    </div>
  );

  const chartSection = (
    <div {...attrs.chartSectionsWrapper}>
      <div {...attrs.chartSection.wrapper}>
        <UpperHandChart {...attrs.chartSection.UhPossessions} />
        <div {...attrs.chartSection.divider} />
        <EstimatedTendsChart {...attrs.chartSection.EstimatedTends} />
        <div {...attrs.chartSection.divider} />
        <SellingZone {...attrs.chartSection.SellingZone} />
      </div>
      <div {...attrs.chartSection.wrapper}>
        <TalkTimeRatio {...attrs.talkTimeRationChart} />
        <div {...attrs.chartSection.divider} />
        <MeetingCountChart {...attrs.weeklyMeetingCountChart} />
        <div {...attrs.chartSection.divider} />
        <MeetingDurationChart {...attrs.meetingDurationChart} />
      </div>
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      {activitySection}
      {analyticsHeadline}
      {chartSection}
    </div>
  );
};
