import React, { memo, useMemo } from 'react';
import { Skeleton } from 'antd';
import { EventSummaryItem } from '../../../../components/EventSummaryItem/EventSummaryItem';
import { EventSummaryTopics } from '../../../../components/EventSummaryItem/components/EventSummaryTopics/EventSummaryTopics';
import { EventSummaryList } from '../../../../components/EventSummaryItem/components/EventSummaryList/EventSummaryList';
import { useAppSelector } from '../../../../store/hooks';
import { Tooltip } from '../../../../components/SoundWave';
import { EventSummaryEmpty } from '../../../../components/EventSummaryItem/components/EventSummaryEmpty/EventSummaryEmpty';

export const MeetingSummary: React.FC = memo(() => {
  const isMeetingDetailsLoading = useAppSelector(
    (state) => state.meetingDetails.isLoading.meetingDetails
  );

  const isMeetingDetailsError = useAppSelector(
    (state) => state.meetingDetails.isError.meetingDetails
  );

  const summary = useAppSelector(
    (state) => state.meetingDetails.data?.analysis?.summary
  );

  const summaryTopicsLabels = useMemo(
    () =>
      summary?.topics ? summary.topics.map((topic) => ({ text: topic })) : [],
    [summary]
  );

  const isLoading = isMeetingDetailsLoading || isMeetingDetailsError;

  const attrs = {
    container: {
      id: 'meeting-summary',
    },
    title: {
      className: 'event-frame__content-title event-analysis-compare__title',
    },
    titleTooltip: {
      text:
        'A summary of key points and action items discussed during the meeting.',
      width: 256,
    },
    summaryTopicsContainer: {
      title: 'Topics',
    },
    summaryTopics: {
      isLoading,
      labels: summaryTopicsLabels,
      emptyText: 'No topics detected for this meeting.',
    },
    summaryInANutshellContainer: {
      title: 'Summary',
    },
    summaryEmpty: {
      text: 'No summary detected for this meeting.',
    },
    summaryKeyPointsContainer: {
      title: 'Key Points Discussed',
    },
    summaryKeyPoints: {
      isLoading,
      items: summary?.keyPoints || [],
      emptyText: 'No key points detected for this meeting.',
    },
    summaryActionItemsContainer: {
      title: 'Action Items',
    },
    summaryActionItems: {
      isLoading,
      ordered: true,
      items: summary?.actionItems || [],
      emptyText: 'No action items detected for this meeting.',
    },
    textSkeleton: {
      active: true,
      title: false,
      paragraph: {
        rows: 5,
        width: '100%',
        style: {
          margin: 0,
        },
      },
    },
  };

  const renderSummary = () => {
    if (isLoading) {
      return <Skeleton {...attrs.textSkeleton} />;
    }

    if (summary?.summary) {
      return summary.summary;
    }

    return <EventSummaryEmpty {...attrs.summaryEmpty} />;
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.title}>
        <span>Meeting Summary</span>
        <Tooltip {...attrs.titleTooltip} />
      </div>
      <EventSummaryItem {...attrs.summaryTopicsContainer}>
        <EventSummaryTopics {...attrs.summaryTopics} />
      </EventSummaryItem>
      <EventSummaryItem {...attrs.summaryInANutshellContainer}>
        {renderSummary()}
      </EventSummaryItem>
      <EventSummaryItem {...attrs.summaryKeyPointsContainer}>
        <EventSummaryList {...attrs.summaryKeyPoints} />
      </EventSummaryItem>
      <EventSummaryItem {...attrs.summaryActionItemsContainer}>
        <EventSummaryList {...attrs.summaryActionItems} />
      </EventSummaryItem>
    </div>
  );
});
