import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  duration: number;
  children?: JSX.Element | string;
  globalLoading: boolean;
}

const InfobarsHeaderSkeleton: React.FC<Props> = (props: Props) => {
  const { duration, children, globalLoading } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [globalLoading]); // eslint-disable-line
  return (
    <>
      {isLoading ? (
        <Skeleton
          loading={isLoading}
          active
          avatar={false}
          paragraph={false}
          title={{
            style: {
              width: 154,
              height: 16,
              borderRadius: 30,
              marginTop: 0,
              marginBottom: -15,
            },
          }}
        />
      ) : (
        children
      )}
    </>
  );
};

export default InfobarsHeaderSkeleton;
