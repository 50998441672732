import React, { useEffect, useRef, useState } from 'react';
import { Table as AntTable } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/store';
import { Row } from '../../../../../types';
import { AddIcon } from '../../../../../components/Icons/AddIcon';
import { DeleteIcon } from '../../../../../components/Icons/DeleteIcon';
import { Loader } from '../../../../../components/Loader/Loader';
import { useTable } from './teamsTable.utils';
import { DeleteMembersModal } from '../../deleteMembersModal/DeleteMembersModal';
import { AssignNewLeaderModal } from '../../assignNewLeaderModal/AssignNewLeaderModal';

type TableProps = {
  onAddMember: () => void;
  users: Row[];
  isShown: boolean;
};

export const TeamsTable: React.FC<TableProps> = ({
  onAddMember,
  users,
  isShown,
}): JSX.Element => {
  const {
    cols,
    rowSelection,
    selectedRows,
    selectedTeamMember,
    user,
    openDeleteModal,
    closeDeleteModal,
    closeNewLeaderModal,
    clearSelectedRow,
  } = useTable();

  const { deleteMembersOpen, assignNewLeaderOpen } = useSelector(
    (state: RootState) => state.teams.modals
  );
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const usersCount = useRef(users.length);

  const deleteDisabled =
    !![selectedTeamMember, ...selectedRows].find(
      (u) => u?.userId === user?.userId
    ) ||
    !![selectedTeamMember, ...selectedRows].find(
      (u) => u?.role === 'owner' || u?.role === 'superAdmin'
    );

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (users.length > 1 && users.length - usersCount.current === 1) {
      setShouldAnimate(true);

      timeout = setTimeout(() => {
        setShouldAnimate(false);
      }, 1500);
    }
    usersCount.current = users.length;
    return () => clearTimeout(timeout);
  }, [users]);

  return (
    <>
      {isShown ? (
        <div className="teams-table">
          <DeleteMembersModal
            members={selectedTeamMember ? [selectedTeamMember] : selectedRows}
            open={deleteMembersOpen}
            onClose={closeDeleteModal}
            clearSelectedRow={clearSelectedRow}
          />
          <AssignNewLeaderModal
            open={assignNewLeaderOpen}
            onClose={closeNewLeaderModal}
          />
          <div className="teams-table__controls">
            <div className="teams-table__controls-left">
              {user?.role !== 'dealmaker' ? (
                <AddIcon
                  tooltipText="Add new member"
                  width="150px"
                  onClick={onAddMember}
                />
              ) : null}
              {selectedRows.length ? (
                <div className="teams-table__controls-left__delete-members">
                  <DeleteIcon
                    disabled={deleteDisabled}
                    onClick={openDeleteModal}
                  />
                </div>
              ) : null}
            </div>
            {/* <SearchIcon /> */}
          </div>
          <AntTable
            rowSelection={
              user?.role !== 'dealmaker'
                ? { type: 'checkbox', ...rowSelection }
                : undefined
            }
            columns={cols}
            dataSource={users}
            rowClassName={(record, index) =>
              `${record.invitationSent ? 'disabled' : ''} ${
                shouldAnimate && index === users.length - 1
                  ? 'animated-row'
                  : ''
              }`
            }
            loading={{ indicator: <Loader />, spinning: !users.length }}
            pagination={false}
            scroll={{ y: 340 }}
            tableLayout="auto"
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
