import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CompanyDetails } from './components/companyDetails/CompanyDetails';
import { RelationshipDetailsHeader } from '../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ConfirmationModal } from '../../../../components/SoundWave';
import {
  cancelEditModeChanges,
  resetRelationshipDetails,
} from '../../../../store/relationshipDetails/relationshipDetails.slice';
import { ApplyChangesModal } from './components/ApplyChangesModal/ApplyChangesModal';
import { RsDetailsControlsRow } from './components/rsDetailsControlsRow/RsDetailsControlsRow';
import { RelationshipEvents } from '../relationshipEvents/RelationshipEvents';
import { WarningIcon } from '../../../../components/Icons/WarningIcon';
import {
  getAccountNotes,
  getRelationshipAccountCompanyDetails,
  getRelationshipContacts,
  getRelationshipEvents,
} from '../../../../store/relationshipDetails/relationshipDetails.thunks';
import { groupedContactsSelector } from '../../../../store/relationshipDetails/relationshipDetails.selectors';
import { Notes } from './components/Notes/Notes';
import {
  useGetRelationshipAccountDetails,
  useMixpanel,
  useTrackPageView,
} from '../../../../hooks';
import { TeamMembersSection } from './components/TeamMembersSection/TeamMembersSection';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import { EditModeToolbar } from './components/EditModeToolbar/EditModeToolbar';

export const RelationshipDetails: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.relationshipDetails.account);
  const userOrganization = useAppSelector((state) => state.auth.org);
  const groupedContacts = useAppSelector(groupedContactsSelector);

  const isAccountLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.account
  );

  const isContactsLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.contacts
  );

  const [isCancelChangesModalOpened, setIsCancelChangesModalOpened] = useState(
    false
  );

  const [isApplyChangesModalOpened, setIsApplyChangesModalOpened] = useState(
    false
  );

  const { accountId } = useParams<{ accountId: string }>();

  const location = useLocation();

  const history = useHistory();

  const { trackEvent } = useMixpanel();

  const isEventsTab = location.hash === '#events';

  const prospectSideTeamMembersGroups = useMemo(
    () => [
      {
        title: groupedContacts.prospectSideTeamMembers.length
          ? 'Team Members'
          : '',
        showAddMemberIfEmpty: true,
        grow:
          groupedContacts.userSideTeamMembers.length >=
          groupedContacts.userSideThirdParties.length,
        side: RelationshipMemberSide.PROSPECT_SIDE,
        contacts: groupedContacts.prospectSideTeamMembers,
      },
      ...(groupedContacts.prospectSideThirdParties.length ||
      groupedContacts.userSideThirdParties.length
        ? [
            {
              title: groupedContacts.prospectSideThirdParties.length
                ? 'Associated 3rd Party contacts'
                : '',
              grow:
                groupedContacts.userSideThirdParties.length >=
                groupedContacts.userSideTeamMembers.length,
              contacts: groupedContacts.prospectSideThirdParties,
            },
          ]
        : []),
    ],
    [groupedContacts]
  );

  const userSideTeamMembersGroups = useMemo(
    () => [
      {
        title: groupedContacts.userSideTeamMembers.length ? 'Team Members' : '',
        showAddMemberIfEmpty: true,
        grow:
          groupedContacts.prospectSideTeamMembers.length >=
          groupedContacts.prospectSideThirdParties.length,
        side: RelationshipMemberSide.USER_SIDE,
        contacts: groupedContacts.userSideTeamMembers,
      },
      ...(groupedContacts.prospectSideThirdParties.length ||
      groupedContacts.userSideThirdParties.length
        ? [
            {
              title: groupedContacts.userSideThirdParties.length
                ? 'Associated 3rd Party contacts'
                : '',
              grow:
                groupedContacts.prospectSideThirdParties.length >=
                groupedContacts.prospectSideTeamMembers.length,
              contacts: groupedContacts.userSideThirdParties,
            },
          ]
        : []),
    ],
    [groupedContacts]
  );

  const unassignedTeamMembersGroups = useMemo(
    () => [
      {
        contacts: groupedContacts.unassigned,
      },
    ],
    [groupedContacts]
  );

  useGetRelationshipAccountDetails();
  useTrackPageView();

  useEffect(() => {
    const getAccountCompanyDetailsRequest = dispatch(
      getRelationshipAccountCompanyDetails(accountId)
    );

    const getContactsRequest = dispatch(getRelationshipContacts(accountId));

    const getAccountNotesRequest = dispatch(getAccountNotes(accountId));

    const getEventsRequest = dispatch(getRelationshipEvents(accountId));

    return () => {
      getAccountCompanyDetailsRequest.abort();
      getContactsRequest.abort();
      getAccountNotesRequest.abort();
      getEventsRequest.abort();
      dispatch(resetRelationshipDetails());
    };
  }, [accountId, dispatch]);

  useEffect(() => {
    trackEvent(isEventsTab ? 'selectEventsTab' : 'selectDetailsTab');
  }, [isEventsTab, trackEvent]);

  const attrs = {
    wrapper: {
      className: 'rs-details',
    },

    navigation: {
      wrapper: {
        className: 'rs-details__navigation',
      },

      details: {
        className: `${
          !isEventsTab ? 'active' : ''
        } rs-details__navigation-nav-item`,
        onClick: () => {
          history.push(location.pathname);
        },
      },

      events: {
        className: `${
          isEventsTab ? 'active' : ''
        } rs-details__navigation-nav-item`,
        onClick: () => {
          history.push('#events');
        },
      },
    },

    content: {
      wrapper: { className: 'rs-details__content' },

      companyRowWrapper: {
        className: 'rs-details__content__company',
      },

      teamWrapper: {
        className: 'rs-details__content__team',
      },

      unassignedWrapper: {
        className: 'rs-details__content__unassigned',
      },
    },
    cancelChangesModal: {
      icon: WarningIcon,
      isOpened: isCancelChangesModalOpened,
      onClose: () => {
        setIsCancelChangesModalOpened(false);
      },
      title: 'Discard changes?',
      footerProps: {
        cancelButtonText: 'Back to Edit',
        confirmButtonText: 'Discard',
        isConfirmButtonSecondary: true,
        onConfirm: () => {
          trackEvent('discardEditModeChanges');
          setIsCancelChangesModalOpened(false);
          dispatch(cancelEditModeChanges());
        },
      },
    },
    applyChangesModal: {
      isOpened: isApplyChangesModalOpened,
      onClose: () => {
        setIsApplyChangesModalOpened(false);
      },
    },
    relationshipDetailsHeader: {
      isLoading:
        isAccountLoading || isContactsLoading || !account || !userOrganization,
      sellerAccountDetails: {
        name: userOrganization?.name || '',
        logoSrc: userOrganization?.avatar || '',
        teamMembers: groupedContacts.userSideTeamMembers,
        associatedContacts: groupedContacts.userSideThirdParties,
      },
      buyerAccountDetails: {
        name: account?.name || '',
        logoSrc: account?.avatarSrc || '',
        teamMembers: groupedContacts.prospectSideTeamMembers,
        associatedContacts: groupedContacts.prospectSideThirdParties,
      },
      relationshipStartDate: account?.createdAt,
      // TODO: Update when CRM connection is implemented
      isCrmConnected: false,
      dealStage: '-',
      dealSize: '-',
    },
    divider: {
      className: 'rs-details__divider',
    },
    prospectSideTeamMembersSection: {
      title: account?.name ? `${account.name} Team` : 'Prospect Team',
      groups: prospectSideTeamMembersGroups,
    },
    userSideTeamMembersSection: {
      title: userOrganization?.name
        ? `${userOrganization.name} Team`
        : 'User Team',
      groups: userSideTeamMembersGroups,
    },
    unassignedTeamMembersSection: {
      title: 'Unassigned Contacts',
      groups: unassignedTeamMembersGroups,
    },
    editModeToolbar: {
      openDiscardChangesModal: () => {
        setIsCancelChangesModalOpened(true);
      },
      openApplyChangesModal: () => {
        setIsApplyChangesModalOpened(true);
      },
    },
  };

  const navigation = (
    <div {...attrs.navigation.wrapper}>
      <div {...attrs.navigation.details}>Details</div>
      <div {...attrs.navigation.events}>Events</div>
    </div>
  );

  const conditionalPageContent = !isEventsTab ? (
    <>
      <RsDetailsControlsRow />
      <div {...attrs.content.wrapper}>
        <div {...attrs.content.teamWrapper}>
          <TeamMembersSection {...attrs.prospectSideTeamMembersSection} />
        </div>
        <div {...attrs.content.teamWrapper}>
          <TeamMembersSection {...attrs.userSideTeamMembersSection} />
        </div>
        {groupedContacts.unassigned.length ? (
          <div {...attrs.content.teamWrapper}>
            <TeamMembersSection {...attrs.unassignedTeamMembersSection} />
          </div>
        ) : null}
        <div {...attrs.divider} />
        <div {...attrs.content.teamWrapper}>
          <CompanyDetails />
        </div>
        <div {...attrs.content.teamWrapper}>
          <Notes />
        </div>
      </div>
    </>
  ) : (
    <RelationshipEvents />
  );

  return (
    <div {...attrs.wrapper}>
      <ConfirmationModal {...attrs.cancelChangesModal} />
      <ApplyChangesModal {...attrs.applyChangesModal} />
      <RelationshipDetailsHeader {...attrs.relationshipDetailsHeader} />
      {navigation}
      {conditionalPageContent}
      <EditModeToolbar {...attrs.editModeToolbar} />
    </div>
  );
};
