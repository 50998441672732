import React from 'react';

export const EmailEventIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M7.99995 7H15.9999C17.6568 7 18.9999 8.34315 18.9999 10V14.5C18.9999 15.7095 18.1411 16.7184 16.9999 16.95V12C16.9999 10.3431 15.6568 9 13.9999 9H5.17065C5.58249 7.83481 6.69373 7 7.99995 7Z"
      fill="#646F87"
    />
    <path
      d="M11 4H19C20.6569 4 22 5.34315 22 7V11.5C22 12.7095 21.1411 13.7184 20 13.95V9C20 7.34315 18.6569 6 17 6H8.17071C8.58254 4.83481 9.69378 4 11 4Z"
      fill="#646F87"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 10C3.34315 10 2 11.3431 2 13V18C2 19.6569 3.34315 21 5 21H13C14.6569 21 16 19.6569 16 18V13C16 11.3431 14.6569 10 13 10H5ZM5.44721 13.1056C4.95324 12.8586 4.35256 13.0588 4.10557 13.5528C3.85858 14.0468 4.05881 14.6474 4.55279 14.8944L7.65836 16.4472C8.50294 16.8695 9.49706 16.8695 10.3416 16.4472L13.4472 14.8944C13.9412 14.6474 14.1414 14.0468 13.8944 13.5528C13.6474 13.0588 13.0468 12.8586 12.5528 13.1056L9.44721 14.6584C9.16569 14.7991 8.83431 14.7991 8.55279 14.6584L5.44721 13.1056Z"
      fill="#646F87"
    />
  </svg>
);
