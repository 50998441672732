import React, { useMemo, useState } from 'react';
import { useGetAdminSettingsData, useTrackPageView } from '../../hooks';
import { toggleApp } from '../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  clearWebhookData,
  getWebhookEvents,
  getWebhookSettings,
} from '../../store/org/org.actions';
import { App } from './components/App/App';
import { ConnectCrmModal } from './components/ConnectCrmModal/ConnectCrmModal';
import { ToggleFeatureModal } from './components/ToggleFeatureModal/ToggleFeatureModal';
import { UpgradeModal } from './components/UpgradeModal/UpgradeModal';
import { WebhooksForm } from './components/WebhooksForm/WebhooksForm';
import { WebhookSettingsIcon } from './icons/WebhookSettingsIcon';
import { WebhooksWarningIcon } from './icons/WebhooksWarningIcon';
import { Features } from '../../types';

export const Apps: React.FC = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);
  const webhook = useAppSelector((state) => state.org.webhook);

  const [isWebhooksFormOpened, setIsWebhooksFormOpened] = useState(false);

  const [selectedFeatureToUpgrade, setSelectedFeatureToUpgrade] = useState<
    string | null
  >(null);

  const [toggleModalState, setToggleModalState] = useState<{
    isOpened: boolean;
    feature: string | null;
    isFeatureActive: boolean;
  }>({
    isOpened: false,
    feature: null,
    isFeatureActive: false,
  });

  const [isConnectCrmModalOpened, setIsConnectCrmModalOpened] = useState(false);

  const isEmailAppInPackage = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.EMAIL_ANALYZER
      )?.isInPackage,
    [user]
  );

  const isTemperAppInPackage = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.PERSON_ANALYSIS
      )?.isInPackage,
    [user]
  );

  const isAssistantAppInPackage = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.SOUNDWAVE
      )?.isInPackage,
    [user]
  );

  const {
    isInPackage: isWebhooksAppInPackage,
    enabled: isWebhooksAppEnabled,
  } = useMemo(
    () =>
      user?.metadata?.features?.find(
        (feature) => feature.name === Features.WEBHOOKS
      ) || { isInPackage: false, enabled: false },
    [user]
  );

  const isAdmin = !!user && user.role !== 'dealmaker';

  const isWebhookError =
    webhook.status.updateFailed ||
    webhook.status.connectionLost ||
    webhook.settings.isDisabledByError;

  const getWebhooksAppButtonLabel = () => {
    if (isWebhooksAppInPackage) {
      return isAdmin ? 'Settings' : 'Active';
    }

    return 'Upgrade';
  };

  const attrs = {
    container: {
      className: 'apps',
    },
    upgradeModal: {
      feature: selectedFeatureToUpgrade,
      onClose: () => {
        setSelectedFeatureToUpgrade(null);
      },
    },
    connectCrmModal: {
      isOpened: isConnectCrmModalOpened,
      onClose: () => {
        setIsConnectCrmModalOpened(false);
      },
    },
    toggleFeatureModal: {
      isOpened: toggleModalState.isOpened,
      feature: toggleModalState.feature,
      isFeatureActive: toggleModalState.isFeatureActive,
      onClose: () => {
        setToggleModalState((prevState) => ({
          ...prevState,
          isOpened: false,
        }));
      },
      onAnimationEnd: () => {
        setToggleModalState((prevState) => ({
          ...prevState,
          feature: null,
          isFeatureActive: false,
        }));
      },
      onConfirm: async () => {
        if (user && toggleModalState.feature) {
          await dispatch(
            toggleApp({
              active: !toggleModalState.isFeatureActive,
              appName: toggleModalState.feature,
            })
          ).unwrap();

          if (
            !toggleModalState.isFeatureActive &&
            toggleModalState.feature === Features.WEBHOOKS
          ) {
            dispatch(clearWebhookData());
            dispatch(getWebhookEvents(user.orgId, user.userId));
            dispatch(getWebhookSettings(user.orgId));
          }
        }
      },
    },
    emailApp: {
      title: 'Q for Email',
      text:
        'Analyze your email communication with prospects and learn how to maximize your advantage.',
      imageSrcSet: [
        { src: '/assets/apps/email.webp', type: 'image/webp' },
        { src: '/assets/apps/email.avif', type: 'image/avif' },
        { src: '/assets/apps/email.png', type: 'image/png' },
      ],
      linkProps: {
        to:
          'https://chrome.google.com/webstore/detail/substratas-q-for-email/okeemaalpjcfeggfnahbeoagboahbbka',
        label: 'Chrome Web Store',
      },
      buttonProps: {
        label: isEmailAppInPackage ? 'Active' : 'Upgrade',
        disabled: isEmailAppInPackage || !isAdmin,
        active: isEmailAppInPackage,
        tooltip:
          !isEmailAppInPackage && !isAdmin
            ? 'Contact your administrator to upgrade'
            : '',
        onClick: () => {
          if (!isEmailAppInPackage && isAdmin) {
            setSelectedFeatureToUpgrade(Features.EMAIL_ANALYZER);
          }
        },
      },
    },
    temperApp: {
      title: 'Temper',
      text:
        'Identify the natural personality traits and tendencies of your prospects to better understand how they tend to behave, communicate, and make decisions.',
      imageSrcSet: [
        { src: '/assets/apps/temper.webp', type: 'image/webp' },
        { src: '/assets/apps/temper.avif', type: 'image/avif' },
        { src: '/assets/apps/temper.png', type: 'image/png' },
      ],
      buttonProps: {
        label: isTemperAppInPackage ? 'Active' : 'Upgrade',
        disabled: isTemperAppInPackage || !isAdmin,
        active: isTemperAppInPackage,
        tooltip:
          !isTemperAppInPackage && !isAdmin
            ? 'Contact your administrator to upgrade'
            : '',
        onClick: () => {
          if (!isTemperAppInPackage && isAdmin) {
            setSelectedFeatureToUpgrade(Features.PERSON_ANALYSIS);
          }
        },
      },
    },
    assistantApp: {
      title: 'AI Meeting Assistant',
      text:
        "Analyze the verbal and nonverbal aspects of every deal-related online meeting to understand what's really going on. Current availability: Zoom, Google Meet, MS Teams.",
      imageSrcSet: [
        { src: '/assets/apps/assistant.webp', type: 'image/webp' },
        { src: '/assets/apps/assistant.avif', type: 'image/avif' },
        { src: '/assets/apps/assistant.png', type: 'image/png' },
      ],
      buttonProps: {
        label: isAssistantAppInPackage ? 'Active' : 'Upgrade',
        disabled: isAssistantAppInPackage || !isAdmin,
        active: isAssistantAppInPackage,
        tooltip:
          !isAssistantAppInPackage && !isAdmin
            ? 'Contact your administrator to upgrade'
            : '',
        onClick: () => {
          if (!isAssistantAppInPackage && isAdmin) {
            setSelectedFeatureToUpgrade(Features.SOUNDWAVE);
          }
        },
      },
    },
    webhooksApp: {
      title: 'Webhooks',
      text:
        'Create webhooks for trigger events to easily connect Substrata to your CRM, email and more.',
      imageSrcSet: [
        { src: '/assets/apps/webhooks.webp', type: 'image/webp' },
        { src: '/assets/apps/webhooks.avif', type: 'image/avif' },
        { src: '/assets/apps/webhooks.png', type: 'image/png' },
      ],
      iconProps:
        isWebhooksAppInPackage && isAdmin && isWebhookError
          ? {
              component: WebhooksWarningIcon,
              tooltip: (
                <>
                  Connection{' '}
                  {webhook.settings.isDisabledByError ? 'lost' : 'failed'}.
                  <br />
                  Open &apos;Settings&apos; for more details.
                </>
              ),
            }
          : null,
      buttonProps: {
        label: getWebhooksAppButtonLabel(),
        outlined: isWebhooksAppInPackage && isAdmin,
        active: isWebhooksAppInPackage && !isAdmin,
        disabled: !isAdmin,
        icon: isWebhooksAppInPackage && isAdmin ? WebhookSettingsIcon : null,
        className: 'apps__webhook-settings-button',
        onClick: () => {
          if (isAdmin && isWebhooksAppInPackage) {
            setIsWebhooksFormOpened(!isWebhooksFormOpened);
          } else if (isAdmin) {
            setSelectedFeatureToUpgrade(Features.WEBHOOKS);
          }
        },
      },
      toggleProps:
        isWebhooksAppInPackage && isAdmin
          ? {
              isActive: isWebhooksAppEnabled,
              onToggle: (isFeatureActive: boolean) => {
                setToggleModalState({
                  feature: Features.WEBHOOKS,
                  isFeatureActive,
                  isOpened: true,
                });
              },
            }
          : null,
      renderAdditionalContent: () => {
        const props = {
          isOpen: isWebhooksFormOpened,
          isWebhookFeatureActive: isWebhooksAppEnabled,
        };

        return <WebhooksForm {...props} />;
      },
    },
    crmApp: {
      title: 'CRM Integration',
      text:
        'See the effect your authority has on your sales results, focusing on win-rate & sales-cycle length.',
      imageSrcSet: [
        { src: '/assets/apps/crm.webp', type: 'image/webp' },
        { src: '/assets/apps/crm.avif', type: 'image/avif' },
        { src: '/assets/apps/crm.png', type: 'image/png' },
      ],
      buttonProps: {
        label: 'Contact Us',
        disabled: !isAdmin,
        tooltip: !isAdmin
          ? 'Contact your administrator to connect your CRM'
          : '',
        onClick: () => {
          if (isAdmin) {
            setIsConnectCrmModalOpened(true);
          }
        },
      },
    },
    suggestFeatureButton: {
      type: 'button' as const,
      className: 'apps__suggest-feature-button app__button app__button--m',
      onClick: () => {
        window.open('https://feedback.substrata.me', '_blank');
      },
    },
  };

  useGetAdminSettingsData();
  useTrackPageView();

  return (
    <div {...attrs.container}>
      <UpgradeModal {...attrs.upgradeModal} />
      <ConnectCrmModal {...attrs.connectCrmModal} />
      <ToggleFeatureModal {...attrs.toggleFeatureModal} />
      <App {...attrs.emailApp} />
      <App {...attrs.temperApp} />
      <App {...attrs.assistantApp} />
      <App {...attrs.webhooksApp} />
      <App {...attrs.crmApp} />
      <button {...attrs.suggestFeatureButton}>
        <span>Suggest a Feature</span>
      </button>
    </div>
  );
};
