import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  GetAnalyticsData,
  getDataCalledFirstTime,
} from '../../store/analytics/analytics.actions';
import { User } from '../../types';
import { FirstColumn } from './components/firstColumn/FirstColumn';
import { SecondColumn } from './components/secondColumn/SecondColumn';
import { ThirdColumn } from './components/thirdColumn/ThirdColumn';
// import { ArrowToLoadPersonalData } from './components/arrowToLoadData/ArrowToLoadData';

export const Analytics: React.FC = (): JSX.Element => {
  const { user, org } = useSelector((state: RootState) => state.auth);
  const {
    stats,
    measurements,
    isLoading,
    noData,
    error,
    isGetDataCalledFirstTime,
  } = useSelector((state: RootState) => state.analytics);
  const { orgId, userId } = user as User;

  const dispatch = useDispatch();

  useEffect(() => {
    const fromDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
    const toDate = moment().format('YYYY-MM-DD');
    dispatch(GetAnalyticsData(orgId, userId, fromDate, toDate));
    dispatch(getDataCalledFirstTime());
  }, []); // eslint-disable-line

  const attrs = {
    temporaryMainWrapper: { className: 'temporary-wrapper' },
    analyticsWrapper: { className: 'personalQ' },
    dateSelectWrapper: {
      className: 'personalQ-date__select',
      style: { marginTop: '-18px' },
    },
    select: { user, noData, isGetDataCalledFirstTime, stats, error },
    firstColumn: { user, org, stats, isLoading },
    secondColumn: { isLoading, user, measurements, noData },
    thirdColumn: {
      user,
      stats,
      isLoading,
      isGetDataCalledFirstTime,
      noData,
      error,
    },
  };

  return (
    <div {...attrs.temporaryMainWrapper}>
      <div {...attrs.dateSelectWrapper}>
        {/* <ArrowToLoadPersonalData {...attrs.select} /> */}
      </div>
      <div {...attrs.analyticsWrapper}>
        <FirstColumn {...attrs.firstColumn} />
        <SecondColumn {...attrs.secondColumn} />
        <ThirdColumn {...attrs.thirdColumn} />
      </div>
    </div>
  );
};
