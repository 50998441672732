import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Row } from '../../../../types';
import { Button, Modal } from '../../../../components';
import {
  invokeDeleteMebersModal,
  updateTeamMembers,
} from '../../../../store/teams/teams.actions';

type DeleteUserModalProps = {
  members: Row[];
  onClose: () => void;
  open: boolean;
  clearSelectedRow: () => void;
};

export const DeleteMembersModal: React.FC<DeleteUserModalProps> = ({
  members,
  onClose,
  open,
  clearSelectedRow,
}: DeleteUserModalProps): JSX.Element => {
  const { currentTeam } = useSelector((state: RootState) => state.teams);
  const { user } = useSelector((state: RootState) => state.auth);

  const [deleted, setDeleted] = useState(false);
  const dispatch = useDispatch();

  const membersNames = members.map((el: Row) => el.name);

  const handleDeleteAndClose = () => {
    setDeleted(true);
    if (user && currentTeam && members) {
      const membersToDelete = members.map((el) => ({
        userId: el.userId,
        teamMemberRole: el.teamMemberRole as 'member' | 'teamLeader',
      }));
      dispatch(
        updateTeamMembers(
          user.orgId,
          user.userId,
          currentTeam.teamId,
          membersToDelete,
          'deleteMembers'
        )
      );
    }
  };

  const close = () => {
    onClose();
    clearSelectedRow();
    dispatch(invokeDeleteMebersModal(false));
  };

  useEffect(() => {
    if (!open) setDeleted(false);
  }, [open]);

  const conditionalChild = deleted ? (
    <div className="deleted delete-modal-body">
      <span className="delete-modal-body__bold">{membersNames.join(', ')}</span>{' '}
      {membersNames.length === 1 ? ' was ' : ' were '}
      <span className="delete-modal-body__bold">removed </span>
      from the team
      <Button
        onClick={close}
        variant="common"
        className="delete-modal-body__btn"
      >
        Got it
      </Button>
    </div>
  ) : (
    <div className="delete-modal-body">
      Are you sure you want to delete{' '}
      <span className="delete-modal-body__bold">
        {membersNames.join(', ')}?
      </span>
      <Button
        className="delete-modal-body__btn"
        onClick={handleDeleteAndClose}
        variant="common"
      >
        Got it
      </Button>
      <Button
        onClick={onClose}
        className="delete-modal-body__btn"
        variant="text"
      >
        Cancel
      </Button>
    </div>
  );

  return (
    <Modal open={open} onClose={close} title="Delete member">
      {conditionalChild}
    </Modal>
  );
};
