import React from 'react';
import { Skeleton } from 'antd';
import {
  Avatar,
  AvatarProps,
  AvatarSize,
} from '../SoundWave/components/Avatar/Avatar';
import { Tooltip } from '../SoundWave';
import { TooltipPosition } from '../SoundWave/components/Tooltip/Tooltip';

export enum EventAnalysisSummaryItemType {
  TEXT = 'text',
  AVATAR = 'avatar',
  AVATARS_GROUP = 'avatarGroup',
}

export interface EventAnalysisSummaryItem {
  title: string;
  value: string | AvatarProps | AvatarProps[];
  icon?: React.ReactNode;
  tooltip?: string;
  contentTooltip?: React.ReactNode;
  className?: string;
}

export interface EventAnalysisSummaryProps {
  items: EventAnalysisSummaryItem[];
  isLoading?: boolean;
}

export const EventAnalysisSummary: React.FC<EventAnalysisSummaryProps> = ({
  items,
  isLoading = false,
}) => {
  const attrs = {
    container: {
      className: 'event-analysis-summary',
    },
    itemTitle: {
      className: 'event-analysis-summary__title',
    },
    itemValue: {
      className: 'event-analysis-summary__value',
    },
    itemValueWithTooltip: {
      className:
        'event-analysis-summary__value event-analysis-summary__value--with-tooltip',
    },
    avatarsGroup: {
      className: 'event-analysis-summary__avatars-group',
    },
    avatarsGroupCounter: {
      className: 'event-analysis-summary__avatars-group-counter',
    },
    avatar: {
      size: AvatarSize.XS,
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      loading: isLoading,
      title: {
        width: 120,
        style: {
          width: 100,
          height: 24,
          borderRadius: 4,
          margin: 0,
        },
      },
    },
    avatarSkeleton: {
      active: true,
      paragraph: false,
      title: false,
      loading: isLoading,
      avatar: {
        shape: 'circle' as const,
        size: 24,
      },
      style: {
        width: 'auto',
      },
    },
  };

  const renderAvatars = (avatars: AvatarProps[]) => {
    if (!avatars.length) {
      return null;
    }

    const avatarsToRender = avatars.length > 3 ? avatars.slice(0, 3) : avatars;
    const diff = avatars.length - avatarsToRender.length;

    return (
      <>
        {avatarsToRender.map((avatar, index) => (
          <Avatar {...attrs.avatar} {...avatar} key={index} />
        ))}
        {diff ? <span {...attrs.avatarsGroupCounter}>+{diff}</span> : null}
      </>
    );
  };

  const renderItem = (
    {
      title,
      value,
      icon,
      tooltip,
      contentTooltip,
      className,
    }: EventAnalysisSummaryItem,
    index: number
  ) => {
    const isLastItem = index === items.length - 1;

    const props = {
      item: {
        key: index,
        className: `event-analysis-summary__item${
          className ? ` ${className}` : ''
        }`,
      },
      tooltip: {
        text: tooltip,
        width: 256,
        position: isLastItem ? TooltipPosition.TOP_END : TooltipPosition.TOP,
      },
      contentTooltip: {
        text: contentTooltip,
        contentClassName: 'event-analysis-summary__content-tooltip',
        position: isLastItem ? TooltipPosition.TOP_END : TooltipPosition.TOP,
        positionOffset: 20,
      },
    };

    if (typeof value === 'string') {
      return (
        <div {...props.item}>
          <div {...attrs.itemTitle}>
            {title}
            {tooltip ? <Tooltip {...props.tooltip} /> : null}
          </div>
          {contentTooltip ? (
            <Tooltip {...props.contentTooltip}>
              <div {...attrs.itemValueWithTooltip}>
                {!isLoading ? (
                  <>
                    {icon}
                    {value}
                  </>
                ) : null}
                <Skeleton {...attrs.textSkeleton} />
              </div>
            </Tooltip>
          ) : (
            <div {...attrs.itemValue}>
              {!isLoading ? (
                <>
                  {icon}
                  {value}
                </>
              ) : null}
              <Skeleton {...attrs.textSkeleton} />
            </div>
          )}
        </div>
      );
    }

    if (Array.isArray(value)) {
      const avatarsGroupProps =
        !isLoading && value.length === 1 ? attrs.itemValue : attrs.avatarsGroup;

      return (
        <div {...props.item}>
          <div {...attrs.itemTitle}>
            {title}
            {tooltip ? <Tooltip {...props.tooltip} /> : null}
          </div>
          {contentTooltip ? (
            <Tooltip {...props.contentTooltip}>
              <div {...avatarsGroupProps}>
                {!isLoading ? renderAvatars(value) : null}
                <Skeleton {...attrs.avatarSkeleton} />
                <Skeleton {...attrs.avatarSkeleton} />
                <Skeleton {...attrs.avatarSkeleton} />
              </div>
            </Tooltip>
          ) : (
            <div {...avatarsGroupProps}>
              {!isLoading ? renderAvatars(value) : null}
              <Skeleton {...attrs.avatarSkeleton} />
              <Skeleton {...attrs.avatarSkeleton} />
              <Skeleton {...attrs.avatarSkeleton} />
            </div>
          )}
        </div>
      );
    }

    return (
      <div {...props.item}>
        <div {...attrs.itemTitle}>
          {title}
          {tooltip ? <Tooltip {...props.tooltip} /> : null}
        </div>
        {contentTooltip ? (
          <Tooltip {...props.contentTooltip}>
            <div {...attrs.itemValueWithTooltip}>
              {!isLoading ? (
                <>
                  {renderAvatars([value])}
                  <span>{value.name}</span>
                </>
              ) : null}
              <Skeleton {...attrs.avatarSkeleton} />
              <Skeleton {...attrs.textSkeleton} />
            </div>
          </Tooltip>
        ) : (
          <div {...attrs.itemValue}>
            {!isLoading ? (
              <>
                {renderAvatars([value])}
                <span>{value.name}</span>
              </>
            ) : null}
            <Skeleton {...attrs.avatarSkeleton} />
            <Skeleton {...attrs.textSkeleton} />
          </div>
        )}
      </div>
    );
  };

  return <div {...attrs.container}>{items.map(renderItem)}</div>;
};
