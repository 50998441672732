import { useFormik, FormikErrors, FormikTouched } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { RootState } from '../../../../store/store';
import { createTeam } from '../../../../store/teams/teams.actions';
import { Row, User } from '../../../../types';

export type Member = {
  userId: string;
  teamMemberRole: 'teamLeader' | 'member' | 'remove';
  name?: string;
  email?: string;
  isDeleted?: boolean;
};

interface AddTeamForm {
  teamName: string;
  members: Member[];
}

interface UseForm {
  values: AddTeamForm;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  errors: FormikErrors<AddTeamForm>;
  touched: FormikTouched<AddTeamForm>;
  closeModal: () => void;
  handleCancel: () => void;
  users: Row[];
  user: User | null;
  members?: Member[];
}

export const useAddTeamForm = (
  onClose: () => void,
  croppedImage: string,
  arrayOfMembers?: Member[]
): UseForm => {
  const { user } = useSelector((state: RootState) => state.auth);
  const { users } = useSelector((state: RootState) => state.users);

  const dispatch = useDispatch();

  const [members, setMembers] = useState<Member[]>();

  useEffect(() => {
    if (arrayOfMembers?.length) {
      const validArrayOfMembers = arrayOfMembers.map((el) => ({
        userId: el.userId,
        teamMemberRole: el.teamMemberRole,
      }));
      setMembers(validArrayOfMembers);
    }
  }, [arrayOfMembers]);

  const handleSubmit = (values: AddTeamForm) => {
    if (user && members?.length && values.teamName) {
      dispatch(
        createTeam(user.orgId, user.userId, {
          name: values.teamName,
          members,
          avatar: croppedImage || undefined,
        })
      );
    }
  };

  const validationSchema = Yup.object().shape({
    teamName: Yup.string().required('*Required field'),
  });

  const formik = useFormik<AddTeamForm>({
    initialValues: {
      teamName: '',
      members: [],
    },
    onSubmit: handleSubmit,
    validationSchema,
  });

  const handleCancel = useCallback((): void => {
    formik.resetForm();
    onClose();
    setMembers([]);
  }, [dispatch, onClose]); //eslint-disable-line

  const closeModal = () => {
    onClose();
    formik.resetForm();
  };

  return {
    ...formik,
    closeModal,
    handleCancel,
    user,
    users,
    members,
  };
};
