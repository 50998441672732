import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTrackPageView } from '../../hooks';
import { RootState } from '../../store/store';
import { TeamActivity } from './components/Activity/TeamActivity';
import { TeamAnalytics } from './components/Analytics/TeamAnalytics';
import { TeamQDateAndTeamSelectors } from './components/dateAndTeamSelector/DateAndTeamSelectors';
import { TeamOverview } from './components/Overview/TeamOverview';

export const TeamQ: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  const { pathname } = useLocation();

  const history = useHistory();

  const dispatch = useDispatch();

  useTrackPageView();

  const attrs = {
    wrapper: {
      className: 'team-q',
    },
    header: {
      className: 'team-q__header',
    },
    headerTitle: {
      className: 'team-q__header-title',
    },
  };

  useEffect(() => {
    if (
      user &&
      user?.role !== 'superAdmin' &&
      user?.role !== 'admin' &&
      user?.role !== 'owner'
    ) {
      history.push(`/orgs/${user.orgId}/users/${user.userId}/deals`);
    } else if (user && pathname.includes(':page')) {
      history.push(
        `/orgs/${user.orgId}/users/${user.userId}/team-analytics/overview`
      );
    }
  }, [user, dispatch, pathname, history]);

  const defineCurrentComponent = () => {
    let current = <TeamOverview />;

    if (pathname.includes('/team-analytics/activity')) {
      current = <TeamActivity />;
    } else if (pathname.includes('/team-analytics/analytics')) {
      current = <TeamAnalytics />;
    }

    return current;
  };

  const getCurrentTitle = () => {
    if (pathname.includes('/team-analytics/analytics')) {
      return 'Team Analytics';
    }

    return 'Team Activity';
  };

  const currentComponent = defineCurrentComponent();
  const title = getCurrentTitle();

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header}>
        {title ? <div {...attrs.headerTitle}>{title}</div> : null}
        <TeamQDateAndTeamSelectors />
      </div>
      {currentComponent}
    </div>
  );
};
