import React from 'react';

export const LockIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.66675C12.3181 2.66675 9.33333 5.65152 9.33333 9.33342V10.6667H8C5.79086 10.6667 4 12.4576 4 14.6667V25.3334C4 27.5426 5.79086 29.3334 8 29.3334H24C26.2091 29.3334 28 27.5426 28 25.3334V14.6667C28 12.4576 26.2091 10.6667 24 10.6667H22.6667V9.33342C22.6667 5.65152 19.6819 2.66675 16 2.66675ZM20 10.6667V9.33342C20 7.12428 18.2091 5.33341 16 5.33341C13.7909 5.33341 12 7.12428 12 9.33342V10.6667H20ZM13.3333 20.0001C13.3333 18.5273 14.5272 17.3334 16 17.3334C17.4728 17.3334 18.6667 18.5273 18.6667 20.0001C18.6667 21.4728 17.4728 22.6667 16 22.6667C14.5272 22.6667 13.3333 21.4728 13.3333 20.0001Z"
      fill="#646F87"
    />
  </svg>
);
