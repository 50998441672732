const MULTIPLIER = 50 / 1.5;

export const getRangeItemStyles = (
  sentimentValue: number
): {
  negativeRangePart: { width: string };
  positiveRangePart: { width: string };
} => {
  const width = `${Math.abs(sentimentValue) * MULTIPLIER}%`;
  const negativeRangePartWidth = sentimentValue > 0 ? '0px' : width;
  const positiveRangePartWidth = sentimentValue > 0 ? width : '0px';

  if (sentimentValue === 0) {
    return {
      negativeRangePart: {
        width: '8px',
      },
      positiveRangePart: {
        width: '8px',
      },
    };
  }

  return {
    negativeRangePart: {
      width: negativeRangePartWidth,
    },
    positiveRangePart: {
      width: positiveRangePartWidth,
    },
  };
};
