import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  isLoading?: boolean;
  children?: JSX.Element | string;
  isTeamQ?: boolean;
}

export const AvgResponseSkeleton: React.FC<Props> = (props: Props) => {
  const { isLoading, children, isTeamQ } = props;

  const attrs = {
    wrapper: { className: `${isTeamQ ? 'team-q-chart' : ''} chart-wrapper` },
    titleWrapper: { className: 'chart-wrapper__title-block' },
    subtitleWrapper: {
      className: `${isTeamQ ? 'no-border ' : ''}chart-wrapper__subtitle`,
      style: { marginBottom: 0 },
    },
    title: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 120,
          maxWidth: '40%',
          height: 20,
          borderRadius: 30,
          marginTop: 0,
        },
      },
    },
    subTitle: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 42,
          maxWidth: '15%',
          height: 13,
          borderRadius: 30,
          marginTop: -5,
        },
      },
    },
    chartHeaderLegend: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 165,
          maxWidth: '50%',
          height: 13,
          borderRadius: 30,
          marginTop: 8,
        },
      },
    },
    chartWrapper: { className: 'qactivity-chart' },
    firstColumn: { className: 'qactivity-chart__first-column' },
    yTicks: {
      isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 20,
          height: 12,
          borderRadius: 30,
          marginTop: 15,
        },
      },
    },
    secondColumn: { className: 'qactivity-chart__second-column' },
    chart: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: '100%',
          maxWidth: '100%',
          height: 174,
          borderRadius: 20,
          marginTop: 15,
        },
      },
    },
    xAxisWrapper: { className: 'qactivity-chart__second-column__x-axis' },
    xTicks: {
      isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 20,
          height: 12,
          borderRadius: 30,
          margin: '2px 0px 0px 5px',
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div {...attrs.wrapper}>
          <div {...attrs.titleWrapper}>
            <Skeleton {...attrs.title} />
          </div>
          <div {...attrs.subtitleWrapper}>
            <Skeleton {...attrs.subTitle} />
            <Skeleton {...attrs.chartHeaderLegend} />
          </div>
          <div {...attrs.chartWrapper}>
            <div {...attrs.firstColumn}>
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
            </div>
            <div {...attrs.secondColumn}>
              <Skeleton {...attrs.chart} />
              <div {...attrs.xAxisWrapper}>
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
