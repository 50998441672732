import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { EmailBranchWithContactsInfo } from '../../types/emailAnalysis.types';
import { RootState } from '../store';
import { getEmailWithContactsInfo } from './threadDetails.utils';

export const selectedEmailWithContactsInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.threadDetails.selectedEmail,
  (state: RootState) => state.threadDetails.contacts,
  (selectedEmail, contacts) => {
    if (!selectedEmail) {
      return null;
    }

    return getEmailWithContactsInfo(selectedEmail, contacts);
  }
);

export const selectedBranchWithContactsInfoSelector = createDraftSafeSelector(
  (state: RootState) => state.threadDetails.selectedBranch,
  (state: RootState) => state.threadDetails.contacts,
  (selectedBranch, contacts) => {
    if (!selectedBranch) {
      return null;
    }

    const branchWithContactsInfo: EmailBranchWithContactsInfo = {
      ...selectedBranch,
      emails: selectedBranch.emails.map((email) =>
        getEmailWithContactsInfo(email, contacts)
      ),
    };

    return branchWithContactsInfo;
  }
);
