import {
  ArrPeople,
  Measurements,
  Stats,
} from '../../../../store/analytics/analytics.types';

type RelationsArray = {
  title: string;
  loading: boolean;
  people?: ArrPeople;
}[];

type InfoBarsArray = {
  title: string;
  min: number;
  max: number;
  current: string | number | undefined;
  toolTip: string;
  animationTime: number;
  sketWidth: number;
  isLoading: boolean;
}[];

export const createRelationsArray = (
  stats: Stats | null,
  isLoading: boolean
): RelationsArray => [
  {
    title: 'Most active relationships',
    loading: isLoading,
    people: stats?.mostActiveParticipants,
  },
  {
    title: 'Most competent with',
    loading: isLoading,
    people: stats?.mostCompetentVs,
  },
  {
    title: 'Least competent with',
    loading: isLoading,
    people: stats?.leastCompetentVs,
  },
];

export const createInfoBarsArray = (
  measurements: Measurements | null,
  animationDuration: number,
  isLoading: boolean
): InfoBarsArray => [
  {
    title: 'empathy',
    min: -1.75,
    max: 1.5,
    current: measurements?.empathy,
    toolTip: `Empathy allows you to walk a mile in your client's 
    shoes and understand their pain points and motivations.
    Being too empathetic may cause you to lose the advantage,
    while not showing enough empathy may come across as being indifferent.`,
    animationTime: animationDuration,
    sketWidth: 70,
    isLoading,
  },
  {
    title: 'responsiveness',
    min: -1.3,
    max: 1,
    current: measurements?.responsiveness,
    toolTip: `Your average response time to your clients. 
    This may impact the way they perceive you.
    Responding too quickly may signal overenthusiasm,
    while responding too slowly may signal a lack of motivation.`,
    animationTime: animationDuration,
    sketWidth: 115,
    isLoading,
  },
  {
    title: 'resilience',
    min: 0,
    max: 2,
    current: measurements?.resilience,
    toolTip: `Your resilience level is based on the successful 
    implementation of your 'Next Move' suggestions across all
    of your interactions. On one hand, you do not want to be perceived
    as being too flexible. On the other hand,
    you do not want to be perceived as being too rigid.`,
    animationTime: animationDuration,
    sketWidth: 81,
    isLoading,
  },
  {
    title: 'intuition',
    min: 0,
    max: 2,
    current: measurements?.intuition,
    toolTip: `Your intuition level is based on your overall performance 
    when no suggestions were presented.`,
    animationTime: animationDuration,
    sketWidth: 72,
    isLoading,
  },
  {
    title: 'subtleness',
    min: 0,
    max: 1,
    current: measurements?.subtleness,
    toolTip: 'Coming soon',
    animationTime: animationDuration,
    sketWidth: 88,
    isLoading,
  },
];
