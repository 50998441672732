import React from 'react';

export const DeactivateIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99967 1.33301C4.31778 1.33301 1.33301 4.31778 1.33301 7.99967C1.33301 11.6816 4.31778 14.6663 7.99967 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99967C14.6663 4.31778 11.6816 1.33301 7.99967 1.33301ZM2.66634 7.99967C2.66634 5.05416 5.05416 2.66634 7.99967 2.66634C9.23215 2.66634 10.367 3.0844 11.2701 3.78644L3.78644 11.2701C3.0844 10.367 2.66634 9.23215 2.66634 7.99967ZM4.72925 12.2129C5.63237 12.915 6.7672 13.333 7.99967 13.333C10.9452 13.333 13.333 10.9452 13.333 7.99967C13.333 6.7672 12.915 5.63237 12.2129 4.72925L4.72925 12.2129Z"
      fill="#F0384A"
    />
  </svg>
);
