import React from 'react';

export const AssignIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M4.19494 7.80474C4.45529 8.06509 4.8774 8.06509 5.13775 7.80474C5.3981 7.54439 5.3981 7.12228 5.13775 6.86193L3.60915 5.33333L12.6663 5.33333C13.0345 5.33333 13.333 5.03486 13.333 4.66667C13.333 4.29848 13.0345 4 12.6663 4L3.60915 4L5.13775 2.4714C5.3981 2.21105 5.3981 1.78895 5.13775 1.5286C4.8774 1.26825 4.45529 1.26825 4.19494 1.5286L1.52827 4.19526C1.26792 4.45561 1.26792 4.87772 1.52827 5.13807L4.19494 7.80474Z"
      fill="white"
    />
    <path
      d="M3.33366 10.667C2.96547 10.667 2.66699 10.9655 2.66699 11.3337C2.66699 11.7018 2.96547 12.0003 3.33366 12.0003L12.3909 12.0003L10.8623 13.5289C10.6019 13.7893 10.6019 14.2114 10.8623 14.4717C11.1226 14.7321 11.5447 14.7321 11.8051 14.4717L14.4717 11.8051C14.7321 11.5447 14.7321 11.1226 14.4717 10.8623L11.8051 8.19559C11.5447 7.93524 11.1226 7.93524 10.8623 8.19559C10.6019 8.45594 10.6019 8.87805 10.8623 9.1384L12.3909 10.667L3.33366 10.667Z"
      fill="#95A1B6"
    />
  </svg>
);
