import React from 'react';

export const RewindIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.05197 2.66659C10.9633 2.66659 13.3333 5.04891 13.3333 7.99992C13.3333 10.9509 10.9633 13.3333 8.05197 13.3333C6.28437 13.3333 4.71768 12.4568 3.7573 11.1047C3.54408 10.8046 3.1279 10.7341 2.82773 10.9473C2.52756 11.1605 2.45708 11.5767 2.6703 11.8769C3.86823 13.5633 5.83136 14.6666 8.05197 14.6666C11.7106 14.6666 14.6666 11.6763 14.6666 7.99992C14.6666 4.32351 11.7106 1.33325 8.05197 1.33325C6.27701 1.33325 4.66657 2.03813 3.4799 3.18227L3.30001 2.63432C3.18516 2.2845 2.80848 2.09401 2.45866 2.20886C2.10884 2.3237 1.91835 2.70039 2.0332 3.05021L2.5804 4.71698C2.65888 4.95603 2.86526 5.13054 3.11404 5.16819C3.36281 5.20584 3.6116 5.10022 3.7573 4.8951C4.71768 3.54309 6.28437 2.66659 8.05197 2.66659Z"
      fill="white"
    />
    <path
      d="M7.97329 5.78993C8.06378 5.51728 8.31874 5.33325 8.60602 5.33325H10.6666C11.0348 5.33325 11.3333 5.63173 11.3333 5.99992C11.3333 6.36811 11.0348 6.66659 10.6666 6.66659H9.08718L9.01791 6.87533H9.6363C10.5673 6.87533 11.3333 7.6289 11.3333 8.57232C11.3333 9.50336 10.5797 10.2693 9.6363 10.2693H8.09422C7.72604 10.2693 7.42756 9.97079 7.42756 9.6026C7.42756 9.23441 7.72604 8.93594 8.09422 8.93594H9.6363C9.8377 8.93594 9.99996 8.77259 9.99996 8.57232C9.99996 8.37095 9.83661 8.20866 9.6363 8.20866H8.09422C7.88008 8.20866 7.67898 8.10579 7.55365 7.93215C7.42833 7.75851 7.39404 7.53524 7.4615 7.332L7.97329 5.78993Z"
      fill="white"
    />
    <path
      d="M7.01006 5.99992C7.01006 5.72376 6.8398 5.4762 6.58193 5.37739C6.32406 5.27858 6.03196 5.34899 5.84743 5.55443L4.83732 6.679C4.59129 6.95292 4.61389 7.37442 4.88781 7.62046C5.11104 7.82097 5.43231 7.84306 5.67673 7.69609V9.59586C5.67673 9.96405 5.97521 10.2625 6.3434 10.2625C6.71159 10.2625 7.01006 9.96405 7.01006 9.59586V5.99992Z"
      fill="white"
    />
  </svg>
);
