import React from 'react';
import { useOrgForm } from './useOrgForm';
import { Organization } from '../../../types';
import { Button, Input, Modal } from '../../../components';
import { urlIcon } from '../icons';

type EditOrganizationProps = {
  open: boolean;
  onClose: () => void;
  org?: Organization | null;
};

export const EditOrganizationUrl: React.FC<EditOrganizationProps> = ({
  onClose,
  open,
  org,
}: EditOrganizationProps): JSX.Element => {
  const {
    errors,
    handleCancel,
    handleChange,
    handleSubmit,
    touched,
    values,
    initialValues,
  } = useOrgForm({ onClose, org });

  return (
    <Modal title="Edit URL" open={open} onClose={handleCancel}>
      <form onSubmit={handleSubmit}>
        <Input
          error={touched.url ? errors.url : ''}
          onChange={handleChange}
          value={values.url}
          name="url"
          icon={urlIcon}
          label="URL"
          className="edit-user__input"
          isUpdateOrg
        />
        <Button
          type="submit"
          variant="common"
          className="edit-user__btn"
          marginTop="20px"
          disabled={!values.url || initialValues.url === values.url}
        >
          Save
        </Button>
      </form>
    </Modal>
  );
};
