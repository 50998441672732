import React from 'react';

export const MeetingEventIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M2 8C2 6.34315 3.34315 5 5 5H14C15.6565 5 16.9995 6.34261 17 7.99901C17 7.99868 17 7.99934 17 7.99901V16C17 16.0007 17 16.0015 17 16.0022C16.9988 17.658 15.6561 19 14 19H5C3.34315 19 2 17.6569 2 16V8Z"
      fill="#646F87"
    />
    <path
      d="M18 16.1935L19.6426 17.5003C20.9354 18.5353 22 17.91 22 16.1095V7.89384C22 6.0933 20.9354 5.46797 19.6426 6.49222L18 7.79905L18 16.1935Z"
      fill="#646F87"
    />
  </svg>
);
