import React, { useEffect, useRef, useState } from 'react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Skeleton } from 'antd';
import { DateTime } from 'luxon';
import { Scrollbar } from '../../../../components';
import { getInvoices } from '../../../../store/billing/billing.thunks';
import { InvoiceType } from '../../../../store/billing/billing.types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export const InvoicesList: React.FC = () => {
  const dispatch = useAppDispatch();

  const invoices = useAppSelector((state) => state.billing.invoices);

  const hasInvoicesNextPage = useAppSelector(
    (state) => state.billing.hasInvoicesNextPage
  );

  const isInvoiceLoading = useAppSelector(
    (state) => state.billing.isLoading.invoices
  );

  const [shouldLoad, setShouldLoad] = useState(true);

  const request = useRef<Promise<unknown> & { abort: () => void }>();
  const scrollableNodeRef = useRef<HTMLElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: isInvoiceLoading ? invoices.length + 5 : invoices.length,
    getScrollElement: () => scrollableNodeRef.current,
    estimateSize: () => 32,
    overscan: 0,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  const attrs = {
    container: {
      className: 'invoices-list',
    },
    headerRow: {
      className: 'invoices-list__row invoices-list__row--header',
    },
    cell: {
      className: 'invoices-list__cell',
    },
    content: {
      className: 'invoices-list__content',
      style: {
        height: `${rowVirtualizer.getTotalSize()}px`,
      },
    },
    emptyContent: {
      className: 'invoices-list__empty-content',
    },
    scrollable: {
      scrollableNodeProps: {
        ref: scrollableNodeRef,
      },
      className: 'invoices-list__scrollable',
    },
  };

  const renderRows = () =>
    virtualItems.map(({ index, size, start }) => {
      const isLoaderRow = index > invoices.length - 1;
      const invoice = invoices[index];

      const props = {
        container: {
          key: index,
          className: 'invoices-list__row',
          style: {
            position: 'absolute' as const,
            top: 0,
            left: 0,
            width: '100%',
            height: `${size}px`,
            transform: `translateY(${start}px)`,
          },
        },
        link: {
          href: invoice?.pdfLink || '',
          rel: 'noopener noreferrer',
          target: '_blank',
          className: 'invoices-list__link',
        },
        cellSkeleton: {
          active: true,
          paragraph: false,
          style: {
            width: 100,
          },
          title: {
            style: {
              width: 100,
              height: 24,
              margin: 0,
              borderRadius: 12,
            },
          },
        },
      };

      if (isLoaderRow) {
        return (
          <div {...props.container}>
            <div {...attrs.cell}>
              <Skeleton {...props.cellSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...props.cellSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...props.cellSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...props.cellSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...props.cellSkeleton} />
            </div>
            <div {...attrs.cell}>
              <Skeleton {...props.cellSkeleton} />
            </div>
          </div>
        );
      }

      if (invoice) {
        const invoiceDate = DateTime.fromISO(invoice.date).toFormat('MMM d, y');
        const invoiceType =
          invoice.type === InvoiceType.MONTHLY
            ? 'Monthly paid'
            : 'Annualy paid';
        const invoiceSeats = invoice.quantity;
        const invoiceAmount = invoice.amount;
        const invoiceStatus =
          invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1);

        return (
          <div {...props.container}>
            <div {...attrs.cell}>{invoiceDate}</div>
            <div {...attrs.cell}>{invoiceType}</div>
            <div {...attrs.cell}>{invoiceSeats}</div>
            <div {...attrs.cell}>{invoiceAmount}</div>
            <div {...attrs.cell}>{invoiceStatus}</div>
            <div {...attrs.cell}>
              {invoice.pdfLink ? <a {...props.link}>View Invoice</a> : 'N/A'}
            </div>
          </div>
        );
      }

      return null;
    });

  useEffect(() => {
    const [lastItem] = [...virtualItems].reverse();

    setShouldLoad(
      !!lastItem &&
        lastItem.index >= invoices.length - 1 &&
        hasInvoicesNextPage &&
        !isInvoiceLoading
    );
  }, [virtualItems, invoices, hasInvoicesNextPage, isInvoiceLoading]);

  useEffect(() => {
    if (shouldLoad) {
      request.current = dispatch(getInvoices());
    }
  }, [dispatch, shouldLoad]);

  useEffect(() => {
    return () => {
      request.current?.abort();
    };
  }, []);

  return (
    <div {...attrs.container}>
      <div {...attrs.headerRow}>
        <div {...attrs.cell}>Date</div>
        <div {...attrs.cell}>Description</div>
        <div {...attrs.cell}>Seats</div>
        <div {...attrs.cell}>Total Amount</div>
        <div {...attrs.cell}>Status</div>
        <div {...attrs.cell}>Invoice PDF</div>
      </div>
      <Scrollbar {...attrs.scrollable}>
        {invoices.length || isInvoiceLoading ? (
          <div {...attrs.content}>{renderRows()}</div>
        ) : (
          <div {...attrs.emptyContent}>You don’t have any invoices yet</div>
        )}
      </Scrollbar>
    </div>
  );
};
