import React from 'react';
import { OptionIconProps } from '../../../../../components/Popup/PopupOption';

export const AssignRolePopupIcon: React.FC<OptionIconProps> = ({
  disabled,
}): JSX.Element => {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={disabled ? '0.4' : '1'}
    >
      <g opacity="1">
        <circle cx="3.00024" cy="3" r="2.5" stroke="white" />
        <rect
          x="5.12933"
          y="2.5"
          width="6.87094"
          height="1"
          rx="0.5"
          fill="white"
        />
        <rect x="9.00024" y="2.5" width="1" height="3" rx="0.5" fill="white" />
        <rect x="11.0002" y="2.5" width="1" height="3" rx="0.5" fill="white" />
      </g>
    </svg>
  );
};
