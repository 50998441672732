import React, { useState } from 'react';
import { Tooltip } from '../../../components/Tooltip/Tooltip';

type IconProps = {
  onClick?: () => void;
  className?: string;
};

export const EditTeamIcon: React.FC<IconProps> = ({
  className,
  onClick,
}: IconProps): JSX.Element => {
  const [isHover, setIsHover] = useState(false);
  const onEnter = () => {
    setIsHover(true);
  };
  const onLeave = () => {
    setIsHover(false);
  };
  return (
    <div
      className="team__control__icon-edit"
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <Tooltip open={isHover} text="Edit team" opposite />
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M13.2452 2.81706C14.332 1.73025 16.0941 1.73025 17.1809 2.81706C18.2224 3.85858 18.2658 5.52026 17.3111 6.61346L17.1809 6.75273L7.57506 16.3586C7.36997 16.5636 7.12323 16.7212 6.85236 16.821L6.68708 16.8739L2.63211 17.9798C2.28552 18.0743 1.96546 17.7857 2.00301 17.4452L2.01817 17.3658L3.12407 13.3109C3.20039 13.031 3.33646 12.7718 3.52212 12.5508L3.63939 12.4229L13.2452 2.81706ZM12.385 5.09195L4.34649 13.13C4.2542 13.2223 4.18117 13.3314 4.13111 13.4511L4.08884 13.574L3.2122 16.7847L6.42397 15.9091C6.50791 15.8862 6.58815 15.8526 6.66278 15.8093L6.77028 15.7372L6.86796 15.6515L14.906 7.61295L12.385 5.09195ZM16.4738 3.52417C15.8162 2.86656 14.7727 2.83003 14.0722 3.41457L13.9523 3.52417L13.092 4.38495L15.613 6.90595L16.4738 6.04563C17.1314 5.38803 17.1679 4.34455 16.5834 3.64407L16.4738 3.52417Z"
          fill="#223754"
        />
      </svg>
    </div>
  );
};
