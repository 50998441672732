import { RangeFilterMaskType } from '../../../../../../../types/meetingRecordsFilters.types';

export const getInputsMaskOptions = (
  min: number,
  max: number,
  maskType?: RangeFilterMaskType
) => {
  switch (maskType) {
    case RangeFilterMaskType.CURRENCY: {
      return {
        mask: '$num',
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ',',
            radix: '.',
            min,
            max,
          },
        },
      };
    }
    default: {
      return {};
    }
  }
};
