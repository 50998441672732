import React from 'react';
import { spinPng } from '../EncryptKeyInput/assets';

type Props = {
  className?: string;
  scaleInPercents?: number;
  isPng?: boolean;
};

export const Spinner: React.FC<Props> = ({
  className,
  scaleInPercents = 100,
  isPng,
}) => {
  const attrs = {
    wrapper: {
      className,
      style: {
        transform: `scale(${scaleInPercents}%)`,
      },
    },

    spinner: {
      className: 'wh-spinner',
    },

    png: {
      className: 'wh-spinner-png',
      src: spinPng,
    },
  };

  const spinnerPng = <img {...attrs.png} alt="" />;

  // const spinnerSvg = (
  //   <svg
  //     width="16"
  //     height="16"
  //     viewBox="0 0 16 16"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       fillRule="evenodd"
  //       clipRule="evenodd"
  //       d="M5.5589 2.51903C6.61839 2.04716 7.79193 1.89248 8.9375 2.0737C10.0831 2.25492 11.1516 2.76428 12.0137 3.54014C12.8758 4.316 13.4945 5.32509 13.7951 6.44529C14.0956 7.5655 14.065 8.7488 13.707 9.85198C13.349 10.9552 12.679 11.931 11.7779 12.6612C10.8769 13.3915 9.78354 13.845 8.63013 13.9668C8.0809 14.0248 7.68268 14.5171 7.74068 15.0663C7.79869 15.6155 8.29095 16.0138 8.84018 15.9558C10.378 15.7934 11.8359 15.1887 13.0373 14.215C14.2386 13.2413 15.132 11.9402 15.6094 10.4693C16.0867 8.99839 16.1275 7.42066 15.7268 5.92706C15.3261 4.43345 14.501 3.08799 13.3516 2.05352C12.2021 1.01904 10.7774 0.339895 9.25 0.0982652C7.72257 -0.143365 6.15785 0.0628821 4.7452 0.692045C3.33255 1.32121 2.13253 2.34632 1.29037 3.64331C0.448203 4.9403 0 6.45358 0 8C0 8.55229 0.447715 9 1 9C1.55229 9 2 8.55229 2 8C2 6.84019 2.33615 5.70523 2.96777 4.73248C3.5994 3.75974 4.49941 2.99091 5.5589 2.51903Z"
  //       fill="url(#paint0_angular_1533_24809)"
  //     />
  //     <defs>
  //       <radialGradient
  //         id="paint0_angular_1533_24809"
  //         cx="0"
  //         cy="0"
  //         r="1"
  //         gradientUnits="userSpaceOnUse"
  //         gradientTransform="translate(8 8) rotate(100.886) scale(7.06037)"
  //       >
  //         <stop offset="0.00192174" stopColor="#8B4CC9" />
  //         <stop offset="0.785316" stopColor="#8B4CC9" stopOpacity="0" />
  //       </radialGradient>
  //     </defs>
  //   </svg>
  // );

  const spinnerToShow = isPng ? spinnerPng : <div {...attrs.spinner} />;

  return <div {...attrs.wrapper}>{spinnerToShow}</div>;
};
