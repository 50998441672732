import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Input } from '../../../../../../components';
import { getWebhookSettingsKey } from '../../../../../../store/org/org.actions';
import { Spinner } from '../Spinner/Spinner';
import { closedEyeIcon, copyIcon, eyeIcon, syncArrowIcon } from './assets';

interface Props {
  value: string;
  isLoading: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children?: JSX.Element;
  orgId: string;
  disabled?: boolean;
}

export const EncryptKeyInput: React.FC<Props> = ({
  value = '',
  isLoading,
  onChange,
  children,
  orgId,
  disabled,
}): JSX.Element => {
  const [copied, setIsCopied] = useState(false);

  const [valueShown, setValueIsShown] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let copyTimer: NodeJS.Timeout;

    if (copied) {
      copyTimer = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    return () => clearTimeout(copyTimer);
  }, [copied]);

  const copyToClipboard = () => {
    if (value?.length) {
      navigator?.clipboard?.writeText(value);

      if (!copied) {
        setIsCopied(true);
      }
    }
  };

  const toggleValueVisibility = () => {
    setValueIsShown(!valueShown);
  };

  const refetchKey = () => {
    if (!disabled) {
      const isRefetchCheck = value.length > 0;

      dispatch(getWebhookSettingsKey(orgId, value, isRefetchCheck));
    }
  };

  // console.log(copied, valueShown);

  const attrs = {
    wrapper: {
      className: 'wk-form__settings__encrypt',
    },

    input: {
      label: 'Encrypt my data (AES)',
      value:
        !valueShown && value.length ? '* * * * * * * * * * * * * * *' : value,
      onChange,
      name: 'encryptKey',
      className: 'wk-form__settings__encrypt-input',
      errorOnTheRight: true,
      placeholder: '',
      isInWebhook: true,
      isHiddenValueWithStars: !valueShown,
      controlPadding: valueShown ? '10px 100px 10px 15px' : '',
      readOnly: true,
    },

    icons: {
      wrapper: {
        className: 'wk-form__settings__encrypt__controls',
        style: {
          justifyContent: `${isLoading ? 'flex-end' : 'space-between'}`,
          opacity: !value ? 0.7 : 1,
        },
      },

      loader: {
        className: 'wk-form__settings__encrypt__controls-loader',
      },

      Spinner: {
        className: 'wk-form__settings__encrypt__controls-spinner',
        isPng: true,
      },

      hideValue: {
        src: valueShown ? closedEyeIcon : eyeIcon,
        onClick: toggleValueVisibility,
      },

      control: {
        wrapper: {
          className: `${
            !value.length ? 'disabled' : ''
          } wk-form__settings__encrypt__controls__control`,
        },

        icon: {
          src: copyIcon,
          onClick: copyToClipboard,
          className: 'wk-form__settings__encrypt__controls__control-icon',
        },

        tooltip: {
          className: 'wk-form__settings__encrypt__controls__control-tooltip',
        },

        copyTooltip: {
          className: `${
            copied ? 'open' : ''
          } wk-form__settings__encrypt__controls__control-tooltip`,
        },
      },

      valueExchange: {
        src: syncArrowIcon,
        onClick: refetchKey,
        className: `${
          disabled || !value ? 'disabled' : ''
        } wk-form__settings__encrypt__controls__control-icon`,
      },
    },
  };

  const changeValueVisibilityIcon = (
    <div {...attrs.icons.control.wrapper}>
      <img {...attrs.icons.hideValue} alt="" />
    </div>
  );

  const copyToClipboardIcon = (
    <div {...attrs.icons.control.wrapper}>
      <img {...attrs.icons.control.icon} alt="" />
      <div {...attrs.icons.control.copyTooltip}>
        {copied ? 'Copied to clipboard' : 'Copy'}
      </div>
    </div>
  );

  const valueExchangeIcon = (
    <div {...attrs.icons.control.wrapper}>
      <img {...attrs.icons.valueExchange} alt="" />
      <div {...attrs.icons.control.tooltip}>Refresh token</div>
    </div>
  );

  const controlIcons = (
    <>
      {changeValueVisibilityIcon}
      {copyToClipboardIcon}
      {valueExchangeIcon}
    </>
  );

  const conditionalControls = isLoading ? (
    <Spinner {...attrs.icons.Spinner} />
  ) : (
    controlIcons
  );

  return (
    <div {...attrs.wrapper}>
      {children}
      <Input {...attrs.input} />
      <div {...attrs.icons.wrapper}>{conditionalControls}</div>
    </div>
  );
};
