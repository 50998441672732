import React, { memo, useEffect, useRef } from 'react';
import { Avatar } from '../../../../../../../../components/SoundWave/components/Avatar/Avatar';
import { MeetingParticipantWithContactInfo } from '../../../../../../../../types/meetingDetails.types';

export interface TranscriptItemProps {
  text: string;
  time: string;
  participant: MeetingParticipantWithContactInfo | null;
  startTimestamp: number;
  onClick: (timestamp: number) => void;
  isChangingTimeline: boolean;
  isActive: boolean;
  scrollableNodeRef?: React.RefObject<HTMLElement>;
}

export const TranscriptItem: React.FC<TranscriptItemProps> = memo(
  ({
    text,
    time,
    participant,
    isActive,
    isChangingTimeline,
    startTimestamp,
    scrollableNodeRef,
    onClick,
  }) => {
    const containerRef = useRef<HTMLButtonElement>(null);

    const speakerName =
      participant?.contact?.metadata?.name || participant?.alias || '';

    const attrs = {
      transcriptItem: {
        ref: containerRef,
        disabled: isActive,
        onClick: () => {
          onClick(startTimestamp);
        },
        className: `transcript-item${
          isActive ? ' transcript-item--active' : ''
        }`,
      },
      speakerInfo: {
        className: 'transcript-item__speaker-info',
      },
      speakerAvatar: {
        src: participant?.contact?.metadata?.avatarSrc || '',
        name: speakerName,
        side: participant?.side,
      },
      speakerTextInfo: {
        className: 'transcript-item__speaker-text-info',
      },
      speakerName: {
        className: 'transcript-item__speaker-name',
      },
      time: {
        className: 'transcript-item__time',
      },
      text: {
        className: 'transcript-item__text',
      },
    };

    useEffect(() => {
      if (
        isActive &&
        !isChangingTimeline &&
        containerRef.current &&
        scrollableNodeRef?.current
      ) {
        const containerClientHeight = containerRef.current.clientHeight;
        const scrollableNodeClientHeight =
          scrollableNodeRef.current.clientHeight;

        const offsetTop =
          scrollableNodeClientHeight / 2 - containerClientHeight / 2;

        scrollableNodeRef.current.scroll({
          top:
            containerClientHeight >= scrollableNodeClientHeight
              ? containerRef.current.offsetTop
              : containerRef.current.offsetTop - offsetTop,
          behavior: 'smooth',
        });
      }
    }, [isActive, isChangingTimeline, scrollableNodeRef]);

    return (
      <button {...attrs.transcriptItem}>
        <span {...attrs.speakerInfo}>
          <Avatar {...attrs.speakerAvatar} />
          <span {...attrs.speakerTextInfo}>
            <span {...attrs.speakerName}>{speakerName}</span>
            <span {...attrs.time}>{time}</span>
          </span>
        </span>
        <span {...attrs.text}>{text}</span>
      </button>
    );
  }
);
