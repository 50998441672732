import React from 'react';

type ToggleProps = {
  active: boolean;
  onToggle: () => void;
  className?: string;
  disabled?: boolean;
};

export const Toggle: React.FC<ToggleProps> = ({
  active = false,
  onToggle = () => {},
  className = '',
  disabled = false,
}: ToggleProps): JSX.Element => {
  const wrapperClassName = `meetings-toggle meetings-toggle-${
    active && !disabled ? 'active' : 'inactive'
  } ${className}`;

  const attrs = {
    wrapper: {
      className: wrapperClassName,
      style: disabled ? { cursor: 'default', opacity: 0.6 } : {},
    },

    input: {
      readOnly: true,
      checked: active,
      className: 'meetings-toggle__control',
      type: 'checkbox',
      onClick: onToggle,
    },

    toggle: {
      className: 'meetings-toggle__toggle',
    },
  };

  return (
    <label {...attrs.wrapper}>
      <input {...attrs.input} />
      <div {...attrs.toggle} />
    </label>
  );
};
