import React, { useState } from 'react';
import { Tooltip } from '../../../../components/SoundWave';
import { TooltipIcon } from '../../../../components/Icons/TooltipIcon';
import comingSoon from './assets/coming-soon-icon.svg';

export type ChartProps = {
  title?: string | JSX.Element;
  subtitle?: string;
  tooltipText?: string;
  children?: JSX.Element;
  width?: string;
  isEmptyWrapper?: boolean;
  isIcon?: boolean;
  isNoData?: boolean;
  upIndex?: boolean;
  invisible?: boolean;
  isInTeamQ?: boolean;
  isSoundWave?: boolean;
};

export const ChartWrapper: React.FC<ChartProps> = ({
  title,
  subtitle,
  tooltipText,
  children,
  isEmptyWrapper,
  isIcon,
  isNoData,
  upIndex,
  invisible,
  isInTeamQ,
}): JSX.Element => {
  const [noDataTooltipOpen, setNoDataTooltipOpen] = useState(false);

  const noDataTooltipToggle = () => {
    setNoDataTooltipOpen(!noDataTooltipOpen);
  };

  const conditionalOpacity = isEmptyWrapper
    ? { opacity: 0.4, zIndex: 1 }
    : { opacity: 1 };

  const attrs = {
    wrapper: {
      className: `${isInTeamQ ? 'team-q-chart ' : ''}${
        invisible ? 'invisible ' : ''
      }chart-wrapper`,

      style: isEmptyWrapper // eslint-disable-line
        ? { backgroundColor: '#f2f2f6' }
        : upIndex
        ? { zIndex: 101 }
        : {},
    },
    noData: {
      wrapper: {
        className: 'chart-wrapper__warn-wrap',
      },
      tooltipBlock: {
        wrapper: {
          className: 'chart-wrapper__warn-wrap__tooltip',
          onMouseEnter: noDataTooltipToggle,
          onMouseLeave: noDataTooltipToggle,
        },
        icon: {
          active: noDataTooltipOpen,
          className: 'chart-wrapper__warn-wrap__tooltip-icon',
          isDarker: true,
        },
        tooltipMessage: {
          className: noDataTooltipOpen
            ? 'chart-wrapper__warn-wrap__tooltip-message'
            : 'closed chart-wrapper__warn-wrap__tooltip-message',
        },
      },

      message: { className: 'chart-wrapper__warn-wrap__message' },
    },
    empty: {
      wrapper: {
        className: 'chart-wrapper__empty-wrap',
      },
      icon: {
        className: 'chart-wrapper__empty-wrap__icon',
        src: comingSoon,
        style: !isIcon ? { display: 'none' } : {},
      },
    },
    titleSection: {
      titleWrapper: {
        className: 'chart-wrapper__title-block',
      },
      title: {
        className: 'chart-wrapper__title-block__title',
        style: conditionalOpacity,
      },
      tooltipBlock: {
        style: isEmptyWrapper ? { cursor: 'default' } : {},
        className: 'chart-wrapper__title-block__tooltip-block',
      },
      icon: {
        active: false,
      },
      comingSoon: {
        className: 'chart-wrapper__title-block__tooltip-block-coming-soon',
      },
    },
    subtitle: {
      style: conditionalOpacity,
      className: `${isInTeamQ ? 'no-border ' : ''}chart-wrapper__subtitle`,
    },
    tooltip: {
      autoPosition: true,
      width: 284,
      text: tooltipText,
      className: 'chart-wrapper__title-tooltip',
      positionOffset: 12,
    },
  };

  const conditionalNoData = isNoData ? (
    <div {...attrs.noData.wrapper}>
      <span {...attrs.noData.message}>Not enough data</span>
      <div {...attrs.noData.tooltipBlock.wrapper}>
        <TooltipIcon {...attrs.noData.tooltipBlock.icon} />
        <div {...attrs.noData.tooltipBlock.tooltipMessage}>
          To gather enough data for this chart, you have to analyze (in Q for
          Chrome) at least 20 email messages. They can all be in a single
          thread.
        </div>
      </div>
    </div>
  ) : null;

  const wrapperBody = !isEmptyWrapper ? (
    <>
      {conditionalNoData}
      <div {...attrs.titleSection.titleWrapper}>
        <span {...attrs.titleSection.title}>{title}</span>
        <Tooltip {...attrs.tooltip}>
          <div {...attrs.titleSection.tooltipBlock}>
            <TooltipIcon {...attrs.titleSection.icon} />
          </div>
        </Tooltip>
      </div>
      <div {...attrs.subtitle}>{subtitle}</div>
      {children}
    </>
  ) : (
    <div {...attrs.empty.wrapper}>
      <img {...attrs.empty.icon} alt="" />
      <div {...attrs.titleSection.titleWrapper}>
        <span {...attrs.titleSection.title}>{title}</span>
        <Tooltip {...attrs.tooltip}>
          <div {...attrs.titleSection.tooltipBlock}>
            <div {...attrs.titleSection.comingSoon}>Coming soon</div>
          </div>
        </Tooltip>
      </div>
      <div {...attrs.subtitle}>{subtitle}</div>
      {children}
    </div>
  );

  return <div {...attrs.wrapper}>{wrapperBody}</div>;
};
