import React from 'react';
import { PlusIcon } from './icons/PlusIcon';

interface Props {
  selectCreateAccOption: () => void;
}

export const AddNewAccountButton: React.FC<Props> = ({
  selectCreateAccOption,
}) => {
  const attrs = {
    button: {
      type: 'button' as const,
      className: 'add-new-account-button',
      onClick: () => {
        selectCreateAccOption();
        console.log('Create a new Account');
      },
    },
    icon: {
      className: 'add-new-account-button__icon',
    },
  };

  return (
    <button {...attrs.button}>
      <PlusIcon {...attrs.icon} />
      <span>Create a New Account</span>
    </button>
  );
};
