import {
  SellingZoneData,
  SellingZoneDataBranchResult,
  SellingZoneDataBranchResultEmail,
  SellingZoneParticipant,
} from '@substratahq/selling-zone/typing/base';
import {
  Email,
  EmailAnalysis,
  EmailParticipant,
} from '../../../../types/emailAnalysis.types';

const getSellingZoneParticipant = (
  participant: EmailParticipant
): SellingZoneParticipant => {
  return {
    email: participant?.email,
    metadata: {
      name: participant?.metadata?.name || '',
      avatarSrc: participant?.metadata?.avatarSrc || '',
      linkedin: participant?.metadata?.linkedin || '',
    },
    isSender: participant?.isSender,
    isUser: participant?.isUser,
    numberEmailsInBranch: participant?.numberEmailsInBranch,
  };
};

const getSellingZoneEmail = (
  email: Email,
  theFirstReplyIndex: number
): SellingZoneDataBranchResultEmail => {
  const allParticipants = [
    getSellingZoneParticipant({
      ...email.sender,
      isSender: true,
      isUser: false,
      isUserDomain: false,
      numberEmailsInBranch: 0,
    }),
    ...email?.recipients?.map((el) =>
      getSellingZoneParticipant({
        ...el,
        isSender: true,
        isUser: false,
        isUserDomain: false,
        numberEmailsInBranch: 0,
      })
    ),
  ];

  return {
    platformMessageId: email?.platformMessageId,
    participants: {
      leftParticipant: getSellingZoneParticipant(
        email?.participants?.leftParticipant
      ),
      rightParticipant: getSellingZoneParticipant(
        email?.participants?.rightParticipant
      ),
    },
    sentAt: email?.sentAt,
    isDraft: false,
    scoreInBranch: email?.scoreInBranch,
    index: typeof email?.index === 'number' ? email?.index : undefined,
    allEmailParticipants: allParticipants,
    repliedEmailIndex: theFirstReplyIndex,
  };
};

export const getSellingZoneData = ({
  analyzedData,
  branchId,
  messageId,
  firstReplyIndex,
  setFirstReplyIndex,
}: {
  analyzedData: EmailAnalysis;
  branchId: string;
  messageId: string | null;
  firstReplyIndex: number;
  setFirstReplyIndex: (index: number) => void;
}): SellingZoneData => {
  const sellingZoneData: SellingZoneData = {
    platformMessageIdToAnalyze: messageId,
    branchIdToSelect: branchId,
    branchResults: [],
    isThreadInternal: analyzedData?.isThreadInternal,
  };
  if (analyzedData?.branch) {
    // eslint-disable-next-line
      for (const branch of [analyzedData?.branch]) {
      // branch.branchId = uuidv4();

      const sellingZoneBranch: SellingZoneDataBranchResult = {
        branchId: branch?.branchId,
        nextMove: {
          value: branch?.nextMove?.value,
        },
        emails: [],
        draft: null,
        participants: [],
      };

      const firstEmailSender: EmailParticipant = branch?.emails?.[0]
        ?.participants?.leftParticipant?.isSender
        ? branch?.emails?.[0]?.participants?.leftParticipant
        : branch?.emails?.[0]?.participants?.rightParticipant;

      // eslint-disable-next-line
        for (const email of branch?.emails) {
        const repliedMessage: Email | undefined = branch?.emails?.find((e) => {
          const senderPerson: EmailParticipant = e.participants?.leftParticipant
            ?.isSender
            ? e.participants?.leftParticipant
            : e.participants?.rightParticipant;

          return senderPerson?.email !== firstEmailSender?.email;
        });

        const repliedEmailIndex =
          repliedMessage?.index !== undefined &&
          typeof repliedMessage?.index === 'number'
            ? repliedMessage?.index
            : 10000;

        if (repliedEmailIndex !== 10000 && !firstReplyIndex) {
          setFirstReplyIndex(repliedEmailIndex);
        }

        sellingZoneBranch.emails.push(
          // eslint-desable-next-line
          getSellingZoneEmail(
            email,
            repliedEmailIndex
          ) as SellingZoneDataBranchResultEmail
        );
      }

      // eslint-disable-next-line
        for (const participant of (branch?.mainBranchParticipants || [])) {
        sellingZoneBranch.participants.push(
          getSellingZoneParticipant(participant) as SellingZoneParticipant
        );
      }

      sellingZoneData.branchResults.push(sellingZoneBranch);
    }
  }

  return sellingZoneData;
};
