import React from 'react';
import { Skeleton } from 'antd';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import {
  Avatar,
  AvatarSize,
} from '../../../SoundWave/components/Avatar/Avatar';
import { WarningIcon } from './icons/WarningIcon';
import {
  EmailDominantParticipant,
  EmailParticipant,
} from '../../../../types/emailAnalysis.types';

export interface UpperHandAnalysisProps {
  isLoading?: boolean;
  dominantSide?: EmailDominantParticipant;
  leftParticipant?: EmailParticipant & {
    side: RelationshipMemberSide;
  };
  rightParticipant?: EmailParticipant & {
    side: RelationshipMemberSide;
  };
}

export const UpperHandAnalysis: React.FC<UpperHandAnalysisProps> = ({
  isLoading = false,
  dominantSide,
  leftParticipant,
  rightParticipant,
}) => {
  const attrs = {
    container: {
      className: `upper-hand-analysis${
        dominantSide === EmailDominantParticipant.UNRESOLVED
          ? ' upper-hand-analysis--unresolved'
          : ''
      }`,
    },
    item: {
      className: 'upper-hand-analysis__item',
    },
    text: {
      className: 'upper-hand-analysis__text',
    },
    icon: {
      className: 'upper-hand-analysis__icon',
    },
    leftParticipantAvatar: {
      size:
        dominantSide === EmailDominantParticipant.LEFT
          ? AvatarSize.L
          : AvatarSize.S,
      side: leftParticipant?.side,
      src: leftParticipant?.metadata?.avatarSrc || '',
      name: leftParticipant?.metadata?.name || '',
    },
    rightParticipantAvatar: {
      size:
        dominantSide === EmailDominantParticipant.RIGHT
          ? AvatarSize.L
          : AvatarSize.S,
      side: rightParticipant?.side,
      src: rightParticipant?.metadata?.avatarSrc || '',
      name: rightParticipant?.metadata?.name || '',
    },
    avatarSkeleton: {
      active: true,
      paragraph: false,
      title: false,
      avatar: {
        shape: 'circle' as const,
        size: 28,
      },
      style: {
        width: 'auto',
      },
    },
    textSkeleton: {
      active: true,
      paragraph: false,
      title: {
        width: 60,
        style: {
          width: 60,
          height: 20,
          borderRadius: 4,
          margin: 0,
        },
      },
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.item}>
        {isLoading ? (
          <Skeleton {...attrs.textSkeleton} />
        ) : (
          <span {...attrs.text}>{leftParticipant?.metadata?.name}</span>
        )}
        {isLoading ? (
          <Skeleton {...attrs.avatarSkeleton} />
        ) : (
          <Avatar {...attrs.leftParticipantAvatar} />
        )}
      </div>
      <WarningIcon {...attrs.icon} />
      <div {...attrs.item}>
        {isLoading ? (
          <Skeleton {...attrs.avatarSkeleton} />
        ) : (
          <Avatar {...attrs.rightParticipantAvatar} />
        )}
        {isLoading ? (
          <Skeleton {...attrs.textSkeleton} />
        ) : (
          <span {...attrs.text}>{rightParticipant?.metadata?.name}</span>
        )}
      </div>
    </div>
  );
};
