import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactPlayerClass from 'react-player';
import { OnProgressProps } from 'react-player/base';
import { ReactPlayerProps } from 'react-player/types/lib';
import { PlayIcon } from './components/Icons/PlayIcon';
import { LoaderIcon } from './components/Icons/LoaderIcon';
import { TranscriptList } from './components/TranscriptList/TranscriptList';
import { FloatingControls } from './components/FloatingControls/FloatingControls';
import { Controls } from './components/Controls/Controls';
import {
  ControlButton,
  ControlButtonSize,
} from './components/ControlButton/ControlButton';
import { useVideoPlayerContext } from '../../context/VideoPlayerContext';
import { useAppSelector } from '../../../../store/hooks';
import { transcriptsWithParticipantInfoSelector } from '../../../../store/meetingDetails/meetingDetails.selectors';

const ReactPlayer = (ReactPlayerClass as unknown) as React.FC<ReactPlayerProps>;

export const VideoPlayer: React.FC = memo(() => {
  const isMeetingDetailsLoading = useAppSelector(
    (state) => state.meetingDetails.isLoading.meetingDetails
  );

  const isMeetingDetailsError = useAppSelector(
    (state) => state.meetingDetails.isError.meetingDetails
  );

  const recordingUrl = useAppSelector(
    (state) => state.meetingDetails.data?.recordingUrl
  );

  const recordingThumbnail = useAppSelector(
    (state) => state.meetingDetails.data?.recordingThumbnail
  );

  const transcripts = useAppSelector(transcriptsWithParticipantInfoSelector);

  const [
    {
      activeTranscriptItemIndex,
      isFullScreen,
      isPlaying,
      isMuted,
      isChangingTimeline,
      showBigPlay,
      duration,
      volume,
      playbackRate,
      timelineState,
      playerRef,
      reactPlayerInstanceRef,
      isLoading,
    },
    {
      setActiveTranscriptItemIndex,
      setIsFullScreen,
      setIsPlaying,
      setIsMuted,
      setIsChangingTimeline,
      setShowBigPlay,
      setDuration,
      setVolume,
      setPlaybackRate,
      setTimelineState,
    },
  ] = useVideoPlayerContext();

  const [isTimelineChanged, setIsTimelineChanged] = useState(false);

  const handleSelectTranscriptItem = useCallback(
    (timestamp: number) => {
      // Add 0.001 to timestamp to fix active transcript item
      reactPlayerInstanceRef.current?.seekTo(timestamp + 0.001, 'seconds');
    },
    [reactPlayerInstanceRef]
  );

  const previousTranscriptItem = useMemo(
    () =>
      [...transcripts].reverse().find((transcript) => {
        return transcript.endTimestamp < timelineState.playedSeconds;
      }),
    [timelineState.playedSeconds, transcripts]
  );

  const nextTranscriptItem = useMemo(
    () =>
      transcripts.find((transcript) => {
        return transcript.startTimestamp > timelineState.playedSeconds;
      }),
    [timelineState.playedSeconds, transcripts]
  );

  const attrs = {
    container: {
      className: 'player-container',
    },
    player: {
      className: `player${isFullScreen ? ' player--fullscreen' : ''}`,
      ref: playerRef,
    },
    videoWrapper: {
      className: 'player__video-wrapper',
    },
    reactPlayer: {
      volume,
      playbackRate,
      url:
        isMeetingDetailsLoading || isMeetingDetailsError
          ? ''
          : recordingUrl || '',
      playing: isPlaying && !isChangingTimeline,
      progressInterval: 1000 / 60,
      muted: isMuted,
      ref: reactPlayerInstanceRef,
      width: '100%',
      height: '100%',
      onEnded: () => {
        setIsPlaying(false);
      },
      onProgress: (progress: OnProgressProps) => {
        setTimelineState(progress);
      },
      onDuration: (durationValue: number) => {
        setDuration(durationValue);
      },
    },
    floatingControlsWrapper: {
      className: 'player__floating-controls-wrapper',
    },
    floatingControls: {
      isPlaying,
      isPreviousSpeakerButtonDisabled: !previousTranscriptItem,
      isNextSpeakerButtonDisabled: !nextTranscriptItem,
      onPlayPause: setIsPlaying,
      onRewind: () => {
        reactPlayerInstanceRef.current?.seekTo(
          Math.max(timelineState.playedSeconds - 15, 0),
          'seconds'
        );
      },
      onFastForward: () => {
        reactPlayerInstanceRef.current?.seekTo(
          Math.min(timelineState.playedSeconds + 15, duration),
          'seconds'
        );
      },
      onNextSpeaker: () => {
        if (nextTranscriptItem) {
          reactPlayerInstanceRef.current?.seekTo(
            nextTranscriptItem.startTimestamp,
            'seconds'
          );
        }
      },
      onPreviousSpeaker: () => {
        if (previousTranscriptItem) {
          reactPlayerInstanceRef.current?.seekTo(
            previousTranscriptItem.startTimestamp,
            'seconds'
          );
        }
      },
    },
    loaderWrapper: {
      className: 'player__loader-wrapper',
    },
    loader: {
      className: 'player__loader',
    },
    bigPlayWrapper: {
      className: `player__big-play-wrapper${
        isTimelineChanged ? ' player__big-play-wrapper--timeline-changed' : ''
      }`,
      ...(recordingThumbnail &&
        !isTimelineChanged && {
          style: {
            background: `url(${recordingThumbnail}) no-repeat center center / cover, rgba(56, 67, 86, 0.60)`,
            backgroundBlendMode: 'multiply',
          },
        }),
    },
    bigPlayButton: {
      label: 'Play',
      size: ControlButtonSize.BIG_PLAY,
      onClick: () => {
        setShowBigPlay(false);
        setIsPlaying(true);
      },
    },
    controlsWrapper: {
      className: 'player__controls-wrapper',
    },
    controls: {
      isMuted,
      volume,
      playbackRate,
      duration,
      isFullScreen,
      played: timelineState.played,
      playedSeconds: timelineState.playedSeconds,
      onMuteChange: setIsMuted,
      onVolumeChange: setVolume,
      onIsChangingTimeline: setIsChangingTimeline,
      onPlaybackRateChange: setPlaybackRate,
      onTimelineChange: (value: number) => {
        reactPlayerInstanceRef.current?.seekTo(value, 'fraction');
      },
      onFullScreen: () => {
        playerRef.current?.requestFullscreen();
      },
    },
    transcriptList: {
      isChangingTimeline,
      items: transcripts,
      activeItemIndex: activeTranscriptItemIndex,
      selectTranscriptItem: handleSelectTranscriptItem,
    },
  };

  useEffect(() => {
    const listener = () => {
      if (document.fullscreenElement === playerRef.current) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    };

    window.addEventListener('fullscreenchange', listener);

    return () => {
      window.removeEventListener('fullscreenchange', listener);
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const activeSpeakerItemIndex = transcripts?.findIndex((transcriptItem) => {
      return (
        timelineState.playedSeconds >= transcriptItem.startTimestamp &&
        timelineState.playedSeconds < transcriptItem.endTimestamp
      );
    });

    setActiveTranscriptItemIndex(
      activeSpeakerItemIndex > -1 ? activeSpeakerItemIndex : null
    );
  }, [setActiveTranscriptItemIndex, timelineState, transcripts]);

  useEffect(() => {
    setIsTimelineChanged(!!timelineState.played);
  }, [timelineState.played]);

  return (
    <div {...attrs.container}>
      <div {...attrs.player}>
        <div {...attrs.videoWrapper}>
          <ReactPlayer {...attrs.reactPlayer} />
        </div>
        {!showBigPlay ? (
          <div {...attrs.floatingControlsWrapper}>
            <FloatingControls {...attrs.floatingControls} />
          </div>
        ) : null}
        {isLoading ? (
          <div {...attrs.loaderWrapper}>
            <LoaderIcon {...attrs.loader} />
          </div>
        ) : null}
        {showBigPlay && !isLoading ? (
          <div {...attrs.bigPlayWrapper}>
            <ControlButton {...attrs.bigPlayButton}>
              <PlayIcon />
            </ControlButton>
          </div>
        ) : null}
        <div {...attrs.controlsWrapper}>
          <Controls {...attrs.controls} />
        </div>
      </div>
      <TranscriptList {...attrs.transcriptList} />
    </div>
  );
});
