import React from 'react';
import CustomSelect from '../../../../../../components/CustomSelect/CustomSelect';
import { Input } from '../../../../../../components/Input/Input';
import { WebhookSettingsAuthType } from '../../../../../../store/org/org.types';
import { AuthParams, FieldName } from '../../hooks/useEditWebhook';
import { deleteRowIcon } from './assets';

type Props = {
  index: number;
  row: AuthParams;
  setAuthParam: (
    index: number,
    fieldName: FieldName,
    value: WebhookSettingsAuthType | string
  ) => void;
  deleteAuthRow: (index: number) => void;
};

export const AuthParamsRow: React.FC<Props> = ({
  index = 0,
  row,
  setAuthParam,
  deleteAuthRow,
}): JSX.Element => {
  const selectValues = [
    { value: 'header', label: 'Header' },
    { value: 'query', label: 'Query' },
  ];

  const onTypeChange = (newValue: typeof selectValues[0]) => {
    setAuthParam(index, 'type', newValue.value);
  };

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof e?.currentTarget?.value === 'string') {
      setAuthParam(index, 'name', e.currentTarget.value);
    }
  };

  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (typeof e?.currentTarget?.value === 'string') {
      setAuthParam(index, 'value', e.currentTarget.value);
    }
  };

  const setectsCurrValue = selectValues.find((el) => el.value === row.type);

  const attrs = {
    wrapper: {
      className: 'auth-param-row',
      style: index === 0 ? { marginTop: '15px' } : {},
    },

    select: {
      placeholder: 'Select Type',
      selectedItem: setectsCurrValue,
      items: selectValues,
      onChange: onTypeChange,
      style: {
        background: '#F8F8FB',
        border: !setectsCurrValue ? 'none' : '1px solid #CDD5E5',
        borderRadius: '8px',
      },
      selectedItemJustify: 'flex-start',
      isInWebhook: true,
      isError: !setectsCurrValue,
    },

    selectError: {
      className: 'auth-param-row-type-error',
    },

    inputs: {
      name: {
        label: '',
        onChange: onNameChange,
        value: row.name,
        className: 'auth-param-row-name-input',
        placeholder: 'Enter param name',
        noLabel: true,
        isInWebhook: true,
        error: !row?.name?.length ? 'Please enter correct name' : '',
      },

      value: {
        label: '',
        value: row.value,
        onChange: onValueChange,
        className: 'auth-param-row-value-input',
        placeholder: 'Enter param value',
        noLabel: true,
        isInWebhook: true,
        error: !row?.value?.length ? 'Please enter correct value' : '',
      },
    },

    deleteRow: {
      className: 'auth-param-row__remove-row-icon',
      src: deleteRowIcon,
      onClick: () => deleteAuthRow(index),
    },
  };

  const selectError = !setectsCurrValue ? (
    <div {...attrs.selectError}>Please select type</div>
  ) : null;

  const deleteRow = <img {...attrs.deleteRow} alt="" />;

  return (
    <div {...attrs.wrapper}>
      <CustomSelect {...attrs.select} />
      {selectError}
      <Input {...attrs.inputs.name} />
      <Input {...attrs.inputs.value} />
      {deleteRow}
    </div>
  );
};
