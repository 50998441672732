import React from 'react';

export const ConnectedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9716 4.86215C13.2319 5.12257 13.2317 5.54468 12.9713 5.80496L7.63484 11.1383C7.50975 11.2633 7.34012 11.3335 7.16328 11.3334C6.98643 11.3333 6.81686 11.263 6.69189 11.1379L4.02836 8.47121C3.76816 8.21071 3.76841 7.7886 4.02891 7.5284C4.28942 7.2682 4.71153 7.26845 4.97172 7.52895L7.16399 9.7238L12.0288 4.86187C12.2892 4.6016 12.7113 4.60172 12.9716 4.86215Z"
      fill="#070B12"
    />
  </svg>
);
