import React, { memo, useRef } from 'react';
import { Duration } from 'luxon';
import { Scrollbar } from '../../../../../../components/Scrollbar/Scrollbar';
import { TranscriptItem } from './components/TranscriptItem/TranscriptItem';
import { TranscriptItemsSkeleton } from './components/TranscriptItemsSkeleton/TranscriptItemsSkeleton';
import { useAppSelector } from '../../../../../../store/hooks';
import { MeetingTranscriptWithParticipantInfo } from '../../../../../../types/meetingDetails.types';

export interface TranscriptListProps {
  items?: MeetingTranscriptWithParticipantInfo[];
  activeItemIndex: number | null;
  isChangingTimeline: boolean;
  selectTranscriptItem: (timestamp: number) => void;
}

export const TranscriptList: React.FC<TranscriptListProps> = memo(
  ({ items, activeItemIndex, isChangingTimeline, selectTranscriptItem }) => {
    const isMeetingDetailsLoading = useAppSelector(
      (state) => state.meetingDetails.isLoading.meetingDetails
    );
    const isMeetingDetailsError = useAppSelector(
      (state) => state.meetingDetails.isError.meetingDetails
    );

    const scrollableNodeRef = useRef<HTMLElement>(null);

    const attrs = {
      transcriptList: {
        className: 'transcript-list',
        scrollableNodeProps: {
          ref: scrollableNodeRef,
        },
      },
    };

    const renderTranscriptItems = () =>
      items?.map((item, index) => {
        const props = {
          isChangingTimeline,
          scrollableNodeRef,
          key: index,
          text: item.text,
          time: Duration.fromObject({
            seconds: Math.round(item.startTimestamp),
          }).toFormat('mm:ss'),
          startTimestamp: item.startTimestamp,
          participant: item?.participant || null,
          isActive: index === activeItemIndex,
          onClick: selectTranscriptItem,
        };

        return <TranscriptItem {...props} />;
      });

    return (
      <Scrollbar {...attrs.transcriptList}>
        {isMeetingDetailsLoading || isMeetingDetailsError ? (
          <TranscriptItemsSkeleton />
        ) : (
          renderTranscriptItems()
        )}
      </Scrollbar>
    );
  }
);
