import { AvatarProps } from '../../../../components/SoundWave/components/Avatar/Avatar';
import { Contact } from '../../../../types/contact.types';
import {
  EmailDominantParticipant,
  EmailParticipant,
  EmailSender,
} from '../../../../types/emailAnalysis.types';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';

export const getEmailParticipantsAvatarProps = (
  ...participants: Array<EmailSender & { contact: Contact | null }>
): AvatarProps[] => {
  return participants.map(({ metadata, contact }) => ({
    name: metadata.name || '',
    src: metadata.avatarSrc || '',
    side:
      contact?.extra?.currentAccountLink?.contactSide ||
      RelationshipMemberSide.UNASSIGNED,
    className: metadata.avatarSrc ? '' : 'email-analysis__avatar',
  }));
};

export const getUpperHandAnalysisTooltip = (
  leftParticipant?: EmailParticipant,
  rightParticipant?: EmailParticipant,
  dominantSide?: EmailDominantParticipant | null,
  isUserThreadOwner?: boolean
): string => {
  if (
    !leftParticipant ||
    !rightParticipant ||
    !dominantSide ||
    dominantSide === EmailDominantParticipant.UNRESOLVED
  ) {
    return 'Upper hand cannot be determined yet';
  }

  const dominantParticipant =
    dominantSide === EmailDominantParticipant.LEFT
      ? leftParticipant
      : rightParticipant;

  return dominantParticipant.isUser && isUserThreadOwner
    ? 'You have the upper hand at this point'
    : `${
        dominantParticipant.metadata?.name || dominantParticipant.email
      } has the upper hand at this point`;
};
