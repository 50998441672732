import React from 'react';

export const ResendInvitePopupIcon: React.FC = (): JSX.Element => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_399_33643)">
        <rect
          x="0.499634"
          y="2.3877"
          width="11.0005"
          height="7.2245"
          rx="0.5"
          stroke="white"
        />
        <rect
          x="-0.0709229"
          y="3.21777"
          width="1"
          height="7.39154"
          rx="0.5"
          transform="rotate(-56.1907 -0.0709229 3.21777)"
          fill="white"
        />
        <rect
          x="0.224121"
          y="9.80957"
          width="1"
          height="6.20243"
          rx="0.5"
          transform="rotate(-122.37 0.224121 9.80957)"
          fill="white"
        />
        <rect
          width="1"
          height="6.30565"
          rx="0.5"
          transform="matrix(0.535389 -0.844606 -0.844606 -0.535389 11.7759 9.80957)"
          fill="white"
        />
        <rect
          width="1"
          height="7.31478"
          rx="0.5"
          transform="matrix(-0.55643 -0.830894 -0.830894 0.55643 12.0069 3.26074)"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_399_33643">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
