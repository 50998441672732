import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { Features, UserConnection } from '../../types';
import { WarningIcon } from '../Icons/WarningIcon';
import { Button, ButtonSize, ButtonVariant } from '../SoundWave';

export const PermissionsBanner: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const userConnections = useAppSelector((state) => state.auth.userConnections);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isOpened, setIsOpened] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const history = useHistory();

  const isEmailAnalyzersEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.EMAIL_ANALYZER
      )?.enabled,
    [user]
  );

  const isSoundWaveEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.SOUNDWAVE
      )?.enabled,
    [user]
  );

  const isSendEmailsEnabled = useMemo(
    () =>
      !!user?.metadata?.features?.find(
        (feature) => feature.name === Features.REPLY_FROM_DASHBOARD
      )?.enabled,
    [user]
  );

  const isAnalyzeEmailsDisconnected =
    !!userConnections && userConnections.gmailRead === false;

  const isSendEmailsDisconnected =
    !!userConnections && userConnections.gmailWrite === false;

  const isCalendarDisconnected =
    !!userConnections && userConnections.calendar === false;

  const attrs = {
    container: {
      className: `permissions-banner${
        isClosing ? ' permissions-banner--closing' : ''
      }`,
      onAnimationEnd: () => {
        if (isClosing) {
          setIsOpened(false);
          setIsClosing(false);
        }
      },
    },
    contentWrapper: {
      className: 'permissions-banner__content-wrapper',
    },
    icon: {
      className: 'permissions-banner__icon',
    },
    text: {
      className: 'permissions-banner__text',
    },
    title: {
      className:
        'permissions-banner__text permissions-banner__text--bold permissions-banner__text--nowrap',
    },
    dismissButton: {
      size: ButtonSize.XS,
      variant: ButtonVariant.Transparent,
      className: 'permissions-banner__dismiss-button',
      onClick: () => {
        setIsClosing(true);
      },
    },
    grantPermissionButton: {
      size: ButtonSize.XS,
      onClick: () => {
        const selectedIntegrationsValue = [
          ...(isEmailAnalyzersEnabled && isAnalyzeEmailsDisconnected
            ? [UserConnection.GmailRead]
            : []),
          ...(isSendEmailsEnabled && isSendEmailsDisconnected
            ? [UserConnection.GmailWrite]
            : []),
          ...(isSoundWaveEnabled && isCalendarDisconnected
            ? [UserConnection.Calendar]
            : []),
        ].join(',');

        history.push(
          `/orgs/${user?.orgId}/users/${user?.userId}/?selectedIntegrations=${selectedIntegrationsValue}`
        );

        setIsClosing(true);
      },
    },
  };

  useEffect(() => {
    if (
      !isInitialized &&
      ((isEmailAnalyzersEnabled && isAnalyzeEmailsDisconnected) ||
        (isSendEmailsEnabled && isSendEmailsDisconnected) ||
        (isSoundWaveEnabled && isCalendarDisconnected))
    ) {
      setIsInitialized(true);
      setIsOpened(true);
    }
  }, [
    isAnalyzeEmailsDisconnected,
    isCalendarDisconnected,
    isEmailAnalyzersEnabled,
    isInitialized,
    isSendEmailsDisconnected,
    isSendEmailsEnabled,
    isSoundWaveEnabled,
  ]);

  if (!isOpened) {
    return null;
  }

  return (
    <div {...attrs.container}>
      <div {...attrs.contentWrapper}>
        <WarningIcon {...attrs.icon} />
        <span {...attrs.title}>Permission Required</span>
        <span {...attrs.text}>
          It looks like you haven’t granted permission to your Google account.
          Please grant access to view updated results.
        </span>
      </div>
      <div {...attrs.contentWrapper}>
        <Button {...attrs.dismissButton}>Dismiss</Button>
        <Button {...attrs.grantPermissionButton}>Grant Permission</Button>
      </div>
    </div>
  );
};
