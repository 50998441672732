import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Duration } from 'luxon';
import { Skeleton } from 'antd';
import { EventAnalysisCompare } from '../../../../../../components/EventAnalysisCompare/EventAnalysisCompare';
import { ChevronDownIcon } from '../../../../../../components';
import { TurnsScale } from './components/TurnsScale/TurnsScale';
import {
  Avatar,
  AvatarSize,
} from '../../../../../../components/SoundWave/components/Avatar/Avatar';
import { useVideoPlayerContext } from '../../../../context/VideoPlayerContext';
import {
  MeetingParticipantWithContactInfo,
  MeetingTranscriptWithParticipantInfo,
} from '../../../../../../types/meetingDetails.types';

const SCALE_STEP = 15;
const SCALE_ITEM_WIDTH = 56;
const MINIMUM_TURN_WIDTH = SCALE_ITEM_WIDTH / SCALE_STEP;

export interface TurnsAnalysisProps {
  isLoading?: boolean;
  participants?: MeetingParticipantWithContactInfo[];
  transcripts?: MeetingTranscriptWithParticipantInfo[];
}

export const TurnsAnalysis: React.FC<TurnsAnalysisProps> = ({
  isLoading: isLoadingProp = false,
  participants = [],
  transcripts = [],
}) => {
  const [
    {
      isPlaying,
      isChangingTimeline,
      duration,
      timelineState,
      reactPlayerInstanceRef,
    },
    { setIsChangingTimeline },
  ] = useVideoPlayerContext();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingCurrentTime, setIsDraggingCurrentTime] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isChangingTimelineInternal, setIsChangingTimelineInternal] = useState(
    false
  );

  const compareContentRef = useRef<HTMLDivElement>(null);
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const currentTimeWrapperRef = useRef<HTMLDivElement>(null);
  const currentTimeRef = useRef<HTMLDivElement>(null);
  const currentTimeLineRef = useRef<HTMLDivElement>(null);

  const dragStartX = useRef<number>(0);
  const currentTimeDragStartX = useRef<number>(0);
  const scrollLeft = useRef<number>(0);

  const sortedParticipants = useMemo(
    () =>
      participants.sort((a, b) => {
        const firstStatsValue = a.stats?.talkTime?.percentage || null;
        const secondStatsValue = b.stats?.talkTime?.percentage || null;

        if (firstStatsValue === null) {
          return 1;
        }

        if (secondStatsValue === null) {
          return -1;
        }

        return secondStatsValue - firstStatsValue;
      }),
    [participants]
  );

  const scaleWidth = useMemo(() => duration * MINIMUM_TURN_WIDTH, [duration]);

  const isLoading = isLoadingProp || !duration;
  const currentPlayedOffset = timelineState.played * scaleWidth;
  const timelineWidth = isLoading ? '100%' : `max(100%, ${scaleWidth + 34}px)`;
  const playedSecondsString = Duration.fromObject({
    seconds: timelineState.playedSeconds,
  })
    .shiftTo('minutes', 'seconds')
    .toFormat('m:ss');

  const timelineItemsCount =
    Math.ceil(
      (contentWrapperRef.current?.scrollWidth || 0) / SCALE_ITEM_WIDTH
    ) + 1;

  const attrs = {
    container: {
      className: `turns-analysis${
        isExpanded ? ' turns-analysis--expanded' : ''
      }${isDragging ? ' turns-analysis--dragging' : ''}
      ${isChangingTimelineInternal ? ' turns-analysis--changing-timeline' : ''}
      ${isPlaying ? ' turns-analysis--playing' : ''}
      ${isPlayed ? ' turns-analysis--played' : ''}${
        isLoading ? ' turns-analysis--loading' : ''
      }`,
    },
    eventAnalysisCompare: {
      contentRef: compareContentRef,
      title: 'Turns',
      tooltip:
        'Visual representation of the sequence and duration of speaking turns by each participant. Each color represents a different speaker, and the horizontal length indicates their relative talk time, providing insights into the flow and balance of the conversation.',
      renderHeaderButton: () => {
        const buttonProps = {
          type: 'button' as const,
          className: 'turns-analysis__button',
          disabled: isLoading,
          onClick: () => {
            setIsExpanded((prevState) => !prevState);
          },
        };

        const iconProps = {
          className: 'turns-analysis__button-icon',
        };

        return (
          <button {...buttonProps}>
            <span>{isExpanded ? 'Hide breakdown' : 'Show breakdown'}</span>
            <ChevronDownIcon {...iconProps} />
          </button>
        );
      },
    },
    contentWrapper: {
      ref: contentWrapperRef,
      className: 'turns-analysis__content-wrapper',
      onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isPlaying) {
          setIsDragging(true);

          if (contentWrapperRef.current) {
            dragStartX.current =
              e.clientX - contentWrapperRef.current.offsetLeft;
            scrollLeft.current = contentWrapperRef.current.scrollLeft;
          }
        }
      },
    },
    content: {
      ref: contentRef,
      className: 'turns-analysis__content',
      style: {
        width: isLoading ? '100%' : `max(100%, ${scaleWidth}px)`,
      },
    },
    currentTime: {
      ref: currentTimeRef,
      className: 'turns-analysis__current-time',
      onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsChangingTimeline(true);
        setIsChangingTimelineInternal(true);
        setIsDraggingCurrentTime(true);
        currentTimeDragStartX.current = e.clientX - currentPlayedOffset;
      },
    },
    currentTimeWrapper: {
      ref: currentTimeWrapperRef,
      className: 'turns-analysis__current-time-wrapper',
      style: {
        minWidth: isLoading ? '100%' : `${scaleWidth}px`,
      },
    },
    currentTimeLine: {
      ref: currentTimeLineRef,
      className: 'turns-analysis__current-time-line',
    },
    timeline: {
      className: 'turns-analysis__timeline',
      style: {
        minWidth: timelineWidth,
      },
      onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        if (contentWrapperRef.current && currentTimeRef.current && isPlayed) {
          const offset =
            (e.clientX +
              contentWrapperRef.current.scrollLeft -
              contentWrapperRef.current.getBoundingClientRect().left -
              currentTimeRef.current.clientWidth / 2) /
            scaleWidth;

          reactPlayerInstanceRef?.current?.seekTo(offset, 'fraction');
        }
      },
    },
    totalTurns: {
      className: 'turns-analysis__total-turns',
      style: {
        minWidth: timelineWidth,
      },
    },
    totalTurnsScale: {
      duration,
      transcripts,
      scaleWidth,
    },
    totalTurnsSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: '100%',
      },
      title: {
        style: {
          width: '100%',
          height: 6,
          margin: 0,
          borderRadius: 6,
        },
      },
    },
    turnItemsWrapper: {
      className: 'turns-analysis__turn-items-wrapper',
      style: {
        minWidth: timelineWidth,
      },
    },
  };

  const timelineItems = useMemo(
    () =>
      Array.from({
        length: timelineItemsCount,
      }).map((_, index) => {
        const isEven = !(index % 2);

        const props = {
          key: index,
          className: `turns-analysis__timeline-item${
            isEven ? '' : ' turns-analysis__timeline-item--divider'
          }`,
        };

        const text = isEven
          ? Duration.fromObject({ seconds: index * SCALE_STEP })
              .shiftTo('minutes', 'seconds')
              .toFormat('m:ss')
          : '';

        return <div {...props}>{text}</div>;
      }),
    [timelineItemsCount]
  );

  const turnItems = useMemo(
    () =>
      sortedParticipants.map(({ alias, contact, side, participantId }) => {
        const name = contact?.metadata?.name || alias || '';
        const props = {
          turnItem: {
            key: participantId,
            className: 'turns-analysis__turn-item',
          },
          turnItemParticipant: {
            'data-turn-item-participant-id': participantId,
            className: 'turns-analysis__turn-item-participant',
          },
          turnItemParticipanName: {
            className: 'turns-analysis__turn-item-participant-name',
          },
          participantAvatar: {
            name,
            side,
            src: contact?.metadata?.avatarSrc || '',
            size: AvatarSize.XS,
          },
          turnsScale: {
            duration,
            transcripts,
            scaleWidth,
            side,
            participantId,
          },
        };

        return (
          <div {...props.turnItem}>
            <div {...props.turnItemParticipant}>
              <Avatar {...props.participantAvatar} />
              <span {...props.turnItemParticipanName}>{name}</span>
            </div>
            <TurnsScale {...props.turnsScale} />
          </div>
        );
      }),
    [duration, scaleWidth, sortedParticipants, transcripts]
  );

  useEffect(() => {
    const mouseUpListener = () => {
      setIsDragging(false);
      setIsDraggingCurrentTime(false);
      setIsChangingTimeline(false);
      setIsChangingTimelineInternal(false);
    };

    const mouseMoveListener = (e: MouseEvent) => {
      if (isDragging && contentWrapperRef.current) {
        e.preventDefault();

        const scrollOffset =
          e.pageX - contentWrapperRef.current.offsetLeft - dragStartX.current;

        contentWrapperRef.current.scrollLeft =
          scrollLeft.current - scrollOffset;
      }

      if (
        isChangingTimeline &&
        isChangingTimelineInternal &&
        currentTimeRef.current
      ) {
        e.preventDefault();
        const offset =
          (e.clientX - currentPlayedOffset - currentTimeDragStartX.current) /
          scaleWidth;

        reactPlayerInstanceRef?.current?.seekTo(
          timelineState.played + offset,
          'fraction'
        );
      }
    };

    document.addEventListener('mouseup', mouseUpListener);
    document.addEventListener('mousemove', mouseMoveListener);

    return () => {
      document.removeEventListener('mouseup', mouseUpListener);
      document.removeEventListener('mousemove', mouseMoveListener);
    };
  }, [
    currentPlayedOffset,
    isChangingTimeline,
    isChangingTimelineInternal,
    isDragging,
    reactPlayerInstanceRef,
    scaleWidth,
    setIsChangingTimeline,
    timelineState.played,
  ]);

  useEffect(() => {
    if (isPlaying) {
      setIsPlayed(true);
    }
  }, [isPlaying]);

  useEffect(() => {
    const contentWrapperRefCurrent = contentWrapperRef.current;
    const currentTimeLineRefCurrent = currentTimeLineRef.current;

    if (contentWrapperRefCurrent && currentTimeLineRefCurrent) {
      currentTimeLineRefCurrent.style.height = `${
        contentWrapperRefCurrent.clientHeight - 6
      }px`;
    }
  }, [isExpanded]);

  useEffect(() => {
    const contentWrapperRefCurrent = contentWrapperRef.current;

    const handler = (e: Event) => {
      if (e.currentTarget instanceof HTMLElement) {
        const { scrollLeft: scrollLeftValue } = e.currentTarget;

        e.currentTarget
          .querySelectorAll('[data-turn-item-participant-id]')
          .forEach((element) => {
            if (element instanceof HTMLElement) {
              element.style.transform = `translateX(${scrollLeftValue || 0}px)`;
            }
          });
      }
    };

    if (contentWrapperRefCurrent) {
      contentWrapperRefCurrent.addEventListener('scroll', handler);
    }

    return () => {
      contentWrapperRefCurrent?.removeEventListener('scroll', handler);
    };
  }, []);

  useLayoutEffect(() => {
    const compareContentRefCurrent = compareContentRef.current;
    const contentWrapperRefCurrent = contentWrapperRef.current;
    const contentRefCurrent = contentRef.current;
    const currentTimeWrapperRefCurrent = currentTimeWrapperRef.current;
    const currentTimeRefCurrent = currentTimeRef.current;
    const currentTimeLineRefCurrent = currentTimeLineRef.current;

    const isElementsExist =
      compareContentRefCurrent &&
      contentWrapperRefCurrent &&
      contentRefCurrent &&
      currentTimeWrapperRefCurrent &&
      currentTimeRefCurrent &&
      currentTimeLineRefCurrent;

    const scrollOffset = Math.floor(
      currentPlayedOffset +
        17 -
        (contentWrapperRefCurrent?.scrollLeft || 0) -
        (contentWrapperRefCurrent?.clientWidth || 0) / 2
    );

    const maxScrollOffset = Math.max(1, Math.ceil(1 / window.devicePixelRatio));

    const shouldScroll =
      isElementsExist && !isDragging && !isDraggingCurrentTime && isPlaying;

    if (shouldScroll) {
      contentWrapperRefCurrent.scrollLeft += scrollOffset;
    }

    if (shouldScroll && scrollOffset >= 0 && scrollOffset <= maxScrollOffset) {
      compareContentRefCurrent.style.position = 'relative';
      contentRefCurrent.style.position = 'static';
      currentTimeWrapperRefCurrent.style.position = 'static';
      currentTimeRefCurrent.style.left = '50%';
      currentTimeLineRefCurrent.style.left = '50%';
      currentTimeRefCurrent.style.transform = 'translateX(-50%)';
      currentTimeLineRefCurrent.style.transform = 'translateX(-50%)';
    } else if (isElementsExist) {
      compareContentRefCurrent.style.position = '';
      contentRefCurrent.style.position = '';
      currentTimeWrapperRefCurrent.style.position = '';
      currentTimeRefCurrent.style.left = '';
      currentTimeLineRefCurrent.style.left = '';
      currentTimeRefCurrent.style.transform = `translateX(${currentPlayedOffset}px)`;
      currentTimeLineRefCurrent.style.transform = `translateX(${currentPlayedOffset}px)`;
    }
  }, [currentPlayedOffset, isDragging, isDraggingCurrentTime, isPlaying]);

  return (
    <div {...attrs.container}>
      <EventAnalysisCompare {...attrs.eventAnalysisCompare}>
        <div {...attrs.contentWrapper}>
          <div {...attrs.content}>
            <div {...attrs.currentTimeWrapper}>
              <div {...attrs.currentTime}>{playedSecondsString}</div>
              <div {...attrs.currentTimeLine} />
            </div>
            <div {...attrs.timeline}>{timelineItems}</div>
            {!isExpanded ? (
              <div {...attrs.totalTurns}>
                {isLoading ? (
                  <Skeleton {...attrs.totalTurnsSkeleton} />
                ) : (
                  <TurnsScale {...attrs.totalTurnsScale} />
                )}
              </div>
            ) : null}
            <div {...attrs.turnItemsWrapper}>{turnItems}</div>
          </div>
        </div>
      </EventAnalysisCompare>
    </div>
  );
};
