export const getIsLoading = ({
  isMeetingDetailsLoading,
  isMeetingDetailsError,
  isThreadDetailsLoading,
  isThreadDetailsError,
  isMeetingDetailsPage,
  isThreadDetailsPage,
}: {
  isMeetingDetailsLoading: boolean;
  isMeetingDetailsError: boolean;
  isThreadDetailsLoading: boolean;
  isThreadDetailsError: boolean;
  isMeetingDetailsPage: boolean;
  isThreadDetailsPage: boolean;
}): boolean => {
  if (isMeetingDetailsPage) {
    return isMeetingDetailsLoading || isMeetingDetailsError;
  }

  if (isThreadDetailsPage) {
    return isThreadDetailsLoading || isThreadDetailsError;
  }

  return false;
};
