import React, { useState } from 'react';
import { Team } from '../../../../../../store/teams/teams.reducer';
import LabelValueRow from '../../../../../../components/LabelValueRow/LabelValueRow';
import {
  Avatar,
  AvatarVariant,
} from '../../../../../../components/Avatar/Avatar';
import { Row } from '../../../../../../types';
import { TeamProfileMemersModal } from './membersModal/TeamProfileMemersModal';
import { sortTeamMembers } from '../../utils/sortMemebersArray';

type Props = {
  team: Team;
};

export const TeamProfile: React.FC<Props> = ({ team }): JSX.Element => {
  const [membersModalOpen, setMembersModalOpen] = useState(false);

  const toggleMembersModal = () => {
    setMembersModalOpen(!membersModalOpen);
  };

  const attrs = {
    MembersModal: {
      team,
      open: membersModalOpen,
      onClose: toggleMembersModal,
    },

    wrapper: {
      className: 'team-profile',
    },

    header: {
      className: 'team-profile-header',
    },

    body: {
      wrapper: {
        className: 'team-profile__body',
      },

      CreatedBy: {
        label: 'Team leader',
        value: team.members.find((el) => el.teamMemberRole === 'teamLeader')
          ?.name,
        rowHeight: '40px',
      },

      avatarRow: {
        wrapper: {
          className: 'team-profile__body__avatar-row',
        },

        title: {
          className: 'team-profile__body__avatar-row-title',
        },

        iconsSection: {
          wrapper: {
            className: 'team-profile__body__avatar-row__icons',
            onClick: toggleMembersModal,
          },

          avatars: {
            first: {
              className: 'team-profile__body__avatar-row__icons-first',
            },

            second: {
              className: 'team-profile__body__avatar-row__icons-second',
            },

            third: {
              className: 'team-profile__body__avatar-row__icons-third',
            },

            number: {
              className: 'team-profile__body__avatar-row__icons-number',
            },
          },
        },
      },
    },

    footerLink: {
      className: 'team-profile__footer-link',
      onClick: toggleMembersModal,
    },
  };

  const createAvatarsSection = (members: Row[]) => {
    let result = null;

    const addVariantType = (index: number) => {
      let variantType: AvatarVariant = 'secondaryD';

      if (index % 1 === 1) {
        variantType = 'primaryL';
      } else if (index % 2 === 0) {
        variantType = 'primaryD';
      } else if (index % 3 === 0) {
        variantType = 'secondaryL';
      }

      return variantType;
    };

    const defineAvatarClassName = (index: number) => {
      let resultClassName = 'team-profile__body__avatar-row__icons-fourth';

      if (index === 0) {
        resultClassName = 'team-profile__body__avatar-row__icons-first';
      } else if (index === 1) {
        resultClassName = 'team-profile__body__avatar-row__icons-second';
      } else if (index === 2) {
        resultClassName = 'team-profile__body__avatar-row__icons-third';
      }

      return resultClassName;
    };

    if (members?.length) {
      if (members.length === 1) {
        const singleAvatar = members.map((el) => (
          <Avatar
            key={el.email}
            image={el.avatar || ''}
            userName={el.name || ''}
            className="team-profile__body__avatar-row__icons-third"
            variant="primaryD"
            isTwentyPx
          />
        ));

        result = (
          <div
            {...attrs.body.avatarRow.iconsSection.wrapper}
            style={{ left: '15px' }}
          >
            {singleAvatar}
          </div>
        );
      } else if (members.length === 2) {
        const avatars = members.map((el, i) => (
          <Avatar
            key={el.email}
            image={el.avatar || ''}
            userName={el.name || ''}
            className={
              i === 0
                ? 'team-profile__body__avatar-row__icons-second'
                : 'team-profile__body__avatar-row__icons-third'
            }
            variant={el.avatar ? '' : addVariantType(i)}
            isTwentyPx
          />
        ));

        result = (
          <div
            {...attrs.body.avatarRow.iconsSection.wrapper}
            style={{ left: '15px' }}
          >
            {avatars}
          </div>
        );
      } else if (members.length === 3) {
        const slicedAvatarsArray = members
          .slice(0, 3)
          .map((el, i) => (
            <Avatar
              key={el.email}
              image={el.avatar || ''}
              userName={el.name || ''}
              className={defineAvatarClassName(i)}
              variant={el.avatar ? '' : addVariantType(i)}
              isTwentyPx
              hasBorder
            />
          ));

        result = (
          <div
            {...attrs.body.avatarRow.iconsSection.wrapper}
            style={{ left: '15px' }}
          >
            {slicedAvatarsArray}{' '}
          </div>
        );
      } else if (members.length > 3) {
        const numberOfMemebersToSlice = members.length === 4 ? 4 : 3;

        const slicedAvatarsArray = members
          .slice(0, numberOfMemebersToSlice)
          .map((el, i) => (
            <Avatar
              key={el.email}
              image={el.avatar || ''}
              userName={el.name || ''}
              className={defineAvatarClassName(i)}
              variant={el.avatar ? '' : addVariantType(i)}
              isTwentyPx
              hasBorder
            />
          ));

        result = (
          <div {...attrs.body.avatarRow.iconsSection.wrapper}>
            {slicedAvatarsArray}{' '}
            <span {...attrs.body.avatarRow.iconsSection.avatars.number}>
              {members.length - 3}
            </span>
          </div>
        );
      }
    }

    return result;
  };

  const avatars = createAvatarsSection(sortTeamMembers(team.members));

  const rowMembers = (
    <div {...attrs.body.avatarRow.wrapper}>
      <div {...attrs.body.avatarRow.title}>Members</div>
      {avatars}
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header}>{team.name}</div>
      <div {...attrs.body.wrapper}>
        <LabelValueRow {...attrs.body.CreatedBy} />
        {rowMembers}
      </div>
      <div {...attrs.footerLink}>View team members</div>
      <TeamProfileMemersModal {...attrs.MembersModal} />
    </div>
  );
};
