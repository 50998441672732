import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import { UpperHandPossessions } from '../../../../../store/charts/charts.types';
import { uhEmail } from '../../../icons';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { AvgResponseSkeleton } from '../../skeletons/AvgResponseSkeleton';

type Data = {
  date: string;
  avgWinLoseScore: number;
}[];

type Props = {
  upperHandData?: UpperHandPossessions | null;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  lastDays?: number;
  isInTeamQ?: boolean;
};

export const UpperHandChart: React.FC<Props> = ({
  upperHandData,
  isLoading,
  noData,
  error,
  lastDays,
  isInTeamQ,
}): JSX.Element => {
  const [data, setData] = useState<Data>([]);

  const [total, setTotal] = useState(0);

  const [currentValue, setCurrentValue] = useState('');

  const [areaHovered, setAreaHovered] = useState(false);

  const valueRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!upperHandData?.currentPeriodDailyStats?.length) {
      setCurrentValue('');
    }
    if (
      upperHandData?.currentPeriod?.emailsAccounted &&
      upperHandData?.currentPeriod?.uhEmails
    ) {
      const avg =
        (upperHandData.currentPeriod.uhEmails /
          upperHandData.currentPeriod.emailsAccounted) *
        100;
      setCurrentValue(`${avg.toFixed(0)}%`);
    }
    if (upperHandData?.currentPeriodDailyStats?.length) {
      // const customData: Data = []; // eslint-disable-next-line
      // for (let i = 0; i < 20; i++) {
      //   customData.push({
      //     date: `Jan ${i + 1}`,
      //     avgWinLoseScore: Math.floor(Math.random() * 5),
      //   });
      // }
      // setData(customData);

      if (upperHandData?.currentPeriodDailyStats?.length) {
        setTotal(
          Math.max(
            ...upperHandData.currentPeriodDailyStats.map(
              (el) => el.avgWinLoseScore
            )
          )
        );
      }
      setData(upperHandData.currentPeriodDailyStats);
    } // eslint-disable-next-line
  }, [upperHandData]);

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
      style: { width: '88px', padding: '8px' },
    },
    bodyWrapper: {
      className: 'chart-tooltip__body-wrapper',
      style: { padding: '0px' },
    },
    date: {
      className: 'uh chart-tooltip__date',
    },
    value: {
      className: 'uh chart-tooltip__value',
    },
    dot: {
      className: 'uh-dot chart-tooltip__value',
    },
    boldText: {
      className: 'chart-tooltip__percents-text-bold',
    },
    boldTextGrey: {
      className: 'chart-tooltip__percents-text-bold-grey',
    },
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;
    if (active && data.length) {
      const formattedDate = payload[0]?.payload?.date
        ? moment(payload[0].payload.date).format('MMM D, YYYY')
        : '';

      const value =
        typeof payload[0]?.payload?.avgWinLoseScore === 'number'
          ? `${(payload[0]?.payload?.avgWinLoseScore * 100).toFixed(0)}%`
          : '0%';

      const conditionalMessage = (
        <div {...tooltipAttrs.value}>
          <div {...tooltipAttrs.dot} />
          {value}
        </div>
      );

      result = (
        <div {...tooltipAttrs.wrapper}>
          <div {...tooltipAttrs.bodyWrapper}>
            <span {...tooltipAttrs.date}>{formattedDate}</span>
            {conditionalMessage}
          </div>
        </div>
      );
    }
    return result;
  };

  const currentPeriod = upperHandData?.currentPeriod?.emailsAccounted
    ? upperHandData?.currentPeriod?.uhEmails /
      upperHandData?.currentPeriod?.emailsAccounted
    : 0;

  const previousPeriod =
    upperHandData?.previousPeriod?.emailsAccounted !== 'no-data' &&
    upperHandData?.previousPeriod?.emailsAccounted &&
    upperHandData?.previousPeriod?.uhEmails !== 'no-data'
      ? upperHandData?.previousPeriod?.uhEmails /
        upperHandData?.previousPeriod?.emailsAccounted
      : 0;

  const uhEmails = upperHandData?.currentPeriod?.uhEmails;

  const totalEmails = upperHandData?.currentPeriod?.emailsAccounted;

  const calculateDifference = () => {
    let res = 0;

    if (previousPeriod && currentPeriod) {
      res = (currentPeriod - previousPeriod) * 100;
    }

    return res;
  };
  const percentsValue = calculateDifference();

  const getValueToShow = () => {
    let value: number | string = 0;

    const isIncludesDot = percentsValue.toString().includes('.');

    const valueLength = percentsValue.toString().length;

    if (percentsValue !== 0) {
      if (
        isIncludesDot &&
        valueLength > 2 &&
        (percentsValue >= 0.1 || percentsValue < -0.1)
      ) {
        value =
          percentsValue.toFixed(1).split('.')[1].slice(0, 1) === '0'
            ? percentsValue.toFixed(0)
            : percentsValue.toFixed(1);
      } else if (!isIncludesDot && valueLength >= 1) {
        value = percentsValue;
      } else {
        value = 0;
      }
    } else if (!previousPeriod) {
      value = 'N/A';
    } else if (previousPeriod === currentPeriod) {
      value = 0;
    }

    return value;
  };
  const footerUhValueDifference = getValueToShow();

  const isPercentsSymbolShown = () => {
    let res = '';

    if (!previousPeriod) {
      res = '';
    } else if (percentsValue || percentsValue === 0) {
      res = '%';
    }

    return res;
  };
  const conditionalPercentsSymbol = isPercentsSymbolShown();

  const getValueClassName = () => {
    let colorStyle = 'stats__upper-hand__values-percents-grey';

    if (percentsValue < -0.1 && percentsValue?.toString().length > 0) {
      colorStyle = 'centered stats__upper-hand__values-percents-red';
    } else if (percentsValue >= 0.1) {
      colorStyle = 'stats__upper-hand__values-percents';
    }

    return colorStyle;
  };
  const conditionalClassName = getValueClassName();

  const attrs = {
    Wrapper: {
      title: 'Upper-Hand Ratio',
      subtitle: `${isInTeamQ ? 'Team avg, last' : 'Last'} ${
        lastDays ? lastDays - 1 : ''
      } days`,
      tooltipText: isInTeamQ
        ? `The percentage of messages when team members had the Upper-Hand. 
        This number is based on the messages 
        in which they were the senders or the main recipients.`
        : `The percentage of messages when you had the Upper-Hand. 
      This number is based on the messages in which you were the sender or main recipient.`,
      isNoData: noData || error,
      isInTeamQ,
    },
    valueOnTop: {
      ref: valueRef,
      className: 'charts__upper-hand-value-on-top',
      style: {
        // eslint-disable-next-line
        position: 'absolute' as any,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -60px)',
      },
    },
    ResponsiveContainer: { height: '95%' },
    AreaChart: {
      data,
      margin: isInTeamQ
        ? { top: 40, right: 8, left: -55, bottom: 5 }
        : { top: 70, right: 35, left: -25, bottom: 10 },
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    CartesianGrid: {
      strokeDasharray: '0 0',
      vertical: false,
      stroke: '#E7EAFF',
      strokeWidth: 0,
    },
    Tooltip: {
      wrapperStyle:
        noData || error
          ? { display: 'none' }
          : { top: '-75px', left: '-55px', width: '120px' },
      content: <CustomTooltip payload={data} active />,
      allowEscapeViewBox: { x: true, y: true },
      isAnimationActive: false,
    },
    yAxis: {
      dataKey: 'avgWinLoseScore',
      tickLine: false,
      axisLine: false,
      domain: noData ? ['auto'] : [0, total * 1.8],
      display: 'none',
    },
    Area: {
      type: 'monotoneY' as CurveType,
      dataKey: 'avgWinLoseScore',
      stroke: areaHovered ? '#2F86C7' : '#4EB4FF',
      strokeWidth: 2,
      fillOpacity: 1,
      fill: 'url(#avgWinLoseScore)',
      onMouseEnter: () => setAreaHovered(true),
      onMouseLeave: () => setAreaHovered(false),
    },
    AreaGradient: { id: 'avgWinLoseScore', x1: '0', y1: '0', x2: '0', y2: '1' },
    svgStopOne: {
      offset: areaHovered ? '2%' : '5%',
      stopColor: areaHovered ? '#2F86C7' : '#4EB4FF',
      stopOpacity: 1,
    },
    svgStopTwo: {
      offset: '95%',
      stopColor: '#4EB4FF',
      stopOpacity: 0.08,
    },
    footer: {
      wrapper: { className: 'stats__upper-hand__footer' },
      icon: {
        className: 'stats__upper-hand__footer-icon',
        src: uhEmail,
      },
      emails: { className: 'stats__upper-hand__footer__emails' },
      tooltip: { className: 'stats__upper-hand__footer__emails-tooltip' },
      divider: { className: 'stats__upper-hand__footer-divider' },
      differenceInPercs: { className: conditionalClassName },
      fromPrevPeriodText: {
        className: 'stats__upper-hand__footer__from-prev',
      },
    },
  };

  const footer = (
    <div {...attrs.footer.wrapper}>
      <div {...attrs.footer.emails}>
        <img {...attrs.footer.icon} alt="" /> {uhEmails}/{totalEmails}
        <div {...attrs.footer.tooltip}>
          Based on {totalEmails} {totalEmails === 1 ? 'message' : 'messages'},
          {isInTeamQ ? ' your team' : ' you'} had the Upper-Hand in {uhEmails}{' '}
          {uhEmails === 1 ? 'message' : 'messages'}.
        </div>
      </div>
      <div {...attrs.footer.divider} />
      <span {...attrs.footer.differenceInPercs}>
        {percentsValue >= 0.1
          ? `+${footerUhValueDifference}`
          : footerUhValueDifference}
        {conditionalPercentsSymbol}
        <span {...attrs.footer.fromPrevPeriodText}>
          from previous {data?.length ? `${data.length - 1} days` : 'period'}
        </span>
      </span>
    </div>
  );

  const conditionalChartBody =
    !upperHandData || noData || error ? (
      <></>
    ) : (
      <>
        <div {...attrs.valueOnTop}>{currentValue}</div>
        <ResponsiveContainer {...attrs.ResponsiveContainer}>
          <AreaChart {...attrs.AreaChart}>
            <defs>
              <linearGradient {...attrs.AreaGradient}>
                <stop {...attrs.svgStopOne} />
                <stop {...attrs.svgStopTwo} />
              </linearGradient>
            </defs>
            <CartesianGrid {...attrs.CartesianGrid} />
            <YAxis {...attrs.yAxis} />
            <Area {...attrs.Area} />
            <Tooltip {...attrs.Tooltip} />
          </AreaChart>
        </ResponsiveContainer>
        {footer}
      </>
    );

  return (
    <AvgResponseSkeleton isLoading={isLoading} isTeamQ={isInTeamQ}>
      <ChartWrapper {...attrs.Wrapper}>{conditionalChartBody}</ChartWrapper>
    </AvgResponseSkeleton>
  );
};
