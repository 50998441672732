import React from 'react';

export const GoogleMeetIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="28"
    viewBox="0 0 32 28"
    fill="none"
    {...props}
  >
    <path
      d="M0 25.2385C0 26.4213 0.94547 27.3792 2.11037 27.3792H2.14071C0.957966 27.3792 0 26.4213 0 25.2385Z"
      fill="#FBBC05"
    />
    <path
      d="M18.1351 8.64801V14.237L25.508 8.15921V2.76107C25.508 1.57833 24.5626 0.620361 23.3977 0.620361H7.42443L7.41016 8.64801H18.1351Z"
      fill="#FBBC05"
    />
    <path
      d="M18.1381 19.8278H7.3953L7.38281 27.3791H23.4006C24.5673 27.3791 25.511 26.4212 25.511 25.2384V20.3648L18.1381 14.2388V19.8278Z"
      fill="#34A853"
    />
    <path
      d="M7.42468 0.620361L0 8.64801H7.41219L7.42468 0.620361Z"
      fill="#EA4335"
    />
    <path
      d="M0 19.8281V25.2388C0 26.4215 0.957966 27.3795 2.14071 27.3795H7.38008L7.39257 19.8281H0Z"
      fill="#1967D2"
    />
    <path
      d="M7.41219 8.64795H0V19.8278H7.39257L7.41219 8.64795Z"
      fill="#4285F4"
    />
    <path
      d="M31.9888 23.3353V4.96088C31.5731 2.52226 28.9561 5.31767 28.9561 5.31767L25.5078 8.15946V20.3633L30.4439 24.4645C32.2261 24.7035 31.9888 23.3353 31.9888 23.3353Z"
      fill="#34A853"
    />
    <path
      d="M18.1367 14.2372L25.5115 20.3649V8.16113L18.1367 14.2372Z"
      fill="#188038"
    />
  </svg>
);
