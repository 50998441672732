import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  ButtonSize,
  ConfirmationModalProps,
  FormFieldProps,
  FormProps,
  ModalProps,
} from '../../../../../components/SoundWave';
import { FormFieldType } from '../../../../../components/SoundWave/components/FormField/FormField';
import { useId } from '../../../../../hooks';
import { sendContactRequest } from '../../../../../store/global/global.thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

export interface ManageSubscriptionFormValues {
  reason: string;
  text: string;
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().required(),
  text: Yup.string().required(),
});

export const useManageSubscriptionModal = ({
  isOpened,
  onClose,
}: {
  isOpened: boolean;
  onClose: () => void;
}): {
  modalProps: ModalProps;
  formProps: FormProps;
  fieldProps: Record<keyof ManageSubscriptionFormValues, FormFieldProps>;
  confirmationModalProps: ConfirmationModalProps;
  isNoteFieldHidden: boolean;
} => {
  const dispatch = useAppDispatch();

  const isContactRequestLoading = useAppSelector(
    (state) => state.global.isLoading.contactRequest
  );

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(
    false
  );

  const [
    initialValues,
    setInitialValues,
  ] = useState<ManageSubscriptionFormValues>({
    reason: '',
    text: '',
  });

  const formId = useId();

  const {
    dirty,
    touched,
    values,
    isValid,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik<ManageSubscriptionFormValues>({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async ({ reason, text }) => {
      try {
        await dispatch(
          sendContactRequest({
            reason,
            text,
          })
        ).unwrap();

        onClose();
        setIsConfirmationModalOpened(true);
      } catch (error) {
        onClose();
      }
    },
  });

  const handleChange = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<any, Element>) => {
      setFieldError(e.target.name, '');
      setFieldTouched(e.target.name, false);
    },
    [setFieldError, setFieldTouched]
  );

  const modalProps = useMemo(
    () => ({
      onClose,
      isOpened,
      width: 384,
      closeOnClickOutside: true,
      isConfirmation: true,
      className: 'billing__modal',
      onAnimationEnd: () => {
        setInitialValues(() => ({ reason: '', text: '' }));
      },
      headerProps: {},
      footerProps: {
        formId,
        isLoading: isContactRequestLoading,
        confirmButtonText: 'Submit',
        buttonsSize: ButtonSize.XS,
        isCancelButtonHidden: true,
        isConfirmButtonSecondary: true,
        isConfirmButtonDisabled: !isValid || !dirty,
      },
    }),
    [dirty, isOpened, formId, isContactRequestLoading, isValid, onClose]
  );

  const confirmationModalProps = useMemo(
    () => ({
      isOpened: isConfirmationModalOpened,
      title: 'Thank you for your request.',
      description: 'Our team will review it and follow up shortly.',
      showHeader: true,
      buttonProps: {
        text: 'Close',
        isCloseButton: true,
      },
      onClose: () => {
        setIsConfirmationModalOpened(false);
      },
    }),
    [isConfirmationModalOpened]
  );

  const formProps = useMemo(
    () => ({
      id: formId,
      onSubmit: handleSubmit,
    }),
    [formId, handleSubmit]
  );

  const fieldProps = useMemo(
    () => ({
      reason: {
        type: FormFieldType.SELECT,
        name: 'reason',
        label: 'Select Option:',
        placeholder: 'Choose one',
        value: values.reason,
        touched: touched.reason,
        options: [
          { label: 'Manage Invoices', value: 'Manage Invoices' },
          { label: 'Change Plan', value: 'Change Plan' },
          { label: 'Cancel Plan', value: 'Cancel Plan' },
          { label: 'Update Payment Method', value: 'Update Payment Method' },
          { label: 'Other', value: 'Other' },
        ],
        onChange: handleChange,
      },
      text: {
        type: FormFieldType.TEXTAREA,
        name: 'text',
        placeholder: 'Could you please tell us more about your request',
        value: values.text,
        touched: touched.text,
        className: 'billing__modal-textarea',
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
    }),
    [handleBlur, handleChange, handleFocus, touched, values]
  );

  useEffect(() => {
    resetForm({ values: initialValues, errors: {}, touched: {} });
  }, [initialValues, resetForm]);

  return {
    modalProps,
    formProps,
    fieldProps,
    confirmationModalProps,
    isNoteFieldHidden: values.reason === '',
  };
};
