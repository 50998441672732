import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { groupBy } from '../../utils/groupBy';
import { RootState } from '../store';
import {
  getIsFilterChecked,
  isDateRangeFilterValue,
  isRangeFilterValue,
} from './meetingRecords.utils';

export const updatedFiltersSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingRecords.filters,
  (filters) => {
    return filters.filter(({ value, defaultValue, isActive }) =>
      getIsFilterChecked(value, defaultValue, isActive)
    );
  }
);

export const unsavedFiltersSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingRecords.filters,
  (filters) => {
    return filters.filter(({ value, previousValue }) => {
      if (isRangeFilterValue(value) && isRangeFilterValue(previousValue)) {
        return (
          value.min !== previousValue.min || value.max !== previousValue.max
        );
      }

      if (
        isDateRangeFilterValue(value) &&
        isDateRangeFilterValue(previousValue)
      ) {
        return (
          value.selectValue !== previousValue.selectValue ||
          value.startDate !== previousValue.startDate ||
          value.endDate !== previousValue.endDate
        );
      }

      return value !== previousValue;
    });
  }
);

export const appliedFiltersSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingRecords.filters,
  (filters) => {
    return filters.filter(({ defaultValue, previousValue }) => {
      if (
        isRangeFilterValue(defaultValue) &&
        isRangeFilterValue(previousValue)
      ) {
        return (
          previousValue.min !== defaultValue.min ||
          previousValue.max !== defaultValue.max
        );
      }

      if (
        isDateRangeFilterValue(defaultValue) &&
        isDateRangeFilterValue(previousValue)
      ) {
        return previousValue.selectValue !== defaultValue.selectValue;
      }

      return previousValue !== defaultValue;
    });
  }
);

export const groupedFiltersSelector = createDraftSafeSelector(
  (state: RootState) => state.meetingRecords.filters,
  (state: RootState) => state.auth.user,
  (filters, user) => {
    const availableFilters = filters.filter(
      (filter) =>
        !filter.restrictedFor ||
        (user && !filter.restrictedFor.includes(user.role))
    );

    return Object.values(
      groupBy(availableFilters, (filter) => filter.groupId || '')
    );
  }
);
