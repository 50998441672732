import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Button, Modal } from '../../../../components';
import {
  invokeNewLeaderModal,
  updateTeamMembers,
} from '../../../../store/teams/teams.actions';

type ReassignLeaderProps = {
  onClose: () => void;
  open: boolean;
};

export const AssignNewLeaderModal: React.FC<ReassignLeaderProps> = ({
  onClose,
  open,
}: ReassignLeaderProps): JSX.Element => {
  const { currentTeam, currentMember } = useSelector(
    (state: RootState) => state.teams
  );
  const { user } = useSelector((state: RootState) => state.auth);

  const [isReassigned, setIsReassigned] = useState(false);
  const dispatch = useDispatch();

  const reassignAndClose = () => {
    setIsReassigned(true);
    let arrWithChangedLeader;
    if (user && currentTeam && currentMember) {
      if (currentMember && currentTeam) {
        arrWithChangedLeader = currentTeam.members.map((el) => {
          if (el.teamMemberRole === 'teamLeader') {
            return { ...el, teamMemberRole: 'member' };
          }
          if (el.userId === currentMember.userId) {
            return { ...el, teamMemberRole: 'teamLeader' };
          }
          return el;
        });
      }
      if (arrWithChangedLeader) {
        const membersToUpdate = arrWithChangedLeader.map((el) => ({
          userId: el.userId,
          teamMemberRole: el.teamMemberRole as 'member' | 'teamLeader',
        }));
        dispatch(
          updateTeamMembers(
            user.orgId,
            user.userId,
            currentTeam.teamId,
            membersToUpdate,
            'addMembers'
          )
        );
      }
    }
  };

  const close = () => {
    onClose();
    dispatch(invokeNewLeaderModal(false));
  };

  useEffect(() => {
    if (!open) setIsReassigned(false);
  }, [open]);

  const currentTeamLeader = currentTeam?.members.find(
    (el) => el.teamMemberRole === 'teamLeader'
  );

  const conditionalModalChild = !isReassigned ? (
    <div className="delete-modal-body">
      A Team Leader has already been assigned
      <br />
      <span className="delete-modal-body__bold">
        ({currentTeamLeader?.name})
      </span>
      , do you wish to{' '}
      <span className="delete-modal-body__bold">make the switch </span>
      <br />
      and assign{' '}
      <span className="delete-modal-body__bold">{currentMember?.name} </span>
      as team leader?
      <Button
        className="delete-modal-body__btn"
        onClick={reassignAndClose}
        variant="common"
      >
        Proceed
      </Button>
    </div>
  ) : (
    <div className="deleted delete-modal-body">
      <span>Team leader has been reassigned successfully</span>
      <Button
        onClick={close}
        variant="common"
        className="delete-modal-body__btn"
      >
        Got it
      </Button>
    </div>
  );

  return (
    <Modal open={open} onClose={close} title="Team Leader" width="444px">
      {conditionalModalChild}
    </Modal>
  );
};
