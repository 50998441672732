import React from 'react';
import { Skeleton } from 'antd';
import { EventSummaryEmpty } from '../EventSummaryEmpty/EventSummaryEmpty';
import { EmailAssessmentInsight } from '../../../../types/emailAnalysis.types';

export interface EventSummarySignalsProps {
  emptyText?: string;
  isLoading?: boolean;
  signals?: EmailAssessmentInsight[] | string[];
}

export const EventSummarySignals: React.FC<EventSummarySignalsProps> = ({
  emptyText,
  isLoading = false,
  signals,
}) => {
  const attrs = {
    container: {
      className: 'event-summary-signals',
    },
    indicatorWrapper: {
      className: 'event-summary-signals__indicator-wrapper',
    },
    indicator: {
      className: 'event-summary-signals__indicator',
    },
    eventSummaryEmpty: {
      text: emptyText,
    },
    signalsSkeleton: {
      active: true,
      title: false,
      paragraph: {
        rows: 3,
        width: '100%',
        style: {
          margin: 0,
        },
      },
    },
  };

  if (isLoading) {
    return (
      <div {...attrs.container}>
        <Skeleton {...attrs.signalsSkeleton} />
      </div>
    );
  }

  if (signals?.length) {
    return (
      <div {...attrs.container}>
        {signals.map((signal, index) => {
          const { text, color } =
            typeof signal === 'string' ? { text: signal, color: '' } : signal;

          const props = {
            key: index,
            className: `event-summary-signals__item${
              color ? ` event-summary-signals__item--${color}` : ''
            }`,
          };

          return (
            <div {...props}>
              <div {...attrs.indicatorWrapper}>
                <div {...attrs.indicator} />
              </div>
              <div>{text}</div>
            </div>
          );
        })}
      </div>
    );
  }

  return <EventSummaryEmpty {...attrs.eventSummaryEmpty} />;
};
