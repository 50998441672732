import React from 'react';

export const FilterIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66699 4C2.66699 2.89543 3.56242 2 4.66699 2H11.3337C12.4382 2 13.3337 2.89543 13.3337 4V4.73952C13.3337 5.30649 13.093 5.84682 12.6716 6.2261L10.0003 8.63024V12.2546C10.0003 12.7597 9.71499 13.2214 9.26328 13.4472L7.92994 14.1139C7.04341 14.5571 6.00033 13.9125 6.00033 12.9213V8.63024L3.32906 6.22611C2.90764 5.84682 2.66699 5.30649 2.66699 4.73952V4ZM4.66699 3.33333C4.2988 3.33333 4.00033 3.63181 4.00033 4V4.73952C4.00033 4.92851 4.08054 5.10862 4.22102 5.23505L6.89228 7.63918C7.17323 7.89204 7.33366 8.25226 7.33366 8.63024V12.9213L8.66699 12.2546V8.63024C8.66699 8.25226 8.82742 7.89204 9.10837 7.63918L11.7796 5.23505C11.9201 5.10862 12.0003 4.92851 12.0003 4.73952V4C12.0003 3.63181 11.7018 3.33333 11.3337 3.33333H4.66699Z"
      fill="#070B12"
    />
  </svg>
);
