import React from 'react';
import { OptionIconProps } from '../../../components/Popup/PopupOption';

export const DeleteMemberPopupIcon: React.FC<OptionIconProps> = ({
  disabled,
}): JSX.Element => {
  const fill = disabled ? '#FFF' : '#FB7378';
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 -1 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={disabled ? '0.4' : '0.8'}
    >
      <rect
        x="0.000244141"
        y="1.83691"
        width="12"
        height="0.418402"
        rx="0.459201"
        fill={fill}
      />
      <rect
        x="1.84656"
        y="-0.5"
        width="8.30769"
        height="0.918402"
        rx="0.459201"
        fill={fill}
      />
      <mask id="path-3-inside-1_399_38516" fill={fill}>
        <path d="M0.923584 1.89746H11.0774L10.3205 10.1819C10.2264 11.2116 9.36282 11.9999 8.32876 11.9999H3.67225C2.63819 11.9999 1.77464 11.2116 1.68055 10.1819L0.923584 1.89746Z" />
      </mask>
      <path
        d="M0.923584 1.89746V0.897461H-0.171954L-0.0722676 1.98845L0.923584 1.89746ZM11.0774 1.89746L12.0733 1.98845L12.173 0.897461H11.0774V1.89746ZM1.68055 10.1819L0.684694 10.2729L1.68055 10.1819ZM10.3205 10.1819L11.3163 10.2729L10.3205 10.1819ZM0.923584 2.89746H11.0774V0.897461H0.923584V2.89746ZM10.0816 1.80647L9.32462 10.0909L11.3163 10.2729L12.0733 1.98845L10.0816 1.80647ZM8.32876 10.9999H3.67225V12.9999H8.32876V10.9999ZM2.6764 10.0909L1.91944 1.80647L-0.0722676 1.98845L0.684694 10.2729L2.6764 10.0909ZM3.67225 10.9999C3.15522 10.9999 2.72344 10.6058 2.6764 10.0909L0.684694 10.2729C0.825831 11.8175 2.12117 12.9999 3.67225 12.9999V10.9999ZM9.32462 10.0909C9.27757 10.6058 8.84579 10.9999 8.32876 10.9999V12.9999C9.87985 12.9999 11.1752 11.8175 11.3163 10.2729L9.32462 10.0909Z"
        fill={fill}
        mask="url(#path-3-inside-1_399_38516)"
        strokeWidth="0.2"
      />
      <rect
        x="5.07788"
        y="3.73438"
        width="4.59201"
        height="0.923077"
        rx="0.461538"
        transform="rotate(90 5.07788 3.73438)"
        fill={fill}
      />
      <rect
        x="7.84741"
        y="3.73438"
        width="4.59201"
        height="0.923077"
        rx="0.461538"
        transform="rotate(90 7.84741 3.73438)"
        fill={fill}
      />
    </svg>
  );
};
