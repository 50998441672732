import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { MeetingCountData } from '../../../../../store/charts/charts.types';
import { MeetingCountSkeleton } from '../../skeletons/MeetingCountSkeleton';

type Data = {
  date: string;
  yourMeetings: number;
  teamAverage: number;
}[];

type Props = {
  meetingCountData: MeetingCountData | null;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  lastDays: number | null;
  isInTeamQ?: boolean;
};

export const MeetingCountChart: React.FC<Props> = ({
  meetingCountData,
  isLoading,
  noData,
  error,
  lastDays,
  isInTeamQ,
}): JSX.Element => {
  const [data, setData] = useState<Data>([]);

  const [lastDaysResponse, setLastDaysResponse] = useState<number | null>(null);

  const [lineHovered, setLineHovered] = useState<'a' | 's' | ''>('');

  useEffect(() => {
    if (!meetingCountData?.stats || isLoading) {
      setData([]);
    }

    if (lastDays) {
      setLastDaysResponse(lastDays);
    }

    if (meetingCountData?.stats?.length) {
      // if (!meetingCountData?.length) {
      // const customData: Data = [];
      // // eslint-disable-next-line
      // for (let i = 0; i < 20; i++) {
      //   customData.push({
      //     date: `Jan ${i + 1}`,
      //     yourMeetings: Math.floor(Math.random() * 15),
      //     teamAverage: Math.floor(Math.random() * 10),
      //   });
      // }
      // setData(customData);
      const dataToSet: Data = meetingCountData.stats.map((item) => ({
        date: item.date,
        yourMeetings: item.count,
        teamAverage: meetingCountData?.teamAverage,
      }));

      setData(dataToSet);
    } // eslint-disable-next-line
  }, [meetingCountData?.stats?.length, lastDays, isLoading]);

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
    },
    date: {
      className: 'chart-tooltip__date-on-top',
      style: {
        textAlign: 'start' as const,
        fontSize: '12px',
      },
    },
    user: {
      wrapper: {
        className: 'chart-tooltip__multi-line',
      },
      border: {
        className: 'chart-tooltip__multi-line-left-border',
        style: {
          backgroundColor: '#4EB4FF',
          height: '38px',
        },
      },
      body: {
        className: 'chart-tooltip__multi-line__body',
      },
    },
    team: {
      wrapper: {
        className: 'chart-tooltip__multi-line',
      },
      border: {
        className: 'chart-tooltip__multi-line-left-border',
        style: {
          backgroundColor: '#365798',
          height: '35px',
        },
      },
      body: { className: 'chart-tooltip__multi-line__body' },
    },
    label: {
      className: 'chart-tooltip__multi-line__label',
    },
    emailsQty: {
      className: 'chart-tooltip__value',
      style: {
        marginBottom: '0px',
      },
    },
    emailsText: {
      className: 'chart-tooltip__value-text',
    },
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    const meetingsQtyValue = (meetings: number) => {
      if (
        meetings &&
        typeof +meetings === 'number' &&
        !Number.isNaN(meetings)
      ) {
        return (
          <span {...tooltipAttrs.emailsQty}>
            {+meetings.toFixed(1)}{' '}
            <span {...tooltipAttrs.emailsText}>
              {+meetings === 1 ? 'meeting' : 'meetings'}
            </span>
          </span>
        );
      }
      return (
        <span {...tooltipAttrs.emailsQty}>
          0<span {...tooltipAttrs.emailsText}> meetings</span>
        </span>
      );
    };

    const formattedDate =
      payload && payload[0]?.payload?.date
        ? moment(payload[0]?.payload?.date).format('MMM D, h:mm A')
        : null;

    if (active && data.length) {
      const { yourMeetings } = payload[1]?.payload;
      const { teamAverage } = payload[0]?.payload;

      const userMeetingsValue = meetingsQtyValue(yourMeetings);
      const teamMeetingsValue = meetingsQtyValue(teamAverage);

      result = (
        <div {...tooltipAttrs.wrapper}>
          <span {...tooltipAttrs.date}>{formattedDate}</span>
          <div {...tooltipAttrs.user.wrapper}>
            <div {...tooltipAttrs.user.border} />
            <div {...tooltipAttrs.user.body}>
              <span {...tooltipAttrs.label}>
                {isInTeamQ ? 'Team Meetings' : 'My Meetings'}
              </span>
              {userMeetingsValue}
            </div>
          </div>

          <div {...tooltipAttrs.team.wrapper}>
            <div {...tooltipAttrs.team.border} />
            <div {...tooltipAttrs.team.body}>
              <span {...tooltipAttrs.label}>Team Average</span>
              {teamMeetingsValue}
            </div>
          </div>
        </div>
      );
    }

    return result;
  };

  const formatTicks = (value: number) => {
    let formattedTicks = value > 0 ? `${value}` : '0';

    if (
      formattedTicks.split('.')[0].length === 4 &&
      +formattedTicks.split('.')[0] >= 1
    ) {
      const formtdNumb = +formattedTicks * 0.001;
      formattedTicks =
        formtdNumb.toString().length < 3
          ? `${formtdNumb.toFixed(0)}k`
          : `${formtdNumb.toFixed(1)}k`;
    } else if (formattedTicks.length === 5) {
      const formtdNumb = +formattedTicks * 0.001;
      const splitedValues = formtdNumb?.toString()?.split('.');
      formattedTicks =
        (splitedValues[1]?.length < 5 && splitedValues[1]?.includes('0')) ||
        formtdNumb.toString().length === 2
          ? `${formtdNumb.toFixed(0)}k`
          : `${formtdNumb.toFixed(1)}k`;
    }

    return formattedTicks;
  };

  const xAxisTickFormatter = (str: string, i: number) => {
    let numberToDivideBy = 5.5;

    if (lastDaysResponse === 30) {
      numberToDivideBy = 4.5;
    } else if (lastDaysResponse === 7) {
      numberToDivideBy = 8;
    }

    const desiredIndex = data?.length / numberToDivideBy || 1;

    if (i % Math.ceil(desiredIndex) === 0) {
      return moment(str).format('MMM D');
    }
    return '';
  };

  const defineLineColors = () => {
    let yourLineColor = '#4EB4FF';

    let teamLineColor = '#365798';

    if (noData || error) {
      yourLineColor = 'rgba(0, 0, 0, 0)';

      teamLineColor = 'rgba(0, 0, 0, 0)';
    } else if (lineHovered === 'a') {
      yourLineColor = '#4EB4FF';
    } else if (lineHovered === 's') {
      teamLineColor = '#365798';
    }

    return { yourLineColor, teamLineColor };
  };

  const { yourLineColor, teamLineColor } = defineLineColors();

  const attrs = {
    meetingCountSkeleton: {
      isLoading,
      isInTeamQ,
    },
    chartWrapper: {
      title: 'Meeting Count',
      subtitle: `Last ${lastDaysResponse || '-'} days`,
      tooltipText: isInTeamQ
        ? "This graph shows the distribution of the number of online meetings for team members compared to your team's average over the selected period."
        : `This graph shows your total count and distribution of online meetings compared to your team’s average over the selected period, excluding my meetings.`,
      isNoData: noData || error,
      isSoundWave: true,
      isInTeamQ,
    },

    totalMeetings: {
      wrapper: {
        className: 'charts__meeting-count__total-meetings-section',
      },

      value: {
        className: 'charts__meeting-count__total-meetings-section-value',
      },

      subtitle: {
        className: 'charts__meeting-count__total-meetings-section-subtitle',
      },
    },

    responsiveContainer: {
      height: '60%',
      width: '100%',
    },
    chart: {
      lineChart: {
        width: 295,
        height: 220,
        data,
        margin: { top: 30, right: 0, left: -28, bottom: -5 },
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      cartesianGrid: {
        strokeDasharray: '0 0',
        vertical: false,
        stroke: '#F8F8FB',
        strokeWidth: 1,
      },
      xAxis: {
        dataKey: 'date',
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 8,
        tickLine: { stroke: '#F8F8FB' },
        axisLine: { stroke: '#F8F8FB' },
        tickMargin: 9,
        padding: { right: 17, left: 12 },
        interval: 0,
        tickFormatter: (str: string, i: number) => xAxisTickFormatter(str, i),
      },
      yAxis: {
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 0,
        tickLine: false,
        axisLine: false,
        tickMargin: 10,
        tickFormatter: formatTicks,
      },
      tooltip: {
        wrapperStyle: { top: '-173px', left: '-85.5px' },
        content: <CustomTooltip payload={data} active />,
        allowEscapeViewBox: { x: true, y: true },
        isAnimationActive: false,
        cursor: !noData && !error,
      },
      lineYour: {
        dataKey: 'yourMeetings',
        stroke: yourLineColor,
        strokeWidth: 2,
        dot: false,
        activeDot: {
          strokeWidth: 2,
          r: 4,
          onMouseEnter: () => setLineHovered('a'),
          onMouseLeave: () => setLineHovered(''),
        },
        style: { filter: 'drop-shadow(1px 2px 2px rgba(36, 45, 81, 0.3))' },
        onMouseEnter: () => setLineHovered('a'),
        onMouseLeave: () => setLineHovered(''),
      },
      lineTeam: {
        dataKey: 'teamAverage',
        stroke: teamLineColor,
        strokeWidth: 2,
        strokeDasharray: 7,
        strokeLinecap: 'square' as const,
        dot: false,
        activeDot: {
          strokeWidth: 2,
          r: 4,
          onMouseEnter: () => setLineHovered('s'),
          onMouseLeave: () => setLineHovered(''),
        },
        style: { filter: 'drop-shadow(1px 2px 2px rgba(78, 180, 255, 0.3))' },
        onMouseEnter: () => setLineHovered('s'),
        onMouseLeave: () => setLineHovered(''),
      },
    },
    legend: {
      wrapper: {
        className: 'charts__meeting-count__legend',
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },

      me: {
        className: 'charts__meeting-count__legend-your',
      },

      team: {
        className: 'charts__meeting-count__legend-team',
      },
    },
  };

  const totalMeetings = noData ? null : (
    <div {...attrs.totalMeetings.wrapper}>
      {' '}
      <div {...attrs.totalMeetings.value}>
        {meetingCountData?.totalCount || '-'}
      </div>
      <div {...attrs.totalMeetings.subtitle}>Total Meetings</div>{' '}
    </div>
  );

  const legend = (
    <div {...attrs.legend.wrapper}>
      <span {...attrs.legend.me}>{isInTeamQ ? 'My Team' : 'My Meetings'}</span>
      <span {...attrs.legend.team}>Team Average</span>
    </div>
  );

  return (
    <MeetingCountSkeleton {...attrs.meetingCountSkeleton}>
      <ChartWrapper {...attrs.chartWrapper}>
        <>
          {totalMeetings}
          <ResponsiveContainer {...attrs.responsiveContainer}>
            <LineChart {...attrs.chart.lineChart}>
              <CartesianGrid {...attrs.chart.cartesianGrid} />
              <XAxis {...attrs.chart.xAxis} />
              <YAxis {...attrs.chart.yAxis} />
              <Tooltip {...attrs.chart.tooltip} />
              <Line
                {...attrs.chart.lineTeam}
                type="linear"
                strokeDasharray={7}
                strokeLinecap="square"
              />
              <Line {...attrs.chart.lineYour} type="linear" />
            </LineChart>
          </ResponsiveContainer>
          {legend}
        </>
      </ChartWrapper>
    </MeetingCountSkeleton>
  );
};
