import React from 'react';

export enum ControlButtonSize {
  SMALL = 'small',
  BIG = 'big',
  BIG_PLAY = 'big-play',
}

export interface ControlButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  onClick: () => void;
  size?: ControlButtonSize;
}

export const ControlButton: React.FC<ControlButtonProps> = ({
  size,
  label,
  children,
  onClick,
  ...rest
}) => {
  const attrs = {
    button: {
      type: 'button' as const,
      className: `player-control${size ? ` player-control--${size}` : ''}`,
      'aria-label': label,
      onClick,
      ...rest,
    },
  };

  return <button {...attrs.button}>{children}</button>;
};
