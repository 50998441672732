import { RelationshipMemberSide } from '../../../../../types/relationshipMember.types';

export const getRangeGroupTitleBySide = (
  side: RelationshipMemberSide,
  userSideOrgName?: string,
  prospectSideOrgName?: string
): string => {
  switch (side) {
    case RelationshipMemberSide.USER_SIDE:
      return userSideOrgName ? `${userSideOrgName} Team` : 'N/A Team';
    case RelationshipMemberSide.PROSPECT_SIDE:
      return prospectSideOrgName ? `${prospectSideOrgName} Team` : 'N/A Team';
    default:
      return 'Not Assigned';
  }
};
