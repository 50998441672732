import React from 'react';

export const WarningIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.00016 3.99992C8.36835 3.99992 8.66683 4.2984 8.66683 4.66659V9.33325C8.66683 9.70144 8.36835 9.99992 8.00016 9.99992C7.63197 9.99992 7.3335 9.70144 7.3335 9.33325V4.66659C7.3335 4.2984 7.63197 3.99992 8.00016 3.99992Z"
      fill="#AD6E00"
    />
    <path
      d="M8.00016 12.3333C8.4604 12.3333 8.8335 11.9602 8.8335 11.4999C8.8335 11.0397 8.4604 10.6666 8.00016 10.6666C7.53993 10.6666 7.16683 11.0397 7.16683 11.4999C7.16683 11.9602 7.53993 12.3333 8.00016 12.3333Z"
      fill="#AD6E00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.3335 7.99992C1.3335 4.31802 4.31826 1.33325 8.00016 1.33325C11.6821 1.33325 14.6668 4.31802 14.6668 7.99992C14.6668 11.6818 11.6821 14.6666 8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99992ZM8.00016 2.66659C5.05464 2.66659 2.66683 5.0544 2.66683 7.99992C2.66683 10.9454 5.05464 13.3333 8.00016 13.3333C10.9457 13.3333 13.3335 10.9454 13.3335 7.99992C13.3335 5.0544 10.9457 2.66659 8.00016 2.66659Z"
      fill="#AD6E00"
    />
  </svg>
);
