import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '../../../../../../components';
import {
  Button,
  ButtonType,
  ButtonVariantType,
} from '../../../../../../components/Button/Button';
import { toggleWebhookStatusModal } from '../../../../../../store/org/org.actions';
import { RootState } from '../../../../../../store/store';
import { copyToClipboardIcon, errorIcon, successIcon } from './icons';

export const WebhookStatusModal: React.FC = (): JSX.Element => {
  const {
    errorMessage,
    updateFailed,
    updateSuccess,
    statusModalOpen,
  } = useSelector((state: RootState) => state.org.webhook.status);

  const [copied, setIsCopied] = useState(false);

  const [errMessageOpen, setErrMessageOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    let copyTimer: NodeJS.Timeout;

    if (copied) {
      copyTimer = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    }

    return () => clearTimeout(copyTimer);
  }, [copied]);

  const copyToClipboard = () => {
    if (errorMessage?.length) {
      navigator?.clipboard?.writeText(errorMessage);

      if (!copied) {
        setIsCopied(true);
      }
    }
  };

  const closeModal = () => {
    dispatch(toggleWebhookStatusModal(false));
  };

  const attrs = {
    actionBodyWrapper: {
      className: 'crm-body__action',
    },

    statusBody: {
      wrapper: {
        className: 'crm-body__status',
      },

      icon: {
        className: 'crm-body__status-img',
        src: updateSuccess ? successIcon : errorIcon,
      },

      boldMessage: {
        className: 'crm-body__status-bold-text',
      },

      message: {
        className: 'crm-body__status-text',
      },

      messageInScroll: {
        wrapper: {
          className: `${
            errMessageOpen ? 'open' : ''
          } crm-body__status__scrollable`,
        },

        title: {
          wrapper: {
            className: 'crm-body__status__scrollable__title',
          },

          text: {
            className: 'crm-body__status__scrollable__title-text',
          },

          chevron: {
            className: 'crm-body__status__scrollable__title-chevron',
            onClick: () => setErrMessageOpen(!errMessageOpen),
          },
        },

        body: {
          wrapper: {
            className: 'crm-body__status__scrollable__body',
          },

          row: {
            className: 'crm-body__status__scrollable__body-row',
          },
        },
      },
    },

    Modal: {
      title: '',
      open: statusModalOpen,
      onClose: closeModal,
      width: '400px',
      className: 'crm-modal',
      noCloseIcon: true,
      noTitle: true,
    },

    buttonSection: {
      wrapper: {
        className: 'crm-body__button-section',
      },

      GotIt: {
        type: 'submit' as ButtonType,
        className: 'crm-body__button-section-submit',
        variant: 'common' as ButtonVariantType,
        onClick: closeModal,
        width: '210px',
        padding: '3px 15px',
        borderRadius: '4px',
        marginTop: '15px',
        fontSize: '12px',
      },

      copy: {
        wrapper: {
          className: 'crm-body__button-section__copy',
        },

        icon: {
          src: copyToClipboardIcon,
          onClick: copyToClipboard,
          className: 'crm-body__button-section__copy-icon',
        },

        tooltip: {
          className: `${
            copied ? 'copied-shown' : ''
          } crm-body__button-section__copy-tooltip`,
        },
      },
    },
  };

  const successBody = updateSuccess ? (
    <div {...attrs.statusBody.wrapper}>
      <img {...attrs.statusBody.icon} alt="" />
      <div {...attrs.statusBody.boldMessage}>Webhook succesfully created</div>
      <Button {...attrs.buttonSection.GotIt}>Ok</Button>
    </div>
  ) : null;

  const generalErrorBody =
    updateFailed && !errorMessage ? (
      <div {...attrs.statusBody.wrapper}>
        <img {...attrs.statusBody.icon} alt="" />
        <div {...attrs.statusBody.boldMessage}>
          It seems there was a problem creating the Webhook. Please try again
          later
        </div>
        <Button {...attrs.buttonSection.GotIt}>Ok</Button>
      </div>
    ) : null;

  const errorMessageToShow =
    typeof errorMessage === 'string' && errorMessage.length
      ? Object.entries(JSON.parse(errorMessage || '')).map(
          ([key, value], index) => {
            const formattedValue = `${key}: ${JSON.stringify(value)}`;

            return (
              <div
                key={`${index}|${key}`}
                {...attrs.statusBody.messageInScroll.body.row}
              >
                {formattedValue}
              </div>
            );
          }
        )
      : null;

  const buttonSectionWithCopy = (
    <div {...attrs.buttonSection.wrapper}>
      <Button {...attrs.buttonSection.GotIt}>Ok</Button>
      <div {...attrs.buttonSection.copy.wrapper}>
        <img {...attrs.buttonSection.copy.icon} alt="" />
        <div {...attrs.buttonSection.copy.tooltip}>
          {copied ? 'Copied to clipboard' : 'Copy'}
        </div>
      </div>
    </div>
  );

  const errorWithMessageBody =
    updateFailed && errorMessage ? (
      <div {...attrs.statusBody.wrapper}>
        <img {...attrs.statusBody.icon} alt="" />
        <div {...attrs.statusBody.boldMessage}>
          {`Couldn't connect the webhook`}
        </div>
        <div {...attrs.statusBody.messageInScroll.wrapper}>
          <div {...attrs.statusBody.messageInScroll.title.wrapper}>
            <div
              {...attrs.statusBody.messageInScroll.title.text}
            >{`View your server's response`}</div>
            <div {...attrs.statusBody.messageInScroll.title.chevron}>{`>`}</div>
          </div>
          <div {...attrs.statusBody.messageInScroll.body.wrapper}>
            {errorMessageToShow}
          </div>
        </div>
        {buttonSectionWithCopy}
      </div>
    ) : null;

  return (
    <Modal {...attrs.Modal}>
      <>
        {successBody}
        {generalErrorBody}
        {errorWithMessageBody}
      </>
    </Modal>
  );
};
