import React from 'react';

export const CrossIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4010_619590)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18198 2.81772C9.37724 3.01298 9.37724 3.32957 9.18198 3.52483L6.70711 5.9997L9.18198 8.47458C9.37724 8.66984 9.37724 8.98642 9.18198 9.18168C8.98672 9.37695 8.67014 9.37695 8.47487 9.18168L6 6.70681L3.52513 9.18168C3.32986 9.37695 3.01328 9.37695 2.81802 9.18168C2.62276 8.98642 2.62276 8.66984 2.81802 8.47458L5.29289 5.9997L2.81802 3.52483C2.62276 3.32957 2.62276 3.01298 2.81802 2.81772C3.01328 2.62246 3.32986 2.62246 3.52513 2.81772L6 5.2926L8.47487 2.81772C8.67014 2.62246 8.98672 2.62246 9.18198 2.81772Z"
        fill="#537BFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_4010_619590">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
