import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { EventAnalysisSummary } from '../../../../components/EventAnalysisSummary/EventAnalysisSummary';
import { RangeAnalysis } from '../../../../components/EventAnalysisCompare/components/RangeAnalysis/RangeAnalysis';
import { useAppSelector } from '../../../../store/hooks';
import { EventAnalysisCompare } from '../../../../components/EventAnalysisCompare/EventAnalysisCompare';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import { getRelativeRangeValues } from '../../../../components/EventAnalysisCompare/components/RangeAnalysis/utils/getRelativeRangeValues';
import { getTotalDurationString } from './ThreadOverview.utils';
import { getTimeString } from '../../utils/getTimeString';
import { OpensIcon } from './icons/OpensIcon';

export const ThreadOverview: React.FC = () => {
  const organization = useAppSelector((state) => state.auth.org);

  const selectedBranch = useAppSelector(
    (state) => state.threadDetails.selectedBranch
  );

  const account = useAppSelector((state) => state.threadDetails.account);

  const isDetailedAnalysisLoading = useAppSelector(
    (state) => state.threadDetails.isLoading.threadDetails
  );

  const relativeReplyTimeValues = useMemo(
    () =>
      getRelativeRangeValues({
        buyerSideReplyTime: selectedBranch?.avgOtherSideReplyTimeHours,
        sellerSideReplyTime: selectedBranch?.avgUserSideReplyTimeHours,
      }),
    [selectedBranch]
  );

  const relativeWordCountValues = useMemo(
    () =>
      getRelativeRangeValues({
        buyerSideWordsCount: selectedBranch?.avgWordCountOtherSide,
        sellerSideWordsCount: selectedBranch?.avgWordCountUserSide,
      }),
    [selectedBranch]
  );

  const durationInWeeks = selectedBranch?.branchDurationWeeks;
  const dateStarted = selectedBranch?.emails?.[0]?.sentAt;

  const attrs = {
    container: {
      id: 'thread-overview',
    },
    title: {
      className: 'event-frame__content-title',
    },
    metricsWrapper: {
      className: 'thread-overview__metrics-wrapper',
    },
    eventAnalysisSummary: {
      isLoading: isDetailedAnalysisLoading,
      items: [
        {
          title: 'Date & Time Started',
          value: dateStarted
            ? DateTime.fromISO(dateStarted).toFormat('MMM dd, yyyy, hh:mm a')
            : 'N/A',
        },
        {
          title: 'Total Duration',
          tooltip:
            durationInWeeks === null
              ? 'This thread contains only 1 message'
              : '',
          value: getTotalDurationString(
            typeof durationInWeeks === 'number' ? durationInWeeks : null
          ),
        },
        {
          title: 'Perceived Authority',
          value:
            typeof selectedBranch?.userSidePerceivedCompetence === 'number'
              ? `${(
                  (selectedBranch?.userSidePerceivedCompetence || 0) * 100
                ).toFixed(0)}%`
              : 'N/A',
          tooltip:
            "Your team's Perceived Authority score in this thread is based on the participants' upper hand results across the entire thread.",
        },
        {
          title: 'Opens',
          value:
            typeof selectedBranch?.accessEventsCount === 'number'
              ? `${selectedBranch.accessEventsCount}`
              : 'N/A',
          ...(typeof selectedBranch?.accessEventsCount === 'number'
            ? { icon: <OpensIcon /> }
            : {}),
        },
      ],
    },
    threadReplyTime: {
      title: 'Avg. Reply Time',
      tooltip: 'Compares average reply times across the entire thread.',
    },
    threadWordsCount: {
      title: 'Avg. word count',
      tooltip: 'Compares average message word counts across the entire thread.',
    },
    buyerSideReplyTime: {
      isLoading: isDetailedAnalysisLoading,
      title: account?.name || '',
      value: getTimeString(selectedBranch?.avgOtherSideReplyTimeHours || 0),
      rangeValue: relativeReplyTimeValues.buyerSideReplyTime,
      side: RelationshipMemberSide.PROSPECT_SIDE,
    },
    sellerSideReplyTime: {
      isLoading: isDetailedAnalysisLoading,
      title: organization?.name || '',
      value: getTimeString(selectedBranch?.avgUserSideReplyTimeHours || 0),
      rangeValue: relativeReplyTimeValues.sellerSideReplyTime,
      side: RelationshipMemberSide.USER_SIDE,
    },
    buyerSideWordsCount: {
      isLoading: isDetailedAnalysisLoading,
      title: account?.name || '',
      value:
        typeof selectedBranch?.avgWordCountOtherSide === 'number'
          ? `${(selectedBranch.avgWordCountOtherSide || 0)
              .toFixed(1)
              .replace('.0', '')} word${
              selectedBranch.avgWordCountOtherSide === 1 ? '' : 's'
            }`
          : 'N/A',
      rangeValue: relativeWordCountValues.buyerSideWordsCount,
      side: RelationshipMemberSide.PROSPECT_SIDE,
    },
    sellerSideWordsCount: {
      isLoading: isDetailedAnalysisLoading,
      title: organization?.name || '',
      value:
        typeof selectedBranch?.avgWordCountUserSide === 'number'
          ? `${(selectedBranch.avgWordCountUserSide || 0)
              .toFixed(1)
              .replace('.0', '')} word${
              selectedBranch.avgWordCountUserSide === 1 ? '' : 's'
            }`
          : 'N/A',
      rangeValue: relativeWordCountValues.sellerSideWordsCount,
      side: RelationshipMemberSide.USER_SIDE,
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.title}>Thread Analysis</div>
      <EventAnalysisSummary {...attrs.eventAnalysisSummary} />
      <div {...attrs.metricsWrapper}>
        <EventAnalysisCompare {...attrs.threadReplyTime}>
          <RangeAnalysis {...attrs.buyerSideReplyTime} />
          <RangeAnalysis {...attrs.sellerSideReplyTime} />
        </EventAnalysisCompare>
        <EventAnalysisCompare {...attrs.threadWordsCount}>
          <RangeAnalysis {...attrs.buyerSideWordsCount} />
          <RangeAnalysis {...attrs.sellerSideWordsCount} />
        </EventAnalysisCompare>
      </div>
    </div>
  );
};
