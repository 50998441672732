import React, { useEffect, useRef, useState } from 'react';
import { Popup } from '../../../../../components';

type OptionsProps = {
  options: JSX.Element[];
};

export const Options: React.FC<OptionsProps> = ({
  options,
}: OptionsProps): JSX.Element => {
  const optionsRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleClick = (e: MouseEvent) => {
    const target = e.target as Node;
    const isOptionsTarget = optionsRef.current?.contains(target);
    if (isOptionsTarget) setOpen(!open);
    else if (!isOptionsTarget && open) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);

    return () => document.removeEventListener('click', handleClick, true);
  });

  const dotsOpacity = open ? { opacity: '1' } : {};

  return (
    <div
      ref={optionsRef}
      className="options"
      style={open ? { opacity: '1', backgroundColor: '#e5e5e5' } : {}}
    >
      <div className="options__dot" style={dotsOpacity} />
      <div className="options__dot" style={dotsOpacity} />
      <div className="options__dot" style={dotsOpacity} />
      <Popup open={open} className="options__popup" options={options} />
    </div>
  );
};
