import React, { useMemo } from 'react';
import { EventAnalysisSummary } from '../../../../components/EventAnalysisSummary/EventAnalysisSummary';
import { EventAnalysisCompare } from '../../../../components/EventAnalysisCompare/EventAnalysisCompare';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { RangeAnalysisGroup } from '../../../../components/EventAnalysisCompare/components/RangeAnalysisGroup/RangeAnalysisGroup';
import { RangeAnalysis } from '../../../../components/EventAnalysisCompare/components/RangeAnalysis/RangeAnalysis';
import { TurnsAnalysis } from './components/TurnsAnalysis/TurnsAnalysis';
import {
  meetingDateStringSelector,
  meetingHostWithContactInfoSelector,
  meetingParticipantsBySideSelector,
  meetingParticipantsWithContactInfoSelector,
  meetingRecordingDurationStringSelector,
  transcriptsWithParticipantInfoSelector,
} from '../../../../store/meetingDetails/meetingDetails.selectors';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import { getRangeGroupTitleBySide } from './utils/getRangeGroupTitleBySide';
import { changeParticipantSide } from '../../../../store/meetingDetails/meetingDetails.thunks';
import { useMixpanel } from '../../../../hooks';

export const MeetingAnalysis: React.FC = () => {
  const dispatch = useAppDispatch();

  const isMeetingDetailsLoading = useAppSelector(
    (state) => state.meetingDetails.isLoading.meetingDetails
  );

  const isMeetingDetailsError = useAppSelector(
    (state) => state.meetingDetails.isError.meetingDetails
  );

  const userOrganization = useAppSelector((state) => state.auth.org);

  const prospectAccount = useAppSelector(
    (state) => state.meetingDetails.data?.account
  );

  const meetingDate = useAppSelector(meetingDateStringSelector);

  const meetingRecordingDurationString = useAppSelector(
    meetingRecordingDurationStringSelector
  );

  const hostWithContactInfo = useAppSelector(
    meetingHostWithContactInfoSelector
  );

  const participantsWithContactInfo = useAppSelector(
    meetingParticipantsWithContactInfoSelector
  );

  const participantsBySide = useAppSelector(meetingParticipantsBySideSelector);

  const transciptsWithParticipantInfo = useAppSelector(
    transcriptsWithParticipantInfoSelector
  );

  const { trackEvent } = useMixpanel();

  const participantsAvatarProps = useMemo(
    () =>
      participantsWithContactInfo.map((participant) => ({
        src: participant.contact?.metadata?.avatarSrc || '',
        name: participant.contact?.metadata?.name || participant.alias || '',
        side: participant.side,
        className: participant.contact?.metadata?.avatarSrc
          ? ''
          : 'meeting-analysis__avatar',
      })),
    [participantsWithContactInfo]
  );

  const hostAvatarProps = hostWithContactInfo
    ? {
        src: hostWithContactInfo.contact?.metadata?.avatarSrc || '',
        name:
          hostWithContactInfo.contact?.metadata?.name ||
          hostWithContactInfo.alias ||
          '',
        side: hostWithContactInfo.side,
        className: hostWithContactInfo.contact?.metadata?.avatarSrc
          ? ''
          : 'meeting-analysis__avatar',
      }
    : {};

  const isLoading = isMeetingDetailsLoading || isMeetingDetailsError;

  const renderTooltipContent = (name: string, title: string, email: string) => {
    const attrs = {
      tooltipContent: {
        className: 'meeting-analysis__tooltip-content',
      },
      tooltipTextWrapper: {
        className: 'meeting-analysis__tooltip-text-wrapper',
      },
      tooltipText: {
        className: 'meeting-analysis__tooltip-text',
      },
      tooltipTextGray: {
        className:
          'meeting-analysis__tooltip-text meeting-analysis__tooltip-text--gray',
      },
      tooltipTextSeparator: {
        className: 'meeting-analysis__tooltip-text-separator',
      },
    };

    return (
      <div {...attrs.tooltipContent}>
        <div {...attrs.tooltipTextWrapper}>
          <span {...attrs.tooltipText}>{name}</span>
          {title ? (
            <>
              <span {...attrs.tooltipTextSeparator} />
              <span {...attrs.tooltipTextGray}>{title}</span>
            </>
          ) : null}
        </div>
        {email ? <span>{email}</span> : null}
      </div>
    );
  };

  const attrs = {
    container: {
      id: 'meeting-analysis',
    },
    title: {
      className: 'event-frame__content-title',
    },
    eventAnalysisSummary: {
      isLoading,
      items: [
        {
          title: 'Date & Time',
          value: meetingDate,
        },
        {
          title: 'Duration',
          className: 'meeting-analysis__summary-item',
          value: meetingRecordingDurationString,
        },
        {
          title: 'Host',
          className: 'meeting-analysis__summary-item',
          value: !isLoading && !hostWithContactInfo ? 'N/A' : hostAvatarProps,
          contentTooltip: hostWithContactInfo
            ? renderTooltipContent(
                hostWithContactInfo?.contact?.metadata?.name ||
                  hostWithContactInfo?.alias ||
                  '',
                hostWithContactInfo?.contact?.metadata?.title || '',
                hostWithContactInfo?.contact?.email || ''
              )
            : '',
        },
        {
          title: 'Participants',
          value: participantsAvatarProps,
        },
      ],
    },
    metricsWrapper: {
      className: 'meeting-analysis__metrics-wrapper',
    },
    talkTimeContainer: {
      title: 'Talk time',
      tooltip:
        'The percentage of the time during which the speaker was talking. Talk Time helps assess prospect-side interest.',
    },
    screenShareContainer: {
      title: 'Screen share time',
      tooltip:
        'The percentage of the time during which a participant shared their screen.',
    },
    turnsAnalysis: {
      isLoading,
      participants: participantsWithContactInfo,
      transcripts: transciptsWithParticipantInfo,
    },
  };

  const renderRangeGroupSkeletons = () => {
    const props = {
      rangeAnalysisGroup: {
        isLoading,
      },
      rangeAnalysis: {
        isLoading,
        avatar: {},
      },
    };

    return (
      <>
        <RangeAnalysisGroup {...props.rangeAnalysisGroup}>
          <RangeAnalysis {...props.rangeAnalysis} />
          <RangeAnalysis {...props.rangeAnalysis} />
        </RangeAnalysisGroup>
        <RangeAnalysisGroup {...props.rangeAnalysisGroup}>
          <RangeAnalysis {...props.rangeAnalysis} />
          <RangeAnalysis {...props.rangeAnalysis} />
        </RangeAnalysisGroup>
      </>
    );
  };

  const renderParticipantsGroupStats = (
    side: RelationshipMemberSide,
    statsType: 'talkTime' | 'screenShareTime'
  ) => {
    const participants = participantsBySide[side];
    const rangeAnalysisGrouProps = {
      title: getRangeGroupTitleBySide(
        side,
        userOrganization?.name,
        prospectAccount?.name
      ),
    };

    if (!participants?.length) {
      return null;
    }

    return (
      <RangeAnalysisGroup {...rangeAnalysisGrouProps}>
        {[...participants]
          .sort((a, b) => {
            const firstStatsValue = a.stats?.[statsType]?.percentage || null;
            const secondStatsValue = b.stats?.[statsType]?.percentage || null;

            if (firstStatsValue === null) {
              return 1;
            }

            if (secondStatsValue === null) {
              return -1;
            }

            return secondStatsValue - firstStatsValue;
          })
          .map(({ participantId, contact, alias, stats }) => {
            const name = contact?.metadata?.name || alias || '';

            const rangeAnalysisProps = {
              side,
              key: participantId,
              rangeValue:
                typeof stats?.[statsType]?.percentage === 'number'
                  ? (stats[statsType]?.percentage || 0) * 100
                  : null,
              title: name,
              value:
                typeof stats?.[statsType]?.percentage === 'number'
                  ? `${Math.round((stats[statsType]?.percentage || 0) * 100)}%`
                  : 'N/A',
              avatar: {
                name,
                side,
                src: contact?.metadata?.avatarSrc || '',
              },
              selectDropdownProps: {
                isHidden:
                  side !== RelationshipMemberSide.UNASSIGNED ||
                  !contact ||
                  !prospectAccount,
                title: 'Select side for speaker',
              },
              dropdownOptions: [
                {
                  label: userOrganization?.name
                    ? `${userOrganization.name} Team`
                    : 'User Team',
                  isSelected: side === RelationshipMemberSide.USER_SIDE,
                  value: RelationshipMemberSide.USER_SIDE,
                  imageSrc: userOrganization?.avatar || '',
                  onChange: (value: RelationshipMemberSide) => {
                    trackEvent('changeParticipantSide');
                    dispatch(
                      changeParticipantSide({
                        participantId,
                        contactId: contact?.contactId || '',
                        accountId: prospectAccount?.accountId || '',
                        side: value,
                      })
                    );
                  },
                },
                {
                  label: prospectAccount?.name
                    ? `${prospectAccount.name} Team`
                    : 'Prospect Team',
                  isSelected: side === RelationshipMemberSide.PROSPECT_SIDE,
                  value: RelationshipMemberSide.PROSPECT_SIDE,
                  imageSrc: prospectAccount?.avatarSrc || '',
                  onChange: (value: RelationshipMemberSide) => {
                    trackEvent('changeParticipantSide');
                    dispatch(
                      changeParticipantSide({
                        participantId,
                        contactId: contact?.contactId || '',
                        accountId: prospectAccount?.accountId || '',
                        side: value,
                      })
                    );
                  },
                },
                {
                  label: 'N/A',
                  isSelected: side === RelationshipMemberSide.UNASSIGNED,
                  value: RelationshipMemberSide.UNASSIGNED,
                },
              ],
              tooltip: renderTooltipContent(
                name,
                contact?.metadata?.title || '',
                contact?.email || ''
              ),
            };

            return <RangeAnalysis {...rangeAnalysisProps} />;
          })}
      </RangeAnalysisGroup>
    );
  };

  const renderTalkTimeAnalysis = () => {
    if (isLoading) {
      return renderRangeGroupSkeletons();
    }

    return (
      <>
        {renderParticipantsGroupStats(
          RelationshipMemberSide.PROSPECT_SIDE,
          'talkTime'
        )}
        {renderParticipantsGroupStats(
          RelationshipMemberSide.USER_SIDE,
          'talkTime'
        )}

        {renderParticipantsGroupStats(
          RelationshipMemberSide.UNASSIGNED,
          'talkTime'
        )}
      </>
    );
  };

  const renderScreenShareAnalysis = () => {
    if (isLoading) {
      return renderRangeGroupSkeletons();
    }

    return (
      <>
        {renderParticipantsGroupStats(
          RelationshipMemberSide.PROSPECT_SIDE,
          'screenShareTime'
        )}
        {renderParticipantsGroupStats(
          RelationshipMemberSide.USER_SIDE,
          'screenShareTime'
        )}

        {renderParticipantsGroupStats(
          RelationshipMemberSide.UNASSIGNED,
          'screenShareTime'
        )}
      </>
    );
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.title}>Meeting Analysis</div>
      <EventAnalysisSummary {...attrs.eventAnalysisSummary} />
      <div {...attrs.metricsWrapper}>
        <EventAnalysisCompare {...attrs.talkTimeContainer}>
          {renderTalkTimeAnalysis()}
        </EventAnalysisCompare>
        <EventAnalysisCompare {...attrs.screenShareContainer}>
          {renderScreenShareAnalysis()}
        </EventAnalysisCompare>
      </div>
      <TurnsAnalysis {...attrs.turnsAnalysis} />
    </div>
  );
};
