import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  isLoading?: boolean;
  children?: JSX.Element | string;
}

export const SentimentSkeleton: React.FC<Props> = (props: Props) => {
  const { isLoading, children } = props;

  const attrs = {
    title: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 173,
          maxWidth: '60%',
          height: 20,
          borderRadius: 30,
          marginTop: 0,
        },
      },
    },
    subTitle: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 105,
          maxWidth: '35%',
          height: 13,
          borderRadius: 30,
          marginTop: -5,
        },
      },
    },
    legend: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 210,
          maxWidth: '70%',
          height: 13,
          borderRadius: 30,
          marginTop: 8,
        },
      },
    },
    tableHeaderTitle: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 75,
          height: 12,
          borderRadius: 30,
          margin: '-1px 0px 0px 6px',
        },
      },
    },
    tableTitle: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 55,
          height: 12,
          borderRadius: 30,
          margin: '-1px 0px 0px 6px',
        },
      },
    },
    tableValue: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          maxWidth: '95%',
          height: 20,
          borderRadius: 30,
          marginTop: '-1px',
        },
      },
    },
    tableStyles: {
      graphWrapper: {
        className: 'sent-wrap',
        // style: { marginTop: '2%', height: '100%', maxHeight: '215px' },
      },
      header: {
        wrapper: {
          className: 'sent-wrap__header',
          style: { border: 'none' },
        },
        headerTitle: {
          className: 'sent-wrap__header-title',
          style: { border: 'none' },
        },
        headerValue: {
          className: 'sent-wrap__header-value',
          style: { padding: '11px 15%' },
        },
      },
      body: {
        wrarrep: {
          className: 'sent-wrap__positive',
          style: { border: 'none', marginTop: 2 },
        },
        title: {
          className: 'sent-wrap__positive-title',
          style: { border: 'none' },
        },
        value: {
          className: 'sent-wrap__positive-wrap',
          style: { animation: 'none' },
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className="chart-wrapper">
          <div className="chart-wrapper__title-block">
            <Skeleton {...attrs.title} />
          </div>
          <div className="chart-wrapper__subtitle" style={{ marginBottom: 5 }}>
            <Skeleton {...attrs.subTitle} />
            <div className="sent-wrap__legend">
              <Skeleton {...attrs.legend} />
            </div>
          </div>
          <div {...attrs.tableStyles.graphWrapper}>
            <div {...attrs.tableStyles.header.wrapper}>
              <div {...attrs.tableStyles.header.headerTitle}>
                <Skeleton {...attrs.tableHeaderTitle} />
              </div>
              <div {...attrs.tableStyles.header.headerValue}>
                <Skeleton {...attrs.tableValue} />
              </div>
            </div>
            <div {...attrs.tableStyles.body.wrarrep}>
              <div {...attrs.tableStyles.body.title}>
                <Skeleton {...attrs.tableTitle} />
              </div>
              <div {...attrs.tableStyles.body.value}>
                <Skeleton {...attrs.tableValue} />
              </div>
            </div>
            <div {...attrs.tableStyles.body.wrarrep}>
              <div {...attrs.tableStyles.body.title}>
                <Skeleton {...attrs.tableTitle} />
              </div>
              <div {...attrs.tableStyles.body.value}>
                <Skeleton {...attrs.tableValue} />
              </div>
            </div>
            <div {...attrs.tableStyles.body.wrarrep}>
              <div {...attrs.tableStyles.body.title}>
                <Skeleton {...attrs.tableTitle} />
              </div>
              <div {...attrs.tableStyles.body.value}>
                <Skeleton {...attrs.tableValue} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
