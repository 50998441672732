import React from 'react';

type TooltipProps = {
  text: string;
  open: boolean;
  width?: string;
  marginLeft?: string;
  opposite?: boolean;
  capitalize?: boolean;
};

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  open,
  width,
  marginLeft,
  opposite,
  capitalize,
}: TooltipProps): JSX.Element => {
  return (
    <div
      className={`${opposite ? 'opposite__' : ''}tooltip-${
        open ? 'open' : 'closed'
      }`}
      style={
        capitalize
          ? { width, marginLeft, textTransform: 'capitalize' }
          : { width, marginLeft }
      }
    >
      {text}
    </div>
  );
};
