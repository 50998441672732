import React from 'react';
import { DateTime } from 'luxon';
import { ChevronLeftIcon } from './icons/ChevronLeftIcon';
import { ChevronRightIcon } from './icons/ChevronRightIcon';

export interface DatePickerHeaderProps {
  date: Date;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseMonth: () => void;
  increaseMonth: () => void;
}

export const DatePickerHeader: React.FC<DatePickerHeaderProps> = ({
  date,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseMonth,
  increaseMonth,
}) => {
  const dateString = DateTime.fromJSDate(date).toFormat('MMMM, yyyy');

  const attrs = {
    container: {
      className: 'date-picker-header',
    },
    prevMonthButton: {
      type: 'button' as const,
      'aria-label': 'Previous month',
      className: 'date-picker-header__button',
      disabled: prevMonthButtonDisabled,
      onClick: decreaseMonth,
    },
    nextMonthButton: {
      type: 'button' as const,
      'aria-label': 'Next month',
      className: 'date-picker-header__button',
      disabled: nextMonthButtonDisabled,
      onClick: increaseMonth,
    },
    icon: {
      className: 'date-picker-header__icon',
    },
  };

  return (
    <div {...attrs.container}>
      <button {...attrs.prevMonthButton}>
        <ChevronLeftIcon {...attrs.icon} />
      </button>
      <span>{dateString}</span>
      <button {...attrs.nextMonthButton}>
        <ChevronRightIcon {...attrs.icon} />
      </button>
    </div>
  );
};
