import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImageCropper, Modal, useFileUpload } from '../../components';
import { AddIcon } from '../../components/Icons/AddIcon';
import { useGetAdminSettingsData, useTrackPageView } from '../../hooks';
import { RootState } from '../../store/store';
import {
  getTeams,
  getTeamsForDealmaker,
  invokeCreateTeamModal,
} from '../../store/teams/teams.actions';
import { getUsers } from '../../store/users/users.actions';
import { CreateTeam } from './components/createTeam/CreateTeam';
import TeamSection from './components/team/Team';
import { welcome, welcomeDealmaker } from './icons';

export const Teams: React.FC = (): JSX.Element => {
  const {
    croppedImage,
    file,
    handleUpload,
    removeFile,
    saveCroppedImage,
  } = useFileUpload();

  const { user } = useSelector((state: RootState) => state.auth);
  const { teams, modals } = useSelector((state: RootState) => state.teams);
  const { isLoading } = useSelector((state: RootState) => state.teams);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.orgId && user?.userId && user?.role) {
      if (user?.role === 'superAdmin') {
        dispatch(getUsers(user.orgId));
        dispatch(getTeams(user.orgId));
      } else if (user.role === 'owner') {
        dispatch(getUsers(user.orgId));
        dispatch(getTeams(user.orgId));
      } else if (user.role === 'admin') {
        dispatch(getUsers(user.orgId));
        dispatch(getTeams(user.orgId));
      } else if (user.role === 'dealmaker') {
        dispatch(getTeamsForDealmaker(user.orgId, user.userId));
      }
    }
  }, []); // eslint-disable-line

  useGetAdminSettingsData();
  useTrackPageView();

  const toggleAddTeamModal = () => {
    dispatch(invokeCreateTeamModal(!modals.createTeamOpen));
  };

  const attrs = {
    modal: {
      open: !!file,
      onClose: removeFile,
      title: 'Upload a photo',
      height: '570px',
      upIndex: true,
    },
    imageCropper: {
      onSave: saveCroppedImage,
      onCancel: removeFile,
      image: file || '',
      isFromTeams: true,
    },
    addTeamIcon: {
      tooltipText: 'Create a new team',
      width: '16rem',
      onClick: toggleAddTeamModal,
    },
    createTeam: {
      open: modals.createTeamOpen,
      onClose: toggleAddTeamModal,
      isLoading,
      croppedAvatar: croppedImage,
      handleUploadAvatar: handleUpload,
      saveCroppedAvatar: saveCroppedImage,
      isUploadAvatarOpen: !!file?.length,
    },
    teamSection: {
      croppedAvatar: croppedImage,
      handleUploadAvatar: handleUpload,
      saveCroppedAvatar: saveCroppedImage,
      isUploadAvatarOpen: !!file?.length as boolean,
    },
    div: {
      table: {
        className: 'table',
      },
      tableControls: {
        className: 'table__controls',
      },
      tableControlsLeft: {
        className: 'table__controls-left',
      },
      teams: {
        className: 'teams',
      },
      teamsWelcome: {
        className: 'teams__welcome',
      },
    },
    img: {
      teamsWelcome: {
        src: user?.role === 'dealmaker' ? welcomeDealmaker : welcome,
        className: 'teams__welcome-img',
      },
    },
    span: {
      teamsWelcomeText: {
        className: 'teams__welcome-text',
      },
    },
  };

  const welcomeTextAccordingToRole =
    user?.role === 'dealmaker'
      ? 'You haven’t been assigned to a team yet.'
      : `On this page, you can create teams and assign roles. This will be
      useful in order to view team stats & analytics and manage them
      efficiently`;

  const conditionalTeamSection = teams?.length ? (
    teams?.map((team) => (
      <div key={team.teamId}>
        <TeamSection team={team} {...attrs.teamSection} />
      </div>
    ))
  ) : (
    <div {...attrs.div.teamsWelcome}>
      <img {...attrs.img.teamsWelcome} alt="welcome" />
      <span {...attrs.span.teamsWelcomeText}>{welcomeTextAccordingToRole}</span>
    </div>
  );

  const conditionalControlIcons =
    user?.role !== 'dealmaker' ? (
      <div {...attrs.div.tableControlsLeft}>
        <AddIcon {...attrs.addTeamIcon} />
      </div>
    ) : null;

  return (
    <div>
      <div {...attrs.div.table}>
        <Modal {...attrs.modal}>
          <ImageCropper {...attrs.imageCropper} />
        </Modal>
        <div {...attrs.div.tableControls}>
          {conditionalControlIcons}
          <CreateTeam {...attrs.createTeam} />
        </div>
      </div>
      <div {...attrs.div.teams}>{conditionalTeamSection}</div>
    </div>
  );
};
