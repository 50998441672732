import React from 'react';
import { Button, Modal } from '../../../../components';
import CustomSelect from '../../../../components/CustomSelect/CustomSelect';
import { useEditUserInfo } from '../../hooks/useEditUserInfo';
import { timezone } from '../../icons';

type RenameUserProps = {
  open: boolean;
  onClose: () => void;
};

export const EditTimeZone: React.FC<RenameUserProps> = ({
  open,
  onClose,
}: RenameUserProps): JSX.Element => {
  const { values, handleChange, handleSubmit, initialValues } = useEditUserInfo(
    onClose
  );

  const table = Array.from(new Array(27).keys()).map((el) => ({
    value: el - 12 >= 0 ? `GMT+${el - 12}:00` : `GMT${el - 12}:00`,
    label: el - 12 >= 0 ? `GMT+${el - 12}:00` : `GMT${el - 12}:00`,
  }));

  const currentValue = table.find((el) => el.value === values.timezone);
  const changeValue = (newValue: typeof table[0]) =>
    handleChange('timezone')(newValue.value);

  return (
    <Modal title="Edit time zone" open={open} onClose={onClose}>
      <div className={`edit-user edit-user-${open ? 'open' : 'closed'}`}>
        <form onSubmit={handleSubmit}>
          <CustomSelect
            label="time zone"
            icon={timezone}
            medium
            selectedItem={currentValue}
            items={table}
            onChange={changeValue}
            style={{
              padding: '25px 17px',
              background: '#f0f2f9',
              justifyContent: 'space-between',
              borderRadius: '1.5rem',
            }}
            margin="40px 0px 0px"
            padding="0px 20px 0px 0px"
            itemPadding="5px 0px 5px 145px"
          />
          <Button
            type="submit"
            className="edit-user__btn-timezone"
            variant="common"
            disabled={initialValues.timezone === values.timezone}
          >
            Save
          </Button>
        </form>
      </div>
    </Modal>
  );
};
