import React from 'react';

export const MutedSpeakerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.71967 2.46738C8.02233 1.38183 10 2.30815 10 4.00382V11.6437C10 13.3394 8.02233 14.2657 6.71967 13.1802L5.27743 11.9783C5.15762 11.8784 5.0066 11.8238 4.85064 11.8238H3.33337C2.2288 11.8238 1.33337 10.9283 1.33337 9.82377V5.82377C1.33337 4.7192 2.2288 3.82377 3.33337 3.82377H4.85064C5.0066 3.82377 5.15762 3.76909 5.27743 3.66925L6.71967 2.46738ZM8.66671 4.00382C8.66671 3.4386 8.00747 3.12983 7.57325 3.49167L6.13101 4.69354C5.77158 4.99307 5.31851 5.1571 4.85064 5.1571H3.33337C2.96518 5.1571 2.66671 5.45558 2.66671 5.82377V9.82377C2.66671 10.192 2.96518 10.4904 3.33337 10.4904H4.85064C5.31851 10.4904 5.77158 10.6545 6.13101 10.954L7.57325 12.1559C8.00747 12.5177 8.66671 12.2089 8.66671 11.6437V4.00382Z"
      fill="#070B12"
    />
    <path
      d="M10.862 6.01903C11.1223 5.75868 11.5444 5.75868 11.8048 6.01903L12.6667 6.88096L13.5286 6.01903C13.789 5.75868 14.2111 5.75868 14.4714 6.01903C14.7318 6.27938 14.7318 6.70149 14.4714 6.96184L13.6095 7.82377L14.4714 8.6857C14.7318 8.94604 14.7318 9.36816 14.4714 9.6285C14.2111 9.88885 13.789 9.88885 13.5286 9.6285L12.6667 8.76658L11.8048 9.6285C11.5444 9.88885 11.1223 9.88885 10.862 9.6285C10.6016 9.36816 10.6016 8.94604 10.862 8.6857L11.7239 7.82377L10.862 6.96184C10.6016 6.70149 10.6016 6.27938 10.862 6.01903Z"
      fill="#070B12"
    />
  </svg>
);
