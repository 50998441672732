import React, { useState } from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { formatNavigationCellValue } from '../../utils/teamq.utils';
import { QCellSkeleton } from '../skeletons/QCellSkeleton';
import {
  BarTooltipTextCase,
  TeamQAvgLineValueUnit,
  TeamQGraph,
  TeamQGraphXAxisVariant,
} from '../teamQGraph/TeamQGraph';

type CurrentComponent =
  | 'upperhand'
  | 'sellingZone'
  | 'competence'
  | 'empathy'
  | 'responsiveness'
  | 'talkTimeRatio';

export const TeamAnalytics: React.FC = () => {
  const {
    requestedTimePeriodDays,
    analyticsUpperHand,
    analyticsUpperHandStatus,
    analyticsSellZone,
    analyticsSellZoneStatus,
    competence,
    competenceStatus,
    empathy,
    empathyStatus,
    responsiveness,
    responsivenessStatus,
  } = useAppSelector((state) => state.teamQ);

  const talkTimeRatioStats = useAppSelector(
    (state) => state.soundWaveTeamQ.talkTimeRatioStats
  );

  const isLoading = useAppSelector((state) => state.soundWaveTeamQ.isLoading);

  const isError = useAppSelector((state) => state.soundWaveTeamQ.isError);

  const [currentComp, setCurrentComp] = useState<CurrentComponent>('upperhand');

  const upperHandData = analyticsUpperHand?.statsPerUser
    ? analyticsUpperHand?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.uhRatio || 0,
        noData: el.uhRatio === 0,
        emails: el.carbonEmailsCount || 0,
      }))
    : null;

  const sellingZoneData = analyticsSellZone?.statsPerUser
    ? analyticsSellZone?.statsPerUser.map((el) => ({
        name: el.name,
        below: el.belowSzEmailsRate || 0,
        in: el.inSzEmailsRate || 0,
        above: el.aboveSzEmailsRate || 0,
        emails: el.carbonEmailsCount || 0,
      }))
    : null;

  const competenceData = competence?.statsPerUser
    ? competence?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.competence || 0,
        emails: el.carbonEmailsCount || 0,
      }))
    : null;

  const empathyData = empathy?.statsPerUser
    ? empathy?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.position || 0,
      }))
    : null;

  const responsivenessData = responsiveness?.statsPerUser
    ? responsiveness?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.position || 0,
      }))
    : null;

  const talkTimeRatioData = talkTimeRatioStats?.statsPerUser
    ? talkTimeRatioStats?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.userTTR || 0,
      }))
    : null;

  const talkTimeRatioStatus = {
    isLoading: isLoading.talkTimeRatioStats,
    error: isError.talkTimeRatioStats,
    noData: !talkTimeRatioStats?.mean,
  };

  const upperHandAvg =
    (analyticsUpperHand?.mean && analyticsUpperHand.mean * 100) || 0;

  const sellZoneAvg =
    (analyticsSellZone?.mean && analyticsSellZone.mean * 100) || 0;

  const competenceAvg = (competence?.mean && competence.mean * 100) || 0;

  const empathyAvg = (empathy?.mean && empathy.mean) || 0;

  const responsivenessAvg = (responsiveness?.mean && responsiveness.mean) || 0;

  const talkTimeRatioAvg =
    (talkTimeRatioStats?.mean && talkTimeRatioStats.mean * 100) || 0;

  const attrs = {
    wrapper: {
      className: 'team-q-analytics',
    },

    nagivationSection: {
      wrapper: {
        className: 'team-q-analytics__navigation-section',
      },

      cell: {
        wrapper: {
          className: 'team-q-analytics__navigation-section__cell',
        },

        title: {
          className: 'team-q-analytics__navigation-section__cell-title',
        },

        value: {
          normal: {
            className: 'team-q-analytics__navigation-section__cell__value',
          },

          bold: {
            className: 'team-q-analytics__navigation-section__cell__value-bold',
          },
        },
      },
    },

    chartSection: {
      wrapper: {
        className: 'team-q-analytics__chart-section',
      },

      UpperHand: {
        title: 'Upper-Hand Ratio',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: analyticsUpperHand?.basedOnMessagesCount,
        tooltipText: `The percentage of messages when team members had the Upper-Hand. 
        This number is based on the messages
        in which they were the senders or main recipients.`,
        graphValueSectionTitleName: 'Upper-Hand ratio (avg/team member)',
        data: upperHandData,
        dataStatus: analyticsUpperHandStatus,
        percentageValue: true,
        xAxisVariantType: 'percentage' as TeamQGraphXAxisVariant,
        avgValue: upperHandAvg,
        avgLineValueUnit: 'percentage' as TeamQAvgLineValueUnit,
        isUpperHand: true,
        barTooltipCase: 'uh' as BarTooltipTextCase,
      },

      SellingZone: {
        title: 'Authority Zone Position Distribution',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: analyticsSellZone?.basedOnMessagesCount,
        tooltipText: `The average distribution of team members' positions in the Authority Zone chart, 
        based on the analyzed messages in the selected time period.
        `,
        graphValueSectionTitleName:
          'Authority Zone position distribution (avg/team member)',
        sellZoneData: sellingZoneData,
        dataStatus: analyticsSellZoneStatus,
        percentageValue: true,
        xAxisVariantType: 'percentage' as TeamQGraphXAxisVariant,
        avgValue: sellZoneAvg,
        avgLineValueUnit: 'percentage' as TeamQAvgLineValueUnit,
        barTooltipCase: 'sellZone' as BarTooltipTextCase,
      },

      Competence: {
        title: 'Perceived Authority by Team Member',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: competence?.basedOnMessagesCount,
        tooltipText: `Team members’ average perceived authority. 
        Based on the ratio of 'Upper-Hand' results across 
        all of your team members’ messages with prospects.`,
        graphValueSectionTitleName: 'Perceived authority (avg/team member)',
        data: competenceData,
        dataStatus: competenceStatus,
        percentageValue: true,
        xAxisVariantType: 'percentage' as TeamQGraphXAxisVariant,
        avgValue: competenceAvg,
        avgLineValueUnit: 'percentage' as TeamQAvgLineValueUnit,
        barTooltipCase: 'competence' as BarTooltipTextCase,
      },

      Empathy: {
        title: 'Empathy by Team Members',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: empathy?.basedOnMessagesCount,
        tooltipText: `The average empathy of your team members 
        during the selected time period vs. the optimal. 
        Being too empathetic may be perceived by prospects as being too pleasing, 
        while not showing enough empathy may come across as being indifferent.`,
        graphValueSectionTitleName: 'Empathy (avg/team member)',
        data: empathyData,
        dataStatus: empathyStatus,
        isTendency: true,
        xAxisVariantType: 'lowHigh' as TeamQGraphXAxisVariant,
        avgValue: empathyAvg,
      },

      Responsiveness: {
        title: 'Responsiveness by Team Member',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: responsiveness?.basedOnMessagesCount,
        tooltipText: `The team’s average response time to prospects 
        during the selected time period vs the optimal response time. 
        Responding too quickly may signal overenthusiasm, 
        while responding too slowly may signal a lack of motivation.`,
        graphValueSectionTitleName: 'Responsiveness (avg/team member)',
        data: responsivenessData,
        dataStatus: responsivenessStatus,
        isTendency: true,
        xAxisVariantType: 'lowHigh' as TeamQGraphXAxisVariant,
        avgValue: responsivenessAvg,
      },

      talkTimeRatio: {
        title: 'Talk-Time Ratio',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: talkTimeRatioStats?.basedOnEventsCount,
        tooltipText:
          'Distribution of the average talk-time ratio of online meetings between team members and their prospects during the selected time period.',
        graphValueSectionTitleName: 'Talk-Time Ratio (avg/team member)',
        data: talkTimeRatioData,
        dataStatus: talkTimeRatioStatus,
        avgValue: talkTimeRatioAvg,
        xAxisVariantType: 'percentage' as TeamQGraphXAxisVariant,
        avgLineValueUnit: 'percentage' as TeamQAvgLineValueUnit,
        barTooltipCase: 'talkTimeRatio' as BarTooltipTextCase,
        isMeeting: true,
        percentageValue: true,
      },

      divider: {
        className: 'team-q-analytics__chart-section-divider',
      },
    },
  };

  const createCell = (
    title: string,
    value: number | 'N/A',
    avgText: string,
    switchTo: CurrentComponent,
    dataStatus: typeof empathyStatus
  ) => {
    const switchToCellComponent = () => {
      if (currentComp !== switchTo) {
        setCurrentComp(switchTo);
      }
    };

    const activeClassName = currentComp === switchTo ? 'active' : '';

    const cellAttrs = {
      wrapper: {
        className: `${activeClassName} team-q-analytics__navigation-section__cell`,
        onClick: switchToCellComponent,
      },

      title: {
        wrapper: {
          className: `${activeClassName} team-q-analytics__navigation-section__cell-title`,
        },
      },

      value: {
        normal: {
          className: `${activeClassName} team-q-analytics__navigation-section__cell__value`,
        },

        bold: {
          className: 'team-q-analytics__navigation-section__cell__value-bold',
        },

        noData: {
          className: 'team-q-analytics__navigation-section__cell__value-n-a',
        },
      },
    };

    const definedValue =
      value === 'N/A'
        ? 'N/A'
        : `${formatNavigationCellValue(value)}${
            switchTo !== 'responsiveness' && switchTo !== 'empathy' ? '%' : ''
          }`;

    const valueToShow =
      definedValue === 'N/A' ? (
        <div {...cellAttrs.value.noData}>N/A</div>
      ) : (
        <>
          <div {...cellAttrs.value.bold}>{definedValue}</div>
          {avgText}
        </>
      );

    return (
      <div {...cellAttrs.wrapper}>
        <div {...cellAttrs.title.wrapper}>{title}</div>
        <QCellSkeleton isLoading={dataStatus.isLoading}>
          <div {...cellAttrs.value.normal}>{valueToShow}</div>
        </QCellSkeleton>
      </div>
    );
  };

  const nagivationSection = (
    <div {...attrs.nagivationSection.wrapper}>
      {createCell(
        'Upper-Hand Ratio',
        +upperHandAvg || 'N/A', // AVERAGE Need to count %
        ' Team Avg.',
        'upperhand',
        analyticsUpperHandStatus
      )}
      {createCell(
        'Authority Zone Position',
        +sellZoneAvg || 'N/A', // AVERAGE Need to count %
        ' Team Avg.',
        'sellingZone',
        analyticsSellZoneStatus
      )}
      {createCell(
        'Perceived Authority',
        +competenceAvg || 'N/A', // AVERAGE Need to count %
        ' Team Avg.',
        'competence',
        competenceStatus
      )}
      {createCell(
        'Empathy',
        +empathyAvg || 'N/A', // AVERAGE Need to count NUMBER
        ' Team Avg.',
        'empathy',
        empathyStatus
      )}
      {createCell(
        'Responsiveness',
        +responsivenessAvg || 'N/A', // AVERAGE Need to count NUMBER
        ' Team Avg.',
        'responsiveness',
        responsivenessStatus
      )}
      {createCell(
        'Talk-Time Ratio',
        +talkTimeRatioAvg || 'N/A',
        'Team Avg.',
        'talkTimeRatio',
        talkTimeRatioStatus
      )}
    </div>
  );

  const UpperHand =
    currentComp === 'upperhand' ? (
      <TeamQGraph {...attrs.chartSection.UpperHand} />
    ) : null;

  const SellingZone =
    currentComp === 'sellingZone' ? (
      <TeamQGraph {...attrs.chartSection.SellingZone} />
    ) : null;

  const Competence =
    currentComp === 'competence' ? (
      <TeamQGraph {...attrs.chartSection.Competence} />
    ) : null;

  const Empathy =
    currentComp === 'empathy' ? (
      <TeamQGraph {...attrs.chartSection.Empathy} />
    ) : null;

  const Responsiveness =
    currentComp === 'responsiveness' ? (
      <TeamQGraph {...attrs.chartSection.Responsiveness} />
    ) : null;

  const TalkTimeRatio =
    currentComp === 'talkTimeRatio' ? (
      <TeamQGraph {...attrs.chartSection.talkTimeRatio} />
    ) : null;

  const chartSection = (
    <div {...attrs.chartSection.wrapper}>
      {UpperHand}
      {SellingZone}
      {Competence}
      {Empathy}
      {Responsiveness}
      {TalkTimeRatio}
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      {nagivationSection}
      {chartSection}
    </div>
  );
};
