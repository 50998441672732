import React from 'react';
import { ErrorIcon } from '../../../../components/Icons/ErrorIcon';
import { ConfirmationModal } from '../../../../components/SoundWave';
import { useFirebase } from '../../../../context';
import { closeRevokeGoogleScopesModal } from '../../../../store/auth/auth.slice';
import { revokeGoogleScopes } from '../../../../store/auth/auth.thunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getModalText } from './utils';

export const RevokeGoogleScopesModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const isOpened = useAppSelector(
    (state) => state.auth.revokeGoogleScopesModal.isOpened
  );

  const connectionId = useAppSelector(
    (state) => state.auth.revokeGoogleScopesModal.connectionId
  );

  const isRevokeGoogleScopesLoading = useAppSelector(
    (state) => state.auth.isLoading.revokeGoogleScopes
  );

  const { signOut } = useFirebase();

  const attrs = {
    modal: {
      isOpened,
      icon: ErrorIcon,
      onClose: () => {
        dispatch(closeRevokeGoogleScopesModal());
      },
      description: getModalText(connectionId),
      footerProps: {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Continue',
        isConfirmButtonDanger: true,
        isLoading: isRevokeGoogleScopesLoading,
        onConfirm: () => {
          dispatch(revokeGoogleScopes()).then(() => {
            dispatch(closeRevokeGoogleScopesModal());
            signOut();
          });
        },
      },
    },
  };

  return <ConfirmationModal {...attrs.modal} />;
};
