import React, { useMemo } from 'react';
import { Skeleton } from 'antd';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  TeamMembersGroup,
  TeamMembersGroupProps,
} from './components/TeamMembersGroup/TeamMembersGroup';

export interface TeamMembersSectionProps {
  title: string;
  groups: TeamMembersGroupProps[];
}

export const TeamMembersSection: React.FC<TeamMembersSectionProps> = ({
  title,
  groups,
}) => {
  const isContactsLoading = useAppSelector(
    (state) => state.relationshipDetails.isLoading.contacts
  );

  const totalMembersCount = useMemo(
    () => groups.reduce<number>((acc, group) => acc + group.contacts.length, 0),
    [groups]
  );

  const attrs = {
    container: {
      className: 'team-members-section',
    },
    header: {
      className: 'team-members-section__header',
    },
    text: {
      className: 'team-members-section__text',
    },
    textGray300: {
      className:
        'team-members-section__text team-members-section__text--gray-300',
    },

    headerSkeleton: {
      main: {
        active: true,
        paragraph: false,
        style: {
          width: '30%',
          padding: '2px 12px',
        },
        title: {
          style: {
            width: '100%',
            height: 20,
            margin: 0,
            borderRadius: 8,
          },
        },
      },

      qty: {
        active: true,
        paragraph: false,
        style: {
          width: '20px',
          padding: '2px 12px',
        },
        title: {
          style: {
            width: '100%',
            height: 20,
            margin: 0,
            borderRadius: 12,
          },
        },
      },
    },

    memberSkeleton: {
      active: true,
      paragraph: false,
      style: {
        width: '100%',
        padding: '14px 16px 10.2px',
      },
      title: {
        style: {
          width: '100%',
          height: 24,
          margin: 0,
          borderRadius: 8,
        },
      },
    },
  };

  const renderGroups = () =>
    groups.map((group, index) => {
      const props = {
        ...group,
        key: index,
      };

      return <TeamMembersGroup {...props} />;
    });

  const headerSkeleton = (
    <>
      <Skeleton {...attrs.headerSkeleton.main} />
      <Skeleton {...attrs.headerSkeleton.qty} />
    </>
  );

  const groupsSkeleton = (
    <>
      <Skeleton {...attrs.memberSkeleton} />
      <Skeleton {...attrs.memberSkeleton} />
      <Skeleton {...attrs.memberSkeleton} />
    </>
  );

  return (
    <div {...attrs.container}>
      <div {...attrs.header}>
        {isContactsLoading ? (
          headerSkeleton
        ) : (
          <>
            <span {...attrs.text}>{title}</span>
            <span {...attrs.textGray300}>({totalMembersCount})</span>
          </>
        )}
      </div>
      {isContactsLoading ? groupsSkeleton : renderGroups()}
    </div>
  );
};
