import React from 'react';
import {
  ConfirmationModal,
  Modal,
  Form,
  FormField,
} from '../../../../components/SoundWave';
import { useConnectCrmModal } from './hooks/useConnectCrmModal';

export interface ConnectCrmModalProps {
  isOpened: boolean;
  onClose: () => void;
}

export const ConnectCrmModal: React.FC<ConnectCrmModalProps> = ({
  isOpened,
  onClose,
}) => {
  const {
    modalProps,
    formProps,
    fieldProps,
    confirmationModalProps,
    isNoteFieldHidden,
  } = useConnectCrmModal({
    isOpened,
    onClose,
  });

  const attrs = {
    title: {
      className: 'apps__modal-title',
    },
    fieldset: {
      className: 'sound-wave-form__fieldset',
    },
  };

  return (
    <>
      <Modal {...modalProps}>
        <div {...attrs.title}>Which CRM would you like to connect?</div>
        <Form {...formProps}>
          <fieldset {...attrs.fieldset}>
            <FormField {...fieldProps.crmName} />
            {isNoteFieldHidden ? null : <FormField {...fieldProps.note} />}
          </fieldset>
        </Form>
      </Modal>
      <ConfirmationModal {...confirmationModalProps} />
    </>
  );
};
