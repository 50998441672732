import React from 'react';

export const LoaderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    {...props}
  >
    <path
      d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM6.0669 24C6.0669 33.9042 14.0958 41.9331 24 41.9331C33.9042 41.9331 41.9331 33.9042 41.9331 24C41.9331 14.0958 33.9042 6.0669 24 6.0669C14.0958 6.0669 6.0669 14.0958 6.0669 24Z"
      fill="#F8FAFC"
    />
    <path
      d="M13.5156 42.1589C12.6785 43.6087 13.1701 45.4825 14.7138 46.1303C19.5939 48.1781 25.0449 48.5661 30.2116 47.1817C36.3599 45.5342 41.6019 41.5119 44.7845 35.9995C47.9671 30.487 48.8296 23.9361 47.1822 17.7878C45.7978 12.6211 42.7362 8.09442 38.5227 4.89203C37.1898 3.87902 35.3214 4.39018 34.4843 5.84003C33.6472 7.28988 34.1653 9.12596 35.4537 10.195C38.2938 12.5514 40.3583 15.7449 41.3261 19.3569C42.5574 23.9521 41.9128 28.8482 39.5342 32.9682C37.1555 37.0881 33.2377 40.0944 28.6425 41.3256C25.0305 42.2935 21.2325 42.1023 17.7718 40.821C16.2018 40.2397 14.3527 40.709 13.5156 42.1589Z"
      fill="#18222F"
    />
  </svg>
);
