import React from 'react';

export const HubspotIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M12.3565 6.57024C11.9362 6.25327 11.4421 6.0454 10.9194 5.96564H10.9216V4.54257C11.3111 4.36299 11.5607 3.97777 11.5626 3.55341V3.52015C11.561 2.91535 11.0647 2.42543 10.4521 2.42381H10.4184C9.80578 2.42543 9.30956 2.91535 9.30792 3.52015V3.55341C9.30982 3.97777 9.55945 4.36299 9.94891 4.54257V5.96862C9.40036 6.05142 8.88371 6.27586 8.45128 6.61918L4.487 3.57115C4.66726 2.90492 4.26629 2.22056 3.59145 2.0426C2.91661 1.86464 2.22341 2.26048 2.04315 2.92671C1.86289 3.59295 2.26384 4.2773 2.93871 4.45526C3.26139 4.54034 3.60503 4.49599 3.89468 4.33184L7.79229 7.32736C7.07382 8.3975 7.09312 9.79299 7.84096 10.8433L6.65482 12.0143C6.5596 11.9844 6.46046 11.9684 6.36054 11.967C5.79189 11.967 5.33092 12.4221 5.33092 12.9835C5.33092 13.5449 5.79189 14 6.36054 14C6.92919 14 7.39016 13.5449 7.39016 12.9835C7.38875 12.8849 7.37261 12.7869 7.34222 12.693L8.51564 11.5345C9.90421 12.5816 11.8897 12.3192 12.9503 10.9483C14.0109 9.57746 13.7451 7.61733 12.3565 6.57024ZM10.4371 10.6548L10.4334 10.6541C9.53678 10.652 8.81161 9.9328 8.81369 9.04763C8.81576 8.16247 9.54428 7.44657 10.4409 7.44858C11.3363 7.45062 12.061 8.16804 12.0606 9.05206C12.0606 9.93725 11.3337 10.6548 10.4371 10.6548Z"
      fill="#FF7B57"
    />
  </svg>
);
