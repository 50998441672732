import React, { useEffect, useRef, useState } from 'react';
import { TooltipIcon } from '../../../../../components/Icons/TooltipIcon';

type UsersSeatsLeftProps = {
  usersCount: number;
  maxUsersCount: number;
};

export const UsersSeatsLeft: React.FC<UsersSeatsLeftProps> = ({
  usersCount,
  maxUsersCount,
}: UsersSeatsLeftProps): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipSeatsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      const isTooltipTurget = tooltipSeatsRef.current?.contains(target);
      if (showTooltip && !isTooltipTurget) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showTooltip]);

  const attrs = {
    wrapper: {
      className: 'table__controls-seats__left',
    },

    messageLink: {
      className: 'table__controls-seats__left-message__link',
      rel: 'noreferrer noopener',
      href: 'https://www.substrata.me/contact/',
      target: '_blank',
    },

    tooltipWrapper: {
      className: 'table__controls-seats__left-tooltip__icon',
    },

    icon: {
      active: showTooltip,
      openTooltip: setShowTooltip,
    },

    tooltip: {
      className: showTooltip
        ? 'open table__controls-seats__left-tooltip__icon-tooltip'
        : 'closed table__controls-seats__left-tooltip__icon-tooltip',
      onMouseLeave: () => setShowTooltip(false),
      ref: tooltipSeatsRef,
    },

    tooltipLink: {
      className: 'table__controls-seats__left-tooltip__icon-tooltip-link',
      rel: 'noreferrer noopener',
      href: 'https://www.substrata.me/contact/',
      target: '_blank',
    },
  };

  const seatsLeft =
    maxUsersCount === -1 ? 'Unlimited' : maxUsersCount - usersCount;

  const seatsLeftMessage =
    seatsLeft === 'Unlimited'
      ? 'Unlimited seats'
      : `${seatsLeft} ${seatsLeft === 1 ? 'seat' : 'seats'} left`;

  const conditionalMessage =
    seatsLeft === 'Unlimited' || seatsLeft >= 1 ? (
      <span>{seatsLeftMessage}</span>
    ) : (
      <span>
        0 seats left - <a {...attrs.messageLink}>Contact us</a>{' '}
      </span>
    );

  const tooltip =
    seatsLeft === 'Unlimited' ? (
      <div {...attrs.tooltip}>
        You have unlimited seats{' '}
        <span role="img" aria-label="congratulations">
          🎉
        </span>
      </div>
    ) : (
      <div {...attrs.tooltip}>
        You’ve purchased a total of {maxUsersCount} seats,{' '}
        <a {...attrs.tooltipLink}>contact us</a> to buy more
      </div>
    );

  return (
    <div {...attrs.wrapper}>
      {conditionalMessage}
      <div {...attrs.tooltipWrapper}>
        <TooltipIcon {...attrs.icon} />
        {tooltip}
      </div>
    </div>
  );
};
