import React from 'react';

export const PauseIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3C9.65685 3 11 4.34315 11 6V18C11 19.6569 9.65685 21 8 21C6.34315 21 5 19.6569 5 18V6C5 4.34315 6.34315 3 8 3ZM16 3C17.6569 3 19 4.34315 19 6V18C19 19.6569 17.6569 21 16 21C14.3431 21 13 19.6569 13 18V6C13 4.34315 14.3431 3 16 3Z"
      fill="white"
    />
  </svg>
);
