import React from 'react';
import { Button, Modal } from '../../../../components';
import { User } from '../../../../types';

type ResentInviteModalProps = {
  onClose: () => void;
  user: User | null;
  open: boolean;
};

export const ResentInviteModal: React.FC<ResentInviteModalProps> = ({
  onClose,
  user,
  open,
}: ResentInviteModalProps): JSX.Element => {
  return (
    <Modal open={open} title="Invite was resent successfully" onClose={onClose}>
      <>
        <div className="resend__invite__message">
          Invite to the address {user?.email} was resent success
        </div>
        <Button onClick={onClose} variant="common">
          Got it
        </Button>
      </>
    </Modal>
  );
};
