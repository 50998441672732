import React, { FC, useEffect, useState } from 'react';
import { barlines } from '../../icons';
import { ReactComponent as Triangle } from '../../icons/triangle_icon.svg';
import GetColorAndMessage from './getColorAndMessage';
import InfobarsSkeleton from '../skeletons/InfobarsSkeleton';
import { TooltipIcon } from '../../../../components/Icons/TooltipIcon';

interface AnimatematedInfoBarProps {
  title: string;
  min: number;
  max: number;
  current: number | string;
  tooltipMessage?: string;
  animationTime: number;
  isLoading: boolean;
  skelTitleWidth: number;
}

export const AnimatematedInfoBar: FC<AnimatematedInfoBarProps> = (props) => {
  const {
    min,
    max,
    title,
    tooltipMessage,
    animationTime,
    isLoading,
    skelTitleWidth,
    current,
  } = props;

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [color, setColor] = useState('#c5c5cc');
  const [measure, setMeasure] = useState(NaN);

  const maxPosssibleVal = max - min;

  const isCurrentHasTypeNumber =
    !Number.isNaN(current) &&
    current !== undefined &&
    typeof current === 'number';

  const conditionToShowNoData =
    Number.isNaN(current) ||
    current === undefined ||
    typeof current !== 'number';

  useEffect(() => {
    setColor('#c5c5cc');
    setMeasure(NaN);
    const barsTimer = setTimeout(() => {
      if (isCurrentHasTypeNumber) {
        const { triangeColor } = GetColorAndMessage(current as number, title);
        setColor(triangeColor);
        const fullValue = (current as number) - min;
        setMeasure((fullValue / maxPosssibleVal) * 100);
      }
    }, 200);
    return () => {
      clearTimeout(barsTimer);
    };
  }, [current]); // eslint-disable-line

  const animationDuration: number = animationTime > 2 ? animationTime : 2;

  const comingSoon =
    title === 'subtleness' ? (
      <span className="barwrapper-footer__coming-soon">coming soon</span>
    ) : null;

  const attrs = {
    wrapper: {
      className: 'barwrapper',
    },
    skeleton: {
      skelTitleWidth,
      duration: 2200,
      globalLoading: isLoading,
    },
    title: {
      wrapper: {
        className: 'barwrapper-title',
      },
      tooltip: {
        wrapper: {
          className: 'barwrapper-title__icon-section',
        },
        icon: {
          active: tooltipOpen,
          openTooltip: () => setTooltipOpen(true),
          closeTooltip: () => setTooltipOpen(false),
          className: 'barwrapper-title__icon-section-icon',
        },
        tooltip: {
          className: tooltipOpen
            ? 'open barwrapper-title__icon-section-tooltip'
            : 'barwrapper-title__icon-section-tooltip',
        },
      },
    },
    body: {
      barNoData: {
        wrapper: {
          className: 'barwrapper__bar-section-bar',
        },
        greyBar: {
          className: 'barwrapper__bar-section-bar-grey',
        },
        lines: {
          className: 'barwrapper__bar-section-bar-lines',
          src: barlines,
        },
        noData: {
          className: 'barwrapper-footer__not-enough-data',
        },
      },
      animatedBar: {
        wrapper: {
          className: 'barwrapper__bar-section',
        },
        animatedTriangle: {
          wrapper: {
            className: 'barwrapper__bar-section-triangle',
            style: {
              left: `${measure}%`,
              transitionDuration: `${animationDuration}s`,
              transitionTimingFunction: 'ease',
            },
          },
          triangle: {
            fill: isCurrentHasTypeNumber ? color : '#c5c5cc',
            className: 'barwrapper__bar-section-triangle-color',
          },
        },
        bar: {
          wrapper: {
            className: 'barwrapper__bar-section-bar',
          },
          gradient: {
            className: 'barwrapper__bar-section-bar-gradient',
          },
          lines: {
            className: 'barwrapper__bar-section-bar-lines',
            src: barlines,
          },
        },
      },
    },
    footer: {
      wrapper: { className: 'barwrapper-footer' },
      label: { className: 'barwrapper-footer__text' },
    },
  };

  const titleSection = (
    <div {...attrs.title.wrapper}>
      {title}
      <div {...attrs.title.tooltip.wrapper}>
        <TooltipIcon {...attrs.title.tooltip.icon} />
        <div {...attrs.title.tooltip.tooltip}>{tooltipMessage}</div>
      </div>
    </div>
  );

  const conditionalBodySection = conditionToShowNoData ? (
    <>
      <div {...attrs.body.barNoData.wrapper}>
        <div {...attrs.body.barNoData.greyBar} />
        <img {...attrs.body.barNoData.lines} alt="lines" />
        {title !== 'subtleness' && current === 'no-data' ? (
          <span {...attrs.body.barNoData.noData}>not enough data</span>
        ) : (
          <></>
        )}
      </div>
    </>
  ) : (
    <div {...attrs.body.animatedBar.wrapper}>
      {title === 'subtleness' ? null : (
        <div {...attrs.body.animatedBar.animatedTriangle.wrapper}>
          <Triangle {...attrs.body.animatedBar.animatedTriangle.triangle} />
        </div>
      )}
      <div {...attrs.body.animatedBar.bar.wrapper}>
        <div {...attrs.body.animatedBar.bar.gradient} />
        <img {...attrs.body.animatedBar.bar.lines} alt="lines" />
      </div>
    </div>
  );

  const footer = (
    <div {...attrs.footer.wrapper}>
      <span {...attrs.footer.label}>LOW</span>
      {comingSoon}
      <span {...attrs.footer.label}>HIGH</span>
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      <InfobarsSkeleton {...attrs.skeleton}>
        <>
          {titleSection}
          {conditionalBodySection}
          {footer}
        </>
      </InfobarsSkeleton>
    </div>
  );
};
