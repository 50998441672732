import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Modal,
  useFileUpload,
  ImageCropper,
  Input,
  Button,
} from '../../../../components';
import {
  ButtonType,
  ButtonVariantType,
} from '../../../../components/Button/Button';
import { RootState } from '../../../../store/store';
import { clearErrors } from '../../../../store/users/users.actions';
import { AvatarUploadClip } from '../../../Teams/elements/avatarUploadClip/AvatarUploadClip';
import { useForm } from '../../hooks/useForm';
import { email, org, user, photo, phone } from '../../icons';
import { InviteSuccessModal } from '../InviteSuccessModal/InviteSuccessModal';

type AddUserProps = {
  open: boolean;
  onClose: () => void;
};

export const AddUser: React.FC<AddUserProps> = ({
  open,
  onClose,
}: AddUserProps): JSX.Element => {
  const {
    croppedImage,
    file,
    handleUpload,
    removeFile,
    saveCroppedImage,
  } = useFileUpload();
  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    closeModal,
    inviteSuccess,
    touched,
    handleCancel,
  } = useForm(onClose, croppedImage);
  const { org: organization } = useSelector((state: RootState) => state.auth);
  const {
    errors: { addUser: addUserError },
  } = useSelector((state: RootState) => state.users);
  const dispatch = useDispatch();

  const [emailFieldLength, setEmailFieldLength] = useState(0);

  useEffect(() => {
    setEmailFieldLength(values.primaryEmail.length);
  }, [values.primaryEmail]);

  useEffect(() => {
    if (
      addUserError &&
      values.primaryEmail.length &&
      values.primaryEmail.length !== emailFieldLength
    ) {
      dispatch(clearErrors());
    }
  }, [values.primaryEmail, emailFieldLength, addUserError, dispatch]);

  const onClosemodal = () => {
    saveCroppedImage('');
    closeModal();
  };
  const onClickCancel = () => {
    saveCroppedImage('');
    handleCancel();
  };

  const attrs = {
    imageCropperModal: {
      open: !!file,
      onClose: removeFile,
      title: 'Upload a photo',
      height: '570px',
      upIndex: true,
    },
    imageCropper: {
      onSave: saveCroppedImage,
      onCancel: removeFile,
      image: file || '',
    },
    inviteSucsessModal: {
      open: inviteSuccess,
      onClose: onClosemodal,
    },
    createUserModal: {
      open,
      onClose: onClickCancel,
      title: 'Add new user',
      width: '610px',
      opacity: file ? '0' : '1',
    },
    avatar: {
      className: 'add-user__avatar',
      onUpload: handleUpload,
      image: croppedImage || photo,
      hasUpload: true,
      style: { width: '5rem', height: '5rem' },
      inModal: true,
    },
    form: {
      onSubmit: handleSubmit,
    },
    inputs: {
      firstName: {
        error: touched.firstName ? errors.firstName : '',
        onChange: handleChange,
        value: values.firstName,
        name: 'firstName',
        icon: user,
        className:
          errors.firstName && values.firstName.length
            ? 'error add-user__form-block__input'
            : 'add-user__form-block__input',
        label: 'First name',
        required: true,
      },
      lastName: {
        error: touched.lastName ? errors.lastName : '',
        onChange: handleChange,
        value: values.lastName,
        name: 'lastName',
        icon: user,
        className:
          errors.lastName && values.lastName.length
            ? 'error add-user__form-block__input'
            : 'add-user__form-block__input',
        label: 'Last name',
        required: true,
      },
      email: {
        error:
          addUserError || (touched.primaryEmail ? errors.primaryEmail : ''),
        onChange: handleChange,
        value: values.primaryEmail,
        name: 'primaryEmail',
        icon: email,
        className:
          addUserError || (errors.primaryEmail && values.primaryEmail.length)
            ? 'error-email add-user__form-block__input'
            : 'add-user__form-block__input',
        label: 'Primary email',
        required: true,
      },
      phoneNumber: {
        error: touched.phoneNumber ? errors.phoneNumber : '',
        onChange: handleChange,
        value: values.phoneNumber,
        name: 'phoneNumber',
        icon: phone,
        className:
          addUserError || (errors.primaryEmail && values.primaryEmail.length)
            ? 'error add-user__form-block__input'
            : 'add-user__form-block__input',
        label: 'Phone number',
      },
      organization: {
        onChange: handleChange,
        value: organization?.name,
        name: 'organizationalUnit',
        icon: org,
        className: 'add-user__form-block__input-last',
        label: 'Organizational unit',
        disabled: true,
      },
    },
    div: {
      addUser: {
        className: 'add-user',
      },
      addUserForm: {
        className: 'add-user__form',
      },
      addUserFormBlock: {
        className: 'add-user__form-block',
      },
    },
    button: {
      type: 'submit' as ButtonType,
      className: 'add-user__form__btn',
      variant: 'common' as ButtonVariantType,
      disabled: !values.firstName || !values.lastName || !values.primaryEmail,
    },
  };

  const avatar = croppedImage ? <Avatar {...attrs.avatar} /> : null;

  const avatarUploadClip = !croppedImage ? (
    <div {...attrs.div.addUserFormBlock}>
      <AvatarUploadClip
        onUpload={handleUpload}
        className="add-user__form-block-avatar__upload__clip"
      />
    </div>
  ) : null;

  return (
    <div>
      <Modal {...attrs.imageCropperModal}>
        <ImageCropper {...attrs.imageCropper} />
      </Modal>
      <InviteSuccessModal {...attrs.inviteSucsessModal} />
      <Modal {...attrs.createUserModal}>
        <div {...attrs.div.addUser}>
          {avatar}
          <form {...attrs.form}>
            <div {...attrs.div.addUserForm}>
              <div {...attrs.div.addUserFormBlock}>
                <Input {...attrs.inputs.firstName} />
                <Input {...attrs.inputs.lastName} />
              </div>
              {avatarUploadClip}
              <div {...attrs.div.addUserFormBlock}>
                <Input {...attrs.inputs.email} />
                <Input {...attrs.inputs.phoneNumber} />
              </div>
              <div {...attrs.div.addUserFormBlock}>
                <Input {...attrs.inputs.organization} />
              </div>
              <Button {...attrs.button}>Send invite</Button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};
