import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  isLoading?: boolean;
  children?: JSX.Element | string;
  isInTeamQ?: boolean;
}

export const MeetingDurationSkeleton: React.FC<Props> = (props: Props) => {
  const { isLoading, children, isInTeamQ } = props;

  const attrs = {
    wrappers: {
      wrapper: {
        className: `${isInTeamQ ? 'team-q-chart' : ''} chart-wrapper`,
      },

      title: {
        className: 'chart-wrapper__title-block',
      },

      subtitle: {
        className: `${isInTeamQ ? 'no-border ' : ''}chart-wrapper__subtitle`,
        style: { paddingBottom: '11px' },
      },

      chart: {
        wrapper: {
          className: 'meeting-count-chart-s',
        },

        yAxis: {
          className: 'meeting-count-chart-s__first-column',
        },

        body: {
          className: 'meeting-count-chart-s__second-column',
        },

        xAxis: {
          className: 'meeting-count-chart-s__second-column__x-axis',
        },
      },
    },

    skeletons: {
      title: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 80,
            maxWidth: '20%',
            height: 13,
            borderRadius: 30,
            marginTop: -5,
          },
        },
      },

      subtitle: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 80,
            maxWidth: '20%',
            height: 13,
            borderRadius: 30,
            marginTop: -5,
          },
        },
      },

      chartBody: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: '100%',
            maxWidth: '100%',
            height: 171,
            borderRadius: 20,
            marginTop: 0,
          },
        },
      },

      yTick: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 20,
            height: 12,
            borderRadius: 30,
            marginTop: 0,
          },
        },
      },

      xTick: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 20,
            height: 12,
            borderRadius: 30,
            margin: '2px 0px 0px 5px',
          },
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div {...attrs.wrappers.wrapper}>
          <div {...attrs.wrappers.title}>
            <Skeleton {...attrs.skeletons.title} />
          </div>
          <div {...attrs.wrappers.subtitle}>
            <Skeleton {...attrs.skeletons.subtitle} />
          </div>
          <div {...attrs.wrappers.chart.wrapper}>
            <div {...attrs.wrappers.chart.yAxis}>
              <Skeleton {...attrs.skeletons.yTick} />
              <Skeleton {...attrs.skeletons.yTick} />
              <Skeleton {...attrs.skeletons.yTick} />
              <Skeleton {...attrs.skeletons.yTick} />
              <Skeleton {...attrs.skeletons.yTick} />
            </div>
            <div {...attrs.wrappers.chart.body}>
              <Skeleton {...attrs.skeletons.chartBody} />
              <div {...attrs.wrappers.chart.xAxis}>
                <Skeleton {...attrs.skeletons.xTick} />
                <Skeleton {...attrs.skeletons.xTick} />
                <Skeleton {...attrs.skeletons.xTick} />
                <Skeleton {...attrs.skeletons.xTick} />
                <Skeleton {...attrs.skeletons.xTick} />
                <Skeleton {...attrs.skeletons.xTick} />
                <Skeleton {...attrs.skeletons.xTick} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
