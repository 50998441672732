import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  durationText: number;
  globalLoading: boolean;
  children?: JSX.Element | string;
}

const RelationsSkeleton: React.FC<Props> = (props: Props) => {
  const { durationText, globalLoading, children } = props;
  const [isLoadingText, setIsLoadingText] = useState<boolean>(false);

  useEffect(() => {
    setIsLoadingText(true);
    const timer = setTimeout(() => {
      setIsLoadingText(false);
    }, durationText);
    return () => {
      clearTimeout(timer);
    };
  }, [globalLoading]); // eslint-disable-line

  const attrs = {
    blockWrapper: { className: 'relations__skeleton-block' },
    imageBlock: { className: 'relations__skeleton-block__img-block' },
    lineSkeleton: {
      loading: isLoadingText,
      active: true,
      avatar: false,
      paragraph: false,
    },
    roundSkeleton: {
      loading: isLoadingText,
      active: true,
      title: false,
      paragraph: false,
    },
  };

  return (
    <>
      {' '}
      {isLoadingText ? (
        <>
          <div {...attrs.blockWrapper}>
            <Skeleton
              {...attrs.lineSkeleton}
              title={{
                style: {
                  width: 168,
                  height: 15,
                  borderRadius: 30,
                  marginBottom: 16,
                },
              }}
            />
            <div {...attrs.imageBlock}>
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 16,
                  style: { position: 'absolute', top: '15.5%', left: '93.3%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 16,
                  style: { position: 'absolute', top: '15.5%', left: '0%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 30,
                  style: { position: 'absolute', top: '-6%', left: '43.5%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 22,
                  style: { position: 'absolute', top: '5%', left: '54%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 22,
                  style: { position: 'absolute', top: '5%', left: '36%' },
                }}
              />
            </div>
            <Skeleton
              {...attrs.lineSkeleton}
              title={{
                style: {
                  position: 'absolute',
                  width: 58,
                  height: 12,
                  borderRadius: 30,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, 130%)',
                },
              }}
            />
          </div>
          <div {...attrs.blockWrapper}>
            <Skeleton
              loading={isLoadingText}
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 144,
                  height: 15,
                  borderRadius: 30,
                  margin: '5px 0px 16px',
                },
              }}
            />
            <div {...attrs.imageBlock}>
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 16,
                  style: { position: 'absolute', top: '13.5%', left: '93.3%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 16,
                  style: { position: 'absolute', top: '13.5%', left: '0%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 30,
                  style: { position: 'absolute', top: '-8%', left: '43.5%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 22,
                  style: { position: 'absolute', top: '3%', left: '54%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 22,
                  style: { position: 'absolute', top: '3%', left: '36%' },
                }}
              />
            </div>
            <Skeleton
              {...attrs.lineSkeleton}
              title={{
                style: {
                  position: 'absolute',
                  width: 58,
                  height: 12,
                  borderRadius: 30,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, 111%)',
                },
              }}
            />
          </div>
          <div {...attrs.blockWrapper}>
            <Skeleton
              {...attrs.lineSkeleton}
              title={{
                style: {
                  width: 149,
                  height: 15,
                  borderRadius: 30,
                  margin: '2px 0px 16px',
                },
              }}
            />
            <div {...attrs.imageBlock}>
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 16,
                  style: { position: 'absolute', top: '5.5%', left: '93.3%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 16,
                  style: { position: 'absolute', top: '5.5%', left: '0%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 30,
                  style: { position: 'absolute', top: '-14%', left: '43.5%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 22,
                  style: { position: 'absolute', top: '-3%', left: '54%' },
                }}
              />
              <Skeleton
                {...attrs.roundSkeleton}
                avatar={{
                  size: 22,
                  style: { position: 'absolute', top: '-2%', left: '36.5%' },
                }}
              />
            </div>
            <Skeleton
              {...attrs.lineSkeleton}
              title={{
                style: {
                  position: 'absolute',
                  width: 58,
                  height: 12,
                  borderRadius: 30,
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, 75%)',
                },
              }}
            />
          </div>
        </>
      ) : (
        children
      )}
    </>
  );
};

export default RelationsSkeleton;
