import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { EventAnalysisSummary } from '../../../../components/EventAnalysisSummary/EventAnalysisSummary';
import { useAppSelector } from '../../../../store/hooks';
import {
  getEmailParticipantsAvatarProps,
  getUpperHandAnalysisTooltip,
} from './EmailAnalysis.utils';
import { UpperHandAnalysis } from '../../../../components/EventAnalysisCompare/components/UpperHandAnalysis/UpperHandAnalysis';
import { EventAnalysisCompare } from '../../../../components/EventAnalysisCompare/EventAnalysisCompare';
import { RelationshipMemberSide } from '../../../../types/relationshipMember.types';
import { SentimentAnalysis } from '../../../../components/EventAnalysisCompare/components/SentimentAnalysis/SentimentAnalysis';
import { RangeAnalysis } from '../../../../components/EventAnalysisCompare/components/RangeAnalysis/RangeAnalysis';
import { EventSummaryItem } from '../../../../components/EventSummaryItem/EventSummaryItem';
import { EventSummaryTopics } from '../../../../components/EventSummaryItem/components/EventSummaryTopics/EventSummaryTopics';
import { EventSummaryNextMove } from '../../../../components/EventSummaryItem/components/EventSummaryNextMove/EventSummaryNextMove';
import { EventSummarySignals } from '../../../../components/EventSummaryItem/components/EventSummarySignals/EventSummarySignals';
import { getTimeString } from '../../utils/getTimeString';
import { getRelativeRangeValues } from '../../../../components/EventAnalysisCompare/components/RangeAnalysis/utils/getRelativeRangeValues';
import { AnalysisDisclaimer } from '../../../../components/EventAnalysisCompare/components/AnalysisDisclaimer/AnalysisDisclaimer';
import { selectedEmailWithContactsInfoSelector } from '../../../../store/threadDetails/threadDetails.selectors';

export const EmailAnalysis: React.FC = () => {
  const isDetailedAnalysisLoading = useAppSelector(
    (state) => state.threadDetails.isLoading.threadDetails
  );

  const user = useAppSelector((state) => state.auth.user);

  const touchPoint = useAppSelector((state) => state.threadDetails.touchPoint);

  const isUserThreadOwner =
    !!user && !!touchPoint && user.userId === touchPoint.userOwnerId;

  const selectedBranch = useAppSelector(
    (state) => state.threadDetails.selectedBranch
  );

  const selectedEmail = useAppSelector(selectedEmailWithContactsInfoSelector);

  const {
    sentAt,
    sender,
    recipients,
    dominantParticipant,
    participants,
    sentiment,
    topics,
    assessmentInsights,
  } = selectedEmail || {};

  const { leftParticipant, rightParticipant } = participants || {};
  const { lastEmailRelativeMetrics } = assessmentInsights || {};
  const { relativeReplyTime, relativeWordCount } =
    lastEmailRelativeMetrics || {};

  const buyerParticipant = leftParticipant?.isUserDomain
    ? rightParticipant
    : rightParticipant;

  const sellerParticipant = leftParticipant?.isUserDomain
    ? leftParticipant
    : rightParticipant;

  const buyerSideReplyTimeValue = buyerParticipant?.isSender
    ? relativeReplyTime?.currentEmailValue
    : relativeReplyTime?.previousEmailValue;

  const sellerSideReplyTimeValue = sellerParticipant?.isSender
    ? relativeReplyTime?.currentEmailValue
    : relativeReplyTime?.previousEmailValue;

  const buyerSideWordsCountValue = buyerParticipant?.isSender
    ? relativeWordCount?.currentEmailValue
    : relativeWordCount?.previousEmailValue;

  const sellerSideWordsCountValue = sellerParticipant?.isSender
    ? relativeWordCount?.currentEmailValue
    : relativeWordCount?.previousEmailValue;

  const buyerParticipantName =
    buyerParticipant?.metadata?.name || buyerParticipant?.email || '';

  const sellerParticipantName =
    sellerParticipant?.metadata?.name || sellerParticipant?.email || '';

  const senderAvatar = useMemo(() => {
    if (!sender || isDetailedAnalysisLoading) {
      return {};
    }

    return getEmailParticipantsAvatarProps(sender)[0];
  }, [sender, isDetailedAnalysisLoading]);

  const toRecipientsAvatars = useMemo(() => {
    if (!recipients || isDetailedAnalysisLoading) {
      return [];
    }

    const toRecipients = recipients.filter(({ type }) => type === 'to');

    return getEmailParticipantsAvatarProps(...toRecipients);
  }, [recipients, isDetailedAnalysisLoading]);

  const ccRecipientsAvatars = useMemo(() => {
    if (!recipients || isDetailedAnalysisLoading) {
      return [];
    }

    const ccRecipients = recipients.filter(({ type }) => type === 'cc');

    return getEmailParticipantsAvatarProps(...ccRecipients);
  }, [recipients, isDetailedAnalysisLoading]);

  const summaryTopicsLabels = useMemo(() => {
    if (!topics || isDetailedAnalysisLoading) {
      return [];
    }

    return topics.map((topic: string) => ({ text: topic }));
  }, [isDetailedAnalysisLoading, topics]);

  const relativeReplyTimeValues = useMemo(
    () =>
      getRelativeRangeValues({
        buyerSideReplyTime: buyerSideReplyTimeValue,
        sellerSideReplyTime: sellerSideReplyTimeValue,
      }),
    [buyerSideReplyTimeValue, sellerSideReplyTimeValue]
  );

  const relativeWordCountValues = useMemo(
    () =>
      getRelativeRangeValues({
        buyerSideWordsCount: buyerSideWordsCountValue,
        sellerSideWordsCount: sellerSideWordsCountValue,
      }),
    [buyerSideWordsCountValue, sellerSideWordsCountValue]
  );

  const attrs = {
    container: {
      id: 'email-analysis',
    },
    title: {
      className: 'event-frame__content-title',
    },
    eventAnalysisSummary: {
      isLoading: isDetailedAnalysisLoading,
      items: [
        {
          title: 'Date & Time',
          value: sentAt
            ? DateTime.fromISO(sentAt).toFormat('MMM dd, yyyy, hh:mm a')
            : '',
        },
        {
          title: 'From',
          value: senderAvatar,
        },
        {
          title: 'To',
          value: toRecipientsAvatars,
        },
        {
          title: 'Cc',
          value: ccRecipientsAvatars.length ? ccRecipientsAvatars : '-',
        },
      ],
    },
    metricsWrapper: {
      className: 'email-analysis__metrics-wrapper',
    },
    upperHandAnalysisContainer: {
      title: 'Upper hand',
      tooltip:
        'View the power-balance between you and your prospect at any given point.',
      contentTooltip: getUpperHandAnalysisTooltip(
        leftParticipant,
        rightParticipant,
        dominantParticipant,
        isUserThreadOwner
      ),
    },
    upperHandAnalysis: {
      isLoading: isDetailedAnalysisLoading,
      ...(dominantParticipant && {
        dominantSide: dominantParticipant,
      }),
      ...(leftParticipant && {
        leftParticipant: {
          ...leftParticipant,
          side:
            leftParticipant.contact?.extra?.currentAccountLink?.contactSide ||
            RelationshipMemberSide.UNASSIGNED,
        },
      }),
      ...(rightParticipant && {
        rightParticipant: {
          ...rightParticipant,
          side:
            rightParticipant.contact?.extra?.currentAccountLink?.contactSide ||
            RelationshipMemberSide.UNASSIGNED,
        },
      }),
    },
    sentimentAnalysisContainer: {
      title: sender
        ? `${sender.metadata.name || sender.email}'s sentiment`
        : 'Sentiment',
      tooltip:
        'Analyzes the pragmatic emotional attitude expressed in the message.',
    },
    sentimentAnalysis: {
      isLoading: isDetailedAnalysisLoading,
      value: sentiment?.mag,
    },
    replyTimeContainer: {
      title: 'Reply time',
      tooltip: `Compares your measured reply time to the prospect's response time in the last few exchanges.`,
    },
    replyTimeDisclaimer: {
      title:
        !isDetailedAnalysisLoading &&
        typeof relativeReplyTimeValues.buyerSideReplyTime !== 'number' &&
        typeof relativeReplyTimeValues.sellerSideReplyTime !== 'number'
          ? 'No data to display yet'
          : relativeReplyTime?.insightText || '',
      ...(relativeReplyTime?.color && {
        isPositive: relativeReplyTime.color === 'green',
      }),
    },
    buyerSideReplyTime: {
      isLoading: isDetailedAnalysisLoading,
      title: buyerParticipantName,
      value: getTimeString(buyerSideReplyTimeValue || 0),
      rangeValue: relativeReplyTimeValues.buyerSideReplyTime,
      side:
        buyerParticipant?.contact?.extra?.currentAccountLink?.contactSide ||
        RelationshipMemberSide.UNASSIGNED,
    },
    sellerSideReplyTime: {
      isLoading: isDetailedAnalysisLoading,
      title: `${sellerParticipantName}${
        sellerParticipant?.isUser && isUserThreadOwner ? ' (You)' : ''
      }`,
      value: getTimeString(sellerSideReplyTimeValue || 0),
      rangeValue: relativeReplyTimeValues.sellerSideReplyTime,
      side:
        sellerParticipant?.contact?.extra?.currentAccountLink?.contactSide ||
        RelationshipMemberSide.UNASSIGNED,
    },
    messageLengthContainer: {
      title: 'Message length',
      tooltip:
        "Compares your email message word count to the prospect's word count in the last few exchanges.",
    },
    wordsCountDisclaimer: {
      title:
        !isDetailedAnalysisLoading &&
        typeof relativeWordCountValues.buyerSideWordsCount !== 'number' &&
        typeof relativeWordCountValues.sellerSideWordsCount !== 'number'
          ? 'No data to display yet'
          : relativeWordCount?.insightText || '',
      ...(relativeWordCount?.color && {
        isPositive: relativeWordCount.color === 'green',
      }),
    },
    buyerSideWordsCount: {
      isLoading: isDetailedAnalysisLoading,
      title: buyerParticipantName,
      value:
        typeof buyerSideWordsCountValue === 'number'
          ? `${(buyerSideWordsCountValue || 0)
              .toFixed(1)
              .replace('.0', '')} word${
              buyerSideWordsCountValue === 1 ? '' : 's'
            }`
          : 'N/A',
      rangeValue: relativeWordCountValues.buyerSideWordsCount,
      side:
        buyerParticipant?.contact?.extra?.currentAccountLink?.contactSide ||
        RelationshipMemberSide.UNASSIGNED,
    },
    sellerSideWordsCount: {
      isLoading: isDetailedAnalysisLoading,
      title: `${sellerParticipantName}${
        sellerParticipant?.isUser && isUserThreadOwner ? ' (You)' : ''
      }`,
      value:
        typeof sellerSideWordsCountValue === 'number'
          ? `${(sellerSideWordsCountValue || 0)
              .toFixed(1)
              .replace('.0', '')} word${
              sellerSideWordsCountValue === 1 ? '' : 's'
            }`
          : 'N/A',
      rangeValue: relativeWordCountValues.sellerSideWordsCount,
      side:
        sellerParticipant?.contact?.extra?.currentAccountLink?.contactSide ||
        RelationshipMemberSide.UNASSIGNED,
    },
    summaryTopicsContainer: {
      title: 'Topics',
    },
    summaryTopics: {
      isLoading: isDetailedAnalysisLoading,
      labels: summaryTopicsLabels,
      emptyText: 'No topics detected for this message.',
    },
    nextMoveContainer: {
      title: 'Next move',
    },
    nextMove: {
      isLoading: isDetailedAnalysisLoading,
      emptyText: 'No suggestions at the moment.',
      text: selectedBranch?.nextMove?.value || '',
    },
    emailSignalsContainer: {
      title: 'Assessments',
    },
    emailSignals: {
      isLoading: isDetailedAnalysisLoading,
      emptyText: 'No assessments detected for this message.',
      signals: assessmentInsights?.assessmentInsights || [],
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.title}>Email Analysis</div>
      <EventAnalysisSummary {...attrs.eventAnalysisSummary} />
      <div {...attrs.metricsWrapper}>
        <EventAnalysisCompare {...attrs.sentimentAnalysisContainer}>
          <SentimentAnalysis {...attrs.sentimentAnalysis} />
        </EventAnalysisCompare>
        <EventAnalysisCompare {...attrs.upperHandAnalysisContainer}>
          <UpperHandAnalysis {...attrs.upperHandAnalysis} />
        </EventAnalysisCompare>
      </div>
      <div {...attrs.metricsWrapper}>
        <EventAnalysisCompare {...attrs.replyTimeContainer}>
          <RangeAnalysis {...attrs.buyerSideReplyTime} />
          <RangeAnalysis {...attrs.sellerSideReplyTime} />
          <AnalysisDisclaimer {...attrs.replyTimeDisclaimer} />
        </EventAnalysisCompare>
        <EventAnalysisCompare {...attrs.messageLengthContainer}>
          <RangeAnalysis {...attrs.buyerSideWordsCount} />
          <RangeAnalysis {...attrs.sellerSideWordsCount} />
          <AnalysisDisclaimer {...attrs.wordsCountDisclaimer} />
        </EventAnalysisCompare>
      </div>
      <EventSummaryItem {...attrs.summaryTopicsContainer}>
        <EventSummaryTopics {...attrs.summaryTopics} />
      </EventSummaryItem>
      <EventSummaryItem {...attrs.nextMoveContainer}>
        <EventSummaryNextMove {...attrs.nextMove} />
      </EventSummaryItem>
      <EventSummaryItem {...attrs.emailSignalsContainer}>
        <EventSummarySignals {...attrs.emailSignals} />
      </EventSummaryItem>
    </div>
  );
};
