import React from 'react';
import { OptionIconProps } from '../../../../../components/Popup/PopupOption';

export const PencilPopupIcon: React.FC<OptionIconProps> = ({
  disabled,
}): JSX.Element => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={disabled ? '0.4' : '1'}
    >
      <g clipPath="url(#clip0_399_38533)">
        <path
          d="M7.4103 2.72274L9.27623 4.58868L3.07349 10.7909L0.588992 11.41L1.20797 8.92552L7.4103 2.72274Z"
          stroke="white"
        />
        <path
          d="M9.39979 0.733508L9.39982 0.733473C9.6953 0.43794 10.2348 0.43765 10.5307 0.733508L11.2656 1.46848C11.4172 1.62 11.4997 1.81926 11.4997 2.03398C11.4997 2.24871 11.4172 2.44792 11.2657 2.59931L11.2656 2.59938L10.7189 3.14612L8.85304 1.28025L9.39979 0.733508Z"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_399_38533">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(-0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
