import React, { useEffect, useRef } from 'react';
import { useIMask } from 'react-imask';
import { useRanger, Ranger } from '@tanstack/react-ranger';
import { getInputsMaskOptions } from './utils/getInputsMaskOptions';
import { RangeFilterMaskType, RangeFilterValue } from '../../../../../../types/meetingRecordsFilters.types';

export interface FilterRangeProps {
  min: number;
  max: number;
  value: RangeFilterValue;
  onChange: (value: RangeFilterValue) => void;
  maskType?: RangeFilterMaskType;
}

export const FilterRange: React.FC<FilterRangeProps> = ({
  min,
  max,
  value,
  onChange,
  maskType,
}) => {
  const rangerRef = useRef<HTMLDivElement>(null);

  const rangerInstance = useRanger<HTMLDivElement>({
    min,
    max,
    values: [value.min, value.max],
    stepSize: 1,
    getRangerElement: () => rangerRef.current,
    onChange: (instance: Ranger<HTMLDivElement>) => {
      const [newMin, newMax] = instance.sortedValues;
      onChange({ min: newMin, max: newMax });
    },
  });

  const {
    ref: minInputRef,
    setUnmaskedValue: setMinInputUnmaskedValue,
  } = useIMask<HTMLInputElement>(getInputsMaskOptions(min, max, maskType), {
    defaultUnmaskedValue: `${value.min}`,
    onAccept: (_, { unmaskedValue }) => {
      const newMin = parseFloat(unmaskedValue) || 0;
      const newMax = newMin > value.max ? newMin : value.max;
      onChange({ min: newMin, max: newMax });
    },
  });

  const {
    ref: maxInput,
    setUnmaskedValue: setMaxInputUnmaskedValue,
  } = useIMask<HTMLInputElement>(getInputsMaskOptions(min, max, maskType), {
    defaultUnmaskedValue: `${value.max}`,
    onAccept: (_, { unmaskedValue }) => {
      const newMax = parseFloat(unmaskedValue) || 0;
      const newMin = newMax < value.min ? newMax : value.min;
      onChange({ min: newMin, max: newMax });
    },
  });

  const renderRangeButtons = () =>
    rangerInstance
      .handles()
      .map(
        (
          {
            value: handleValue,
            onKeyDownHandler,
            onMouseDownHandler,
            onTouchStart,
            isActive,
          },
          index
        ) => {
          const buttonProps = {
            onTouchStart,
            onKeyDown: onKeyDownHandler,
            onMouseDown: onMouseDownHandler,
            'aria-valuemin': rangerInstance.options.min,
            'aria-valuemax': rangerInstance.options.max,
            'aria-valuenow': handleValue,
            type: 'button' as const,
            role: 'slider',
            key: index,
            className: 'filter-range__range-button',
            style: {
              zIndex: isActive ? 2 : 1,
              left: `${rangerInstance.getPercentageForValue(handleValue)}%`,
            },
          };

          return <button {...buttonProps} />;
        }
      );

  const renderRangeSegments = () =>
    rangerInstance.getSteps().map(({ left, width }, index) => {
      if (index !== 1) {
        return null;
      }

      const segmentProps = {
        key: index,
        className: 'filter-range__range-segment',
        style: {
          left: `${left}%`,
          width: `${width}%`,
        },
      };

      return <div {...segmentProps} />;
    });

  const attrs = {
    container: {
      className: 'filter-range',
    },
    rangeWrapper: {
      className: 'filter-range__range-wrapper',
    },
    range: {
      ref: rangerRef,
      className: 'filter-range__range',
    },
    inputsWrapper: {
      className: 'filter-range__inputs-wrapper',
    },
    minInput: {
      className: 'filter-range__input',
      type: 'text' as const,
      ref: minInputRef,
    },
    maxInput: {
      className: 'filter-range__input',
      type: 'text' as const,
      ref: maxInput,
    },
  };

  useEffect(() => {
    setMinInputUnmaskedValue(`${value.min}`);
    setMaxInputUnmaskedValue(`${value.max}`);
  }, [setMaxInputUnmaskedValue, setMinInputUnmaskedValue, value]);

  return (
    <div {...attrs.container}>
      <div {...attrs.rangeWrapper}>
        <div {...attrs.range}>
          {renderRangeButtons()}
          {renderRangeSegments()}
        </div>
      </div>
      <div {...attrs.inputsWrapper}>
        <input {...attrs.minInput} />
        <span>-</span>
        <input {...attrs.maxInput} />
      </div>
    </div>
  );
};
