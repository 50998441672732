export enum InvoiceType {
  MONTHLY = 'monthly',
  ANNUALY = 'annualy',
}

export enum InvoiceStatus {
  PAID = 'paid',
  OPEN = 'open',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
}

export interface SubscriptionInfo {
  price: number;
  numberOfSeats: number;
  planName: string;
  nextInvoiceDate: string;
  subscriptionActive: boolean;
}

export interface Invoice {
  invoiceId: string;
  date: string;
  type: InvoiceType;
  quantity: number;
  amount: number;
  status: InvoiceStatus;
  pdfLink?: string;
}

export interface BillingState {
  isLoading: {
    subscription: boolean;
    invoices: boolean;
  };
  isError: {
    subscription: boolean;
    invoices: boolean;
  };
  subscription: SubscriptionInfo | null;
  invoices: Invoice[];
  hasInvoicesNextPage: boolean;
}
