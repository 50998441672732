import React from 'react';
import { useOrgForm } from './useOrgForm';
import { Organization } from '../../../types';
import { Button, Input, Modal } from '../../../components';
import { locationIcon } from '../icons';

type EditOrganizationProps = {
  open: boolean;
  onClose: () => void;
  org?: Organization | null;
};

export const EditOrganizationLocation: React.FC<EditOrganizationProps> = ({
  onClose,
  open,
  org,
}: EditOrganizationProps): JSX.Element => {
  const {
    errors,
    handleCancel,
    handleChange,
    handleSubmit,
    touched,
    values,
    initialValues,
  } = useOrgForm({ onClose, org });

  return (
    <Modal title="Edit location" open={open} onClose={handleCancel}>
      <form onSubmit={handleSubmit}>
        <Input
          error={touched.location ? errors.location : ''}
          onChange={handleChange}
          value={values.location as string}
          name="location"
          label="Location"
          icon={locationIcon}
          className="edit-user__input"
        />
        <Button
          type="submit"
          className="edit-user__btn"
          variant="common"
          marginTop="20px"
          disabled={
            !values.location || initialValues.location === values.location
          }
        >
          Save
        </Button>
      </form>
    </Modal>
  );
};
