import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  isLoading?: boolean;
  children?: JSX.Element | string;
}

export const QCellSkeleton: React.FC<Props> = (props: Props) => {
  const { isLoading, children } = props;

  const attrs = {
    wrapper: {
      className: 'team-q-skeleton',
    },

    cell: {
      wrapper: {
        className: 'team-q-skeleton__cell-value',
      },

      skeleton: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: '100%',
            height: 10,
            borderRadius: 2,
            margin: 'auto',
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div {...attrs.wrapper}>
          <div {...attrs.cell.wrapper}>
            <Skeleton {...attrs.cell.skeleton} />
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
