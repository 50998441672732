import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { useAppSelector } from '../../../../../store/hooks';
import { AccountOption } from '../../../../../components/AssignAccountModal/components/AccountOption/AccountOption';
import { FormFieldType } from '../../../../../components/SoundWave/components/FormField/FormField';
import { Account } from '../../../../../types/account.types';
import { AddNewAccountButton } from '../components/AddNewAccountButton/AddNewAccountButton';
import { useAddBotModal } from './useAddBotModal';

interface SelectItem {
  key: string;
  label: string;
  value: string;
  avatar?: string;
}

interface FieldProps {
  accountId: {
    type: FormFieldType;
    withAutocomplete: boolean;
    label: string;
    value: string;
    placeholder: string;
    renderDropdownContent: () => JSX.Element;
    onChange: (_: string, value: string) => void;
    onInputChange?: (value: string) => void;
    options: SelectItem[];
  };
}

export const useAddBotModalForm = (): {
  fieldProps: FieldProps;
  accountInputValue: string;
  resetAccountInputValue: () => void;
} => {
  const {
    selectAccount,
    resetSelectedAccount,
    setFieldValue,
  } = useAddBotModal();

  const {
    accounts,
    selectedAccount,
    addAccount,
    isLoading,
    meetingUrlFieldError,
    isModalOpened,
    isSuccess,
  } = useAppSelector((state) => state.relatioshipFlows.addBot);

  const [inputValue, setInputValue] = useState('');

  const [accountsToShow, setAccountsToShow] = useState<Account[] | []>([]);

  useEffect(() => {
    if (accounts?.length && !addAccount?.isSuccess) {
      setAccountsToShow(accounts);
    }
  }, []);

  useEffect(() => {
    if (selectedAccount && selectedAccount?.name !== inputValue) {
      setInputValue(selectedAccount.name);
    }
  }, [selectedAccount]);

  const resetAccountInputValue = () => {
    setInputValue('');
  };

  const filterResults = useCallback(
    debounce((currentValue: string) => {
      const filteredAccounts = accounts.filter((account) =>
        account.name.toLowerCase().includes(currentValue.toLowerCase())
      );

      setAccountsToShow(filteredAccounts);
    }, 200),
    []
  );

  const updateValue = useCallback(
    (val: string) => {
      console.log('here', val);

      setInputValue(val);
      filterResults(val);
    }, // eslint-disable-next-line
    [filterResults, inputValue]
  );

  const resetAddBotValues = () => {
    setFieldValue('accountName', '');
    setFieldValue('accountWebsite', '');
  };

  const accountOptions = useMemo(
    () =>
      accountsToShow.map((account, index) => {
        const props = {
          key: index,
          name: account.name,
          logo: account.avatarSrc,
          search: inputValue,
        };

        return {
          value: account.accountId,
          label: account.name,
          dropdownLabel: <AccountOption {...props} />,
          isHidden: false,
          key: account.accountId,
        };
      }),
    [inputValue, selectedAccount, accountsToShow, accounts]
  );

  const fieldProps = useMemo(
    () => ({
      accountId: {
        className: 'add-bot__body-account-input',
        type: FormFieldType.SELECT,
        withAutocomplete: true,
        label: 'Account (optional)',
        value: inputValue,
        placeholder: 'Select Account',
        renderDropdownContent: () => {
          if (
            inputValue === 'Create a new Account' ||
            selectedAccount?.name === inputValue
          ) {
            return <></>;
          }

          return (
            <AddNewAccountButton
              selectCreateAccOption={() => {
                updateValue('Create a new Account');
                resetAddBotValues();
                resetSelectedAccount();
              }}
            />
          );
        },
        onChange: (_: string, value: string) => {
          const choosedAcc = accountsToShow.find(
            (accs) => accs.accountId === value
          );
          if (choosedAcc) {
            selectAccount(choosedAcc?.accountId || '');
          }
          setInputValue(choosedAcc?.name || '');
        },
        onInputChange: (value: string) => updateValue(value),
        options: accountOptions,
        disabled: inputValue === 'Create a new Account',
      },
    }),
    [
      selectedAccount?.accountId,
      accounts,
      accountOptions,
      inputValue,
      accountsToShow,
    ]
  );

  // console.log(inputValue, accountsToShow);

  return {
    fieldProps,
    accountInputValue: inputValue,
    resetAccountInputValue,
  };
};
