import React from 'react';
import { Measurements } from '../../../../store/analytics/analytics.types';
import { User } from '../../../../types';
import CircleProgressBar from '../circleBar/CircleProgressBar';
import { createInfoBarsArray } from '../data/analyticsData';
import { AnimatematedInfoBar } from '../infoBar/AnimatedInfoBar';
import InfobarsHeaderSkeleton from '../skeletons/InfobarsHeaderSkeleton';
import Summary from '../summary/Summary';

interface Props {
  user: User | null;
  isLoading: boolean;
  measurements: Measurements | null;
  noData: boolean;
}

export const SecondColumn: React.FC<Props> = ({
  user,
  measurements,
  noData,
  isLoading,
}): JSX.Element => {
  const animationDuration = (measurements?.overallCompetence as number) * 2.5;

  const infoBarsArray = createInfoBarsArray(
    measurements,
    animationDuration,
    isLoading
  );

  const attrs = {
    wrapper: {
      className: 'personalQ__secondColumn',
    },
    div: {
      infoBars: {
        className: 'personalQ__secondColumn-infobars',
      },
      competence: {
        className: 'personalQ__secondColumn-overallcompetence',
      },
      summary: {
        className: 'personalQ__secondColumn-overallcompetence-summary',
      },
    },
    span: {
      header: {
        className: 'personalQ__secondColumn-infobars-header',
      },
    },
    skeleton: {
      infobarsHeader: {
        duration: 2200,
        globalLoading: isLoading,
      },
    },
  };

  const animatedInfoBars = infoBarsArray?.map((el) => (
    <AnimatematedInfoBar
      key={el.toolTip}
      title={el.title}
      min={el.min}
      max={el.max}
      current={el.current || ''}
      tooltipMessage={el.toolTip}
      animationTime={el.animationTime}
      skelTitleWidth={el.sketWidth}
      isLoading={el.isLoading}
    />
  ));

  const circleProgressBar = (
    <CircleProgressBar
      measure={measurements?.overallCompetence || ''}
      animationTime={animationDuration}
      isLoading={isLoading}
      noData={noData}
      user={user as User}
    />
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.div.infoBars}>
        <InfobarsHeaderSkeleton {...attrs.skeleton.infobarsHeader}>
          <span {...attrs.span.header}>Estimated tendencies</span>
        </InfobarsHeaderSkeleton>
        {animatedInfoBars}
      </div>
      <div {...attrs.div.competence}>
        {circleProgressBar}
        <div {...attrs.div.summary}>
          <Summary />
        </div>
      </div>
    </div>
  );
};
