import moment from 'moment';
import React from 'react';
import { ArrPeople, Stats } from '../../../../store/analytics/analytics.types';
import { Organization, User } from '../../../../types';
import { createRelationsArray } from '../data/analyticsData';
import Relations from '../relations/Relations';
import RelationsNoData from '../relations/RelationsNoData';
import AnalyzeSkeleton from '../skeletons/AnalyzeSkeleton';
import PersonalInfoSkeleton from '../skeletons/PersonalInfoSkeleton';
import RelationsSkeleton from '../skeletons/RelationsSkeleton';
import { avgLifecycle, avgMessages, thrAnalyzed } from '../../icons';

interface Props {
  user: User | null;
  org: Organization | null;
  stats: Stats | null;
  isLoading: boolean;
}

export const FirstColumn: React.FC<Props> = ({
  user,
  org,
  stats,
  isLoading,
}): JSX.Element => {
  const relationsArray = createRelationsArray(stats, isLoading);

  const attrs = {
    wrapper: {
      className: 'personalQ__firstColumn',
    },
    skeleton: {
      personalInfo: {
        duration: 1200,
        globalLoading: isLoading,
      },
      analyze: {
        duration: 1500,
        globalLoading: isLoading,
      },
      relations: {
        durationText: 1900,
        globalLoading: isLoading,
      },
    },
    profile: {
      div: {
        profile: {
          className: 'personalQ__firstColumn-profile',
        },
        since: {
          className: 'personalQ__firstColumn-profile-since',
        },
      },
      span: {
        name: {
          className: 'personalQ__firstColumn-profile-name',
        },
        company: {
          className: 'personalQ__firstColumn-profile-company',
        },
        sinceText: {
          className: 'personalQ__firstColumn-profile-since-text',
        },
        sinceDate: {
          className: 'personalQ__firstColumn-profile-since-date',
        },
      },
      img: {
        className: 'personalQ__firstColumn-profile-companyLogo',
        src: org?.avatar,
      },
    },
    analyze: {
      wrapper: {
        className: 'personalQ__firstColumn-analyze',
      },
      div: {
        label: {
          className: 'personalQ__firstColumn-analyze-label',
        },
      },
      span: {
        value: {
          className: 'personalQ__firstColumn-analyze-label-value',
        },
      },
      img: {
        thrAnalyzed: {
          src: thrAnalyzed,
        },
        msg: {
          src: avgMessages,
        },
        life: {
          src: avgLifecycle,
        },
      },
    },
    relations: {
      wrapper: {
        className: 'personalQ__firstColumn-analyze-relations',
      },
      title: {
        className: 'personalQ__firstColumn-analyze-relations__title',
      },
    },
  };

  const organizationAvatar = org?.avatar ? (
    <img {...attrs.profile.img} alt="Logo" />
  ) : null;

  const memberSinse = moment(user?.createdAt).format('MMM D, YYYY');

  const threadsAnalyzedValue = Math.round(stats?.activeThreadsAnalyzed || 0);
  const messagesPerThreadValue = Math.round(stats?.avgMessagesPerThread || 0);
  const threadLifecycleValue = Math.round(stats?.avgThreadDurationInWeeks || 0);

  const relations =
    stats?.mostActiveParticipants &&
    stats?.mostCompetentVs &&
    stats?.leastCompetentVs ? (
      <>
        {relationsArray?.map((el) => (
          <Relations
            key={el.title}
            title={el.title}
            people={el.people as ArrPeople}
          />
        ))}
      </>
    ) : (
      <>
        <RelationsNoData title="Most active relationships" />
        <RelationsNoData title="Most competent with" />
        <RelationsNoData title="Least competent with" />
      </>
    );

  return (
    <div {...attrs.wrapper}>
      <PersonalInfoSkeleton {...attrs.skeleton.personalInfo}>
        <div {...attrs.profile.div.profile}>
          <span {...attrs.profile.span.name}>{user?.name}</span>
          <span {...attrs.profile.span.company}>
            {organizationAvatar} {org?.name}
          </span>
          <div {...attrs.profile.div.since}>
            <span {...attrs.profile.span.sinceText}>Member since:</span>{' '}
            <span {...attrs.profile.span.sinceDate}>{memberSinse}</span>
          </div>
        </div>
      </PersonalInfoSkeleton>
      <div {...attrs.analyze.wrapper}>
        <AnalyzeSkeleton {...attrs.skeleton.analyze}>
          <>
            <div {...attrs.analyze.div.label}>
              <img {...attrs.analyze.img.thrAnalyzed} alt="thred analyzed" />{' '}
              Active threads analyzed:
              <span {...attrs.analyze.span.value}>{threadsAnalyzedValue}</span>
            </div>
            <div {...attrs.analyze.div.label}>
              <img {...attrs.analyze.img.msg} alt="messages" />
              Avg. messages per thread:
              <span {...attrs.analyze.span.value}>
                {messagesPerThreadValue}
              </span>
            </div>
            <div {...attrs.analyze.div.label}>
              <img {...attrs.analyze.img.life} alt="life-cycle" />
              Avg. thread life-cycle (wks):
              <span {...attrs.analyze.span.value}>{threadLifecycleValue}</span>
            </div>
          </>
        </AnalyzeSkeleton>
        <div {...attrs.relations.wrapper}>
          <RelationsSkeleton {...attrs.skeleton.relations}>
            <>
              <div {...attrs.relations.title} />
              {relations}
            </>
          </RelationsSkeleton>
        </div>
      </div>
    </div>
  );
};
