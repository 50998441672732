import React from 'react';
import { Button, Input, Modal } from '../../../../components';
import { useRenameUser } from './useRenameUser';
import { user } from '../../icons';

type RenameUserProps = {
  open: boolean;
  onClose: () => void;
};

export const RenameUser: React.FC<RenameUserProps> = ({
  open,
  onClose,
}: RenameUserProps): JSX.Element => {
  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    handleCancel,
  } = useRenameUser(onClose);

  const attrs = {
    modal: {
      open,
      onClose: handleCancel,
      title: 'Rename user',
    },
    form: {
      onSubmit: handleSubmit,
    },
    firstNameInput: {
      label: 'First name',
      value: values.firstName,
      onChange: handleChange,
      name: 'firstName',
      icon: user,
      className: 'edit-user__input',
      error: touched.firstName ? errors.firstName : '',
    },
    lastNameInput: {
      label: 'Last name',
      value: values.lastName,
      onChange: handleChange,
      name: 'lastName',
      icon: user,
      className: 'edit-user__input',
      error: touched.lastName ? errors.lastName : '',
    },
  };

  return (
    <Modal {...attrs.modal}>
      <form {...attrs.form}>
        <Input {...attrs.firstNameInput} />
        <Input {...attrs.lastNameInput} />
        <Button type="submit" className="add-user__btn" variant="common">
          Rename
        </Button>
      </form>
    </Modal>
  );
};
