import React, { useEffect, useRef, useState } from 'react';
import { Duration } from 'luxon';
import { GraphComtWrapper } from './components/graphWrapper/GraphComtWrapper';
import { TeamQGraphRow } from './components/graphRow/TeamQGraphRow';
import { QSortArrow } from './components/sortArrow/QSortArrow';
import {
  formatNavigationCellValue,
  roundMaxGraphValue,
} from '../../utils/teamq.utils';

type SingleValue = {
  name: string;
  value: number;
  noData?: boolean;
  emails?: number;
};

type SellingZoneValue = {
  name: string;
  below: number;
  in: number;
  above: number;
  emails: number;
};

interface DataStatus {
  isLoading: boolean;
  error: boolean;
  noData: boolean;
}

type ArraySortCase = 'a-z' | 'z-a' | '1-9' | '9-1';

export type TeamQGraphXAxisVariant = 'basic' | 'percentage' | 'lowHigh';

export type TeamQAvgLineValueUnit =
  | 'week'
  | 'msg'
  | 'percentage'
  | 'empty'
  | 'meetings';

export type BarTooltipTextCase =
  | 'duration'
  | 'length'
  | 'analyzed'
  | 'simulated'
  | 'uh'
  | 'sellZone'
  | 'competence'
  | 'meetingCount'
  | 'meetingDuration'
  | 'talkTimeRatio';

type Props = {
  title: string;
  tooltipText: string;
  timePeriodText: string;
  basedOn?: number;
  graphValueSectionTitleName: string;
  barTooltipCase?: BarTooltipTextCase;
  data?: SingleValue[] | null;
  sellZoneData?: SellingZoneValue[] | null;
  wordValue?: boolean;
  percentageValue?: boolean;
  isTendency?: boolean;
  dataStatus?: DataStatus;
  maxValue?: number;
  xAxisVariantType?: TeamQGraphXAxisVariant;
  avgValue?: number;
  avgLineValueUnit?: TeamQAvgLineValueUnit;
  isThreadDuration?: boolean;
  isThreadLength?: boolean;
  isUpperHand?: boolean;
  isMeeting?: boolean;
  isDuration?: boolean;
};

export const TeamQGraph: React.FC<Props> = ({
  title,
  tooltipText,
  timePeriodText,
  basedOn,
  graphValueSectionTitleName,
  barTooltipCase,
  data,
  sellZoneData,
  wordValue = false,
  percentageValue = false,
  isTendency = false,
  dataStatus = {
    isLoading: false,
    noData: false,
    error: false,
  },
  maxValue,
  xAxisVariantType = 'basic',
  avgValue = 0,
  avgLineValueUnit = 'empty',
  isThreadDuration = false,
  isThreadLength = false,
  isUpperHand = false,
  isMeeting = false,
  isDuration = false,
}) => {
  const [localData, setLocalData] = useState<SingleValue[] | null>(null);

  const [sellZoneLocalData, setSellZoneLocalData] = useState<
    SellingZoneValue[] | null
  >(null);

  const [arrSortCase, setArrSortCase] = useState<ArraySortCase>('9-1');

  const [animation, setAnimation] = useState(false);

  const graphBodyRef = useRef<HTMLDivElement>(null);

  const { isLoading, noData, error } = dataStatus;

  useEffect(() => {
    if (data?.length) {
      setAnimation(true);

      const initialSortedData = [...data].sort((a, b) => b.value - a.value);

      setLocalData(initialSortedData);
    } else if (sellZoneData?.length) {
      setAnimation(true);

      const initialSortedData = [...sellZoneData].sort(
        (a, b) => b.in - a.in || b.above - a.above || b.below - a.below
      );

      setSellZoneLocalData(initialSortedData);
    } else {
      setLocalData(null);
      setSellZoneLocalData(null);
    }
  }, [data, sellZoneData]); // eslint-disable-line

  useEffect(() => {
    let animationTimer: NodeJS.Timeout;

    if (localData) {
      setAnimation(false);

      animationTimer = setTimeout(() => {
        if (arrSortCase === '9-1') {
          if (isThreadDuration) {
            const sortedArrWithoutNa = [
              ...localData.filter((el) => !el.noData),
            ].sort((a, b) => b.value - a.value);

            const filteredNa = [
              ...localData.filter((el) => el.noData),
            ].sort((a, b) =>
              a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1
            );

            const concatedArrs = sortedArrWithoutNa.concat(filteredNa);

            setLocalData(concatedArrs);
          } else {
            const sorted91 = [...localData].sort((a, b) => b.value - a.value);

            setLocalData(sorted91);
          }
        } else if (arrSortCase === '1-9') {
          if (isThreadDuration) {
            const sortedArrWithoutNa = [
              ...localData.filter((el) => !el.noData),
            ].sort((a, b) => a.value - b.value);

            const filteredNa = [
              ...localData.filter((el) => el.noData),
            ].sort((a, b) =>
              a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1
            );

            const concatedArrs = filteredNa.concat(sortedArrWithoutNa);

            setLocalData(concatedArrs);
          } else {
            const sorted19 = [...localData].sort((a, b) => a.value - b.value);

            setLocalData(sorted19);
          }
        } else if (arrSortCase === 'a-z') {
          const sortedAZ = [...localData].sort((a, b) =>
            a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1
          );

          setLocalData(sortedAZ);
        } else if (arrSortCase === 'z-a') {
          const sortedZA = [...localData].sort((a, b) =>
            b?.name.toLowerCase() > a?.name.toLowerCase() ? 1 : -1
          );

          setLocalData(sortedZA);
        }

        setAnimation(true);
      }, 500);
    } else if (sellZoneLocalData) {
      setAnimation(false);

      animationTimer = setTimeout(() => {
        if (arrSortCase === '9-1') {
          const sorted91 = [...sellZoneLocalData].sort((a, b) => b.in - a.in);

          setSellZoneLocalData(sorted91);
        } else if (arrSortCase === '1-9') {
          const sorted19 = [...sellZoneLocalData].sort((a, b) => a.in - b.in);

          setSellZoneLocalData(sorted19);
        } else if (arrSortCase === 'a-z') {
          const sortedAZ = [...sellZoneLocalData].sort((a, b) =>
            a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1
          );

          setSellZoneLocalData(sortedAZ);
        } else if (arrSortCase === 'z-a') {
          const sortedZA = [...sellZoneLocalData].sort((a, b) =>
            b?.name.toLowerCase() > a?.name.toLowerCase() ? 1 : -1
          );

          setSellZoneLocalData(sortedZA);
        }

        setAnimation(true);
      }, 500);
    }

    return () => {
      clearTimeout(animationTimer);
    };
  }, [arrSortCase]); // eslint-disable-line

  const mathSort = () => {
    if (localData || sellZoneLocalData) {
      if (arrSortCase === '9-1') {
        setArrSortCase('1-9');
      } else {
        setArrSortCase('9-1');
      }
    }
  };

  const alphabeticSort = () => {
    if (localData || sellZoneLocalData) {
      if (arrSortCase === 'a-z') {
        setArrSortCase('z-a');
      } else {
        setArrSortCase('a-z');
      }
    }
  };

  const attrs = {
    GraphWrapper: {
      title,
      isMeeting,
      timePeriodText: !isLoading && !isLoading && !error ? timePeriodText : '',
      basedOn: !isLoading && !isLoading && !error ? basedOn : null,
      tooltipText,
      isSellZone: sellZoneLocalData !== null && sellZoneLocalData?.length > 0,
      isThreadDurationOrLength: isThreadDuration || isThreadLength,
    },

    wrapper: {
      className: 'q-graph',
    },

    noData: {
      wrapper: {
        className: 'q-graph__no-data-wrap',
      },

      message: { className: 'q-graph__no-data-wrap-message' },
    },

    avgValue: {
      wrapper: {
        className: 'q-graph__avg',
      },

      movableSection: {
        wrapper: {
          className: 'q-graph__avg__movable',
        },

        value: {
          className: 'q-graph__avg__movable-value',
        },

        line: {
          className: 'q-graph__avg__movable-line',
        },
      },
    },

    header: {
      wrapper: {
        className: `${noData || error ? 'hidden' : ''} q-graph__header`,
      },

      member: {
        wrapper: {
          className: 'q-graph__header__member',
        },
        label: {
          className: 'q-graph__header__member-label',
        },
      },

      value: {
        wrapper: {
          className: 'q-graph__header__value',
        },
        label: {
          className: 'q-graph__header__value-label',
        },
      },

      sortSection: {
        alphaSortWrapper: {
          className: 'q-graph__header__sort-section',
          onClick: alphabeticSort,
        },

        mathSortWrapper: {
          className: 'q-graph__header__sort-section',
          onClick: mathSort,
        },

        arrow: {
          alphaFromStart: {
            className: 'q-graph__header__sort-section__icon',
          },
          alphaFromEnd: {
            className: 'q-graph__header__sort-section__icon',
          },
          valueFromHigh: {
            className: 'q-graph__header__sort-section__icon',
          },
          valueFromLow: {
            className: 'q-graph__header__sort-section__icon',
          },
        },
      },
    },

    body: {
      wrapper: {
        className: 'q-graph__body',
        ref: graphBodyRef,
      },
    },

    footer: {
      wrapper: {
        className: `${noData || error ? 'hidden' : ''} q-graph__footer`,
      },

      member: {
        className: 'q-graph__footer__member',
      },

      axis: {
        wrapper: {
          className: 'q-graph__footer__axis',
        },

        low: {
          className: 'q-graph__footer__axis-text-low',
        },

        high: {
          className: 'q-graph__footer__axis-text-high',
        },

        optimal: {
          className: 'q-graph__footer__axis-text-optimal',
        },

        lineSection: {
          wrapper: {
            className: 'q-graph__footer__axis__line-section',
          },

          range: {
            className: 'q-graph__footer__axis__line-section-range',
          },

          rangeLowHigh: {
            wrapper: {
              className: 'q-graph__footer__axis__line-section-range__low-high',
            },
          },

          subsection: {
            wrapper: {
              className: 'q-graph__footer__axis__line-section__subsection',
            },

            tick: {
              className: 'q-graph__footer__axis__line-section__subsection-tick',
            },

            value: {
              className:
                'q-graph__footer__axis__line-section__subsection-value',
            },
          },
        },
      },
    },
  };

  const sortAZ = (
    <div {...attrs.header.sortSection.arrow.alphaFromStart}>
      <QSortArrow active={arrSortCase === 'a-z'} reversed />
    </div>
  );

  const sortZA = (
    <div {...attrs.header.sortSection.arrow.alphaFromEnd}>
      <QSortArrow active={arrSortCase === 'z-a'} />
    </div>
  );

  const sort19 = (
    <div {...attrs.header.sortSection.arrow.valueFromLow}>
      <QSortArrow active={arrSortCase === '1-9'} />
    </div>
  );

  const sort91 = (
    <div {...attrs.header.sortSection.arrow.valueFromHigh}>
      <QSortArrow active={arrSortCase === '9-1'} reversed />
    </div>
  );

  const headerBody = (
    <>
      <div {...attrs.header.member.wrapper}>
        <div {...attrs.header.member.label}>Team member</div>
        <div {...attrs.header.sortSection.alphaSortWrapper}>
          {sortZA}
          {sortAZ}
        </div>
      </div>
      <div {...attrs.header.value.wrapper}>
        <div {...attrs.header.value.label}>{graphValueSectionTitleName}</div>
        <div {...attrs.header.sortSection.mathSortWrapper}>
          {sort19}
          {sort91}
        </div>
      </div>
    </>
  );

  const arrMaxSingleValue =
    (localData?.length && Math.max(...localData.map((el) => el.value))) || 0;

  const roundedValue = roundMaxGraphValue(arrMaxSingleValue);

  const createXAxis = () => {
    let axisToReturn = null;

    const createTick = (value: string | number) => {
      return (
        <div {...attrs.footer.axis.lineSection.subsection.wrapper}>
          <div {...attrs.footer.axis.lineSection.subsection.tick} />
          <div {...attrs.footer.axis.lineSection.subsection.value}>{value}</div>
        </div>
      );
    };

    if (xAxisVariantType === 'basic' && roundedValue) {
      const axisTicksArray = Array.from(new Array(5).keys()).map(
        (el, i) => (roundedValue * (i + 1)) / 5 || 0
      );

      axisToReturn = (
        <div {...attrs.footer.axis.wrapper}>
          <div {...attrs.footer.axis.lineSection.wrapper}>
            <div {...attrs.footer.axis.lineSection.range} />
            {createTick(0)}
            {axisTicksArray.map((el) => (
              <React.Fragment key={el}>{createTick(el)}</React.Fragment>
            ))}
          </div>
        </div>
      );
    } else if (xAxisVariantType === 'percentage') {
      axisToReturn = (
        <div {...attrs.footer.axis.wrapper}>
          <div {...attrs.footer.axis.lineSection.wrapper}>
            <div {...attrs.footer.axis.lineSection.range} />
            {createTick(0)}
            {createTick('20%')}
            {createTick('40%')}
            {createTick('60%')}
            {createTick('80%')}
            {createTick('100%')}
          </div>
        </div>
      );
    } else if (xAxisVariantType === 'lowHigh') {
      axisToReturn = (
        <div {...attrs.footer.axis.wrapper}>
          <div {...attrs.footer.axis.lineSection.wrapper}>
            <div {...attrs.footer.axis.lineSection.rangeLowHigh.wrapper} />
          </div>
        </div>
      );
    }

    return axisToReturn;
  };

  const xAxisLine = createXAxis();

  const footerBody = (
    <>
      <div {...attrs.footer.member} />
      {xAxisLine}
    </>
  );

  const createBarTooltip = (
    value?: number,
    emails?: number,
    sellZoneValues?: { below: number; in: number; above: number }
  ) => {
    const currValue = typeof value === 'number' ? value : 0;

    const currSellZoneValues = {
      below:
        typeof sellZoneValues?.below === 'number' ? sellZoneValues?.below : 0,
      in: typeof sellZoneValues?.in === 'number' ? sellZoneValues?.in : 0,
      above:
        typeof sellZoneValues?.above === 'number' ? sellZoneValues?.above : 0,
    };

    let singleBarTooltip = '';

    let sellZoneTooltips = { below: '', in: '', above: '' };

    switch (barTooltipCase) {
      case 'duration':
        singleBarTooltip = `Average of ${formatNavigationCellValue(
          value || 0
        )} ${value === 1 ? 'week' : 'weeks'} per thread out of ${emails} ${
          emails === 1 ? 'thread' : 'threads'
        }`;

        break;

      case 'length':
        singleBarTooltip = `Average of ${formatNavigationCellValue(
          value || 0
        )} ${
          value === 1 ? 'message' : 'messages'
        } per thread out of ${emails} ${emails === 1 ? 'thread' : 'threads'}`;

        break;

      case 'analyzed':
        singleBarTooltip = `Analyzed a total of ${formatNavigationCellValue(
          value || 0
        )} ${
          value === 1 ? 'message' : 'messages'
        } during the selected time period`;

        break;

      case 'simulated':
        singleBarTooltip = `Simulated a total of ${formatNavigationCellValue(
          value || 0
        )} ${
          value === 1 ? 'message' : 'messages'
        } during the selected time period`;

        break;

      case 'uh':
        singleBarTooltip = `${formatNavigationCellValue(
          currValue * 100 || 0
        )}% out of ${emails} ${emails === 1 ? 'message' : 'messages'}`;

        break;

      case 'sellZone':
        sellZoneTooltips = {
          below: `${formatNavigationCellValue(
            currSellZoneValues.below * 100 || 0
          )}% below the Authority Zone out of ${emails} ${
            emails === 1 ? 'message' : 'messages'
          }`,

          in: `${formatNavigationCellValue(
            currSellZoneValues.in * 100 || 0
          )}% in the Authority Zone out of ${emails} ${
            emails === 1 ? 'message' : 'messages'
          }`,

          above: `${formatNavigationCellValue(
            currSellZoneValues.above * 100 || 0
          )}% above the Authority Zone out of ${emails} ${
            emails === 1 ? 'message' : 'messages'
          }`,
        };

        break;

      case 'competence':
        singleBarTooltip = `${formatNavigationCellValue(
          currValue > 0 ? currValue * 100 : 0
        )}% out of ${emails} ${emails === 1 ? 'message' : 'messages'}`;

        break;
      case 'meetingCount':
        singleBarTooltip = `A total of ${currValue} online meeting${
          currValue === 1 ? '' : 's'
        } during the selected time period.`;
        break;
      case 'meetingDuration': {
        const { minutes, seconds } = Duration.fromObject({
          minutes: currValue,
        }).shiftTo('minutes', 'seconds');
        const showSeconds = !minutes;

        singleBarTooltip = `Average online meeting duration of ${
          showSeconds ? seconds.toFixed(0) : minutes
        } ${
          showSeconds
            ? `second${+seconds.toFixed(0) === 1 ? '' : 's'}`
            : `minute${minutes === 1 ? '' : 's'}`
        } during the selected time period.`;
        break;
      }
      case 'talkTimeRatio':
        singleBarTooltip = `Average talk-time ratio of online meetings ${formatNavigationCellValue(
          currValue > 0 ? currValue * 100 : 0
        )}% during the selected time period.`;
        break;
      default:
        singleBarTooltip = '';
    }

    return { singleBarTooltip, sellZoneTooltips };
  };

  const rowsToShow = sellZoneLocalData
    ? sellZoneLocalData.map((el, i) => (
        <React.Fragment key={`${el}${i}`}>
          <TeamQGraphRow
            name={el.name}
            invisible={!animation}
            wordValue={wordValue}
            variant="sellingZone"
            sellingZoneValues={{ below: el.below, in: el.in, above: el.above }}
            maxValue={maxValue}
            sellZoneTooltips={
              createBarTooltip(0, el.emails || 0, {
                below: el.below,
                in: el.in,
                above: el.above,
              }).sellZoneTooltips
            }
          />
        </React.Fragment>
      ))
    : localData?.map((el, i) => (
        <React.Fragment key={`${el}${i}`}>
          <TeamQGraphRow
            name={el.name}
            singleValue={el.value}
            noDataFlag={el?.noData}
            maxValue={roundedValue}
            invisible={!animation}
            wordValue={wordValue}
            percentageValue={percentageValue}
            singleBarTooltip={
              createBarTooltip(el.value, el.emails || 0).singleBarTooltip
            }
            variant={isTendency ? 'tendency' : 'basic'}
            isThreadDuration={isThreadDuration}
            isUpperHand={isUpperHand}
            isMeeting={isMeeting}
            isDuration={isDuration}
          />
        </React.Fragment>
      ));

  const defineAvgLinePositionAndVisibility = () => {
    let leftPosition = 0;

    let avgLDispay = 'flex';

    let avgLineHeigth = '100%';

    if (xAxisVariantType === 'basic' && avgValue && roundedValue) {
      leftPosition = (avgValue / roundedValue) * 100 - 0.2;
    } else if (xAxisVariantType === 'percentage') {
      leftPosition = avgValue - 0.2;
    } else if (xAxisVariantType === 'lowHigh' && localData?.length) {
      const filterZeroValue = localData.filter(
        (el) => el.value && el.value > 0
      );

      const sum =
        filterZeroValue.length &&
        filterZeroValue.reduce((accumulator, el) => {
          return accumulator + el.value;
        }, 0);

      const avgTendencyValue = sum / filterZeroValue.length;

      avgLineHeigth = '100%';

      leftPosition = (avgTendencyValue / 20) * 100;
    }

    if (leftPosition <= 0 || error || noData || isLoading) {
      avgLDispay = 'none';
    }

    return { leftPosition: `${leftPosition}%`, avgLDispay, avgLineHeigth };
  };

  const {
    leftPosition,
    avgLDispay,
    avgLineHeigth,
  } = defineAvgLinePositionAndVisibility();

  const defineAvgLineValueUnit = () => {
    let valueUnit = '';

    if (isDuration) {
      return ' hr';
    }

    if (avgValue) {
      if (avgLineValueUnit === 'msg') {
        valueUnit = avgValue === 1 ? ' Message' : ' Messages';
      } else if (avgLineValueUnit === 'week') {
        valueUnit = avgValue === 1 ? ' Week' : ' Weeks';
      } else if (avgLineValueUnit === 'percentage') {
        valueUnit = '%';
      } else if (avgLineValueUnit === 'meetings') {
        valueUnit = avgValue === 1 ? ' Meeting' : ' Meetings';
      }
    }

    return valueUnit;
  };

  const avgValueUnit = defineAvgLineValueUnit();

  const avgValueLine = (
    <div
      {...attrs.avgValue.wrapper}
      style={{ display: avgLDispay, height: avgLineHeigth }}
    >
      <div
        {...attrs.avgValue.movableSection.wrapper}
        style={{ transform: `translateX(${leftPosition})` }}
      >
        <div {...attrs.avgValue.movableSection.value}>
          Team Avg {formatNavigationCellValue(avgValue, isDuration)}
          {avgValueUnit}
        </div>
        <div {...attrs.avgValue.movableSection.line} />
      </div>
    </div>
  );

  const conditionalBodySection =
    noData || error ? (
      <div {...attrs.noData.wrapper}>
        <span {...attrs.noData.message}>Not enough data</span>
      </div>
    ) : (
      <>{rowsToShow}</>
    );

  return (
    <GraphComtWrapper {...attrs.GraphWrapper}>
      <div {...attrs.wrapper}>
        {isLoading ? null : (
          <>
            {avgValueLine}
            <div {...attrs.header.wrapper}>{headerBody}</div>
            <div {...attrs.body.wrapper}>{conditionalBodySection}</div>
            <div {...attrs.footer.wrapper}>{footerBody}</div>
          </>
        )}
      </div>
    </GraphComtWrapper>
  );
};
