import React, { useEffect, useState } from 'react';

type Values = {
  emailsCount: number;
  prospectsCount: number;
};

type Section = {
  negativeVs: Values;
  neutralVs: Values;
  positiveVs: Values;
};

export type CurrCase =
  | 'pos-pos'
  | 'pos-net'
  | 'pos-neg'
  | 'net-pos'
  | 'net-net'
  | 'net-neg'
  | 'neg-pos'
  | 'neg-net'
  | 'neg-neg';

type Props = {
  open?: boolean;
  currCase?: CurrCase;
  data: {
    positive: Section;
    neutral: Section;
    negative: Section;
  } | null;
};

type LocalData = {
  positive: Section;
  neutral: Section;
  negative: Section;
} | null;

type ResultValues = {
  totalEmails: number;
  prospects: number;
  currentEmails: number;
  currCaseSent: 'positive' | 'neutral' | 'negative';
  currCaseReceived: 'positive' | 'neutral' | 'negative';
  currClassName: string;
} | null;

export const SentimentTooltip: React.FC<Props> = ({
  data,
  open,
  currCase,
}): JSX.Element => {
  const [localData, setLocalData] = useState<LocalData>(null);

  const [resultValues, setResultValues] = useState<ResultValues>(null);

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
  }, [data]);

  useEffect(() => {
    if (open && currCase && localData) {
      const defineValues = () => {
        const { positive, neutral, negative } = localData;

        const {
          positiveVs: ppVs,
          neutralVs: pNlVs,
          negativeVs: pNgVs,
        } = positive;

        const {
          positiveVs: nlPVs,
          neutralVs: nlNlVs,
          negativeVs: nlNgVs,
        } = neutral;

        const {
          positiveVs: ngPVs,
          neutralVs: ngNlVs,
          negativeVs: ngNgVs,
        } = negative;

        const totalPos =
          ppVs.emailsCount + pNlVs.emailsCount + pNgVs.emailsCount;

        const totalNet =
          nlPVs.emailsCount + nlNlVs.emailsCount + nlNgVs.emailsCount;

        const totalNeg =
          ngPVs.emailsCount + ngNlVs.emailsCount + ngNgVs.emailsCount;

        const saveResult = (
          totalEms: number,
          currEmls: number,
          currentCaseSent: 'positive' | 'neutral' | 'negative',
          currentCaseReceived: 'positive' | 'neutral' | 'negative',
          currClass: string
        ) => {
          const prospectsInPercents = Math.round((currEmls / totalEms) * 100);

          const objToSave = {
            totalEmails: totalEms,
            prospects: prospectsInPercents,
            currentEmails: currEmls,
            currCaseSent: currentCaseSent,
            currCaseReceived: currentCaseReceived,
            currClassName: currClass,
          };

          if (objToSave) {
            setResultValues(objToSave);
          }
        };

        if (currCase === 'pos-pos') {
          saveResult(
            totalPos,
            ppVs.emailsCount,
            'positive',
            'positive',
            'sent-wrap__positive-wrap__neutral-open'
          );
        } else if (currCase === 'pos-net') {
          saveResult(
            totalPos,
            pNlVs.emailsCount,
            'positive',
            'neutral',
            'sent-wrap__positive-wrap__neutral-open'
          );
        } else if (currCase === 'pos-neg') {
          saveResult(
            totalPos,
            pNgVs.emailsCount,
            'positive',
            'negative',
            'sent-wrap__positive-wrap__negative-open'
          );
        } else if (currCase === 'net-pos') {
          saveResult(
            totalNet,
            nlPVs.emailsCount,
            'neutral',
            'positive',
            'sent-wrap__neutral-wrap__positive-open'
          );
        } else if (currCase === 'net-net') {
          saveResult(
            totalNet,
            nlNlVs.emailsCount,
            'neutral',
            'neutral',
            'sent-wrap__neutral-wrap__neutral-open'
          );
        } else if (currCase === 'net-neg') {
          saveResult(
            totalNet,
            nlNgVs.emailsCount,
            'neutral',
            'negative',
            'sent-wrap__neutral-wrap__negative-open'
          );
        } else if (currCase === 'neg-pos') {
          saveResult(
            totalNeg,
            ngPVs.emailsCount,
            'negative',
            'positive',
            'sent-wrap__negative-wrap__positive-open'
          );
        } else if (currCase === 'neg-net') {
          saveResult(
            totalNeg,
            ngNlVs.emailsCount,
            'negative',
            'neutral',
            'sent-wrap__negative-wrap__neutral-open'
          );
        } else if (currCase === 'neg-neg') {
          saveResult(
            totalNeg,
            ngNgVs.emailsCount,
            'negative',
            'negative',
            'sent-wrap__negative-wrap__negative-open'
          );
        }
      };
      defineValues();
    }
  }, [open, currCase, localData]);

  const totalEmailsRt = resultValues?.totalEmails;

  const prospectsRt = resultValues?.prospects;

  const sntmtSent = resultValues?.currCaseSent;

  const sntmtReceived = resultValues?.currCaseReceived;

  // const currEmailsRt = resultValues?.currentEmails;

  const definedWrapperClassName =
    resultValues?.currClassName || 'sentiment-hidden-tooltip';

  const defineTooltipBorderColor = () => {
    let border = '';

    if (sntmtReceived === 'negative') {
      border = '#365798';
    } else if (sntmtReceived === 'neutral') {
      border = '#4EB4FF';
    } else if (sntmtReceived === 'positive') {
      border = '#ACACB7';
    }

    return border;
  };

  const attrs = {
    wrapper: {
      className: definedWrapperClassName,
    },
    body: {
      className: 'sentiment-tooltip-body',
    },
    border: {
      className: 'sentiment-tooltip-body-left-border',
      style: {
        backgroundColor: defineTooltipBorderColor(),
      },
    },
    ul: {
      className: 'sentiment-tooltip-body__ul',
    },
    li: {
      className: 'sentiment-tooltip-body__ul__li',
    },
    bold: {
      className: 'sentiment-tooltip-body__ul__li-bold',
    },
    sentiment: {
      className: 'sentiment-tooltip-body__ul__li-place',
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.body}>
        <div {...attrs.border} />
        <div {...attrs.ul}>
          <div {...attrs.li}>
            When you conveyed a <span {...attrs.sentiment}>{sntmtSent}</span>{' '}
            sentiment (in {totalEmailsRt}{' '}
            {totalEmailsRt === 1 ? 'message' : 'messages'}), the prospects’
            response sentiment was{' '}
            <span {...attrs.sentiment}>{sntmtReceived}</span>{' '}
            <span {...attrs.bold}> {prospectsRt}%</span> of the time
          </div>
        </div>
      </div>
    </div>
  );
};
