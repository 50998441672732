import React from 'react';
import { TooltipIcon } from '../../../../../../components/Icons/TooltipIcon';
import {
  Tooltip,
  TooltipPosition,
} from '../../../../../../components/SoundWave';

type Props = {
  title: string;
  tooltipText: string;
  timePeriodText: string;
  basedOn?: number | null;
  children?: JSX.Element;
  isSellZone?: boolean;
  isThreadDurationOrLength?: boolean;
  isMeeting?: boolean;
};

export const GraphComtWrapper: React.FC<Props> = ({
  title,
  tooltipText,
  timePeriodText,
  basedOn,
  children,
  isSellZone = false,
  isThreadDurationOrLength = false,
  isMeeting = false,
}) => {
  const getBasedOnMessagesText = () => {
    if (typeof basedOn === 'number') {
      if (isMeeting) {
        return `Based on ${basedOn} meeting${basedOn === 1 ? '' : 's'}`;
      }

      return `Based on ${basedOn} ${
        basedOn === 1
          ? `${isThreadDurationOrLength ? 'thread' : 'message'}`
          : `${isThreadDurationOrLength ? 'threads' : 'messages'}`
      }`;
    }

    return '';
  };

  const attrs = {
    wrapper: {
      className: `graph-wrapper`,
    },

    title: {
      wrapper: {
        className: 'graph-wrapper__title',
      },

      label: {
        className: 'graph-wrapper__title-label',
      },

      tooltip: {
        wrapper: {
          className: 'graph-wrapper__title__tooltip',
        },

        icon: {
          className: 'graph-wrapper__title__tooltip-icon',
          active: false,
          isDarker: true,
        },
      },
    },

    subtitle: {
      wrapper: {
        className: 'graph-wrapper__subtitle',
      },

      sellZoneLegend: {
        wrapper: {
          className: 'graph-wrapper__subtitle__sz-legend',
        },

        below: {
          className: 'graph-wrapper__subtitle__sz-legend-below',
        },

        in: {
          className: 'graph-wrapper__subtitle__sz-legend-in',
        },

        above: {
          className: 'graph-wrapper__subtitle__sz-legend-above',
        },
      },
    },

    basedOn: {
      className: 'graph-wrapper-based-on',
    },

    body: {
      wrapper: {
        className: 'graph-wrapper__body',
      },
    },

    tooltip: {
      position: TooltipPosition.BOTTOM_START,
      width: 284,
      text: tooltipText,
      className: 'graph-wrapper__tooltip',
      positionOffset: 12,
    },
  };

  const sellingZoneLegend = isSellZone ? (
    <div {...attrs.subtitle.sellZoneLegend.wrapper}>
      <div {...attrs.subtitle.sellZoneLegend.below}>Below</div>
      <div {...attrs.subtitle.sellZoneLegend.in}>In</div>
      <div {...attrs.subtitle.sellZoneLegend.above}>Above</div>
    </div>
  ) : null;

  const basedOnMessagesText = getBasedOnMessagesText();

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.title.wrapper}>
        <div {...attrs.title.label}>{title}</div>
        <Tooltip {...attrs.tooltip}>
          <div {...attrs.title.tooltip.wrapper}>
            <TooltipIcon {...attrs.title.tooltip.icon} />
          </div>
        </Tooltip>
      </div>
      <div {...attrs.subtitle.wrapper}>
        {timePeriodText}
        {sellingZoneLegend}
      </div>
      <div {...attrs.basedOn}>{basedOnMessagesText}</div>
      <div {...attrs.body.wrapper}>{children}</div>
    </div>
  );
};
