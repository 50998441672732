import React from 'react';

export const CrossIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1866_98649)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18198 2.81821C9.37724 3.01347 9.37724 3.33006 9.18198 3.52532L6.70711 6.00019L9.18198 8.47507C9.37724 8.67033 9.37724 8.98691 9.18198 9.18217C8.98672 9.37743 8.67014 9.37743 8.47487 9.18217L6 6.7073L3.52513 9.18217C3.32986 9.37743 3.01328 9.37743 2.81802 9.18217C2.62276 8.98691 2.62276 8.67033 2.81802 8.47507L5.29289 6.00019L2.81802 3.52532C2.62276 3.33006 2.62276 3.01347 2.81802 2.81821C3.01328 2.62295 3.32986 2.62295 3.52513 2.81821L6 5.29308L8.47487 2.81821C8.67014 2.62295 8.98672 2.62295 9.18198 2.81821Z"
        fill="#070B12"
      />
    </g>
    <defs>
      <clipPath id="clip0_1866_98649">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
