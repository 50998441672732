import React from 'react';
import { MeetingTranscriptWithParticipantInfo } from '../../../../../../../../types/meetingDetails.types';
import { RelationshipMemberSide } from '../../../../../../../../types/relationshipMember.types';

export interface TurnsScaleProps {
  duration?: number;
  transcripts?: MeetingTranscriptWithParticipantInfo[];
  side?: RelationshipMemberSide;
  participantId?: string;
  scaleWidth?: number;
}

export const TurnsScale: React.FC<TurnsScaleProps> = ({
  duration = 0,
  transcripts,
  side,
  participantId,
  scaleWidth,
}) => {
  const attrs = {
    container: {
      className: `turns-scale${side ? ` turns-scale--${side}` : ''}`,
    },
    itemsWrapper: {
      className: 'turns-scale__items-wrapper',
      ...(typeof scaleWidth === 'number' && { style: { width: scaleWidth } }),
    },
  };

  const renderTurns = () =>
    transcripts?.map(({ startTimestamp, endTimestamp, participant }, index) => {
      const offset = (startTimestamp / duration) * 100;
      const turnDuration = endTimestamp - startTimestamp;
      const turnSide = participant?.side || '';

      const props = {
        key: index,
        className: `turns-scale__item turns-scale__item--${turnSide}${
          participantId && participantId !== participant?.participantId
            ? ' turns-scale__item--hidden'
            : ''
        }`,
        style: {
          left: `${offset}%`,
          width: `${(turnDuration / duration) * 100}%`,
        },
      };

      return <div {...props} />;
    });

  return (
    <div {...attrs.container}>
      <div {...attrs.itemsWrapper}>{renderTurns()}</div>
    </div>
  );
};
