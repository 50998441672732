import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberLink,
  RelationshipMemberSide,
} from '../../types/relationshipMember.types';
import { Contact } from '../../types/contact.types';

const getContactChanges = (
  contact: Contact,
  contactInSnapshot?: Contact
): RelationshipMemberLink | null => {
  if (!contactInSnapshot) {
    return null;
  }

  const isDifferentAssociationType =
    contact.extra?.currentAccountLink?.associationType !==
    contactInSnapshot.extra?.currentAccountLink?.associationType;
  const isDifferentContactSide =
    contact.extra?.currentAccountLink?.contactSide !==
    contactInSnapshot.extra?.currentAccountLink?.contactSide;
  const isDifferentActiveState =
    contact.extra?.currentAccountLink?.isActive !==
    contactInSnapshot.extra?.currentAccountLink?.isActive;

  if (
    !isDifferentAssociationType &&
    !isDifferentContactSide &&
    !isDifferentActiveState
  ) {
    return null;
  }

  return {
    ...(isDifferentAssociationType && {
      associationType: contact.extra?.currentAccountLink?.associationType,
    }),
    ...(isDifferentContactSide && {
      contactSide: contact.extra?.currentAccountLink?.contactSide,
    }),
    ...(isDifferentActiveState && {
      isActive: contact.extra?.currentAccountLink?.isActive,
    }),
  };
};

const getEditModeChanges = (
  contacts: Contact[],
  contactsSnapshot: Contact[]
) => {
  const changes: Array<{
    contact: Contact;
    changes: RelationshipMemberLink;
  }> = [];

  contacts.forEach((contact) => {
    const contactInSnapshot = contactsSnapshot.find(
      (item) => item.contactId === contact.contactId
    );

    const contactChanges = getContactChanges(contact, contactInSnapshot);

    if (!contactInSnapshot || !contactChanges) {
      return;
    }

    changes.push({
      contact: contactInSnapshot,
      changes: contactChanges,
    });
  });

  return changes;
};

const getIsChangeActiveStateDisabled = (
  contacts: Contact[],
  selectedContactIds: string[],
  isActivate: boolean
) => {
  const selectedContactIdsSet = new Set(selectedContactIds);
  const selectedContacts = contacts.filter(({ contactId }) =>
    selectedContactIdsSet.has(contactId)
  );

  return (
    !selectedContacts.length ||
    selectedContacts.every(
      ({ extra }) => extra?.currentAccountLink?.isActive === isActivate
    )
  );
};

const getIsSideOptionDisabled = (
  contacts: Contact[],
  selectedContactIds: string[],
  contactSide: RelationshipMemberSide,
  associationType?: RelationshipMemberAssociationType
) => {
  const selectedContactIdsSet = new Set(selectedContactIds);
  const selectedContacts = contacts.filter(({ contactId }) =>
    selectedContactIdsSet.has(contactId)
  );

  return selectedContacts.every(({ extra }) => {
    if (!associationType) {
      return extra?.currentAccountLink?.contactSide === contactSide;
    }

    return (
      extra?.currentAccountLink?.contactSide === contactSide &&
      extra?.currentAccountLink?.associationType === associationType
    );
  });
};

export const groupedContactsSelector = createDraftSafeSelector(
  (state: RootState) => state.relationshipDetails.contacts,
  (contacts) =>
    contacts.reduce<{
      prospectSideTeamMembers: Contact[];
      prospectSideThirdParties: Contact[];
      userSideTeamMembers: Contact[];
      userSideThirdParties: Contact[];
      unassigned: Contact[];
    }>(
      (acc, contact) => {
        if (
          contact?.extra?.currentAccountLink?.contactSide ===
          RelationshipMemberSide.PROSPECT_SIDE
        ) {
          if (
            contact?.extra?.currentAccountLink?.associationType ===
            RelationshipMemberAssociationType.TEAM_MEMBER
          ) {
            acc.prospectSideTeamMembers.push(contact);
          } else {
            acc.prospectSideThirdParties.push(contact);
          }

          return acc;
        }

        if (
          contact?.extra?.currentAccountLink?.contactSide ===
          RelationshipMemberSide.USER_SIDE
        ) {
          if (
            contact?.extra?.currentAccountLink?.associationType ===
            RelationshipMemberAssociationType.TEAM_MEMBER
          ) {
            acc.userSideTeamMembers.push(contact);
          } else {
            acc.userSideThirdParties.push(contact);
          }

          return acc;
        }

        acc.unassigned.push(contact);
        return acc;
      },
      {
        prospectSideTeamMembers: [],
        prospectSideThirdParties: [],
        userSideTeamMembers: [],
        userSideThirdParties: [],
        unassigned: [],
      }
    )
);

export const editModeChangesSelector = createDraftSafeSelector(
  (state: RootState) => state.relationshipDetails.contacts,
  (state: RootState) => state.relationshipDetails.contactsSnapshot,
  (contacts, contactsSnapshot) => {
    if (!contactsSnapshot) {
      return [];
    }

    return getEditModeChanges(contacts, contactsSnapshot);
  }
);

export const isActivateButtonDisabledSelector = createDraftSafeSelector(
  (state: RootState) => state.relationshipDetails.contacts,
  (state: RootState) => state.relationshipDetails.selectedContactIds,
  (contacts, selectedContactIds) =>
    getIsChangeActiveStateDisabled(contacts, selectedContactIds, true)
);

export const isDeactivateButtonDisabledSelector = createDraftSafeSelector(
  (state: RootState) => state.relationshipDetails.contacts,
  (state: RootState) => state.relationshipDetails.selectedContactIds,
  (contacts, selectedContactIds) =>
    getIsChangeActiveStateDisabled(contacts, selectedContactIds, false)
);

export const sideOptionDisabledFlagsSelector = createDraftSafeSelector(
  (state: RootState) => state.relationshipDetails.contacts,
  (state: RootState) => state.relationshipDetails.selectedContactIds,
  (contacts, selectedContactIds) => ({
    prospectSideTeamMember: getIsSideOptionDisabled(
      contacts,
      selectedContactIds,
      RelationshipMemberSide.PROSPECT_SIDE,
      RelationshipMemberAssociationType.TEAM_MEMBER
    ),
    prospectSideThirdParty: getIsSideOptionDisabled(
      contacts,
      selectedContactIds,
      RelationshipMemberSide.PROSPECT_SIDE,
      RelationshipMemberAssociationType.THIRD_PARTY
    ),
    userSideTeamMember: getIsSideOptionDisabled(
      contacts,
      selectedContactIds,
      RelationshipMemberSide.USER_SIDE,
      RelationshipMemberAssociationType.TEAM_MEMBER
    ),
    userSideThirdParty: getIsSideOptionDisabled(
      contacts,
      selectedContactIds,
      RelationshipMemberSide.USER_SIDE,
      RelationshipMemberAssociationType.THIRD_PARTY
    ),
    unassigned: getIsSideOptionDisabled(
      contacts,
      selectedContactIds,
      RelationshipMemberSide.UNASSIGNED
    ),
  })
);
