import React, { useEffect, useState } from 'react';
import { ReactComponent as FirstStep } from './assets/loader_1st-step-1.svg';
import { ReactComponent as SecondStep } from './assets/loader_2d-step.svg';
import { ReactComponent as ThirdStep } from './assets/loader_3d-step.svg';

export const Loader: React.FC = (): JSX.Element | null => {
  const [currentStep, setCurrentStep] = useState(0);

  const loop = [
    null,
    <React.Fragment key="first">
      <FirstStep />
    </React.Fragment>,
    <React.Fragment key="second">
      <SecondStep />
    </React.Fragment>,
    <React.Fragment key="third">
      <ThirdStep />
    </React.Fragment>,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prevStep) => (prevStep < 3 ? prevStep + 1 : 0));
    }, 700);
    return () => clearInterval(interval);
  });

  return loop[currentStep];
};
