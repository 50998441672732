import React from 'react';

export const CalendarIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M6 9.3335C6 9.70169 5.70152 10.0002 5.33333 10.0002C4.96514 10.0002 4.66667 9.70169 4.66667 9.3335C4.66667 8.96531 4.96514 8.66683 5.33333 8.66683C5.70152 8.66683 6 8.96531 6 9.3335Z"
      fill="#646F87"
    />
    <path
      d="M5.33333 12.6668C5.70152 12.6668 6 12.3684 6 12.0002C6 11.632 5.70152 11.3335 5.33333 11.3335C4.96514 11.3335 4.66667 11.632 4.66667 12.0002C4.66667 12.3684 4.96514 12.6668 5.33333 12.6668Z"
      fill="#646F87"
    />
    <path
      d="M8.66667 9.3335C8.66667 9.70169 8.36819 10.0002 8 10.0002C7.63181 10.0002 7.33333 9.70169 7.33333 9.3335C7.33333 8.96531 7.63181 8.66683 8 8.66683C8.36819 8.66683 8.66667 8.96531 8.66667 9.3335Z"
      fill="#646F87"
    />
    <path
      d="M8 12.6668C8.36819 12.6668 8.66667 12.3684 8.66667 12.0002C8.66667 11.632 8.36819 11.3335 8 11.3335C7.63181 11.3335 7.33333 11.632 7.33333 12.0002C7.33333 12.3684 7.63181 12.6668 8 12.6668Z"
      fill="#646F87"
    />
    <path
      d="M11.3333 9.3335C11.3333 9.70169 11.0349 10.0002 10.6667 10.0002C10.2985 10.0002 10 9.70169 10 9.3335C10 8.96531 10.2985 8.66683 10.6667 8.66683C11.0349 8.66683 11.3333 8.96531 11.3333 9.3335Z"
      fill="#646F87"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3333 2.00016C11.3333 1.63197 11.0349 1.3335 10.6667 1.3335C10.2985 1.3335 10 1.63197 10 2.00016V3.3335L6 3.3335V2.00016C6 1.63197 5.70152 1.3335 5.33333 1.3335C4.96514 1.3335 4.66667 1.63197 4.66667 2.00016V3.3335L4 3.3335C2.89543 3.3335 2 4.22893 2 5.3335V12.6668C2 13.7714 2.89543 14.6668 4 14.6668H12C13.1046 14.6668 14 13.7714 14 12.6668V5.3335C14 4.22893 13.1046 3.3335 12 3.3335H11.3333V2.00016ZM4 4.66683C3.63181 4.66683 3.33333 4.96531 3.33333 5.3335V6.50016H12.6667V5.3335C12.6667 4.96531 12.3682 4.66683 12 4.66683H4ZM12.6667 7.8335H3.33333V12.6668C3.33333 13.035 3.63181 13.3335 4 13.3335H12C12.3682 13.3335 12.6667 13.035 12.6667 12.6668V7.8335Z"
      fill="#646F87"
    />
  </svg>
);
