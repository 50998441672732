import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from '../../../../components';
import { Avatar } from '../../../../components/Avatar/Avatar';
import { ButtonVariantType } from '../../../../components/Button/Button';
import { updateUserRole } from '../../../../store/users/users.actions';
import { Role, User } from '../../../../types';
import { RoleSelect } from '../../../Teams/elements/roleSelect/RoleSelect';

type AssignRoleModalProps = {
  onClose: () => void;
  user: User | null;
  open: boolean;
};

type Roles = {
  value: string;
  label: string;
};

export const AssignRoleModal: React.FC<AssignRoleModalProps> = ({
  onClose,
  user,
  open,
}: AssignRoleModalProps): JSX.Element => {
  const [role, setRole] = useState<Role | null>(null);

  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'dealmaker', label: 'Dealmaker' },
  ];

  const dispatch = useDispatch();

  const onRoleChange = (roleToChange: Roles) => {
    setRole(roleToChange?.value as Role);
  };

  const handleSave = () => {
    if (user?.userId && user?.orgId && role) {
      dispatch(updateUserRole(role, user.orgId, user.userId));
    }
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setRole(null);
    }
  }, [open]);

  const attrs = {
    modal: {
      open,
      onClose,
      title: 'Assign a role',
    },
    avatar: {
      user,
      image: user?.avatar,
      className: 'options__name__avatar',
      inTable: true,
    },
    select: {
      items: roles,
      placeholder: user?.role === 'admin' ? roles[0].label : roles[1].label,
      onChange: onRoleChange,
    },
    saveButton: {
      onClick: handleSave,
      className: 'assign-role-modal__btns-btn',
      variant: 'common' as ButtonVariantType,
      disabled: user?.role === role || role === null,
    },
    div: {
      wrapper: {
        className: 'assign-role-modal',
      },
      name: {
        className: 'assign-role-modal__name',
      },
      button: {
        className: 'assign-role-modal__btns',
      },
    },
  };

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.div.wrapper}>
        <div {...attrs.div.name}>
          <Avatar {...attrs.avatar} />
          {user?.name}
        </div>
        <RoleSelect {...attrs.select} />
        <div {...attrs.div.button}>
          <Button {...attrs.saveButton}>Save</Button>
        </div>
      </div>
    </Modal>
  );
};
