import React, { useState, useEffect, useRef } from 'react';

interface Item {
  key: string;
  label: string;
  value: string;
}

interface InputProps {
  value: string;
  setValue: (value: string) => void;
  items: Item[];
  onClick?: () => void;
  onChange?: (newValue: Item) => void;
  placeholder?: string;
  icon?: string | null;
  error?: string | null;
  className?: string;
  disabled?: boolean;
}

export function InputAutocomplete(props: InputProps): JSX.Element {
  const {
    value,
    setValue,
    items,
    onClick,
    onChange,
    placeholder,
    icon,
    error,
    className = '',
    disabled,
  } = props;

  const [open, setOpen] = useState(false);

  const refToCatchClickOutside = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      const target = e.target as Node;
      if (open && !refToCatchClickOutside.current?.contains(target)) {
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    if (!value?.length) setOpen(false);
  }, [value]);

  const updateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
    if (!open) {
      setOpen(true);
    }
  };

  const updateValueFromOptions = (item: Item) => {
    setValue(item.value);
    if (onChange) onChange(item);
    if (open) setOpen(false);
  };

  const onInputTap = () => {
    if (!open && value?.length) {
      setOpen(true);
    }
  };

  const optIcon = icon ? (
    <img className="main-container__input-icon" src={icon} alt="Icon" />
  ) : null;

  const errorMessage = error ? (
    <span className="main-container__input-error">{error}</span>
  ) : null;

  const droppedItems = items
    .filter((el) => el.value.includes(value))
    .map((el) => (
      <div
        key={JSON.stringify(el)}
        className="drop-item"
        onClick={() => {
          updateValueFromOptions(el);
        }}
      >
        {el.value}
      </div>
    ));

  const conditionalClassName = () => {
    let currentClassName = 'main-container__input-control';
    if (open) currentClassName = 'open main-container__input-control';
    if (disabled) currentClassName = 'disabled main-container__input-control';
    if (error) currentClassName = 'error main-container__input-control';
    return currentClassName;
  };

  return (
    <div
      className={`main-container ${className}`}
      onClick={onClick}
      ref={refToCatchClickOutside}
    >
      <input
        value={value}
        onFocus={onInputTap}
        onChange={updateValue}
        placeholder={placeholder}
        className={conditionalClassName()}
        type="text"
      />
      {optIcon}
      {errorMessage}
      <div className="main-container__dropped-overflow-container">
        <div
          className={`main-container__dropped-block ${!open ? 'closed' : ''}`}
          style={!droppedItems.length ? { padding: 0 } : {}}
        >
          {droppedItems}
        </div>
      </div>
    </div>
  );
}
