import { PaginationSortOrder } from '../../../../../types/pagination.types';

const sortOrderMap = new Map<
  PaginationSortOrder | null,
  PaginationSortOrder | null
>([
  [null, PaginationSortOrder.DESC],
  [PaginationSortOrder.DESC, PaginationSortOrder.ASC],
  [PaginationSortOrder.ASC, null],
]);

export const getCurrentSorting = (
  newSortBy: string,
  currentSortBy: string | null,
  sortOrder: PaginationSortOrder | null
): { sortBy: string | null; sortOrder: PaginationSortOrder | null } => {
  if (currentSortBy === newSortBy) {
    const newSortOrder = sortOrderMap.get(sortOrder) || null;
    return {
      sortBy: newSortOrder ? newSortBy : null,
      sortOrder: newSortOrder,
    };
  }

  return {
    sortBy: newSortBy,
    sortOrder: PaginationSortOrder.DESC,
  };
};
