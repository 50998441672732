import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  ImageCropper,
  Modal,
  useFileUpload,
} from '../../../../components';
import { RootState } from '../../../../store/store';
import { updateUser } from '../../../../store/users/users.actions';
import Label from '../../../../components/Label/Label';
import { updateOrganization } from '../../../../store/org/org.actions';
import {
  locationIcon,
  orgEmailIcon,
  orgNameIcon,
  timezoneIcon,
  urlIcon,
} from '../../icons';
import { EditOrganizationName } from '../../editOrganizationModals/EditOrganizationName';
import { EditOrganizationUrl } from '../../editOrganizationModals/EditOrganizationUrl';
import { EditOrganizationLocation } from '../../editOrganizationModals/EditOrganizationLocation';
import { EditOrgTimeZone } from '../../editOrganizationModals/EdiOrgTimeZone';

export const OrgProfile: React.FC = (): JSX.Element => {
  const {
    handleUpload,
    file,
    removeFile,
    croppedImage,
    saveCroppedImage,
  } = useFileUpload();

  const { user } = useSelector((state: RootState) => state.auth);

  const { myOrg, isLoading, updateError, updateSuccess } = useSelector(
    (state: RootState) => state.org
  );

  const dispatch = useDispatch();

  const [isEditNameOpen, setIsEditNameOpen] = useState(false);

  const [isEditUrlOpen, setIsEditUrlOpen] = useState(false);

  const [isEditLocationOpen, setIsEditLocationOpen] = useState(false);

  const [isOpenEditTimeZone, setIsOpenEditTimeZone] = useState(false);

  useEffect(() => {
    if (croppedImage)
      dispatch(
        updateOrganization({ newAvatar: croppedImage }, user?.orgId || '', true)
      );
  }, [croppedImage, dispatch, user?.orgId]);

  const permitToChangeOrgInfo =
    (user &&
      (user.role === 'superAdmin' ||
        user.role === 'owner' ||
        user.role === 'admin')) ||
    false;

  useEffect(() => {
    if (croppedImage)
      dispatch(
        updateUser(
          { newAvatar: croppedImage },
          user?.orgId || '',
          user?.userId || ''
        )
      );
  }, [croppedImage, dispatch, user?.userId, user?.orgId]);

  const conditionToShowGMT: string = user?.timezone?.includes('GMT')
    ? user.timezone
    : `GMT${user?.timezone}`;

  const attrs = {
    wrapper: {
      className: 'org-profile',
    },

    avatarSection: {
      wrapper: {
        className: 'org-profile__avatar-section',
      },

      header: {
        wrapper: {
          className: 'org-profile__avatar-section__header',
        },

        title: {
          className: 'org-profile__avatar-section__header-title',
        },

        roleLabel: {
          className: 'org-profile__avatar-section__header-role',
        },
      },

      avatar: {
        wrapper: {
          className: 'org-profile__avatar-section__avatar',
        },

        Avatar: {
          org: myOrg,
          image: myOrg?.avatar,
          className: 'org-profile__avatar-section__avatar-image',
          onUpload: handleUpload,
          hasUpload: true,
        },
      },
    },

    editModals: {
      orgName: {
        org: myOrg,
        open: isEditNameOpen,
        onClose: () => setIsEditNameOpen(false),
        isLoading,
        updateSuccess,
        isConflictError: updateError,
      },

      orgUrl: {
        org: myOrg,
        open: isEditUrlOpen,
        onClose: () => setIsEditUrlOpen(false),
      },

      orgLocation: {
        org: myOrg,
        open: isEditLocationOpen,
        onClose: () => setIsEditLocationOpen(false),
      },

      orgTimeZone: {
        org: myOrg,
        open: isOpenEditTimeZone,
        onClose: () => setIsOpenEditTimeZone(false),
      },

      modal: {
        open: !!file,
        onClose: removeFile,
        title: 'Upload a photo',
        height: '570px',
      },

      imageCropper: {
        onSave: saveCroppedImage,
        onCancel: removeFile,
        image: file || '',
      },

      avatar: {
        org: myOrg,
        image: myOrg?.avatar,
        onUpload: handleUpload,
        hasUpload: true,
      },
    },

    body: {
      wrapper: {
        className: 'my-profile__body',
      },

      rows: {
        Name: {
          title: 'Name',
          value: myOrg?.name,
          onClick: () => setIsEditNameOpen(true),
          disabled: !permitToChangeOrgInfo,
          image: orgNameIcon,
          isLowerHeight: true,
        },

        Location: {
          title: 'Location',
          value: myOrg?.location || '',
          onClick: () => setIsEditLocationOpen(true),
          disabled: !permitToChangeOrgInfo,
          image: locationIcon,
          isLowerHeight: true,
        },

        Email: {
          title: 'Primary Email',
          value: myOrg?.primaryEmail,
          disabled: true,
          image: orgEmailIcon,
          hasLock: true,
          isLowerHeight: true,
        },

        Url: {
          title: 'URL',
          value: myOrg?.url,
          onClick: () => setIsEditUrlOpen(true),
          disabled: !permitToChangeOrgInfo,
          image: urlIcon,
          isLowerHeight: true,
        },

        TimeZone: {
          title: 'Time zone',
          value: conditionToShowGMT,
          onClick: () => setIsOpenEditTimeZone(true),
          image: timezoneIcon,
          isLowerHeight: true,
        },
      },
    },
  };

  const editModals = (
    <>
      <EditOrganizationName {...attrs.editModals.orgName} />
      <EditOrganizationUrl {...attrs.editModals.orgUrl} />
      <EditOrganizationLocation {...attrs.editModals.orgLocation} />
      <EditOrgTimeZone {...attrs.editModals.orgTimeZone} />
      <Modal {...attrs.editModals.modal}>
        <ImageCropper {...attrs.editModals.imageCropper} />
      </Modal>
    </>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.avatarSection.wrapper}>
        <div {...attrs.avatarSection.header.wrapper}>
          <div {...attrs.avatarSection.header.title}>Organization</div>
        </div>
        <div {...attrs.avatarSection.avatar.wrapper}>
          <Avatar {...attrs.avatarSection.avatar.Avatar} />
        </div>
      </div>
      <div {...attrs.body.wrapper}>
        <Label {...attrs.body.rows.Name} />
        <Label {...attrs.body.rows.Location} />
        <Label {...attrs.body.rows.Email} />
        <Label {...attrs.body.rows.Url} />
        <Label {...attrs.body.rows.TimeZone} />
      </div>
      {editModals}
    </div>
  );
};
