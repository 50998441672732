import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import {
  ButtonSize,
  ConfirmationModalProps,
  FormFieldProps,
  FormProps,
  ModalProps,
} from '../../../../../components/SoundWave';
import { FormFieldType } from '../../../../../components/SoundWave/components/FormField/FormField';
import { useId } from '../../../../../hooks';
import { sendContactRequest } from '../../../../../store/global/global.thunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

export interface UpgradeFormValues {
  note: string;
}

const validationSchema = Yup.object().shape({
  note: Yup.string().required(),
});

export const useUpgradeModal = ({
  feature,
  onClose,
}: {
  feature: string | null;
  onClose: () => void;
}): {
  modalProps: ModalProps;
  formProps: FormProps;
  fieldProps: Record<keyof UpgradeFormValues, FormFieldProps>;
  confirmationModalProps: ConfirmationModalProps;
} => {
  const dispatch = useAppDispatch();

  const isContactRequestLoading = useAppSelector(
    (state) => state.global.isLoading.contactRequest
  );

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(
    false
  );

  const [initialValues, setInitialValues] = useState<UpgradeFormValues>({
    note: '',
  });

  const formId = useId();

  const {
    dirty,
    touched,
    values,
    isValid,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik<UpgradeFormValues>({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (formValues) => {
      try {
        await dispatch(
          sendContactRequest({
            ...formValues,
            feature,
          })
        ).unwrap();

        onClose();
        setIsConfirmationModalOpened(true);
      } catch (error) {
        onClose();
      }
    },
  });

  const handleChange = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<any, Element>) => {
      setFieldError(e.target.name, '');
      setFieldTouched(e.target.name, false);
    },
    [setFieldError, setFieldTouched]
  );

  const modalProps = useMemo(
    () => ({
      onClose,
      isOpened: !!feature,
      width: 384,
      closeOnClickOutside: true,
      isConfirmation: true,
      className: 'apps__modal',
      onAnimationEnd: () => {
        setInitialValues(() => ({ note: '' }));
      },
      headerProps: {},
      footerProps: {
        formId,
        isLoading: isContactRequestLoading,
        confirmButtonText: 'Submit',
        buttonsSize: ButtonSize.XS,
        isCancelButtonHidden: true,
        isConfirmButtonSecondary: true,
        isConfirmButtonDisabled: !isValid || !dirty,
      },
    }),
    [dirty, feature, formId, isContactRequestLoading, isValid, onClose]
  );

  const confirmationModalProps = useMemo(
    () => ({
      isOpened: isConfirmationModalOpened,
      title: 'Thank you for your request.',
      description: 'Our team will review it and follow up shortly.',
      showHeader: true,
      buttonProps: {
        text: 'Close',
        isCloseButton: true,
      },
      onClose: () => {
        setIsConfirmationModalOpened(false);
      },
    }),
    [isConfirmationModalOpened]
  );

  const formProps = useMemo(
    () => ({
      id: formId,
      onSubmit: handleSubmit,
    }),
    [formId, handleSubmit]
  );

  const fieldProps = useMemo(
    () => ({
      note: {
        type: FormFieldType.TEXTAREA,
        name: 'note',
        placeholder: 'Could you please tell us more about your request',
        value: values.note,
        touched: touched.note,
        className: 'apps__modal-textarea',
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
    }),
    [handleBlur, handleChange, handleFocus, touched, values]
  );

  useEffect(() => {
    resetForm({ values: initialValues, errors: {}, touched: {} });
  }, [initialValues, resetForm]);

  return {
    modalProps,
    formProps,
    fieldProps,
    confirmationModalProps,
  };
};
