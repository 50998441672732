import { Organization } from '../../../../../../../types';
import { Account } from '../../../../../../../types/account.types';
import { Contact } from '../../../../../../../types/contact.types';
import {
  RelationshipMemberAssociationType,
  RelationshipMemberLink,
  RelationshipMemberSide,
} from '../../../../../../../types/relationshipMember.types';

export const getChangesTextDescription = (
  contact: Contact,
  changes: RelationshipMemberLink,
  prospectAccount: Account | null,
  userOrganization: Organization | null
): string => {
  const {
    isActive,
    contactSide: contactSideChanges,
    associationType: associationTypeChanges,
  } = changes;
  const contactSide =
    contactSideChanges || contact.extra?.currentAccountLink?.contactSide;

  const associationType =
    associationTypeChanges ||
    contact.extra?.currentAccountLink?.associationType ||
    RelationshipMemberAssociationType.TEAM_MEMBER;

  const associationTypeText =
    associationType === RelationshipMemberAssociationType.TEAM_MEMBER
      ? 'team participant'
      : '3rd party';

  const sideChangeText =
    contactSide === RelationshipMemberSide.UNASSIGNED
      ? 'Changed to unassigned'
      : `Changed to ${
          contactSide === RelationshipMemberSide.PROSPECT_SIDE
            ? prospectAccount?.name || 'Prospect'
            : userOrganization?.name || 'User'
        } ${associationTypeText}`;

  const activeStatusChangeText = isActive
    ? 'Mark as active'
    : 'Mark as inactive';

  return [
    typeof isActive === 'boolean' ? activeStatusChangeText : '',
    contactSideChanges || associationTypeChanges ? sideChangeText : '',
  ]
    .filter((str) => !!str)
    .join(', ');
};
