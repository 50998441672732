import { Skeleton } from 'antd';
import React from 'react';
import { EventSummaryEmpty } from '../EventSummaryEmpty/EventSummaryEmpty';

export interface EventSummaryNextMoveProps {
  emptyText?: string;
  isLoading?: boolean;
  text?: string;
}

export const EventSummaryNextMove: React.FC<EventSummaryNextMoveProps> = ({
  emptyText,
  isLoading = false,
  text,
}) => {
  const attrs = {
    eventSummaryEmpty: {
      text: emptyText,
    },
    textSkeleton: {
      active: true,
      title: false,
      paragraph: {
        rows: 3,
        width: '100%',
        style: {
          margin: 0,
        },
      },
    },
  };

  if (isLoading) {
    return <Skeleton {...attrs.textSkeleton} />;
  }

  if (!text) {
    return <EventSummaryEmpty {...attrs.eventSummaryEmpty} />;
  }

  return <>{text}</>;
};
