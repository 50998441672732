import React, { useEffect } from 'react';
import { useOrgForm } from './useOrgForm';
import { Organization } from '../../../types';
import { Button, Input, Modal } from '../../../components';
import { orgNameIcon } from '../icons';

type EditOrganizationProps = {
  open: boolean;
  onClose: () => void;
  org?: Organization | null;
  isLoading: boolean;
  isConflictError: boolean;
  updateSuccess: boolean;
};

export const EditOrganizationName: React.FC<EditOrganizationProps> = ({
  onClose,
  open,
  org,
  isLoading,
  isConflictError,
  updateSuccess,
}: EditOrganizationProps): JSX.Element => {
  const {
    errors,
    handleCancel,
    handleChange,
    handleSubmit,
    touched,
    values,
    initialValues,
  } = useOrgForm({ onClose, org, isNameForm: true });

  useEffect(() => {
    if (!isLoading && !isConflictError && updateSuccess) {
      onClose();
    }
    // eslint-disable-next-line
  }, [isLoading, isConflictError, updateSuccess]);

  const createError = () => {
    let message;
    if (touched.name) message = errors.name;
    if (isConflictError) {
      message = 'An organization with this name already exists';
    }
    return message;
  };

  return (
    <Modal title="Edit Organization Name" open={open} onClose={handleCancel}>
      <form onSubmit={handleSubmit}>
        <Input
          error={createError()}
          onChange={handleChange}
          value={values.name as string}
          name="name"
          label="Organization Name"
          icon={orgNameIcon}
          isUpdateOrg
          className="edit-user__input"
        />
        <Button
          type="submit"
          variant="common"
          marginTop="20px"
          className="edit-user__btn"
          disabled={
            !values.name || initialValues.name === values.name || isLoading
          }
        >
          Save
        </Button>
      </form>
    </Modal>
  );
};
