import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import GetColorAndMessage from '../infoBar/getColorAndMessage';
import SummarySkeleton from '../skeletons/SummarySkeleton';

const Summary: React.FC = (): JSX.Element => {
  const [summaryArr, setSummaryArr] = useState<string[]>();

  const mess = useSelector((state: RootState) => state.analytics.measurements);
  const isLoading = useSelector(
    (state: RootState) => state.analytics.isLoading
  );

  const measurements = useCallback( // eslint-disable-line
    useSelector((state: any) => { // eslint-disable-line
      const measurement = { ...state.analytics.measurements };
      if (measurement) {
        if (measurement.resilience >= measurement.intuition) {
          delete measurement.intuition;
          delete measurement.subtleness;
        } else if (measurement.resilience < measurement.intuition) {
          delete measurement.resilience;
          delete measurement.subtleness;
        }
      }
      return measurement;
    }),
    [mess]
  );

  useEffect(() => {
    const arrResult: string[] = [];
    if (measurements) {
      const measureCopy = { ...measurements };
      const keys = Object.keys(measureCopy);
      keys.forEach((el) => {
        if (measureCopy[el]) {
          const { message } = GetColorAndMessage(measureCopy[el], el);
          arrResult.push(message);
        }
      });
      setSummaryArr(arrResult);
    }
  }, [measurements]);

  return (
    <>
      <SummarySkeleton duration={3000} globalLoading={isLoading}>
        <>
          <span className="personalQ__secondColumn-overallcompetence-summary-title">
            SUMMARY
          </span>
          <ul className="personalQ__secondColumn-overallcompetence-summary-list">
            {summaryArr?.length ? (
              summaryArr.map((el) =>
                el ? (
                  <li
                    key={el}
                    className="personalQ__secondColumn-overallcompetence-summary-list-item"
                  >
                    {el}
                  </li>
                ) : null
              )
            ) : (
              <span className="personalQ__secondColumn-overallcompetence-summary-list-noData">
                Not enough data
              </span>
            )}
          </ul>{' '}
        </>
      </SummarySkeleton>
    </>
  );
};

export default Summary;
