import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';

type TableRowNameProps = {
  userId: string;
};

export const TableRowName: React.FC<TableRowNameProps> = ({
  userId,
}: TableRowNameProps): JSX.Element => {
  const teams = useSelector((state: RootState) => state.teams.teams);
  const { name } = useSelector((state: RootState) => state.users.users).find(
    (user) => user.userId === userId
  ) || { role: 'dealmaker', name: '' };

  const member = teams
    ?.find((el) => el.members.map((er) => er.userId === userId))
    ?.members.find((m) => m.userId === userId);

  return (
    <div className="teams-options__member-name">{member?.name || name}</div>
  );
};
