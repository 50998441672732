import React, { useEffect, useState } from 'react';
import { Button } from '../../../../components';
import {
  ButtonType,
  ButtonVariantType,
} from '../../../../components/Button/Button';
import { InputAutocomplete } from '../../../../components/InputAutocomplete/InputAutocomplete';
import { RoleSelect } from '../../elements/roleSelect/RoleSelect';
import { email, userIcon } from '../../icons';
import { Member, useAddTeamForm } from '../hooks/useAddTeamForm';

export type EditMembersCaseType = 'adding' | 'editing' | 'editingFromTable';

type EditMembersProps = {
  onClose: () => void;
  arrayOfMembers: Member[];
  setArrayOfMembers: React.Dispatch<Member[]>;
  currentCase: EditMembersCaseType;
};

type UsersForAutocomplete = {
  key: string;
  label: string;
  value: string;
};

export const EditMembers: React.FC<EditMembersProps> = ({
  onClose,
  arrayOfMembers,
  setArrayOfMembers,
  currentCase,
}: EditMembersProps): JSX.Element => {
  const { users } = useAddTeamForm(onClose, '');

  const [usersForAutocomplete, setUsersForAutocomplete] = useState<
    UsersForAutocomplete[] | null
  >(null);
  const [memberId, setmemberId] = useState('');
  const [memberRole, setMemberRole] = useState<
    'teamLeader' | 'member' | 'remove'
  >('member');
  const [memberName, setMemberName] = useState('');
  const [memberEmail, setMemberEmail] = useState('');
  const [currentMember, setCurrentMember] = useState<Member>();
  const [autocompleteValue, setAutocompleteValue] = useState('');

  useEffect(() => {
    const mappedUsers = users
      .filter((el) => el.inviteStatus === 'accepted')
      .map((el) => ({
        key: el.userId,
        label: el.name,
        value: el.email,
      }));
    setUsersForAutocomplete(mappedUsers);
  }, [users.length]); // eslint-disable-line

  useEffect(() => {
    if (
      autocompleteValue.length &&
      (memberEmail || memberEmail || memberName) &&
      !usersForAutocomplete?.find((el) => el.value === autocompleteValue)
    ) {
      setMemberEmail('');
      setmemberId('');
      setMemberName('');
    }
  }, [autocompleteValue]); // eslint-disable-line

  const roles = [
    { value: 'teamLeader', label: 'Team leader' },
    { value: 'member', label: 'Member' },
    { value: 'remove', label: 'Remove' },
  ];

  // eslint-disable-next-line
  const onChangeRoleCurrentMember = (role: any) => {
    const { value } = role;
    let result;
    if (currentMember && arrayOfMembers?.length) {
      setCurrentMember({ ...currentMember, teamMemberRole: value });
      if (value === 'teamLeader') {
        result = arrayOfMembers.map((el) => {
          if (el.teamMemberRole === 'teamLeader') {
            return { ...el, teamMemberRole: 'member' };
          }
          if (el.userId === currentMember.userId) {
            return { ...el, teamMemberRole: value };
          }
          return el;
        });
      } else if (value === 'remove' && currentCase === 'adding') {
        result = arrayOfMembers.filter(
          (el) => el.userId !== currentMember.userId
        );
      } else if (value === 'remove' && currentCase !== 'adding') {
        result = arrayOfMembers.map((el) => {
          if (currentMember.userId === el.userId) {
            return { ...el, isDeleted: true };
          }
          return el;
        });
      } else {
        result = arrayOfMembers.map((el) => {
          if (el.userId === currentMember.userId) {
            return { ...el, teamMemberRole: value };
          }
          return el;
        });
      }
      setArrayOfMembers(result);
      setMemberRole('member');
    }
  };

  const onChangeMember = (arr: UsersForAutocomplete) => {
    if (arr) {
      setmemberId(arr.key);
      setMemberName(arr.label);
      setMemberEmail(arr.value);
    }
  };
  // eslint-disable-next-line
  const onChangeMemberRole = (arr: any) => {
    if (arr) {
      setMemberRole(arr.value);
    }
  };

  const addMemberToTheTeam = () => {
    if (memberId && memberRole && arrayOfMembers) {
      setArrayOfMembers([
        ...arrayOfMembers,
        {
          userId: memberId,
          teamMemberRole: memberRole,
          name: memberName,
          email: memberEmail,
        },
      ]);
      if (memberRole !== 'member') {
        setMemberRole('member');
      }
      setMemberEmail('');
      setmemberId('');
      setMemberName('');
      setAutocompleteValue('');
    } else if (
      (!memberId || !memberName || !memberEmail) &&
      usersForAutocomplete
    ) {
      const memberToAdd = usersForAutocomplete.find(
        (el) => el.value === autocompleteValue
      );
      const checkDuplicate =
        currentCase !== 'editing'
          ? arrayOfMembers.some((el) => el.userId === memberToAdd?.key)
          : arrayOfMembers.some(
              (el) => el.userId === memberToAdd?.key && !el.isDeleted
            );

      if (memberToAdd && !checkDuplicate) {
        setArrayOfMembers([
          ...arrayOfMembers,
          {
            userId: memberToAdd.key,
            teamMemberRole: memberRole,
            name: memberToAdd.label,
            email: memberToAdd.value,
          },
        ]);
        if (memberRole !== 'member') {
          setMemberRole('member');
        }
        setMemberEmail('');
        setmemberId('');
        setMemberName('');
        setAutocompleteValue('');
      }
    }
  };

  const rolesToSelect = () => {
    if (
      arrayOfMembers.length &&
      arrayOfMembers.some((el) => el.teamMemberRole === 'teamLeader')
    ) {
      return [roles[1]];
    }
    return roles.filter((el) => el.value !== 'remove');
  };

  const memberRoleSelectWrapperClassName = (member: Member) => {
    return currentMember?.userId === member.userId
      ? 'teams-add__members-members__block-member-select active '
      : 'teams-add__members-members__block-member-select';
  };

  const disableDuplicateMember =
    currentCase !== 'editing'
      ? arrayOfMembers.some(
          (el) => el.userId === memberId || el.email === autocompleteValue
        )
      : arrayOfMembers.some((el) => el.userId === memberId && !el.isDeleted);

  const memberExistenceCheck =
    usersForAutocomplete?.find((el) => el.value === autocompleteValue) &&
    !disableDuplicateMember;

  const attrs = {
    addMemberSection: {
      wrapper: {
        className: 'teams-add__members',
      },

      autocomplete: {
        value: autocompleteValue,
        setValue: setAutocompleteValue,
        onChange: onChangeMember,
        items: usersForAutocomplete || [],
        icon: email,
        className: 'teams-add__members-autocomplete',
        placeholder: 'Enter user’s email',
      },

      roleSelectWrapper: {
        className: 'teams-add__members-select',
      },

      roleSelect: {
        onChange: onChangeMemberRole,
        items: rolesToSelect(),
        selectedItem: memberRole === 'member' ? roles[1] : roles[0],
      },

      button: {
        className: 'teams-add__members-button',
        variant: 'common' as ButtonVariantType,
        onClick: addMemberToTheTeam,
        disabled: disableDuplicateMember || !memberExistenceCheck,
      },
    },

    members: {
      wrapper: {
        className: 'teams-add__members-members__block',
      },

      userImg: {
        className: 'teams-add__members-members__block-user__icon',
        src: userIcon,
      },

      roleSelectWrapper: (member: Member) => ({
        key: member.userId,
        className: 'teams-add__members-members__block-member',
      }),

      roleSelect: (member: Member) => ({
        onClick: () => setCurrentMember(member),
        onChange: onChangeRoleCurrentMember,
        items:
          member.teamMemberRole === 'teamLeader'
            ? roles.filter((el) => el.value === 'member')
            : roles,
        selectedItem:
          member.teamMemberRole === 'teamLeader' ? roles[0] : roles[1],
        isTeamLeader: member.teamMemberRole === 'teamLeader',
      }),
    },

    submitButton: {
      type: 'submit' as ButtonType,
      className: 'create-new-team__btn',
      variant: 'common' as ButtonVariantType,
      // disabled: noTeamLeadError,
    },
  };

  const addMemberButtonText = currentCase === 'adding' ? 'Add member' : 'Add';

  const addedMembers =
    arrayOfMembers.length > 0 &&
    arrayOfMembers
      .filter((el) => !el.isDeleted)
      .map((el) => (
        <div {...attrs.members.roleSelectWrapper(el)} key={el.userId}>
          <img {...attrs.members.userImg} alt="" />
          {el.name}
          <div className={memberRoleSelectWrapperClassName(el)}>
            <RoleSelect {...attrs.members.roleSelect(el)} />
          </div>
        </div>
      ));

  return (
    <>
      <div {...attrs.addMemberSection.wrapper}>
        <InputAutocomplete {...attrs.addMemberSection.autocomplete} />
        <div {...attrs.addMemberSection.roleSelectWrapper}>
          <RoleSelect {...attrs.addMemberSection.roleSelect} />
        </div>
        <Button {...attrs.addMemberSection.button}>
          {addMemberButtonText}
        </Button>
      </div>
      <div {...attrs.members.wrapper}>{addedMembers}</div>
    </>
  );
};
