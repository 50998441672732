import {
  RSSortBy,
  RSSortOrder,
} from '../../../../../store/relationships/relationships.types';

const sortOrderMap = new Map<RSSortOrder | null, RSSortOrder | null>([
  [null, RSSortOrder.FROM_HIGH],
  [RSSortOrder.FROM_HIGH, RSSortOrder.FLOM_LOW],
  [RSSortOrder.FLOM_LOW, null],
]);

export const getRSCurrentSorting = (
  newSortBy: RSSortBy,
  currentSortBy: RSSortBy | null,
  sortOrder: RSSortOrder | null
): { sortBy: RSSortBy | null; sortOrder: RSSortOrder | null } => {
  if (currentSortBy === newSortBy) {
    const newSortOrder = sortOrderMap.get(sortOrder) || null;
    return {
      sortBy: newSortOrder ? newSortBy : null,
      sortOrder: newSortOrder,
    };
  }

  return {
    sortBy: newSortBy,
    sortOrder: RSSortOrder.FROM_HIGH,
  };
};
