import React from 'react';
import { getIconComponent } from './utils/getIconComponent';

export interface AnalysisDisclaimerProps {
  title?: string;
  isPositive?: boolean;
}

export const AnalysisDisclaimer: React.FC<AnalysisDisclaimerProps> = ({
  title,
  isPositive,
}) => {
  const IconComponent = getIconComponent(isPositive);

  const attrs = {
    container: {
      className: 'analysis-disclaimer',
    },
    title: {
      className: 'analysis-disclaimer__title',
    },
    icon: {
      className: 'analysis-disclaimer__icon',
    },
  };

  if (!title) {
    return null;
  }

  return (
    <div {...attrs.container}>
      <IconComponent {...attrs.icon} />
      <span {...attrs.title}>{title}</span>
    </div>
  );
};
