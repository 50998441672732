import React from 'react';

export const SpeakerIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.71967 2.46738C8.02233 1.38183 10 2.30815 10 4.00382V11.6437C10 13.3394 8.02233 14.2657 6.71967 13.1802L5.27743 11.9783C5.15762 11.8784 5.0066 11.8238 4.85064 11.8238H3.33337C2.2288 11.8238 1.33337 10.9283 1.33337 9.82377V5.82377C1.33337 4.7192 2.2288 3.82377 3.33337 3.82377H4.85064C5.0066 3.82377 5.15762 3.76909 5.27743 3.66925L6.71967 2.46738ZM8.66671 4.00382C8.66671 3.4386 8.00747 3.12983 7.57325 3.49167L6.13101 4.69354C5.77158 4.99307 5.31851 5.1571 4.85064 5.1571H3.33337C2.96518 5.1571 2.66671 5.45558 2.66671 5.82377V9.82377C2.66671 10.192 2.96518 10.4904 3.33337 10.4904H4.85064C5.31851 10.4904 5.77158 10.6545 6.13101 10.954L7.57325 12.1559C8.00747 12.5177 8.66671 12.2089 8.66671 11.6437V4.00382Z"
      fill="#070B12"
    />
    <path
      d="M12.3686 3.22748C12.6979 3.06282 13.0983 3.19631 13.263 3.52562L13.7723 4.5442C14.8045 6.60874 14.8045 9.0388 13.7723 11.1033L13.263 12.1219C13.0983 12.4512 12.6979 12.5847 12.3686 12.4201C12.0392 12.2554 11.9058 11.8549 12.0704 11.5256L12.5797 10.507C13.4243 8.81788 13.4243 6.82965 12.5797 5.14049L12.0704 4.12191C11.9058 3.79259 12.0392 3.39214 12.3686 3.22748Z"
      fill="#070B12"
    />
    <path
      d="M11.5334 4.7571C11.3125 4.46255 10.8946 4.40285 10.6 4.62377C10.3055 4.84468 10.2458 5.26255 10.4667 5.5571C11.4741 6.90031 11.4741 8.74722 10.4667 10.0904C10.2458 10.385 10.3055 10.8029 10.6 11.0238C10.8946 11.2447 11.3125 11.185 11.5334 10.8904C12.8963 9.07315 12.8963 6.57438 11.5334 4.7571Z"
      fill="#070B12"
    />
  </svg>
);
