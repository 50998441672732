import React from 'react';

export interface EventSummaryEmptyProps {
  text?: string;
}

export const EventSummaryEmpty: React.FC<EventSummaryEmptyProps> = ({
  text,
}) => {
  const attrs = {
    container: {
      className: 'event-summary-empty',
    },
  };

  if (!text) {
    return null;
  }

  return <div {...attrs.container}>{text}</div>;
};
