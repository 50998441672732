import { FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { updateOrganization } from '../../../store/org/org.actions';
import { RootState } from '../../../store/store';
import { Organization } from '../../../types';

export type AddOrgForm = {
  name: string;
  url: string;
  location: string | null;
  timezone?: string;
};

type UseOrgFormParams = {
  onClose: () => void;
  org?: Organization | null;
  isNameForm?: boolean;
};

type UseOrgFormReturn = {
  handleCancel: () => void;
  errors: FormikErrors<AddOrgForm>;
  handleChange: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (
    field: string,
    value: any, // eslint-disable-line
    shouldValidate?: boolean | undefined
  ) => Promise<FormikErrors<FormikValues>> | Promise<void>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  touched: FormikTouched<AddOrgForm>;
  values: AddOrgForm;
  initialValues: AddOrgForm;
};

export const useOrgForm = ({
  onClose,
  org,
  isNameForm,
}: UseOrgFormParams): UseOrgFormReturn => {
  const dispatch = useDispatch();
  const { org: organization } = useSelector((state: RootState) => state.auth);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    url: Yup.string().url('Must be a valid URL'),
    location: Yup.string().nullable(),
  });

  const handleSubmit = (values: AddOrgForm) => {
    const valuesToUpdate = {
      name: values.name === organization?.name ? undefined : values.name,
      url: values.url,
      location: values.location?.length ? values.location : null,
      tz: values.timezone?.length ? values.timezone : undefined,
    };
    dispatch(
      updateOrganization(
        valuesToUpdate,
        org?.orgId || '',
        org?.orgId === organization?.orgId
      )
    );
    if (!isNameForm) {
      onClose();
    }
  };

  const formik = useFormik<AddOrgForm>({
    initialValues: {
      name: org?.name || '',
      url: org?.url || '',
      location: org?.location || '',
      timezone: org?.timezone || '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return {
    ...formik,
    handleCancel,
  };
};
