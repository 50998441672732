import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  isLoading?: boolean;
  children?: JSX.Element | string;
  isTeamQ?: boolean;
}

export const QActivitySkeleton: React.FC<Props> = (props: Props) => {
  const { isLoading, children, isTeamQ } = props;

  const attrs = {
    yTicks: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 20,
          height: 12,
          borderRadius: 30,
          marginTop: 0,
        },
      },
    },
    xTicks: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 20,
          height: 12,
          borderRadius: 30,
          margin: '2px 0px 0px 5px',
        },
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className={`${isTeamQ ? 'team-q-chart' : ''} chart-wrapper`}>
          <div className="chart-wrapper__title-block">
            <Skeleton
              loading={isLoading}
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 120,
                  maxWidth: '40%',
                  height: 20,
                  borderRadius: 30,
                  marginTop: 0,
                },
              }}
            />
          </div>
          <div
            className={`${isTeamQ ? 'no-border ' : ''}chart-wrapper__subtitle`}
          >
            <Skeleton
              loading={isLoading}
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 80,
                  maxWidth: '20%',
                  height: 13,
                  borderRadius: 30,
                  marginTop: -5,
                },
              }}
            />
            <Skeleton
              loading={isLoading}
              active
              avatar={false}
              paragraph={false}
              title={{
                style: {
                  width: 265,
                  maxWidth: '70%',
                  height: 13,
                  borderRadius: 30,
                  marginTop: 8,
                },
              }}
            />
          </div>
          <div className="qactivity-chart">
            <div className="qactivity-chart__first-column">
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
              <Skeleton {...attrs.yTicks} />
            </div>
            <div className="qactivity-chart__second-column">
              <Skeleton
                loading={isLoading}
                active
                avatar={false}
                paragraph={false}
                title={{
                  style: {
                    width: '100%',
                    maxWidth: '100%',
                    height: 171,
                    borderRadius: 20,
                    marginTop: 0,
                  },
                }}
              />
              <div className="qactivity-chart__second-column__x-axis">
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
                <Skeleton {...attrs.xTicks} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
