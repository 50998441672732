import { Duration } from 'luxon';

export const formatNavigationCellValue = (
  value: number | 'N/A',
  isDuration?: boolean
): string => {
  if (typeof value === 'number' && isDuration) {
    return Duration.fromObject({
      minutes: value,
    }).toFormat('hh:mm');
  }

  let valueToReturn = 'no value';

  const cutValue = (val: number) => {
    let result = val;

    const integer = val.toString().includes('.')
      ? +val.toString().split('.')[0]
      : val;

    const tenth = val.toString().includes('.')
      ? +val.toString().split('.')[1].substring(0, 1)
      : 0;

    if (+tenth !== 0 && integer < 10000) {
      result = +`${integer}.${tenth}`;
    } else result = +integer;

    return result;
  };

  const stringValue =
    typeof value === 'number' ? cutValue(value).toString() : 'N/A';

  if (stringValue !== 'N/A') {
    const integer = +stringValue.split('.')[0];

    switch (true) {
      case integer >= 1000000:
        valueToReturn = `${
          stringValue.includes('.')
            ? `${stringValue.substring(0, 1)}${
                +stringValue.substring(1, 2) === 0
                  ? ''
                  : `.${stringValue.substring(1, 2)}`
              }`
            : `${integer.toString().substring(0, 1)}${
                +integer.toString().substring(1, 2) === 0
                  ? ''
                  : `.${integer.toString().substring(1, 2)}`
              }`
        }M`;

        // console.log('case 1M - 9.9M', value, stringValue, valueToReturn);
        break;

      case integer >= 100000:
        valueToReturn = `${`${stringValue.substring(0, 3)}${
          +stringValue.substring(3, 4) === 0
            ? ''
            : `.${stringValue.substring(3, 4)}`
        }`}K`;

        // console.log(
        //   'case 100 000 - 999 000',
        //   value,
        //   stringValue,
        //   valueToReturn
        // );
        break;

      case integer >= 10000:
        valueToReturn = `${`${stringValue.substring(0, 2)}${
          +stringValue.substring(2, 3) === 0
            ? ''
            : `.${stringValue.substring(2, 3)}`
        }`}K`;

        // console.log('case 10K - 99.9K', value, stringValue, valueToReturn);
        break;

      case integer >= 1000:
        valueToReturn = `${
          stringValue.includes('.')
            ? `${stringValue.substring(0, 1)}${
                +stringValue.substring(1, 2) === 0
                  ? ''
                  : `.${stringValue.substring(1, 2)}`
              }`
            : `${integer.toString().substring(0, 1)}${
                +integer.toString().substring(1, 2) === 0
                  ? ''
                  : `.${integer.toString().substring(1, 2)}`
              }`
        }K`;
        // console.log('case 1K - 9.9K', value, stringValue, valueToReturn);
        break;

      default:
        valueToReturn = stringValue;
      // console.log('default case', value, stringValue, valueToReturn);
    }
  } else {
    valueToReturn = 'No value';
  }

  return valueToReturn;
};

export const roundMaxGraphValue = (value: number): number => {
  let result = Math.ceil(value / 10) * 10;

  if (value <= 10) {
    if (value <= 1) {
      result = 1;
    } else if (value > 1 && value <= 2.5) {
      result = 2.5;
    } else if (value > 2.5 && value <= 5) {
      result = 5;
    } else {
      result = 10;
    }
  } else if (value <= 100) {
    if (value > 10 && value <= 15) {
      result = 15;
    } else if (value > 15 && value <= 20) {
      result = 20;
    } else if (value > 20 && value <= 25) {
      result = 25;
    } else if (value > 25 && value <= 30) {
      result = 30;
    } else if (value > 30 && value <= 35) {
      result = 35;
    } else if (value > 35 && value <= 40) {
      result = 40;
    } else if (value > 40 && value <= 45) {
      result = 45;
    } else if (value > 45 && value <= 50) {
      result = 50;
    } else {
      result = Math.ceil(value / 10) * 10;
    }
  } else if (value <= 1000) {
    if (value > 100 && value <= 150) {
      result = 150;
    } else if (value > 150 && value <= 200) {
      result = 200;
    } else if (value > 200 && value <= 250) {
      result = 250;
    } else {
      result = Math.ceil(value / 100) * 100;
    }
  } else if (value <= 10000) {
    if (value > 1000 && value <= 1500) {
      result = 1500;
    } else if (value > 1500 && value <= 2000) {
      result = 2000;
    } else if (value > 2000 && value <= 2500) {
      result = 2500;
    } else {
      result = Math.ceil(value / 1000) * 1000;
    }
  } else if (value <= 100000) {
    if (value > 10000 && value <= 15000) {
      result = 15000;
    } else if (value > 15000 && value <= 20000) {
      result = 20000;
    } else if (value > 20000 && value <= 25000) {
      result = 25000;
    } else {
      result = Math.ceil(value / 10000) * 10000;
    }
  } else {
    result = Math.ceil(value / 100000) * 100000;
  }

  return result;
};
