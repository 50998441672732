import React from 'react';
import { RelationshipMemberSide } from '../../../../../../types/relationshipMember.types';
import { LogoPlaceholderIcon } from '../../../../../Icons';
import { CheckedIcon } from './icons/CheckedIcon';

export interface SideOptionProps {
  label: string;
  isSelected: boolean;
  value: RelationshipMemberSide;
  onChange?: (value: RelationshipMemberSide) => void;
  imageSrc?: string;
}

export const SideOption: React.FC<SideOptionProps> = ({
  label,
  isSelected,
  value,
  onChange,
  imageSrc,
}) => {
  const attrs = {
    container: {
      role: 'option',
      className: `side-option${isSelected ? ' side-option--selected' : ''}`,
      onClick: () => {
        if (!isSelected) {
          onChange?.(value);
        }
      },
    },
    imageWrapper: {
      className: 'side-option__image-wrapper',
    },
    image: {
      src: imageSrc,
    },
    logo: {
      className: 'side-option__logo',
    },
    label: {
      className: 'side-option__label',
    },
    selectedIcon: {
      className: 'side-option__selected-icon',
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.imageWrapper}>
        {imageSrc ? (
          <img {...attrs.image} alt="" />
        ) : (
          <LogoPlaceholderIcon {...attrs.logo} />
        )}
      </div>
      <div {...attrs.label}>
        <span>{label}</span>
        {isSelected ? <CheckedIcon {...attrs.selectedIcon} /> : null}
      </div>
    </div>
  );
};
