import React, { useEffect, useState } from 'react';
import {
  TeamQTendBarTriangleVariantType,
  TendBarTriangle,
} from './assets/TendBarTriangle';

interface Props {
  value: number | null;
}

export const TeamQTendencyBar: React.FC<Props> = ({ value }) => {
  const [
    triangleVariant,
    setTriangleVariant,
  ] = useState<TeamQTendBarTriangleVariantType>('default');

  const [measure, setMeasure] = useState<number | null>(null);

  const min = 0;

  const max = 20;

  const maxPosssibleVal = max - min;

  const isCurrentHasTypeNumber =
    !Number.isNaN(value) && value !== undefined && typeof value === 'number';

  const conditionToShowNoData =
    Number.isNaN(value) || value === undefined || typeof value !== 'number';

  useEffect(() => {
    if (measure || triangleVariant !== 'default') {
      setTriangleVariant('default');
      setMeasure(null);
    }

    const barTimer = setTimeout(() => {
      if (isCurrentHasTypeNumber && (value || value === 0)) {
        const resultValue = ((value - min) / maxPosssibleVal) * 100;

        setMeasure(resultValue);

        if (
          (resultValue >= 28.6 && resultValue <= 38) ||
          (resultValue >= 62 && resultValue <= 71.4)
        ) {
          setTriangleVariant('greenOpacity');
        } else if (resultValue > 38 && resultValue < 62) {
          setTriangleVariant('green');
        } else {
          setTriangleVariant('grey');
        }
      }
    }, 200);
    return () => {
      clearTimeout(barTimer);
    };
  }, [value]); // eslint-disable-line

  const animationDuration = measure || measure === 0 ? 1.5 : 0;

  const attrs = {
    wrapper: {
      className: 'team-q-tendency',
    },

    body: {
      barNoData: {
        wrapper: {
          className: 'team-q-tendency__bar-section-bar',
        },

        greyBar: {
          className: 'team-q-tendency__bar-section-bar-grey',
        },

        noData: {
          className: 'team-q-tendency__bar-section-bar__not-enough-data',
        },
      },

      animatedBar: {
        wrapper: {
          className: 'team-q-tendency__bar-section',
        },

        animatedTriangle: {
          wrapper: {
            className: 'team-q-tendency__bar-section-triangle',
            style: {
              left: `${measure || 50}%`,
              transitionDuration: `${animationDuration}s`,
              transitionTimingFunction: 'ease',
            },
          },
          triangle: {
            variant: triangleVariant,
          },
        },

        bar: {
          wrapper: {
            className: 'team-q-tendency__bar-section-bar',
          },

          blueBackground: {
            className: 'team-q-tendency__bar-section-bar-gradient-bg',
          },

          separator: {
            wrapper: {
              className: 'team-q-tendency__bar-section-bar__separators',
            },

            section: {
              className: 'team-q-tendency__bar-section-bar__separators-section',
            },
          },
        },
      },
    },
  };

  const separators = (
    <div {...attrs.body.animatedBar.bar.separator.wrapper}>
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
      <div {...attrs.body.animatedBar.bar.separator.section} />
    </div>
  );

  const conditionalBodySection = conditionToShowNoData ? (
    <div {...attrs.body.barNoData.wrapper}>
      <div {...attrs.body.animatedBar.animatedTriangle.wrapper} />
      <div {...attrs.body.animatedBar.bar.wrapper}>
        {separators}
        {!value ? (
          <span {...attrs.body.barNoData.noData}>Not enough data</span>
        ) : null}
      </div>
    </div>
  ) : (
    <div {...attrs.body.animatedBar.wrapper}>
      <div {...attrs.body.animatedBar.animatedTriangle.wrapper}>
        <TendBarTriangle
          {...attrs.body.animatedBar.animatedTriangle.triangle}
        />
      </div>
      <div {...attrs.body.animatedBar.bar.wrapper}>
        <div {...attrs.body.animatedBar.bar.blueBackground} />
        {separators}
      </div>
    </div>
  );

  return <div {...attrs.wrapper}>{conditionalBodySection}</div>;
};
