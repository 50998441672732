import React from 'react';

export enum SliderSize {
  SMALL = 'small',
}

export interface SliderProps {
  step: number;
  value: number;
  size?: SliderSize;
  disabled?: boolean;
  dark?: boolean;
  onChange: (value: number) => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
}

export const Slider: React.FC<SliderProps> = ({
  step,
  value,
  size,
  disabled = false,
  dark = false,
  onChange,
  onMouseDown,
  onMouseUp,
}) => {
  const backgroundColor = dark ? 'rgba(241, 244, 248, 0.30)' : '#F1F4F8';

  const attrs = {
    input: {
      className: `player-slider${size ? ` player-slider--${size}` : ''}`,
      type: 'range',
      min: 0,
      max: 1,
      step,
      value,
      disabled,
      style: {
        background: `linear-gradient(to right, #537BFF 0%, #537BFF ${
          disabled ? 0 : value * 100
        }%, ${backgroundColor} ${
          disabled ? 0 : value * 100
        }%, ${backgroundColor} 100%)`,
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(parseFloat(e.target.value));
      },
      onMouseDown,
      onMouseUp,
    },
  };

  return <input {...attrs.input} />;
};
