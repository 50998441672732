import { Skeleton } from 'antd';
import React from 'react';
import { EventSummaryEmpty } from '../EventSummaryEmpty/EventSummaryEmpty';

export interface EventSummaryListProps {
  emptyText?: string;
  isLoading?: boolean;
  items?: string[];
  ordered?: boolean;
}

export const EventSummaryList: React.FC<EventSummaryListProps> = ({
  emptyText,
  isLoading = false,
  items,
  ordered = false,
}) => {
  const ListType = ordered ? 'ol' : 'ul';

  const attrs = {
    container: {
      className: 'event-summary-list',
    },
    eventSummaryEmpty: {
      text: emptyText,
    },
    listSkeleton: {
      active: true,
      title: false,
      paragraph: {
        rows: 5,
        width: '100%',
        style: {
          margin: 0,
        },
      },
    },
  };

  if (isLoading) {
    return <Skeleton {...attrs.listSkeleton} />;
  }

  if (items?.length) {
    return (
      <ListType {...attrs.container}>
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ListType>
    );
  }

  return <EventSummaryEmpty {...attrs.eventSummaryEmpty} />;
};
