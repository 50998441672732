import {
  Organization,
  User,
  UserActivity,
  UserConnection,
  UserConnections,
} from '../../types';

export enum UserSignUpFlow {
  COMPLETED = 'completed',
  SELF_SIGNUP = 'selfSignUp',
  INVITE_SIGNUP = 'inviteSignUp',
  SUBSCRIPTION_EXPIRED = 'subscriptionExpired',
}

export interface GetCustomTokenRequestBody {
  authCode: string;
}
export interface AuthState {
  isLoading: {
    signIn: boolean;
    customToken: boolean;
    identity: boolean;
    userConnections: boolean;
    signOut: boolean;
    userData: boolean;
    userActivity: boolean;
    toggleApp: boolean;
    supportRequest: boolean;
    welcomeModal: boolean;
    dontShowInstallExtensionPopup: boolean;
    connectAnalyzeEmails: boolean;
    connectSendEmails: boolean;
    connectCalendar: boolean;
    zoomOAuthUrl: boolean;
    zoomAuthCode: boolean;
    disconnectZoom: boolean;
    revokeGoogleScopes: boolean;
  };
  isError: {
    signIn: boolean;
    customToken: boolean;
    identity: boolean;
    userConnections: boolean;
    signOut: boolean;
    userData: boolean;
    userActivity: boolean;
    toggleApp: boolean;
    supportRequest: boolean;
    welcomeModal: boolean;
    dontShowInstallExtensionPopup: boolean;
    connectAnalyzeEmails: boolean;
    connectSendEmails: boolean;
    connectCalendar: boolean;
    zoomOAuthUrl: boolean;
    zoomAuthCode: boolean;
    disconnectZoom: boolean;
    revokeGoogleScopes: boolean;
  };
  isLoggedIn: boolean;
  isInitialized: boolean;
  customToken: string | null;
  user: User | null;
  org: Organization | null;
  userSignUpFlow: UserSignUpFlow | null;
  onboardingModalActive: boolean;
  isAddInfoModalActive: boolean;
  isAccountTeamTabActive: boolean;
  userActivity: UserActivity | null;
  userConnections: UserConnections | null;
  zoomOAuthUrl: string | null;
  retryConnectZoom: boolean;
  revokeGoogleScopesModal: {
    isOpened: boolean;
    connectionId: UserConnection;
  };
}
