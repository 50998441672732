import React, { useState } from 'react';
import { Duration } from 'luxon';
import { useAppSelector } from '../../../../store/hooks';
import { formatNavigationCellValue } from '../../utils/teamq.utils';
import { QCellSkeleton } from '../skeletons/QCellSkeleton';
import {
  BarTooltipTextCase,
  TeamQAvgLineValueUnit,
  TeamQGraph,
} from '../teamQGraph/TeamQGraph';

type CurrentComponent =
  | 'duration'
  | 'length'
  | 'analyzed'
  | 'simulated'
  | 'meetingCount'
  | 'meetingDuration';

export const TeamActivity: React.FC = () => {
  const {
    requestedTimePeriodDays,
    threadDuration,
    threadDurationStatus,
    threadLength,
    threadLengthStatus,
    analyzed,
    analyzedStatus,
    simulated,
    simulatedStatus,
  } = useAppSelector((state) => state.teamQ);

  const meetingCountStats = useAppSelector(
    (state) => state.soundWaveTeamQ.meetingCountStats
  );

  const meetingDurationStats = useAppSelector(
    (state) => state.soundWaveTeamQ.meetingDurationStats
  );

  const isLoading = useAppSelector((state) => state.soundWaveTeamQ.isLoading);

  const isError = useAppSelector((state) => state.soundWaveTeamQ.isError);

  const [currentComp, setCurrentComp] = useState<CurrentComponent>('duration');

  const threadDurationData = threadDuration?.statsPerUser
    ? threadDuration?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.avgThreadDurationWeeks || 0,
        noData: el.avgThreadDurationWeeks === null,
        emails: el.branchesCount || 0,
      }))
    : null;

  const threadLengthData = threadLength?.statsPerUser
    ? threadLength?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.avgMessagesPerThread || 0,
        emails: el.threadsCount || 0,
      }))
    : null;

  const analyzedData = analyzed?.statsPerUser
    ? analyzed?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.emailsAnalyzed || 0,
      }))
    : null;

  const simulatedData = threadLength?.statsPerUser
    ? simulated?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.emailsSimulated || 0,
      }))
    : null;

  const meetingCountData = meetingCountStats?.statsPerUser
    ? meetingCountStats?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.count || 0,
      }))
    : null;

  const meetingDurationData = meetingDurationStats?.statsPerUser
    ? meetingDurationStats?.statsPerUser.map((el) => ({
        name: el.name,
        value: el.userAverageDurationMin || 0,
      }))
    : null;

  const meetingCountStatus = {
    isLoading: isLoading.meetingCountStats,
    error: isError.meetingCountStats,
    noData: !meetingCountStats?.mean,
  };

  const meetingDurationStatus = {
    isLoading: isLoading.meetingDurationStats,
    error: isError.meetingDurationStats,
    noData: !meetingDurationStats?.mean,
  };

  const attrs = {
    wrapper: {
      className: 'team-q-activity',
    },

    nagivationSection: {
      wrapper: {
        className: 'team-q-activity__navigation-section',
      },

      cell: {
        wrapper: {
          className: 'team-q-activity__navigation-section__cell',
        },

        title: {
          className: 'team-q-activity__navigation-section__cell-title',
        },

        value: {
          normal: {
            className: 'team-q-activity__navigation-section__cell__value',
          },

          bold: {
            className: 'team-q-activity__navigation-section__cell__value-bold',
          },
        },
      },
    },

    chartSection: {
      wrapper: {
        className: 'team-q-activity__chart-section',
      },

      ThreadDuration: {
        title: 'Thread Duration',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: threadDuration?.basedOnBranchesCount || 0,
        tooltipText: `The average thread duration between team members and 
        their prospects during the selected time period.`,
        graphValueSectionTitleName: 'Thread duration (avg/team member)',
        data: threadDurationData,
        dataStatus: threadDurationStatus,
        wordValue: true,
        avgValue: threadDuration?.mean || 0,
        avgLineValueUnit: 'week' as TeamQAvgLineValueUnit,
        barTooltipCase: 'duration' as BarTooltipTextCase,
        isThreadDuration: true,
      },

      ThreadLength: {
        title: 'Number of Messages per Thread',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: threadLength?.basedOnBranchesCount || 0,
        tooltipText: `The average number of messages per thread between 
        team members and their prospects during the selected time period.`,
        graphValueSectionTitleName:
          'Number of messages per thread (avg/team member)',
        data: threadLengthData,
        dataStatus: threadLengthStatus,
        avgValue: threadLength?.mean || 0,
        avgLineValueUnit: 'msg' as TeamQAvgLineValueUnit,
        barTooltipCase: 'length' as BarTooltipTextCase,
        isThreadLength: true,
      },

      MessagesAnalyzed: {
        title: 'Number of Messages Analyzed',
        timePeriodText: `Total, last ${requestedTimePeriodDays || 0} days`,
        basedOn: analyzed?.basedOnMessagesCount,
        tooltipText: `The average number of messages analyzed by each team member 
        during the selected time period.`,
        graphValueSectionTitleName:
          'Number of analyzed messages (total/team member)',
        data: analyzedData,
        dataStatus: analyzedStatus,
        avgValue: analyzed?.mean || 0,
        avgLineValueUnit: 'msg' as TeamQAvgLineValueUnit,
        barTooltipCase: 'analyzed' as BarTooltipTextCase,
      },

      MessagesSimulated: {
        title: 'Number of Simulated Email Messages',
        timePeriodText: `Total, last ${requestedTimePeriodDays || 0} days`,
        basedOn: simulated?.basedOnMessagesCount,
        tooltipText: `The average number of messages simulated by team members 
        during the selected time period.`,
        graphValueSectionTitleName:
          'Number of simulated email messages (total/team member)',
        data: simulatedData,
        dataStatus: simulatedStatus,
        avgValue: simulated?.mean || 0,
        avgLineValueUnit: 'msg' as TeamQAvgLineValueUnit,
        barTooltipCase: 'simulated' as BarTooltipTextCase,
      },

      meetingCount: {
        title: 'Meeting Count',
        timePeriodText: `Total, last ${requestedTimePeriodDays || 0} days`,
        basedOn: meetingCountStats?.basedOnEventsCount,
        tooltipText:
          'The total number of meetings between team members and their prospects during the selected time period.',
        graphValueSectionTitleName: 'Number of Meetings, (avg/team member)',
        data: meetingCountData,
        dataStatus: meetingCountStatus,
        avgValue: meetingCountStats?.mean || 0,
        avgLineValueUnit: 'meetings' as TeamQAvgLineValueUnit,
        barTooltipCase: 'meetingCount' as BarTooltipTextCase,
        isMeeting: true,
      },

      meetingDuration: {
        title: 'Meeting Duration',
        timePeriodText: `Average, last ${requestedTimePeriodDays || 0} days`,
        basedOn: meetingDurationStats?.basedOnEventsCount,
        tooltipText:
          'Distribution of the average duration of online meetings between team members and their prospects during the selected time period.',
        graphValueSectionTitleName: 'Meeting duration (avg/team member)',
        data: meetingDurationData,
        dataStatus: meetingDurationStatus,
        avgValue: meetingDurationStats?.mean || 0,
        avgLineValueUnit: 'meetings' as TeamQAvgLineValueUnit,
        barTooltipCase: 'meetingDuration' as BarTooltipTextCase,
        isDuration: true,
        isMeeting: true,
      },

      divider: {
        className: 'team-q-activity__chart-section-divider',
      },
    },
  };

  const createCell = (
    title: string,
    value: number | string,
    avgText: string,
    switchTo: CurrentComponent,
    dataStatus: typeof threadDurationStatus
  ) => {
    const switchToCellComponent = () => {
      if (currentComp !== switchTo) {
        setCurrentComp(switchTo);
      }
    };

    const activeClassName = currentComp === switchTo ? 'active' : '';

    const cellAttrs = {
      wrapper: {
        className: `${activeClassName} team-q-activity__navigation-section__cell`,
        onClick: switchToCellComponent,
      },

      title: {
        className: `${activeClassName} team-q-activity__navigation-section__cell-title`,
      },

      value: {
        normal: {
          className: `${activeClassName} team-q-activity__navigation-section__cell__value`,
        },

        bold: {
          className: 'team-q-activity__navigation-section__cell__value-bold',
        },

        noData: {
          className: 'team-q-activity__navigation-section__cell__value-n-a',
        },
      },
    };

    const definedValue =
      typeof value === 'number' ? formatNavigationCellValue(value) : value;

    const valueToShow =
      definedValue === 'N/A' ? (
        <div {...cellAttrs.value.noData}>N/A</div>
      ) : (
        <>
          <div {...cellAttrs.value.bold}>{definedValue}</div>
          {avgText}
        </>
      );

    return (
      <div {...cellAttrs.wrapper}>
        <div {...cellAttrs.title}>{title}</div>
        <QCellSkeleton isLoading={dataStatus.isLoading}>
          <div {...cellAttrs.value.normal}>{valueToShow}</div>
        </QCellSkeleton>
      </div>
    );
  };

  const nagivationSection = (
    <div {...attrs.nagivationSection.wrapper}>
      {createCell(
        'Thread Duration',
        threadDuration?.mean || 'N/A',
        'Weeks, Team Avg.',
        'duration',
        threadDurationStatus
      )}
      {createCell(
        'Thread Length',
        threadLength?.mean || 'N/A',
        'Messages, Team Avg.',
        'length',
        threadLengthStatus
      )}
      {createCell(
        'Messages Analyzed',
        analyzed?.mean || 'N/A',
        'Avg/Team Member ',
        'analyzed',
        analyzedStatus
      )}
      {createCell(
        'Messages Simulated',
        simulated?.mean || 'N/A',
        'Avg/Team Member ',
        'simulated',
        simulatedStatus
      )}
      {createCell(
        'Meeting Count',
        meetingCountStats?.mean || 'N/A',
        'Meetings, Team Avg.',
        'meetingCount',
        meetingCountStatus
      )}
      {createCell(
        'Meeting Duration',
        meetingDurationStats?.mean
          ? Duration.fromObject({
              minutes: meetingDurationStats?.mean,
            }).toFormat('hh:mm')
          : 'N/A',
        'Team Meeting Duration Avg.',
        'meetingDuration',
        meetingDurationStatus
      )}
    </div>
  );

  const ThreadDuration =
    currentComp === 'duration' ? (
      <TeamQGraph {...attrs.chartSection.ThreadDuration} />
    ) : null;

  const Length =
    currentComp === 'length' ? (
      <TeamQGraph {...attrs.chartSection.ThreadLength} />
    ) : null;

  const Analyzed =
    currentComp === 'analyzed' ? (
      <TeamQGraph {...attrs.chartSection.MessagesAnalyzed} />
    ) : null;

  const Simulated =
    currentComp === 'simulated' ? (
      <TeamQGraph {...attrs.chartSection.MessagesSimulated} />
    ) : null;

  const MeetingCount =
    currentComp === 'meetingCount' ? (
      <TeamQGraph {...attrs.chartSection.meetingCount} />
    ) : null;

  const MeetingDuration =
    currentComp === 'meetingDuration' ? (
      <TeamQGraph {...attrs.chartSection.meetingDuration} />
    ) : null;

  const chartSection = (
    <div {...attrs.chartSection.wrapper}>
      {ThreadDuration}
      {Length}
      {Analyzed}
      {Simulated}
      {MeetingCount}
      {MeetingDuration}
    </div>
  );

  return (
    <div {...attrs.wrapper}>
      {nagivationSection}
      {chartSection}
    </div>
  );
};
