import React, { useMemo } from 'react';
import { MutedSpeakerIcon } from '../Icons/MutedSpeakerIcon';
import { SpeakerIcon } from '../Icons/SpeakerIcon';
import { FullScreenIcon } from '../Icons/FullScreenIcon';
import {
  ControlButton,
  ControlButtonSize,
} from '../ControlButton/ControlButton';
import { Slider, SliderSize } from '../Slider/Slider';
import { getTimeString } from './Controls.utils';

export interface ControlsProps {
  isMuted: boolean;
  volume: number;
  played: number;
  playedSeconds: number;
  playbackRate: number;
  duration: number;
  isFullScreen: boolean;
  onMuteChange: (value: boolean) => void;
  onVolumeChange: (value: number) => void;
  onTimelineChange: (value: number) => void;
  onIsChangingTimeline: (value: boolean) => void;
  onPlaybackRateChange: (value: number) => void;
  onFullScreen: () => void;
}

const playbackRateMap: Record<number, number> = {
  1: 1.5,
  1.5: 2,
  2: 1,
};

export const Controls: React.FC<ControlsProps> = ({
  isMuted,
  volume,
  played,
  playedSeconds,
  playbackRate,
  duration,
  isFullScreen,
  onMuteChange,
  onVolumeChange,
  onTimelineChange,
  onIsChangingTimeline,
  onPlaybackRateChange,
  onFullScreen,
}) => {
  const playedTime = useMemo(() => getTimeString(playedSeconds), [
    playedSeconds,
  ]);

  const leftTime = useMemo(() => getTimeString(duration), [duration]);

  const attrs = {
    container: {
      className: `player-controls${
        isFullScreen ? ' player-controls--full-screen' : ''
      }`,
    },
    volumeControlsWrapper: {
      className: 'player-controls__volume-controls-wrapper',
    },
    volumeButton: {
      label: 'Toggle volume',
      size: ControlButtonSize.SMALL,
      onClick: () => {
        onMuteChange(!isMuted);
      },
    },
    volumeSliderWrapper: {
      className: 'player-controls__volume-slider-wrapper',
    },
    volumeSlider: {
      step: 0.01,
      value: isMuted ? 0 : volume,
      disabled: isMuted,
      size: SliderSize.SMALL,
      dark: isFullScreen,
      onChange: onVolumeChange,
    },
    timelineSliderWrapper: {
      className: 'player-controls__timeline-slider-wrapper',
    },
    timelineSlider: {
      step: 0.001,
      value: played,
      dark: isFullScreen,
      onChange: (value: number) => {
        if (value >= 1) {
          onTimelineChange(0.99999999);
        } else {
          onTimelineChange(value);
        }
      },
      onMouseDown: () => {
        onIsChangingTimeline(true);
      },
      onMouseUp: () => {
        onIsChangingTimeline(false);
      },
    },
    playbackRateButton: {
      label: 'Change playback rate',
      size: ControlButtonSize.SMALL,
      onClick: () => {
        onPlaybackRateChange(playbackRateMap[playbackRate]);
      },
    },
    fullScreenButton: {
      label: 'Toggle fullscreen',
      size: ControlButtonSize.SMALL,
      onClick: () => {
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          onFullScreen();
        }
      },
    },
    text: {
      className: 'player-controls__text',
    },
    timeText: {
      className: 'player-controls__text player-controls__time',
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.volumeControlsWrapper}>
        <ControlButton {...attrs.volumeButton}>
          {isMuted ? <MutedSpeakerIcon /> : <SpeakerIcon />}
        </ControlButton>
        <div {...attrs.volumeSliderWrapper}>
          <Slider {...attrs.volumeSlider} />
        </div>
      </div>
      <div {...attrs.timeText}>{playedTime}</div>
      <div {...attrs.timelineSliderWrapper}>
        <Slider {...attrs.timelineSlider} />
      </div>
      <div {...attrs.timeText}>{leftTime}</div>
      <ControlButton {...attrs.playbackRateButton}>
        <span {...attrs.text}>{playbackRate}x</span>
      </ControlButton>
      <ControlButton {...attrs.fullScreenButton}>
        <FullScreenIcon />
      </ControlButton>
    </div>
  );
};
