import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { FormProps } from '../../SoundWave/components/Form/Form';
import { AddAccountFormValues } from '../../../store/relationshipFlows/relationshipFlows.types';
import {
  FormFieldProps,
  FormFieldType,
} from '../../SoundWave/components/FormField/FormField';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useId, useMixpanel } from '../../../hooks';
import {
  closeAddAccountModal,
  openAddMemberModal,
  resetAddAccountFlow,
  updateSelectedAccount,
} from '../../../store/relationshipFlows/relationshipFlows.slice';
import {
  createAccount,
  updateAccount,
} from '../../../store/relationshipFlows/relationshipFlows.thunks';
import { ModalProps } from '../../SoundWave/components/Modal/Modal';
import { ConfirmationModalProps } from '../../SoundWave/components/ConfirmationModal/ConfirmationModal';
import { ConfirmationIcon } from '../../Icons/ConfirmationIcon';
import { LogoPlaceholderIcon } from '../../Icons';
import { Account } from '../../../types/account.types';
import { WarningIcon } from '../../Icons/WarningIcon';
import { StatusCode } from '../../../types/statusCode.types';

const validationSchema = Yup.object().shape({
  logo: Yup.string(),
  name: Yup.string().required('This field is required'),
  website: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/,
    'Invalid website URL'
  ),
});

export const useAddAccountForm = (): {
  modalProps: ModalProps;
  formProps: FormProps;
  fieldProps: Record<keyof AddAccountFormValues, FormFieldProps>;
  cancelModalProps: ConfirmationModalProps;
  confirmationModalProps: ConfirmationModalProps;
} => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const isOpened = useAppSelector(
    (state) => state.relatioshipFlows.addAccount.isModalOpened
  );

  const isLoading = useAppSelector(
    (state) => state.relatioshipFlows.isLoading.addAccount
  );

  const initialValues = useAppSelector(
    (state) => state.relatioshipFlows.addAccount.initialValues
  );

  const withRedirect = useAppSelector(
    (state) => state.relatioshipFlows.addAccount.withRedirect
  );

  const isAssignAccountModalOpened = useAppSelector(
    (state) => state.relatioshipFlows.assignAccount.isModalOpened
  );

  const accountId = useAppSelector(
    (state) => state.relatioshipFlows.addAccount.accountId
  );

  const assignAccountEventId = useAppSelector(
    (state) => state.relatioshipFlows.assignAccount.eventId
  );

  const { trackEvent } = useMixpanel();

  const [isCancelModalOpened, setIsCancelModalOpened] = useState(false);

  const [isConfirmationModalOpened, setIsConfirmationModalOpened] = useState(
    false
  );

  const [
    latestCreatedAccount,
    setLatestCreatedAccount,
  ] = useState<Account | null>(null);

  const history = useHistory();

  const formId = useId();

  const {
    errors,
    touched,
    values,
    isValid,
    dirty,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik<AddAccountFormValues>({
    initialValues,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (formValues, helpers) => {
      try {
        trackEvent(accountId ? 'editAccount' : 'createAccount');

        const account = accountId
          ? await dispatch(updateAccount({ ...formValues, accountId })).unwrap()
          : await dispatch(createAccount(formValues)).unwrap();

        dispatch(closeAddAccountModal());

        if (isAssignAccountModalOpened && assignAccountEventId) {
          dispatch(updateSelectedAccount(account));
        }

        if (withRedirect) {
          history.push(
            `/orgs/${user?.orgId}/users/${user?.userId}/relationships/${account.accountId}`,
            {
              account: formValues,
            }
          );
          setLatestCreatedAccount(account);
          setIsConfirmationModalOpened(true);
        }
      } catch (error) {
        if (
          error instanceof AxiosError &&
          error.response?.status === StatusCode.Conflict
        ) {
          helpers.setFieldError(
            'name',
            'Account with this name already exists'
          );
        }
      }
    },
  });

  const handleChange = useCallback(
    (name: string, value: string) => {
      setFieldValue(name, value);
    },
    [setFieldValue]
  );

  const handleFocus = useCallback(
    (e: React.FocusEvent<any, Element>) => {
      setFieldError(e.target.name, '');
      setFieldTouched(e.target.name, false);
    },
    [setFieldError, setFieldTouched]
  );

  const modalProps = useMemo(
    () => ({
      isOpened,
      closeOnClickOutside: true,
      width: 460,
      onClose: () => {
        if (dirty) {
          setIsCancelModalOpened(true);
        } else {
          dispatch(closeAddAccountModal());
        }
      },
      onAnimationEnd: () => {
        dispatch(resetAddAccountFlow());
      },
      headerProps: {
        title: accountId ? 'Edit Account' : 'Create a New Account',
      },
      footerProps: {
        formId,
        isLoading,
        cancelButtonText: 'Discard',
        confirmButtonText: accountId ? 'Apply' : 'Create Account',
        isConfirmButtonDisabled: !isValid || !dirty,
      },
    }),
    [accountId, dirty, dispatch, formId, isLoading, isOpened, isValid]
  );

  const formProps = useMemo(
    () => ({
      id: formId,
      onSubmit: handleSubmit,
    }),
    [formId, handleSubmit]
  );

  const fieldProps = useMemo(
    () => ({
      logo: {
        type: FormFieldType.IMAGE,
        name: 'logo',
        label: 'Logo',
        value: values.logo,
        placeholderIcon: LogoPlaceholderIcon,
        customTitle: 'Upload Logo Image',
        onChange: handleChange,
      },
      name: {
        type: FormFieldType.TEXT,
        name: 'name',
        placeholder: "Org's Name",
        label: 'Account Name',
        value: values.name,
        required: true,
        error: errors.name,
        touched: touched.name,
        withAutocomplete: true,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
      website: {
        type: FormFieldType.TEXT,
        name: 'website',
        placeholder: 'www.company.com',
        label: 'Account Website (URL)',
        value: values.website,
        error: errors.website,
        touched: touched.website,
        onChange: handleChange,
        onBlur: handleBlur,
        onFocus: handleFocus,
      },
    }),
    [errors, handleBlur, handleChange, handleFocus, touched, values]
  );

  const cancelModalProps = useMemo(
    () => ({
      icon: WarningIcon,
      isOpened: isCancelModalOpened,
      title: accountId ? 'Cancel Account Update?' : 'Cancel Account Creation?',
      footerProps: {
        cancelButtonText: 'Back',
        confirmButtonText: 'Yes, Cancel',
        isConfirmButtonSecondary: true,
        onConfirm: () => {
          setIsCancelModalOpened(false);
          dispatch(closeAddAccountModal());
        },
      },
      onClose: () => {
        setIsCancelModalOpened(false);
      },
    }),
    [accountId, dispatch, isCancelModalOpened]
  );

  const confirmationModalProps = useMemo(
    () => ({
      icon: ConfirmationIcon,
      isOpened: isConfirmationModalOpened,
      title: 'Account Created!',
      description:
        'You can now add new participants yourself. Substrata will also automatically include relevant people based on real-world interactions.',
      footerProps: {
        cancelButtonText: "I'll do it later",
        confirmButtonText: 'Add Members',
        onConfirm: () => {
          if (latestCreatedAccount) {
            dispatch(
              openAddMemberModal({
                accountId: latestCreatedAccount.accountId,
                accountName: latestCreatedAccount.name,
              })
            );
          }

          setIsConfirmationModalOpened(false);
          setLatestCreatedAccount(null);
        },
      },
      onClose: () => {
        setIsConfirmationModalOpened(false);
        setLatestCreatedAccount(null);
      },
    }),
    [dispatch, isConfirmationModalOpened, latestCreatedAccount]
  );

  useEffect(() => {
    resetForm({ values: initialValues, errors: {}, touched: {} });
  }, [initialValues, resetForm]);

  return {
    modalProps,
    formProps,
    fieldProps,
    cancelModalProps,
    confirmationModalProps,
  };
};
