import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { getOrganization } from '../../store/org/org.actions';
import { getAppSumo } from '../../store/appSumo/appSumo.actions';
import { OrgProfile } from './components/ogrProfile/OrgProfile';
import { OrgGeneralInfo } from './components/generalInfo/OrgGeneralInfo';
import { SubscriptionInfo } from './components/subscriptionInfo/SubscriptionInfo';
import { OrgOverview } from './components/overview/OrgOverview';
import { useGetAdminSettingsData, useTrackPageView } from '../../hooks';

export const MyOrganization: React.FC = (): JSX.Element => {
  const { org } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (org?.orgId) {
      dispatch(getOrganization(org.orgId));
      dispatch(getAppSumo(org.orgId));
    }
  }, []); // eslint-disable-line

  useGetAdminSettingsData();
  useTrackPageView();

  const attrs = {
    wrapper: {
      className: 'organization',
    },

    firstColumn: {
      className: 'organization__first-column',
    },

    secondColumn: {
      className: 'organization__second-column',
    },

    thirdColumn: {
      className: 'organization__third-column',
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.firstColumn}>
        <OrgProfile />
      </div>
      <div {...attrs.secondColumn}>
        <OrgGeneralInfo />
        <SubscriptionInfo />
      </div>
      <div {...attrs.thirdColumn}>
        <OrgOverview />
      </div>
    </div>
  );
};
