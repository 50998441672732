import React from 'react';

export const LoaderIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM2.0223 8C2.0223 11.3014 4.69861 13.9777 8 13.9777C11.3014 13.9777 13.9777 11.3014 13.9777 8C13.9777 4.69861 11.3014 2.0223 8 2.0223C4.69861 2.0223 2.0223 4.69861 2.0223 8Z"
      fill="#F8FAFC"
    />
    <path
      d="M14.9896 7.99976C15.5476 7.99976 16.0066 7.54557 15.9363 6.99196C15.7141 5.24189 14.9176 3.60366 13.6569 2.3429C12.1566 0.842611 10.1217 -0.00024398 8 -0.000244141C5.87827 -0.000244301 3.84344 0.84261 2.34315 2.3429C1.08239 3.60366 0.285897 5.24189 0.063701 6.99196C-0.00658679 7.54557 0.452387 7.99975 1.01043 7.99975C1.56848 7.99975 2.01216 7.54418 2.10602 6.99408C2.31293 5.78149 2.89072 4.65326 3.77212 3.77187C4.89342 2.65057 6.41424 2.02062 8 2.02062C9.58576 2.02062 11.1066 2.65057 12.2279 3.77187C13.1093 4.65326 13.6871 5.78149 13.894 6.99408C13.9878 7.54418 14.4315 7.99976 14.9896 7.99976Z"
      fill="#18222F"
    />
  </svg>
);
