import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../../store/store';
import LabelValueRow from '../../../../components/LabelValueRow/LabelValueRow';

export const MyActivity: React.FC = (): JSX.Element => {
  const { user, userActivity } = useSelector((state: RootState) => state.auth);

  const defineActivityValues = () => {
    const active = user?.active ? 'Active' : 'Inactive';

    const totalAnalyzed =
      userActivity?.totalEmailsAnalyzed || 'No messages analyzed';

    const totalSimulated =
      userActivity?.totalEmailsSimulated || 'No messages simulated';

    const lastAnalyzedAt = userActivity?.lastEmailAnalyzedDate;

    const lastSimulatedAt = userActivity?.lastSimulationDate;

    return {
      active,
      totalAnalyzed,
      totalSimulated,
      lastAnalyzedAt,
      lastSimulatedAt,
    };
  };

  const {
    active,
    totalAnalyzed,
    totalSimulated,
    lastAnalyzedAt,
    lastSimulatedAt,
  } = defineActivityValues();

  const attrs = {
    wrapper: {
      className: 'my-activity',
    },

    header: {
      className: 'my-activity-header',
    },

    body: {
      wrapper: {
        className: 'my-activity__body',
      },

      rows: {
        Status: {
          label: 'Status',
          value: active,
          coloredValue: true,
        },

        MemberSince: {
          label: 'Member Since',
          value: moment(user?.createdAt).format('MMM D, YYYY'),
        },

        TotalAnalyzed: {
          label: 'Total Emails Analyzed',
          value: `${totalAnalyzed}`,
        },

        TotalSimulated: {
          label: 'Total Emails Simulated',
          value: `${totalSimulated}`,
        },

        LastAnalyzed: {
          label: 'Last Email Analyzed',
          value: lastAnalyzedAt?.length
            ? moment(lastAnalyzedAt).fromNow()
            : 'No messages analyzed',
        },

        LastSimulated: {
          label: 'Last Email Simulated',
          value: lastSimulatedAt?.length
            ? moment(lastSimulatedAt).fromNow()
            : 'No messages simulated',
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header}>Activity</div>
      <div {...attrs.body.wrapper}>
        <LabelValueRow {...attrs.body.rows.Status} />
        <LabelValueRow {...attrs.body.rows.MemberSince} />
        <LabelValueRow {...attrs.body.rows.TotalAnalyzed} />
        <LabelValueRow {...attrs.body.rows.TotalSimulated} />
        <LabelValueRow {...attrs.body.rows.LastAnalyzed} />
        <LabelValueRow {...attrs.body.rows.LastSimulated} />
      </div>
    </div>
  );
};
