import React from 'react';

export const WebhookSettingsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.00065 1.33374C11.6825 1.33374 14.6673 4.31851 14.6673 8.00041C14.6673 11.6823 11.6825 14.6671 8.00065 14.6671C4.31875 14.6671 1.33398 11.6823 1.33398 8.00041C1.33398 4.31851 4.31875 1.33374 8.00065 1.33374ZM8.52087 4.97972L8.46479 4.93131C8.2908 4.80221 8.05199 4.80061 7.8764 4.92652L7.81376 4.97972L7.76535 5.0358C7.63625 5.20979 7.63466 5.4486 7.76057 5.62419L7.81376 5.68682L9.62685 7.49994H5.16685L5.099 7.5045C4.87714 7.5346 4.70151 7.71023 4.67141 7.93209L4.66685 7.99994L4.67141 8.06778C4.70151 8.28965 4.87714 8.46527 5.099 8.49537L5.16685 8.49994H9.62685L7.81336 10.3135L7.76494 10.3696C7.61967 10.5653 7.63575 10.8431 7.81323 11.0206C7.9907 11.1982 8.26848 11.2144 8.46425 11.0692L8.52033 11.0208L11.188 8.35411L11.2364 8.29803C11.3656 8.12404 11.3672 7.88519 11.2413 7.70957L11.1881 7.64693L8.52087 4.97972L8.46479 4.93131L8.52087 4.97972Z"
      fill="#4D4558"
    />
  </svg>
);
