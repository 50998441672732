import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Input, Button, Modal } from '../../../../components';
import { Member, useAddTeamForm } from '../hooks/useAddTeamForm';
import { fieldIconTeam } from '../../icons/index';
import { photo } from '../../../Users/icons';
import { resetTeamsErrors } from '../../../../store/teams/teams.actions';
import {
  ButtonType,
  ButtonVariantType,
} from '../../../../components/Button/Button';
import { EditMembers, EditMembersCaseType } from '../editMembers/EditMembers';

type CreateTeamProps = {
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  handleUploadAvatar: (e: ChangeEvent<HTMLInputElement>) => void;
  saveCroppedAvatar: (image: string) => void;
  croppedAvatar: string;
  isUploadAvatarOpen: boolean;
};

export const CreateTeam: React.FC<CreateTeamProps> = ({
  open,
  onClose,
  isLoading,
  handleUploadAvatar,
  saveCroppedAvatar,
  croppedAvatar,
  isUploadAvatarOpen,
}: CreateTeamProps): JSX.Element => {
  const { errors: createTeamFail, success } = useSelector(
    (state: RootState) => state.teams
  );
  const [arrayOfMembers, setArrayOfMembers] = useState<Member[]>([]);
  const [isNoAddedMembers, setIsNoAddedMembers] = useState(false);
  const [noTeamLeadError, setNoTeamLeadError] = useState(false);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    errors,
    handleChange,
    handleSubmit,
    values,
    touched,
    handleCancel,
  } = useAddTeamForm(onClose, croppedAvatar, arrayOfMembers);

  const createAndClose = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmit(e);
    if (!arrayOfMembers?.length) {
      setIsNoAddedMembers(true);
    }
  };

  const cancelAndClose = () => {
    saveCroppedAvatar('');
    setArrayOfMembers([]);
    setIsNoAddedMembers(false);
    handleCancel();
  };

  const clickGotIt = () => {
    handleCancel();
    dispatch(resetTeamsErrors());
    setTimeout(() => {
      setSuccessModalOpen(false);
    }, 200);
  };

  const error = () => {
    let currentError;
    if (touched.teamName) currentError = errors.teamName;
    if (createTeamFail.createTeam) currentError = 'Team name already exists';
    if (isNoAddedMembers) currentError = 'Add members to create a new team';
    return currentError;
  };

  useEffect(() => {
    if (
      arrayOfMembers.length &&
      !arrayOfMembers.some((el) => el.teamMemberRole === 'teamLeader')
    ) {
      setNoTeamLeadError(true);
    } else if (
      !arrayOfMembers.length ||
      arrayOfMembers.some((el) => el.teamMemberRole === 'teamLeader')
    ) {
      setNoTeamLeadError(false);
    }
  }, [arrayOfMembers]);

  useEffect(() => {
    if (createTeamFail.createTeam) {
      dispatch(resetTeamsErrors());
    }
    if (isNoAddedMembers) {
      setIsNoAddedMembers(false);
    } // eslint-disable-next-line
  }, [values.teamName, arrayOfMembers?.length]);

  useEffect(() => {
    if (success.createTeam) {
      setTimeout(() => {
        saveCroppedAvatar('');
        setArrayOfMembers([]);
        setSuccessModalOpen(true);
      }, 100);
    } // eslint-disable-next-line
  }, [isLoading, success.createTeam]);

  const attrs = {
    modal: {
      title: !isSuccessModalOpen ? 'Create team' : '',
      open,
      onClose: !isSuccessModalOpen ? cancelAndClose : clickGotIt,
      opacity: isUploadAvatarOpen ? '0' : '1',
    },

    successModalBody: {
      className: 'create-new-team__success-modal',
    },

    titleError: {
      className: 'teams-add__members-body__title-error',
    },

    avatar: {
      className: 'add-user__avatar',
      onUpload: handleUploadAvatar,
      image: croppedAvatar || photo,
      hasUpload: true,
      style: { width: '5rem', height: '5rem' },
      inModal: true,
    },

    input: {
      error: error(),
      onChange: handleChange,
      value: values.teamName,
      name: 'teamName',
      icon: fieldIconTeam,
      placeholder: 'Enter team’s name',
      className: error()
        ? 'error create-new-team__input'
        : 'create-new-team__input',
      label: 'Team’s name',
      required: true,
    },

    AddMembers: {
      onClose,
      arrayOfMembers,
      setArrayOfMembers,
      currentCase: 'adding' as EditMembersCaseType,
    },

    buttons: {
      createTeam: {
        type: 'submit' as ButtonType,
        className: 'create-new-team__btn',
        variant: 'common' as ButtonVariantType,
        disabled: noTeamLeadError || !arrayOfMembers.length,
        isLoading,
      },

      gotIt: {
        type: 'submit' as ButtonType,
        className: 'create-new-team__success-modal__button',
        variant: 'common' as ButtonVariantType,
        onClick: clickGotIt,
      },
    },

    form: {
      onSubmit: createAndClose,
    },

    modalBodyWrapper: {
      className: 'create-new-team',
    },
  };

  const noTeamLeaderMessage = noTeamLeadError ? (
    <div {...attrs.titleError}>
      No Team Leader has been assigned, please select one to continue
    </div>
  ) : null;

  // const avatar = croppedAvatar ? <Avatar {...attrs.avatar} /> : null;

  // const avatarUploadClip = !croppedAvatar ? (
  //   <AvatarUploadClip onUpload={handleUploadAvatar} />
  // ) : null;

  const conditionalModalBody = !isSuccessModalOpen ? (
    <div {...attrs.modalBodyWrapper}>
      {noTeamLeaderMessage}
      {/* {avatar} */}
      <form {...attrs.form}>
        <div>
          <Input {...attrs.input} />
          {/* {avatarUploadClip} */}
        </div>
        <EditMembers {...attrs.AddMembers} />
        <Button {...attrs.buttons.createTeam}>Create Team</Button>
      </form>
    </div>
  ) : (
    <div {...attrs.successModalBody}>
      Team <span>{values.teamName}</span> was created, let’s roll!
      <Button {...attrs.buttons.gotIt}>Got It</Button>
    </div>
  );

  return <Modal {...attrs.modal}>{conditionalModalBody}</Modal>;
};
