import React from 'react';

type EditIconProps = {
  onClick?: () => void;
  className?: string | undefined;
};

export const EditIcon: React.FC<EditIconProps> = ({
  onClick,
  className,
}: EditIconProps): JSX.Element => {
  return (
    <div className={className}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={onClick}
      >
        <path
          d="M13.9676 2.53211C13.0359 1.60042 11.5253 1.60047 10.5937 2.53221L2.62703 10.4998C2.35614 10.7707 2.16574 11.1115 2.07701 11.4842L1.34661 14.5518C1.3064 14.7207 1.35669 14.8984 1.47946 15.0212C1.60224 15.144 1.77992 15.1943 1.94883 15.154L5.01633 14.4237C5.38913 14.3349 5.72998 14.1444 6.00095 13.8735L13.9677 5.90578C14.8992 4.97412 14.8992 3.46371 13.9676 2.53211ZM11.3009 3.23927C11.842 2.6981 12.7193 2.69807 13.2605 3.23921C13.8016 3.78031 13.8016 4.65759 13.2605 5.19872L12.6663 5.79301L10.7067 3.83345L11.3009 3.23927ZM9.99968 4.5406L11.9592 6.50016L5.2938 13.1664C5.1537 13.3065 4.97746 13.405 4.78471 13.4509L2.50761 13.9931L3.04981 11.7158C3.09569 11.5231 3.19413 11.347 3.33418 11.2069L9.99968 4.5406Z"
          fill="#646C83"
        />
      </svg>
    </div>
  );
};
