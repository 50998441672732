import React from 'react';

export const ReassignAccountIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M4.19591 7.80474C4.45626 8.06509 4.87837 8.06509 5.13872 7.80474C5.39907 7.54439 5.39907 7.12228 5.13872 6.86193L3.61013 5.33333L12.6673 5.33333C13.0355 5.33333 13.334 5.03486 13.334 4.66667C13.334 4.29848 13.0355 4 12.6673 4L3.61013 4L5.13872 2.4714C5.39907 2.21105 5.39907 1.78895 5.13872 1.5286C4.87837 1.26825 4.45626 1.26825 4.19591 1.5286L1.52925 4.19526C1.2689 4.45561 1.2689 4.87772 1.52925 5.13807L4.19591 7.80474Z"
        fill="#070B12"
      />
      <path
        d="M3.33268 10.6667C2.96449 10.6667 2.66602 10.9652 2.66602 11.3334C2.66602 11.7016 2.96449 12.0001 3.33268 12.0001L12.3899 12.0001L10.8613 13.5287C10.6009 13.789 10.6009 14.2111 10.8613 14.4715C11.1216 14.7318 11.5437 14.7318 11.8041 14.4715L14.4708 11.8048C14.7311 11.5445 14.7311 11.1224 14.4708 10.862L11.8041 8.19534C11.5437 7.93499 11.1216 7.93499 10.8613 8.19534C10.6009 8.45569 10.6009 8.8778 10.8613 9.13815L12.3899 10.6667L3.33268 10.6667Z"
        fill="#646F87"
      />
    </svg>
  );
};
