import { Skeleton } from 'antd';
import React from 'react';
import { getRangeItemStyles } from './SentimentAnalysis.utils';

export interface SentimentAnalysisProps {
  isLoading?: boolean;
  value?: number;
}

export const SentimentAnalysis: React.FC<SentimentAnalysisProps> = ({
  isLoading = false,
  value,
}) => {
  const isPositive = (value as number) > 0;
  const isNegative = (value as number) < 0;
  const { negativeRangePart, positiveRangePart } = getRangeItemStyles(
    value || 0
  );

  const attrs = {
    container: {
      className: `sentiment-analysis${
        isPositive ? ' sentiment-analysis--positive' : ''
      }${isNegative ? ' sentiment-analysis--negative' : ''}`,
    },
    text: {
      className: 'sentiment-analysis__text',
    },
    range: {
      className: 'sentiment-analysis__range',
    },
    rangeDivider: {
      className: 'sentiment-analysis__range-divider',
    },
    negativeRangePart: {
      className:
        'sentiment-analysis__range-part sentiment-analysis__range-part--negative',
      style: negativeRangePart,
    },
    positiveRangePart: {
      className:
        'sentiment-analysis__range-part sentiment-analysis__range-part--positive',
      style: positiveRangePart,
    },
    rangeSkeleton: {
      active: true,
      paragraph: false,
      title: {
        width: 'auto',
        style: {
          width: '100%',
          height: 6,
          borderRadius: 4,
          margin: '7px 0',
        },
      },
    },
  };

  return (
    <div {...attrs.container}>
      <div {...attrs.text}>
        <span>Negative</span>
        <span>Positive</span>
      </div>
      {isLoading ? (
        <Skeleton {...attrs.rangeSkeleton} />
      ) : (
        <div {...attrs.range}>
          <div {...attrs.negativeRangePart} />
          <div {...attrs.rangeDivider} />
          <div {...attrs.positiveRangePart} />
        </div>
      )}
    </div>
  );
};
