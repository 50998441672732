import React from 'react';
import { Tooltip } from '../Tooltip/Tooltip';
import { useTooltip } from '../hooks/useTooltip';

type DeleteIconProps = {
  onClick: () => void;
  disabled?: boolean;
};

const noop = () => {};

export const DeleteIcon: React.FC<DeleteIconProps> = ({
  onClick,
  disabled = false,
}: DeleteIconProps): JSX.Element => {
  const { open, handleToggleTooltip } = useTooltip();
  const fillColor = !disabled && open ? '#FB7378' : '#898EA1';

  return (
    <div className="table__icon">
      <Tooltip
        open={open}
        text={!disabled ? 'Delete users' : 'You cannot delete owners'}
      />
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={disabled ? 'disabled table__icon-red' : 'table__icon-red'}
        onMouseEnter={handleToggleTooltip}
        onMouseLeave={handleToggleTooltip}
        onClick={disabled ? noop : onClick}
      >
        <rect
          x="8.80444"
          y="5.27393"
          width="6.4983"
          height="1"
          rx="0.5"
          fill={fillColor}
          style={{ transitionDuration: '0.3s', opacity: '0.8' }}
        />
        <rect
          x="5.12268"
          y="6.81836"
          width="13.7547"
          height="1"
          rx="0.5"
          fill={fillColor}
          style={{ transitionDuration: '0.3s', opacity: '0.8' }}
        />
        <rect
          x="6.73352"
          y="18.2168"
          width="10.6601"
          height="1"
          rx="0.5"
          fill={fillColor}
          style={{ transitionDuration: '0.3s', opacity: '0.8' }}
        />
        <rect
          x="7.73352"
          y="7.30371"
          width="11.9131"
          height="1"
          rx="0.5"
          transform="rotate(90 7.73352 7.30371)"
          fill={fillColor}
          style={{ transitionDuration: '0.3s', opacity: '0.8' }}
        />
        <rect
          x="6.99963"
          y="7"
          width="10"
          height="12"
          fill={fillColor}
          style={{ transitionDuration: '0.3s', opacity: '0.8' }}
        />
        <rect
          x="17.3937"
          y="7.30371"
          width="11.9131"
          height="1"
          rx="0.5"
          transform="rotate(90 17.3937 7.30371)"
          fill={fillColor}
          style={{ transitionDuration: '0.3s', opacity: '0.8' }}
        />
        <rect
          x="12.3141"
          y="10.4434"
          width="5.34235"
          height="0.5"
          rx="0.25"
          transform="rotate(90 12.3141 10.4434)"
          fill="#F9F9FC"
        />
        <rect
          x="14.8766"
          y="10.4434"
          width="5.34235"
          height="0.5"
          rx="0.25"
          transform="rotate(90 14.8766 10.4434)"
          fill="#F9F9FC"
        />
        <rect
          x="9.75159"
          y="10.4434"
          width="5.34235"
          height="0.5"
          rx="0.25"
          transform="rotate(90 9.75159 10.4434)"
          fill="#F9F9FC"
        />
      </svg>
    </div>
  );
};
