import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';

interface Props {
  duration: number;
  globalLoading: boolean;
  children?: JSX.Element | string;
}

const DealStatsSkeleton: React.FC<Props> = (props: Props) => {
  const { duration, globalLoading, children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, duration);
    return () => {
      clearTimeout(timer);
    };
  }, [globalLoading]); // eslint-disable-line

  const scheduleSkeleton = (
    width: number | string,
    height: number,
    marginTop: number,
    margin?: string
  ) => {
    return (
      <Skeleton
        loading={isLoading}
        active
        avatar={false}
        paragraph={false}
        title={{
          style: {
            width,
            height,
            borderRadius: 16,
            margin,
            marginTop,
            marginBottom: 6,
          },
        }}
      />
    );
  };

  const attrs = {
    wrapper: { className: 'personalQ__thirdColumn-skeleton__wrapper' },
    stats: {
      wrapper: { className: 'personalQ__thirdColumn-skeleton__wrapper-stats' },
      details: {
        className: 'personalQ__thirdColumn-skeleton__wrapper-stats-details',
      },
    },
    skeletons: {
      title: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 131,
            height: 17,
            borderRadius: 30,
            marginBottom: 14,
            marginTop: 0,
          },
        },
      },
      comingSoon: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 160,
            height: 18,
            borderRadius: 30,
            margin: 'auto',
          },
        },
      },
      winRateTitle: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 50,
            height: 16,
            borderRadius: 16,
            margin: 'auto',
          },
        },
      },
      winRateValue: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 50,
            height: 16,
            borderRadius: 16,
            margin: 'auto',
            marginTop: 10,
          },
        },
      },
      totalDealsTitle: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 69,
            height: 16,
            borderRadius: 16,
            margin: 'auto',
          },
        },
      },
      totalDealsValue: {
        loading: isLoading,
        active: true,
        avatar: false,
        paragraph: false,
        title: {
          style: {
            width: 69,
            height: 16,
            borderRadius: 16,
            margin: 'auto',
            marginTop: 10,
          },
        },
      },
    },
    graphs: {
      first: {
        graph: {
          className: 'personalQ__thirdColumn-skeleton__wrapper-first__schedule',
        },
        xAxis: {
          className:
            'personalQ__thirdColumn-skeleton__wrapper-first__schedule-row',
        },
        footer: {
          className:
            'personalQ__thirdColumn-skeleton__wrapper-first__schedule-last__child',
        },
      },
      second: {
        graph: {
          className:
            'personalQ__thirdColumn-skeleton__wrapper-second__schedule',
        },
        xAxis: {
          className:
            'personalQ__thirdColumn-skeleton__wrapper-second__schedule-row',
        },
      },
    },
  };

  return (
    <>
      {' '}
      {isLoading ? (
        <div {...attrs.wrapper}>
          <Skeleton {...attrs.skeletons.title} />
          <Skeleton {...attrs.skeletons.comingSoon} />
          <div {...attrs.stats.wrapper}>
            <div {...attrs.stats.details}>
              <Skeleton {...attrs.skeletons.winRateTitle} />
              <Skeleton {...attrs.skeletons.winRateValue} />
            </div>
            <div {...attrs.stats.details}>
              <Skeleton {...attrs.skeletons.totalDealsTitle} />
              <Skeleton {...attrs.skeletons.totalDealsValue} />
            </div>
          </div>
          <div {...attrs.graphs.first.graph}>
            {scheduleSkeleton(112, 16, 0)}
            {scheduleSkeleton(62, 16, 0)}
            {scheduleSkeleton(33, 16, 0)}
            {scheduleSkeleton('90%', 16, 4)}
            {scheduleSkeleton('90%', 16, 2)}
            {scheduleSkeleton('90%', 16, 2)}
            <div {...attrs.graphs.first.xAxis}>
              {scheduleSkeleton(24, 16, 0)}
              {scheduleSkeleton(24, 16, 0)}
              {scheduleSkeleton(24, 16, 0)}
              {scheduleSkeleton(24, 16, 0)}
              {scheduleSkeleton(24, 16, 0)}
              {scheduleSkeleton(31, 16, 0, '0px 2px 0px 5px')}
            </div>
            <div {...attrs.graphs.first.footer}>
              {scheduleSkeleton(71, 16, 5, '0px 15px 0px 0px')}
              {scheduleSkeleton(71, 16, 5, '0px 0px 0px -30px')}
            </div>
          </div>
          <div {...attrs.graphs.second.graph}>
            {scheduleSkeleton(75, 16, 0)}
            {scheduleSkeleton(33, 16, 4)}
            {scheduleSkeleton('90%', 16, 0)}
            {scheduleSkeleton('90%', 16, 0)}
            {scheduleSkeleton('90%', 16, 0)}
            {scheduleSkeleton('90%', 16, 0)}
            {scheduleSkeleton('90%', 16, 0)}
            <div {...attrs.graphs.second.xAxis}>
              {scheduleSkeleton(25, 16, 0)}
              {scheduleSkeleton(25, 16, 0)}
              {scheduleSkeleton(25, 16, 0)}
              {scheduleSkeleton(25, 16, 0)}
              {scheduleSkeleton(25, 16, 0)}
              {scheduleSkeleton(25, 16, 0, '0px 7px 0px 10px')}
            </div>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default DealStatsSkeleton;
