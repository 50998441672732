import React, { useEffect, useRef, useState } from 'react';
import { Table as AntTable } from 'antd';

import { useTable } from './table.utils';
import { DeleteUserModal } from '../DeleteUserModal/DeleteUserModal';
import { AssignRoleModal } from '../AssignRoleModal/AssignRoleModal';
import { ResentInviteModal } from '../ResentInviteModal/ResentInviteModal';
import { AddIcon } from '../../../../components/Icons/AddIcon';
import { DeleteIcon } from '../../../../components/Icons/DeleteIcon';
import { Loader } from '../../../../components/Loader/Loader';
import { UsersSeatsLeft } from './components/UsersSeatsLeft';

type TableProps = {
  onAddUser: () => void;
  onRenameUser: () => void;
};

export const Table: React.FC<TableProps> = ({
  onAddUser,
  onRenameUser,
}): JSX.Element => {
  const {
    cols,
    users,
    rowSelection,
    selectedRows,
    invokeModal,
    deleteModalOpen,
    closeDeleteModal,
    assignRoleModalOpen,
    currentUser,
    orgId,
    toggleAssignRoleModal,
    resentInvite,
    closeResentInviteModal,
    user,
    usersCount,
    maxUsersCount,
  } = useTable(onRenameUser);

  const deleteDisabled =
    !![currentUser, ...selectedRows].find((u) => u?.userId === user?.userId) ||
    !![currentUser, ...selectedRows].find(
      (u) => u?.role === 'owner' || u?.role === 'superAdmin'
    );

  const [shouldAnimate, setShouldAnimate] = useState(false);
  const usersCountRef = useRef(users.length);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (users.length > 1 && users.length - usersCountRef.current === 1) {
      const elementToScroll = document.getElementsByClassName(
        'ant-table-body'
      )[0];
      if (elementToScroll) {
        const { scrollHeight } = elementToScroll;
        elementToScroll.scrollTo({ top: scrollHeight, behavior: 'smooth' });
      }

      setShouldAnimate(true);

      timeout = setTimeout(() => {
        setShouldAnimate(false);
      }, 1500);
    }
    usersCountRef.current = users.length;
    return () => clearTimeout(timeout);
  }, [users]);

  const disableAddUsers = usersCount >= maxUsersCount && maxUsersCount !== -1;

  const addUser = () => {
    if (!disableAddUsers) {
      onAddUser();
    }
  };

  const attrs = {
    div: {
      table: {
        className: 'table',
      },
      tableConctol: {
        className: 'table__controls',
      },
    },
    deleteModal: {
      users: currentUser ? [currentUser] : selectedRows,
      orgId,
      open: deleteModalOpen,
      onClose: closeDeleteModal,
    },
    roleModal: {
      user: currentUser,
      open: assignRoleModalOpen,
      onClose: toggleAssignRoleModal,
    },
    resendInviteModal: {
      user: currentUser,
      open: resentInvite,
      onClose: closeResentInviteModal,
    },
    addUserIcon: {
      tooltipText: 'Add new user',
      onClick: addUser,
      disabled: disableAddUsers,
    },
    usersSeats: {
      usersCount,
      maxUsersCount,
    },
    deleteIcon: {
      disabled: deleteDisabled,
      onClick: invokeModal,
    },
  };

  const controlIcons = selectedRows.length ? (
    <DeleteIcon {...attrs.deleteIcon} />
  ) : null;

  const antTable = (
    <AntTable
      rowSelection={{ type: 'checkbox', ...rowSelection }}
      columns={cols}
      dataSource={users}
      rowClassName={(record, index) =>
        `${
          record.invitationSent === 'sent' ||
          record.invitationSent === 'expired'
            ? 'disabled'
            : ''
        } ${shouldAnimate && index === users.length - 1 ? 'animated-row' : ''}`
      }
      loading={{ indicator: <Loader />, spinning: !users.length }}
      pagination={false}
      scroll={{ y: 580 }}
      tableLayout="auto"
    />
  );

  return (
    <div {...attrs.div.table}>
      <DeleteUserModal {...attrs.deleteModal} />
      <AssignRoleModal {...attrs.roleModal} />
      <ResentInviteModal {...attrs.resendInviteModal} />
      <div {...attrs.div.tableConctol}>
        <AddIcon {...attrs.addUserIcon} />
        {controlIcons}
        <UsersSeatsLeft {...attrs.usersSeats} />
      </div>
      {antTable}
    </div>
  );
};
