import React, { useCallback, useRef } from 'react';
import { ChevronDownIcon } from '../../../../../Icons';
import { useClickOutside } from '../../../../../../hooks';

export interface SelectDropdownProps {
  isExpanded: boolean;
  onExpand: (isExpanded: boolean) => void;
  children?: React.ReactNode;
  isHidden?: boolean;
  title?: string;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  isExpanded,
  onExpand,
  children,
  isHidden = false,
  title,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const attrs = {
    container: {
      ref: containerRef,
      className: `select-dropdown${
        isExpanded ? ' select-dropdown--expanded' : ''
      }${isHidden ? ' select-dropdown--hidden' : ''}`,
    },
    button: {
      type: 'button' as const,
      className: 'select-dropdown__button',
      onClick: () => {
        onExpand(!isExpanded);
      },
    },
    buttonIcon: {
      className: 'select-dropdown__button-icon',
    },
    content: {
      className: 'select-dropdown__content',
    },
    title: {
      className: 'select-dropdown__title',
    },
    list: {
      role: 'listbox',
      className: 'select-dropdown__list',
    },
  };

  const handleClickOutside = useCallback(() => {
    onExpand(false);
  }, [onExpand]);

  useClickOutside(containerRef, handleClickOutside);

  return (
    <div {...attrs.container}>
      <button {...attrs.button}>
        <ChevronDownIcon {...attrs.buttonIcon} />
      </button>
      <div {...attrs.content}>
        {title ? <div {...attrs.title}>{title}</div> : null}
        <div {...attrs.list}>{children}</div>
      </div>
    </div>
  );
};
