import React, { useState, useEffect } from 'react';
import { Avatar } from '../../../../components';
import { ArrPeople } from '../../../../store/analytics/analytics.types';
import { ReactComponent as Left } from './icons/arrowLeft.svg';
import { ReactComponent as Right } from './icons/arrowRight.svg';

export type RelationsProps = {
  title: string;
  people: ArrPeople;
};

const Relations: React.FC<RelationsProps> = ({
  title,
  people,
}: RelationsProps): JSX.Element => {
  // eslint-disable-next-line
  const [movedAvatars, setMovedAvatars] = useState<any>();

  useEffect(() => {
    if (people) setMovedAvatars(people.map((el) => el.metadata));
  }, [people]);

  function swap(arr: string[], str: string) {
    if (str === 'left') {
      const popedFirstEl = arr.pop();
      setMovedAvatars([popedFirstEl, ...arr]);
    } else {
      const popedEl = arr.shift();
      setMovedAvatars([...arr, popedEl]);
    }
  }

  return (
    <div className="relations">
      {' '}
      <span className="relations__title">{title}</span>
      <div className="relations__avatarsblock">
        {movedAvatars ? (
          <>
            <Left
              className="relations__avatarsblock-arrows"
              onClick={() => {
                swap(movedAvatars, 'left');
              }}
            />
            {movedAvatars.map((
              el: any, // eslint-disable-line
              i: number
            ) => (
              <Avatar
                key={el.name}
                user={el}
                image={el.avatarSrc}
                className={`relations__avatarsblock-img${i + 1}`}
                style={{
                  minWidth: 0,
                  minHeight: 0,
                  width: '100%',
                  height: '100%',
                  transitionDuration: '0.07s',
                }}
              />
            ))}
            <Right
              className="relations__avatarsblock-arrows"
              onClick={() => {
                swap(movedAvatars, 'right');
              }}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <span className="relations__name">
        {movedAvatars ? movedAvatars[0].name : null}
      </span>
    </div>
  );
};

export default Relations;
