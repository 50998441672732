import React, { useState } from 'react';
import { useToggle } from '../../components';
import { useGetAdminSettingsData, useTrackPageView } from '../../hooks';
import { AddUser } from './components/AddUser/AddUser';
import { RenameUser } from './components/RenameUser/RenameUser';
import { Table } from './components/Table/Table';

export const Users: React.FC = (): JSX.Element => {
  const { open: renameUserOpen, toggle: toggleRenameUser } = useToggle(false);
  const [addUserOpen, setAddUserOpen] = useState(false);

  useGetAdminSettingsData();
  useTrackPageView();

  return (
    <div className="users">
      <Table
        onRenameUser={toggleRenameUser}
        onAddUser={() => setAddUserOpen(true)}
      />
      <AddUser onClose={() => setAddUserOpen(false)} open={addUserOpen} />
      <RenameUser onClose={toggleRenameUser} open={renameUserOpen} />
    </div>
  );
};
