import React from 'react';
import { Button, Modal } from '../../../components';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { Organization } from '../../../types';
import { timezoneIcon } from '../icons';
import { useOrgForm } from './useOrgForm';

type RenameUserProps = {
  open: boolean;
  onClose: () => void;
  org?: Organization | null;
};

export const EditOrgTimeZone: React.FC<RenameUserProps> = ({
  open,
  onClose,
  org,
}: RenameUserProps): JSX.Element => {
  const { setFieldValue, handleSubmit, values, initialValues } = useOrgForm({
    onClose,
    org,
  });

  const table = Array.from(new Array(27).keys()).map((el) => ({
    value: el - 12 >= 0 ? `GMT+${el - 12}:00` : `GMT${el - 12}:00`,
    label: el - 12 >= 0 ? `GMT+${el - 12}:00` : `GMT${el - 12}:00`,
  }));

  const currentValue = table.find((el) => el.value === values.timezone);

  const changeValue = (newValue: typeof table[0]) => {
    setFieldValue('timezone', newValue.value);
  };

  return (
    <Modal title="Edit time zone" open={open} onClose={onClose}>
      <div className={`edit-user edit-user-${open ? 'open' : 'closed'}`}>
        <form onSubmit={handleSubmit}>
          <CustomSelect
            label="time zone"
            icon={timezoneIcon}
            medium
            selectedItem={currentValue}
            items={table}
            onChange={changeValue}
            style={{
              padding: '25px 17px',
              background: '#f0f2f9',
              justifyContent: 'space-between',
              borderRadius: '1.5rem',
            }}
            margin="40px 0px 0px"
            padding="0px 12px 0px 0px"
            itemPadding="5px 0px 5px 145px"
          />
          <Button
            type="submit"
            className="edit-user__btn-timezone"
            variant="common"
            disabled={initialValues.timezone === values.timezone}
          >
            Save
          </Button>
        </form>
      </div>
    </Modal>
  );
};
