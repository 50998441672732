import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../store/store';
import {
  WebhookEventTypes,
  WebhookSettingsAuthType,
  WebhookSettingsToUpdate,
} from '../../../../../store/org/org.types';
import {
  updateWebhookEvents,
  updateWebhookSettings,
} from '../../../../../store/org/org.actions';

export type FieldName = 'type' | 'name' | 'value';

export type AuthParams = {
  type: WebhookSettingsAuthType;
  name: string;
  value: string;
};

type EditWebhookForm = {
  url: string;
  encryptKey: string;
  addParticipant: boolean;
  removeParticipant: boolean;
  newAnalysis: boolean;
  upperHandUpdate: boolean;
  hasAuth: boolean;
  hasEncryption: boolean;
};

// eslint-disable-next-line
export const useEditWebhook = (authParamsArr?: AuthParams[]) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const { webhook } = useSelector((state: RootState) => state.org);

  const dispatch = useDispatch();

  // console.log(authParamsArr);

  const defineEvents = (
    events: WebhookEventTypes[]
  ): {
    addParticipantEv: boolean;
    removeParticipantEv: boolean;
    newAnalysisEv: boolean;
    upperHandUpdateEv: boolean;
  } => {
    const addParticipantEv = events.includes(
      'NEW_TO_OR_CC_ADDED_BY_BUYER_SIDE' as WebhookEventTypes
    );

    const removeParticipantEv = events.includes(
      'TO_OR_CC_REMOVED_BY_BUYER_SIDE' as WebhookEventTypes
    );

    const newAnalysisEv = events.includes(
      'NEW_ANALYSIS_RESULT' as WebhookEventTypes
    );

    const upperHandUpdateEv = events.includes(
      'UH_SWITCH_SIDE' as WebhookEventTypes
    );

    return {
      addParticipantEv,
      removeParticipantEv,
      newAnalysisEv,
      upperHandUpdateEv,
    };
  };

  const {
    addParticipantEv,
    removeParticipantEv,
    newAnalysisEv,
    upperHandUpdateEv,
  } = defineEvents(webhook.eventsInfo.events);

  const handleSubmit = (values: EditWebhookForm) => {
    const enabledEventsToSubmit = [
      `${
        values.addParticipant
          ? ('NEW_TO_OR_CC_ADDED_BY_BUYER_SIDE' as WebhookEventTypes)
          : ''
      }`,
      `${
        values.removeParticipant
          ? ('TO_OR_CC_REMOVED_BY_BUYER_SIDE' as WebhookEventTypes)
          : ''
      }`,
      `${
        values.newAnalysis ? ('NEW_ANALYSIS_RESULT' as WebhookEventTypes) : ''
      }`,
      `${
        values.upperHandUpdate ? ('UH_SWITCH_SIDE' as WebhookEventTypes) : ''
      }`,
    ];

    const enabledEventsToUpdate: any = enabledEventsToSubmit.filter(
      (el) => el.length > 0
    );

    const webhookSettingsBody: WebhookSettingsToUpdate = {
      url: values.url,
      keyId: webhook?.settings?.keyId,
      hasAuth: values.hasAuth,
      hasEncryption: values.hasEncryption,
      auth: authParamsArr,
    };

    if (user) {
      dispatch(
        updateWebhookEvents(user.orgId, user.userId, enabledEventsToUpdate)
      );

      dispatch(updateWebhookSettings(user.orgId, webhookSettingsBody));
    }
  };

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .url('Please enter a valid URL')
      .required('*Required field'),
  });

  const formik = useFormik<EditWebhookForm>({
    initialValues: {
      url: webhook?.settings?.url || '',
      encryptKey: webhook?.settings?.key || '',
      addParticipant: addParticipantEv,
      removeParticipant: removeParticipantEv,
      newAnalysis: newAnalysisEv,
      upperHandUpdate: upperHandUpdateEv,
      hasAuth: webhook?.settings?.hasAuth,
      hasEncryption: webhook?.settings?.hasEncryption,
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    formik.resetForm();
  };

  const { initialValues } = formik;

  return {
    ...formik,
    handleCancel,
    initialValues,
    defineEvents,
  };
};
