import React from 'react';
import { OutgoingEmailActivity } from '../../../../../store/charts/charts.types';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { QActivitySkeleton } from '../../skeletons/QActivitySkeleton';
import { HeatMap, HeatMapType } from './heatMap/HeatMap';

type Props = {
  data?: OutgoingEmailActivity | null;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
  lastDays?: number;
};

export const OutgoingActivityHeatMap: React.FC<Props> = ({
  data,
  isLoading,
  noData,
  error,
  lastDays,
}) => {
  const attrs = {
    Skeleton: {
      isLoading,
    },

    ChartWrapper: {
      title: 'Outgoing Sales Email Activity',
      subtitle: `Frequency by time of day, weekly view, last ${
        lastDays ? lastDays - 1 : ''
      } days`,
      tooltipText: `This shows the frequency of your weekly 
      outgoing deal-related messages from people outside of
      your organization, per time of day.`,
      isNoData: noData || error,
    },

    legend: {
      wrapper: {
        className: 'charts__outgoing-activity-legend',
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      colorScheme: {
        wrapper: {
          className: 'charts__outgoing-activity-legend__color-scheme',
        },
        left: {
          className: 'charts__outgoing-activity-legend__color-scheme-left',
        },
        right: {
          className: 'charts__outgoing-activity-legend__color-scheme-right',
        },
      },
    },

    HeatMap: {
      data,
      type: 'outgoing' as HeatMapType,
      noData,
      error,
    },
  };

  const legend = (
    <div {...attrs.legend.wrapper}>
      Low
      <div {...attrs.legend.colorScheme.wrapper}>
        <div {...attrs.legend.colorScheme.left} />
        <div {...attrs.legend.colorScheme.right} />
      </div>
      High
    </div>
  );

  return (
    <QActivitySkeleton {...attrs.Skeleton}>
      <ChartWrapper {...attrs.ChartWrapper}>
        <>
          {legend}
          <HeatMap {...attrs.HeatMap} />
        </>
      </ChartWrapper>
    </QActivitySkeleton>
  );
};
