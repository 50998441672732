import React from 'react';

export const EnvelopeIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1665 3.3335C2.78579 3.3335 1.6665 4.45278 1.6665 5.8335V14.1668C1.6665 15.5475 2.78579 16.6668 4.1665 16.6668H15.8332C17.2139 16.6668 18.3332 15.5475 18.3332 14.1668V5.8335C18.3332 4.45278 17.2139 3.3335 15.8332 3.3335H4.1665ZM5.46209 6.80679C5.07915 6.55149 4.56176 6.65497 4.30646 7.03791C4.05117 7.42085 4.15465 7.93824 4.53759 8.19354L8.61309 10.9105C9.45284 11.4704 10.5468 11.4704 11.3866 10.9105L15.4621 8.19354C15.845 7.93824 15.9485 7.42085 15.6932 7.03791C15.4379 6.65497 14.9205 6.55149 14.5376 6.80679L10.4621 9.52379C10.1822 9.7104 9.8175 9.7104 9.53759 9.52379L5.46209 6.80679Z"
      fill="white"
    />
  </svg>
);
