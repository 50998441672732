import { useFormik, FormikErrors, FormikTouched } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import {
  addUser,
  clearErrors,
  invokeAddUserModal,
} from '../../../store/users/users.actions';
import { RootState } from '../../../store/store';

interface AddUserForm {
  firstName: string;
  lastName: string;
  primaryEmail: string;
  organizationalUnit: string;
  phoneNumber: string | undefined;
}

interface UseForm {
  values: AddUserForm;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  errors: FormikErrors<AddUserForm>;
  touched: FormikTouched<AddUserForm>;
  inviteSuccess: boolean;
  closeModal: () => void;
  handleCancel: () => void;
}

export const useForm = (onClose: () => void, croppedImage: string): UseForm => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const inviteSuccess = useSelector(
    (state: RootState) => state.users.modals.addUserModalOpen
  );

  const handleSubmit = (values: AddUserForm) => {
    dispatch(
      addUser({
        email: values.primaryEmail,
        firstName: values.firstName,
        lastName: values.lastName,
        inviteType: 'userInvite',
        name: `${values.firstName} ${values.lastName}`,
        orgId: user?.orgId || '',
        userId: user?.userId || '',
        avatar: croppedImage || undefined,
      })
    );
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/[a-zA-Z]/g)
      .required('*Required field'),
    lastName: Yup.string()
      .matches(/[a-zA-Z]/g)
      .required('*Required field'),
    primaryEmail: Yup.string()
      .email('Primary email must be a valid email address')
      .required('*Required field'),
  });

  const formik = useFormik<AddUserForm>({
    initialValues: {
      firstName: '',
      lastName: '',
      primaryEmail: '',
      organizationalUnit: '',
      phoneNumber: '',
    },
    onSubmit: handleSubmit,
    validationSchema,
  });

  const handleCancel = useCallback((): void => {
    formik.resetForm();
    onClose();
    dispatch(clearErrors());
  }, [dispatch, onClose]); //eslint-disable-line

  const closeModal = () => {
    dispatch(invokeAddUserModal(user?.orgId || ''));
  };

  useEffect(() => {
    if (inviteSuccess) handleCancel();
  }, [inviteSuccess, handleCancel]);

  return {
    values: formik.values,
    handleChange: formik.handleChange,
    handleSubmit: formik.handleSubmit,
    errors: formik.errors,
    touched: formik.touched,
    inviteSuccess,
    closeModal,
    handleCancel,
  };
};
