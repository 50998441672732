import React from 'react';

export const ForwardIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.66671 7.99992C2.66671 5.04891 5.03669 2.66659 7.94801 2.66659C9.7156 2.66659 11.2823 3.54309 12.2427 4.8951C12.3884 5.10022 12.6372 5.20584 12.8859 5.16819C13.1347 5.13054 13.3411 4.95603 13.4196 4.71698L13.9668 3.05021C14.0816 2.70039 13.8911 2.3237 13.5413 2.20886C13.1915 2.09401 12.8148 2.2845 12.7 2.63432L12.5201 3.18227C11.3334 2.03813 9.72296 1.33325 7.94801 1.33325C4.28938 1.33325 1.33337 4.32351 1.33337 7.99992C1.33337 11.6763 4.28938 14.6666 7.94801 14.6666C10.1686 14.6666 12.1318 13.5633 13.3297 11.8769C13.5429 11.5767 13.4724 11.1605 13.1722 10.9473C12.8721 10.7341 12.4559 10.8046 12.2427 11.1047C11.2823 12.4568 9.7156 13.3333 7.94801 13.3333C5.03669 13.3333 2.66671 10.9509 2.66671 7.99992Z"
      fill="white"
    />
    <path
      d="M6.58201 5.37739C6.83989 5.4762 7.01014 5.72376 7.01014 5.99992V9.59586C7.01014 9.96405 6.71167 10.2625 6.34348 10.2625C5.97529 10.2625 5.67681 9.96405 5.67681 9.59586V7.69609C5.43239 7.84306 5.11113 7.82097 4.88789 7.62046C4.61397 7.37442 4.59137 6.95292 4.8374 6.679L5.84751 5.55443C6.03204 5.34899 6.32414 5.27858 6.58201 5.37739Z"
      fill="white"
    />
    <path
      d="M7.97337 5.78993C8.06386 5.51728 8.31882 5.33325 8.6061 5.33325H10.6667C11.0349 5.33325 11.3334 5.63173 11.3334 5.99992C11.3334 6.36811 11.0349 6.66659 10.6667 6.66659H9.08727L9.01799 6.87533H9.63638C10.5674 6.87533 11.3334 7.6289 11.3334 8.57232C11.3334 9.50336 10.5798 10.2693 9.63638 10.2693H8.09431C7.72612 10.2693 7.42764 9.97079 7.42764 9.6026C7.42764 9.23441 7.72612 8.93594 8.09431 8.93594H9.63638C9.83778 8.93594 10 8.77259 10 8.57232C10 8.37095 9.83669 8.20866 9.63638 8.20866H8.09431C7.88016 8.20866 7.67906 8.10579 7.55373 7.93215C7.42841 7.75851 7.39412 7.53524 7.46158 7.332L7.97337 5.78993Z"
      fill="white"
    />
  </svg>
);
