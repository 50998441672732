import React, { useState } from 'react';
import { ButtonSize, Modal } from '../../../../components/SoundWave';

export interface ToggleFeatureModalProps {
  isOpened: boolean;
  feature: string | null;
  isFeatureActive: boolean;
  onClose: () => void;
  onAnimationEnd: () => void;
  onConfirm: () => Promise<void>;
}

export const ToggleFeatureModal: React.FC<ToggleFeatureModalProps> = ({
  isOpened,
  feature,
  isFeatureActive,
  onClose,
  onAnimationEnd,
  onConfirm,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const attrs = {
    modal: {
      isOpened,
      onClose,
      onAnimationEnd,
      width: 384,
      closeOnClickOutside: true,
      isConfirmation: true,
      className: 'apps__modal',
      headerProps: {},
      footerProps: {
        isLoading,
        cancelButtonText: 'Cancel',
        confirmButtonText: isFeatureActive ? 'Turn OFF' : 'Turn ON',
        buttonsSize: ButtonSize.XS,
        onConfirm: async () => {
          try {
            setIsLoading(true);
            await onConfirm();
            onClose();
          } catch (error) {
            onClose();
          } finally {
            setIsLoading(false);
          }
        },
      },
    },
    title: {
      className: 'apps__modal-title',
    },
  };

  return (
    <Modal {...attrs.modal}>
      <div {...attrs.title}>
        Are you sure you would like to turn the {feature}{' '}
        {isFeatureActive ? 'OFF' : 'ON'}?
      </div>
    </Modal>
  );
};
