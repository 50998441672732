import React from 'react';

interface Props {
  active: boolean;
  openTooltip?: (value: React.SetStateAction<boolean>) => void;
  closeTooltip?: (value: React.SetStateAction<boolean>) => void;
  className?: string;
  isDarker?: boolean;
}

export const TooltipIcon: React.FC<Props> = ({
  active,
  openTooltip,
  closeTooltip,
  className,
  isDarker,
}): JSX.Element => {
  const onOpen = () => {
    if (openTooltip) {
      openTooltip(true);
    }
  };

  const onClose = () => {
    if (closeTooltip) {
      closeTooltip(false);
    }
  };

  const color = isDarker ? '#223754' : '#646C83';

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14.4 14.4"
      fill={active ? color : '#FFFF'}
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
      className={className}
    >
      <path
        d="M13.5 7C13.5 10.5899 10.5899 13.5 7 13.5C3.41015 13.5 0.5 10.5899 0.5 7C0.5 3.41015 3.41015 0.5 7 0.5C10.5899 0.5 13.5 3.41015 13.5 7Z"
        stroke={color}
      />
      <path
        d="M7.52854 10.4626C7.52854 10.7732 7.27681 11.0249 6.96628 11.0249C6.65576 11.0249 6.40403 10.7732 6.40403 10.4626V6.29477C6.40403 5.98425 6.65576 5.73252 6.96628 5.73252C7.27681 5.73252 7.52854 5.98425 7.52854 6.29478V10.4626ZM6.33704 4.33047C6.33704 4.12949 6.39127 3.97477 6.49973 3.86631C6.61139 3.75785 6.7693 3.70361 6.97346 3.70361C7.17125 3.70361 7.32437 3.75785 7.43284 3.86631C7.54449 3.97477 7.60032 4.12949 7.60032 4.33047C7.60032 4.52187 7.54449 4.6734 7.43284 4.78506C7.32437 4.89352 7.17125 4.94775 6.97346 4.94775C6.7693 4.94775 6.61139 4.89352 6.49973 4.78506C6.39127 4.6734 6.33704 4.52187 6.33704 4.33047Z"
        fill={active ? '#FFFF' : color}
      />
    </svg>
  );
};
