import React from 'react';
import {
  Filter,
  FilterType,
  FilterValue,
  RangeFilterValue,
} from '../../../../../../types/relationshipFilters.types';
import { CheckboxCheckedIcon } from '../../../../../../components/Icons/CheckboxCheckedIcon';
import { CheckboxUncheckedIcon } from '../../../../../../components/Icons/CheckboxUncheckedIcon';
import { FilterSelect } from '../FilterSelect/FilterSelect';
import { DatePicker } from '../DatePicker/DatePicker';
import { FilterRange } from '../FilterRange/FilterRange';
import {
  isDateRangeFilterValue,
  isRangeFilterValue,
  getIsFilterChecked,
} from '../../../../../../store/relationships/relationships.utils';

export interface RelationshipFilterProps extends Filter<FilterValue> {
  onActiveChange: (filterId: string) => void;
  onChange: (filterId: string, value: FilterValue) => void;
}

export const RelationshipFilter: React.FC<RelationshipFilterProps> = ({
  id,
  value,
  defaultValue,
  label,
  type,
  selectOptions,
  isActive,
  isDisabled,
  maskType,
  onActiveChange,
  onChange,
}) => {
  const isChecked = getIsFilterChecked(value, defaultValue, isActive);

  const attrs = {
    container: {
      className: `relationship-filter${
        isActive && type !== FilterType.CHECKBOX
          ? ' relationship-filter--active'
          : ''
      }`,
    },
    button: {
      type: 'button' as const,
      className: 'relationship-filter__button',
      disabled: isDisabled,
      onClick: () => {
        onActiveChange(id);

        if (type === FilterType.CHECKBOX) {
          onChange(id, !value);
        }
      },
    },
    checkboxIcon: {
      className: 'relationship-filter__checkbox-icon',
    },
    badge: {
      className: 'relationship-filter__badge',
    },
    content: {
      className: 'relationship-filter__content',
    },
  };

  const renderFilterSelect = () => {
    if (type === FilterType.RANGE || !selectOptions) {
      return null;
    }

    const filterSelectProps = {
      selectedOption: selectOptions.find((option) =>
        isDateRangeFilterValue(option.value) && isDateRangeFilterValue(value)
          ? option.value.selectValue === value.selectValue
          : option.value === value
      ),
      options: selectOptions,
      onSelect: (option: FilterValue) => {
        onChange(id, option);
      },
    };

    return <FilterSelect {...filterSelectProps} />;
  };

  const renderDatePicker = () => {
    if (
      type !== FilterType.DATE_RANGE ||
      !isDateRangeFilterValue(value) ||
      (isDateRangeFilterValue(value) && value.selectValue !== 'custom')
    ) {
      return null;
    }

    const datePickerProps = {
      startDate: value.startDate,
      endDate: value.endDate,
      onChange: (startDate: string | null, endDate: string | null) => {
        onChange(id, { ...value, startDate, endDate });
      },
    };

    return <DatePicker {...datePickerProps} />;
  };

  const renderFilterRange = () => {
    if (
      type !== FilterType.RANGE ||
      !isRangeFilterValue(value) ||
      !isRangeFilterValue(defaultValue)
    ) {
      return null;
    }

    const filterProps = {
      value,
      maskType,
      min: defaultValue.min,
      max: defaultValue.max,
      onChange: (rangeValue: RangeFilterValue) => {
        onChange(id, rangeValue);
      },
    };

    return <FilterRange {...filterProps} />;
  };

  return (
    <div {...attrs.container}>
      <button {...attrs.button}>
        {isChecked ? (
          <CheckboxCheckedIcon {...attrs.checkboxIcon} />
        ) : (
          <CheckboxUncheckedIcon {...attrs.checkboxIcon} />
        )}
        <span>{label}</span>
        {isDisabled ? <span {...attrs.badge}>Coming Soon</span> : null}
      </button>
      {type !== FilterType.CHECKBOX ? (
        <div {...attrs.content}>
          {renderFilterSelect()}
          {renderDatePicker()}
          {renderFilterRange()}
        </div>
      ) : null}
    </div>
  );
};
