import React from 'react';

export const DisconnectIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_10729_1144)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7426 3.75729C13.003 4.01764 13.003 4.43975 12.7426 4.7001L9.44281 7.99993L12.7426 11.2998C13.003 11.5601 13.003 11.9822 12.7426 12.2426C12.4823 12.5029 12.0602 12.5029 11.7998 12.2426L8.5 8.94274L5.20017 12.2426C4.93982 12.5029 4.51771 12.5029 4.25736 12.2426C3.99701 11.9822 3.99701 11.5601 4.25736 11.2998L7.55719 7.99993L4.25736 4.7001C3.99701 4.43975 3.99701 4.01764 4.25736 3.75729C4.51771 3.49694 4.93982 3.49694 5.20017 3.75729L8.5 7.05712L11.7998 3.75729C12.0602 3.49694 12.4823 3.49694 12.7426 3.75729Z"
        fill="#070B12"
      />
    </g>
    <defs>
      <clipPath id="clip0_10729_1144">
        <rect width="16" height="16" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
