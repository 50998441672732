import React from 'react';
import { Button, Input, Modal } from '../../../../components';
import { useEditUserInfo } from '../../hooks/useEditUserInfo';
import { work } from '../../icons';

type RenameUserProps = {
  open: boolean;
  onClose: () => void;
};

export const EditUserJobTitle: React.FC<RenameUserProps> = ({
  open,
  onClose,
}: RenameUserProps): JSX.Element => {
  const {
    values,
    handleChange,
    handleSubmit,
    touched,
    errors,
    initialValues,
  } = useEditUserInfo(onClose);

  return (
    <Modal title="Edit job title" open={open} onClose={onClose}>
      <div className={`edit-user edit-user-${open ? 'open' : 'closed'}`}>
        <form onSubmit={handleSubmit}>
          <Input
            label="job title"
            value={values.title}
            onChange={handleChange}
            name="title"
            icon={work}
            className="edit-user__input"
            error={touched.title ? errors.title : ''}
          />
          <Button
            type="submit"
            className={touched.title ? 'err edit-user__btn' : 'edit-user__btn'}
            variant="common"
            disabled={
              initialValues.title === values.title || !values.title?.length
            }
          >
            Save
          </Button>
        </form>
      </div>
    </Modal>
  );
};
