import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getUserActivity,
  getUserConnections,
  getUserData,
} from '../../store/auth/auth.thunks';
import { MyProfile } from './components/myProfile/MyProfile';
import { MyOrgAndTeams } from './components/myOrgAndTeams/MyOrgAndTeams';
import { MyActivity } from './components/myActivity/MyActivity';
import {
  getTeams,
  getTeamsForDealmaker,
} from '../../store/teams/teams.actions';
import { useTrackPageView } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { IntegrationCard } from './components/IntegrationCard/IntegrationCard';
import { useIntegrations } from './hooks/useIntegrations';
import { RevokeGoogleScopesModal } from './components/RevokeGoogleScopesModal/RevokeGoogleScopesModal';

export const Account: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.auth.user);

  const integrationsSectionRef = useRef<HTMLDivElement>(null);

  const integrations = useIntegrations();

  const { search } = useLocation();

  useEffect(() => {
    if (user) {
      dispatch(getUserData());
      dispatch(getUserActivity());

      if (user.role === 'dealmaker') {
        dispatch(getTeamsForDealmaker(user.orgId, user.userId));
      } else {
        dispatch(getTeams(user.orgId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const selectedIntegrations = new URLSearchParams(search).get(
      'selectedIntegrations'
    );

    if (selectedIntegrations && integrationsSectionRef.current) {
      integrationsSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [search]);

  useEffect(() => {
    const request = dispatch(getUserConnections());

    return () => {
      request.abort();
    };
  }, [dispatch]);

  useTrackPageView();

  const attrs = {
    wrapper: {
      className: 'account',
      id: 'account',
    },
    sectionOld: {
      className: 'account__section account__section--p16',
    },
    sectionNoPadding: {
      className: 'account__section account__section--no-padding',
    },
    sectionHeader: {
      className: 'account__section-header',
    },
    integrationsSection: {
      ref: integrationsSectionRef,
      className: 'account__section',
    },
    integrations: {
      className: 'account__integrations',
    },
  };

  const renderIntegrationCards = () =>
    integrations.map((integrationCardProps) => {
      const props = {
        ...integrationCardProps,
        key: integrationCardProps.id,
      };

      return <IntegrationCard {...props} />;
    });

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.sectionOld}>
        <MyProfile />
      </div>
      <div {...attrs.sectionNoPadding}>
        <MyOrgAndTeams />
      </div>
      <div {...attrs.sectionOld}>
        <MyActivity />
      </div>
      <div {...attrs.integrationsSection}>
        <div {...attrs.sectionHeader}>Integrations</div>
        <div {...attrs.integrations}>{renderIntegrationCards()}</div>
      </div>
      <RevokeGoogleScopesModal />
    </div>
  );
};
