import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useClickOutside, useMixpanel } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import { FilterIcon } from './icons/FilterIcon';
import { FilterAppliedIcon } from './icons/FilterAppliedIcon';
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from '../../../../components/SoundWave';
import {
  Filter,
  FilterValue,
} from '../../../../types/meetingRecordsFilters.types';
import {
  appliedFiltersSelector,
  groupedFiltersSelector,
  unsavedFiltersSelector,
  updatedFiltersSelector,
} from '../../../../store/meetingRecords/meetingRecords.selectors';
import { getRelationshipsFiltersQueryParams } from '../../../../store/meetingRecords/meetingRecords.utils';
import {
  applyFilters,
  changeFilterValue,
  clearFilters,
  resetUnsavedFilters,
  toggleFilterActiveState,
  updateFiltersQueryParams,
} from '../../../../store/meetingRecords/meetingRecords.slice';
import { MeetingRecordsFilter } from './components/MeetingRecordsFilter/MeetingRecordsFilter';
import { checkFeatureAvailability } from '../myMeetingsTable/utils/checkForFeature';
import { Features } from '../../../../types';

export const MeetingRecordsFilters: React.FC = () => {
  const { user } = useAppSelector((state) => state.auth);

  const isSoundWaveEnabled = checkFeatureAvailability(user, Features.SOUNDWAVE);

  const dispatch = useAppDispatch();

  const groupedFilters = useAppSelector(groupedFiltersSelector);
  const updatedFilters = useAppSelector(updatedFiltersSelector);
  const unsavedFilters = useAppSelector(unsavedFiltersSelector);
  const appliedFilters = useAppSelector(appliedFiltersSelector);

  const { trackEvent } = useMixpanel();

  const [isOpened, setIsOpened] = useState(false);

  const [
    shouldUpdateFiltersQueryParams,
    setShouldUpdateFiltersQueryParams,
  ] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const counterValue = isOpened ? updatedFilters.length : appliedFilters.length;

  const attrs = {
    container: {
      className: `${
        !isSoundWaveEnabled ? 'disabled ' : ''
      }meeting-records-filters`,
      ref: wrapperRef,
    },
    toggleButton: {
      size: ButtonSize.S,
      variant: 'secondary' as ButtonVariant,
      onClick: () => {
        setIsOpened(!isOpened);
      },
    },
    appliedFiltersCounter: {
      className: 'meeting-records-filters__applied-filters-counter',
    },
    popup: {
      className: `meeting-records-filters__popup${
        isOpened ? ' meeting-records-filters__popup--opened' : ''
      }`,
    },
    popupHeader: {
      className: 'meeting-records-filters__popup-header',
    },
    popupContent: {
      className: 'meeting-records-filters__popup-content',
    },
    popupFooter: {
      className: 'meeting-records-filters__popup-footer',
    },
    clearButton: {
      size: ButtonSize.XS,
      disabled: !updatedFilters.length,
      secondary: true,
      onClick: () => {
        trackEvent('clearFilters');
        dispatch(clearFilters());
        setIsOpened(false);

        if (appliedFilters.length) {
          setShouldUpdateFiltersQueryParams(true);
        }
      },
    },
    applyButton: {
      size: ButtonSize.XS,
      disabled: !unsavedFilters.length,
      onClick: () => {
        trackEvent('applyFilters');
        dispatch(applyFilters());
        setShouldUpdateFiltersQueryParams(true);
        setIsOpened(false);
      },
    },
  };

  const renderFilter = (filter: Filter<FilterValue>) => {
    const filterProps = {
      ...filter,
      key: filter.id,
      onActiveChange: (filterId: string) => {
        dispatch(toggleFilterActiveState(filterId));
      },
      onChange: (id: string, value: FilterValue) => {
        dispatch(changeFilterValue({ id, value }));
      },
    };

    return <MeetingRecordsFilter {...filterProps} />;
  };

  const renderFiltersGroup = (
    filters: Filter<FilterValue>[],
    index: number
  ) => {
    const props = {
      key: index,
      className: 'meeting-records-filters__filters-group',
    };

    return <div {...props}>{filters.map(renderFilter)}</div>;
  };

  useClickOutside(wrapperRef, () => {
    setIsOpened(false);
  });

  useEffect(() => {
    if (shouldUpdateFiltersQueryParams) {
      dispatch(
        updateFiltersQueryParams(
          getRelationshipsFiltersQueryParams(appliedFilters)
        )
      );
      setShouldUpdateFiltersQueryParams(false);
    }
  }, [appliedFilters, dispatch, shouldUpdateFiltersQueryParams]);

  useEffect(() => {
    if (!isOpened) {
      dispatch(resetUnsavedFilters());
    }
  }, [dispatch, isOpened]);

  const popupSection = isSoundWaveEnabled ? (
    <div {...attrs.popup}>
      <div {...attrs.popupHeader}>Select Filters</div>
      <div {...attrs.popupContent}>
        {groupedFilters.map(renderFiltersGroup)}
      </div>
      <div {...attrs.popupFooter}>
        <Button {...attrs.clearButton}>
          <span>Clear</span>
        </Button>
        <Button {...attrs.applyButton}>
          <span>Apply</span>
        </Button>
      </div>
    </div>
  ) : null;

  return (
    <div {...attrs.container}>
      <Button {...attrs.toggleButton}>
        {appliedFilters.length ? <FilterAppliedIcon /> : <FilterIcon />}
        <span>Filters</span>
        {counterValue ? (
          <span {...attrs.appliedFiltersCounter}>{counterValue}</span>
        ) : null}
      </Button>
      <div {...attrs.popup}>
        <div {...attrs.popupHeader}>Select Filters</div>
        <div {...attrs.popupContent}>
          {groupedFilters.map(renderFiltersGroup)}
        </div>
        <div {...attrs.popupFooter}>
          <Button {...attrs.clearButton}>
            <span>Clear</span>
          </Button>
          <Button {...attrs.applyButton}>
            <span>Apply</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
