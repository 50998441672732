import React, { useMemo, useState } from 'react';
import { Scrollbar } from '../../../../../../../../components';
import { ChevronDownIcon } from '../../../../../../../../components/SoundWave';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/hooks';
import {
  selectMember,
  unselectMember,
} from '../../../../../../../../store/relationshipDetails/relationshipDetails.slice';
import { openAddMemberModal } from '../../../../../../../../store/relationshipFlows/relationshipFlows.slice';
import { Contact } from '../../../../../../../../types/contact.types';
import { RelationshipMemberSide } from '../../../../../../../../types/relationshipMember.types';
import { TeamMember } from '../../../TeamMember/TeamMember';
import { PlusIcon } from './icons/PlusIcon';

export interface TeamMembersGroupProps {
  title?: string;
  showAddMemberIfEmpty?: boolean;
  grow?: boolean;
  side?: RelationshipMemberSide;
  contacts: Contact[];
}

const MAX_CONTACTS_TO_SHOW = 5;

export const TeamMembersGroup: React.FC<TeamMembersGroupProps> = ({
  title,
  showAddMemberIfEmpty = false,
  grow = false,
  side,
  contacts,
}) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector((state) => state.relationshipDetails.account);

  const isEditMode = useAppSelector(
    (state) => state.relationshipDetails.isEditMode
  );

  const selectedContactIds = useAppSelector(
    (state) => state.relationshipDetails.selectedContactIds
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const selectedContactIdsSet = useMemo(() => new Set(selectedContactIds), [
    selectedContactIds,
  ]);

  const attrs = {
    scrollable: {
      className: `team-members-section__scrollable${
        grow ? ' team-members-section__scrollable--grow' : ''
      }`,
    },
    groupTitle: {
      className: 'team-members-section__group-title',
    },
    text: {
      className: 'team-members-section__text',
    },
    textGray300: {
      className:
        'team-members-section__text team-members-section__text--gray-300',
    },
    textGray400: {
      className:
        'team-members-section__text team-members-section__text--gray-400',
    },
    textBlue: {
      className: 'team-members-section__text team-members-section__text--blue',
    },
    showAllButton: {
      type: 'button' as const,
      className: 'team-members-section__show-all-button',
      onClick: () => {
        setIsExpanded(true);
      },
    },
    addMember: {
      className: `team-members-section__add-member${
        grow ? ' team-members-section__add-member--grow' : ''
      }`,
    },
    addMemberSeparator: {
      className: 'team-members-section__add-member-separator',
    },
    addMemberButton: {
      type: 'button' as const,
      className: 'team-members-section__add-member-button',
      onClick: () => {
        if (account) {
          dispatch(
            openAddMemberModal({
              accountId: account.accountId,
              accountName: account.name,
              initialValues: {
                assignedTo: side || '',
              },
            })
          );
        }
      },
    },
  };

  const renderContacts = () => {
    const contactsToRender =
      isExpanded || isEditMode
        ? contacts
        : contacts.slice(0, MAX_CONTACTS_TO_SHOW);

    return contactsToRender.map((contact, index) => {
      const props = {
        contact,
        key: index,
        isEditable: isEditMode,
        isSelected: selectedContactIdsSet.has(contact.contactId),
        onSelect: (isSelected: boolean, { contactId }: Contact) => {
          if (!isSelected) {
            dispatch(unselectMember(contactId));
          } else {
            dispatch(selectMember(contactId));
          }
        },
      };

      return <TeamMember {...props} />;
    });
  };

  if (showAddMemberIfEmpty && !contacts.length) {
    return (
      <div {...attrs.addMember}>
        <div {...attrs.addMemberSeparator} />
        <button {...attrs.addMemberButton}>
          <span {...attrs.textBlue}>Add First Team Member</span>
          <PlusIcon />
        </button>
        <div {...attrs.addMemberSeparator} />
      </div>
    );
  }

  return (
    <Scrollbar {...attrs.scrollable}>
      {title ? (
        <div {...attrs.groupTitle}>
          <span {...attrs.textGray400}>{title}</span>
          <span {...attrs.textGray400}>({contacts.length})</span>
        </div>
      ) : null}
      {renderContacts()}
      {contacts.length > MAX_CONTACTS_TO_SHOW && !isExpanded && !isEditMode ? (
        <button {...attrs.showAllButton}>
          <span {...attrs.textBlue}>Show All</span>
          <ChevronDownIcon />
        </button>
      ) : null}
    </Scrollbar>
  );
};
