import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import LabelValueRow from '../../../../components/LabelValueRow/LabelValueRow';
import { invokeCreateTeamModal } from '../../../../store/teams/teams.actions';

export const OrgOverview: React.FC = (): JSX.Element => {
  const { user } = useSelector((state: RootState) => state.auth);

  const { myOrg } = useSelector((state: RootState) => state.org);

  const dispatch = useDispatch();

  const openCreateTeamModal = () => {
    dispatch(invokeCreateTeamModal(true));
  };

  const activeUsers = myOrg?.activeUsersCount
    ? `${myOrg?.activeUsersCount}`
    : '';

  const inactiveUsers = myOrg?.inactiveUsersCount
    ? `${myOrg?.inactiveUsersCount}`
    : '';

  const totalTeams = myOrg?.teamsCount ? `${myOrg?.teamsCount}` : '';

  const usersLink = `/orgs/${user?.orgId}/admin-settings/users`;

  const appsLink = `/orgs/${user?.orgId}/admin-settings/apps`;

  const teamsLink = `/orgs/${user?.orgId}/admin-settings/teams`;

  const teamQLink = `/orgs/${user?.orgId}/users/${user?.userId}/team-analytics/overview`;

  const attrs = {
    wrapper: {
      className: 'overview-info',
    },

    header: {
      className: 'overview-info-header',
    },

    headerWithMargin: {
      className: 'margin overview-info-header',
    },

    body: {
      wrapper: {
        className: 'overview-info__body',
      },

      rows: {
        overview: {
          TeamsQty: {
            label: 'Teams',
            value: totalTeams,
          },

          ActiveUsersQty: {
            label: 'Total Active Users',
            value: activeUsers,
          },

          InactiveUsersQty: {
            label: 'Total Inactive Users',
            value: inactiveUsers,
          },
        },

        links: {
          Users: {
            label: 'Manage Users',
            value: '',
            valueLink: usersLink,
            hoverAndLinkClickEverywhere: true,
          },

          Apps: {
            label: 'Apps Settings',
            value: '',
            valueLink: appsLink,
            hoverAndLinkClickEverywhere: true,
          },

          Teams: {
            label: 'Create a New Team',
            value: '',
            valueLink: teamsLink,
            onAction: openCreateTeamModal,
            hoverAndLinkClickEverywhere: true,
          },

          TeamQ: {
            label: 'Team Q Analytics',
            value: '',
            valueLink: teamQLink,
            hoverAndLinkClickEverywhere: true,
          },
        },
      },
    },
  };

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header}>Quick Overview</div>
      <div {...attrs.body.wrapper}>
        <LabelValueRow {...attrs.body.rows.overview.TeamsQty} />
        <LabelValueRow {...attrs.body.rows.overview.ActiveUsersQty} />
        <LabelValueRow {...attrs.body.rows.overview.InactiveUsersQty} />
      </div>
      <div {...attrs.headerWithMargin}>Quick Links</div>
      <div {...attrs.body.wrapper}>
        <LabelValueRow {...attrs.body.rows.links.Users} />
        <LabelValueRow {...attrs.body.rows.links.Apps} />
        <LabelValueRow {...attrs.body.rows.links.Teams} />
        <LabelValueRow {...attrs.body.rows.links.TeamQ} />
      </div>
    </div>
  );
};
