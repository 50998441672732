import { User } from '../../../../../types';

export const checkFeatureAvailability = (
  user: User | null,
  featureName: string
) => {
  if (user && featureName) {
    return (
      user.metadata?.features?.find((feature) => feature.name === featureName)
        ?.enabled || false
    );
  }

  return false;
};
