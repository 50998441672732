import { Skeleton } from 'antd';
import React from 'react';

interface Props {
  children?: JSX.Element | string;
  isLoading?: boolean;
}

export const TopDomainSkeleton: React.FC<Props> = (props: Props) => {
  const { children, isLoading } = props;

  const attrs = {
    title: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 178,
          maxWidth: '45%',
          height: 20,
          borderRadius: 30,
          marginTop: 0,
        },
      },
    },
    subTitle: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 168,
          maxWidth: '41%',
          height: 13,
          borderRadius: 30,
          marginTop: -6,
        },
      },
    },
    bar: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 16,
          height: 140,
          borderRadius: 10,
          margin: 0,
          marginTop: 5,
        },
      },
    },
    xAxisTick: {
      loading: isLoading,
      active: true,
      avatar: false,
      paragraph: false,
      title: {
        style: {
          width: 10,
          height: 10,
          borderRadius: 30,
          margin: 'auto',
          marginTop: 12,
        },
      },
    },
  };

  const chartColumn = (
    <div className="top-domains-chart__column">
      <Skeleton {...attrs.bar} />
      <Skeleton {...attrs.xAxisTick} />
    </div>
  );

  return (
    <>
      {isLoading ? (
        <div className="chart-wrapper">
          <div className="chart-wrapper__title-block">
            <Skeleton {...attrs.title} />
          </div>
          <div className="chart-wrapper__subtitle">
            <Skeleton {...attrs.subTitle} />
          </div>
          <div className="top-domains-chart">
            {chartColumn}
            {chartColumn}
            {chartColumn}
            {chartColumn}
            {chartColumn}
            {chartColumn}
            {chartColumn}
            {chartColumn}
            {chartColumn}
            {chartColumn}
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
