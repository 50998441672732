import React from 'react';
import { Avatar, Modal } from '../../../../../../../components';
import { AvatarVariant } from '../../../../../../../components/Avatar/Avatar';
import { Team } from '../../../../../../../store/teams/teams.reducer';
import { sortTeamMembers } from '../../../utils/sortMemebersArray';

type Props = {
  team: Team;
  open: boolean;
  onClose: () => void;
};

export const TeamProfileMemersModal: React.FC<Props> = ({
  team,
  open,
  onClose,
}): JSX.Element => {
  const attrs = {
    Modal: {
      title: team.name,
      open,
      onClose,
      className: 'profile-team-modal',
    },

    body: {
      wrapper: {
        className: 'profile-team-modal__body',
      },

      row: {
        wrapper: {
          className: 'profile-team-modal__body__member-row',
        },

        avatarSection: {
          wrapper: {
            className: 'profile-team-modal__body__member-row__img-section',
          },

          Avatar: {
            className:
              'profile-team-modal__body__member-row__img-section-avatar',
          },
        },

        info: {
          wrapper: {
            className: 'profile-team-modal__body__member-row__info',
          },

          name: {
            className: 'profile-team-modal__body__member-row__info-name',
          },

          role: {
            className: 'profile-team-modal__body__member-row__info-role',
          },
        },
      },
    },
  };

  const createRow = (
    index: number,
    name: string,
    img?: string,
    role?: 'teamLeader' | 'member'
  ) => {
    const addVariantType = () => {
      let variantType: AvatarVariant = 'secondaryD';

      if (index % 1 === 1) {
        variantType = 'primaryL';
      } else if (index % 2 === 0) {
        variantType = 'primaryD';
      } else if (index % 3 === 0) {
        variantType = 'secondaryL';
      }

      return variantType;
    };
    const memberRole = role === 'teamLeader' ? 'Team Leader' : 'Team Member';

    const memberRow = (
      <div {...attrs.body.row.wrapper}>
        <div {...attrs.body.row.avatarSection.wrapper}>
          <Avatar
            {...attrs.body.row.avatarSection.Avatar}
            image={img}
            userName={name}
            variant={addVariantType()}
          />
        </div>
        <div {...attrs.body.row.info.wrapper}>
          <div
            className={`${
              role === 'teamLeader' ? 'team-leader' : ''
            } profile-team-modal__body__member-row__info-name`}
          >
            {name}
          </div>
          <div {...attrs.body.row.info.role}>{memberRole}</div>
        </div>
      </div>
    );

    return memberRow;
  };

  const mappedMembers = sortTeamMembers(team.members).map((el, i) => (
    <React.Fragment key={el.key}>
      {createRow(i, el.name, el.avatar, el.teamMemberRole)}
    </React.Fragment>
  ));

  return (
    <Modal {...attrs.Modal}>
      <>
        <div className="profile-team-modal-shadow-overlay" />
        <div {...attrs.body.wrapper}>{mappedMembers}</div>
      </>
    </Modal>
  );
};
