import React from 'react';
import { Skeleton } from 'antd';
import { LogoPlaceholderIcon } from '../../../../../../components';
import LabelValueRow from '../../../../../../components/LabelValueRow/LabelValueRow';
import {
  Avatar,
  AvatarSize,
} from '../../../../../../components/SoundWave/components/Avatar/Avatar';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { openAddAccountModal } from '../../../../../../store/relationshipFlows/relationshipFlows.slice';

export const CompanyDetails: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { accountCompanyDetails: isLoading } = useAppSelector(
    (state) => state.relationshipDetails.isLoading
  );
  const accountCompanyDetails = useAppSelector(
    (state) => state.relationshipDetails.accountCompanyDetails
  );

  const renderLink = (url: string, label?: string) => {
    const props = {
      key: url,
      href: url,
      target: '_blank',
      rel: 'noreferrer noopener',
      className: 'company__link',
    };

    return <a {...props}>{label || url}</a>;
  };

  const renderSocialLinks = (
    socials: Array<{
      type: string;
      url: string;
    }>
  ) =>
    socials.map(({ url }, index) => {
      const isLastItem = index === socials.length - 1;

      return (
        <>
          {renderLink(url)}
          {!isLastItem ? <span>, </span> : ''}
        </>
      );
    });

  const attrs = {
    wrapper: {
      className: 'company',
    },

    header: {
      wrapper: {
        className: 'company__header-row',
      },

      leftSection: {
        wrapper: {
          className: 'company__header-row__left-section',
        },

        avatar: {
          size: AvatarSize.L,
          src: accountCompanyDetails?.avatarSrc || '',
          placeholder: LogoPlaceholderIcon,
          hasBorder: false,
          shape: 'square' as const,
          className: 'company__header-row__left-section-avatar',
        },

        info: {
          wrapper: {
            className: 'company__header-row__left-section__info',
          },

          name: {
            className: 'company__header-row__left-section__info-name',
          },

          description: {
            className: 'company__header-row__left-section__info-description',
          },
        },
      },

      rightSection: {
        wrapper: {
          className: 'company__header-row__right-section',
        },

        crm: {
          className: 'company__header-row__right-section-crm-button',
        },

        edit: {
          className: 'company__header-row__right-section-edit-button',
          onClick: () => {
            if (accountCompanyDetails) {
              dispatch(
                openAddAccountModal({
                  accountId: accountCompanyDetails?.accountId,
                  initialValues: {
                    logo: accountCompanyDetails?.avatarSrc || '',
                    name: accountCompanyDetails?.accountName || '',
                    website: accountCompanyDetails?.companyWebsite || '',
                  },
                })
              );
            }
          },
        },
      },

      skeleton: {
        left: {
          active: true,
          paragraph: false,
          style: {
            width: '30%',
            padding: '13px 12px',
          },
          title: {
            style: {
              width: '100%',
              height: 26,
              margin: 0,
              borderRadius: 8,
            },
          },
        },

        right: {
          active: true,
          paragraph: false,
          style: {
            width: '40px',
            padding: '11px 0px',
          },
          title: {
            style: {
              width: '100%',
              height: 24,
              margin: 0,
              borderRadius: 8,
            },
          },
        },
      },
    },

    content: {
      wrapper: {
        className: 'company__content',
      },

      section: {
        wrapper: {
          className: 'company__content__section',
        },
      },

      divider: {
        className: 'company__content-divider',
      },

      sectionRow: {
        wrapper: {
          className: 'company__content__section__row',
        },
      },
    },
    websiteRow: {
      label: 'Website',
      value: accountCompanyDetails?.companyWebsite
        ? renderLink(accountCompanyDetails.companyWebsite)
        : '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    headequartersRow: {
      label: 'HQ',
      value: accountCompanyDetails?.headquarters || '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    yearFoundedRow: {
      label: 'Year Founded',
      value: accountCompanyDetails?.foundedYear || '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    categoryRow: {
      label: 'Category',
      value: accountCompanyDetails?.category || '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    employeesRow: {
      label: 'Employees',
      value: accountCompanyDetails?.employees || '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    estimatedAnnualRevenueRow: {
      label: 'Est. Revenue',
      value: accountCompanyDetails?.estimatedAnnualRevenue || '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    totalFundingRow: {
      label: 'Total Funding',
      value: accountCompanyDetails?.totalFunding || '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    socialMediaRow: {
      label: 'Socials',
      value: accountCompanyDetails?.socialMedia?.length
        ? renderSocialLinks(accountCompanyDetails.socialMedia)
        : '-',
      rowHeight: '30px',
      withFadeInAnimation: true,
    },
    skeleton: {
      active: true,
      paragraph: false,
      style: {
        width: '100%',
        padding: '8px 8px',
      },
      title: {
        style: {
          width: '100%',
          height: 16,
          margin: 0,
          borderRadius: 8,
        },
      },
    },
  };

  const editIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.45492 2.86215C10.236 2.0811 11.5023 2.08111 12.2834 2.86215L13.2262 3.80496C14.0072 4.58601 14.0072 5.85234 13.2262 6.63339L7.08722 12.7723C6.80634 13.0532 6.44819 13.2441 6.05842 13.3206L3.9113 13.7423C2.98159 13.9249 2.16343 13.1067 2.34602 12.177L2.76768 10.0299C2.84423 9.64012 3.03511 9.28197 3.31598 9.00109L9.45492 2.86215ZM11.3405 3.80496C11.0802 3.54461 10.6581 3.54461 10.3977 3.80496L9.85045 4.35225L9.85526 4.3611C10.2855 5.1527 10.9356 5.80285 11.7272 6.23306L11.7361 6.23787L12.2834 5.69058C12.5437 5.43023 12.5437 5.00812 12.2834 4.74777L11.3405 3.80496ZM10.7618 7.21214C9.9998 6.73303 9.35528 6.08851 8.87617 5.32652L4.25879 9.9439C4.16517 10.0375 4.10154 10.1569 4.07603 10.2868L3.65436 12.434L5.80148 12.0123C5.9314 11.9868 6.05078 11.9231 6.14441 11.8295L10.7618 7.21214Z"
        fill="#646F87"
      />
    </svg>
  );

  const headerContent = isLoading ? (
    <>
      <Skeleton {...attrs.header.skeleton.left} />
      <Skeleton {...attrs.header.skeleton.right} />
    </>
  ) : (
    <>
      <div {...attrs.header.leftSection.wrapper}>
        <Avatar {...attrs.header.leftSection.avatar} />
        <div {...attrs.header.leftSection.info.wrapper}>
          <div {...attrs.header.leftSection.info.name}>
            {accountCompanyDetails?.accountName}
          </div>
          <div {...attrs.header.leftSection.info.description}>
            {accountCompanyDetails?.description}
          </div>
        </div>
      </div>
      <div {...attrs.header.rightSection.wrapper}>
        <button {...attrs.header.rightSection.edit}>{editIcon}</button>
      </div>
    </>
  );

  const content = isLoading ? (
    <>
      <Skeleton {...attrs.skeleton} />
      <Skeleton {...attrs.skeleton} />
      <Skeleton {...attrs.skeleton} />
      <Skeleton {...attrs.skeleton} />
      <Skeleton {...attrs.skeleton} />
      <Skeleton {...attrs.skeleton} />
      <Skeleton {...attrs.skeleton} />
      <Skeleton {...attrs.skeleton} />
    </>
  ) : (
    <>
      <LabelValueRow {...attrs.websiteRow} />
      <LabelValueRow {...attrs.headequartersRow} />
      <LabelValueRow {...attrs.yearFoundedRow} />
      <LabelValueRow {...attrs.categoryRow} />
      <LabelValueRow {...attrs.employeesRow} />
      <LabelValueRow {...attrs.estimatedAnnualRevenueRow} />
      <LabelValueRow {...attrs.totalFundingRow} />
      <LabelValueRow {...attrs.socialMediaRow} />
    </>
  );

  return (
    <div {...attrs.wrapper}>
      <div {...attrs.header.wrapper}>{headerContent}</div>
      <div {...attrs.content.wrapper}>{content}</div>
    </div>
  );
};
