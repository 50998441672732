import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartWrapper } from '../../chartWrapper/ChartWrapper';
import { TopDomainSkeleton } from '../../skeletons/TopDomainsSkeleton';

type Data = {
  domain: string;
  emailsCount: number;
}[];

type Props = {
  activeDomainsdata?: Data;
  isLoading: boolean;
  noData: boolean;
  error: boolean;
};

type LocalData = {
  domain: string;
  emails: number;
}[];

export const TopTenDomains: React.FC<Props> = ({
  activeDomainsdata,
  isLoading,
  noData,
  error,
}): JSX.Element => {
  const [activeBarIndex, setActiveBarIndex] = useState<number | null>(null);
  const [data, setData] = useState<LocalData>([]);

  useEffect(() => {
    if (activeDomainsdata?.length) {
      if (activeDomainsdata.length < 10) {
        const arrWithEmptyDomains: LocalData = []; // eslint-disable-next-line
        for (let i = 0; i + activeDomainsdata.length < 10; i++) {
          arrWithEmptyDomains.push({ domain: '-', emails: 0 });
        }
        const sortedDomains = activeDomainsdata
          .map((el) => ({
            domain: el.domain,
            emails: el.emailsCount,
          }))
          .sort((a, b) => a.emails - b.emails);
        const resultArray = arrWithEmptyDomains.concat(sortedDomains);
        setData(resultArray);
      } else {
        const sortedDomains = activeDomainsdata
          .map((el) => ({
            domain: el.domain,
            emails: el.emailsCount,
          }))
          .sort((a, b) => a.emails - b.emails);
        setData(sortedDomains);
      }
    } else {
      const arrNoData: LocalData = []; // eslint-disable-next-line
      for (let i = 0; i < 10; i++) {
        arrNoData.push({ domain: '-', emails: 0 });
      }
      setData(arrNoData);
    } // eslint-disable-next-line
  }, [activeDomainsdata?.length]);

  const onMouseBarEnter = (mess: string, index: number) => {
    setActiveBarIndex(index);
  };
  const onMouseBarLeave = () => {
    if (activeBarIndex !== null && activeBarIndex > -1) {
      setActiveBarIndex(null);
    }
  };

  const tooltipAttrs = {
    wrapper: {
      className: 'chart-tooltip',
      style: { height: '63px', width: '255px' },
    },
    body: {
      wrapper: {
        className: 'chart-tooltip__single-line',
      },
      border: {
        className: 'chart-tooltip__single-line-left-border',
        style: {
          backgroundColor: '#4EB4FF',
          height: '33px',
        },
      },
      content: {
        className: 'chart-tooltip__single-line__body',
      },
    },
    value: {
      className: 'top-domains chart-tooltip__value',
    },
    valueText: { className: 'chart-tooltip__value-text' },
    boldText: { className: 'chart-tooltip__percents-text-bold' },
    boldTextDomain: { className: 'chart-tooltip__percents-text-bold-domain' },
  };

  // eslint-disable-next-line
  const CustomTooltip = ({ active, payload }: any): JSX.Element => {
    let result = <div />;

    if (active && data.length) {
      const totalEmails: number = payload[0].payload.emails;
      const domainName: number = payload[0].payload.domain;

      const messageToShow =
        totalEmails && !Number.isNaN(totalEmails) ? (
          <span {...tooltipAttrs.value}>
            <span {...tooltipAttrs.valueText}>
              You had{' '}
              <span {...tooltipAttrs.boldText}>
                {totalEmails?.toFixed(0)}{' '}
                {totalEmails === 1 ? 'interaction' : 'interactions'}
              </span>{' '}
              with people from{' '}
              <span {...tooltipAttrs.boldTextDomain}>{domainName}</span>
            </span>
          </span>
        ) : (
          <span {...tooltipAttrs.value}>
            0<span {...tooltipAttrs.valueText}>-</span>
          </span>
        );

      result = (
        <div {...tooltipAttrs.wrapper}>
          <div {...tooltipAttrs.body.wrapper}>
            <div {...tooltipAttrs.body.border} />
            <div {...tooltipAttrs.body.content}>{messageToShow}</div>
          </div>
        </div>
      );
    }
    return result;
  };

  // eslint-disable-next-line
  const CustomLabel = (props: any) => {
    const { x, y, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={12}
          y={0}
          dy={-8.5}
          textAnchor="end"
          fill="#646C83"
          transform="rotate(315)"
          fontSize={9}
        >
          {payload.value.length > 13
            ? `${payload.value?.substring(0, 13)}...`
            : payload.value}
        </text>
      </g>
    );
  };

  const attrs = {
    chartWrapper: {
      title: 'Most Active Accounts',
      subtitle: 'Total interactions by domain',
      tooltipText: `This shows the most active accounts you’ve 
      engaged with during the selected time period.
      The bar height represents the total messages in that period
      between you and all of the participants from that domain name.`,
      isNoData: noData || error,
      upIndex: activeBarIndex !== null && activeBarIndex > -1,
    },
    legendWrapper: {
      className: 'charts__avg-partics',
      style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
    },
    legendUser: {
      className: 'charts__avg-partics__user-legend',
    },
    legendProspect: {
      className: 'charts__avg-partics__prospect-legend',
    },
    legendOther: {
      className: 'charts__avg-partics__other-legend',
    },
    responsiveContainer: {
      height: '100%',
    },
    chart: {
      barChart: {
        width: 295,
        height: 220,
        data,
        margin: { top: 30, right: 0, left: -28, bottom: 30 },
        style: noData || error ? { opacity: 0.5, zIndex: 1 } : {},
      },
      cartesianGrid: {
        strokeDasharray: '0 0',
        vertical: false,
        stroke: '#F8F8FB',
        strokeWidth: 1,
      },
      xAxis: {
        dataKey: 'domain',
        tick: <CustomLabel />,
        tickSize: 0,
        tickLine: { stroke: '#F8F8FB' },
        axisLine: { stroke: '#F8F8FB' },
        tickMargin: 26,
        padding: { right: 5, left: 5 },
        interval: 0,
      },
      yAxis: {
        tick: { stroke: '#646C83', strokeWidth: 0.1 },
        tickSize: 0,
        tickLine: false,
        axisLine: false,
        tickMargin: 10,
      },
      tooltip: {
        wrapperStyle:
          activeBarIndex !== null && activeBarIndex > -1 && !noData && !error
            ? { top: '-90px', left: '-138px' }
            : { display: 'none' },
        content: <CustomTooltip payload={data} active />,
        allowEscapeViewBox: { x: true, y: true },
        isAnimationActive: false,
        cursor: false,
      },
      bar: {
        dataKey: 'emails',
        fill: '#4EB4FF',
        barSize: 16,
        isAnimationActive: false,
        onMouseEnter: onMouseBarEnter,
        onMouseLeave: onMouseBarLeave,
      },
    },
  };

  return (
    <TopDomainSkeleton isLoading={isLoading}>
      <ChartWrapper {...attrs.chartWrapper}>
        <ResponsiveContainer {...attrs.responsiveContainer}>
          <BarChart {...attrs.chart.barChart}>
            <CartesianGrid {...attrs.chart.cartesianGrid} />
            <XAxis {...attrs.chart.xAxis} />
            <YAxis {...attrs.chart.yAxis} />
            <Tooltip {...attrs.chart.tooltip} />
            <Bar {...attrs.chart.bar} radius={[4, 4, 0, 0]}>
              {data.map((el, index) => (
                <Cell
                  filter={index === activeBarIndex ? 'brightness(85%)' : ''}
                  key={`cell-${index}${el}`}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </TopDomainSkeleton>
  );
};
